import { OnInit } from '@angular/core';
import { AuthService } from 'fecommon';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(authService) {
        this.authService = authService;
        this.pageGroup = [];
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.userSubject.subscribe(function (userProfile) {
            _this.pageGroup = userProfile.page_group;
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
