import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GivingsPlaceComponent } from './donation/givings-place/givings-place.component';
import { DreceiptIssueComponent } from './donation/dreceipt-issue/dreceipt-issue.component';
import { GivingsCallWatingComponent } from './donation/givings-call-wating/givings-call-wating.component';
import { GivingsCallDurationComponent } from './donation/givings-call-duration/givings-call-duration.component';
import { GivingsCallHistoryComponent } from './donation/givings-call-history/givings-call-history.component';
import { LogisticsDeptsComponent } from './donation/logistics-depts/logistics-depts.component';
import { LogisticsEmpsComponent } from './donation/logistics-emps/logistics-emps.component';
import { GivingsRegionComponent } from './donation/givings-region/givings-region.component';
import { GivingsCallComponent } from './donation/givings-call/givings-call.component';
import { GivingsCallAreaComponent } from './donation/givings-call-area/givings-call-area.component';
import { RawDataComponent } from './donation/raw-data/raw-data.component';
import { WarehouseProductionComponent } from './donation/warehouse-production/warehouse-production.component';
import { WarehouseShippingComponent } from './donation/warehouse-shipping/warehouse-shipping.component';
import { WarehouseEventShippingComponent } from './donation/warehouse-event-shipping/warehouse-event-shipping.component';
import { WarehouseTotalComponent } from './donation/warehouse-total/warehouse-total.component';
import { ShopGivingsProductionComponent } from './donation/shop-givings-production/shop-givings-production.component';
import { SalesRateBySupplyComponent } from './donation/sales-rate-by-supply/sales-rate-by-supply.component';
import { SupplyRateBySalesComponent } from './donation/supply-rate-by-sales/supply-rate-by-sales.component';
import { GivingsByDonationComponent } from './donation/givings-by-donation/givings-by-donation.component';
import { AngelComponent } from './angel/angel.component';
import { AuthGuard } from 'fecommon';
import { GivingsCallAllDurationComponent } from './donation/givings-call-all-duration/givings-call-all-duration.component';

const routes: Routes = [
    { path: 'statistics/donation', redirectTo: 'statistics/donation/givings-place', pathMatch: 'full' },
    {
        path: 'statistics/donation/givings-place',
        component: GivingsPlaceComponent,
        canActivate: [AuthGuard],
        data: {
            title: '기부처별 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '기부처별 통계'
        }
    },
    {
        path: 'statistics/donation/anonymous',
        component: GivingsPlaceComponent,
        canActivate: [AuthGuard],
        data: {
            title: '기부처별 익명기부 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '기부처별 익명기부 통계'
        }
    },
    {
        path: 'statistics/donation/givings-region',
        component: GivingsRegionComponent,
        canActivate: [AuthGuard],
        data: {
            title: '지역별 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '지역별 통계'
        }
    },
    {
        path: 'statistics/donation/dreceipt-issue',
        component: DreceiptIssueComponent,
        canActivate: [AuthGuard],
        data: {
            title: '기부영수증 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '기부영수증 통계'
        }
    },
    {
        path: 'statistics/donation/logistics-depts',
        component: LogisticsDeptsComponent,
        canActivate: [AuthGuard],
        data: {
            title: '수송구분별 순환지원 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '수송구분별 순환지원 통계'
        }
    },
    {
        path: 'statistics/donation/logistics-emps',
        component: LogisticsEmpsComponent,
        canActivate: [AuthGuard],
        data: {
            title: '운전자별 순환지원 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '운전자별 순환지원 통계'
        }
    },
    {
        path: 'statistics/donation/call-givings',
        component: GivingsCallComponent,
        canActivate: [AuthGuard],
        data: {
            title: '콜접수 구분별 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '콜접수 구분별 통계'
        }
    },
    {
        path: 'statistics/donation/givings-call-area',
        component: GivingsCallAreaComponent,
        canActivate: [AuthGuard],
        data: {
            title: '수거 권역별 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '수거 권역별 통계'
        }
    },
    {
        path: 'statistics/donation/givings-call-history',
        component: GivingsCallHistoryComponent,
        canActivate: [AuthGuard],
        data: {
            title: '상담문의 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '상담문의 통계'
        }
    },
    {
        path: 'statistics/donation/givings-call-all-duration',
        component: GivingsCallAllDurationComponent,
        canActivate: [AuthGuard],
        data: {
            title: '접수~발급 소요일 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '접수~발급 소요일 통계'
        }
    },
    {
        path: 'statistics/donation/givings-call-wating',
        component: GivingsCallWatingComponent,
        canActivate: [AuthGuard],
        data: {
            title: '콜수거 방문소요일 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '콜수거 방문소요일 통계'
        }
    },
    {
        path: 'statistics/donation/givings-call-duration',
        component: GivingsCallDurationComponent,
        canActivate: [AuthGuard],
        data: {
            title: '콜수거 접수대기일 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '콜수거 접수대기일 통계'
        }
    },
    {
        path: 'statistics/donation/warehouse-total',
        component: WarehouseTotalComponent,
        canActivate: [AuthGuard],
        data: {
            title: '되살림터 종합 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '되살림터 종합 통계'
        }
    },
    {
        path: 'statistics/donation/warehouse-production',
        component: WarehouseProductionComponent,
        canActivate: [AuthGuard],
        data: {
            title: '생산 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '생산 통계'
        }
    },
    {
        path: 'statistics/donation/warehouse-shipping',
        component: WarehouseShippingComponent,
        canActivate: [AuthGuard],
        data: {
            title: '전체출고 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '전체 출고 통계'
        }
    },
    {
        path: 'statistics/donation/warehouse-event-shipping',
        component: WarehouseEventShippingComponent,
        canActivate: [AuthGuard],
        data: {
            title: '행사출고 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '행사출고 통계'
        }
    },
    {
        path: 'statistics/donation/shop-givings',
        component: ShopGivingsProductionComponent,
        canActivate: [AuthGuard],
        data: {
            title: '매장별 점생산 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '매장별 점생산 통계'
        }
    },
    {
        path: 'statistics/donation/raw-data',
        component: RawDataComponent,
        canActivate: [AuthGuard],
        data: {
            title: '원시데이터 다운로드',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '원시데이터 다운로드'
        }
    },
    {
        path: 'statistics/donation/sales-rate-by-supply',
        component: SalesRateBySupplyComponent,
        canActivate: [AuthGuard],
        data: {
            title: '공급대비판매 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '공급대비판매 통계'
        }
    },
    {
        path: 'statistics/donation/supply-rate-by-sales',
        component: SupplyRateBySalesComponent,
        canActivate: [AuthGuard],
        data: {
            title: '판매대비공급 통계',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '판매대비공급 통계'
        }
    },
    {
        path: 'statistics/donation/givings-by-donation',
        component: GivingsByDonationComponent,
        canActivate: [AuthGuard],
        data: {
            title: '기부건별 조회',
            homeLink: '/',
            firstLevel: '기부',
            secondLevel: '기부건별 조회'
        }
    },
    {
        path: 'statistics/angel/angel-day-time',
        component: AngelComponent,
        canActivate: [AuthGuard],
        data: {
            title: '자원활동 통계',
            homeLink: '/',
            firstLevel: '자원',
            secondLevel: '자원활동 통계'
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class StatisticsRoutingModule { }