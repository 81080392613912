<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style">
      <label>기간</label>
      <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()"
        (dateChange)="chosenYearHandler($event.target.value, end)" formControlName="year" readonly>
      <mat-datepicker #end startView="multi-year" (yearSelected)="chosenYearHandler($event, end)"></mat-datepicker>
    </div>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div class="statistics-mat-table" #TABLE>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="monthly_01">
          <mat-header-cell *matHeaderCellDef mat-sort-header>1월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_01 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_02">
          <mat-header-cell *matHeaderCellDef mat-sort-header>2월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_02 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_03">
          <mat-header-cell *matHeaderCellDef mat-sort-header>3월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_03 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_04">
          <mat-header-cell *matHeaderCellDef mat-sort-header>4월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_04 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_05">
          <mat-header-cell *matHeaderCellDef mat-sort-header>5월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_05 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_06">
          <mat-header-cell *matHeaderCellDef mat-sort-header>6월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_06 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_07">
          <mat-header-cell *matHeaderCellDef mat-sort-header>7월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_07 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_08">
          <mat-header-cell *matHeaderCellDef mat-sort-header>8월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_08 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_09">
          <mat-header-cell *matHeaderCellDef mat-sort-header>9월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_09 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_10">
          <mat-header-cell *matHeaderCellDef mat-sort-header>10월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_10 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_11">
          <mat-header-cell *matHeaderCellDef mat-sort-header>11월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_11 | number:'0.2'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="monthly_12">
          <mat-header-cell *matHeaderCellDef mat-sort-header>12월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.monthly_12 | number:'0.2'}}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>