/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다. 
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EmpRole } from '../model/empRole';
import { Failure } from '../model/failure';
import { Policies } from '../model/policies';
import { Role } from '../model/role';
import { RoleDescription } from '../model/roleDescription';
import { RoleList } from '../model/roleList';
import { RolePost } from '../model/rolePost';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RoleService {

    protected basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 해당 부서전체 인원에 role 추가 및 변경
     * 
     * @param dept_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deptsDeptIdRolesPut(dept_id: string, body: RoleList, observe?: 'body', reportProgress?: boolean): Observable<Array<EmpRole>>;
    public deptsDeptIdRolesPut(dept_id: string, body: RoleList, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EmpRole>>>;
    public deptsDeptIdRolesPut(dept_id: string, body: RoleList, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EmpRole>>>;
    public deptsDeptIdRolesPut(dept_id: string, body: RoleList, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptsDeptIdRolesPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deptsDeptIdRolesPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<EmpRole>>(`${this.basePath}/depts/${encodeURIComponent(String(dept_id))}/roles`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * emp에게 role 추가 및 변경
     * 
     * @param emp_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empsEmpIdRolesPut(emp_id: string, body: RoleList, observe?: 'body', reportProgress?: boolean): Observable<EmpRole>;
    public empsEmpIdRolesPut(emp_id: string, body: RoleList, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmpRole>>;
    public empsEmpIdRolesPut(emp_id: string, body: RoleList, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmpRole>>;
    public empsEmpIdRolesPut(emp_id: string, body: RoleList, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling empsEmpIdRolesPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling empsEmpIdRolesPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<EmpRole>(`${this.basePath}/emps/${encodeURIComponent(String(emp_id))}/roles`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * role 전체 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rolesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Role>>;
    public rolesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Role>>>;
    public rolesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Role>>>;
    public rolesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Role>>(`${this.basePath}/roles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * role 등록
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rolesPost(body: RolePost, observe?: 'body', reportProgress?: boolean): Observable<RolePost>;
    public rolesPost(body: RolePost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RolePost>>;
    public rolesPost(body: RolePost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RolePost>>;
    public rolesPost(body: RolePost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rolesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RolePost>(`${this.basePath}/roles`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * role 삭제
     * 
     * @param role_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rolesRoleNameDelete(role_name: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rolesRoleNameDelete(role_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rolesRoleNameDelete(role_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rolesRoleNameDelete(role_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (role_name === null || role_name === undefined) {
            throw new Error('Required parameter role_name was null or undefined when calling rolesRoleNameDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/roles/${encodeURIComponent(String(role_name))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * role 조회
     * 
     * @param role_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rolesRoleNameGet(role_name: string, observe?: 'body', reportProgress?: boolean): Observable<Role>;
    public rolesRoleNameGet(role_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Role>>;
    public rolesRoleNameGet(role_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Role>>;
    public rolesRoleNameGet(role_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (role_name === null || role_name === undefined) {
            throw new Error('Required parameter role_name was null or undefined when calling rolesRoleNameGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Role>(`${this.basePath}/roles/${encodeURIComponent(String(role_name))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * role에 등록된 policies 삭제(사용안함)
     * 
     * @param emp_id api를 요청한 간사아이디
     * @param emp_name api를 요청한 간사이름
     * @param role_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rolesRoleNamePoliciesDelete(emp_id: string, emp_name: string, role_name: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rolesRoleNamePoliciesDelete(emp_id: string, emp_name: string, role_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rolesRoleNamePoliciesDelete(emp_id: string, emp_name: string, role_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rolesRoleNamePoliciesDelete(emp_id: string, emp_name: string, role_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling rolesRoleNamePoliciesDelete.');
        }
        if (emp_name === null || emp_name === undefined) {
            throw new Error('Required parameter emp_name was null or undefined when calling rolesRoleNamePoliciesDelete.');
        }
        if (role_name === null || role_name === undefined) {
            throw new Error('Required parameter role_name was null or undefined when calling rolesRoleNamePoliciesDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', <any>emp_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/roles/${encodeURIComponent(String(role_name))}/policies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * role에 policies 추가 및 변경
     * 
     * @param role_name 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rolesRoleNamePoliciesPut(role_name: string, body: Policies, observe?: 'body', reportProgress?: boolean): Observable<Policies>;
    public rolesRoleNamePoliciesPut(role_name: string, body: Policies, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Policies>>;
    public rolesRoleNamePoliciesPut(role_name: string, body: Policies, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Policies>>;
    public rolesRoleNamePoliciesPut(role_name: string, body: Policies, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (role_name === null || role_name === undefined) {
            throw new Error('Required parameter role_name was null or undefined when calling rolesRoleNamePoliciesPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rolesRoleNamePoliciesPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Policies>(`${this.basePath}/roles/${encodeURIComponent(String(role_name))}/policies`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * role 수정 description
     * 
     * @param role_name 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rolesRoleNamePut(role_name: string, body: RoleDescription, observe?: 'body', reportProgress?: boolean): Observable<Role>;
    public rolesRoleNamePut(role_name: string, body: RoleDescription, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Role>>;
    public rolesRoleNamePut(role_name: string, body: RoleDescription, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Role>>;
    public rolesRoleNamePut(role_name: string, body: RoleDescription, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (role_name === null || role_name === undefined) {
            throw new Error('Required parameter role_name was null or undefined when calling rolesRoleNamePut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rolesRoleNamePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Role>(`${this.basePath}/roles/${encodeURIComponent(String(role_name))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
