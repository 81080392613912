<div class="section-basic">
    <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

        <div class="form-group m-form__group default-style datepicker-group">
            <label>조회기간</label>
            <div class="input-group">
                <input [matDatepicker]="start" (click)="start.open()" class="form-control m-input cursorpointer"
                    formControlName="gte_date" readonly>
                <mat-datepicker #start [dateRangePicker]="{startDate: start, endDate: end}"
                    (rangeComplete)="rangeComplete($event)"></mat-datepicker>
                <div class="input-group-append">
                    <span class="input-group-text">~</span>
                </div>
                <input [matDatepicker]="end" (click)="end.open()" class="form-control m-input cursorpointer"
                    formControlName="lte_date" readonly>
                <mat-datepicker #end [dateRangePicker]="{startDate: start, endDate: end}"
                    (rangeComplete)="rangeComplete($event)"></mat-datepicker>
            </div>
        </div>

        <div class="form-group m-form__group default-style">
            <label>기증처</label>
            <input class="form-control m-input" formControlName="dept_name" (keydown.enter)="search()">
        </div>

        <div class="form-group m-form__group default-style">
            <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
        </div>

    </form>
</div>

<!-- 데이터 테이블 -->
<div class="result">
    <div class="section-basic mb-0 pb-2">
        <div class="btns_right_align">
            <button class="btn btn-sm btn-outline-primary" (click)="onClickExcelExport()"
                [disabled]="dataSource.data.length == 0">엑셀
                다운로드</button>
        </div>

        <div class="m--space-10"></div>

        <mat-table #table class="unset-cursor-pointer" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="give_confirm_date">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 기증일자 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.give_confirm_date | date: 'yyyy-MM-dd'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="give_reception_joiner_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 기증자명 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.give_reception_joiner_name}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="give_confirm_emp_dept_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 기증처 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.give_confirm_emp_dept_name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="give_route">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 기증경로 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.give_route }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="good_cl">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 의류 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.good_cl | currencyMask}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="good_li">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 잡화 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.good_li | currencyMask}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="good_br">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 도서음반 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.good_br | currencyMask}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="good_el">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 가전 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.good_el}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tot_amt">
                <mat-header-cell *matHeaderCellDef> 기증총점수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tot_amt | currencyMask}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <div *ngIf="dataSource.data.length == 0" class="no-records">
            데이터가 존재하지 않습니다.
        </div>

        <div class="m-separator m-separator--dashed"></div>
        <ngb-pagination [pageSize]="pageSize" [collectionSize]="paginationCollection? paginationCollection.length : 0"
            [(page)]="page" (pageChange)="setPage($event)" [boundaryLinks]="true" [maxSize]="5"></ngb-pagination>
    </div>
</div>
