/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsGreenbusinessService = /** @class */ (function () {
    function EsGreenbusinessService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsGreenbusinessService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsGreenbusinessService.prototype.autoOrderGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/auto_order", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.autoSchedulerOnoffGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/auto_scheduler_onoff", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.bstoreGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/bstore", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.checkAutoOrderGet = function (shop_id, good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling checkAutoOrderGet.');
        }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling checkAutoOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/check_auto_order", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.estmateGet = function (gte_date, lte_date, estimate_title, vendor_company_name, good_name, estimate_state, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling estmateGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling estmateGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (estimate_title !== undefined && estimate_title !== null) {
            queryParameters = queryParameters.set('estimate_title', estimate_title);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (estimate_state !== undefined && estimate_state !== null) {
            queryParameters = queryParameters.set('estimate_state', estimate_state);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/estmate", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.estmateGoodListGet = function (good_code, vendor_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling estmateGoodListGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        if (vendor_id !== undefined && vendor_id !== null) {
            queryParameters = queryParameters.set('vendor_id', vendor_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/estmate_good_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.externalOrderGet = function (gte_date, lte_date, vendor_company_name, external_order_complete_status, external_order_trade_category, good_first_category_name, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling externalOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling externalOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        if (external_order_complete_status !== undefined && external_order_complete_status !== null) {
            queryParameters = queryParameters.set('external_order_complete_status', external_order_complete_status);
        }
        if (external_order_trade_category !== undefined && external_order_trade_category !== null) {
            queryParameters = queryParameters.set('external_order_trade_category', external_order_trade_category);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/external_order", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenDeadlineGet = function (chk_date, chk_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (chk_date === null || chk_date === undefined) {
            throw new Error('Required parameter chk_date was null or undefined when calling greenDeadlineGet.');
        }
        if (chk_type === null || chk_type === undefined) {
            throw new Error('Required parameter chk_type was null or undefined when calling greenDeadlineGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (chk_date !== undefined && chk_date !== null) {
            queryParameters = queryParameters.set('chk_date', chk_date);
        }
        if (chk_type !== undefined && chk_type !== null) {
            queryParameters = queryParameters.set('chk_type', chk_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/deadline", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenDeadlinemonGet = function (chk_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (chk_type === null || chk_type === undefined) {
            throw new Error('Required parameter chk_type was null or undefined when calling greenDeadlinemonGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (chk_type !== undefined && chk_type !== null) {
            queryParameters = queryParameters.set('chk_type', chk_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/deadlinemon", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenSalesDetailGet = function (sale_date, vendor_id, area_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (sale_date === null || sale_date === undefined) {
            throw new Error('Required parameter sale_date was null or undefined when calling greenSalesDetailGet.');
        }
        if (vendor_id === null || vendor_id === undefined) {
            throw new Error('Required parameter vendor_id was null or undefined when calling greenSalesDetailGet.');
        }
        if (area_type === null || area_type === undefined) {
            throw new Error('Required parameter area_type was null or undefined when calling greenSalesDetailGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (sale_date !== undefined && sale_date !== null) {
            queryParameters = queryParameters.set('sale_date', sale_date);
        }
        if (vendor_id !== undefined && vendor_id !== null) {
            queryParameters = queryParameters.set('vendor_id', vendor_id);
        }
        if (area_type !== undefined && area_type !== null) {
            queryParameters = queryParameters.set('area_type', area_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/green_sales_detail", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenSalesHeaderGet = function (gte_date, lte_date, vendor_company_name, area_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling greenSalesHeaderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling greenSalesHeaderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        if (area_type !== undefined && area_type !== null) {
            queryParameters = queryParameters.set('area_type', area_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/green_sales_header", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenSalesItemGet = function (gte_date, lte_date, vendor_company_name, area_type, good_type, good_second, good_name, tag_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling greenSalesItemGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling greenSalesItemGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        if (area_type !== undefined && area_type !== null) {
            queryParameters = queryParameters.set('area_type', area_type);
        }
        if (good_type !== undefined && good_type !== null) {
            queryParameters = queryParameters.set('good_type', good_type);
        }
        if (good_second !== undefined && good_second !== null) {
            queryParameters = queryParameters.set('good_second', good_second);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (tag_name !== undefined && tag_name !== null) {
            queryParameters = queryParameters.set('tag_name', tag_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/green_sales_item", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenSalesTotGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling greenSalesTotGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling greenSalesTotGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/green_sales_tot", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenbusinessAutoOrderCancelGet = function (cancel_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (cancel_date === null || cancel_date === undefined) {
            throw new Error('Required parameter cancel_date was null or undefined when calling greenbusinessAutoOrderCancelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (cancel_date !== undefined && cancel_date !== null) {
            queryParameters = queryParameters.set('cancel_date', cancel_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/auto_order_cancel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenbusinessLogisticsOrderGet = function (gte_date, lte_date, search_type, maker_name, warehouse_id, logistics_order_id, logistics_order_status, page_number, logistics_order_type, logistics_order_reciever_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling greenbusinessLogisticsOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling greenbusinessLogisticsOrderGet.');
        }
        if (search_type === null || search_type === undefined) {
            throw new Error('Required parameter search_type was null or undefined when calling greenbusinessLogisticsOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', maker_name);
        }
        if (warehouse_id !== undefined && warehouse_id !== null) {
            queryParameters = queryParameters.set('warehouse_id', warehouse_id);
        }
        if (logistics_order_id !== undefined && logistics_order_id !== null) {
            queryParameters = queryParameters.set('logistics_order_id', logistics_order_id);
        }
        if (logistics_order_status !== undefined && logistics_order_status !== null) {
            queryParameters = queryParameters.set('logistics_order_status', logistics_order_status);
        }
        if (search_type !== undefined && search_type !== null) {
            queryParameters = queryParameters.set('search_type', search_type);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        if (logistics_order_type !== undefined && logistics_order_type !== null) {
            queryParameters = queryParameters.set('logistics_order_type', logistics_order_type);
        }
        if (logistics_order_reciever_name !== undefined && logistics_order_reciever_name !== null) {
            queryParameters = queryParameters.set('logistics_order_reciever_name', logistics_order_reciever_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/logistics_order", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenbusinessMakerMetaGet = function (maker_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', maker_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/maker/meta", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenbusinessShopOrderConfirmGet = function (shop_id, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling greenbusinessShopOrderConfirmGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/shop_order/confirm", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.greenbusinessVendorMetaGet = function (vendor_company_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/vendor/meta", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.internalOrderGet = function (gte_date, lte_date, shop_name_or_dept_name, internal_order_complete_status, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling internalOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling internalOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (shop_name_or_dept_name !== undefined && shop_name_or_dept_name !== null) {
            queryParameters = queryParameters.set('shop_name_or_dept_name', shop_name_or_dept_name);
        }
        if (internal_order_complete_status !== undefined && internal_order_complete_status !== null) {
            queryParameters = queryParameters.set('internal_order_complete_status', internal_order_complete_status);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/internal_order", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.logisticsOrderExcelGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsOrderExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsOrderExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/order/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.makerGet = function (gte_date, lte_date, maker_name, maker_status, maker_category, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', maker_name);
        }
        if (maker_status !== undefined && maker_status !== null) {
            queryParameters = queryParameters.set('maker_status', maker_status);
        }
        if (maker_category !== undefined && maker_category !== null) {
            queryParameters = queryParameters.set('maker_category', maker_category);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/maker", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.newSpecialOrderGet = function (gte_date, lte_date, special_order_goods_type, vendor_company_name, special_order_complete_status, special_order_no, special_order_goods_reciever_name, good_first_category_name, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling newSpecialOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling newSpecialOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (special_order_goods_type !== undefined && special_order_goods_type !== null) {
            queryParameters = queryParameters.set('special_order_goods_type', special_order_goods_type);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        if (special_order_complete_status !== undefined && special_order_complete_status !== null) {
            queryParameters = queryParameters.set('special_order_complete_status', special_order_complete_status);
        }
        if (special_order_no !== undefined && special_order_no !== null) {
            queryParameters = queryParameters.set('special_order_no', special_order_no);
        }
        if (special_order_goods_reciever_name !== undefined && special_order_goods_reciever_name !== null) {
            queryParameters = queryParameters.set('special_order_goods_reciever_name', special_order_goods_reciever_name);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/special_order_new", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.randomOrderGet = function (gte_date, lte_date, random_order_goods_shop_name, random_order_complete_status, random_order_type, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling randomOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling randomOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (random_order_goods_shop_name !== undefined && random_order_goods_shop_name !== null) {
            queryParameters = queryParameters.set('random_order_goods_shop_name', random_order_goods_shop_name);
        }
        if (random_order_complete_status !== undefined && random_order_complete_status !== null) {
            queryParameters = queryParameters.set('random_order_complete_status', random_order_complete_status);
        }
        if (random_order_type !== undefined && random_order_type !== null) {
            queryParameters = queryParameters.set('random_order_type', random_order_type);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/random_order", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.seqGet = function (id, type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling seqGet.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling seqGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', id);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/seq", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.setgoodGet = function (gte_date, lte_date, good_name, set_type, warehouse_name, item_issue_emp_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling setgoodGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling setgoodGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (set_type !== undefined && set_type !== null) {
            queryParameters = queryParameters.set('set_type', set_type);
        }
        if (warehouse_name !== undefined && warehouse_name !== null) {
            queryParameters = queryParameters.set('warehouse_name', warehouse_name);
        }
        if (item_issue_emp_name !== undefined && item_issue_emp_name !== null) {
            queryParameters = queryParameters.set('item_issue_emp_name', item_issue_emp_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/setgood", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.shopAutoOrderSchedulerGet = function (shop_id, good_name, shop_auto_order_scheduler_auto_flg, good_first_category_name, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (shop_auto_order_scheduler_auto_flg !== undefined && shop_auto_order_scheduler_auto_flg !== null) {
            queryParameters = queryParameters.set('shop_auto_order_scheduler_auto_flg', shop_auto_order_scheduler_auto_flg);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/shop_auto_order_scheduler", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.shopDeliveryOrderGet = function (period_type, gte_date, lte_date, shop_id, shop_name, good_first_category_name, shop_order_complete_status, shop_order_id, reciever_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (period_type === null || period_type === undefined) {
            throw new Error('Required parameter period_type was null or undefined when calling shopDeliveryOrderGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling shopDeliveryOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling shopDeliveryOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (period_type !== undefined && period_type !== null) {
            queryParameters = queryParameters.set('period_type', period_type);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (shop_order_complete_status !== undefined && shop_order_complete_status !== null) {
            queryParameters = queryParameters.set('shop_order_complete_status', shop_order_complete_status);
        }
        if (shop_order_id !== undefined && shop_order_id !== null) {
            queryParameters = queryParameters.set('shop_order_id', shop_order_id);
        }
        if (reciever_name !== undefined && reciever_name !== null) {
            queryParameters = queryParameters.set('reciever_name', reciever_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/delivery_order", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.shopOrderGet = function (period_type, gte_date, lte_date, shop_id, shop_name, good_first_category_name, shop_order_complete_status, shop_order_id, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (period_type === null || period_type === undefined) {
            throw new Error('Required parameter period_type was null or undefined when calling shopOrderGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling shopOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling shopOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (period_type !== undefined && period_type !== null) {
            queryParameters = queryParameters.set('period_type', period_type);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (shop_order_complete_status !== undefined && shop_order_complete_status !== null) {
            queryParameters = queryParameters.set('shop_order_complete_status', shop_order_complete_status);
        }
        if (shop_order_id !== undefined && shop_order_id !== null) {
            queryParameters = queryParameters.set('shop_order_id', shop_order_id);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/shop_order", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.specialOrderGet = function (gte_date, lte_date, special_order_goods_type, vendor_company_name, special_order_complete_status, special_order_no, special_order_goods_reciever_name, good_first_category_name, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling specialOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling specialOrderGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (special_order_goods_type !== undefined && special_order_goods_type !== null) {
            queryParameters = queryParameters.set('special_order_goods_type', special_order_goods_type);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        if (special_order_complete_status !== undefined && special_order_complete_status !== null) {
            queryParameters = queryParameters.set('special_order_complete_status', special_order_complete_status);
        }
        if (special_order_no !== undefined && special_order_no !== null) {
            queryParameters = queryParameters.set('special_order_no', special_order_no);
        }
        if (special_order_goods_reciever_name !== undefined && special_order_goods_reciever_name !== null) {
            queryParameters = queryParameters.set('special_order_goods_reciever_name', special_order_goods_reciever_name);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/special_order", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.specialSalesGet = function (gte_date, lte_date, vendor_company_name, good_name, order_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling specialSalesGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling specialSalesGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (order_type !== undefined && order_type !== null) {
            queryParameters = queryParameters.set('order_type', order_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/special_sales", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.updateJoinerInfoGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/update_joiner_info", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.updateJoinerInfoUseGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/update_joiner_info_use", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.vendorGet = function (gte_date, lte_date, vendor_company_name, vendor_deal_flg, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        if (vendor_deal_flg !== undefined && vendor_deal_flg !== null) {
            queryParameters = queryParameters.set('vendor_deal_flg', vendor_deal_flg);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/vendor", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGreenbusinessService.prototype.vendorInfoGet = function (vendor_company_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', vendor_company_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/greenbusiness/vendor_name", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsGreenbusinessService;
}());
export { EsGreenbusinessService };
