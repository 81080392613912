/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsJoinersService = /** @class */ (function () {
    function EsJoinersService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsJoinersService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsJoinersService.prototype.joinersCellpNoGet = function (joiner_cellp_no, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (joiner_cellp_no === null || joiner_cellp_no === undefined) {
            throw new Error('Required parameter joiner_cellp_no was null or undefined when calling joinersCellpNoGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_cellp_no !== undefined && joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('joiner_cellp_no', joiner_cellp_no);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/cellp_no", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersGet = function (joiner_name, joiner_addr, joiner_phone, joiner_private_no, joiner_div, is_giving_angel, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', joiner_name);
        }
        if (joiner_addr !== undefined && joiner_addr !== null) {
            queryParameters = queryParameters.set('joiner_addr', joiner_addr);
        }
        if (joiner_phone !== undefined && joiner_phone !== null) {
            queryParameters = queryParameters.set('joiner_phone', joiner_phone);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', joiner_private_no);
        }
        if (joiner_div !== undefined && joiner_div !== null) {
            queryParameters = queryParameters.set('joiner_div', joiner_div);
        }
        if (is_giving_angel !== undefined && is_giving_angel !== null) {
            queryParameters = queryParameters.set('is_giving_angel', is_giving_angel);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersGivingsMarketingGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling joinersGivingsMarketingGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling joinersGivingsMarketingGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/givings/marketing", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersGivingsRecentGet = function (joiner_name, joiner_cellp_no, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (joiner_name === null || joiner_name === undefined) {
            throw new Error('Required parameter joiner_name was null or undefined when calling joinersGivingsRecentGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', joiner_name);
        }
        if (joiner_cellp_no !== undefined && joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('joiner_cellp_no', joiner_cellp_no);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/givings/recent", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersIdentificationAndNumberGet = function (joiner_name, joiner_private_no, joiner_cellp_no, joiner_phone_no, joiner_type, joiner_div, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', joiner_name);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', joiner_private_no);
        }
        if (joiner_cellp_no !== undefined && joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('joiner_cellp_no', joiner_cellp_no);
        }
        if (joiner_phone_no !== undefined && joiner_phone_no !== null) {
            queryParameters = queryParameters.set('joiner_phone_no', joiner_phone_no);
        }
        if (joiner_type !== undefined && joiner_type !== null) {
            queryParameters = queryParameters.set('joiner_type', joiner_type);
        }
        if (joiner_div !== undefined && joiner_div !== null) {
            queryParameters = queryParameters.set('joiner_div', joiner_div);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/identification_and_number", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersJoinerIdGivingsGet = function (joiner_id, give_status, include_del_flg, give_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (joiner_id === null || joiner_id === undefined) {
            throw new Error('Required parameter joiner_id was null or undefined when calling joinersJoinerIdGivingsGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (give_status !== undefined && give_status !== null) {
            queryParameters = queryParameters.set('give_status', give_status);
        }
        if (include_del_flg !== undefined && include_del_flg !== null) {
            queryParameters = queryParameters.set('include_del_flg', include_del_flg);
        }
        if (give_id !== undefined && give_id !== null) {
            queryParameters = queryParameters.set('give_id', give_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/" + encodeURIComponent(String(joiner_id)) + "/givings", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersJoinerIdPartnersWorklogGet = function (gte_date, lte_date, all_content, joiner_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (all_content !== undefined && all_content !== null) {
            queryParameters = queryParameters.set('all_content', all_content);
        }
        if (joiner_id !== undefined && joiner_id !== null) {
            queryParameters = queryParameters.set('joiner_id', joiner_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/partners/worklog", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersJoinerPhonePhoneGet = function (joiner_phone, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (joiner_phone === null || joiner_phone === undefined) {
            throw new Error('Required parameter joiner_phone was null or undefined when calling joinersJoinerPhonePhoneGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_phone !== undefined && joiner_phone !== null) {
            queryParameters = queryParameters.set('joiner_phone', joiner_phone);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/phone", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersJoinerPrivateNoPrivateGet = function (joiner_private_no, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (joiner_private_no === null || joiner_private_no === undefined) {
            throw new Error('Required parameter joiner_private_no was null or undefined when calling joinersJoinerPrivateNoPrivateGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', joiner_private_no);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/private", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersMarketingGet = function (lte_recent_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (lte_recent_date !== undefined && lte_recent_date !== null) {
            queryParameters = queryParameters.set('lte_recent_date', lte_recent_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/marketing", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersPartnersDuplicateGet = function (joiner_name, joiner_private_no, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', joiner_name);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', joiner_private_no);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/partners/duplicate", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersPartnersGet = function (joiner_name, joiner_issue_emp_dept_name, search_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', joiner_name);
        }
        if (joiner_issue_emp_dept_name !== undefined && joiner_issue_emp_dept_name !== null) {
            queryParameters = queryParameters.set('joiner_issue_emp_dept_name', joiner_issue_emp_dept_name);
        }
        if (search_type !== undefined && search_type !== null) {
            queryParameters = queryParameters.set('search_type', search_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/partners", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersPartnersMetaGet = function (joiner_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (joiner_name === null || joiner_name === undefined) {
            throw new Error('Required parameter joiner_name was null or undefined when calling joinersPartnersMetaGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', joiner_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/partners/meta", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsJoinersService.prototype.joinersRegionMarketingGet = function (gte_date, lte_date, state, city, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling joinersRegionMarketingGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling joinersRegionMarketingGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', state);
        }
        if (city !== undefined && city !== null) {
            queryParameters = queryParameters.set('city', city);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/joiners/region/marketing", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsJoinersService;
}());
export { EsJoinersService };
