<div class="section-basic">

    <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

        <div class="form-group m-form__group default-style datepicker-group">
            <label>기간</label>
            <div class="input-group">
                <input [matDatepicker]="start" (click)="start.open()" class="form-control m-input cursorpointer"
                    formControlName="gte_date" readonly>
                <mat-datepicker #start [dateRangePicker]="{startDate: start, endDate: end}"
                    (rangeComplete)="rangeComplete($event)"></mat-datepicker>
                <div class="input-group-append">
                    <span class="input-group-text">~</span>
                </div>
                <input [matDatepicker]="end" (click)="end.open()" class="form-control m-input cursorpointer"
                    formControlName="lte_date" readonly>
                <mat-datepicker #end [dateRangePicker]="{startDate: start, endDate: end}"
                    (rangeComplete)="rangeComplete($event)"></mat-datepicker>
            </div>

            <!-- <div class="input-group">
                <input [matDatepicker]="start" [max]="maxDate" (click)="start.open()" class="form-control m-input"
                    formControlName="gte_date" (dateChange)="onChangeStartDate()">
                <mat-datepicker #start></mat-datepicker>

                <button class="btn btn-secondary" type="button"></button>

                <mat-datepicker #end></mat-datepicker>
                <input [matDatepicker]="end" [min]="minDate" [max]="maxDate" (click)="end.open()"
                    class="form-control m-input" formControlName="lte_date" (dateChange)="onChangeEndDate()">
            </div> -->
        </div>

        <app-dept-auto-complete [initLoginDeptFlg]="true" (selected)="selectDept($event)"></app-dept-auto-complete>

        <!-- 기증처별 통계에서 사용 -->
        <!-- 익명기증통계에서는 숨김 -->
        <ng-container *ngIf="!isAnonymous">
            <div class="form-group m-form__group default-style form_select">
                <label>구분</label>
                <select class="form-control m-input" formControlName="give_route" (change)="search();">
                    <option value="">전체</option>
                    <option value="수거">수거</option>
                    <option value="택배">택배</option>
                    <option value="점기증">점기증</option>
                </select>
            </div>
        </ng-container>

        <div class="form-group m-form__group default-style">
            <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
        </div>

    </form>

</div>

<div class="result">
    <div class="section-basic">

        <div class="btns_right_align">
            <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
        </div>

        <div class="m--space-20"></div>

        <div class="statistics-mat-table" #TABLE>

            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>년월</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.date}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="department_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>국</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.department_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="team_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>팀</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.team_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="location_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>매장/부서명</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.location_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="give_route" *ngIf="!isAnonymous">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>구분</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.give_route}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="give_count">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>기부건수</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.give_count | currencyMask}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="give_unit_count">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>기부량</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.give_unit_count | currencyMask}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="의류">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>의류(점)</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{convertCategories('의류',  element?.categories) | currencyMask}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="잡화">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>잡화(점)</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{convertCategories('잡화', element?.categories) | currencyMask}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="도서음반">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>도서음반(점)</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{convertCategories('도서음반', element?.categories) | currencyMask}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="가전">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>가전(점)</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{convertCategories('가전', element?.categories) | currencyMask}}
                    </mat-cell>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>

    </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>
