import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, OnChanges, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { AuthService, UserProfile } from 'fecommon';
import { CodeService, DeptInfo } from 'src/app/restapi/be-angel';

@Component({
  selector: 'app-angel-dept-auto-complete',
  templateUrl: './angel-dept-auto-complete.component.html',
  styleUrls: ['./angel-dept-auto-complete.component.scss'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AngelDeptAutoCompleteComponent),
        multi: true
    }
]
})
export class AngelDeptAutoCompleteComponent implements OnInit {
  
  @Input() placeholder: string;
  @Input() initLoginDeptFlg: boolean;
  @Input() initValue: DeptInfo;
  @Input() readonly: boolean;
  @Output() selected = new EventEmitter<DeptInfo>();
  @ViewChild('el') el: ElementRef;

  control = new FormControl('');
  searchResults: Observable<Array<DeptInfo>>;
  selectedItem: DeptInfo;

  displayName = ((item: DeptInfo) => {
    if (this.initValue && this.initValue === this.control.value) {
      return this.initValue.dept_name;
    }
    return item ? item.dept_name : undefined;
  });

  constructor(
    private authService: AuthService,
    private codeService: CodeService
  ) { }
 
  ngOnInit() {
    this.placeholder = this.placeholder ? this.placeholder + ' 자동완성' : '';

    if (this.readonly) this.control.disable();

    this.searchResults = this.control.valueChanges.pipe(
      debounceTime(250),
      tap((value: string) => {
        if (!value && this.selectedItem) {
          this.selectedItem = null;
          this.selected.emit(null);
          this.propagateChange('');
        }
      }),
      distinctUntilChanged(),
      switchMap(inputText =>
        (typeof inputText != 'string' || inputText == null || inputText.trim() === '') ? of([]) :
          this.codeService.angelDeptGet('SEARCHTYPE', inputText).pipe(catchError(() => of([])))
      )
    );
  }

  ngOnDestroy() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initValue) {
      this.initValue = changes.initValue.currentValue;

      console.log('this.initValue:', this.initValue)
      if(Object.entries(this.initValue).length > 0) {
        setTimeout(() => {
          this.control.setValue(this.initValue);
          this.selectedItem = this.initValue;
        });
      } else {
          this.control.setValue(null);
          this.selectedItem = null;
          this.propagateChange('');
      }
    }

    if (changes.readonly) {
      this.readonly = changes.readonly.currentValue;
      if (this.readonly) {
        this.control.setValue('');
        this.control.disable();
      } else {
        this.control.enable();
      }
    }

    if (changes.initLoginDeptFlg) this.setLoginDeptInfo();
  }

  onAutoCompletionClosed() {
    if (!this.selectedItem) {
      this.control.setValue(null);
    } else {
      this.control.setValue(this.selectedItem);
      this.propagateChange(this.selectedItem.dept_name);
    }
  }

  setLoginDeptInfo() {
    this.authService.userSubject.subscribe((userProfile: UserProfile) => {
      console.log('userProfile:',userProfile);
      const deptInfo: DeptInfo = {
        dept_id: userProfile.emp_dept_id,
        dept_name: userProfile.emp_dept_name,
        full_dept_id: userProfile.full_dept_id,
        full_dept_name: userProfile.full_dept_name
      }
      this.control.setValue(deptInfo);
      this.onDeptSelected(deptInfo);
    });
  }

  onDeptSelected(value: DeptInfo) {
    if (!value) return;

    this.selectedItem = value;
    this.selected.emit(value);
  }

  writeValue(value: any): void {
    this.propagateChange(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  propagateChange = (_: any) => { }
}
