import { OnInit } from '@angular/core';
import { MatDatepicker, MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { take } from 'rxjs/operators';
import { dateFormat_Month, MONTH_FORMATS } from 'src/app/share/utils/common-utils';
import * as _moment from 'moment';
import { AlertDialogComponent, ConfirmDialogComponent } from 'fecommon';
var moment = _moment;
var ɵ0 = MONTH_FORMATS;
var RawDataAnonymousGivingsComponent = /** @class */ (function () {
    function RawDataAnonymousGivingsComponent(fb, esGivingsService, dialog) {
        this.fb = fb;
        this.esGivingsService = esGivingsService;
        this.dialog = dialog;
    }
    RawDataAnonymousGivingsComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    RawDataAnonymousGivingsComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            date: moment().add(-1, 'month'),
            dept_info: null
        });
    };
    Object.defineProperty(RawDataAnonymousGivingsComponent.prototype, "fc_date", {
        get: function () { return this.form.get('date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RawDataAnonymousGivingsComponent.prototype, "fc_deptInfo", {
        get: function () { return this.form.get('dept_info'); },
        enumerable: true,
        configurable: true
    });
    RawDataAnonymousGivingsComponent.prototype.chosenMonthHandler = function (normalizedMonth, datepicker) {
        this.fc_date.setValue(normalizedMonth);
        datepicker.close();
    };
    RawDataAnonymousGivingsComponent.prototype.selectDept = function (value) {
        if (!value || value == null) {
            this.fc_deptInfo.setValue(null);
        }
        else {
            this.fc_deptInfo.setValue(value);
        }
    };
    RawDataAnonymousGivingsComponent.prototype.download = function () {
        var _this = this;
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: '익명 기부건별 데이터',
                description: '다운로드 하시겠습니까?',
                okText: '확인',
                cancelText: '취소'
            }
        }).afterClosed().pipe(take(1)).subscribe(function (confirmed) {
            if (confirmed) {
                _this.givingsExcelGet();
            }
        });
    };
    RawDataAnonymousGivingsComponent.prototype.givingsExcelGet = function () {
        var _this = this;
        this.esGivingsService.givingsExcelGet(dateFormat_Month(this.fc_date.value), dateFormat_Month(this.fc_date.value), this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null, 'Y').pipe(take(1)).subscribe(function (res) {
            _this.dialog.open(AlertDialogComponent, {
                data: {
                    message: '이메일로 전송될 예정입니다.'
                }
            });
        }, function (err) { console.error(err); });
    };
    return RawDataAnonymousGivingsComponent;
}());
export { RawDataAnonymousGivingsComponent };
export { ɵ0 };
