import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService, UserProfile } from 'fecommon';
import { EsDeptInfo, EsDeptService } from 'src/app/restapi/be-esquery';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
    selector: 'app-dept-auto-complete',
    templateUrl: './dept-auto-complete.component.html',
    styleUrls: ['./dept-auto-complete.component.scss']
})
export class DeptAutoCompleteComponent implements OnInit, OnDestroy {

    @Input() label: string;
    @Input() initLoginDeptFlg: boolean;
    @Input() shopType: string;
    @Output() selected = new EventEmitter<EsDeptInfo>();

    control = new FormControl();
    searchResults: Observable<Array<EsDeptInfo>>;

    constructor(
        private authService: AuthService,
        private esDeptService: EsDeptService
    ) { }

    ngOnInit() {
        if (!this.label) this.label = '부서';

        if (this.initLoginDeptFlg) this.setLoginDeptInfo();

        if (!this.shopType) this.shopType = null;

        this.searchResults = this.control.valueChanges.pipe(
            debounceTime(500),
            tap((value: string) => {
                if (!value) {
                    this.selected.emit(null);
                }
            }),
            filter((value: any) => !!value && typeof value === 'string'),
            mergeMap((value: string) => {
                return this.esDeptService.deptInfoGet(value, (<any>this.shopType));
            }),
            takeUntil(componentDestroyed(this)),
        );
    }

    ngOnDestroy() { }

    setLoginDeptInfo() {
        this.authService.userSubject.subscribe((userProfile: UserProfile) => {
            const deptInfo: EsDeptInfo = {
                dept_id: userProfile.emp_dept_id,
                dept_name: userProfile.emp_dept_name
            }
            this.control.setValue(deptInfo);
            this.onDeptSelected(deptInfo);
        });
    }

    displayDeptName(dept: EsDeptInfo): string | undefined {
        return dept ? dept.dept_name : undefined;
    }

    onDeptSelected(value: EsDeptInfo) {
        if (!value) return;

        this.selected.emit(value);
    }
}
