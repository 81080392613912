<div class="section-basic">
    <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style">
      <label>활동지</label>
      <app-angel-dept-auto-complete [placeholder]="'활동지'" formControlName="search_act_dept_name">
      </app-angel-dept-auto-complete>
    </div>

      <div class="form-group m-form__group default-style form_select">
      <label>활동구분</label>
      <select class="form-control m-input" formControlName="act_div" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_div_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

    <div class="form-group m-form__group default-style form_select">
      <label>활동주기</label>
      <select class="form-control m-input" formControlName="act_period" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_period_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>
    
    <div class="form-group m-form__group default-style form_select">
      <label>직업</label>
      <select class="form-control m-input" formControlName="job" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of job_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

    <div class="form-group m-form__group default-style form_select">
      <label>성별</label>
      <select class="form-control m-input" formControlName="gender" (change)="search()">
        <option value="">전체</option>
        <option value="남">남</option>
        <option value="여">여</option>
      </select>
    </div>


        <div class="form-group m-form__group default-style">
            <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
        </div>

    </form>
</div>

<!-- 데이터 테이블 -->
<div class="result">
    <div class="section-basic mb-0 pb-2">
        <div class="btns_right_align">
            <button class="btn btn-sm btn-outline-primary" (click)="onClickExcelExport()"
                [disabled]="dataSource.data.length == 0">엑셀
                다운로드</button>
        </div>

        <div class="m--space-10"></div>

        <div class="statistics-mat-table" #TABLE>

            <mat-table class="unset-cursor-pointer" [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="department_name" sticky>
                    <mat-header-cell *matHeaderCellDef class="sticky-unset-border sticky"> 국
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="sticky-unset-border sticky"> {{element.department_name}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="team_name" sticky>
                    <mat-header-cell *matHeaderCellDef class="sticky-unset-border sticky"> 팀
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="sticky-unset-border sticky">
                        {{element.team_name}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="location_name" sticky>
                    <mat-header-cell *matHeaderCellDef class="sticky"> 매장
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.location_name}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="mon_mor">
                    <mat-header-cell *matHeaderCellDef> 오전 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mon_mor | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="mon_aft">
                    <mat-header-cell *matHeaderCellDef> 오후 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mon_aft | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="mon_din">
                    <mat-header-cell *matHeaderCellDef> 저녁 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mon_din | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tue_mor">
                    <mat-header-cell *matHeaderCellDef> 오전 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.tue_mor | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tue_aft">
                    <mat-header-cell *matHeaderCellDef> 오후 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.tue_aft | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tue_din">
                    <mat-header-cell *matHeaderCellDef> 저녁 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.tue_din | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="wed_mor">
                    <mat-header-cell *matHeaderCellDef> 오전 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.wed_mor | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="wed_aft">
                    <mat-header-cell *matHeaderCellDef> 오후 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.wed_aft | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="wed_din">
                    <mat-header-cell *matHeaderCellDef> 저녁 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.wed_din | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="thu_mor">
                    <mat-header-cell *matHeaderCellDef> 오전 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.thu_mor | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="thu_aft">
                    <mat-header-cell *matHeaderCellDef> 오후 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.thu_aft | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="thu_din">
                    <mat-header-cell *matHeaderCellDef> 저녁 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.thu_din | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="fri_mor">
                    <mat-header-cell *matHeaderCellDef> 오전 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.fri_mor | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="fri_aft">
                    <mat-header-cell *matHeaderCellDef> 오후 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.fri_aft | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="fri_din">
                    <mat-header-cell *matHeaderCellDef> 저녁 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.fri_din | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sat_mor">
                    <mat-header-cell *matHeaderCellDef> 오전 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.sat_mor | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sat_aft">
                    <mat-header-cell *matHeaderCellDef> 오후 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.sat_aft | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sat_din">
                    <mat-header-cell *matHeaderCellDef> 저녁 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.sat_din | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sun_mor">
                    <mat-header-cell *matHeaderCellDef> 오전 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.sun_mor | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sun_aft">
                    <mat-header-cell *matHeaderCellDef> 오후 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.sun_aft | currencyMask}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sun_din">
                    <mat-header-cell *matHeaderCellDef> 저녁 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.sun_din | currencyMask}}
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="group1" sticky>
                    <mat-header-cell *matHeaderCellDef class="header-group1"></mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="group2">
                    <mat-header-cell *matHeaderCellDef class="header-group2"> 월요일 </mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="group3">
                    <mat-header-cell *matHeaderCellDef class="header-group2"> 화요일 </mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="group4">
                    <mat-header-cell *matHeaderCellDef class="header-group2"> 수요일 </mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="group5">
                    <mat-header-cell *matHeaderCellDef class="header-group2"> 목요일 </mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="group6">
                    <mat-header-cell *matHeaderCellDef class="header-group2"> 금요일 </mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="group7">
                    <mat-header-cell *matHeaderCellDef class="header-group2"> 토요일 </mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="group8">
                    <mat-header-cell *matHeaderCellDef class="header-group3"> 일요일 </mat-header-cell>
                </ng-container>

                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef>
                        <div class="no-records">데이터가 존재하지 않습니다.</div>
                    </mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="['group1', 'group2', 'group3', 'group4', 'group5', 'group6', 'group7', 'group8']">
                </mat-header-row>
                <mat-header-row *matHeaderRowDef="displayedColumns">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'row-default':row,'row-team':row.location_name == '팀소계','row-department':row.team_name == '국소계'}">
                </mat-row>

                <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource.data.length == 0)}">
                </mat-footer-row>
            </mat-table>

        </div>

        <div class="m-separator m-separator--dashed"></div>
        </div>
</div>
