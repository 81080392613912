<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="search();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style">
      <label>기간</label>
      <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()"
        (dateChange)="chosenYearHandler($event.target.value, end)" formControlName="year" readonly>
      <mat-datepicker #end startView="multi-year" (yearSelected)="chosenYearHandler($event, end)"></mat-datepicker>
    </div>

    <div class="form-group m-form__group default-style">
      <label>지역1</label>
      <input class="form-control m-input" type="text" placeholder="예)서울,경기,경남,전남..." formControlName="areas">
    </div>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div class="statistics-mat-table" #TABLE>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef mat-sort-header>지역1</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.state}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef mat-sort-header>지역2</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.city}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_route">
          <mat-header-cell *matHeaderCellDef mat-sort-header>구분</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_route}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_count">
          <mat-header-cell *matHeaderCellDef mat-sort-header>누적<br />건수</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_count | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_unit_count">
          <mat-header-cell *matHeaderCellDef mat-sort-header>누적<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_unit_count | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="01c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>1월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('01', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="01uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>1월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('01', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="02c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>2월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('02', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="02uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>2월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('02', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="03c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>3월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('03', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="03uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>3월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('03', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="04c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>4월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('04', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="04uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>4월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('04', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="05c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>5월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('05', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="05uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>5월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('05', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="06c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>6월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('06', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="06uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>6월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('06', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="07c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>7월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('07', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="07uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>7월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('07', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="08c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>8월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('08', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="08uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>8월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('08', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="09c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>9월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('09', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="09uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>9월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('09', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="10c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>10월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('10', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="10uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>10월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('10', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="11c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>11월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('11', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="11uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>11월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('11', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="12c">
          <mat-header-cell *matHeaderCellDef mat-sort-header>12월<br />기증건</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('12', 'give_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="12uc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>12월<br />기증량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('12', 'give_unit_count', element) | currencyMask}}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>