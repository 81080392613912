/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsAutoCancel } from '../model/esAutoCancel';
import { EsAutoSchedulerInfo } from '../model/esAutoSchedulerInfo';
import { EsBstore } from '../model/esBstore';
import { EsEstmate } from '../model/esEstmate';
import { EsEstmateGood } from '../model/esEstmateGood';
import { EsExternalOrder } from '../model/esExternalOrder';
import { EsFailure } from '../model/esFailure';
import { EsGreenSale } from '../model/esGreenSale';
import { EsGreenSaleDetail } from '../model/esGreenSaleDetail';
import { EsGreenSaleHeader } from '../model/esGreenSaleHeader';
import { EsGreenSaleItem } from '../model/esGreenSaleItem';
import { EsGreenSaleTot } from '../model/esGreenSaleTot';
import { EsInternalOrder } from '../model/esInternalOrder';
import { EsLogisticsOrder } from '../model/esLogisticsOrder';
import { EsMaker } from '../model/esMaker';
import { EsRandomOrder } from '../model/esRandomOrder';
import { EsSetGoodInfo } from '../model/esSetGoodInfo';
import { EsShopOrder } from '../model/esShopOrder';
import { EsShopOrderAutoScheduler } from '../model/esShopOrderAutoScheduler';
import { EsShopOrderConfirm } from '../model/esShopOrderConfirm';
import { EsSpecialOrder } from '../model/esSpecialOrder';
import { EsVendor } from '../model/esVendor';
import { NEWEsSpecialOrder } from '../model/nEWEsSpecialOrder';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsGreenbusinessService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 자동주문 처리
     * 자동주문 주문서 생성
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public autoOrderGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public autoOrderGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public autoOrderGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public autoOrderGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/greenbusiness/auto_order`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자동주문 정보가져오기
     * 자동주문 구동정보가져오기
     * @param gte_date 시작일자(YYYY-MM-DD)
     * @param lte_date 종료일자(YYYY-MM-DD)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public autoSchedulerOnoffGet(gte_date?: string, lte_date?: string, observe?: 'body', reportProgress?: boolean): Observable<EsAutoSchedulerInfo>;
    public autoSchedulerOnoffGet(gte_date?: string, lte_date?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsAutoSchedulerInfo>>;
    public autoSchedulerOnoffGet(gte_date?: string, lte_date?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsAutoSchedulerInfo>>;
    public autoSchedulerOnoffGet(gte_date?: string, lte_date?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsAutoSchedulerInfo>(`${this.basePath}/greenbusiness/auto_scheduler_onoff`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 아름다운가게 검색
     * 아름다운가게 검색
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bstoreGet(observe?: 'body', reportProgress?: boolean): Observable<Array<EsBstore>>;
    public bstoreGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsBstore>>>;
    public bstoreGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsBstore>>>;
    public bstoreGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsBstore>>(`${this.basePath}/greenbusiness/bstore`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자동주문 기존내역 확인처리
     * 자동주문 주문서 기존내역유무체크
     * @param shop_id 매장아이디
     * @param good_code 상품코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkAutoOrderGet(shop_id: string, good_code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public checkAutoOrderGet(shop_id: string, good_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public checkAutoOrderGet(shop_id: string, good_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public checkAutoOrderGet(shop_id: string, good_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling checkAutoOrderGet.');
        }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling checkAutoOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/greenbusiness/check_auto_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 판매견적서 검색
     * 판매견적서 검색
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param estimate_title 견적제목(LIKE)
     * @param vendor_company_name 납품처명(LIKE)
     * @param good_name 상품명(LIKE)
     * @param estimate_state 진행상황
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estmateGet(gte_date: string, lte_date: string, estimate_title?: string, vendor_company_name?: string, good_name?: string, estimate_state?: '진행중' | '취소' | '완료', observe?: 'body', reportProgress?: boolean): Observable<Array<EsEstmate>>;
    public estmateGet(gte_date: string, lte_date: string, estimate_title?: string, vendor_company_name?: string, good_name?: string, estimate_state?: '진행중' | '취소' | '완료', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEstmate>>>;
    public estmateGet(gte_date: string, lte_date: string, estimate_title?: string, vendor_company_name?: string, good_name?: string, estimate_state?: '진행중' | '취소' | '완료', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEstmate>>>;
    public estmateGet(gte_date: string, lte_date: string, estimate_title?: string, vendor_company_name?: string, good_name?: string, estimate_state?: '진행중' | '취소' | '완료', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling estmateGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling estmateGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (estimate_title !== undefined && estimate_title !== null) {
            queryParameters = queryParameters.set('estimate_title', <any>estimate_title);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (estimate_state !== undefined && estimate_state !== null) {
            queryParameters = queryParameters.set('estimate_state', <any>estimate_state);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEstmate>>(`${this.basePath}/greenbusiness/estmate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 판매견적서 상품견적내역 검색
     * 판매견적서 검색
     * @param good_code 상품코드
     * @param vendor_id 납품아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estmateGoodListGet(good_code: string, vendor_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsEstmateGood>>;
    public estmateGoodListGet(good_code: string, vendor_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEstmateGood>>>;
    public estmateGoodListGet(good_code: string, vendor_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEstmateGood>>>;
    public estmateGoodListGet(good_code: string, vendor_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling estmateGoodListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }
        if (vendor_id !== undefined && vendor_id !== null) {
            queryParameters = queryParameters.set('vendor_id', <any>vendor_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEstmateGood>>(`${this.basePath}/greenbusiness/estmate_good_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 주문서-외부관리 검색(등록일, 납품처명, 상태현황, 거래유형)
     * 주문서-외부관리 등
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param vendor_company_name 납품처명
     * @param external_order_complete_status 상태현황
     * @param external_order_trade_category 거래유형
     * @param good_first_category_name 물류구분
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public externalOrderGet(gte_date: string, lte_date: string, vendor_company_name?: string, external_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '입금완료' | '주문취소', external_order_trade_category?: '일반' | '위탁', good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsExternalOrder>>;
    public externalOrderGet(gte_date: string, lte_date: string, vendor_company_name?: string, external_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '입금완료' | '주문취소', external_order_trade_category?: '일반' | '위탁', good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsExternalOrder>>>;
    public externalOrderGet(gte_date: string, lte_date: string, vendor_company_name?: string, external_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '입금완료' | '주문취소', external_order_trade_category?: '일반' | '위탁', good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsExternalOrder>>>;
    public externalOrderGet(gte_date: string, lte_date: string, vendor_company_name?: string, external_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '입금완료' | '주문취소', external_order_trade_category?: '일반' | '위탁', good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling externalOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling externalOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }
        if (external_order_complete_status !== undefined && external_order_complete_status !== null) {
            queryParameters = queryParameters.set('external_order_complete_status', <any>external_order_complete_status);
        }
        if (external_order_trade_category !== undefined && external_order_trade_category !== null) {
            queryParameters = queryParameters.set('external_order_trade_category', <any>external_order_trade_category);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsExternalOrder>>(`${this.basePath}/greenbusiness/external_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매출및 수불 마감일자 체크
     * 매출및 수불 마감일자 체크
     * @param chk_date 체크일자(YYYY-MM-DD)
     * @param chk_type 체크타입(매출/수불)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenDeadlineGet(chk_date: string, chk_type: '매출' | '수불', observe?: 'body', reportProgress?: boolean): Observable<any>;
    public greenDeadlineGet(chk_date: string, chk_type: '매출' | '수불', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public greenDeadlineGet(chk_date: string, chk_type: '매출' | '수불', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public greenDeadlineGet(chk_date: string, chk_type: '매출' | '수불', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (chk_date === null || chk_date === undefined) {
            throw new Error('Required parameter chk_date was null or undefined when calling greenDeadlineGet.');
        }
        if (chk_type === null || chk_type === undefined) {
            throw new Error('Required parameter chk_type was null or undefined when calling greenDeadlineGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chk_date !== undefined && chk_date !== null) {
            queryParameters = queryParameters.set('chk_date', <any>chk_date);
        }
        if (chk_type !== undefined && chk_type !== null) {
            queryParameters = queryParameters.set('chk_type', <any>chk_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/greenbusiness/deadline`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매출 마감일자 체크
     * 매출 마감일자 체크
     * @param chk_type 체크타입(매출/수불)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenDeadlinemonGet(chk_type: '매출' | '수불', observe?: 'body', reportProgress?: boolean): Observable<any>;
    public greenDeadlinemonGet(chk_type: '매출' | '수불', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public greenDeadlinemonGet(chk_type: '매출' | '수불', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public greenDeadlinemonGet(chk_type: '매출' | '수불', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (chk_type === null || chk_type === undefined) {
            throw new Error('Required parameter chk_type was null or undefined when calling greenDeadlinemonGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chk_type !== undefined && chk_type !== null) {
            queryParameters = queryParameters.set('chk_type', <any>chk_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/greenbusiness/deadlinemon`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린사업정보조회디테일내용
     * 그린매출(전체)정보조회디테일내용
     * @param sale_date 매출일자(YYYY-MM-DD)
     * @param vendor_id 납품처코드
     * @param area_type 매출구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenSalesDetailGet(sale_date: string, vendor_id: string, area_type: '온라인' | 'B2B특판' | '매장', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenSaleDetail>>;
    public greenSalesDetailGet(sale_date: string, vendor_id: string, area_type: '온라인' | 'B2B특판' | '매장', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenSaleDetail>>>;
    public greenSalesDetailGet(sale_date: string, vendor_id: string, area_type: '온라인' | 'B2B특판' | '매장', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenSaleDetail>>>;
    public greenSalesDetailGet(sale_date: string, vendor_id: string, area_type: '온라인' | 'B2B특판' | '매장', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (sale_date === null || sale_date === undefined) {
            throw new Error('Required parameter sale_date was null or undefined when calling greenSalesDetailGet.');
        }
        if (vendor_id === null || vendor_id === undefined) {
            throw new Error('Required parameter vendor_id was null or undefined when calling greenSalesDetailGet.');
        }
        if (area_type === null || area_type === undefined) {
            throw new Error('Required parameter area_type was null or undefined when calling greenSalesDetailGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sale_date !== undefined && sale_date !== null) {
            queryParameters = queryParameters.set('sale_date', <any>sale_date);
        }
        if (vendor_id !== undefined && vendor_id !== null) {
            queryParameters = queryParameters.set('vendor_id', <any>vendor_id);
        }
        if (area_type !== undefined && area_type !== null) {
            queryParameters = queryParameters.set('area_type', <any>area_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenSaleDetail>>(`${this.basePath}/greenbusiness/green_sales_detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린사업정보조회
     * 그린매출(전체)정보조회
     * @param gte_date 시작일자(YYYY-MM-DD)
     * @param lte_date 종료일자(YYYY-MM-DD)
     * @param vendor_company_name 납품처명
     * @param area_type 매출구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenSalesHeaderGet(gte_date: string, lte_date: string, vendor_company_name?: string, area_type?: '온라인' | 'B2B특판' | '매장', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenSaleHeader>>;
    public greenSalesHeaderGet(gte_date: string, lte_date: string, vendor_company_name?: string, area_type?: '온라인' | 'B2B특판' | '매장', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenSaleHeader>>>;
    public greenSalesHeaderGet(gte_date: string, lte_date: string, vendor_company_name?: string, area_type?: '온라인' | 'B2B특판' | '매장', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenSaleHeader>>>;
    public greenSalesHeaderGet(gte_date: string, lte_date: string, vendor_company_name?: string, area_type?: '온라인' | 'B2B특판' | '매장', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling greenSalesHeaderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling greenSalesHeaderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }
        if (area_type !== undefined && area_type !== null) {
            queryParameters = queryParameters.set('area_type', <any>area_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenSaleHeader>>(`${this.basePath}/greenbusiness/green_sales_header`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린사업정보조회상품별내용
     * 그린매출(상품별)정보조회
     * @param gte_date 시작일자(YYYY-MM-DD)
     * @param lte_date 종료일자(YYYY-MM-DD)
     * @param vendor_company_name 납품처명
     * @param area_type 매출구분
     * @param good_type 상품구분상위
     * @param good_second 상품구분하위
     * @param good_name 상품명
     * @param tag_name 태그명칭
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenSalesItemGet(gte_date: string, lte_date: string, vendor_company_name?: string, area_type?: '온라인' | 'B2B특판' | '매장', good_type?: '공익상품' | '메아리상품', good_second?: string, good_name?: string, tag_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenSaleItem>>;
    public greenSalesItemGet(gte_date: string, lte_date: string, vendor_company_name?: string, area_type?: '온라인' | 'B2B특판' | '매장', good_type?: '공익상품' | '메아리상품', good_second?: string, good_name?: string, tag_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenSaleItem>>>;
    public greenSalesItemGet(gte_date: string, lte_date: string, vendor_company_name?: string, area_type?: '온라인' | 'B2B특판' | '매장', good_type?: '공익상품' | '메아리상품', good_second?: string, good_name?: string, tag_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenSaleItem>>>;
    public greenSalesItemGet(gte_date: string, lte_date: string, vendor_company_name?: string, area_type?: '온라인' | 'B2B특판' | '매장', good_type?: '공익상품' | '메아리상품', good_second?: string, good_name?: string, tag_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling greenSalesItemGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling greenSalesItemGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }
        if (area_type !== undefined && area_type !== null) {
            queryParameters = queryParameters.set('area_type', <any>area_type);
        }
        if (good_type !== undefined && good_type !== null) {
            queryParameters = queryParameters.set('good_type', <any>good_type);
        }
        if (good_second !== undefined && good_second !== null) {
            queryParameters = queryParameters.set('good_second', <any>good_second);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (tag_name !== undefined && tag_name !== null) {
            queryParameters = queryParameters.set('tag_name', <any>tag_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenSaleItem>>(`${this.basePath}/greenbusiness/green_sales_item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 전사매출정보 조회
     * 전사매출정보 조회
     * @param gte_date 시작일자(YYYY-MM-DD)
     * @param lte_date 종료일자(YYYY-MM-DD)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenSalesTotGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<EsGreenSaleTot>;
    public greenSalesTotGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsGreenSaleTot>>;
    public greenSalesTotGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsGreenSaleTot>>;
    public greenSalesTotGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling greenSalesTotGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling greenSalesTotGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsGreenSaleTot>(`${this.basePath}/greenbusiness/green_sales_tot`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자동주문 취소일 검색
     * 발주서 조회
     * @param cancel_date 주문취소월(YYYY-MM)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenbusinessAutoOrderCancelGet(cancel_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsAutoCancel>>;
    public greenbusinessAutoOrderCancelGet(cancel_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsAutoCancel>>>;
    public greenbusinessAutoOrderCancelGet(cancel_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsAutoCancel>>>;
    public greenbusinessAutoOrderCancelGet(cancel_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (cancel_date === null || cancel_date === undefined) {
            throw new Error('Required parameter cancel_date was null or undefined when calling greenbusinessAutoOrderCancelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cancel_date !== undefined && cancel_date !== null) {
            queryParameters = queryParameters.set('cancel_date', <any>cancel_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsAutoCancel>>(`${this.basePath}/greenbusiness/auto_order_cancel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 발주서-검색(등록일, 생산자명, 입고창고, 발주번호, 상태현황)
     * 발주서 조회
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param search_type 조회타입
     * @param maker_name 생산자명
     * @param warehouse_id 입고창고 아이디
     * @param logistics_order_id 발주번호
     * @param logistics_order_status 상태현황
     * @param page_number 
     * @param logistics_order_type 발주서 구분
     * @param logistics_order_reciever_name 수취인
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenbusinessLogisticsOrderGet(gte_date: string, lte_date: string, search_type: '발주' | '입고', maker_name?: string, warehouse_id?: string, logistics_order_id?: string, logistics_order_status?: '입고대기' | '입고완료' | '부분입고' | '발주취소', page_number?: string, logistics_order_type?: '일반발주' | '매장직송발주' | '고객직송발주', logistics_order_reciever_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsLogisticsOrder>>;
    public greenbusinessLogisticsOrderGet(gte_date: string, lte_date: string, search_type: '발주' | '입고', maker_name?: string, warehouse_id?: string, logistics_order_id?: string, logistics_order_status?: '입고대기' | '입고완료' | '부분입고' | '발주취소', page_number?: string, logistics_order_type?: '일반발주' | '매장직송발주' | '고객직송발주', logistics_order_reciever_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsLogisticsOrder>>>;
    public greenbusinessLogisticsOrderGet(gte_date: string, lte_date: string, search_type: '발주' | '입고', maker_name?: string, warehouse_id?: string, logistics_order_id?: string, logistics_order_status?: '입고대기' | '입고완료' | '부분입고' | '발주취소', page_number?: string, logistics_order_type?: '일반발주' | '매장직송발주' | '고객직송발주', logistics_order_reciever_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsLogisticsOrder>>>;
    public greenbusinessLogisticsOrderGet(gte_date: string, lte_date: string, search_type: '발주' | '입고', maker_name?: string, warehouse_id?: string, logistics_order_id?: string, logistics_order_status?: '입고대기' | '입고완료' | '부분입고' | '발주취소', page_number?: string, logistics_order_type?: '일반발주' | '매장직송발주' | '고객직송발주', logistics_order_reciever_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling greenbusinessLogisticsOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling greenbusinessLogisticsOrderGet.');
        }
        if (search_type === null || search_type === undefined) {
            throw new Error('Required parameter search_type was null or undefined when calling greenbusinessLogisticsOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', <any>maker_name);
        }
        if (warehouse_id !== undefined && warehouse_id !== null) {
            queryParameters = queryParameters.set('warehouse_id', <any>warehouse_id);
        }
        if (logistics_order_id !== undefined && logistics_order_id !== null) {
            queryParameters = queryParameters.set('logistics_order_id', <any>logistics_order_id);
        }
        if (logistics_order_status !== undefined && logistics_order_status !== null) {
            queryParameters = queryParameters.set('logistics_order_status', <any>logistics_order_status);
        }
        if (search_type !== undefined && search_type !== null) {
            queryParameters = queryParameters.set('search_type', <any>search_type);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }
        if (logistics_order_type !== undefined && logistics_order_type !== null) {
            queryParameters = queryParameters.set('logistics_order_type', <any>logistics_order_type);
        }
        if (logistics_order_reciever_name !== undefined && logistics_order_reciever_name !== null) {
            queryParameters = queryParameters.set('logistics_order_reciever_name', <any>logistics_order_reciever_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsLogisticsOrder>>(`${this.basePath}/greenbusiness/logistics_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입점생산자 Like검색 완성
     * 입점생산자 이름으로 Like 검색 기능 제공 (앞단어 일치 여부로 판단)
     * @param maker_name 입점생산자 명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenbusinessMakerMetaGet(maker_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsMaker>>;
    public greenbusinessMakerMetaGet(maker_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsMaker>>>;
    public greenbusinessMakerMetaGet(maker_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsMaker>>>;
    public greenbusinessMakerMetaGet(maker_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', <any>maker_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsMaker>>(`${this.basePath}/greenbusiness/maker/meta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 주문 입고 확인 대상 목록 검색
     * 매장별 주문 입고 확인 대상 목록 검색
     * @param shop_id 
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenbusinessShopOrderConfirmGet(shop_id: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopOrderConfirm>>;
    public greenbusinessShopOrderConfirmGet(shop_id: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopOrderConfirm>>>;
    public greenbusinessShopOrderConfirmGet(shop_id: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopOrderConfirm>>>;
    public greenbusinessShopOrderConfirmGet(shop_id: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling greenbusinessShopOrderConfirmGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopOrderConfirm>>(`${this.basePath}/greenbusiness/shop_order/confirm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 외부납품처관리 Like검색 완성
     * 납품처검색 및 선택을 위해 Like검색 기능 제공
     * @param vendor_company_name 외부납품처명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenbusinessVendorMetaGet(vendor_company_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsVendor>>;
    public greenbusinessVendorMetaGet(vendor_company_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsVendor>>>;
    public greenbusinessVendorMetaGet(vendor_company_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsVendor>>>;
    public greenbusinessVendorMetaGet(vendor_company_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsVendor>>(`${this.basePath}/greenbusiness/vendor/meta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 주문서-내부관리 검색(등록일, 매장/부서명, 상태현황)
     * 주문서-내부관리 등
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param shop_name_or_dept_name 매장_부서명명
     * @param internal_order_complete_status 상태현황
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalOrderGet(gte_date: string, lte_date: string, shop_name_or_dept_name?: string, internal_order_complete_status?: '출고요청' | '주문확인' | '출고완료', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsInternalOrder>>;
    public internalOrderGet(gte_date: string, lte_date: string, shop_name_or_dept_name?: string, internal_order_complete_status?: '출고요청' | '주문확인' | '출고완료', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsInternalOrder>>>;
    public internalOrderGet(gte_date: string, lte_date: string, shop_name_or_dept_name?: string, internal_order_complete_status?: '출고요청' | '주문확인' | '출고완료', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsInternalOrder>>>;
    public internalOrderGet(gte_date: string, lte_date: string, shop_name_or_dept_name?: string, internal_order_complete_status?: '출고요청' | '주문확인' | '출고완료', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling internalOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling internalOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (shop_name_or_dept_name !== undefined && shop_name_or_dept_name !== null) {
            queryParameters = queryParameters.set('shop_name_or_dept_name', <any>shop_name_or_dept_name);
        }
        if (internal_order_complete_status !== undefined && internal_order_complete_status !== null) {
            queryParameters = queryParameters.set('internal_order_complete_status', <any>internal_order_complete_status);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsInternalOrder>>(`${this.basePath}/greenbusiness/internal_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린 입고 엑셀다운로드(임시급)
     * 
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsOrderExcelGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public logisticsOrderExcelGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public logisticsOrderExcelGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public logisticsOrderExcelGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsOrderExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsOrderExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/logistics/order/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입점생산자관리 검색(등록일, 생산자명, 생산자상태, 생산자유형)
     * 입점생산자관리 등
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param maker_name 생산자명
     * @param maker_status 생산자상태
     * @param maker_category 생산자유형
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public makerGet(gte_date?: string, lte_date?: string, maker_name?: string, maker_status?: '입점심사' | '입점완료' | '입점취소', maker_category?: '기업' | '사회적기업' | '예비사회적기업' | '영농조합' | '협동조합' | '친환경' | '장애인복지직업재활' | '공정무역' | '장애인복지시설', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsMaker>>;
    public makerGet(gte_date?: string, lte_date?: string, maker_name?: string, maker_status?: '입점심사' | '입점완료' | '입점취소', maker_category?: '기업' | '사회적기업' | '예비사회적기업' | '영농조합' | '협동조합' | '친환경' | '장애인복지직업재활' | '공정무역' | '장애인복지시설', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsMaker>>>;
    public makerGet(gte_date?: string, lte_date?: string, maker_name?: string, maker_status?: '입점심사' | '입점완료' | '입점취소', maker_category?: '기업' | '사회적기업' | '예비사회적기업' | '영농조합' | '협동조합' | '친환경' | '장애인복지직업재활' | '공정무역' | '장애인복지시설', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsMaker>>>;
    public makerGet(gte_date?: string, lte_date?: string, maker_name?: string, maker_status?: '입점심사' | '입점완료' | '입점취소', maker_category?: '기업' | '사회적기업' | '예비사회적기업' | '영농조합' | '협동조합' | '친환경' | '장애인복지직업재활' | '공정무역' | '장애인복지시설', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', <any>maker_name);
        }
        if (maker_status !== undefined && maker_status !== null) {
            queryParameters = queryParameters.set('maker_status', <any>maker_status);
        }
        if (maker_category !== undefined && maker_category !== null) {
            queryParameters = queryParameters.set('maker_category', <any>maker_category);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsMaker>>(`${this.basePath}/greenbusiness/maker`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 주문서-특판관리 검색(등록일, 주문유형, 특판처명, 상태현황, 주문번호, 수취인, 배송방식)
     * 주문서-특판관리 등
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param special_order_goods_type 주문유형
     * @param vendor_company_name 특판처명
     * @param special_order_complete_status 상태현황
     * @param special_order_no 주문번호
     * @param special_order_goods_reciever_name 수취인
     * @param good_first_category_name 배송방식
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public newSpecialOrderGet(gte_date: string, lte_date: string, special_order_goods_type?: '주문' | '반품', vendor_company_name?: string, special_order_complete_status?: '출고요청' | '출고완료', special_order_no?: string, special_order_goods_reciever_name?: string, good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<NEWEsSpecialOrder>>;
    public newSpecialOrderGet(gte_date: string, lte_date: string, special_order_goods_type?: '주문' | '반품', vendor_company_name?: string, special_order_complete_status?: '출고요청' | '출고완료', special_order_no?: string, special_order_goods_reciever_name?: string, good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NEWEsSpecialOrder>>>;
    public newSpecialOrderGet(gte_date: string, lte_date: string, special_order_goods_type?: '주문' | '반품', vendor_company_name?: string, special_order_complete_status?: '출고요청' | '출고완료', special_order_no?: string, special_order_goods_reciever_name?: string, good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NEWEsSpecialOrder>>>;
    public newSpecialOrderGet(gte_date: string, lte_date: string, special_order_goods_type?: '주문' | '반품', vendor_company_name?: string, special_order_complete_status?: '출고요청' | '출고완료', special_order_no?: string, special_order_goods_reciever_name?: string, good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling newSpecialOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling newSpecialOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (special_order_goods_type !== undefined && special_order_goods_type !== null) {
            queryParameters = queryParameters.set('special_order_goods_type', <any>special_order_goods_type);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }
        if (special_order_complete_status !== undefined && special_order_complete_status !== null) {
            queryParameters = queryParameters.set('special_order_complete_status', <any>special_order_complete_status);
        }
        if (special_order_no !== undefined && special_order_no !== null) {
            queryParameters = queryParameters.set('special_order_no', <any>special_order_no);
        }
        if (special_order_goods_reciever_name !== undefined && special_order_goods_reciever_name !== null) {
            queryParameters = queryParameters.set('special_order_goods_reciever_name', <any>special_order_goods_reciever_name);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<NEWEsSpecialOrder>>(`${this.basePath}/greenbusiness/special_order_new`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 주문서-임의관리 검색(등록일, 매장명, 상태현황)
     * 주문서-외부관리 등
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param random_order_goods_shop_name 매장명
     * @param random_order_complete_status 상태현황
     * @param random_order_type 주문유형
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public randomOrderGet(gte_date: string, lte_date: string, random_order_goods_shop_name?: string, random_order_complete_status?: '출고요청' | '출고완료' | '매장확인', random_order_type?: '주문' | '출고', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsRandomOrder>>;
    public randomOrderGet(gte_date: string, lte_date: string, random_order_goods_shop_name?: string, random_order_complete_status?: '출고요청' | '출고완료' | '매장확인', random_order_type?: '주문' | '출고', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsRandomOrder>>>;
    public randomOrderGet(gte_date: string, lte_date: string, random_order_goods_shop_name?: string, random_order_complete_status?: '출고요청' | '출고완료' | '매장확인', random_order_type?: '주문' | '출고', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsRandomOrder>>>;
    public randomOrderGet(gte_date: string, lte_date: string, random_order_goods_shop_name?: string, random_order_complete_status?: '출고요청' | '출고완료' | '매장확인', random_order_type?: '주문' | '출고', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling randomOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling randomOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (random_order_goods_shop_name !== undefined && random_order_goods_shop_name !== null) {
            queryParameters = queryParameters.set('random_order_goods_shop_name', <any>random_order_goods_shop_name);
        }
        if (random_order_complete_status !== undefined && random_order_complete_status !== null) {
            queryParameters = queryParameters.set('random_order_complete_status', <any>random_order_complete_status);
        }
        if (random_order_type !== undefined && random_order_type !== null) {
            queryParameters = queryParameters.set('random_order_type', <any>random_order_type);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsRandomOrder>>(`${this.basePath}/greenbusiness/random_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 각 메뉴별 날짜별 seq 의 최대 추출
     * 각 메뉴별 날짜별 seq 의 최대을 추출하여 신규 PK 생성에 활용
     * @param id 특정 메뉴의 DB Item 별 PK
     * @param type 어떤 메뉴인지 알게 해주는 데이터. ex) MAKER, SPECIALORDER 등 (sk와 동일)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public seqGet(id: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public seqGet(id: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public seqGet(id: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public seqGet(id: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling seqGet.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling seqGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<number>(`${this.basePath}/greenbusiness/seq`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 세트상품구성 이력정보가져오기
     * 세트상품구성 이력정보가져오기
     * @param gte_date 시작일자(YYYY-MM-DD)
     * @param lte_date 종료일자(YYYY-MM-DD)
     * @param good_name 세트상품명
     * @param set_type 구성해체구분
     * @param warehouse_name 창고명
     * @param item_issue_emp_name 등록자
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setgoodGet(gte_date: string, lte_date: string, good_name?: string, set_type?: '구성' | '해체', warehouse_name?: string, item_issue_emp_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsSetGoodInfo>>;
    public setgoodGet(gte_date: string, lte_date: string, good_name?: string, set_type?: '구성' | '해체', warehouse_name?: string, item_issue_emp_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsSetGoodInfo>>>;
    public setgoodGet(gte_date: string, lte_date: string, good_name?: string, set_type?: '구성' | '해체', warehouse_name?: string, item_issue_emp_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsSetGoodInfo>>>;
    public setgoodGet(gte_date: string, lte_date: string, good_name?: string, set_type?: '구성' | '해체', warehouse_name?: string, item_issue_emp_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling setgoodGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling setgoodGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (set_type !== undefined && set_type !== null) {
            queryParameters = queryParameters.set('set_type', <any>set_type);
        }
        if (warehouse_name !== undefined && warehouse_name !== null) {
            queryParameters = queryParameters.set('warehouse_name', <any>warehouse_name);
        }
        if (item_issue_emp_name !== undefined && item_issue_emp_name !== null) {
            queryParameters = queryParameters.set('item_issue_emp_name', <any>item_issue_emp_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsSetGoodInfo>>(`${this.basePath}/greenbusiness/setgood`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장자동주문 스케쥴러 목록 검색(매장명, 상품명, 스케쥴러 ON_OFF 상태, 주문구분(공익상품_메아리상품))
     * 매장자동주문 스케쥴러 목록 등
     * @param shop_id 매장코드
     * @param good_name 상품명
     * @param shop_auto_order_scheduler_auto_flg 스케쥴러 ON_OFF 상태
     * @param good_first_category_name 주문구분(공익상품_메아리상품)
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopAutoOrderSchedulerGet(shop_id?: string, good_name?: string, shop_auto_order_scheduler_auto_flg?: 'ON' | 'OFF', good_first_category_name?: '공익상품' | '메아리상품', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopOrderAutoScheduler>>;
    public shopAutoOrderSchedulerGet(shop_id?: string, good_name?: string, shop_auto_order_scheduler_auto_flg?: 'ON' | 'OFF', good_first_category_name?: '공익상품' | '메아리상품', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopOrderAutoScheduler>>>;
    public shopAutoOrderSchedulerGet(shop_id?: string, good_name?: string, shop_auto_order_scheduler_auto_flg?: 'ON' | 'OFF', good_first_category_name?: '공익상품' | '메아리상품', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopOrderAutoScheduler>>>;
    public shopAutoOrderSchedulerGet(shop_id?: string, good_name?: string, shop_auto_order_scheduler_auto_flg?: 'ON' | 'OFF', good_first_category_name?: '공익상품' | '메아리상품', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (shop_auto_order_scheduler_auto_flg !== undefined && shop_auto_order_scheduler_auto_flg !== null) {
            queryParameters = queryParameters.set('shop_auto_order_scheduler_auto_flg', <any>shop_auto_order_scheduler_auto_flg);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopOrderAutoScheduler>>(`${this.basePath}/greenbusiness/shop_auto_order_scheduler`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 택배주문 목록 검색
     * 택배주문 목록 등
     * @param period_type 기간타입(주문일자_출고일자)
     * @param gte_date 
     * @param lte_date 
     * @param shop_id 
     * @param shop_name 
     * @param good_first_category_name 주문구분(공익상품_메아리상품)
     * @param shop_order_complete_status 상태현황
     * @param shop_order_id 주문번호
     * @param reciever_name 수취인
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopDeliveryOrderGet(period_type: '주문일자' | '출고일자', gte_date: string, lte_date: string, shop_id?: string, shop_name?: string, good_first_category_name?: '공익상품' | '메아리상품', shop_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '매장확인' | '주문취소', shop_order_id?: string, reciever_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopOrder>>;
    public shopDeliveryOrderGet(period_type: '주문일자' | '출고일자', gte_date: string, lte_date: string, shop_id?: string, shop_name?: string, good_first_category_name?: '공익상품' | '메아리상품', shop_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '매장확인' | '주문취소', shop_order_id?: string, reciever_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopOrder>>>;
    public shopDeliveryOrderGet(period_type: '주문일자' | '출고일자', gte_date: string, lte_date: string, shop_id?: string, shop_name?: string, good_first_category_name?: '공익상품' | '메아리상품', shop_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '매장확인' | '주문취소', shop_order_id?: string, reciever_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopOrder>>>;
    public shopDeliveryOrderGet(period_type: '주문일자' | '출고일자', gte_date: string, lte_date: string, shop_id?: string, shop_name?: string, good_first_category_name?: '공익상품' | '메아리상품', shop_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '매장확인' | '주문취소', shop_order_id?: string, reciever_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (period_type === null || period_type === undefined) {
            throw new Error('Required parameter period_type was null or undefined when calling shopDeliveryOrderGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling shopDeliveryOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling shopDeliveryOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (period_type !== undefined && period_type !== null) {
            queryParameters = queryParameters.set('period_type', <any>period_type);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (shop_order_complete_status !== undefined && shop_order_complete_status !== null) {
            queryParameters = queryParameters.set('shop_order_complete_status', <any>shop_order_complete_status);
        }
        if (shop_order_id !== undefined && shop_order_id !== null) {
            queryParameters = queryParameters.set('shop_order_id', <any>shop_order_id);
        }
        if (reciever_name !== undefined && reciever_name !== null) {
            queryParameters = queryParameters.set('reciever_name', <any>reciever_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopOrder>>(`${this.basePath}/greenbusiness/delivery_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 그린상품주문 목록 검색(기간타입(주문일자_출고일자), 기간, 주문구분(공익상품_메아리상품))
     * 그린상품주문 주문 목록 등
     * @param period_type 기간타입(주문일자_출고일자)
     * @param gte_date 
     * @param lte_date 
     * @param shop_id 
     * @param shop_name 
     * @param good_first_category_name 주문구분(공익상품_메아리상품)
     * @param shop_order_complete_status 상태현황
     * @param shop_order_id 주문번호
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopOrderGet(period_type: '주문일자' | '출고일자', gte_date: string, lte_date: string, shop_id?: string, shop_name?: string, good_first_category_name?: '공익상품' | '메아리상품', shop_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '매장확인' | '주문취소', shop_order_id?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopOrder>>;
    public shopOrderGet(period_type: '주문일자' | '출고일자', gte_date: string, lte_date: string, shop_id?: string, shop_name?: string, good_first_category_name?: '공익상품' | '메아리상품', shop_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '매장확인' | '주문취소', shop_order_id?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopOrder>>>;
    public shopOrderGet(period_type: '주문일자' | '출고일자', gte_date: string, lte_date: string, shop_id?: string, shop_name?: string, good_first_category_name?: '공익상품' | '메아리상품', shop_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '매장확인' | '주문취소', shop_order_id?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopOrder>>>;
    public shopOrderGet(period_type: '주문일자' | '출고일자', gte_date: string, lte_date: string, shop_id?: string, shop_name?: string, good_first_category_name?: '공익상품' | '메아리상품', shop_order_complete_status?: '출고요청' | '주문확인' | '출고완료' | '매장확인' | '주문취소', shop_order_id?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (period_type === null || period_type === undefined) {
            throw new Error('Required parameter period_type was null or undefined when calling shopOrderGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling shopOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling shopOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (period_type !== undefined && period_type !== null) {
            queryParameters = queryParameters.set('period_type', <any>period_type);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (shop_order_complete_status !== undefined && shop_order_complete_status !== null) {
            queryParameters = queryParameters.set('shop_order_complete_status', <any>shop_order_complete_status);
        }
        if (shop_order_id !== undefined && shop_order_id !== null) {
            queryParameters = queryParameters.set('shop_order_id', <any>shop_order_id);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopOrder>>(`${this.basePath}/greenbusiness/shop_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 주문서-특판관리 검색(등록일, 주문유형, 특판처명, 상태현황, 주문번호, 수취인, 배송방식)
     * 주문서-특판관리 등
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param special_order_goods_type 주문유형
     * @param vendor_company_name 특판처명
     * @param special_order_complete_status 상태현황
     * @param special_order_no 주문번호
     * @param special_order_goods_reciever_name 수취인
     * @param good_first_category_name 배송방식
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public specialOrderGet(gte_date: string, lte_date: string, special_order_goods_type?: '주문' | '반품', vendor_company_name?: string, special_order_complete_status?: '출고요청' | '출고완료', special_order_no?: string, special_order_goods_reciever_name?: string, good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsSpecialOrder>>;
    public specialOrderGet(gte_date: string, lte_date: string, special_order_goods_type?: '주문' | '반품', vendor_company_name?: string, special_order_complete_status?: '출고요청' | '출고완료', special_order_no?: string, special_order_goods_reciever_name?: string, good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsSpecialOrder>>>;
    public specialOrderGet(gte_date: string, lte_date: string, special_order_goods_type?: '주문' | '반품', vendor_company_name?: string, special_order_complete_status?: '출고요청' | '출고완료', special_order_no?: string, special_order_goods_reciever_name?: string, good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsSpecialOrder>>>;
    public specialOrderGet(gte_date: string, lte_date: string, special_order_goods_type?: '주문' | '반품', vendor_company_name?: string, special_order_complete_status?: '출고요청' | '출고완료', special_order_no?: string, special_order_goods_reciever_name?: string, good_first_category_name?: '그린물류' | '업체직배송', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling specialOrderGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling specialOrderGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (special_order_goods_type !== undefined && special_order_goods_type !== null) {
            queryParameters = queryParameters.set('special_order_goods_type', <any>special_order_goods_type);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }
        if (special_order_complete_status !== undefined && special_order_complete_status !== null) {
            queryParameters = queryParameters.set('special_order_complete_status', <any>special_order_complete_status);
        }
        if (special_order_no !== undefined && special_order_no !== null) {
            queryParameters = queryParameters.set('special_order_no', <any>special_order_no);
        }
        if (special_order_goods_reciever_name !== undefined && special_order_goods_reciever_name !== null) {
            queryParameters = queryParameters.set('special_order_goods_reciever_name', <any>special_order_goods_reciever_name);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsSpecialOrder>>(`${this.basePath}/greenbusiness/special_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린매출정보조회
     * 그린매출(특판)정보조회
     * @param gte_date 시작일자(YYYY-MM-DD)
     * @param lte_date 종료일자(YYYY-MM-DD)
     * @param vendor_company_name 납품처명
     * @param good_name 상품명
     * @param order_type 주문구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public specialSalesGet(gte_date: string, lte_date: string, vendor_company_name?: string, good_name?: string, order_type?: '주문' | '반품', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenSale>>;
    public specialSalesGet(gte_date: string, lte_date: string, vendor_company_name?: string, good_name?: string, order_type?: '주문' | '반품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenSale>>>;
    public specialSalesGet(gte_date: string, lte_date: string, vendor_company_name?: string, good_name?: string, order_type?: '주문' | '반품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenSale>>>;
    public specialSalesGet(gte_date: string, lte_date: string, vendor_company_name?: string, good_name?: string, order_type?: '주문' | '반품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling specialSalesGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling specialSalesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (order_type !== undefined && order_type !== null) {
            queryParameters = queryParameters.set('order_type', <any>order_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenSale>>(`${this.basePath}/greenbusiness/special_sales`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 내부용_조이너정보수정추후제거
     * 내부용_조이너정보수정추후제거
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateJoinerInfoGet(observe?: 'body', reportProgress?: boolean): Observable<Array<EsSetGoodInfo>>;
    public updateJoinerInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsSetGoodInfo>>>;
    public updateJoinerInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsSetGoodInfo>>>;
    public updateJoinerInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsSetGoodInfo>>(`${this.basePath}/greenbusiness/update_joiner_info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 내부용_조이너정보수정추후제거
     * 내부용_조이너정보수정추후제거
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateJoinerInfoUseGet(observe?: 'body', reportProgress?: boolean): Observable<Array<EsSetGoodInfo>>;
    public updateJoinerInfoUseGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsSetGoodInfo>>>;
    public updateJoinerInfoUseGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsSetGoodInfo>>>;
    public updateJoinerInfoUseGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsSetGoodInfo>>(`${this.basePath}/greenbusiness/update_joiner_info_use`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 외부납품처관리 검색(등록일, 납품처명, 거래여부)
     * 외부납품처관리 등
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param vendor_company_name 납품처명
     * @param vendor_deal_flg 거래여부
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vendorGet(gte_date?: string, lte_date?: string, vendor_company_name?: string, vendor_deal_flg?: 'Y' | 'N', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsVendor>>;
    public vendorGet(gte_date?: string, lte_date?: string, vendor_company_name?: string, vendor_deal_flg?: 'Y' | 'N', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsVendor>>>;
    public vendorGet(gte_date?: string, lte_date?: string, vendor_company_name?: string, vendor_deal_flg?: 'Y' | 'N', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsVendor>>>;
    public vendorGet(gte_date?: string, lte_date?: string, vendor_company_name?: string, vendor_deal_flg?: 'Y' | 'N', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }
        if (vendor_deal_flg !== undefined && vendor_deal_flg !== null) {
            queryParameters = queryParameters.set('vendor_deal_flg', <any>vendor_deal_flg);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsVendor>>(`${this.basePath}/greenbusiness/vendor`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 외부납품처 동일명 검색(납품처명)
     * 외부납품처정보 등
     * @param vendor_company_name 납품처명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vendorInfoGet(vendor_company_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsVendor>>;
    public vendorInfoGet(vendor_company_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsVendor>>>;
    public vendorInfoGet(vendor_company_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsVendor>>>;
    public vendorInfoGet(vendor_company_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (vendor_company_name !== undefined && vendor_company_name !== null) {
            queryParameters = queryParameters.set('vendor_company_name', <any>vendor_company_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsVendor>>(`${this.basePath}/greenbusiness/vendor_name`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
