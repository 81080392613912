<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style datepicker-group">
      <label>기간</label>
      <div class="input-group">
        <input [matDatepicker]="start" [max]="maxDate" (click)="start.open()" class="form-control m-input"
          formControlName="gte_date" (dateChange)="onChangeStartDate()">
        <mat-datepicker #start></mat-datepicker>

        <button class="btn btn-secondary" type="button"></button>

        <mat-datepicker #end></mat-datepicker>
        <input [matDatepicker]="end" [min]="minDate" [max]="maxDate" (click)="end.open()" class="form-control m-input"
          formControlName="lte_date" (dateChange)="onChangeEndDate()">
      </div>
    </div>

    <app-shop-auto-complete [shopType]="'매장'" (selected)="selectShop($event)"></app-shop-auto-complete>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div class="statistics-mat-table" #TABLE>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>년월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.date}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="department_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>국</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.department_name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="team_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>팀</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.team_name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="location_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>매장/부서명</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.location_name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="production_count">
          <mat-header-cell *matHeaderCellDef mat-sort-header>총점 생산량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.production_count | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="의류">
          <mat-header-cell *matHeaderCellDef mat-sort-header>의류(점)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertCategories('의류',  element?.categories) | currencyMask}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="잡화">
          <mat-header-cell *matHeaderCellDef mat-sort-header>잡화(점)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertCategories('잡화', element?.categories) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="도서음반">
          <mat-header-cell *matHeaderCellDef mat-sort-header>도서음반(점)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertCategories('도서음반', element?.categories) | currencyMask}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="가전">
          <mat-header-cell *matHeaderCellDef mat-sort-header>가전(점)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertCategories('가전', element?.categories) | currencyMask}}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>