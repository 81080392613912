/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsLogisticsService = /** @class */ (function () {
    function EsLogisticsService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsLogisticsService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsLogisticsService.prototype.logisticsConfirmGet = function (search_div, gte_collect_date, lte_collect_date, logistics_collect_emp_dept_id, logistics_collect_area_id, logistics_collect_emp_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (search_div === null || search_div === undefined) {
            throw new Error('Required parameter search_div was null or undefined when calling logisticsConfirmGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsConfirmGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsConfirmGet.');
        }
        if (logistics_collect_emp_dept_id === null || logistics_collect_emp_dept_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_dept_id was null or undefined when calling logisticsConfirmGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (search_div !== undefined && search_div !== null) {
            queryParameters = queryParameters.set('search_div', search_div);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', lte_collect_date);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', logistics_collect_emp_dept_id);
        }
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', logistics_collect_area_id);
        }
        if (logistics_collect_emp_name !== undefined && logistics_collect_emp_name !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_name', logistics_collect_emp_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/confirm", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsDateBoxCountGet = function (logistics_collect_area_id, logistics_collect_emp_dept_id, gte_collect_date, lte_collect_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (logistics_collect_area_id === null || logistics_collect_area_id === undefined) {
            throw new Error('Required parameter logistics_collect_area_id was null or undefined when calling logisticsDateBoxCountGet.');
        }
        if (logistics_collect_emp_dept_id === null || logistics_collect_emp_dept_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_dept_id was null or undefined when calling logisticsDateBoxCountGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsDateBoxCountGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsDateBoxCountGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', logistics_collect_area_id);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', logistics_collect_emp_dept_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', lte_collect_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/date/box_count", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsDateGet = function (logistics_collect_area_id, logistics_collect_emp_dept_id, gte_collect_date, lte_collect_date, assigned, name, base_addr, logistics_div, logistics_collect_emp_name, cellp_no, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (logistics_collect_area_id === null || logistics_collect_area_id === undefined) {
            throw new Error('Required parameter logistics_collect_area_id was null or undefined when calling logisticsDateGet.');
        }
        if (logistics_collect_emp_dept_id === null || logistics_collect_emp_dept_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_dept_id was null or undefined when calling logisticsDateGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsDateGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsDateGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', logistics_collect_area_id);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', logistics_collect_emp_dept_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', lte_collect_date);
        }
        if (assigned !== undefined && assigned !== null) {
            queryParameters = queryParameters.set('assigned', assigned);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', name);
        }
        if (base_addr !== undefined && base_addr !== null) {
            queryParameters = queryParameters.set('base_addr', base_addr);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', logistics_div);
        }
        if (logistics_collect_emp_name !== undefined && logistics_collect_emp_name !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_name', logistics_collect_emp_name);
        }
        if (cellp_no !== undefined && cellp_no !== null) {
            queryParameters = queryParameters.set('cellp_no', cellp_no);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/date", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsDateTaskGet = function (logistics_collect_emp_id, gte_collect_date, lte_collect_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (logistics_collect_emp_id === null || logistics_collect_emp_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_id was null or undefined when calling logisticsDateTaskGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsDateTaskGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsDateTaskGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (logistics_collect_emp_id !== undefined && logistics_collect_emp_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_id', logistics_collect_emp_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', lte_collect_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/date/task", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsDateUnassignGet = function (logistics_collect_area_id, logistics_collect_emp_dept_id, gte_collect_date, lte_collect_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (logistics_collect_area_id === null || logistics_collect_area_id === undefined) {
            throw new Error('Required parameter logistics_collect_area_id was null or undefined when calling logisticsDateUnassignGet.');
        }
        if (logistics_collect_emp_dept_id === null || logistics_collect_emp_dept_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_dept_id was null or undefined when calling logisticsDateUnassignGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsDateUnassignGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsDateUnassignGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', logistics_collect_area_id);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', logistics_collect_emp_dept_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', lte_collect_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/date/unassign", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsDeptsGet = function (gte_date, lte_date, dept_id, logistics_collect_area_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsDeptsGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsDeptsGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', logistics_collect_area_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/depts", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsEmpsGet = function (gte_date, lte_date, dept_id, logistics_collect_area_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsEmpsGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsEmpsGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', logistics_collect_area_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/emps", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsExcelGet = function (gte_date, lte_date, dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsGivingsDateGet = function (logistics_collect_area_id, gte_collect_date, lte_collect_date, assigned, name, base_addr, logistics_div, cellp_no, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (logistics_collect_area_id === null || logistics_collect_area_id === undefined) {
            throw new Error('Required parameter logistics_collect_area_id was null or undefined when calling logisticsGivingsDateGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsGivingsDateGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsGivingsDateGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', logistics_collect_area_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', lte_collect_date);
        }
        if (assigned !== undefined && assigned !== null) {
            queryParameters = queryParameters.set('assigned', assigned);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', name);
        }
        if (base_addr !== undefined && base_addr !== null) {
            queryParameters = queryParameters.set('base_addr', base_addr);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', logistics_div);
        }
        if (cellp_no !== undefined && cellp_no !== null) {
            queryParameters = queryParameters.set('cellp_no', cellp_no);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/givings/date", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsLogisticsService.prototype.logisticsNormalLargeGet = function (gte_date, lte_date, logistics_req_emp_dept_name, logistics_collect_emp_dept_id, event_flg, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsNormalLargeGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsNormalLargeGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (logistics_req_emp_dept_name !== undefined && logistics_req_emp_dept_name !== null) {
            queryParameters = queryParameters.set('logistics_req_emp_dept_name', logistics_req_emp_dept_name);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', logistics_collect_emp_dept_id);
        }
        if (event_flg !== undefined && event_flg !== null) {
            queryParameters = queryParameters.set('event_flg', event_flg);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/logistics/normal_large", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsLogisticsService;
}());
export { EsLogisticsService };
