/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsShopsService = /** @class */ (function () {
    function EsShopsService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsShopsService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsShopsService.prototype.shopsCheckShopCloseGet = function (shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling shopsCheckShopCloseGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/check_shop_close", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsCheckShopNameGet = function (shop_name, shop_type, shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_name === null || shop_name === undefined) {
            throw new Error('Required parameter shop_name was null or undefined when calling shopsCheckShopNameGet.');
        }
        if (shop_type === null || shop_type === undefined) {
            throw new Error('Required parameter shop_type was null or undefined when calling shopsCheckShopNameGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', shop_type);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/check_shop_name", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsContractGet = function (shop_id, shop_number, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling shopsContractGet.');
        }
        if (shop_number === null || shop_number === undefined) {
            throw new Error('Required parameter shop_number was null or undefined when calling shopsContractGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (shop_number !== undefined && shop_number !== null) {
            queryParameters = queryParameters.set('shop_number', shop_number);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/contract", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsExcelGet = function (shop_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', shop_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsGet = function (shop_type, shop_name_or_dept_name, manager_emp_name, shop_local_name, shop_close_flg, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_type === null || shop_type === undefined) {
            throw new Error('Required parameter shop_type was null or undefined when calling shopsGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_name_or_dept_name !== undefined && shop_name_or_dept_name !== null) {
            queryParameters = queryParameters.set('shop_name_or_dept_name', shop_name_or_dept_name);
        }
        if (manager_emp_name !== undefined && manager_emp_name !== null) {
            queryParameters = queryParameters.set('manager_emp_name', manager_emp_name);
        }
        if (shop_local_name !== undefined && shop_local_name !== null) {
            queryParameters = queryParameters.set('shop_local_name', shop_local_name);
        }
        if (shop_close_flg !== undefined && shop_close_flg !== null) {
            queryParameters = queryParameters.set('shop_close_flg', shop_close_flg);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', shop_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsGivingsProductionExcelGet = function (gte_date, lte_date, shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling shopsGivingsProductionExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling shopsGivingsProductionExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/givings/production/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsGivingsProductionGet = function (gte_date, lte_date, shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling shopsGivingsProductionGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling shopsGivingsProductionGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/givings/production", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsInfoGet = function (dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling shopsInfoGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/shopinfo", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsMaintenanceGet = function (shop_id, shop_number, shop_maintenance_type, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling shopsMaintenanceGet.');
        }
        if (shop_number === null || shop_number === undefined) {
            throw new Error('Required parameter shop_number was null or undefined when calling shopsMaintenanceGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (shop_number !== undefined && shop_number !== null) {
            queryParameters = queryParameters.set('shop_number', shop_number);
        }
        if (shop_maintenance_type !== undefined && shop_maintenance_type !== null) {
            queryParameters = queryParameters.set('shop_maintenance_type', shop_maintenance_type);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/maintenance", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsMetaGet = function (shop_type, shop_name, dept_id, shop_virtual, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_type === null || shop_type === undefined) {
            throw new Error('Required parameter shop_type was null or undefined when calling shopsMetaGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', shop_type);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        if (shop_virtual !== undefined && shop_virtual !== null) {
            queryParameters = queryParameters.set('shop_virtual', shop_virtual);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/meta", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsNoAuthGet = function (shop_type, page_number, shop_name_or_dept_name, manager_emp_name, shop_local_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_type === null || shop_type === undefined) {
            throw new Error('Required parameter shop_type was null or undefined when calling shopsNoAuthGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', shop_type);
        }
        if (shop_name_or_dept_name !== undefined && shop_name_or_dept_name !== null) {
            queryParameters = queryParameters.set('shop_name_or_dept_name', shop_name_or_dept_name);
        }
        if (manager_emp_name !== undefined && manager_emp_name !== null) {
            queryParameters = queryParameters.set('manager_emp_name', manager_emp_name);
        }
        if (shop_local_name !== undefined && shop_local_name !== null) {
            queryParameters = queryParameters.set('shop_local_name', shop_local_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/no_auth", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsPoscodeGenerateGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/poscode_generate", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsRealsalesGet = function (shop_id, target_year, target_percent, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling shopsRealsalesGet.');
        }
        if (target_year === null || target_year === undefined) {
            throw new Error('Required parameter target_year was null or undefined when calling shopsRealsalesGet.');
        }
        if (target_percent === null || target_percent === undefined) {
            throw new Error('Required parameter target_percent was null or undefined when calling shopsRealsalesGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (target_year !== undefined && target_year !== null) {
            queryParameters = queryParameters.set('target_year', target_year);
        }
        if (target_percent !== undefined && target_percent !== null) {
            queryParameters = queryParameters.set('target_percent', target_percent);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/realsales", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsSeqGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/seq", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsShopsService.prototype.shopsTargetsalesGet = function (target_year, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (target_year === null || target_year === undefined) {
            throw new Error('Required parameter target_year was null or undefined when calling shopsTargetsalesGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (target_year !== undefined && target_year !== null) {
            queryParameters = queryParameters.set('target_year', target_year);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/shops/targetsales", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsShopsService;
}());
export { EsShopsService };
