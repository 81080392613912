<h3>그린물류 입고확정 데이터</h3>

<div class="m--space-20"></div>

<form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

  <div class="form-group m-form__group default-style">
    <label>기간</label>
    <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()" formControlName="date" readonly>
    <mat-datepicker #end startView="year" (monthSelected)="chosenMonthHandler($event, end)"></mat-datepicker>
  </div>

  <div class="form-group m-form__group default-style last">
    <button class="btn btn-sm btn-primary" (click)="download()">다운로드</button>
  </div>

</form>