import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS, MatDatepicker } from '@angular/material';
import { EsSalesService } from 'src/app/restapi/be-esquery';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DateRangePicker } from 'src/app/share/components/directives/date-range-picker.directive';
import { dateFormat, DEFAULT_FORMATS, dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { CodeService, ActiveStateYear, StatisticsService, Code } from 'src/app/restapi/be-angel';

const moment = _moment;

@Component({
  selector: 'app-angel-activity-status-annual',
  templateUrl: './angel-activity-status-annual.component.html',
  styleUrls: ['./angel-activity-status-annual.component.scss'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ]
})
export class AngelActivityStatusAnnualComponent implements OnInit {

  form: FormGroup;
  dataSource: MatTableDataSource<ActiveStateYear> = new MatTableDataSource([]);
  displayedColumns = ['department_name', 'team_name', 'location_name', 'mon01_active', 'mon01_wait', 'mon01_stop', 'mon01_end', 'mon02_active', 'mon02_wait', 'mon02_stop', 'mon02_end', 'mon03_active', 'mon03_wait', 'mon03_stop', 'mon03_end', 
    'mon04_active', 'mon04_wait', 'mon04_stop', 'mon04_end', 'mon05_active', 'mon05_wait', 'mon05_stop', 'mon05_end', 'mon06_active', 'mon06_wait', 'mon06_stop', 'mon06_end', 'mon07_active', 'mon07_wait', 'mon07_stop', 'mon07_end', 
    'mon08_active', 'mon08_wait', 'mon08_stop', 'mon08_end', 'mon09_active', 'mon09_wait', 'mon09_stop', 'mon09_end', 'mon10_active', 'mon10_wait', 'mon10_stop', 'mon10_end', 'mon11_active', 'mon11_wait', 'mon11_stop', 'mon11_end', 
    'mon12_active', 'mon12_wait', 'mon12_stop', 'mon12_end'];
  paginationCollection: Array<any> = new Array<any>();
  page: number = 1;
  pageSize: number = 10;
  act_div_list: Array<Code>;
  act_period_list: Array<Code>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder, private statisticsService: StatisticsService, private datePipe: DatePipe,private codeService: CodeService) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.codeService.angelCodeGet("자원활동","활동구분").subscribe( res => {
      this.act_div_list = res;
      console.info(this.act_div_list);
    })
    this.codeService.angelCodeGet("자원활동","활동주기").subscribe( res => {
      this.act_period_list = res;
      console.info(this.act_period_list);
    })

  }

  ngOnDestroy(): void { }

  initForm() {
    this.form = this.fb.group({
      act_div: [''],
      act_period: [''],
      year: moment(),
      search_act_dept_name: ['']
    });
  }

  get fc_actDiv() { return this.form.get('act_div'); }
  get fc_actPeriod() { return this.form.get('act_period'); }
  get fc_year() { return this.form.get('year'); }
  get fc_deptName() { return this.form.get('search_act_dept_name'); }

  chosenYearHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_year.setValue(normalizedMonth);
    this.search();
    
    datepicker.close();
  }

  
  rangeComplete(event: DateRangePicker) {
    this.form.patchValue({
      gte_date: event.startDate,
      lte_date: event.endDate
    });
  }

  search() {
    this.statisticsService.statisticsActiveStateYearGet(dateFormat_Year(this.fc_year.value),this.fc_deptName.value,
      this.fc_actDiv.value, this.fc_actPeriod.value).subscribe({
        next: res => {
          console.info(res)
          this.dataSource.data = res;
        },
        complete: () => {
          this.sort.sortChange.emit({ active: this.sort.active, direction: this.sort.direction });
        }
      });
  }

  onClickExcelExport() {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertDatasource());
    const wscols = [{ wch: 20 }, { wch: 15 }, { wch: 18 }, { wch: 7 }, { wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },
      { wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },
      { wch: 7 },{ wch: 7 },]
    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, '활동상태연보');
    XLSX.writeFile(wb, `활동상태연보_${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}.xlsx`);
  }

  convertDatasource() {
    const xlsxDatasource: Array<any> = [];

    this.dataSource.data.forEach((item: any) => {
      xlsxDatasource.push({
        '국': item.department_name, '팀': item.team_name, '매장': item.location_name,
        '1월활동': item.mon01_active , '1월신규':  item.mon01_wait, '1월중단':  item.mon01_stop, '1월종료':  item.mon01_end,
        '2월활동': item.mon02_active , '2월신규':  item.mon02_wait, '2월중단':  item.mon02_stop, '2월종료':  item.mon02_end,
        '3월활동': item.mon03_active , '3월신규':  item.mon03_wait, '3월중단':  item.mon03_stop, '3월종료':  item.mon03_end,
        '4월활동': item.mon04_active , '4월신규':  item.mon04_wait, '4월중단':  item.mon04_stop, '4월종료':  item.mon04_end,
        '5월활동': item.mon05_active , '5월신규':  item.mon05_wait, '5월중단':  item.mon05_stop, '5월종료':  item.mon05_end,
        '6월활동': item.mon06_active , '6월신규':  item.mon06_wait, '6월중단':  item.mon06_stop, '6월종료':  item.mon06_end,
        '7월활동': item.mon07_active , '7월신규':  item.mon07_wait, '7월중단':  item.mon07_stop, '7월종료':  item.mon07_end,
        '8월활동': item.mon08_active , '8월신규':  item.mon08_wait, '8월중단':  item.mon08_stop, '8월종료':  item.mon08_end,
        '9월활동': item.mon09_active , '9월신규':  item.mon09_wait, '9월중단':  item.mon09_stop, '9월종료':  item.mon09_end,
        '10월활동': item.mon10_active , '10월신규':  item.mon10_wait, '10월중단':  item.mon10_stop, '10월종료':  item.mon10_end,
        '11월활동': item.mon11_active , '11월신규':  item.mon11_wait, '11월중단':  item.mon11_stop, '11월종료':  item.mon11_end,
        '12월활동': item.mon12_active , '12월신규':  item.mon12_wait, '12월중단':  item.mon12_stop, '12월종료':  item.mon12_end,
      });
    });
    return xlsxDatasource;
  }

}
