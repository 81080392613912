import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EsMasterService } from 'src/app/restapi/be-esquery';
import { EsAreas } from 'src/app/restapi/be-esquery/model/esAreas';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
    selector: 'app-areas-auto-complete',
    templateUrl: './areas-auto-complete.component.html',
    styleUrls: ['./areas-auto-complete.component.scss']
})
export class AreasAutoCompleteComponent implements OnInit, OnDestroy {

    @Input() label: string;
    @Output() selected = new EventEmitter<EsAreas>();

    control = new FormControl();
    selectedAreas: EsAreas;
    searchResults: Observable<Array<EsAreas>>;

    constructor(
        private esMasterService: EsMasterService
    ) { }

    ngOnInit() {
        if (!this.label) this.label = '권역';

        this.searchResults = this.control.valueChanges.pipe(
            debounceTime(500),
            tap((value: string) => {
                if (!value) {
                    this.selected.emit(null);
                }
            }),
            filter((value: any) => !!value && typeof value === 'string'),
            mergeMap((value: string) => {
                return this.esMasterService.masterAreaInfoGet(value);
            }),
            takeUntil(componentDestroyed(this)),
        );
    }

    ngOnDestroy() { }

    displayAreasName(areas: EsAreas): string | undefined {
        return areas ? areas.area_name : undefined;
    }

    onAreasSelected(value: EsAreas) {
        if (!value) return;

        this.selectedAreas = value;
        this.selected.emit(this.selectedAreas);
    }
}
