/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다. 
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Failure } from '../model/failure';
import { VehicleInfo } from '../model/vehicleInfo';
import { VehicleInfoPost } from '../model/vehicleInfoPost';
import { VehicleManagement } from '../model/vehicleManagement';
import { VehicleManagementPost } from '../model/vehicleManagementPost';
import { VehicleMonthly } from '../model/vehicleMonthly';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VehicleService {

    protected basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * vehicle 조회
     * 
     * @param owner_dept_id 
     * @param driver_emp_id 
     * @param vehicle_number 
     * @param vehicle_full_number 
     * @param vehicle_status 
     * @param vehicle_id 
     * @param del_flg 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vehiclesInfoGet(owner_dept_id?: string, driver_emp_id?: string, vehicle_number?: string, vehicle_full_number?: string, vehicle_status?: string, vehicle_id?: string, del_flg?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleInfo>>;
    public vehiclesInfoGet(owner_dept_id?: string, driver_emp_id?: string, vehicle_number?: string, vehicle_full_number?: string, vehicle_status?: string, vehicle_id?: string, del_flg?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleInfo>>>;
    public vehiclesInfoGet(owner_dept_id?: string, driver_emp_id?: string, vehicle_number?: string, vehicle_full_number?: string, vehicle_status?: string, vehicle_id?: string, del_flg?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleInfo>>>;
    public vehiclesInfoGet(owner_dept_id?: string, driver_emp_id?: string, vehicle_number?: string, vehicle_full_number?: string, vehicle_status?: string, vehicle_id?: string, del_flg?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (owner_dept_id !== undefined && owner_dept_id !== null) {
            queryParameters = queryParameters.set('owner_dept_id', <any>owner_dept_id);
        }
        if (driver_emp_id !== undefined && driver_emp_id !== null) {
            queryParameters = queryParameters.set('driver_emp_id', <any>driver_emp_id);
        }
        if (vehicle_number !== undefined && vehicle_number !== null) {
            queryParameters = queryParameters.set('vehicle_number', <any>vehicle_number);
        }
        if (vehicle_full_number !== undefined && vehicle_full_number !== null) {
            queryParameters = queryParameters.set('vehicle_full_number', <any>vehicle_full_number);
        }
        if (vehicle_status !== undefined && vehicle_status !== null) {
            queryParameters = queryParameters.set('vehicle_status', <any>vehicle_status);
        }
        if (vehicle_id !== undefined && vehicle_id !== null) {
            queryParameters = queryParameters.set('vehicle_id', <any>vehicle_id);
        }
        if (del_flg !== undefined && del_flg !== null) {
            queryParameters = queryParameters.set('del_flg', <any>del_flg);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<VehicleInfo>>(`${this.basePath}/vehicles/info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vehicle 관리정보조회
     * 
     * @param gte_date 
     * @param lte_date 
     * @param owner_dept_id 
     * @param vehicle_number 
     * @param vehicle_full_number 
     * @param management_div 
     * @param vehicle_id 
     * @param management_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vehiclesManagementGet(gte_date?: string, lte_date?: string, owner_dept_id?: string, vehicle_number?: string, vehicle_full_number?: string, management_div?: '주행기록' | '정비기록' | '기타지출' | '사고기록', vehicle_id?: string, management_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleManagement>>;
    public vehiclesManagementGet(gte_date?: string, lte_date?: string, owner_dept_id?: string, vehicle_number?: string, vehicle_full_number?: string, management_div?: '주행기록' | '정비기록' | '기타지출' | '사고기록', vehicle_id?: string, management_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleManagement>>>;
    public vehiclesManagementGet(gte_date?: string, lte_date?: string, owner_dept_id?: string, vehicle_number?: string, vehicle_full_number?: string, management_div?: '주행기록' | '정비기록' | '기타지출' | '사고기록', vehicle_id?: string, management_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleManagement>>>;
    public vehiclesManagementGet(gte_date?: string, lte_date?: string, owner_dept_id?: string, vehicle_number?: string, vehicle_full_number?: string, management_div?: '주행기록' | '정비기록' | '기타지출' | '사고기록', vehicle_id?: string, management_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (owner_dept_id !== undefined && owner_dept_id !== null) {
            queryParameters = queryParameters.set('owner_dept_id', <any>owner_dept_id);
        }
        if (vehicle_number !== undefined && vehicle_number !== null) {
            queryParameters = queryParameters.set('vehicle_number', <any>vehicle_number);
        }
        if (vehicle_full_number !== undefined && vehicle_full_number !== null) {
            queryParameters = queryParameters.set('vehicle_full_number', <any>vehicle_full_number);
        }
        if (management_div !== undefined && management_div !== null) {
            queryParameters = queryParameters.set('management_div', <any>management_div);
        }
        if (vehicle_id !== undefined && vehicle_id !== null) {
            queryParameters = queryParameters.set('vehicle_id', <any>vehicle_id);
        }
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', <any>management_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<VehicleManagement>>(`${this.basePath}/vehicles/management`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vehicle 월별통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param owner_dept_id 
     * @param vehicle_full_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vehiclesMonthlyGet(gte_date: string, lte_date: string, owner_dept_id?: string, vehicle_full_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleMonthly>>;
    public vehiclesMonthlyGet(gte_date: string, lte_date: string, owner_dept_id?: string, vehicle_full_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleMonthly>>>;
    public vehiclesMonthlyGet(gte_date: string, lte_date: string, owner_dept_id?: string, vehicle_full_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleMonthly>>>;
    public vehiclesMonthlyGet(gte_date: string, lte_date: string, owner_dept_id?: string, vehicle_full_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling vehiclesMonthlyGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling vehiclesMonthlyGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (owner_dept_id !== undefined && owner_dept_id !== null) {
            queryParameters = queryParameters.set('owner_dept_id', <any>owner_dept_id);
        }
        if (vehicle_full_number !== undefined && vehicle_full_number !== null) {
            queryParameters = queryParameters.set('vehicle_full_number', <any>vehicle_full_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<VehicleMonthly>>(`${this.basePath}/vehicles/monthly`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vehicle 등록
     * 차량정보 등록
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vehiclesPost(body: VehicleInfoPost, observe?: 'body', reportProgress?: boolean): Observable<VehicleInfo>;
    public vehiclesPost(body: VehicleInfoPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VehicleInfo>>;
    public vehiclesPost(body: VehicleInfoPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VehicleInfo>>;
    public vehiclesPost(body: VehicleInfoPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vehiclesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VehicleInfo>(`${this.basePath}/vehicles`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vehicle 삭제
     * 
     * @param vehicle_id 
     * @param stop_date 
     * @param vehicle_status 
     * @param del_flg 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vehiclesVehicleIdDelete(vehicle_id: string, stop_date?: string, vehicle_status?: '폐차' | '매매' | '기타', del_flg?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vehiclesVehicleIdDelete(vehicle_id: string, stop_date?: string, vehicle_status?: '폐차' | '매매' | '기타', del_flg?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vehiclesVehicleIdDelete(vehicle_id: string, stop_date?: string, vehicle_status?: '폐차' | '매매' | '기타', del_flg?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vehiclesVehicleIdDelete(vehicle_id: string, stop_date?: string, vehicle_status?: '폐차' | '매매' | '기타', del_flg?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (vehicle_id === null || vehicle_id === undefined) {
            throw new Error('Required parameter vehicle_id was null or undefined when calling vehiclesVehicleIdDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stop_date !== undefined && stop_date !== null) {
            queryParameters = queryParameters.set('stop_date', <any>stop_date);
        }
        if (vehicle_status !== undefined && vehicle_status !== null) {
            queryParameters = queryParameters.set('vehicle_status', <any>vehicle_status);
        }
        if (del_flg !== undefined && del_flg !== null) {
            queryParameters = queryParameters.set('del_flg', <any>del_flg);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/vehicles/${encodeURIComponent(String(vehicle_id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 차량정보수정
     * 
     * @param vehicle_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vehiclesVehicleIdInfoPut(vehicle_id: string, body: VehicleInfoPost, observe?: 'body', reportProgress?: boolean): Observable<VehicleInfo>;
    public vehiclesVehicleIdInfoPut(vehicle_id: string, body: VehicleInfoPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VehicleInfo>>;
    public vehiclesVehicleIdInfoPut(vehicle_id: string, body: VehicleInfoPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VehicleInfo>>;
    public vehiclesVehicleIdInfoPut(vehicle_id: string, body: VehicleInfoPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (vehicle_id === null || vehicle_id === undefined) {
            throw new Error('Required parameter vehicle_id was null or undefined when calling vehiclesVehicleIdInfoPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vehiclesVehicleIdInfoPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VehicleInfo>(`${this.basePath}/vehicles/${encodeURIComponent(String(vehicle_id))}/info`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 차량관리정보수정
     * 
     * @param vehicle_id 
     * @param management_id 
     * @param body 
     * @param issue_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vehiclesVehicleIdManagementManagementIdPut(vehicle_id: string, management_id: string, body: VehicleManagementPost, issue_date?: string, observe?: 'body', reportProgress?: boolean): Observable<VehicleManagement>;
    public vehiclesVehicleIdManagementManagementIdPut(vehicle_id: string, management_id: string, body: VehicleManagementPost, issue_date?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VehicleManagement>>;
    public vehiclesVehicleIdManagementManagementIdPut(vehicle_id: string, management_id: string, body: VehicleManagementPost, issue_date?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VehicleManagement>>;
    public vehiclesVehicleIdManagementManagementIdPut(vehicle_id: string, management_id: string, body: VehicleManagementPost, issue_date?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (vehicle_id === null || vehicle_id === undefined) {
            throw new Error('Required parameter vehicle_id was null or undefined when calling vehiclesVehicleIdManagementManagementIdPut.');
        }
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling vehiclesVehicleIdManagementManagementIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vehiclesVehicleIdManagementManagementIdPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issue_date !== undefined && issue_date !== null) {
            queryParameters = queryParameters.set('issue_date', <any>issue_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VehicleManagement>(`${this.basePath}/vehicles/${encodeURIComponent(String(vehicle_id))}/management/${encodeURIComponent(String(management_id))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 차량관리정보생성
     * 
     * @param vehicle_id 
     * @param issue_date 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vehiclesVehicleIdManagementPost(vehicle_id: string, issue_date: string, body: VehicleManagementPost, observe?: 'body', reportProgress?: boolean): Observable<VehicleManagement>;
    public vehiclesVehicleIdManagementPost(vehicle_id: string, issue_date: string, body: VehicleManagementPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VehicleManagement>>;
    public vehiclesVehicleIdManagementPost(vehicle_id: string, issue_date: string, body: VehicleManagementPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VehicleManagement>>;
    public vehiclesVehicleIdManagementPost(vehicle_id: string, issue_date: string, body: VehicleManagementPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (vehicle_id === null || vehicle_id === undefined) {
            throw new Error('Required parameter vehicle_id was null or undefined when calling vehiclesVehicleIdManagementPost.');
        }
        if (issue_date === null || issue_date === undefined) {
            throw new Error('Required parameter issue_date was null or undefined when calling vehiclesVehicleIdManagementPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vehiclesVehicleIdManagementPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issue_date !== undefined && issue_date !== null) {
            queryParameters = queryParameters.set('issue_date', <any>issue_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VehicleManagement>(`${this.basePath}/vehicles/${encodeURIComponent(String(vehicle_id))}/management`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
