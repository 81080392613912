/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var PrivateNumberService = /** @class */ (function () {
    function PrivateNumberService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    PrivateNumberService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    PrivateNumberService.prototype.privateNumberGet = function (resource_type, resource_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (resource_type === null || resource_type === undefined) {
            throw new Error('Required parameter resource_type was null or undefined when calling privateNumberGet.');
        }
        if (resource_id === null || resource_id === undefined) {
            throw new Error('Required parameter resource_id was null or undefined when calling privateNumberGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (resource_type !== undefined && resource_type !== null) {
            queryParameters = queryParameters.set('resource_type', resource_type);
        }
        if (resource_id !== undefined && resource_id !== null) {
            queryParameters = queryParameters.set('resource_id', resource_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/private/number", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    PrivateNumberService.prototype.privateNumberPut = function (resource_type, resource_id, private_no, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (resource_type === null || resource_type === undefined) {
            throw new Error('Required parameter resource_type was null or undefined when calling privateNumberPut.');
        }
        if (resource_id === null || resource_id === undefined) {
            throw new Error('Required parameter resource_id was null or undefined when calling privateNumberPut.');
        }
        if (private_no === null || private_no === undefined) {
            throw new Error('Required parameter private_no was null or undefined when calling privateNumberPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (resource_type !== undefined && resource_type !== null) {
            queryParameters = queryParameters.set('resource_type', resource_type);
        }
        if (resource_id !== undefined && resource_id !== null) {
            queryParameters = queryParameters.set('resource_id', resource_id);
        }
        if (private_no !== undefined && private_no !== null) {
            queryParameters = queryParameters.set('private_no', private_no);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.put(this.basePath + "/private/number", null, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return PrivateNumberService;
}());
export { PrivateNumberService };
