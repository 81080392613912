import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS, MatDatepicker } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { EsGivingCallHistoryStatistics } from 'src/app/restapi/be-esquery/model/esGivingCallHistoryStatistics';
import { EsGivingHistory } from 'src/app/restapi/be-esquery/model/esGivingHistory';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

@Component({
  selector: 'app-givings-call-history',
  templateUrl: './givings-call-history.component.html',
  styleUrls: ['./givings-call-history.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ]
})
export class GivingsCallHistoryComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  dataList: Array<EsGivingCallHistoryStatistics> = [];
  dataSource: MatTableDataSource<EsGivingCallHistoryStatistics> = new MatTableDataSource([]);
  displayedColumns = ['history_category', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  constructor(
    private fb: FormBuilder,
    private esGivingsService: EsGivingsService
  ) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      year: moment()
    });
  }

  get fc_year() { return this.form.get('year'); }

  chosenYearHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_year.setValue(normalizedMonth);
    this.search();
    
    datepicker.close();
  }

  convertMonthInfo(month: string, data: EsGivingCallHistoryStatistics) {
    if (data.monthly_info) {
      const givingHistory: EsGivingHistory = data.monthly_info.find(x => x.date.split("-")[1] == month);
      if (givingHistory) return givingHistory.count;
    }

    return '';
  }

  search() {
    this.givingsCallHistoryGet();
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      if (sort.active == '01' || sort.active == '02' || sort.active == '03' || sort.active == '04' || sort.active == '05' ||
        sort.active == '06' || sort.active == '07' || sort.active == '08' || sort.active == '09' || sort.active == '10' ||
        sort.active == '11' || sort.active == '12') {
        sorted = this.dataList.sort((a: EsGivingCallHistoryStatistics, b: EsGivingCallHistoryStatistics) => {
          let result = 0;
          let a_data: number = Number(this.convertMonthInfo(sort.active, a));
          let b_data: number = Number(this.convertMonthInfo(sort.active, b));

          if (a_data < b_data) result = -1;
          else if (a_data > b_data) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
      else {
        sorted = this.dataList.sort((a: EsGivingCallHistoryStatistics, b: EsGivingCallHistoryStatistics) => {
          let result = 0;
          if (a[sort.active] == undefined) a[sort.active] = "";
          if (b[sort.active] == undefined) b[sort.active] = "";

          if (a[sort.active] < b[sort.active]) result = -1;
          else if (a[sort.active] > b[sort.active]) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsGivingCallHistoryStatistics>(this.dataList);
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '상담문의 통계');
    XLSX.writeFile(wb, '상담문의 통계.xlsx');
  }

  givingsCallHistoryGet() {
    this.dataList = [];

    this.esGivingsService.givingsCallHistoryGet(
      dateFormat_Year(this.fc_year.value),
      dateFormat_Year(this.fc_year.value),
    ).pipe(
      take(1)
    ).subscribe((res: Array<EsGivingCallHistoryStatistics>) => {
      this.dataList = res;
      this.initPage(this.sort);
    },
      (err) => {
        console.error(err);
        this.initPage(this.sort);
      }
    );
  }

}