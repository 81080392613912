import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MatDatepicker } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat_Month, MONTH_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = MONTH_FORMATS;
var GivingsCallAreaComponent = /** @class */ (function () {
    function GivingsCallAreaComponent(fb, esGivingsService) {
        this.fb = fb;
        this.esGivingsService = esGivingsService;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['date', 'give_route', 'give_apply_route', 'state', 'logistics_collect_area_name', 'give_count', '의류', '잡화', '도서음반', '가전'];
    }
    GivingsCallAreaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    GivingsCallAreaComponent.prototype.ngOnDestroy = function () { };
    GivingsCallAreaComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            date: moment().add(-1, 'month')
        });
    };
    Object.defineProperty(GivingsCallAreaComponent.prototype, "fc_date", {
        get: function () { return this.form.get('date'); },
        enumerable: true,
        configurable: true
    });
    GivingsCallAreaComponent.prototype.chosenMonthHandler = function (normalizedMonth, datepicker) {
        this.fc_date.setValue(normalizedMonth);
        this.search();
        datepicker.close();
    };
    GivingsCallAreaComponent.prototype.convertCategories = function (category, categories) {
        if (categories.find(function (x) { return x.category == category; })) {
            return categories.find(function (x) { return x.category == category; }).sum;
        }
        return "";
    };
    GivingsCallAreaComponent.prototype.search = function () {
        this.givingsCallAreaGet();
    };
    GivingsCallAreaComponent.prototype.initPage = function (sort) {
        var _this = this;
        if (!this.dataList)
            this.dataList = [];
        var sorted = this.dataList;
        if (sort) {
            if (sort.active == '의류' || sort.active == '잡화' || sort.active == '도서음반' || sort.active == '가전') {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    var a_cate_sum = Number(_this.convertCategories(sort.active, a.categories));
                    var b_cate_sum = Number(_this.convertCategories(sort.active, b.categories));
                    if (a_cate_sum < b_cate_sum)
                        result = -1;
                    else if (a_cate_sum > b_cate_sum)
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
            else {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    if (a[sort.active] == undefined)
                        a[sort.active] = "";
                    if (b[sort.active] == undefined)
                        b[sort.active] = "";
                    if (a[sort.active] < b[sort.active])
                        result = -1;
                    else if (a[sort.active] > b[sort.active])
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
        }
        this.dataList = sorted;
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    GivingsCallAreaComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '수거 권역별 통계');
        XLSX.writeFile(wb, '수거 권역별 통계.xlsx');
    };
    GivingsCallAreaComponent.prototype.givingsCallAreaGet = function () {
        var _this = this;
        this.dataList = [];
        this.esGivingsService.givingsCallAreaGet(dateFormat_Month(this.fc_date.value), dateFormat_Month(this.fc_date.value)).pipe(take(1)).subscribe(function (res) {
            _this.dataList = res;
            _this.initPage(_this.sort);
        }, function (err) {
            console.error(err);
            _this.initPage(_this.sort);
        });
    };
    return GivingsCallAreaComponent;
}());
export { GivingsCallAreaComponent };
export { ɵ0 };
