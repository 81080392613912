/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsDreceiptService = /** @class */ (function () {
    function EsDreceiptService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsDreceiptService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsDreceiptService.prototype.dreceiptCompactGet = function (gte_dr_req_date, lte_dr_req_date, dr_issue_status, dr_give_type, joiner_private_no, give_reception_joiner_name, give_reception_joiner_cellp_no, give_reception_joiner_div, event_partner_joiner_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_dr_req_date === null || gte_dr_req_date === undefined) {
            throw new Error('Required parameter gte_dr_req_date was null or undefined when calling dreceiptCompactGet.');
        }
        if (lte_dr_req_date === null || lte_dr_req_date === undefined) {
            throw new Error('Required parameter lte_dr_req_date was null or undefined when calling dreceiptCompactGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_dr_req_date !== undefined && gte_dr_req_date !== null) {
            queryParameters = queryParameters.set('gte_dr_req_date', gte_dr_req_date);
        }
        if (lte_dr_req_date !== undefined && lte_dr_req_date !== null) {
            queryParameters = queryParameters.set('lte_dr_req_date', lte_dr_req_date);
        }
        if (dr_issue_status !== undefined && dr_issue_status !== null) {
            queryParameters = queryParameters.set('dr_issue_status', dr_issue_status);
        }
        if (dr_give_type !== undefined && dr_give_type !== null) {
            queryParameters = queryParameters.set('dr_give_type', dr_give_type);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', joiner_private_no);
        }
        if (give_reception_joiner_name !== undefined && give_reception_joiner_name !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_name', give_reception_joiner_name);
        }
        if (give_reception_joiner_cellp_no !== undefined && give_reception_joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_cellp_no', give_reception_joiner_cellp_no);
        }
        if (give_reception_joiner_div !== undefined && give_reception_joiner_div !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_div', give_reception_joiner_div);
        }
        if (event_partner_joiner_name !== undefined && event_partner_joiner_name !== null) {
            queryParameters = queryParameters.set('event_partner_joiner_name', event_partner_joiner_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dreceipt/compact", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsDreceiptService.prototype.dreceiptGet = function (gte_dr_req_date, lte_dr_req_date, dr_issue_status, dr_give_type, joiner_private_no, give_reception_joiner_name, give_reception_joiner_cellp_no, give_reception_joiner_div, event_partner_joiner_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_dr_req_date === null || gte_dr_req_date === undefined) {
            throw new Error('Required parameter gte_dr_req_date was null or undefined when calling dreceiptGet.');
        }
        if (lte_dr_req_date === null || lte_dr_req_date === undefined) {
            throw new Error('Required parameter lte_dr_req_date was null or undefined when calling dreceiptGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_dr_req_date !== undefined && gte_dr_req_date !== null) {
            queryParameters = queryParameters.set('gte_dr_req_date', gte_dr_req_date);
        }
        if (lte_dr_req_date !== undefined && lte_dr_req_date !== null) {
            queryParameters = queryParameters.set('lte_dr_req_date', lte_dr_req_date);
        }
        if (dr_issue_status !== undefined && dr_issue_status !== null) {
            queryParameters = queryParameters.set('dr_issue_status', dr_issue_status);
        }
        if (dr_give_type !== undefined && dr_give_type !== null) {
            queryParameters = queryParameters.set('dr_give_type', dr_give_type);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', joiner_private_no);
        }
        if (give_reception_joiner_name !== undefined && give_reception_joiner_name !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_name', give_reception_joiner_name);
        }
        if (give_reception_joiner_cellp_no !== undefined && give_reception_joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_cellp_no', give_reception_joiner_cellp_no);
        }
        if (give_reception_joiner_div !== undefined && give_reception_joiner_div !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_div', give_reception_joiner_div);
        }
        if (event_partner_joiner_name !== undefined && event_partner_joiner_name !== null) {
            queryParameters = queryParameters.set('event_partner_joiner_name', event_partner_joiner_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dreceipt", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsDreceiptService.prototype.dreceiptIssueGet = function (year, is_auto, is_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling dreceiptIssueGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (is_auto !== undefined && is_auto !== null) {
            queryParameters = queryParameters.set('is_auto', is_auto);
        }
        if (is_type !== undefined && is_type !== null) {
            queryParameters = queryParameters.set('is_type', is_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dreceipt/issue", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsDreceiptService.prototype.dreceiptStatusGet = function (dr_issue_status, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (dr_issue_status === null || dr_issue_status === undefined) {
            throw new Error('Required parameter dr_issue_status was null or undefined when calling dreceiptStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (dr_issue_status !== undefined && dr_issue_status !== null) {
            queryParameters = queryParameters.set('dr_issue_status', dr_issue_status);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dreceipt/status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsDreceiptService;
}());
export { EsDreceiptService };
