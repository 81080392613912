import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MatDatepicker } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = YEAR_FORMATS;
var GivingsCallAllDurationComponent = /** @class */ (function () {
    function GivingsCallAllDurationComponent(fb, esGivingsService) {
        this.fb = fb;
        this.esGivingsService = esGivingsService;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['monthly_01', 'monthly_02', 'monthly_03', 'monthly_04', 'monthly_05', 'monthly_06', 'monthly_07', 'monthly_08', 'monthly_09', 'monthly_10', 'monthly_11', 'monthly_12'];
    }
    GivingsCallAllDurationComponent.prototype.ngOnInit = function () {
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            //this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    GivingsCallAllDurationComponent.prototype.ngOnDestroy = function () { };
    GivingsCallAllDurationComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            year: moment()
        });
    };
    Object.defineProperty(GivingsCallAllDurationComponent.prototype, "fc_year", {
        get: function () { return this.form.get('year'); },
        enumerable: true,
        configurable: true
    });
    GivingsCallAllDurationComponent.prototype.chosenYearHandler = function (normalizedMonth, datepicker) {
        this.fc_year.setValue(normalizedMonth);
        this.search();
        datepicker.close();
    };
    // convertMonthInfo(month: string, data: EsGivingCallAllDurationStatistics) {
    //   if (data.monthly_info) {
    //     const givingHistory: EsGivingHistory = data.monthly_info.find(x => x.date.split("-")[1] == month);
    //     console.log(givingHistory);
    //     if (givingHistory) return givingHistory.count;
    //   }
    //   return '';
    // }
    GivingsCallAllDurationComponent.prototype.search = function () {
        this.givingsCallHistoryGet();
    };
    GivingsCallAllDurationComponent.prototype.initPage = function () {
        // if (!this.dataList) this.dataList = [];
        // let sorted = this.dataList;
        // if (sort) {
        //   if (sort.active == 'monthly_01' ||monthly_sort.monthly_activemonthly_ == '0monthly_2' || monthly_sort.amonthly_ctive monthly_== '03monthly_' || smonthly_ort.acmonthly_tive =monthly_= '04' || sort.active == '05' ||
        //     sort.active == '06' || sort.active == '07' || sort.active == '08' || sort.active == '09' || sort.active == '10' ||
        //     sort.active == '11' || sort.active == '12') {
        //     sorted = this.dataList.sort((a: EsGivingCallAllDurationStatistics, b: EsGivingCallAllDurationStatistics) => {
        //       let result = 0;
        //       let a_data: number = Number(this.convertMonthInfo(sort.active, a));
        //       let b_data: number = Number(this.convertMonthInfo(sort.active, b));
        //       if (a_data < b_data) result = -1;
        //       else if (a_data > b_data) result = 1;
        //       return result * (sort.direction === 'asc' ? 1 : -1);
        //     });
        //   }
        //   else {
        //     sorted = this.dataList.sort((a: EsGivingCallAllDurationStatistics, b: EsGivingCallAllDurationStatistics) => {
        //       let result = 0;
        //       if (a[sort.active] == undefined) a[sort.active] = "";
        //       if (b[sort.active] == undefined) b[sort.active] = "";
        //       if (a[sort.active] < b[sort.active]) result = -1;
        //       else if (a[sort.active] > b[sort.active]) result = 1;
        //       return result * (sort.direction === 'asc' ? 1 : -1);
        //     });
        //   }
        // }
        // this.dataList = sorted;
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    GivingsCallAllDurationComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '접수~발급 소요일 통계');
        XLSX.writeFile(wb, '접수~발급 소요일 통계.xlsx');
    };
    GivingsCallAllDurationComponent.prototype.givingsCallHistoryGet = function () {
        var _this = this;
        this.dataList = [];
        this.esGivingsService.givingsCallAllDurationGet(dateFormat_Year(this.fc_year.value), dateFormat_Year(this.fc_year.value)).pipe(take(1)).subscribe(function (res) {
            _this.dataList = res;
            _this.initPage();
        }, function (err) {
            console.error(err);
            _this.initPage();
        });
    };
    return GivingsCallAllDurationComponent;
}());
export { GivingsCallAllDurationComponent };
export { ɵ0 };
