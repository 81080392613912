import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MAT_DATE_FORMATS } from '@angular/material';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import { EsWarehouseService, EsProductionStatisticalData, EsDeptInfo } from 'src/app/restapi/be-esquery';
import { take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-warehouse-production',
  templateUrl: './warehouse-production.component.html',
  styleUrls: ['./warehouse-production.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS }
  ]
})
export class WarehouseProductionComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  form: FormGroup;
  dataList: Array<EsProductionStatisticalData> = [];
  dataSource: MatTableDataSource<EsProductionStatisticalData> = new MatTableDataSource([]);
  displayedColumns = ['search_yyyy_mm', 'recycle_place_name', 'production_work_group_name', 'give_good_cate', 'give_good_detail_cate', 'general_production',
    'event_production', 'event_disposal', 'tot_working_people_num', 'tot_working_time', 'production_per_working_people', 'production_per_working_time'];
  minDate = moment().add(-3, 'month');
  maxDate = moment();

  spans = [];

  constructor(
    private fb: FormBuilder,
    private esWarehouseService: EsWarehouseService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      gte_date: this.minDate,
      lte_date: this.maxDate,
      dept_info: null
    });
  }

  get fc_gteDate() { return this.form.get('gte_date'); }
  get fc_lteDate() { return this.form.get('lte_date'); }
  get fc_deptInfo() { return this.form.get('dept_info'); }

  onChangeStartDate() {
    const limitDate = moment(this.fc_gteDate.value).add(3, 'month');
    this.minDate = this.fc_gteDate.value;

    if (this.fc_gteDate.value > this.fc_lteDate.value) {
      this.fc_lteDate.setValue(this.fc_gteDate.value);
    }

    if (limitDate < this.fc_lteDate.value) {
      this.fc_lteDate.setValue(limitDate);
    }
  }

  onChangeEndDate() {
    const limitDate = moment(this.fc_lteDate.value).add(-3, 'month');

    if (limitDate > this.fc_gteDate.value) {
      this.fc_gteDate.setValue(limitDate);
      this.minDate = limitDate;
    }
  }

  selectDept(value: EsDeptInfo) {
    if (!value || value == null) {
      this.fc_deptInfo.setValue(null);
      return;
    }

    this.fc_deptInfo.setValue(value);
    this.search();
  }

  search() {
    this.productionGet();
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '생산통계');
    XLSX.writeFile(wb, '생산통계.xlsx');
  }

  productionGet() {
    this.dataList = [];

    this.esWarehouseService.warehouseRecyclePlaceProductionStatisticalTableGet(
      dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value),
      this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null
    ).pipe(
      take(1)
    ).subscribe((res: Array<EsProductionStatisticalData>) => {
      this.dataList = res;
      this.dataSource = new MatTableDataSource<EsProductionStatisticalData>(this.dataList);
      this.cacheSpan('production_work_group_name', (data: EsProductionStatisticalData) => data.production_work_group_name);
    },
      (err) => {
        console.error(err);
        this.dataSource = new MatTableDataSource<EsProductionStatisticalData>(this.dataList);
      }
    );
  }

  getRowSpan(col: any, index: number) {
    return this.spans[index] && this.spans[index][col];
  }

  cacheSpan(key: any, accessor: any) {
    this.spans = [];

    for (let i = 0; i < this.dataList.length;) {
      let currentValue = accessor(this.dataList[i]);
      let count = 1;

      for (let j = i + 1; j < this.dataList.length; j++) {
        if (currentValue != accessor(this.dataList[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      this.spans[i][key] = count;
      i += count;
    }
  }

}