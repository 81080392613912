/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsDreceiptIssue } from '../model/esDreceiptIssue';
import { EsDreceiptList } from '../model/esDreceiptList';
import { EsFailure } from '../model/esFailure';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsDreceiptService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 기부영수증 검색 날짜,상태(리스트에 필요한 데이터만)
     * 검색 날짜 형식 e.g) -&gt; 2018-09-12
     * @param gte_dr_req_date 
     * @param lte_dr_req_date 
     * @param dr_issue_status 
     * @param dr_give_type 
     * @param joiner_private_no 
     * @param give_reception_joiner_name 
     * @param give_reception_joiner_cellp_no 
     * @param give_reception_joiner_div 
     * @param event_partner_joiner_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dreceiptCompactGet(gte_dr_req_date: string, lte_dr_req_date: string, dr_issue_status?: string, dr_give_type?: string, joiner_private_no?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_joiner_div?: string, event_partner_joiner_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsDreceiptList>>;
    public dreceiptCompactGet(gte_dr_req_date: string, lte_dr_req_date: string, dr_issue_status?: string, dr_give_type?: string, joiner_private_no?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_joiner_div?: string, event_partner_joiner_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDreceiptList>>>;
    public dreceiptCompactGet(gte_dr_req_date: string, lte_dr_req_date: string, dr_issue_status?: string, dr_give_type?: string, joiner_private_no?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_joiner_div?: string, event_partner_joiner_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDreceiptList>>>;
    public dreceiptCompactGet(gte_dr_req_date: string, lte_dr_req_date: string, dr_issue_status?: string, dr_give_type?: string, joiner_private_no?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_joiner_div?: string, event_partner_joiner_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_dr_req_date === null || gte_dr_req_date === undefined) {
            throw new Error('Required parameter gte_dr_req_date was null or undefined when calling dreceiptCompactGet.');
        }
        if (lte_dr_req_date === null || lte_dr_req_date === undefined) {
            throw new Error('Required parameter lte_dr_req_date was null or undefined when calling dreceiptCompactGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_dr_req_date !== undefined && gte_dr_req_date !== null) {
            queryParameters = queryParameters.set('gte_dr_req_date', <any>gte_dr_req_date);
        }
        if (lte_dr_req_date !== undefined && lte_dr_req_date !== null) {
            queryParameters = queryParameters.set('lte_dr_req_date', <any>lte_dr_req_date);
        }
        if (dr_issue_status !== undefined && dr_issue_status !== null) {
            queryParameters = queryParameters.set('dr_issue_status', <any>dr_issue_status);
        }
        if (dr_give_type !== undefined && dr_give_type !== null) {
            queryParameters = queryParameters.set('dr_give_type', <any>dr_give_type);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', <any>joiner_private_no);
        }
        if (give_reception_joiner_name !== undefined && give_reception_joiner_name !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_name', <any>give_reception_joiner_name);
        }
        if (give_reception_joiner_cellp_no !== undefined && give_reception_joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_cellp_no', <any>give_reception_joiner_cellp_no);
        }
        if (give_reception_joiner_div !== undefined && give_reception_joiner_div !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_div', <any>give_reception_joiner_div);
        }
        if (event_partner_joiner_name !== undefined && event_partner_joiner_name !== null) {
            queryParameters = queryParameters.set('event_partner_joiner_name', <any>event_partner_joiner_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDreceiptList>>(`${this.basePath}/dreceipt/compact`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기부영수증 검색 날짜,상태
     * 검색 날짜 형식 e.g) -&gt; 2018-09-12
     * @param gte_dr_req_date 
     * @param lte_dr_req_date 
     * @param dr_issue_status 
     * @param dr_give_type 
     * @param joiner_private_no 
     * @param give_reception_joiner_name 
     * @param give_reception_joiner_cellp_no 
     * @param give_reception_joiner_div 
     * @param event_partner_joiner_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dreceiptGet(gte_dr_req_date: string, lte_dr_req_date: string, dr_issue_status?: string, dr_give_type?: string, joiner_private_no?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_joiner_div?: string, event_partner_joiner_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsDreceiptList>>;
    public dreceiptGet(gte_dr_req_date: string, lte_dr_req_date: string, dr_issue_status?: string, dr_give_type?: string, joiner_private_no?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_joiner_div?: string, event_partner_joiner_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDreceiptList>>>;
    public dreceiptGet(gte_dr_req_date: string, lte_dr_req_date: string, dr_issue_status?: string, dr_give_type?: string, joiner_private_no?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_joiner_div?: string, event_partner_joiner_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDreceiptList>>>;
    public dreceiptGet(gte_dr_req_date: string, lte_dr_req_date: string, dr_issue_status?: string, dr_give_type?: string, joiner_private_no?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_joiner_div?: string, event_partner_joiner_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_dr_req_date === null || gte_dr_req_date === undefined) {
            throw new Error('Required parameter gte_dr_req_date was null or undefined when calling dreceiptGet.');
        }
        if (lte_dr_req_date === null || lte_dr_req_date === undefined) {
            throw new Error('Required parameter lte_dr_req_date was null or undefined when calling dreceiptGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_dr_req_date !== undefined && gte_dr_req_date !== null) {
            queryParameters = queryParameters.set('gte_dr_req_date', <any>gte_dr_req_date);
        }
        if (lte_dr_req_date !== undefined && lte_dr_req_date !== null) {
            queryParameters = queryParameters.set('lte_dr_req_date', <any>lte_dr_req_date);
        }
        if (dr_issue_status !== undefined && dr_issue_status !== null) {
            queryParameters = queryParameters.set('dr_issue_status', <any>dr_issue_status);
        }
        if (dr_give_type !== undefined && dr_give_type !== null) {
            queryParameters = queryParameters.set('dr_give_type', <any>dr_give_type);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', <any>joiner_private_no);
        }
        if (give_reception_joiner_name !== undefined && give_reception_joiner_name !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_name', <any>give_reception_joiner_name);
        }
        if (give_reception_joiner_cellp_no !== undefined && give_reception_joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_cellp_no', <any>give_reception_joiner_cellp_no);
        }
        if (give_reception_joiner_div !== undefined && give_reception_joiner_div !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_div', <any>give_reception_joiner_div);
        }
        if (event_partner_joiner_name !== undefined && event_partner_joiner_name !== null) {
            queryParameters = queryParameters.set('event_partner_joiner_name', <any>event_partner_joiner_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDreceiptList>>(`${this.basePath}/dreceipt`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기부영수증 발급 통계
     * 기부영수증 발급 통계
     * @param year 
     * @param is_auto 
     * @param is_type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dreceiptIssueGet(year: string, is_auto?: 'Y' | 'N', is_type?: '발행일' | '기증일', observe?: 'body', reportProgress?: boolean): Observable<EsDreceiptIssue>;
    public dreceiptIssueGet(year: string, is_auto?: 'Y' | 'N', is_type?: '발행일' | '기증일', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDreceiptIssue>>;
    public dreceiptIssueGet(year: string, is_auto?: 'Y' | 'N', is_type?: '발행일' | '기증일', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDreceiptIssue>>;
    public dreceiptIssueGet(year: string, is_auto?: 'Y' | 'N', is_type?: '발행일' | '기증일', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling dreceiptIssueGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (is_auto !== undefined && is_auto !== null) {
            queryParameters = queryParameters.set('is_auto', <any>is_auto);
        }
        if (is_type !== undefined && is_type !== null) {
            queryParameters = queryParameters.set('is_type', <any>is_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDreceiptIssue>(`${this.basePath}/dreceipt/issue`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기부영수증 검색 상태 기준(사용하지 않음)
     * 기부영수증 발급 상태를 기준으로 검색한다.
     * @param dr_issue_status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dreceiptStatusGet(dr_issue_status: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsDreceiptList>>;
    public dreceiptStatusGet(dr_issue_status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDreceiptList>>>;
    public dreceiptStatusGet(dr_issue_status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDreceiptList>>>;
    public dreceiptStatusGet(dr_issue_status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dr_issue_status === null || dr_issue_status === undefined) {
            throw new Error('Required parameter dr_issue_status was null or undefined when calling dreceiptStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dr_issue_status !== undefined && dr_issue_status !== null) {
            queryParameters = queryParameters.set('dr_issue_status', <any>dr_issue_status);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDreceiptList>>(`${this.basePath}/dreceipt/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
