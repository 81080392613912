import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { EsLogisticsEmpStatistics } from 'src/app/restapi/be-esquery/model/esLogisticsEmpStatistics';

@Component({
  selector: 'app-logistics-emps-div',
  templateUrl: './logistics-emps-div.component.html',
  styleUrls: ['./logistics-emps-div.component.scss']
})
export class LogisticsEmpsDivComponent implements OnInit, OnChanges {

  @Input() dataList: Array<EsLogisticsEmpStatistics>;
  @ViewChild('TABLE') table: ElementRef;
  dataSource: MatTableDataSource<EsLogisticsEmpStatistics> = new MatTableDataSource([]);
  displayedColumns = ['emp_name', 'count', 'give_unit_count'];

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataList && changes.dataList.currentValue != null) {
      setTimeout(() => {
        this.convertData();
      });
    }
  }

  convertData() {
    const totalCount: number = this.dataList.map(x => x.count).filter(x => x).reduce((pValue, cValue) => { return pValue + cValue; }, 0);
    const totalGiveUnitCount: number = this.dataList.map(x => x.give_unit_count).filter(x => x).reduce((pValue, cValue) => { return pValue + cValue; }, 0);

    this.dataList.push({
      emp_name: '합계',
      count: totalCount,
      give_unit_count: totalGiveUnitCount
    });

    this.dataSource = new MatTableDataSource<EsLogisticsEmpStatistics>(this.dataList);
  }

}