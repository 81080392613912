import { Component, OnInit } from '@angular/core';
import { AuthService, UserProfile } from 'fecommon';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  pageGroup: Array<string> = [];

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.userSubject.subscribe((userProfile: UserProfile) => {
      this.pageGroup = userProfile.page_group;
    });
  }

}
