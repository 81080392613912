import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DateRangePicker } from 'src/app/share/components/directives/date-range-picker.directive';
import { EsSalesService, EsRateSales } from 'src/app/restapi/be-esquery';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import { MAT_DATE_FORMATS, MatTableDataSource, MatSort, Sort } from '@angular/material';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-sales-rate-by-supply',
  templateUrl: './sales-rate-by-supply.component.html',
  styleUrls: ['./sales-rate-by-supply.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS },
  ]
})
export class SalesRateBySupplyComponent implements OnInit {
  form: FormGroup;
  dataSource: MatTableDataSource<EsRateSales> = new MatTableDataSource([]);
  displayedColumns = ['shop_name', 'good_second_category_name', 'good_third_category_name', 'tot_sale_amt', 'no_tax_sale_amt',
    'tot_sale_qty', 'avg_price', 'rate', 'recycle_place_name', 'normal_cnt', 'event_cnt', 'none_cnt', 'tot_cnt'];
  paginationCollection: Array<any> = new Array<any>();
  page: number = 1;
  pageSize: number = 10;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder, private esSalesService: EsSalesService) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy(): void { }

  initForm() {
    this.form = this.fb.group({
      gte_date: [new Date()],
      lte_date: [new Date()],
      dept_name: ['']
    });
  }

  get fc_gteDate() { return this.form.get('gte_date'); }
  get fc_lteDate() { return this.form.get('lte_date'); }
  get fc_deptName() { return this.form.get('dept_name'); }

  initPage(sort?: Sort) {
    if (!this.paginationCollection) this.paginationCollection = [];

    let sorted = this.paginationCollection;
    if (sort) {
      sorted = this.paginationCollection.sort((a: any, b: any) => {
        let result = 0;

        if (a[sort.active] == undefined) a[sort.active] = "";
        if (b[sort.active] == undefined) b[sort.active] = "";

        if (a[sort.active] < b[sort.active]) {
          result = -1;
        } else if (a[sort.active] > b[sort.active]) {
          result = 1;
        }
        return result * (sort.direction === 'asc' ? 1 : -1);
      });
    }
    this.paginationCollection = sorted;
    this.setPage(this.page);
  }

  setPage(currentPage: number) {
    this.page = currentPage;
    const dataSource = this.paginationCollection.slice(
      (currentPage - 1) * this.pageSize,
      currentPage * this.pageSize
    );
    this.dataSource.data = dataSource;
    this.dataSource.sort = this.sort;
  }

  rangeComplete(event: DateRangePicker) {
    this.form.patchValue({
      gte_date: event.startDate,
      lte_date: event.endDate
    });
  }

  search() {
    this.esSalesService.salesRateBySupplyGet(dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value), this.fc_deptName.value && this.fc_deptName.value.trim() != '' ?
      this.fc_deptName.value : null).subscribe({
        next: res => {
          this.paginationCollection = res;
        },
        complete: () => {
          this.setPage(this.page);
          this.sort.sortChange.emit({ active: this.sort.active, direction: this.sort.direction });
        }
      });
  }

  onClickExcelExport() {
    this.esSalesService.salesRateBySupplyExcelGet(dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value), this.fc_deptName.value && this.fc_deptName.value.trim() != '' ?
      this.fc_deptName.value : null).subscribe(res => {
        this.createDownloadExcel(res.url);
      });
  }

  createDownloadExcel(downloadUrl: string) {
    let link: any = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
