/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsMasterService = /** @class */ (function () {
    function EsMasterService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsMasterService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsMasterService.prototype.masterAreaCallStatusGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling masterAreaCallStatusGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling masterAreaCallStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/area/call_status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterAreaDeptInfoGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/area/dept_info", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterAreaInfoGet = function (area_name, management_dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (area_name !== undefined && area_name !== null) {
            queryParameters = queryParameters.set('area_name', area_name);
        }
        if (management_dept_id !== undefined && management_dept_id !== null) {
            queryParameters = queryParameters.set('management_dept_id', management_dept_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/area/info", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterAreaReceptionStatusGet = function (gte_date, lte_date, area_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling masterAreaReceptionStatusGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling masterAreaReceptionStatusGet.');
        }
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling masterAreaReceptionStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (area_id !== undefined && area_id !== null) {
            queryParameters = queryParameters.set('area_id', area_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/area/reception/status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterAreaSetCallMailGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/area/set_call_mail", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterCodeListGet = function (code_group_name, code_category_name, code_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (code_group_name === null || code_group_name === undefined) {
            throw new Error('Required parameter code_group_name was null or undefined when calling masterCodeListGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (code_group_name !== undefined && code_group_name !== null) {
            queryParameters = queryParameters.set('code_group_name', code_group_name);
        }
        if (code_category_name !== undefined && code_category_name !== null) {
            queryParameters = queryParameters.set('code_category_name', code_category_name);
        }
        if (code_name !== undefined && code_name !== null) {
            queryParameters = queryParameters.set('code_name', code_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/code/list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterEqualGreenGoodGet = function (good_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_name === null || good_name === undefined) {
            throw new Error('Required parameter good_name was null or undefined when calling masterEqualGreenGoodGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/equal_green_good", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterGoodExcelGet = function (good_div, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_div === null || good_div === undefined) {
            throw new Error('Required parameter good_div was null or undefined when calling masterGoodExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_div !== undefined && good_div !== null) {
            queryParameters = queryParameters.set('good_div', good_div);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/good/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterGoodManagementGet = function (good_div, good_name, barcode, maker_name, good_category_code, tax_flg, dealing_div, logistics_div, good_status, good_tag, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_div === null || good_div === undefined) {
            throw new Error('Required parameter good_div was null or undefined when calling masterGoodManagementGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (barcode !== undefined && barcode !== null) {
            queryParameters = queryParameters.set('barcode', barcode);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', maker_name);
        }
        if (good_category_code !== undefined && good_category_code !== null) {
            queryParameters = queryParameters.set('good_category_code', good_category_code);
        }
        if (tax_flg !== undefined && tax_flg !== null) {
            queryParameters = queryParameters.set('tax_flg', tax_flg);
        }
        if (dealing_div !== undefined && dealing_div !== null) {
            queryParameters = queryParameters.set('dealing_div', dealing_div);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', logistics_div);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', good_status);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', good_tag);
        }
        if (good_div !== undefined && good_div !== null) {
            queryParameters = queryParameters.set('good_div', good_div);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/good/management", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterGoodMetaGet = function (good_name_or_barcode, good_first_category_name, shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_name_or_barcode !== undefined && good_name_or_barcode !== null) {
            queryParameters = queryParameters.set('good_name_or_barcode', good_name_or_barcode);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/good/meta", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterGreenGoodExcelGet = function (shop_id, good_name_or_barcode, good_first_category_name, good_status, good_tag, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (good_name_or_barcode !== undefined && good_name_or_barcode !== null) {
            queryParameters = queryParameters.set('good_name_or_barcode', good_name_or_barcode);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', good_status);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', good_tag);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/green_good/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterGreenGoodGet = function (shop_id, good_name_or_barcode, good_first_category_name, good_status, good_tag, sort_type, good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (good_name_or_barcode !== undefined && good_name_or_barcode !== null) {
            queryParameters = queryParameters.set('good_name_or_barcode', good_name_or_barcode);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', good_status);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', good_tag);
        }
        if (sort_type !== undefined && sort_type !== null) {
            queryParameters = queryParameters.set('sort_type', sort_type);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/green_good", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterGreenGoodMetaGet = function (good_name_or_barcode, good_first_category_name, select_good_shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_name_or_barcode !== undefined && good_name_or_barcode !== null) {
            queryParameters = queryParameters.set('good_name_or_barcode', good_name_or_barcode);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (select_good_shop_id !== undefined && select_good_shop_id !== null) {
            queryParameters = queryParameters.set('select_good_shop_id', select_good_shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/green_good/meta", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterMakerMakerIdGoodGet = function (maker_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (maker_id === null || maker_id === undefined) {
            throw new Error('Required parameter maker_id was null or undefined when calling masterMakerMakerIdGoodGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/maker/" + encodeURIComponent(String(maker_id)) + "/good", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterMappingAddrExcelGet = function (area_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (area_name === null || area_name === undefined) {
            throw new Error('Required parameter area_name was null or undefined when calling masterMappingAddrExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (area_name !== undefined && area_name !== null) {
            queryParameters = queryParameters.set('area_name', area_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/mapping_addr/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterMappingAddrGet = function (mapping_flg, si_do, si_gun_gu, eub_myeon_dong_or_dong_ri, area_id, collect_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (mapping_flg !== undefined && mapping_flg !== null) {
            queryParameters = queryParameters.set('mapping_flg', mapping_flg);
        }
        if (si_do !== undefined && si_do !== null) {
            queryParameters = queryParameters.set('si_do', si_do);
        }
        if (si_gun_gu !== undefined && si_gun_gu !== null) {
            queryParameters = queryParameters.set('si_gun_gu', si_gun_gu);
        }
        if (eub_myeon_dong_or_dong_ri !== undefined && eub_myeon_dong_or_dong_ri !== null) {
            queryParameters = queryParameters.set('eub_myeon_dong_or_dong_ri', eub_myeon_dong_or_dong_ri);
        }
        if (area_id !== undefined && area_id !== null) {
            queryParameters = queryParameters.set('area_id', area_id);
        }
        if (collect_type !== undefined && collect_type !== null) {
            queryParameters = queryParameters.set('collect_type', collect_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/mapping_addr", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterPriceChangeGet = function (from_date, to_date, good_name, app_flg, remark, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (from_date !== undefined && from_date !== null) {
            queryParameters = queryParameters.set('from_date', from_date);
        }
        if (to_date !== undefined && to_date !== null) {
            queryParameters = queryParameters.set('to_date', to_date);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (app_flg !== undefined && app_flg !== null) {
            queryParameters = queryParameters.set('app_flg', app_flg);
        }
        if (remark !== undefined && remark !== null) {
            queryParameters = queryParameters.set('remark', remark);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/price_change", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterRecyclePlaceParcelStatusGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling masterRecyclePlaceParcelStatusGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling masterRecyclePlaceParcelStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/recycle_place/parcel_status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterSetGreenGoodGet = function (good_name, set_yn, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_name === null || good_name === undefined) {
            throw new Error('Required parameter good_name was null or undefined when calling masterSetGreenGoodGet.');
        }
        if (set_yn === null || set_yn === undefined) {
            throw new Error('Required parameter set_yn was null or undefined when calling masterSetGreenGoodGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (set_yn !== undefined && set_yn !== null) {
            queryParameters = queryParameters.set('set_yn', set_yn);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/set_green_good", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterSetOnlyGoodGet = function (good_name, greenbusiness_storage_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_name === null || good_name === undefined) {
            throw new Error('Required parameter good_name was null or undefined when calling masterSetOnlyGoodGet.');
        }
        if (greenbusiness_storage_id === null || greenbusiness_storage_id === undefined) {
            throw new Error('Required parameter greenbusiness_storage_id was null or undefined when calling masterSetOnlyGoodGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', greenbusiness_storage_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/set_only_good", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsMasterService.prototype.masterUseGreenGoodGet = function (good_name, vendor_shop_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_name === null || good_name === undefined) {
            throw new Error('Required parameter good_name was null or undefined when calling masterUseGreenGoodGet.');
        }
        if (vendor_shop_code === null || vendor_shop_code === undefined) {
            throw new Error('Required parameter vendor_shop_code was null or undefined when calling masterUseGreenGoodGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (vendor_shop_code !== undefined && vendor_shop_code !== null) {
            queryParameters = queryParameters.set('vendor_shop_code', vendor_shop_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/master/use_green_good", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsMasterService;
}());
export { EsMasterService };
