<div class="section-basic">

   <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

      <div class="form-group m-form__group default-style">
      <label class="required">연도</label>
      <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()"
        (dateChange)="chosenYearHandler($event.target.value, end)" formControlName="year" readonly>
      <mat-datepicker #end startView="multi-year" (yearSelected)="chosenYearHandler($event, end)"></mat-datepicker>
    </div>

    <div class="form-group m-form__group default-style">
      <label>활동지</label>
      <app-angel-dept-auto-complete [placeholder]="'활동지'" formControlName="search_act_dept_name">
      </app-angel-dept-auto-complete>
    </div>

      <div class="form-group m-form__group default-style form_select">
      <label>활동구분</label>
      <select class="form-control m-input" formControlName="act_div" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_div_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

    <div class="form-group m-form__group default-style form_select">
      <label>활동주기</label>
      <select class="form-control m-input" formControlName="act_period" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_period_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

        <div class="form-group m-form__group default-style">
            <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
        </div>

    </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()" [disabled]="dataSource.data.length == 0">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div class="statistics-mat-table" #TABLE>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="division">
          <mat-header-cell *matHeaderCellDef >연차</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.division}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon01">
          <mat-header-cell *matHeaderCellDef >1월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon01 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon02">
          <mat-header-cell *matHeaderCellDef >2월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon02 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon03">
          <mat-header-cell *matHeaderCellDef >3월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon03 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon04">
          <mat-header-cell *matHeaderCellDef >4월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon04 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon05">
          <mat-header-cell *matHeaderCellDef >5월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon05 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon06">
          <mat-header-cell *matHeaderCellDef >6월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon06 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon07">
          <mat-header-cell *matHeaderCellDef >7월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon07 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon08">
          <mat-header-cell *matHeaderCellDef >8월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon08 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon09">
          <mat-header-cell *matHeaderCellDef >9월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon09 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon10">
          <mat-header-cell *matHeaderCellDef >10월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon10 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon11">
          <mat-header-cell *matHeaderCellDef >11월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon11 | currencyMask }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon12">
          <mat-header-cell *matHeaderCellDef >12월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mon12 | currencyMask }}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      
      <div *ngIf="dataSource.data.length == 0" class="no-records">
          데이터가 존재하지 않습니다.
      </div>

    </div>

  </div>

</div>
