/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsEvent } from '../model/esEvent';
import { EsFailure } from '../model/esFailure';
import { EsLogistics } from '../model/esLogistics';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsEventsService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 행사 검색(행사기간, 등록부서)
     * 행사세부정보, 참여협력처, 참여매장, 히스토리 등
     * @param gte_date 
     * @param lte_date 
     * @param event_emp_dept 등록부서
     * @param event_name 행사명
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public eventsGet(gte_date: string, lte_date: string, event_emp_dept?: string, event_name?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsEvent>>;
    public eventsGet(gte_date: string, lte_date: string, event_emp_dept?: string, event_name?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEvent>>>;
    public eventsGet(gte_date: string, lte_date: string, event_emp_dept?: string, event_name?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEvent>>>;
    public eventsGet(gte_date: string, lte_date: string, event_emp_dept?: string, event_name?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling eventsGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling eventsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (event_emp_dept !== undefined && event_emp_dept !== null) {
            queryParameters = queryParameters.set('event_emp_dept', <any>event_emp_dept);
        }
        if (event_name !== undefined && event_name !== null) {
            queryParameters = queryParameters.set('event_name', <any>event_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEvent>>(`${this.basePath}/events`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 행사 1건에 포함된 수배송건 모든 정보
     * 행사에 포함된 모든 행사수거, 행사배송 정보 조회
     * @param gte_date 
     * @param lte_date 
     * @param event_id 
     * @param manufacture_dept_id 
     * @param logistics_div 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public eventsLogisticsInfoGet(gte_date?: string, lte_date?: string, event_id?: string, manufacture_dept_id?: string, logistics_div?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsLogistics>>;
    public eventsLogisticsInfoGet(gte_date?: string, lte_date?: string, event_id?: string, manufacture_dept_id?: string, logistics_div?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsLogistics>>>;
    public eventsLogisticsInfoGet(gte_date?: string, lte_date?: string, event_id?: string, manufacture_dept_id?: string, logistics_div?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsLogistics>>>;
    public eventsLogisticsInfoGet(gte_date?: string, lte_date?: string, event_id?: string, manufacture_dept_id?: string, logistics_div?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (event_id !== undefined && event_id !== null) {
            queryParameters = queryParameters.set('event_id', <any>event_id);
        }
        if (manufacture_dept_id !== undefined && manufacture_dept_id !== null) {
            queryParameters = queryParameters.set('manufacture_dept_id', <any>manufacture_dept_id);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', <any>logistics_div);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsLogistics>>(`${this.basePath}/events/logistics_info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 행사 중 수거_생산지원 대상만 조회
     * 되살림터에서 수거_생산지원 대상인 행사만 조회
     * @param event_logistics_type 수거_생산지원 으로 검색하면 됩니다.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public eventsLogisticsTypeGet(event_logistics_type: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsEvent>>;
    public eventsLogisticsTypeGet(event_logistics_type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEvent>>>;
    public eventsLogisticsTypeGet(event_logistics_type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEvent>>>;
    public eventsLogisticsTypeGet(event_logistics_type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (event_logistics_type === null || event_logistics_type === undefined) {
            throw new Error('Required parameter event_logistics_type was null or undefined when calling eventsLogisticsTypeGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (event_logistics_type !== undefined && event_logistics_type !== null) {
            queryParameters = queryParameters.set('event_logistics_type', <any>event_logistics_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEvent>>(`${this.basePath}/events/logistics_type`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터 관련 행사조회
     * 되살림터 관련 행사조회
     * @param gte_date 
     * @param lte_date 
     * @param manufacture_dept_id 
     * @param event_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public eventsWarehouseGet(gte_date: string, lte_date: string, manufacture_dept_id?: string, event_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsEvent>>;
    public eventsWarehouseGet(gte_date: string, lte_date: string, manufacture_dept_id?: string, event_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEvent>>>;
    public eventsWarehouseGet(gte_date: string, lte_date: string, manufacture_dept_id?: string, event_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEvent>>>;
    public eventsWarehouseGet(gte_date: string, lte_date: string, manufacture_dept_id?: string, event_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling eventsWarehouseGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling eventsWarehouseGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (manufacture_dept_id !== undefined && manufacture_dept_id !== null) {
            queryParameters = queryParameters.set('manufacture_dept_id', <any>manufacture_dept_id);
        }
        if (event_name !== undefined && event_name !== null) {
            queryParameters = queryParameters.set('event_name', <any>event_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEvent>>(`${this.basePath}/events/warehouse`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
