/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다. 
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AverageBoxCnt } from '../model/averageBoxCnt';
import { DownloadUrl } from '../model/downloadUrl';
import { Failure } from '../model/failure';
import { GoodCodeData } from '../model/goodCodeData';
import { GoodData } from '../model/goodData';
import { GoodTagsPut } from '../model/goodTagsPut';
import { GreenbusinessDeadline } from '../model/greenbusinessDeadline';
import { InventoryDeadline } from '../model/inventoryDeadline';
import { InventoryErrorRate } from '../model/inventoryErrorRate';
import { ParcelGivePlace } from '../model/parcelGivePlace';
import { PostGivingGoodData } from '../model/postGivingGoodData';
import { PostGreenGoodData } from '../model/postGreenGoodData';
import { PostPriceChangeData } from '../model/postPriceChangeData';
import { PriceChangeData } from '../model/priceChangeData';
import { PutGivingGoodData } from '../model/putGivingGoodData';
import { PutGreenGoodData } from '../model/putGreenGoodData';
import { RecyclePlaceDeadline } from '../model/recyclePlaceDeadline';
import { SalesDeadline } from '../model/salesDeadline';
import { WarehouseCodeData } from '../model/warehouseCodeData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WarehouseService {

    protected basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 박스 평균수량 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public averageBoxCntGet(observe?: 'body', reportProgress?: boolean): Observable<AverageBoxCnt>;
    public averageBoxCntGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AverageBoxCnt>>;
    public averageBoxCntGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AverageBoxCnt>>;
    public averageBoxCntGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AverageBoxCnt>(`${this.basePath}/average_box_cnt`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 박스 평균수량 수정
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public averageBoxCntPut(body: AverageBoxCnt, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public averageBoxCntPut(body: AverageBoxCnt, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public averageBoxCntPut(body: AverageBoxCnt, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public averageBoxCntPut(body: AverageBoxCnt, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling averageBoxCntPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/average_box_cnt`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 바코드 생성
     * 
     * @param good_div 상품 분류값
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public barcodeGenerationGet(good_div: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public barcodeGenerationGet(good_div: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public barcodeGenerationGet(good_div: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public barcodeGenerationGet(good_div: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_div === null || good_div === undefined) {
            throw new Error('Required parameter good_div was null or undefined when calling barcodeGenerationGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_div !== undefined && good_div !== null) {
            queryParameters = queryParameters.set('good_div', <any>good_div);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/barcode_generation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 시스템에 의한 마감일 정보 세팅
     * 시스템에 의한 마감일 정보 세팅
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deadlineSettingSystemGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deadlineSettingSystemGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deadlineSettingSystemGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deadlineSettingSystemGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/deadline_setting/system`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증상품 조회
     * 
     * @param untreated 미취급포함
     * @param good_name 상품명
     * @param barcode 바코드
     * @param good_div_code 상품 구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingGoodGet(untreated?: 'Y', good_name?: string, barcode?: string, good_div_code?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GoodData>>;
    public givingGoodGet(untreated?: 'Y', good_name?: string, barcode?: string, good_div_code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GoodData>>>;
    public givingGoodGet(untreated?: 'Y', good_name?: string, barcode?: string, good_div_code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GoodData>>>;
    public givingGoodGet(untreated?: 'Y', good_name?: string, barcode?: string, good_div_code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (untreated !== undefined && untreated !== null) {
            queryParameters = queryParameters.set('untreated', <any>untreated);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (barcode !== undefined && barcode !== null) {
            queryParameters = queryParameters.set('barcode', <any>barcode);
        }
        if (good_div_code !== undefined && good_div_code !== null) {
            queryParameters = queryParameters.set('good_div_code', <any>good_div_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GoodData>>(`${this.basePath}/giving_good`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증상품 등록
     * 
     * @param reg_emp_id 등록 간사 ID
     * @param reg_emp_name 등록 간사명
     * @param reg_emp_dept_name 등록 간사 부서명
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingGoodPost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostGivingGoodData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public givingGoodPost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostGivingGoodData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public givingGoodPost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostGivingGoodData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public givingGoodPost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostGivingGoodData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reg_emp_id === null || reg_emp_id === undefined) {
            throw new Error('Required parameter reg_emp_id was null or undefined when calling givingGoodPost.');
        }
        if (reg_emp_name === null || reg_emp_name === undefined) {
            throw new Error('Required parameter reg_emp_name was null or undefined when calling givingGoodPost.');
        }
        if (reg_emp_dept_name === null || reg_emp_dept_name === undefined) {
            throw new Error('Required parameter reg_emp_dept_name was null or undefined when calling givingGoodPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling givingGoodPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reg_emp_id !== undefined && reg_emp_id !== null) {
            queryParameters = queryParameters.set('reg_emp_id', <any>reg_emp_id);
        }
        if (reg_emp_name !== undefined && reg_emp_name !== null) {
            queryParameters = queryParameters.set('reg_emp_name', <any>reg_emp_name);
        }
        if (reg_emp_dept_name !== undefined && reg_emp_dept_name !== null) {
            queryParameters = queryParameters.set('reg_emp_dept_name', <any>reg_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/giving_good`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증상품 수정
     * 
     * @param sk sk
     * @param mod_emp_id 수정 간사 ID
     * @param mod_emp_name 수정 간사명
     * @param mod_emp_dept_name 수정 간사 부서명
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingGoodPut(sk: string, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, body: PutGivingGoodData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public givingGoodPut(sk: string, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, body: PutGivingGoodData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public givingGoodPut(sk: string, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, body: PutGivingGoodData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public givingGoodPut(sk: string, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, body: PutGivingGoodData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (sk === null || sk === undefined) {
            throw new Error('Required parameter sk was null or undefined when calling givingGoodPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling givingGoodPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling givingGoodPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling givingGoodPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling givingGoodPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sk !== undefined && sk !== null) {
            queryParameters = queryParameters.set('sk', <any>sk);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', <any>mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', <any>mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', <any>mod_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/giving_good`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품분류 조회
     * 
     * @param good_div_depth 상품분류단계
     * @param good_div_code 상품분류코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodDivSearchGet(good_div_depth: number, good_div_code: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GoodCodeData>>;
    public goodDivSearchGet(good_div_depth: number, good_div_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GoodCodeData>>>;
    public goodDivSearchGet(good_div_depth: number, good_div_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GoodCodeData>>>;
    public goodDivSearchGet(good_div_depth: number, good_div_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_div_depth === null || good_div_depth === undefined) {
            throw new Error('Required parameter good_div_depth was null or undefined when calling goodDivSearchGet.');
        }
        if (good_div_code === null || good_div_code === undefined) {
            throw new Error('Required parameter good_div_code was null or undefined when calling goodDivSearchGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_div_depth !== undefined && good_div_depth !== null) {
            queryParameters = queryParameters.set('good_div_depth', <any>good_div_depth);
        }
        if (good_div_code !== undefined && good_div_code !== null) {
            queryParameters = queryParameters.set('good_div_code', <any>good_div_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GoodCodeData>>(`${this.basePath}/good_div/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품분류 등록
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodDivSearchPost(body: GoodCodeData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public goodDivSearchPost(body: GoodCodeData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public goodDivSearchPost(body: GoodCodeData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public goodDivSearchPost(body: GoodCodeData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling goodDivSearchPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/good_div/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품 코드로 특정 상품 정보 조회
     * 
     * @param good_code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodGoodCodeGoodCodeGet(good_code: string, observe?: 'body', reportProgress?: boolean): Observable<GoodData>;
    public goodGoodCodeGoodCodeGet(good_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GoodData>>;
    public goodGoodCodeGoodCodeGet(good_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GoodData>>;
    public goodGoodCodeGoodCodeGet(good_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling goodGoodCodeGoodCodeGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GoodData>(`${this.basePath}/good/good_code/${encodeURIComponent(String(good_code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 특정 상품 정보 조회
     * 
     * @param good_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodGoodIdGet(good_id: string, observe?: 'body', reportProgress?: boolean): Observable<GoodData>;
    public goodGoodIdGet(good_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GoodData>>;
    public goodGoodIdGet(good_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GoodData>>;
    public goodGoodIdGet(good_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_id === null || good_id === undefined) {
            throw new Error('Required parameter good_id was null or undefined when calling goodGoodIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GoodData>(`${this.basePath}/good/${encodeURIComponent(String(good_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품 정보에 태그를 등록한다
     * 상품 정보에 태그를 등록한다
     * @param good_id 상품 아이디
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodGoodIdTagsPut(good_id: string, body: GoodTagsPut, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public goodGoodIdTagsPut(good_id: string, body: GoodTagsPut, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public goodGoodIdTagsPut(good_id: string, body: GoodTagsPut, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public goodGoodIdTagsPut(good_id: string, body: GoodTagsPut, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_id === null || good_id === undefined) {
            throw new Error('Required parameter good_id was null or undefined when calling goodGoodIdTagsPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling goodGoodIdTagsPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/good/${encodeURIComponent(String(good_id))}/tags`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품상태 수정
     * 
     * @param good_id 상품 ID
     * @param good_status 상품상태
     * @param mod_emp_id 수정 간사 ID
     * @param mod_emp_name 수정 간사명
     * @param mod_emp_dept_name 수정 간사 부서명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodGoodStatusPut(good_id: string, good_status: '주문가능' | '일시품절' | '판매중지', mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public goodGoodStatusPut(good_id: string, good_status: '주문가능' | '일시품절' | '판매중지', mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public goodGoodStatusPut(good_id: string, good_status: '주문가능' | '일시품절' | '판매중지', mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public goodGoodStatusPut(good_id: string, good_status: '주문가능' | '일시품절' | '판매중지', mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_id === null || good_id === undefined) {
            throw new Error('Required parameter good_id was null or undefined when calling goodGoodStatusPut.');
        }
        if (good_status === null || good_status === undefined) {
            throw new Error('Required parameter good_status was null or undefined when calling goodGoodStatusPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling goodGoodStatusPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling goodGoodStatusPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling goodGoodStatusPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_id !== undefined && good_id !== null) {
            queryParameters = queryParameters.set('good_id', <any>good_id);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', <any>good_status);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', <any>mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', <any>mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', <any>mod_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/good/good_status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상단노출 수정
     * 
     * @param good_id 상품 ID
     * @param good_tag 상단노출
     * @param mod_emp_id 수정 간사 ID
     * @param mod_emp_name 수정 간사명
     * @param mod_emp_dept_name 수정 간사 부서명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodGoodTagPut(good_id: string, good_tag: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public goodGoodTagPut(good_id: string, good_tag: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public goodGoodTagPut(good_id: string, good_tag: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public goodGoodTagPut(good_id: string, good_tag: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_id === null || good_id === undefined) {
            throw new Error('Required parameter good_id was null or undefined when calling goodGoodTagPut.');
        }
        if (good_tag === null || good_tag === undefined) {
            throw new Error('Required parameter good_tag was null or undefined when calling goodGoodTagPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling goodGoodTagPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling goodGoodTagPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling goodGoodTagPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_id !== undefined && good_id !== null) {
            queryParameters = queryParameters.set('good_id', <any>good_id);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', <any>good_tag);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', <any>mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', <any>mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', <any>mod_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/good/good_tag`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 전체 상품 목록 조회
     * 
     * @param untreated 미취급포함
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodListGet(untreated?: 'Y', observe?: 'body', reportProgress?: boolean): Observable<Array<GoodData>>;
    public goodListGet(untreated?: 'Y', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GoodData>>>;
    public goodListGet(untreated?: 'Y', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GoodData>>>;
    public goodListGet(untreated?: 'Y', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (untreated !== undefined && untreated !== null) {
            queryParameters = queryParameters.set('untreated', <any>untreated);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GoodData>>(`${this.basePath}/good_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 시스템에 의한 상품 가격 변동 처리
     * 시스템에 의한 상품 가격 변동 처리
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodPriceChangeSystemGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public goodPriceChangeSystemGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public goodPriceChangeSystemGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public goodPriceChangeSystemGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/good_price_change/system`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 조회
     * 
     * @param good_name 상품명
     * @param barcode 바코드
     * @param good_div_code 상품 구분
     * @param maker_name 생산자명
     * @param tax_flg 과세여부
     * @param dealing_div 취급여부
     * @param logistics_div 물류구분
     * @param good_status 상품상태
     * @param good_tag 상단노출
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenGoodGet(good_name?: string, barcode?: string, good_div_code?: string, maker_name?: string, tax_flg?: '과세' | '비과세', dealing_div?: '취급' | '미취급', logistics_div?: '그린물류' | '업체직배송', good_status?: '주문가능' | '일시품절' | '판매중지', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'body', reportProgress?: boolean): Observable<Array<GoodData>>;
    public greenGoodGet(good_name?: string, barcode?: string, good_div_code?: string, maker_name?: string, tax_flg?: '과세' | '비과세', dealing_div?: '취급' | '미취급', logistics_div?: '그린물류' | '업체직배송', good_status?: '주문가능' | '일시품절' | '판매중지', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GoodData>>>;
    public greenGoodGet(good_name?: string, barcode?: string, good_div_code?: string, maker_name?: string, tax_flg?: '과세' | '비과세', dealing_div?: '취급' | '미취급', logistics_div?: '그린물류' | '업체직배송', good_status?: '주문가능' | '일시품절' | '판매중지', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GoodData>>>;
    public greenGoodGet(good_name?: string, barcode?: string, good_div_code?: string, maker_name?: string, tax_flg?: '과세' | '비과세', dealing_div?: '취급' | '미취급', logistics_div?: '그린물류' | '업체직배송', good_status?: '주문가능' | '일시품절' | '판매중지', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (barcode !== undefined && barcode !== null) {
            queryParameters = queryParameters.set('barcode', <any>barcode);
        }
        if (good_div_code !== undefined && good_div_code !== null) {
            queryParameters = queryParameters.set('good_div_code', <any>good_div_code);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', <any>maker_name);
        }
        if (tax_flg !== undefined && tax_flg !== null) {
            queryParameters = queryParameters.set('tax_flg', <any>tax_flg);
        }
        if (dealing_div !== undefined && dealing_div !== null) {
            queryParameters = queryParameters.set('dealing_div', <any>dealing_div);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', <any>logistics_div);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', <any>good_status);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', <any>good_tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GoodData>>(`${this.basePath}/green_good`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품 이미지 미리보기
     * 상품 이미지 미리보기
     * @param file_uuid file_uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenGoodImagePreviewGet(file_uuid: string, observe?: 'body', reportProgress?: boolean): Observable<DownloadUrl>;
    public greenGoodImagePreviewGet(file_uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadUrl>>;
    public greenGoodImagePreviewGet(file_uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadUrl>>;
    public greenGoodImagePreviewGet(file_uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (file_uuid === null || file_uuid === undefined) {
            throw new Error('Required parameter file_uuid was null or undefined when calling greenGoodImagePreviewGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (file_uuid !== undefined && file_uuid !== null) {
            queryParameters = queryParameters.set('file_uuid', <any>file_uuid);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<DownloadUrl>(`${this.basePath}/green_good/image_preview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 등록
     * 
     * @param reg_emp_id 등록 간사 ID
     * @param reg_emp_name 등록 간사명
     * @param reg_emp_dept_name 등록 간사 부서명
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenGoodPost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostGreenGoodData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public greenGoodPost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostGreenGoodData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public greenGoodPost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostGreenGoodData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public greenGoodPost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostGreenGoodData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reg_emp_id === null || reg_emp_id === undefined) {
            throw new Error('Required parameter reg_emp_id was null or undefined when calling greenGoodPost.');
        }
        if (reg_emp_name === null || reg_emp_name === undefined) {
            throw new Error('Required parameter reg_emp_name was null or undefined when calling greenGoodPost.');
        }
        if (reg_emp_dept_name === null || reg_emp_dept_name === undefined) {
            throw new Error('Required parameter reg_emp_dept_name was null or undefined when calling greenGoodPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling greenGoodPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reg_emp_id !== undefined && reg_emp_id !== null) {
            queryParameters = queryParameters.set('reg_emp_id', <any>reg_emp_id);
        }
        if (reg_emp_name !== undefined && reg_emp_name !== null) {
            queryParameters = queryParameters.set('reg_emp_name', <any>reg_emp_name);
        }
        if (reg_emp_dept_name !== undefined && reg_emp_dept_name !== null) {
            queryParameters = queryParameters.set('reg_emp_dept_name', <any>reg_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/green_good`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 수정
     * 
     * @param sk sk
     * @param mod_emp_id 수정 간사 ID
     * @param mod_emp_name 수정 간사명
     * @param mod_emp_dept_name 수정 간사 부서명
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenGoodPut(sk: string, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, body: PutGreenGoodData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public greenGoodPut(sk: string, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, body: PutGreenGoodData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public greenGoodPut(sk: string, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, body: PutGreenGoodData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public greenGoodPut(sk: string, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, body: PutGreenGoodData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (sk === null || sk === undefined) {
            throw new Error('Required parameter sk was null or undefined when calling greenGoodPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling greenGoodPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling greenGoodPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling greenGoodPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling greenGoodPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sk !== undefined && sk !== null) {
            queryParameters = queryParameters.set('sk', <any>sk);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', <any>mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', <any>mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', <any>mod_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/green_good`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린물류 재고 마감일 설정 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenbusinessDeadlineGet(observe?: 'body', reportProgress?: boolean): Observable<GreenbusinessDeadline>;
    public greenbusinessDeadlineGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GreenbusinessDeadline>>;
    public greenbusinessDeadlineGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GreenbusinessDeadline>>;
    public greenbusinessDeadlineGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GreenbusinessDeadline>(`${this.basePath}/greenbusiness_deadline`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린물류 재고 마감일 설정 수정
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public greenbusinessDeadlinePut(body: GreenbusinessDeadline, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public greenbusinessDeadlinePut(body: GreenbusinessDeadline, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public greenbusinessDeadlinePut(body: GreenbusinessDeadline, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public greenbusinessDeadlinePut(body: GreenbusinessDeadline, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling greenbusinessDeadlinePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/greenbusiness_deadline`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 재고 마감일 설정 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryDeadlineGet(observe?: 'body', reportProgress?: boolean): Observable<InventoryDeadline>;
    public inventoryDeadlineGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryDeadline>>;
    public inventoryDeadlineGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryDeadline>>;
    public inventoryDeadlineGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InventoryDeadline>(`${this.basePath}/inventory_deadline`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 재고 마감일 설정 수정
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryDeadlinePut(body: InventoryDeadline, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public inventoryDeadlinePut(body: InventoryDeadline, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public inventoryDeadlinePut(body: InventoryDeadline, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public inventoryDeadlinePut(body: InventoryDeadline, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling inventoryDeadlinePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/inventory_deadline`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 재고 오차율 기준 설정 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryErrorRateGet(observe?: 'body', reportProgress?: boolean): Observable<InventoryErrorRate>;
    public inventoryErrorRateGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryErrorRate>>;
    public inventoryErrorRateGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryErrorRate>>;
    public inventoryErrorRateGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InventoryErrorRate>(`${this.basePath}/inventory_error_rate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 재고 오차율 기준 설정 수정
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryErrorRatePut(body: InventoryErrorRate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public inventoryErrorRatePut(body: InventoryErrorRate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public inventoryErrorRatePut(body: InventoryErrorRate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public inventoryErrorRatePut(body: InventoryErrorRate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling inventoryErrorRatePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/inventory_error_rate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 택배 입고처 목록 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelGivePlaceListGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelGivePlace>>;
    public parcelGivePlaceListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelGivePlace>>>;
    public parcelGivePlaceListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelGivePlace>>>;
    public parcelGivePlaceListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelGivePlace>>(`${this.basePath}/parcel_give_place_list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 택배 입고처 설정 조회
     * 
     * @param recycle_place 되살림터
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelGivePlaceSetGet(recycle_place: string, observe?: 'body', reportProgress?: boolean): Observable<ParcelGivePlace>;
    public parcelGivePlaceSetGet(recycle_place: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelGivePlace>>;
    public parcelGivePlaceSetGet(recycle_place: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelGivePlace>>;
    public parcelGivePlaceSetGet(recycle_place: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling parcelGivePlaceSetGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelGivePlace>(`${this.basePath}/parcel_give_place_set`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 택배 입고처 콜 접수 제한수 수정
     * 
     * @param recycle_place 되살림터
     * @param parcel_limit 접수 제한수
     * @param mod_emp_id 수정 간사 ID
     * @param mod_emp_name 수정 간사명
     * @param mod_emp_dept_name 수정 간사 부서명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelGivePlaceSetParcelLimitPut(recycle_place: string, parcel_limit: number, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelGivePlaceSetParcelLimitPut(recycle_place: string, parcel_limit: number, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelGivePlaceSetParcelLimitPut(recycle_place: string, parcel_limit: number, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelGivePlaceSetParcelLimitPut(recycle_place: string, parcel_limit: number, mod_emp_id: string, mod_emp_name: string, mod_emp_dept_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        if (parcel_limit === null || parcel_limit === undefined) {
            throw new Error('Required parameter parcel_limit was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (parcel_limit !== undefined && parcel_limit !== null) {
            queryParameters = queryParameters.set('parcel_limit', <any>parcel_limit);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', <any>mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', <any>mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', <any>mod_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/parcel_give_place_set/parcel_limit`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 택배 입고처 설정 수정
     * 
     * @param recycle_place 되살림터
     * @param set_yn 설정여부
     * @param reg_emp_id 설정 간사 ID
     * @param reg_emp_name 설정 간사명
     * @param reg_emp_dept_name 설정 간사 부서명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelGivePlaceSetPut(recycle_place: string, set_yn: 'Y' | 'N', reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelGivePlaceSetPut(recycle_place: string, set_yn: 'Y' | 'N', reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelGivePlaceSetPut(recycle_place: string, set_yn: 'Y' | 'N', reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelGivePlaceSetPut(recycle_place: string, set_yn: 'Y' | 'N', reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling parcelGivePlaceSetPut.');
        }
        if (set_yn === null || set_yn === undefined) {
            throw new Error('Required parameter set_yn was null or undefined when calling parcelGivePlaceSetPut.');
        }
        if (reg_emp_id === null || reg_emp_id === undefined) {
            throw new Error('Required parameter reg_emp_id was null or undefined when calling parcelGivePlaceSetPut.');
        }
        if (reg_emp_name === null || reg_emp_name === undefined) {
            throw new Error('Required parameter reg_emp_name was null or undefined when calling parcelGivePlaceSetPut.');
        }
        if (reg_emp_dept_name === null || reg_emp_dept_name === undefined) {
            throw new Error('Required parameter reg_emp_dept_name was null or undefined when calling parcelGivePlaceSetPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (set_yn !== undefined && set_yn !== null) {
            queryParameters = queryParameters.set('set_yn', <any>set_yn);
        }
        if (reg_emp_id !== undefined && reg_emp_id !== null) {
            queryParameters = queryParameters.set('reg_emp_id', <any>reg_emp_id);
        }
        if (reg_emp_name !== undefined && reg_emp_name !== null) {
            queryParameters = queryParameters.set('reg_emp_name', <any>reg_emp_name);
        }
        if (reg_emp_dept_name !== undefined && reg_emp_dept_name !== null) {
            queryParameters = queryParameters.set('reg_emp_dept_name', <any>reg_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/parcel_give_place_set`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 특정 그린상품 가격변동 정보 삭제
     * 
     * @param sk sk
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceChangeDelete(sk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public priceChangeDelete(sk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public priceChangeDelete(sk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public priceChangeDelete(sk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (sk === null || sk === undefined) {
            throw new Error('Required parameter sk was null or undefined when calling priceChangeDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sk !== undefined && sk !== null) {
            queryParameters = queryParameters.set('sk', <any>sk);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/price_change`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 가격변동 목록조회
     * 
     * @param from_date 조회 시작일
     * @param to_date 조회 종료일
     * @param app_flg 적용여부
     * @param good_name 상품명
     * @param remark 비고
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceChangeGet(from_date: string, to_date: string, app_flg: '전체' | '적용' | '미적용', good_name?: string, remark?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PriceChangeData>>;
    public priceChangeGet(from_date: string, to_date: string, app_flg: '전체' | '적용' | '미적용', good_name?: string, remark?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PriceChangeData>>>;
    public priceChangeGet(from_date: string, to_date: string, app_flg: '전체' | '적용' | '미적용', good_name?: string, remark?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PriceChangeData>>>;
    public priceChangeGet(from_date: string, to_date: string, app_flg: '전체' | '적용' | '미적용', good_name?: string, remark?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (from_date === null || from_date === undefined) {
            throw new Error('Required parameter from_date was null or undefined when calling priceChangeGet.');
        }
        if (to_date === null || to_date === undefined) {
            throw new Error('Required parameter to_date was null or undefined when calling priceChangeGet.');
        }
        if (app_flg === null || app_flg === undefined) {
            throw new Error('Required parameter app_flg was null or undefined when calling priceChangeGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from_date !== undefined && from_date !== null) {
            queryParameters = queryParameters.set('from_date', <any>from_date);
        }
        if (to_date !== undefined && to_date !== null) {
            queryParameters = queryParameters.set('to_date', <any>to_date);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (app_flg !== undefined && app_flg !== null) {
            queryParameters = queryParameters.set('app_flg', <any>app_flg);
        }
        if (remark !== undefined && remark !== null) {
            queryParameters = queryParameters.set('remark', <any>remark);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PriceChangeData>>(`${this.basePath}/price_change`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 가격변동 등록
     * 
     * @param reg_emp_id 등록 간사 ID
     * @param reg_emp_name 등록 간사명
     * @param reg_emp_dept_name 등록 간사 부서명
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceChangePost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostPriceChangeData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public priceChangePost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostPriceChangeData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public priceChangePost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostPriceChangeData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public priceChangePost(reg_emp_id: string, reg_emp_name: string, reg_emp_dept_name: string, body: PostPriceChangeData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reg_emp_id === null || reg_emp_id === undefined) {
            throw new Error('Required parameter reg_emp_id was null or undefined when calling priceChangePost.');
        }
        if (reg_emp_name === null || reg_emp_name === undefined) {
            throw new Error('Required parameter reg_emp_name was null or undefined when calling priceChangePost.');
        }
        if (reg_emp_dept_name === null || reg_emp_dept_name === undefined) {
            throw new Error('Required parameter reg_emp_dept_name was null or undefined when calling priceChangePost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling priceChangePost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reg_emp_id !== undefined && reg_emp_id !== null) {
            queryParameters = queryParameters.set('reg_emp_id', <any>reg_emp_id);
        }
        if (reg_emp_name !== undefined && reg_emp_name !== null) {
            queryParameters = queryParameters.set('reg_emp_name', <any>reg_emp_name);
        }
        if (reg_emp_dept_name !== undefined && reg_emp_dept_name !== null) {
            queryParameters = queryParameters.set('reg_emp_dept_name', <any>reg_emp_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/price_change`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터 재고 마감일 설정 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recyclePlaceDeadlineGet(observe?: 'body', reportProgress?: boolean): Observable<RecyclePlaceDeadline>;
    public recyclePlaceDeadlineGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecyclePlaceDeadline>>;
    public recyclePlaceDeadlineGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecyclePlaceDeadline>>;
    public recyclePlaceDeadlineGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RecyclePlaceDeadline>(`${this.basePath}/recycle_place_deadline`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터 재고 마감일 설정 수정
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recyclePlaceDeadlinePut(body: RecyclePlaceDeadline, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public recyclePlaceDeadlinePut(body: RecyclePlaceDeadline, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public recyclePlaceDeadlinePut(body: RecyclePlaceDeadline, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public recyclePlaceDeadlinePut(body: RecyclePlaceDeadline, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling recyclePlaceDeadlinePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/recycle_place_deadline`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매출 마감일 설정 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salesDeadlineGet(observe?: 'body', reportProgress?: boolean): Observable<SalesDeadline>;
    public salesDeadlineGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesDeadline>>;
    public salesDeadlineGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesDeadline>>;
    public salesDeadlineGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SalesDeadline>(`${this.basePath}/sales_deadline`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매출 마감일 설정 수정
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salesDeadlinePut(body: SalesDeadline, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public salesDeadlinePut(body: SalesDeadline, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public salesDeadlinePut(body: SalesDeadline, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public salesDeadlinePut(body: SalesDeadline, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling salesDeadlinePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/sales_deadline`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 물류분류 조회
     * 
     * @param warehouse_div_depth 물류분류단계
     * @param warehouse_div_code 물류분류코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseDivSearchGet(warehouse_div_depth: number, warehouse_div_code: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WarehouseCodeData>>;
    public warehouseDivSearchGet(warehouse_div_depth: number, warehouse_div_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WarehouseCodeData>>>;
    public warehouseDivSearchGet(warehouse_div_depth: number, warehouse_div_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WarehouseCodeData>>>;
    public warehouseDivSearchGet(warehouse_div_depth: number, warehouse_div_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (warehouse_div_depth === null || warehouse_div_depth === undefined) {
            throw new Error('Required parameter warehouse_div_depth was null or undefined when calling warehouseDivSearchGet.');
        }
        if (warehouse_div_code === null || warehouse_div_code === undefined) {
            throw new Error('Required parameter warehouse_div_code was null or undefined when calling warehouseDivSearchGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (warehouse_div_depth !== undefined && warehouse_div_depth !== null) {
            queryParameters = queryParameters.set('warehouse_div_depth', <any>warehouse_div_depth);
        }
        if (warehouse_div_code !== undefined && warehouse_div_code !== null) {
            queryParameters = queryParameters.set('warehouse_div_code', <any>warehouse_div_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WarehouseCodeData>>(`${this.basePath}/warehouse_div/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 물류분류 등록
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseDivSearchPost(body: WarehouseCodeData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public warehouseDivSearchPost(body: WarehouseCodeData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public warehouseDivSearchPost(body: WarehouseCodeData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public warehouseDivSearchPost(body: WarehouseCodeData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling warehouseDivSearchPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/warehouse_div/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
