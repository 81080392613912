/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Empty } from '../model/empty';
import { Failure } from '../model/failure';
import { Phonebook } from '../model/phonebook';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PhonebookService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 폰북 리스트 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public phonebooksGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Phonebook>>;
    public phonebooksGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Phonebook>>>;
    public phonebooksGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Phonebook>>>;
    public phonebooksGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Phonebook>>(`${this.basePath}/phonebooks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 폰북 삭제
     * 
     * @param name 삭제할 폰북 이름
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public phonebooksNameDelete(name: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public phonebooksNameDelete(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public phonebooksNameDelete(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public phonebooksNameDelete(name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling phonebooksNameDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Empty>(`${this.basePath}/phonebooks/${encodeURIComponent(String(name))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 폰북 조회
     * 
     * @param name 폰북 이름
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public phonebooksNameGet(name: string, observe?: 'body', reportProgress?: boolean): Observable<Phonebook>;
    public phonebooksNameGet(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Phonebook>>;
    public phonebooksNameGet(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Phonebook>>;
    public phonebooksNameGet(name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling phonebooksNameGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Phonebook>(`${this.basePath}/phonebooks/${encodeURIComponent(String(name))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 폰북 수정
     * 
     * @param name 폰북 이름
     * @param phonebook 폰북 정보
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public phonebooksNamePut(name: string, phonebook: Phonebook, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public phonebooksNamePut(name: string, phonebook: Phonebook, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public phonebooksNamePut(name: string, phonebook: Phonebook, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public phonebooksNamePut(name: string, phonebook: Phonebook, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling phonebooksNamePut.');
        }
        if (phonebook === null || phonebook === undefined) {
            throw new Error('Required parameter phonebook was null or undefined when calling phonebooksNamePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Empty>(`${this.basePath}/phonebooks/${encodeURIComponent(String(name))}`,
            phonebook,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 폰북 생성
     * 
     * @param phonebook 
     * @param is_personal 개인용 여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public phonebooksPost(phonebook: Phonebook, is_personal?: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public phonebooksPost(phonebook: Phonebook, is_personal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public phonebooksPost(phonebook: Phonebook, is_personal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public phonebooksPost(phonebook: Phonebook, is_personal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (phonebook === null || phonebook === undefined) {
            throw new Error('Required parameter phonebook was null or undefined when calling phonebooksPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (is_personal !== undefined && is_personal !== null) {
            queryParameters = queryParameters.set('is_personal', <any>is_personal);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/phonebooks`,
            phonebook,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
