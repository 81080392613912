<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style">
      <label>기간</label>
      <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()"
        (dateChange)="chosenMonthHandler($event.target.value, end)" formControlName="date" readonly>
      <mat-datepicker #end startView="year" (monthSelected)="chosenMonthHandler($event, end)"></mat-datepicker>
    </div>

    <app-dept-auto-complete [initLoginDeptFlg]="true" (selected)="selectDept($event)"></app-dept-auto-complete>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="row">
      <div class='col'>
        <h3>{{date}}월 되살림터 통합지표</h3>
      </div>
      <div class="col btns_right_align">
        <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
      </div>
    </div>

    <span>산정폐기량 계산 수식 : 산정폐기량 = 기부량(입고량)-생산량-행사생산량-창고보관량-센터간순환량(생산전)-매장생산량-행사폐기량</span>

    <div class="m--space-20"></div>

    <div class="statistics-mat-table" #TABLE>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="recycle_place_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>되살림터</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.recycle_place_name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_cate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>물품구분</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_cate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="retrieve_sum">
          <mat-header-cell *matHeaderCellDef mat-sort-header>기부량(입고량)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.retrieve_sum | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="production_sum">
          <mat-header-cell *matHeaderCellDef mat-sort-header>생산량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.production_sum | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="event_production">
          <mat-header-cell *matHeaderCellDef mat-sort-header>행사생산량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.event_production | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="storage_capacity">
          <mat-header-cell *matHeaderCellDef mat-sort-header>창고보관량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.storage_capacity | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="center_cycle_product_before">
          <mat-header-cell *matHeaderCellDef mat-sort-header>센터간순환량(생산전)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.center_cycle_product_before | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="center_cycle_product_after">
          <mat-header-cell *matHeaderCellDef mat-sort-header>센터간순환량(생산후)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.center_cycle_product_after | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="shop_production">
          <mat-header-cell *matHeaderCellDef mat-sort-header>매장생산량(점생산)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.shop_production | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="event_disposal">
          <mat-header-cell *matHeaderCellDef mat-sort-header>행사폐기량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.event_disposal | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="calculation_disposal">
          <mat-header-cell *matHeaderCellDef mat-sort-header>산정폐기량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.calculation_disposal | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="disposal_rate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>폐기율</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.disposal_rate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="shipping_sum">
          <mat-header-cell *matHeaderCellDef mat-sort-header>출고량</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.shipping_sum | currencyMask}}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>