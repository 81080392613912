/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsGivingsService = /** @class */ (function () {
    function EsGivingsService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsGivingsService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsGivingsService.prototype.givingsCallAllDurationGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallAllDurationGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallAllDurationGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/call/all_duration", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsCallAreaGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallAreaGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallAreaGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/call/area", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsCallDurationGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallDurationGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallDurationGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/call/duration", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsCallGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/call", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsCallHistoryGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallHistoryGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallHistoryGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/call/history", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsCallWatingGet = function (gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallWatingGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallWatingGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/call/wating", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsExcelGet = function (gte_date, lte_date, dept_id, is_anonymous, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        if (is_anonymous !== undefined && is_anonymous !== null) {
            queryParameters = queryParameters.set('is_anonymous', is_anonymous);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsPlaceGet = function (gte_date, lte_date, dept_id, give_route, is_anonymous, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsPlaceGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsPlaceGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        if (give_route !== undefined && give_route !== null) {
            queryParameters = queryParameters.set('give_route', give_route);
        }
        if (is_anonymous !== undefined && is_anonymous !== null) {
            queryParameters = queryParameters.set('is_anonymous', is_anonymous);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/place", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsReceptionDateGet = function (gte_date, lte_date, date_div, give_apply_route, give_route, give_status, logistics_collect_confirm, logistics_collect_owner_flg, give_reception_hold_flg, give_reception_joiner_cellp_no, give_place_dept_id, give_reception_joiner_name, logistics_collect_area_id, give_addr, give_tags, except_reception, give_reception_emp_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsReceptionDateGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsReceptionDateGet.');
        }
        if (date_div === null || date_div === undefined) {
            throw new Error('Required parameter date_div was null or undefined when calling givingsReceptionDateGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (date_div !== undefined && date_div !== null) {
            queryParameters = queryParameters.set('date_div', date_div);
        }
        if (give_apply_route !== undefined && give_apply_route !== null) {
            queryParameters = queryParameters.set('give_apply_route', give_apply_route);
        }
        if (give_route !== undefined && give_route !== null) {
            queryParameters = queryParameters.set('give_route', give_route);
        }
        if (give_status !== undefined && give_status !== null) {
            queryParameters = queryParameters.set('give_status', give_status);
        }
        if (logistics_collect_confirm !== undefined && logistics_collect_confirm !== null) {
            queryParameters = queryParameters.set('logistics_collect_confirm', logistics_collect_confirm);
        }
        if (logistics_collect_owner_flg !== undefined && logistics_collect_owner_flg !== null) {
            queryParameters = queryParameters.set('logistics_collect_owner_flg', logistics_collect_owner_flg);
        }
        if (give_reception_hold_flg !== undefined && give_reception_hold_flg !== null) {
            queryParameters = queryParameters.set('give_reception_hold_flg', give_reception_hold_flg);
        }
        if (give_reception_joiner_cellp_no !== undefined && give_reception_joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_cellp_no', give_reception_joiner_cellp_no);
        }
        if (give_place_dept_id !== undefined && give_place_dept_id !== null) {
            queryParameters = queryParameters.set('give_place_dept_id', give_place_dept_id);
        }
        if (give_reception_joiner_name !== undefined && give_reception_joiner_name !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_name', give_reception_joiner_name);
        }
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', logistics_collect_area_id);
        }
        if (give_addr !== undefined && give_addr !== null) {
            queryParameters = queryParameters.set('give_addr', give_addr);
        }
        if (give_tags !== undefined && give_tags !== null) {
            queryParameters = queryParameters.set('give_tags', give_tags);
        }
        if (except_reception !== undefined && except_reception !== null) {
            queryParameters = queryParameters.set('except_reception', except_reception);
        }
        if (give_reception_emp_name !== undefined && give_reception_emp_name !== null) {
            queryParameters = queryParameters.set('give_reception_emp_name', give_reception_emp_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/reception/date", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsReceptionGet = function (give_reception_joiner_div, give_reception_joiner_name, give_reception_joiner_cellp_no, give_reception_emp_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (give_reception_joiner_div !== undefined && give_reception_joiner_div !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_div', give_reception_joiner_div);
        }
        if (give_reception_joiner_name !== undefined && give_reception_joiner_name !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_name', give_reception_joiner_name);
        }
        if (give_reception_joiner_cellp_no !== undefined && give_reception_joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_cellp_no', give_reception_joiner_cellp_no);
        }
        if (give_reception_emp_name !== undefined && give_reception_emp_name !== null) {
            queryParameters = queryParameters.set('give_reception_emp_name', give_reception_emp_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/reception", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsRegionGet = function (gte_date, lte_date, region, give_route, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsRegionGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsRegionGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (region !== undefined && region !== null) {
            queryParameters = queryParameters.set('region', region);
        }
        if (give_route !== undefined && give_route !== null) {
            queryParameters = queryParameters.set('give_route', give_route);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/region", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsGivingsService.prototype.givingsSearchBoardGet = function (gte_date, lte_date, dept_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsSearchBoardGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsSearchBoardGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (dept_name !== undefined && dept_name !== null) {
            queryParameters = queryParameters.set('dept_name', dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/givings/search_board", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsGivingsService;
}());
export { EsGivingsService };
