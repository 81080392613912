/**
 * angel
 *  ## 자원활동관련 데이타를 관리한다. 
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AngelId } from '../model/angelId';
import { DownloadUrl } from '../model/downloadUrl';
import { EncodedFile } from '../model/encodedFile';
import { Failure } from '../model/failure';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FilesService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9905/angel/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 자원활동 첨부파일을 삭제.
     * 자원활동 첨부파일을 삭제한다.
     * @param type_id angel_id/group_id
     * @param file_uuid file_uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelAngelIdFilesDelete(type_id: string, file_uuid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelAngelIdFilesDelete(type_id: string, file_uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelAngelIdFilesDelete(type_id: string, file_uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelAngelIdFilesDelete(type_id: string, file_uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type_id === null || type_id === undefined) {
            throw new Error('Required parameter type_id was null or undefined when calling angelAngelIdFilesDelete.');
        }
        if (file_uuid === null || file_uuid === undefined) {
            throw new Error('Required parameter file_uuid was null or undefined when calling angelAngelIdFilesDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (file_uuid !== undefined && file_uuid !== null) {
            queryParameters = queryParameters.set('file_uuid', <any>file_uuid);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/angels/${encodeURIComponent(String(type_id))}/files`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자원활동 첨부파일 다운로드.
     * 활동천사 첨부파일을 다운로드한다.
     * @param type_id angel_id/group_id
     * @param file_uuid file_uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelAngelIdFilesGet(type_id: string, file_uuid: string, observe?: 'body', reportProgress?: boolean): Observable<DownloadUrl>;
    public angelAngelIdFilesGet(type_id: string, file_uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadUrl>>;
    public angelAngelIdFilesGet(type_id: string, file_uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadUrl>>;
    public angelAngelIdFilesGet(type_id: string, file_uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type_id === null || type_id === undefined) {
            throw new Error('Required parameter type_id was null or undefined when calling angelAngelIdFilesGet.');
        }
        if (file_uuid === null || file_uuid === undefined) {
            throw new Error('Required parameter file_uuid was null or undefined when calling angelAngelIdFilesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (file_uuid !== undefined && file_uuid !== null) {
            queryParameters = queryParameters.set('file_uuid', <any>file_uuid);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<DownloadUrl>(`${this.basePath}/angels/${encodeURIComponent(String(type_id))}/files`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자원활동 첨부파일 등록.
     * 자원활동  첨부파일을 등록한다
     * @param type_id angel_id/group_id
     * @param up_file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelAngelIdFilesPost(type_id: string, up_file: EncodedFile, observe?: 'body', reportProgress?: boolean): Observable<AngelId>;
    public angelAngelIdFilesPost(type_id: string, up_file: EncodedFile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AngelId>>;
    public angelAngelIdFilesPost(type_id: string, up_file: EncodedFile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AngelId>>;
    public angelAngelIdFilesPost(type_id: string, up_file: EncodedFile, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type_id === null || type_id === undefined) {
            throw new Error('Required parameter type_id was null or undefined when calling angelAngelIdFilesPost.');
        }
        if (up_file === null || up_file === undefined) {
            throw new Error('Required parameter up_file was null or undefined when calling angelAngelIdFilesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AngelId>(`${this.basePath}/angels/${encodeURIComponent(String(type_id))}/files`,
            up_file,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 사진이미지 삭제.
     * 활동천사 사진이미지를 삭제한다.
     * @param angel_id 사용자 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelAngelIdPictureDelete(angel_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelAngelIdPictureDelete(angel_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelAngelIdPictureDelete(angel_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelAngelIdPictureDelete(angel_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelAngelIdPictureDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/angel/${encodeURIComponent(String(angel_id))}/picture`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 사진이미지 다운로드.
     * 활동천사 사진이미지를 다운로드한다.
     * @param angel_id 사용자 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelAngelIdPictureGet(angel_id: string, observe?: 'body', reportProgress?: boolean): Observable<DownloadUrl>;
    public angelAngelIdPictureGet(angel_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadUrl>>;
    public angelAngelIdPictureGet(angel_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadUrl>>;
    public angelAngelIdPictureGet(angel_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelAngelIdPictureGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<DownloadUrl>(`${this.basePath}/angel/${encodeURIComponent(String(angel_id))}/picture`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 사진이미지 등록.
     * 활동천사 사진이미지를 등록한다
     * @param angel_id 사용자 아이디
     * @param up_file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelAngelIdPicturePost(angel_id: string, up_file: EncodedFile, observe?: 'body', reportProgress?: boolean): Observable<AngelId>;
    public angelAngelIdPicturePost(angel_id: string, up_file: EncodedFile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AngelId>>;
    public angelAngelIdPicturePost(angel_id: string, up_file: EncodedFile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AngelId>>;
    public angelAngelIdPicturePost(angel_id: string, up_file: EncodedFile, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelAngelIdPicturePost.');
        }
        if (up_file === null || up_file === undefined) {
            throw new Error('Required parameter up_file was null or undefined when calling angelAngelIdPicturePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AngelId>(`${this.basePath}/angel/${encodeURIComponent(String(angel_id))}/picture`,
            up_file,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
