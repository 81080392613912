import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule, MatTableModule, MatSortModule, MatDatepickerModule } from '@angular/material';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AngelDayTimeComponent } from './angel-day-time/angel-day-time.component';
import { AngelActivityStatusComponent } from './angel-activity-status/angel-activity-status.component';
import { AngelGenderAnnualComponent } from './angel-gender-annual/angel-gender-annual.component';
import { AngelRegionAnnualComponent } from './angel-region-annual/angel-region-annual.component';
import { AngelAgeAnnualComponent } from './angel-age-annual/angel-age-annual.component';
import { AngelTimeAnnualComponent } from './angel-time-annual/angel-time-annual.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ShareModule } from 'src/app/share/share.module';
import { AngelWorkyearAnnualComponent } from './angel-workyear-annual/angel-workyear-annual.component';
import { AngelActivityStatusAnnualComponent } from './angel-activity-status-annual/angel-activity-status-annual.component';
import { AngelComponent } from './angel.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        MatDatepickerModule,
        NgbPaginationModule,
        ShareModule
    ],
    declarations: [
        AngelComponent,
        AngelDayTimeComponent,
        AngelActivityStatusComponent,
        AngelGenderAnnualComponent,
        AngelRegionAnnualComponent,
        AngelAgeAnnualComponent,
        AngelTimeAnnualComponent,
        AngelWorkyearAnnualComponent,
        AngelActivityStatusAnnualComponent,
    ]
})
export class AngelModule { }
