import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS } from '@angular/material';
import { EsRateSales, EsSalesService } from 'src/app/restapi/be-esquery';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DateRangePicker } from 'src/app/share/components/directives/date-range-picker.directive';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-supply-rate-by-sales',
  templateUrl: './supply-rate-by-sales.component.html',
  styleUrls: ['./supply-rate-by-sales.component.scss'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS },
  ]
})
export class SupplyRateBySalesComponent implements OnInit {
  form: FormGroup;
  dataSource: MatTableDataSource<EsRateSales> = new MatTableDataSource([]);
  displayedColumns = ['recycle_place_name', 'shop_name', 'good_second_category_name', 'good_third_category_name', 'normal_cnt', 'event_cnt', 'none_cnt', 'tot_cnt',
    'rate', 'tot_sale_amt', 'no_tax_sale_amt', 'tot_sale_qty', 'avg_price'];
  paginationCollection: Array<any> = new Array<any>();
  page: number = 1;
  pageSize: number = 10;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder, private esSalesService: EsSalesService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy(): void { }

  initForm() {
    this.form = this.fb.group({
      gte_date: [new Date()],
      lte_date: [new Date()],
      dept_name: ['']
    });
  }

  get fc_gteDate() { return this.form.get('gte_date'); }
  get fc_lteDate() { return this.form.get('lte_date'); }
  get fc_deptName() { return this.form.get('dept_name'); }

  initPage(sort?: Sort) {
    if (!this.paginationCollection) this.paginationCollection = [];

    let sorted = this.paginationCollection;
    if (sort) {
      sorted = this.paginationCollection.sort((a: any, b: any) => {
        let result = 0;

        if (a[sort.active] == undefined) a[sort.active] = "";
        if (b[sort.active] == undefined) b[sort.active] = "";

        if (a[sort.active] < b[sort.active]) {
          result = -1;
        } else if (a[sort.active] > b[sort.active]) {
          result = 1;
        }
        return result * (sort.direction === 'asc' ? 1 : -1);
      });
    }
    this.paginationCollection = sorted;
    this.setPage(this.page);
  }

  setPage(currentPage: number) {
    this.page = currentPage;
    const dataSource = this.paginationCollection.slice(
      (currentPage - 1) * this.pageSize,
      currentPage * this.pageSize
    );
    this.dataSource.data = dataSource;
    this.dataSource.sort = this.sort;
  }

  rangeComplete(event: DateRangePicker) {
    this.form.patchValue({
      gte_date: event.startDate,
      lte_date: event.endDate
    });
  }

  search() {
    this.esSalesService.supplyRateBySalesGet(dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value), this.fc_deptName.value && this.fc_deptName.value.trim() != '' ?
      this.fc_deptName.value : null).subscribe({
        next: res => {
          this.paginationCollection = res;
        },
        complete: () => {
          this.setPage(this.page);
          this.sort.sortChange.emit({ active: this.sort.active, direction: this.sort.direction });
        }
      });
  }

  onClickExcelExport() {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertDatasource());
    const wscols = [{ wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 },
    { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, '판매대비공급통계');
    XLSX.writeFile(wb, `판매대비공급통계_${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}.xlsx`);
  }

  convertDatasource() {
    const xlsxDatasource: Array<any> = [];

    this.paginationCollection.forEach((item: any) => {
      xlsxDatasource.push({
        '되살림터': item.recycle_place_name,
        '매장': item.shop_name,
        '물품구분': item.good_second_category_name,
        '물품상세': item.good_third_category_name,
        '일반출고량': item.normal_cnt,
        '행사출고량': item.event_cnt,
        '무택출고량': item.none_cnt,
        '총출고량': item.tot_cnt,
        '판매대비공급율': item.rate,
        '매출': item.tot_sale_amt,
        'VAT제외매출': item.no_tax_sale_amt,
        '판매수량': item.tot_sale_qty,
        '평균단가': item.avg_price
      });
    });
    return xlsxDatasource;
  }

}
