/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsDgShopPayment } from '../model/esDgShopPayment';
import { EsDownloadUrl } from '../model/esDownloadUrl';
import { EsExShopPayment } from '../model/esExShopPayment';
import { EsFailure } from '../model/esFailure';
import { EsGreenBuying } from '../model/esGreenBuying';
import { EsGreenInventory } from '../model/esGreenInventory';
import { EsGreenItemPayment } from '../model/esGreenItemPayment';
import { EsReport } from '../model/esReport';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsAccountsupportService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 그린상품 매입내역(생산자)
     * 그린상품 매입내역(생산자)가져오기
     * @param gte_date 
     * @param lte_date 
     * @param date_type 일자구분
     * @param view_type view형식
     * @param good_category_name 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buyingGreenListGet(gte_date: string, lte_date: string, date_type: '입고일자' | '세금계산서일자', view_type: '일자별' | '상품별' | '일자별합계' | '매입가별', good_category_name?: '공익상품' | '메아리상품', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenBuying>>;
    public buyingGreenListGet(gte_date: string, lte_date: string, date_type: '입고일자' | '세금계산서일자', view_type: '일자별' | '상품별' | '일자별합계' | '매입가별', good_category_name?: '공익상품' | '메아리상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenBuying>>>;
    public buyingGreenListGet(gte_date: string, lte_date: string, date_type: '입고일자' | '세금계산서일자', view_type: '일자별' | '상품별' | '일자별합계' | '매입가별', good_category_name?: '공익상품' | '메아리상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenBuying>>>;
    public buyingGreenListGet(gte_date: string, lte_date: string, date_type: '입고일자' | '세금계산서일자', view_type: '일자별' | '상품별' | '일자별합계' | '매입가별', good_category_name?: '공익상품' | '메아리상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling buyingGreenListGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling buyingGreenListGet.');
        }
        if (date_type === null || date_type === undefined) {
            throw new Error('Required parameter date_type was null or undefined when calling buyingGreenListGet.');
        }
        if (view_type === null || view_type === undefined) {
            throw new Error('Required parameter view_type was null or undefined when calling buyingGreenListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (date_type !== undefined && date_type !== null) {
            queryParameters = queryParameters.set('date_type', <any>date_type);
        }
        if (view_type !== undefined && view_type !== null) {
            queryParameters = queryParameters.set('view_type', <any>view_type);
        }
        if (good_category_name !== undefined && good_category_name !== null) {
            queryParameters = queryParameters.set('good_category_name', <any>good_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenBuying>>(`${this.basePath}/accountsupport/buying_green_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증물품 종합수불
     * 기증물품 종합수불가져오기
     * @param account_month 결산월(YYYY-MM)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dgShopAllGet(account_month: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsDgShopPayment>>;
    public dgShopAllGet(account_month: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDgShopPayment>>>;
    public dgShopAllGet(account_month: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDgShopPayment>>>;
    public dgShopAllGet(account_month: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (account_month === null || account_month === undefined) {
            throw new Error('Required parameter account_month was null or undefined when calling dgShopAllGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account_month !== undefined && account_month !== null) {
            queryParameters = queryParameters.set('account_month', <any>account_month);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDgShopPayment>>(`${this.basePath}/accountsupport/dg_shop_all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증물품 종합수불(매장별)
     * 기증물품 종합수불(매장별)가져오기
     * @param account_month 결산월(YYYY-MM)
     * @param shop_name 매장명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dgShopListGet(account_month: string, shop_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsDgShopPayment>>;
    public dgShopListGet(account_month: string, shop_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDgShopPayment>>>;
    public dgShopListGet(account_month: string, shop_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDgShopPayment>>>;
    public dgShopListGet(account_month: string, shop_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (account_month === null || account_month === undefined) {
            throw new Error('Required parameter account_month was null or undefined when calling dgShopListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account_month !== undefined && account_month !== null) {
            queryParameters = queryParameters.set('account_month', <any>account_month);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDgShopPayment>>(`${this.basePath}/accountsupport/dg_shop_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 별정상품 종합수불
     * 별정상품 종합수불가져오기
     * @param account_month 결산월(YYYY-MM)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exShopAllGet(account_month: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsExShopPayment>>;
    public exShopAllGet(account_month: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsExShopPayment>>>;
    public exShopAllGet(account_month: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsExShopPayment>>>;
    public exShopAllGet(account_month: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (account_month === null || account_month === undefined) {
            throw new Error('Required parameter account_month was null or undefined when calling exShopAllGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account_month !== undefined && account_month !== null) {
            queryParameters = queryParameters.set('account_month', <any>account_month);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsExShopPayment>>(`${this.basePath}/accountsupport/ex_shop_all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 별정상품 종합수불(매장별)
     * 별정상품 종합수불(매장별)가져오기
     * @param account_month 결산월(YYYY-MM)
     * @param shop_name 매장명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exShopListGet(account_month: string, shop_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsExShopPayment>>;
    public exShopListGet(account_month: string, shop_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsExShopPayment>>>;
    public exShopListGet(account_month: string, shop_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsExShopPayment>>>;
    public exShopListGet(account_month: string, shop_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (account_month === null || account_month === undefined) {
            throw new Error('Required parameter account_month was null or undefined when calling exShopListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account_month !== undefined && account_month !== null) {
            queryParameters = queryParameters.set('account_month', <any>account_month);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsExShopPayment>>(`${this.basePath}/accountsupport/ex_shop_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 매입내역(생산자)
     * 그린상품 매입내역(생산자)가져오기
     * @param account_month 결산월(YYYY-MM)
     * @param view_type view형식
     * @param good_category_name 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryGreenListGet(account_month: string, view_type: '입고별' | '상품별' | '입고별합계', good_category_name?: '공익상품' | '메아리상품', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenInventory>>;
    public inventoryGreenListGet(account_month: string, view_type: '입고별' | '상품별' | '입고별합계', good_category_name?: '공익상품' | '메아리상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenInventory>>>;
    public inventoryGreenListGet(account_month: string, view_type: '입고별' | '상품별' | '입고별합계', good_category_name?: '공익상품' | '메아리상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenInventory>>>;
    public inventoryGreenListGet(account_month: string, view_type: '입고별' | '상품별' | '입고별합계', good_category_name?: '공익상품' | '메아리상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (account_month === null || account_month === undefined) {
            throw new Error('Required parameter account_month was null or undefined when calling inventoryGreenListGet.');
        }
        if (view_type === null || view_type === undefined) {
            throw new Error('Required parameter view_type was null or undefined when calling inventoryGreenListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account_month !== undefined && account_month !== null) {
            queryParameters = queryParameters.set('account_month', <any>account_month);
        }
        if (view_type !== undefined && view_type !== null) {
            queryParameters = queryParameters.set('view_type', <any>view_type);
        }
        if (good_category_name !== undefined && good_category_name !== null) {
            queryParameters = queryParameters.set('good_category_name', <any>good_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenInventory>>(`${this.basePath}/accountsupport/inventory_green_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증영수증작성로직(내부사용)
     * 기증영수증작성로직(내부사용) 응답용
     * @param tax_date 기증연도
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiptGet(tax_date: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public receiptGet(tax_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public receiptGet(tax_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public receiptGet(tax_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tax_date === null || tax_date === undefined) {
            throw new Error('Required parameter tax_date was null or undefined when calling receiptGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tax_date !== undefined && tax_date !== null) {
            queryParameters = queryParameters.set('tax_date', <any>tax_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/accountsupport/receipt`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증영수증작성내역 검색
     * 기증영수증작성내역 검색
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiptListGet(observe?: 'body', reportProgress?: boolean): Observable<Array<EsReport>>;
    public receiptListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsReport>>>;
    public receiptListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsReport>>>;
    public receiptListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsReport>>(`${this.basePath}/accountsupport/receipt_list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 국세청신고내역서작성(내부사용)
     * 결산지원에서 요청시 응답용
     * @param tax_date 국세신고연도
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public taxReportGet(tax_date: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public taxReportGet(tax_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public taxReportGet(tax_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public taxReportGet(tax_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tax_date === null || tax_date === undefined) {
            throw new Error('Required parameter tax_date was null or undefined when calling taxReportGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tax_date !== undefined && tax_date !== null) {
            queryParameters = queryParameters.set('tax_date', <any>tax_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/accountsupport/tax_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 소득공제신고내역서 검색
     * 소득공제신고내역서 검색
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public taxReportListGet(observe?: 'body', reportProgress?: boolean): Observable<Array<EsReport>>;
    public taxReportListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsReport>>>;
    public taxReportListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsReport>>>;
    public taxReportListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsReport>>(`${this.basePath}/accountsupport/tax_report_list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 종합수불
     * 그린상품 종합수불가져오기
     * @param account_month 결산월(YYYY-MM)
     * @param good_category_name 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tgmeGreenAllGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenItemPayment>>;
    public tgmeGreenAllGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenItemPayment>>>;
    public tgmeGreenAllGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenItemPayment>>>;
    public tgmeGreenAllGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (account_month === null || account_month === undefined) {
            throw new Error('Required parameter account_month was null or undefined when calling tgmeGreenAllGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account_month !== undefined && account_month !== null) {
            queryParameters = queryParameters.set('account_month', <any>account_month);
        }
        if (good_category_name !== undefined && good_category_name !== null) {
            queryParameters = queryParameters.set('good_category_name', <any>good_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenItemPayment>>(`${this.basePath}/accountsupport/tgme_green_all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 종합수불(매장/창고별)엑셀출력
     * 그린상품 종합수불(매장/창고별)엑셀출력
     * @param account_month 결산월(YYYY-MM)
     * @param good_category_name 상품분류
     * @param shop_name 매장(창고)명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tgmeGreenListExcelGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', shop_name?: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public tgmeGreenListExcelGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', shop_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public tgmeGreenListExcelGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', shop_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public tgmeGreenListExcelGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', shop_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (account_month === null || account_month === undefined) {
            throw new Error('Required parameter account_month was null or undefined when calling tgmeGreenListExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account_month !== undefined && account_month !== null) {
            queryParameters = queryParameters.set('account_month', <any>account_month);
        }
        if (good_category_name !== undefined && good_category_name !== null) {
            queryParameters = queryParameters.set('good_category_name', <any>good_category_name);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/accountsupport/tgme_green_list_excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 종합수불(매장/창고별)
     * 그린상품 종합수불(매장/창고별)가져오기
     * @param account_month 결산월(YYYY-MM)
     * @param good_category_name 상품분류
     * @param shop_name 매장(창고)명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tgmeGreenListGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', shop_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenItemPayment>>;
    public tgmeGreenListGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', shop_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenItemPayment>>>;
    public tgmeGreenListGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', shop_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenItemPayment>>>;
    public tgmeGreenListGet(account_month: string, good_category_name?: '공익상품' | '메아리상품', shop_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (account_month === null || account_month === undefined) {
            throw new Error('Required parameter account_month was null or undefined when calling tgmeGreenListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account_month !== undefined && account_month !== null) {
            queryParameters = queryParameters.set('account_month', <any>account_month);
        }
        if (good_category_name !== undefined && good_category_name !== null) {
            queryParameters = queryParameters.set('good_category_name', <any>good_category_name);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenItemPayment>>(`${this.basePath}/accountsupport/tgme_green_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
