import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
    {
        path: '', component: HomeComponent,
        data: {
            title: '베이스캠프',
            homeLink: '/',
            firstLevel: '홈'
        }
    },
    {
        path: 'dashboard', component: DashboardComponent,
        data: {
            title: '대시보드',
            homeLink: '/',
            firstLevel: '대시보드'
        }
    },
    { path: 'logout', component: LogoutComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
