import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS } from '@angular/material';
import { DatePipe } from '@angular/common';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { CodeService, ActiveState, StatisticsService, Code } from 'src/app/restapi/be-angel';

@Component({
  selector: 'app-angel-activity-status',
  templateUrl: './angel-activity-status.component.html',
  styleUrls: ['./angel-activity-status.component.scss'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS },
  ]
})
export class AngelActivityStatusComponent implements OnInit {
    form: FormGroup;
    dataSource: MatTableDataSource<ActiveState> = new MatTableDataSource([]);
    displayedColumns = ['department_name', 'team_name', 'location_name', 'active', 'stop', 'end'];
    page: number = 1;
    pageSize: number = 10;
    act_div_list: Array<Code>;
    act_period_list: Array<Code>;
    job_list: Array<Code>;

    @ViewChild(MatSort) sort: MatSort;

    constructor(private fb: FormBuilder, private statisticsService: StatisticsService, private datePipe: DatePipe,private codeService: CodeService) { }

    ngOnInit() {
      this.initForm();
      this.search();

      this.codeService.angelCodeGet("자원활동","활동구분").subscribe( res => {
        this.act_div_list = res;
        console.info(this.act_div_list);
      })
      this.codeService.angelCodeGet("자원활동","활동주기").subscribe( res => {
        this.act_period_list = res;
        console.info(this.act_period_list);
      })
      this.codeService.angelCodeGet("자원활동","직업").subscribe( res => {
        this.job_list = res;
        console.info(this.job_list);
      })

    }

    ngOnDestroy(): void { }

    a(a,b){
      console.info(a)
      console.info(b)
    }

    initForm() {
    this.form = this.fb.group({
      act_div: [''],
      act_period: [''],
      job: [''],
      gender: [''],
      search_act_dept_name: ['']
    });
  }

    get fc_actDiv() { return this.form.get('act_div'); }
    get fc_actPeriod() { return this.form.get('act_period'); }
    get fc_job() { return this.form.get('job'); }
    get fc_gender() { return this.form.get('gender'); }
    get fc_deptName() { return this.form.get('search_act_dept_name'); }

    search() {
      this.statisticsService.statisticsActiveStateGet(this.fc_deptName.value,this.fc_actDiv.value,this.fc_actPeriod.value,
        this.fc_job.value,this.fc_gender.value).subscribe({
          next: res => {
            this.dataSource.data = res;
          },
          complete: () => {
            this.sort.sortChange.emit({ active: this.sort.active, direction: this.sort.direction });
          }
        });
    }

    onClickExcelExport() {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertDatasource());
      const wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, '활동상태현황');
      XLSX.writeFile(wb, `활동상태현황_${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}.xlsx`);
    }

    convertDatasource() {
      const xlsxDatasource: Array<any> = [];

      this.dataSource.data.forEach((item: any) => {
        xlsxDatasource.push({
          '국': item.department_name,
          '팀': item.team_name,
          '매장': item.location_name,
          '활동중': item.active,
          // '활동대기': item.wait,
          '일시중단': item.stop,
          '활동종료': item.end
        });
      });
      return xlsxDatasource;
    }

}
