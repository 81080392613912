<div class="section-basic">
    <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style">
      <label>활동지</label>
      <app-angel-dept-auto-complete [placeholder]="'활동지'" formControlName="search_act_dept_name">
      </app-angel-dept-auto-complete>
    </div>

    <div class="form-group m-form__group default-style form_select">
      <label>활동구분</label>
      <select class="form-control m-input" formControlName="act_div" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_div_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

    <div class="form-group m-form__group default-style form_select">
      <label>활동주기</label>
      <select class="form-control m-input" formControlName="act_period" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_period_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>
    
    <div class="form-group m-form__group default-style form_select">
      <label>직업</label>
      <select class="form-control m-input" formControlName="job" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of job_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

    <div class="form-group m-form__group default-style form_select">
      <label>성별</label>
      <select class="form-control m-input" formControlName="gender" (change)="search()">
        <option value="">전체</option>
        <option value="남">남</option>
        <option value="여">여</option>
      </select>
    </div>


        <div class="form-group m-form__group default-style">
            <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
        </div>

    </form>
</div>

<!-- 데이터 테이블 -->
<div class="result">
    <div class="section-basic mb-0 pb-2">
        <div class="btns_right_align">
            <button class="btn btn-sm btn-outline-primary" (click)="onClickExcelExport()"
                [disabled]="dataSource.data.length == 0">엑셀
                다운로드</button>
        </div>

        <div class="m--space-10"></div>

        <mat-table #table class="unset-cursor-pointer" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="department_name">
                <mat-header-cell *matHeaderCellDef > 국 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.department_name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="team_name">
                <mat-header-cell *matHeaderCellDef > 팀 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.team_name}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="location_name">
                <mat-header-cell *matHeaderCellDef > 매장 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.location_name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef > 활동중 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.active | currencyMask}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="stop">
                <mat-header-cell *matHeaderCellDef > 일시중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.stop | currencyMask}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="end">
                <mat-header-cell *matHeaderCellDef > 활동종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.end | currencyMask}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'row-default':row,'row-team':row.location_name == '팀소계','row-department':row.team_name == '국소계'}"></mat-row>
        </mat-table>

        <div *ngIf="dataSource.data.length == 0" class="no-records">
            데이터가 존재하지 않습니다.
        </div>

        <div class="m-separator m-separator--dashed"></div>
        
    </div>
</div>
