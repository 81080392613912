import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, MatDatepicker } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
import { CodeService, StatisticsService } from 'src/app/restapi/be-angel';
import { DatePipe } from '@angular/common';
var moment = _moment;
var ɵ0 = YEAR_FORMATS;
var AngelGenderAnnualComponent = /** @class */ (function () {
    function AngelGenderAnnualComponent(fb, statisticsService, codeService, datePipe) {
        this.fb = fb;
        this.statisticsService = statisticsService;
        this.codeService = codeService;
        this.datePipe = datePipe;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['division', 'mon01', 'mon02', 'mon03', 'mon04', 'mon05', 'mon06', 'mon07', 'mon08', 'mon09', 'mon10', 'mon11', 'mon12'];
    }
    AngelGenderAnnualComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.codeService.angelCodeGet("자원활동", "활동구분").subscribe(function (res) {
            _this.act_div_list = res;
            console.info(_this.act_div_list);
        });
        this.codeService.angelCodeGet("자원활동", "활동주기").subscribe(function (res) {
            _this.act_period_list = res;
            console.info(_this.act_period_list);
        });
    };
    AngelGenderAnnualComponent.prototype.ngOnDestroy = function () { };
    AngelGenderAnnualComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            act_div: [''],
            act_period: [''],
            year: moment(),
            search_act_dept_name: ['']
        });
    };
    Object.defineProperty(AngelGenderAnnualComponent.prototype, "fc_countDiv", {
        get: function () { return this.form.get('act_div'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelGenderAnnualComponent.prototype, "fc_countPeriod", {
        get: function () { return this.form.get('act_period'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelGenderAnnualComponent.prototype, "fc_year", {
        get: function () { return this.form.get('year'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelGenderAnnualComponent.prototype, "fc_deptName", {
        get: function () { return this.form.get('search_act_dept_name'); },
        enumerable: true,
        configurable: true
    });
    AngelGenderAnnualComponent.prototype.chosenYearHandler = function (normalizedMonth, datepicker) {
        this.fc_year.setValue(normalizedMonth);
        this.search();
        datepicker.close();
    };
    AngelGenderAnnualComponent.prototype.convertMonthInfo = function (month, data) {
        if (data.monthly_info) {
            var givingHistory = data.monthly_info.find(function (x) { return x.date.split("-")[1] == month; });
            if (givingHistory)
                return givingHistory.count;
        }
        return '';
    };
    AngelGenderAnnualComponent.prototype.search = function () {
        this.givingsCallHistoryGet();
    };
    AngelGenderAnnualComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '성별연보');
        XLSX.writeFile(wb, "\uC131\uBCC4\uC5F0\uBCF4_" + this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + ".xlsx");
    };
    AngelGenderAnnualComponent.prototype.givingsCallHistoryGet = function () {
        var _this = this;
        this.dataList = [];
        this.statisticsService.statisticsGenderYearGet(dateFormat_Year(this.fc_year.value), this.fc_deptName.value, this.fc_countDiv.value, this.fc_countPeriod.value).pipe(take(1)).subscribe(function (res) {
            console.info(res);
            _this.dataList = res;
            _this.dataSource = new MatTableDataSource(_this.dataList);
        }, function (err) {
            console.error(err);
        });
    };
    return AngelGenderAnnualComponent;
}());
export { AngelGenderAnnualComponent };
export { ɵ0 };
