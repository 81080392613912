import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyMask'
})
export class CurrencyMaskPipe implements PipeTransform {

    transform(value: any): any {
        if (value == null) {
            return '';
        }
        let formatNum: string = this.format_number(value.toString());

        let result: any = formatNum != null && formatNum != '' ? formatNum : '';
        return result;
    }

    format_number(value: string) {
        let valueStr: string = value.toString();

        if(value.includes('-')) {
            valueStr = value.replace(/-/gi, '').toString();
        }
        
        let decimalIndex: number = valueStr.lastIndexOf('.');
        let removeIndex: number = decimalIndex > -1 ? valueStr.length - decimalIndex : 0;
        let thousand_separator: string = ',';
        let rest: number = (valueStr.length - removeIndex) % 3;
        let result: string = valueStr.substr(0, rest);
        let thousands: any = valueStr.substr(rest).match(/\d{3}/g);

        if (thousands) {
            let separator = rest ? thousand_separator : '';
            result += separator + thousands.join(thousand_separator);
            if (decimalIndex > -1) {
                result = result.concat(valueStr.substring(decimalIndex, valueStr.length));
            }
        }
        
        if(value.includes('-')) {
            return `-${result}`;
        } else {
            return result;
        }        
    }

}