<mat-tab-group animationDuration="0ms">
    <mat-tab label="요일&시간대별현황">
        <app-angel-day-time></app-angel-day-time>
    </mat-tab>
    <mat-tab label="활동상태현황">
        <app-angel-activity-status></app-angel-activity-status>
    </mat-tab>
    <mat-tab label="활동상태연보">
        <app-angel-activity-status-annual></app-angel-activity-status-annual>
    </mat-tab>
    <mat-tab label="성별연보">
        <app-angel-gender-annual></app-angel-gender-annual>
    </mat-tab>
    <mat-tab label="지역별연보">
        <app-angel-region-annual></app-angel-region-annual>
    </mat-tab>
    <mat-tab label="연령대별연보">
        <app-angel-age-annual></app-angel-age-annual>
    </mat-tab>
    <mat-tab label="시간&건수연보">
        <app-angel-time-annual></app-angel-time-annual>
    </mat-tab>
    <mat-tab label="연차별연보">
        <app-angel-workyear-annual></app-angel-workyear-annual>
    </mat-tab>
</mat-tab-group>
