/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Empty } from '../model/empty';
import { Failure } from '../model/failure';
import { History } from '../model/history';
import { Sync } from '../model/sync';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GroupwareService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 그룹웨어의 출퇴근 발생내역 조회
     * 
     * @param q yyyy-MM-dd
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dcmsyncGet(q: string, observe?: 'body', reportProgress?: boolean): Observable<History>;
    public dcmsyncGet(q: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<History>>;
    public dcmsyncGet(q: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<History>>;
    public dcmsyncGet(q: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (q === null || q === undefined) {
            throw new Error('Required parameter q was null or undefined when calling dcmsyncGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<History>(`${this.basePath}/dcmsync`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그룹웨어의 출퇴근 발생내역을 베이스캠프로 동기화
     * 
     * @param sync 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dcmsyncPost(sync: Sync, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public dcmsyncPost(sync: Sync, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public dcmsyncPost(sync: Sync, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public dcmsyncPost(sync: Sync, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (sync === null || sync === undefined) {
            throw new Error('Required parameter sync was null or undefined when calling dcmsyncPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/dcmsync`,
            sync,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그룹웨어의 휴가 발생내역 조회
     * 
     * @param q yyyy-MM-dd
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dvmsyncGet(q: string, observe?: 'body', reportProgress?: boolean): Observable<History>;
    public dvmsyncGet(q: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<History>>;
    public dvmsyncGet(q: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<History>>;
    public dvmsyncGet(q: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (q === null || q === undefined) {
            throw new Error('Required parameter q was null or undefined when calling dvmsyncGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<History>(`${this.basePath}/dvmsync`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그룹웨어의 휴가 발생내역을 베이스캠프로 동기화
     * 
     * @param sync 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dvmsyncPost(sync: Sync, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public dvmsyncPost(sync: Sync, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public dvmsyncPost(sync: Sync, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public dvmsyncPost(sync: Sync, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (sync === null || sync === undefined) {
            throw new Error('Required parameter sync was null or undefined when calling dvmsyncPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/dvmsync`,
            sync,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 베이스캠프의 인사기초정보 변경내역 조회
     * 
     * @param q yyyy-MM-dd
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrmsyncGet(q: string, observe?: 'body', reportProgress?: boolean): Observable<History>;
    public hrmsyncGet(q: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<History>>;
    public hrmsyncGet(q: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<History>>;
    public hrmsyncGet(q: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (q === null || q === undefined) {
            throw new Error('Required parameter q was null or undefined when calling hrmsyncGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<History>(`${this.basePath}/hrmsync`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 베이스캠프의 인사기초정보 변경내역을 그룹웨어로 동기화
     * 
     * @param sync 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrmsyncPost(sync: Sync, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public hrmsyncPost(sync: Sync, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public hrmsyncPost(sync: Sync, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public hrmsyncPost(sync: Sync, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (sync === null || sync === undefined) {
            throw new Error('Required parameter sync was null or undefined when calling hrmsyncPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/hrmsync`,
            sync,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 휴가 시작 및 종료 대상 임직원 조회
     * 
     * @param q yyyy-MM-dd
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leaveupGet(q: string, observe?: 'body', reportProgress?: boolean): Observable<History>;
    public leaveupGet(q: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<History>>;
    public leaveupGet(q: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<History>>;
    public leaveupGet(q: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (q === null || q === undefined) {
            throw new Error('Required parameter q was null or undefined when calling leaveupGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<History>(`${this.basePath}/leaveup`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 휴가 시작 및 종료 대상 임직원의 상태 업데이트
     * 
     * @param sync 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leaveupPost(sync: Sync, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public leaveupPost(sync: Sync, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public leaveupPost(sync: Sync, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public leaveupPost(sync: Sync, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (sync === null || sync === undefined) {
            throw new Error('Required parameter sync was null or undefined when calling leaveupPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/leaveup`,
            sync,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
