/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsAreaReceptionData } from '../model/esAreaReceptionData';
import { EsAreas } from '../model/esAreas';
import { EsCallStatusData } from '../model/esCallStatusData';
import { EsCode } from '../model/esCode';
import { EsDownloadUrl } from '../model/esDownloadUrl';
import { EsFailure } from '../model/esFailure';
import { EsGoodData } from '../model/esGoodData';
import { EsMappingAddr } from '../model/esMappingAddr';
import { EsParcelStatusData } from '../model/esParcelStatusData';
import { EsPriceChangeData } from '../model/esPriceChangeData';
import { EsSetGoodData } from '../model/esSetGoodData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsMasterService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 권역별 콜수 현황 조회
     * 권역별 콜수 현황 조회
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterAreaCallStatusGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<EsCallStatusData>;
    public masterAreaCallStatusGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsCallStatusData>>;
    public masterAreaCallStatusGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsCallStatusData>>;
    public masterAreaCallStatusGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling masterAreaCallStatusGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling masterAreaCallStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsCallStatusData>(`${this.basePath}/master/area/call_status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 권한에 따른 권역정보 조회
     * 권한에 따른 권역정보 조회
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterAreaDeptInfoGet(observe?: 'body', reportProgress?: boolean): Observable<Array<EsAreas>>;
    public masterAreaDeptInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsAreas>>>;
    public masterAreaDeptInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsAreas>>>;
    public masterAreaDeptInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsAreas>>(`${this.basePath}/master/area/dept_info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 권역명 자동 완성 조회
     * 권역명 자동 완성 조회
     * @param area_name 
     * @param management_dept_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterAreaInfoGet(area_name?: string, management_dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsAreas>>;
    public masterAreaInfoGet(area_name?: string, management_dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsAreas>>>;
    public masterAreaInfoGet(area_name?: string, management_dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsAreas>>>;
    public masterAreaInfoGet(area_name?: string, management_dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (area_name !== undefined && area_name !== null) {
            queryParameters = queryParameters.set('area_name', <any>area_name);
        }
        if (management_dept_id !== undefined && management_dept_id !== null) {
            queryParameters = queryParameters.set('management_dept_id', <any>management_dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsAreas>>(`${this.basePath}/master/area/info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 권역별 방문예정일 기준 기증 접수건수 조회
     * 권역별 방문예정일 기준 기증 접수건수 조회
     * @param gte_date 
     * @param lte_date 
     * @param area_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterAreaReceptionStatusGet(gte_date: string, lte_date: string, area_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsAreaReceptionData>>;
    public masterAreaReceptionStatusGet(gte_date: string, lte_date: string, area_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsAreaReceptionData>>>;
    public masterAreaReceptionStatusGet(gte_date: string, lte_date: string, area_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsAreaReceptionData>>>;
    public masterAreaReceptionStatusGet(gte_date: string, lte_date: string, area_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling masterAreaReceptionStatusGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling masterAreaReceptionStatusGet.');
        }
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling masterAreaReceptionStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (area_id !== undefined && area_id !== null) {
            queryParameters = queryParameters.set('area_id', <any>area_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsAreaReceptionData>>(`${this.basePath}/master/area/reception/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 담당자에 콜제한설정 메일 발송
     * 담당자에 콜제한설정 메일 발송(주기적발송)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterAreaSetCallMailGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public masterAreaSetCallMailGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public masterAreaSetCallMailGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public masterAreaSetCallMailGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/master/area/set_call_mail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 코드정보를 가져온다
     * 코드정보를 가져온다
     * @param code_group_name 코드구분명
     * @param code_category_name 코드카테고리명
     * @param code_name 코드명및카테고리명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterCodeListGet(code_group_name: string, code_category_name?: string, code_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsCode>>;
    public masterCodeListGet(code_group_name: string, code_category_name?: string, code_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsCode>>>;
    public masterCodeListGet(code_group_name: string, code_category_name?: string, code_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsCode>>>;
    public masterCodeListGet(code_group_name: string, code_category_name?: string, code_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code_group_name === null || code_group_name === undefined) {
            throw new Error('Required parameter code_group_name was null or undefined when calling masterCodeListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code_group_name !== undefined && code_group_name !== null) {
            queryParameters = queryParameters.set('code_group_name', <any>code_group_name);
        }
        if (code_category_name !== undefined && code_category_name !== null) {
            queryParameters = queryParameters.set('code_category_name', <any>code_category_name);
        }
        if (code_name !== undefined && code_name !== null) {
            queryParameters = queryParameters.set('code_name', <any>code_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsCode>>(`${this.basePath}/master/code/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 동일상품 정보 찾기
     * 상품명으로 상품정보 찾기
     * @param good_name 상품명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterEqualGreenGoodGet(good_name: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGoodData>>;
    public masterEqualGreenGoodGet(good_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGoodData>>>;
    public masterEqualGreenGoodGet(good_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGoodData>>>;
    public masterEqualGreenGoodGet(good_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_name === null || good_name === undefined) {
            throw new Error('Required parameter good_name was null or undefined when calling masterEqualGreenGoodGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGoodData>>(`${this.basePath}/master/equal_green_good`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품정보 엑셀다운로드
     * 상품정보 엑셀다운로드
     * @param good_div 상품구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterGoodExcelGet(good_div: '기증상품' | '그린상품', observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public masterGoodExcelGet(good_div: '기증상품' | '그린상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public masterGoodExcelGet(good_div: '기증상품' | '그린상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public masterGoodExcelGet(good_div: '기증상품' | '그린상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_div === null || good_div === undefined) {
            throw new Error('Required parameter good_div was null or undefined when calling masterGoodExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_div !== undefined && good_div !== null) {
            queryParameters = queryParameters.set('good_div', <any>good_div);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/master/good/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품 관리 - 조건으로 검색
     * 상품 관리 - 조건으로 검색
     * @param good_div 상품구분
     * @param good_name 상품명
     * @param barcode 바코드
     * @param maker_name 생산자명
     * @param good_category_code 상품분류코드
     * @param tax_flg 과세여부
     * @param dealing_div 취급여부
     * @param logistics_div 물류구분
     * @param good_status 상품상태
     * @param good_tag 상단노출
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterGoodManagementGet(good_div: '기증상품' | '그린상품', good_name?: string, barcode?: string, maker_name?: string, good_category_code?: string, tax_flg?: '과세' | '비과세', dealing_div?: '취급' | '미취급', logistics_div?: '그린물류' | '업체직배송', good_status?: '주문가능' | '일시품절' | '판매중지', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGoodData>>;
    public masterGoodManagementGet(good_div: '기증상품' | '그린상품', good_name?: string, barcode?: string, maker_name?: string, good_category_code?: string, tax_flg?: '과세' | '비과세', dealing_div?: '취급' | '미취급', logistics_div?: '그린물류' | '업체직배송', good_status?: '주문가능' | '일시품절' | '판매중지', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGoodData>>>;
    public masterGoodManagementGet(good_div: '기증상품' | '그린상품', good_name?: string, barcode?: string, maker_name?: string, good_category_code?: string, tax_flg?: '과세' | '비과세', dealing_div?: '취급' | '미취급', logistics_div?: '그린물류' | '업체직배송', good_status?: '주문가능' | '일시품절' | '판매중지', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGoodData>>>;
    public masterGoodManagementGet(good_div: '기증상품' | '그린상품', good_name?: string, barcode?: string, maker_name?: string, good_category_code?: string, tax_flg?: '과세' | '비과세', dealing_div?: '취급' | '미취급', logistics_div?: '그린물류' | '업체직배송', good_status?: '주문가능' | '일시품절' | '판매중지', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_div === null || good_div === undefined) {
            throw new Error('Required parameter good_div was null or undefined when calling masterGoodManagementGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (barcode !== undefined && barcode !== null) {
            queryParameters = queryParameters.set('barcode', <any>barcode);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', <any>maker_name);
        }
        if (good_category_code !== undefined && good_category_code !== null) {
            queryParameters = queryParameters.set('good_category_code', <any>good_category_code);
        }
        if (tax_flg !== undefined && tax_flg !== null) {
            queryParameters = queryParameters.set('tax_flg', <any>tax_flg);
        }
        if (dealing_div !== undefined && dealing_div !== null) {
            queryParameters = queryParameters.set('dealing_div', <any>dealing_div);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', <any>logistics_div);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', <any>good_status);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', <any>good_tag);
        }
        if (good_div !== undefined && good_div !== null) {
            queryParameters = queryParameters.set('good_div', <any>good_div);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGoodData>>(`${this.basePath}/master/good/management`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품구분별, 상품명 Like, 매장아이디 검색 완성
     * 상품구분별, 상품명 Like, 매장아이디 검색 완성
     * @param good_name_or_barcode 상품명_바코드
     * @param good_first_category_name 상품분류
     * @param shop_id 선별상품 포함 검색 (매장아이디 또는 외부납품처아이디)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterGoodMetaGet(good_name_or_barcode?: string, good_first_category_name?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGoodData>>;
    public masterGoodMetaGet(good_name_or_barcode?: string, good_first_category_name?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGoodData>>>;
    public masterGoodMetaGet(good_name_or_barcode?: string, good_first_category_name?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGoodData>>>;
    public masterGoodMetaGet(good_name_or_barcode?: string, good_first_category_name?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_name_or_barcode !== undefined && good_name_or_barcode !== null) {
            queryParameters = queryParameters.set('good_name_or_barcode', <any>good_name_or_barcode);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGoodData>>(`${this.basePath}/master/good/meta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품정보 엑셀 다운로드
     * 그린상품정보 엑셀 다운로드한다. (물류 및 매출 &gt; 수불관리 &gt; 그린상품주문 &gt; 상품정보_팝업)
     * @param shop_id 매장아이디(매장주문시 필수입력)
     * @param good_name_or_barcode 상품명_바코드
     * @param good_first_category_name 주문구분
     * @param good_status 출고구분
     * @param good_tag HOT상품
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterGreenGoodExcelGet(shop_id?: string, good_name_or_barcode?: string, good_first_category_name?: '공익상품' | '메아리상품', good_status?: '주문가능' | '일시품절' | '판매중지' | '주문가능_일시품절', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public masterGreenGoodExcelGet(shop_id?: string, good_name_or_barcode?: string, good_first_category_name?: '공익상품' | '메아리상품', good_status?: '주문가능' | '일시품절' | '판매중지' | '주문가능_일시품절', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public masterGreenGoodExcelGet(shop_id?: string, good_name_or_barcode?: string, good_first_category_name?: '공익상품' | '메아리상품', good_status?: '주문가능' | '일시품절' | '판매중지' | '주문가능_일시품절', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public masterGreenGoodExcelGet(shop_id?: string, good_name_or_barcode?: string, good_first_category_name?: '공익상품' | '메아리상품', good_status?: '주문가능' | '일시품절' | '판매중지' | '주문가능_일시품절', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (good_name_or_barcode !== undefined && good_name_or_barcode !== null) {
            queryParameters = queryParameters.set('good_name_or_barcode', <any>good_name_or_barcode);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', <any>good_status);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', <any>good_tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/master/green_good/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품정보조회
     * 그린상품을 조회한다. (물류 및 매출 &gt; 수불관리 &gt; 그린상품주문 &gt; 상품정보_팝업)
     * @param shop_id 매장아이디(매장주문시 필수입력)
     * @param good_name_or_barcode 상품명_바코드
     * @param good_first_category_name 주문구분
     * @param good_status 출고구분
     * @param good_tag HOT상품
     * @param sort_type 정렬기준(상품명/태그명)
     * @param good_code 상품코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterGreenGoodGet(shop_id?: string, good_name_or_barcode?: string, good_first_category_name?: '공익상품' | '메아리상품', good_status?: '주문가능' | '일시품절' | '판매중지' | '주문가능_일시품절', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', sort_type?: 'good_tag' | 'good_name', good_code?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGoodData>>;
    public masterGreenGoodGet(shop_id?: string, good_name_or_barcode?: string, good_first_category_name?: '공익상품' | '메아리상품', good_status?: '주문가능' | '일시품절' | '판매중지' | '주문가능_일시품절', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', sort_type?: 'good_tag' | 'good_name', good_code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGoodData>>>;
    public masterGreenGoodGet(shop_id?: string, good_name_or_barcode?: string, good_first_category_name?: '공익상품' | '메아리상품', good_status?: '주문가능' | '일시품절' | '판매중지' | '주문가능_일시품절', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', sort_type?: 'good_tag' | 'good_name', good_code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGoodData>>>;
    public masterGreenGoodGet(shop_id?: string, good_name_or_barcode?: string, good_first_category_name?: '공익상품' | '메아리상품', good_status?: '주문가능' | '일시품절' | '판매중지' | '주문가능_일시품절', good_tag?: 'EVENT' | 'NEW' | '홈쇼핑' | 'HOT' | '일반', sort_type?: 'good_tag' | 'good_name', good_code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (good_name_or_barcode !== undefined && good_name_or_barcode !== null) {
            queryParameters = queryParameters.set('good_name_or_barcode', <any>good_name_or_barcode);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', <any>good_status);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', <any>good_tag);
        }
        if (sort_type !== undefined && sort_type !== null) {
            queryParameters = queryParameters.set('sort_type', <any>sort_type);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGoodData>>(`${this.basePath}/master/green_good`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품정보 상품명 Like 검색 완성 - 선별상품 여부
     * 그린상품정보 상품명 Like 검색 완성 - 선별상품 여부
     * @param good_name_or_barcode 상품명_바코드
     * @param good_first_category_name 상품분류
     * @param select_good_shop_id 선별상품 포함 검색 (매장아이디 또는 외부납품처아이디)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterGreenGoodMetaGet(good_name_or_barcode?: string, good_first_category_name?: '메아리상품' | '공익상품', select_good_shop_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGoodData>>;
    public masterGreenGoodMetaGet(good_name_or_barcode?: string, good_first_category_name?: '메아리상품' | '공익상품', select_good_shop_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGoodData>>>;
    public masterGreenGoodMetaGet(good_name_or_barcode?: string, good_first_category_name?: '메아리상품' | '공익상품', select_good_shop_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGoodData>>>;
    public masterGreenGoodMetaGet(good_name_or_barcode?: string, good_first_category_name?: '메아리상품' | '공익상품', select_good_shop_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_name_or_barcode !== undefined && good_name_or_barcode !== null) {
            queryParameters = queryParameters.set('good_name_or_barcode', <any>good_name_or_barcode);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (select_good_shop_id !== undefined && select_good_shop_id !== null) {
            queryParameters = queryParameters.set('select_good_shop_id', <any>select_good_shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGoodData>>(`${this.basePath}/master/green_good/meta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입점생산자 상품 조회
     * 입점생산자 상품 조회
     * @param maker_id 입점생산자 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterMakerMakerIdGoodGet(maker_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGoodData>>;
    public masterMakerMakerIdGoodGet(maker_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGoodData>>>;
    public masterMakerMakerIdGoodGet(maker_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGoodData>>>;
    public masterMakerMakerIdGoodGet(maker_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (maker_id === null || maker_id === undefined) {
            throw new Error('Required parameter maker_id was null or undefined when calling masterMakerMakerIdGoodGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGoodData>>(`${this.basePath}/master/maker/${encodeURIComponent(String(maker_id))}/good`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 지역별 권역정보 엑셀다운로드
     * 지역별 권역정보 엑셀다운로드
     * @param area_name 지역명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterMappingAddrExcelGet(area_name: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public masterMappingAddrExcelGet(area_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public masterMappingAddrExcelGet(area_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public masterMappingAddrExcelGet(area_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_name === null || area_name === undefined) {
            throw new Error('Required parameter area_name was null or undefined when calling masterMappingAddrExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (area_name !== undefined && area_name !== null) {
            queryParameters = queryParameters.set('area_name', <any>area_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/master/mapping_addr/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 법정동 매핑 정보 조회
     * 법정동 매핑 정보 조회
     * @param mapping_flg 매핑여부
     * @param si_do 시도명
     * @param si_gun_gu 시군구명
     * @param eub_myeon_dong_or_dong_ri 읍면동명 또는 동리명
     * @param area_id 권역 아이디
     * @param collect_type 수거 타입
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterMappingAddrGet(mapping_flg?: 'Y' | 'N', si_do?: string, si_gun_gu?: string, eub_myeon_dong_or_dong_ri?: string, area_id?: string, collect_type?: '전체' | '수거' | '택배', observe?: 'body', reportProgress?: boolean): Observable<Array<EsMappingAddr>>;
    public masterMappingAddrGet(mapping_flg?: 'Y' | 'N', si_do?: string, si_gun_gu?: string, eub_myeon_dong_or_dong_ri?: string, area_id?: string, collect_type?: '전체' | '수거' | '택배', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsMappingAddr>>>;
    public masterMappingAddrGet(mapping_flg?: 'Y' | 'N', si_do?: string, si_gun_gu?: string, eub_myeon_dong_or_dong_ri?: string, area_id?: string, collect_type?: '전체' | '수거' | '택배', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsMappingAddr>>>;
    public masterMappingAddrGet(mapping_flg?: 'Y' | 'N', si_do?: string, si_gun_gu?: string, eub_myeon_dong_or_dong_ri?: string, area_id?: string, collect_type?: '전체' | '수거' | '택배', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mapping_flg !== undefined && mapping_flg !== null) {
            queryParameters = queryParameters.set('mapping_flg', <any>mapping_flg);
        }
        if (si_do !== undefined && si_do !== null) {
            queryParameters = queryParameters.set('si_do', <any>si_do);
        }
        if (si_gun_gu !== undefined && si_gun_gu !== null) {
            queryParameters = queryParameters.set('si_gun_gu', <any>si_gun_gu);
        }
        if (eub_myeon_dong_or_dong_ri !== undefined && eub_myeon_dong_or_dong_ri !== null) {
            queryParameters = queryParameters.set('eub_myeon_dong_or_dong_ri', <any>eub_myeon_dong_or_dong_ri);
        }
        if (area_id !== undefined && area_id !== null) {
            queryParameters = queryParameters.set('area_id', <any>area_id);
        }
        if (collect_type !== undefined && collect_type !== null) {
            queryParameters = queryParameters.set('collect_type', <any>collect_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsMappingAddr>>(`${this.basePath}/master/mapping_addr`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린상품 가격변동 정보 조회
     * 그린상품 가격변동 정보 조회
     * @param from_date 조회 시작일
     * @param to_date 조회 종료일
     * @param good_name 상품명
     * @param app_flg 적용여부
     * @param remark 비고
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterPriceChangeGet(from_date?: string, to_date?: string, good_name?: string, app_flg?: '전체' | '적용' | '미적용', remark?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsPriceChangeData>>;
    public masterPriceChangeGet(from_date?: string, to_date?: string, good_name?: string, app_flg?: '전체' | '적용' | '미적용', remark?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsPriceChangeData>>>;
    public masterPriceChangeGet(from_date?: string, to_date?: string, good_name?: string, app_flg?: '전체' | '적용' | '미적용', remark?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsPriceChangeData>>>;
    public masterPriceChangeGet(from_date?: string, to_date?: string, good_name?: string, app_flg?: '전체' | '적용' | '미적용', remark?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from_date !== undefined && from_date !== null) {
            queryParameters = queryParameters.set('from_date', <any>from_date);
        }
        if (to_date !== undefined && to_date !== null) {
            queryParameters = queryParameters.set('to_date', <any>to_date);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (app_flg !== undefined && app_flg !== null) {
            queryParameters = queryParameters.set('app_flg', <any>app_flg);
        }
        if (remark !== undefined && remark !== null) {
            queryParameters = queryParameters.set('remark', <any>remark);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsPriceChangeData>>(`${this.basePath}/master/price_change`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 택배 현황 조회
     * 되살림터별 택배 현황 조회
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterRecyclePlaceParcelStatusGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<EsParcelStatusData>;
    public masterRecyclePlaceParcelStatusGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsParcelStatusData>>;
    public masterRecyclePlaceParcelStatusGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsParcelStatusData>>;
    public masterRecyclePlaceParcelStatusGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling masterRecyclePlaceParcelStatusGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling masterRecyclePlaceParcelStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsParcelStatusData>(`${this.basePath}/master/recycle_place/parcel_status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 세트상품 포함여부로 Like검색 완성
     * 사용상품 Like검색 완성
     * @param good_name 상품명
     * @param set_yn 세트상품 포함여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterSetGreenGoodGet(good_name: string, set_yn: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGoodData>>;
    public masterSetGreenGoodGet(good_name: string, set_yn: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGoodData>>>;
    public masterSetGreenGoodGet(good_name: string, set_yn: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGoodData>>>;
    public masterSetGreenGoodGet(good_name: string, set_yn: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_name === null || good_name === undefined) {
            throw new Error('Required parameter good_name was null or undefined when calling masterSetGreenGoodGet.');
        }
        if (set_yn === null || set_yn === undefined) {
            throw new Error('Required parameter set_yn was null or undefined when calling masterSetGreenGoodGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (set_yn !== undefined && set_yn !== null) {
            queryParameters = queryParameters.set('set_yn', <any>set_yn);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGoodData>>(`${this.basePath}/master/set_green_good`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 세트상품만 Like검색 완성
     * 세트상품만 Like검색 완성
     * @param good_name 상품명
     * @param greenbusiness_storage_id 창고아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterSetOnlyGoodGet(good_name: string, greenbusiness_storage_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsSetGoodData>>;
    public masterSetOnlyGoodGet(good_name: string, greenbusiness_storage_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsSetGoodData>>>;
    public masterSetOnlyGoodGet(good_name: string, greenbusiness_storage_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsSetGoodData>>>;
    public masterSetOnlyGoodGet(good_name: string, greenbusiness_storage_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_name === null || good_name === undefined) {
            throw new Error('Required parameter good_name was null or undefined when calling masterSetOnlyGoodGet.');
        }
        if (greenbusiness_storage_id === null || greenbusiness_storage_id === undefined) {
            throw new Error('Required parameter greenbusiness_storage_id was null or undefined when calling masterSetOnlyGoodGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', <any>greenbusiness_storage_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsSetGoodData>>(`${this.basePath}/master/set_only_good`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장/납품처별 사용상품 Like검색 완성
     * 매장/납품처별 사용상품 Like검색 완성
     * @param good_name 상품명
     * @param vendor_shop_code 납품처명_매장코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masterUseGreenGoodGet(good_name: string, vendor_shop_code: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGoodData>>;
    public masterUseGreenGoodGet(good_name: string, vendor_shop_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGoodData>>>;
    public masterUseGreenGoodGet(good_name: string, vendor_shop_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGoodData>>>;
    public masterUseGreenGoodGet(good_name: string, vendor_shop_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_name === null || good_name === undefined) {
            throw new Error('Required parameter good_name was null or undefined when calling masterUseGreenGoodGet.');
        }
        if (vendor_shop_code === null || vendor_shop_code === undefined) {
            throw new Error('Required parameter vendor_shop_code was null or undefined when calling masterUseGreenGoodGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (vendor_shop_code !== undefined && vendor_shop_code !== null) {
            queryParameters = queryParameters.set('vendor_shop_code', <any>vendor_shop_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGoodData>>(`${this.basePath}/master/use_green_good`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
