/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다.
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var WarehouseService = /** @class */ (function () {
    function WarehouseService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    WarehouseService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    WarehouseService.prototype.averageBoxCntGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/average_box_cnt", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.averageBoxCntPut = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling averageBoxCntPut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/average_box_cnt", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.barcodeGenerationGet = function (good_div, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_div === null || good_div === undefined) {
            throw new Error('Required parameter good_div was null or undefined when calling barcodeGenerationGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_div !== undefined && good_div !== null) {
            queryParameters = queryParameters.set('good_div', good_div);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/barcode_generation", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.deadlineSettingSystemGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        return this.httpClient.get(this.basePath + "/deadline_setting/system", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.givingGoodGet = function (untreated, good_name, barcode, good_div_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (untreated !== undefined && untreated !== null) {
            queryParameters = queryParameters.set('untreated', untreated);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (barcode !== undefined && barcode !== null) {
            queryParameters = queryParameters.set('barcode', barcode);
        }
        if (good_div_code !== undefined && good_div_code !== null) {
            queryParameters = queryParameters.set('good_div_code', good_div_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/giving_good", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.givingGoodPost = function (reg_emp_id, reg_emp_name, reg_emp_dept_name, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (reg_emp_id === null || reg_emp_id === undefined) {
            throw new Error('Required parameter reg_emp_id was null or undefined when calling givingGoodPost.');
        }
        if (reg_emp_name === null || reg_emp_name === undefined) {
            throw new Error('Required parameter reg_emp_name was null or undefined when calling givingGoodPost.');
        }
        if (reg_emp_dept_name === null || reg_emp_dept_name === undefined) {
            throw new Error('Required parameter reg_emp_dept_name was null or undefined when calling givingGoodPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling givingGoodPost.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (reg_emp_id !== undefined && reg_emp_id !== null) {
            queryParameters = queryParameters.set('reg_emp_id', reg_emp_id);
        }
        if (reg_emp_name !== undefined && reg_emp_name !== null) {
            queryParameters = queryParameters.set('reg_emp_name', reg_emp_name);
        }
        if (reg_emp_dept_name !== undefined && reg_emp_dept_name !== null) {
            queryParameters = queryParameters.set('reg_emp_dept_name', reg_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/giving_good", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.givingGoodPut = function (sk, mod_emp_id, mod_emp_name, mod_emp_dept_name, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (sk === null || sk === undefined) {
            throw new Error('Required parameter sk was null or undefined when calling givingGoodPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling givingGoodPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling givingGoodPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling givingGoodPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling givingGoodPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (sk !== undefined && sk !== null) {
            queryParameters = queryParameters.set('sk', sk);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', mod_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/giving_good", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodDivSearchGet = function (good_div_depth, good_div_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_div_depth === null || good_div_depth === undefined) {
            throw new Error('Required parameter good_div_depth was null or undefined when calling goodDivSearchGet.');
        }
        if (good_div_code === null || good_div_code === undefined) {
            throw new Error('Required parameter good_div_code was null or undefined when calling goodDivSearchGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_div_depth !== undefined && good_div_depth !== null) {
            queryParameters = queryParameters.set('good_div_depth', good_div_depth);
        }
        if (good_div_code !== undefined && good_div_code !== null) {
            queryParameters = queryParameters.set('good_div_code', good_div_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/good_div/search", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodDivSearchPost = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling goodDivSearchPost.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/good_div/search", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodGoodCodeGoodCodeGet = function (good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling goodGoodCodeGoodCodeGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/good/good_code/" + encodeURIComponent(String(good_code)), {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodGoodIdGet = function (good_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_id === null || good_id === undefined) {
            throw new Error('Required parameter good_id was null or undefined when calling goodGoodIdGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/good/" + encodeURIComponent(String(good_id)), {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodGoodIdTagsPut = function (good_id, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_id === null || good_id === undefined) {
            throw new Error('Required parameter good_id was null or undefined when calling goodGoodIdTagsPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling goodGoodIdTagsPut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/good/" + encodeURIComponent(String(good_id)) + "/tags", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodGoodStatusPut = function (good_id, good_status, mod_emp_id, mod_emp_name, mod_emp_dept_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_id === null || good_id === undefined) {
            throw new Error('Required parameter good_id was null or undefined when calling goodGoodStatusPut.');
        }
        if (good_status === null || good_status === undefined) {
            throw new Error('Required parameter good_status was null or undefined when calling goodGoodStatusPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling goodGoodStatusPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling goodGoodStatusPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling goodGoodStatusPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_id !== undefined && good_id !== null) {
            queryParameters = queryParameters.set('good_id', good_id);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', good_status);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', mod_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.put(this.basePath + "/good/good_status", null, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodGoodTagPut = function (good_id, good_tag, mod_emp_id, mod_emp_name, mod_emp_dept_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_id === null || good_id === undefined) {
            throw new Error('Required parameter good_id was null or undefined when calling goodGoodTagPut.');
        }
        if (good_tag === null || good_tag === undefined) {
            throw new Error('Required parameter good_tag was null or undefined when calling goodGoodTagPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling goodGoodTagPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling goodGoodTagPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling goodGoodTagPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_id !== undefined && good_id !== null) {
            queryParameters = queryParameters.set('good_id', good_id);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', good_tag);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', mod_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.put(this.basePath + "/good/good_tag", null, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodListGet = function (untreated, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (untreated !== undefined && untreated !== null) {
            queryParameters = queryParameters.set('untreated', untreated);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/good_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.goodPriceChangeSystemGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        return this.httpClient.get(this.basePath + "/good_price_change/system", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.greenGoodGet = function (good_name, barcode, good_div_code, maker_name, tax_flg, dealing_div, logistics_div, good_status, good_tag, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (barcode !== undefined && barcode !== null) {
            queryParameters = queryParameters.set('barcode', barcode);
        }
        if (good_div_code !== undefined && good_div_code !== null) {
            queryParameters = queryParameters.set('good_div_code', good_div_code);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', maker_name);
        }
        if (tax_flg !== undefined && tax_flg !== null) {
            queryParameters = queryParameters.set('tax_flg', tax_flg);
        }
        if (dealing_div !== undefined && dealing_div !== null) {
            queryParameters = queryParameters.set('dealing_div', dealing_div);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', logistics_div);
        }
        if (good_status !== undefined && good_status !== null) {
            queryParameters = queryParameters.set('good_status', good_status);
        }
        if (good_tag !== undefined && good_tag !== null) {
            queryParameters = queryParameters.set('good_tag', good_tag);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/green_good", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.greenGoodImagePreviewGet = function (file_uuid, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (file_uuid === null || file_uuid === undefined) {
            throw new Error('Required parameter file_uuid was null or undefined when calling greenGoodImagePreviewGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (file_uuid !== undefined && file_uuid !== null) {
            queryParameters = queryParameters.set('file_uuid', file_uuid);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/green_good/image_preview", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.greenGoodPost = function (reg_emp_id, reg_emp_name, reg_emp_dept_name, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (reg_emp_id === null || reg_emp_id === undefined) {
            throw new Error('Required parameter reg_emp_id was null or undefined when calling greenGoodPost.');
        }
        if (reg_emp_name === null || reg_emp_name === undefined) {
            throw new Error('Required parameter reg_emp_name was null or undefined when calling greenGoodPost.');
        }
        if (reg_emp_dept_name === null || reg_emp_dept_name === undefined) {
            throw new Error('Required parameter reg_emp_dept_name was null or undefined when calling greenGoodPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling greenGoodPost.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (reg_emp_id !== undefined && reg_emp_id !== null) {
            queryParameters = queryParameters.set('reg_emp_id', reg_emp_id);
        }
        if (reg_emp_name !== undefined && reg_emp_name !== null) {
            queryParameters = queryParameters.set('reg_emp_name', reg_emp_name);
        }
        if (reg_emp_dept_name !== undefined && reg_emp_dept_name !== null) {
            queryParameters = queryParameters.set('reg_emp_dept_name', reg_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/green_good", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.greenGoodPut = function (sk, mod_emp_id, mod_emp_name, mod_emp_dept_name, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (sk === null || sk === undefined) {
            throw new Error('Required parameter sk was null or undefined when calling greenGoodPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling greenGoodPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling greenGoodPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling greenGoodPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling greenGoodPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (sk !== undefined && sk !== null) {
            queryParameters = queryParameters.set('sk', sk);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', mod_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/green_good", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.greenbusinessDeadlineGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/greenbusiness_deadline", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.greenbusinessDeadlinePut = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling greenbusinessDeadlinePut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/greenbusiness_deadline", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.inventoryDeadlineGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/inventory_deadline", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.inventoryDeadlinePut = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling inventoryDeadlinePut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/inventory_deadline", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.inventoryErrorRateGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/inventory_error_rate", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.inventoryErrorRatePut = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling inventoryErrorRatePut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/inventory_error_rate", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.parcelGivePlaceListGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/parcel_give_place_list", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.parcelGivePlaceSetGet = function (recycle_place, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling parcelGivePlaceSetGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/parcel_give_place_set", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.parcelGivePlaceSetParcelLimitPut = function (recycle_place, parcel_limit, mod_emp_id, mod_emp_name, mod_emp_dept_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        if (parcel_limit === null || parcel_limit === undefined) {
            throw new Error('Required parameter parcel_limit was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        if (mod_emp_id === null || mod_emp_id === undefined) {
            throw new Error('Required parameter mod_emp_id was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        if (mod_emp_name === null || mod_emp_name === undefined) {
            throw new Error('Required parameter mod_emp_name was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        if (mod_emp_dept_name === null || mod_emp_dept_name === undefined) {
            throw new Error('Required parameter mod_emp_dept_name was null or undefined when calling parcelGivePlaceSetParcelLimitPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (parcel_limit !== undefined && parcel_limit !== null) {
            queryParameters = queryParameters.set('parcel_limit', parcel_limit);
        }
        if (mod_emp_id !== undefined && mod_emp_id !== null) {
            queryParameters = queryParameters.set('mod_emp_id', mod_emp_id);
        }
        if (mod_emp_name !== undefined && mod_emp_name !== null) {
            queryParameters = queryParameters.set('mod_emp_name', mod_emp_name);
        }
        if (mod_emp_dept_name !== undefined && mod_emp_dept_name !== null) {
            queryParameters = queryParameters.set('mod_emp_dept_name', mod_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.put(this.basePath + "/parcel_give_place_set/parcel_limit", null, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.parcelGivePlaceSetPut = function (recycle_place, set_yn, reg_emp_id, reg_emp_name, reg_emp_dept_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling parcelGivePlaceSetPut.');
        }
        if (set_yn === null || set_yn === undefined) {
            throw new Error('Required parameter set_yn was null or undefined when calling parcelGivePlaceSetPut.');
        }
        if (reg_emp_id === null || reg_emp_id === undefined) {
            throw new Error('Required parameter reg_emp_id was null or undefined when calling parcelGivePlaceSetPut.');
        }
        if (reg_emp_name === null || reg_emp_name === undefined) {
            throw new Error('Required parameter reg_emp_name was null or undefined when calling parcelGivePlaceSetPut.');
        }
        if (reg_emp_dept_name === null || reg_emp_dept_name === undefined) {
            throw new Error('Required parameter reg_emp_dept_name was null or undefined when calling parcelGivePlaceSetPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (set_yn !== undefined && set_yn !== null) {
            queryParameters = queryParameters.set('set_yn', set_yn);
        }
        if (reg_emp_id !== undefined && reg_emp_id !== null) {
            queryParameters = queryParameters.set('reg_emp_id', reg_emp_id);
        }
        if (reg_emp_name !== undefined && reg_emp_name !== null) {
            queryParameters = queryParameters.set('reg_emp_name', reg_emp_name);
        }
        if (reg_emp_dept_name !== undefined && reg_emp_dept_name !== null) {
            queryParameters = queryParameters.set('reg_emp_dept_name', reg_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.put(this.basePath + "/parcel_give_place_set", null, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.priceChangeDelete = function (sk, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (sk === null || sk === undefined) {
            throw new Error('Required parameter sk was null or undefined when calling priceChangeDelete.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (sk !== undefined && sk !== null) {
            queryParameters = queryParameters.set('sk', sk);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        return this.httpClient.delete(this.basePath + "/price_change", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.priceChangeGet = function (from_date, to_date, app_flg, good_name, remark, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (from_date === null || from_date === undefined) {
            throw new Error('Required parameter from_date was null or undefined when calling priceChangeGet.');
        }
        if (to_date === null || to_date === undefined) {
            throw new Error('Required parameter to_date was null or undefined when calling priceChangeGet.');
        }
        if (app_flg === null || app_flg === undefined) {
            throw new Error('Required parameter app_flg was null or undefined when calling priceChangeGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (from_date !== undefined && from_date !== null) {
            queryParameters = queryParameters.set('from_date', from_date);
        }
        if (to_date !== undefined && to_date !== null) {
            queryParameters = queryParameters.set('to_date', to_date);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (app_flg !== undefined && app_flg !== null) {
            queryParameters = queryParameters.set('app_flg', app_flg);
        }
        if (remark !== undefined && remark !== null) {
            queryParameters = queryParameters.set('remark', remark);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/price_change", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.priceChangePost = function (reg_emp_id, reg_emp_name, reg_emp_dept_name, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (reg_emp_id === null || reg_emp_id === undefined) {
            throw new Error('Required parameter reg_emp_id was null or undefined when calling priceChangePost.');
        }
        if (reg_emp_name === null || reg_emp_name === undefined) {
            throw new Error('Required parameter reg_emp_name was null or undefined when calling priceChangePost.');
        }
        if (reg_emp_dept_name === null || reg_emp_dept_name === undefined) {
            throw new Error('Required parameter reg_emp_dept_name was null or undefined when calling priceChangePost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling priceChangePost.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (reg_emp_id !== undefined && reg_emp_id !== null) {
            queryParameters = queryParameters.set('reg_emp_id', reg_emp_id);
        }
        if (reg_emp_name !== undefined && reg_emp_name !== null) {
            queryParameters = queryParameters.set('reg_emp_name', reg_emp_name);
        }
        if (reg_emp_dept_name !== undefined && reg_emp_dept_name !== null) {
            queryParameters = queryParameters.set('reg_emp_dept_name', reg_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/price_change", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.recyclePlaceDeadlineGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/recycle_place_deadline", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.recyclePlaceDeadlinePut = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling recyclePlaceDeadlinePut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/recycle_place_deadline", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.salesDeadlineGet = function (observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/sales_deadline", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.salesDeadlinePut = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling salesDeadlinePut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/sales_deadline", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.warehouseDivSearchGet = function (warehouse_div_depth, warehouse_div_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (warehouse_div_depth === null || warehouse_div_depth === undefined) {
            throw new Error('Required parameter warehouse_div_depth was null or undefined when calling warehouseDivSearchGet.');
        }
        if (warehouse_div_code === null || warehouse_div_code === undefined) {
            throw new Error('Required parameter warehouse_div_code was null or undefined when calling warehouseDivSearchGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (warehouse_div_depth !== undefined && warehouse_div_depth !== null) {
            queryParameters = queryParameters.set('warehouse_div_depth', warehouse_div_depth);
        }
        if (warehouse_div_code !== undefined && warehouse_div_code !== null) {
            queryParameters = queryParameters.set('warehouse_div_code', warehouse_div_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/warehouse_div/search", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    WarehouseService.prototype.warehouseDivSearchPost = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling warehouseDivSearchPost.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/warehouse_div/search", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return WarehouseService;
}());
export { WarehouseService };
