<div class="form-group m-form__group default-style">
  <label>{{ label }}</label>
  <input class="form-control m-input" type="text" placeholder="자동완성" [formControl]="control" [matAutocomplete]="auto">

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayShopName"
    (optionSelected)="onShopSelected($event.option.value)">
    <mat-option *ngFor="let option of searchResults | async" [value]="option">
      {{ displayShopName(option) }}
    </mat-option>
  </mat-autocomplete>
</div>