import { OnInit } from '@angular/core';
import { MatDatepicker, MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { dateFormat_Month, MONTH_FORMATS } from 'src/app/share/utils/common-utils';
import * as _moment from 'moment';
import { AlertDialogComponent, ConfirmDialogComponent } from 'fecommon';
import { EsGreenbusinessService } from 'src/app/restapi/be-esquery';
var moment = _moment;
var ɵ0 = MONTH_FORMATS;
var RawDataLogisticsOrderComponent = /** @class */ (function () {
    function RawDataLogisticsOrderComponent(fb, esGreenbusinessService, dialog) {
        this.fb = fb;
        this.esGreenbusinessService = esGreenbusinessService;
        this.dialog = dialog;
    }
    RawDataLogisticsOrderComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    RawDataLogisticsOrderComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            date: moment().add(-1, 'month')
        });
    };
    Object.defineProperty(RawDataLogisticsOrderComponent.prototype, "fc_date", {
        get: function () { return this.form.get('date'); },
        enumerable: true,
        configurable: true
    });
    RawDataLogisticsOrderComponent.prototype.chosenMonthHandler = function (normalizedMonth, datepicker) {
        this.fc_date.setValue(normalizedMonth);
        datepicker.close();
    };
    RawDataLogisticsOrderComponent.prototype.download = function () {
        var _this = this;
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: '그린 입고 데이터',
                description: '다운로드 하시겠습니까?',
                okText: '확인',
                cancelText: '취소'
            }
        }).afterClosed().pipe(take(1)).subscribe(function (confirmed) {
            if (confirmed) {
                _this.logisticsOrderExcelGet();
            }
        });
    };
    RawDataLogisticsOrderComponent.prototype.logisticsOrderExcelGet = function () {
        var _this = this;
        this.esGreenbusinessService.logisticsOrderExcelGet(dateFormat_Month(this.fc_date.value), dateFormat_Month(this.fc_date.value)).pipe(take(1)).subscribe(function (res) {
            _this.dialog.open(AlertDialogComponent, {
                data: {
                    message: '이메일로 전송될 예정입니다.'
                }
            });
        }, function (err) { console.error(err); });
    };
    return RawDataLogisticsOrderComponent;
}());
export { RawDataLogisticsOrderComponent };
export { ɵ0 };
