import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS } from '@angular/material';
import { EsRateSales, EsSalesService } from 'src/app/restapi/be-esquery';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DateRangePicker } from 'src/app/share/components/directives/date-range-picker.directive';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { CodeService, ByTimetable, StatisticsService } from 'src/app/restapi/be-angel';
import { Code } from 'src/app/restapi/be-angel';

@Component({
  selector: 'app-angel-day-time',
  templateUrl: './angel-day-time.component.html',
  styleUrls: ['./angel-day-time.component.scss'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS },
  ]
})
export class AngelDayTimeComponent implements OnInit {

    @ViewChild('TABLE') table: ElementRef;
    form: FormGroup;
    dataSource: MatTableDataSource<ByTimetable> = new MatTableDataSource([]);
    displayedColumns = ['department_name', 'team_name', 'location_name', 'mon_mor', 'mon_aft', 'mon_din', 'tue_mor', 'tue_aft', 'tue_din',
              'wed_mor', 'wed_aft', 'wed_din', 'thu_mor', 'thu_aft','thu_din','fri_mor','fri_aft','fri_din','sat_mor',
              'sat_aft','sat_din','sun_mor','sun_aft','sun_din'];
    act_div_list: Array<Code>;
    act_period_list: Array<Code>;
    job_list: Array<Code>;
    
    @ViewChild(MatSort) sort: MatSort;

    constructor(private fb: FormBuilder, private statisticsService: StatisticsService, private datePipe: DatePipe, private codeService: CodeService) { }

    ngOnInit() {
      this.initForm();
      this.search();

      this.codeService.angelCodeGet("자원활동","활동구분").subscribe( res => {
        this.act_div_list = res;
        console.info(this.act_div_list);
      })
      this.codeService.angelCodeGet("자원활동","활동주기").subscribe( res => {
        this.act_period_list = res;
        console.info(this.act_period_list);
      })
      this.codeService.angelCodeGet("자원활동","직업").subscribe( res => {
        this.job_list = res;
        console.info(this.job_list);
      })

    }

    ngOnDestroy(): void { }

    initForm() {
      this.form = this.fb.group({
        act_div: [''],
        act_period: [''],
        job: [''],
        gender: [''],
        gte_date: [''],
        lte_date: [''],
        dept_name: [''],
        search_act_dept_name: ['']
      });
    }

    get fc_actDiv() { return this.form.get('act_div'); }
    get fc_actPeriod() { return this.form.get('act_period'); }
    get fc_job() { return this.form.get('job'); }
    get fc_gender() { return this.form.get('gender'); }
    get fc_gteDate() { return this.form.get('gte_date'); }
    get fc_lteDate() { return this.form.get('lte_date'); }
    get fc_deptName() { return this.form.get('search_act_dept_name'); }

    rangeComplete(event: DateRangePicker) {
      this.form.patchValue({
        gte_date: event.startDate,
        lte_date: event.endDate
      });
    }

    search() {
      this.statisticsService.statisticsByTimetableGet(this.fc_deptName.value,this.fc_actDiv.value,this.fc_actPeriod.value,
        this.fc_job.value,this.fc_gender.value).subscribe({
          next: res => {
            console.info(res);
            this.dataSource.data = res;
          },
          complete: () => {
            this.sort.sortChange.emit({ active: this.sort.active, direction: this.sort.direction });
          }
        });
    }

    onClickExcelExport() {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertDatasource());
      const wscols = [{ wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 7 }, { wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },
      { wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },{ wch: 7 },
      { wch: 7 },{ wch: 7 },];
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, '요일시간대별현황_');
      XLSX.writeFile(wb, `요일시간대별현황_${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}.xlsx`);
    }

    convertDatasource() {
      const xlsxDatasource: Array<any> = [];

      this.dataSource.data.forEach((item: any) => {
        xlsxDatasource.push({
          '국': item.department_name, '팀': item.team_name, '매장': item.location_name,
          '월오전': item.mon_mor, '월오후': item.mon_aft, '월저녁': item.mon_din,
          '화오전': item.tue_mor, '화오후': item.tue_aft, '화저녁': item.tue_din,
          '수오전': item.wed_mor, '수오후': item.wed_aft, '수저녁': item.wed_din,
          '목오전': item.thu_mor, '목오후': item.thu_aft, '목저녁': item.thu_din,
          '금오전': item.fri_mor, '금오후': item.fri_aft, '금저녁': item.fri_din,
          '토오전': item.sat_mor, '토오후': item.sat_aft, '토저녁': item.sat_din,
          '일오전': item.sun_mor, '일오후': item.sun_aft, '일저녁': item.sun_din,
        });
      });
      return xlsxDatasource;
    }

}
