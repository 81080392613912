/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsFailure } from '../model/esFailure';
import { EsLogistics } from '../model/esLogistics';
import { EsLogisticsCollectTask } from '../model/esLogisticsCollectTask';
import { EsLogisticsDeptsStatistics } from '../model/esLogisticsDeptsStatistics';
import { EsLogisticsEmpsStatistics } from '../model/esLogisticsEmpsStatistics';
import { EsLogisticsTaskCount } from '../model/esLogisticsTaskCount';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsLogisticsService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 수배송 확인 검색
     * 검색 날짜 형식 e.g) -&gt; 2018-09-12
     * @param search_div 
     * @param gte_collect_date 
     * @param lte_collect_date 
     * @param logistics_collect_emp_dept_id 
     * @param logistics_collect_area_id 
     * @param logistics_collect_emp_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsConfirmGet(search_div: '권역' | '부서', gte_collect_date: string, lte_collect_date: string, logistics_collect_emp_dept_id: string, logistics_collect_area_id?: string, logistics_collect_emp_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsLogisticsCollectTask>>;
    public logisticsConfirmGet(search_div: '권역' | '부서', gte_collect_date: string, lte_collect_date: string, logistics_collect_emp_dept_id: string, logistics_collect_area_id?: string, logistics_collect_emp_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsLogisticsCollectTask>>>;
    public logisticsConfirmGet(search_div: '권역' | '부서', gte_collect_date: string, lte_collect_date: string, logistics_collect_emp_dept_id: string, logistics_collect_area_id?: string, logistics_collect_emp_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsLogisticsCollectTask>>>;
    public logisticsConfirmGet(search_div: '권역' | '부서', gte_collect_date: string, lte_collect_date: string, logistics_collect_emp_dept_id: string, logistics_collect_area_id?: string, logistics_collect_emp_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (search_div === null || search_div === undefined) {
            throw new Error('Required parameter search_div was null or undefined when calling logisticsConfirmGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsConfirmGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsConfirmGet.');
        }
        if (logistics_collect_emp_dept_id === null || logistics_collect_emp_dept_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_dept_id was null or undefined when calling logisticsConfirmGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search_div !== undefined && search_div !== null) {
            queryParameters = queryParameters.set('search_div', <any>search_div);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', <any>gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', <any>lte_collect_date);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', <any>logistics_collect_emp_dept_id);
        }
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', <any>logistics_collect_area_id);
        }
        if (logistics_collect_emp_name !== undefined && logistics_collect_emp_name !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_name', <any>logistics_collect_emp_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsLogisticsCollectTask>>(`${this.basePath}/logistics/confirm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 오늘의 수거권역별 총 박스카운트 및 수거건수
     * 검색 날짜 형식 e.g) -&gt; 2018-09-12
     * @param logistics_collect_area_id 
     * @param logistics_collect_emp_dept_id 
     * @param gte_collect_date 
     * @param lte_collect_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsDateBoxCountGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'body', reportProgress?: boolean): Observable<EsLogisticsTaskCount>;
    public logisticsDateBoxCountGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsLogisticsTaskCount>>;
    public logisticsDateBoxCountGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsLogisticsTaskCount>>;
    public logisticsDateBoxCountGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (logistics_collect_area_id === null || logistics_collect_area_id === undefined) {
            throw new Error('Required parameter logistics_collect_area_id was null or undefined when calling logisticsDateBoxCountGet.');
        }
        if (logistics_collect_emp_dept_id === null || logistics_collect_emp_dept_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_dept_id was null or undefined when calling logisticsDateBoxCountGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsDateBoxCountGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsDateBoxCountGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', <any>logistics_collect_area_id);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', <any>logistics_collect_emp_dept_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', <any>gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', <any>lte_collect_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsLogisticsTaskCount>(`${this.basePath}/logistics/date/box_count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 날짜별 수거권역별 기증건 및 logistics
     * 검색 날짜 형식 e.g) -&gt; 2018-09-12
     * @param logistics_collect_area_id 
     * @param logistics_collect_emp_dept_id 
     * @param gte_collect_date 
     * @param lte_collect_date 
     * @param assigned 
     * @param name 
     * @param base_addr 
     * @param logistics_div 
     * @param logistics_collect_emp_name 
     * @param cellp_no 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsDateGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, assigned?: 'Y' | 'N', name?: string, base_addr?: string, logistics_div?: string, logistics_collect_emp_name?: string, cellp_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsLogisticsCollectTask>>;
    public logisticsDateGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, assigned?: 'Y' | 'N', name?: string, base_addr?: string, logistics_div?: string, logistics_collect_emp_name?: string, cellp_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsLogisticsCollectTask>>>;
    public logisticsDateGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, assigned?: 'Y' | 'N', name?: string, base_addr?: string, logistics_div?: string, logistics_collect_emp_name?: string, cellp_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsLogisticsCollectTask>>>;
    public logisticsDateGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, assigned?: 'Y' | 'N', name?: string, base_addr?: string, logistics_div?: string, logistics_collect_emp_name?: string, cellp_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (logistics_collect_area_id === null || logistics_collect_area_id === undefined) {
            throw new Error('Required parameter logistics_collect_area_id was null or undefined when calling logisticsDateGet.');
        }
        if (logistics_collect_emp_dept_id === null || logistics_collect_emp_dept_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_dept_id was null or undefined when calling logisticsDateGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsDateGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsDateGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', <any>logistics_collect_area_id);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', <any>logistics_collect_emp_dept_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', <any>gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', <any>lte_collect_date);
        }
        if (assigned !== undefined && assigned !== null) {
            queryParameters = queryParameters.set('assigned', <any>assigned);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (base_addr !== undefined && base_addr !== null) {
            queryParameters = queryParameters.set('base_addr', <any>base_addr);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', <any>logistics_div);
        }
        if (logistics_collect_emp_name !== undefined && logistics_collect_emp_name !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_name', <any>logistics_collect_emp_name);
        }
        if (cellp_no !== undefined && cellp_no !== null) {
            queryParameters = queryParameters.set('cellp_no', <any>cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsLogisticsCollectTask>>(`${this.basePath}/logistics/date`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 개인의 수거간사의 수배송 task
     * 검색 날짜 형식 e.g) -&gt; 2018-09-12
     * @param logistics_collect_emp_id 
     * @param gte_collect_date 
     * @param lte_collect_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsDateTaskGet(logistics_collect_emp_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsLogisticsCollectTask>>;
    public logisticsDateTaskGet(logistics_collect_emp_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsLogisticsCollectTask>>>;
    public logisticsDateTaskGet(logistics_collect_emp_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsLogisticsCollectTask>>>;
    public logisticsDateTaskGet(logistics_collect_emp_id: string, gte_collect_date: string, lte_collect_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (logistics_collect_emp_id === null || logistics_collect_emp_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_id was null or undefined when calling logisticsDateTaskGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsDateTaskGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsDateTaskGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (logistics_collect_emp_id !== undefined && logistics_collect_emp_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_id', <any>logistics_collect_emp_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', <any>gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', <any>lte_collect_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsLogisticsCollectTask>>(`${this.basePath}/logistics/date/task`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 날짜별 수거권역별 미할당기증건 및 logistics
     * 검색 날짜 형식 e.g) -&gt; 2018-09-12
     * @param logistics_collect_area_id 
     * @param logistics_collect_emp_dept_id 
     * @param gte_collect_date 
     * @param lte_collect_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsDateUnassignGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsLogisticsCollectTask>>;
    public logisticsDateUnassignGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsLogisticsCollectTask>>>;
    public logisticsDateUnassignGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsLogisticsCollectTask>>>;
    public logisticsDateUnassignGet(logistics_collect_area_id: string, logistics_collect_emp_dept_id: string, gte_collect_date: string, lte_collect_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (logistics_collect_area_id === null || logistics_collect_area_id === undefined) {
            throw new Error('Required parameter logistics_collect_area_id was null or undefined when calling logisticsDateUnassignGet.');
        }
        if (logistics_collect_emp_dept_id === null || logistics_collect_emp_dept_id === undefined) {
            throw new Error('Required parameter logistics_collect_emp_dept_id was null or undefined when calling logisticsDateUnassignGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsDateUnassignGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsDateUnassignGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', <any>logistics_collect_area_id);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', <any>logistics_collect_emp_dept_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', <any>gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', <any>lte_collect_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsLogisticsCollectTask>>(`${this.basePath}/logistics/date/unassign`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부서별 수거통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param dept_id 
     * @param logistics_collect_area_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsDeptsGet(gte_date: string, lte_date: string, dept_id?: string, logistics_collect_area_id?: string, observe?: 'body', reportProgress?: boolean): Observable<EsLogisticsDeptsStatistics>;
    public logisticsDeptsGet(gte_date: string, lte_date: string, dept_id?: string, logistics_collect_area_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsLogisticsDeptsStatistics>>;
    public logisticsDeptsGet(gte_date: string, lte_date: string, dept_id?: string, logistics_collect_area_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsLogisticsDeptsStatistics>>;
    public logisticsDeptsGet(gte_date: string, lte_date: string, dept_id?: string, logistics_collect_area_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsDeptsGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsDeptsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', <any>logistics_collect_area_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsLogisticsDeptsStatistics>(`${this.basePath}/logistics/depts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사람별 수거통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param dept_id 
     * @param logistics_collect_area_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsEmpsGet(gte_date: string, lte_date: string, dept_id?: string, logistics_collect_area_id?: string, observe?: 'body', reportProgress?: boolean): Observable<EsLogisticsEmpsStatistics>;
    public logisticsEmpsGet(gte_date: string, lte_date: string, dept_id?: string, logistics_collect_area_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsLogisticsEmpsStatistics>>;
    public logisticsEmpsGet(gte_date: string, lte_date: string, dept_id?: string, logistics_collect_area_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsLogisticsEmpsStatistics>>;
    public logisticsEmpsGet(gte_date: string, lte_date: string, dept_id?: string, logistics_collect_area_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsEmpsGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsEmpsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', <any>logistics_collect_area_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsLogisticsEmpsStatistics>(`${this.basePath}/logistics/emps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 수배송 엑셀다운르도
     * 
     * @param gte_date 
     * @param lte_date 
     * @param dept_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsExcelGet(gte_date: string, lte_date: string, dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public logisticsExcelGet(gte_date: string, lte_date: string, dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public logisticsExcelGet(gte_date: string, lte_date: string, dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public logisticsExcelGet(gte_date: string, lte_date: string, dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/logistics/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 날짜별 수거권역기준 기증건
     * 검색 날짜 형식 e.g) -&gt; 2018-09-12
     * @param logistics_collect_area_id 
     * @param gte_collect_date 
     * @param lte_collect_date 
     * @param assigned 
     * @param name 
     * @param base_addr 
     * @param logistics_div 
     * @param cellp_no 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsGivingsDateGet(logistics_collect_area_id: string, gte_collect_date: string, lte_collect_date: string, assigned?: 'Y' | 'N', name?: string, base_addr?: string, logistics_div?: string, cellp_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsLogisticsCollectTask>>;
    public logisticsGivingsDateGet(logistics_collect_area_id: string, gte_collect_date: string, lte_collect_date: string, assigned?: 'Y' | 'N', name?: string, base_addr?: string, logistics_div?: string, cellp_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsLogisticsCollectTask>>>;
    public logisticsGivingsDateGet(logistics_collect_area_id: string, gte_collect_date: string, lte_collect_date: string, assigned?: 'Y' | 'N', name?: string, base_addr?: string, logistics_div?: string, cellp_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsLogisticsCollectTask>>>;
    public logisticsGivingsDateGet(logistics_collect_area_id: string, gte_collect_date: string, lte_collect_date: string, assigned?: 'Y' | 'N', name?: string, base_addr?: string, logistics_div?: string, cellp_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (logistics_collect_area_id === null || logistics_collect_area_id === undefined) {
            throw new Error('Required parameter logistics_collect_area_id was null or undefined when calling logisticsGivingsDateGet.');
        }
        if (gte_collect_date === null || gte_collect_date === undefined) {
            throw new Error('Required parameter gte_collect_date was null or undefined when calling logisticsGivingsDateGet.');
        }
        if (lte_collect_date === null || lte_collect_date === undefined) {
            throw new Error('Required parameter lte_collect_date was null or undefined when calling logisticsGivingsDateGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', <any>logistics_collect_area_id);
        }
        if (gte_collect_date !== undefined && gte_collect_date !== null) {
            queryParameters = queryParameters.set('gte_collect_date', <any>gte_collect_date);
        }
        if (lte_collect_date !== undefined && lte_collect_date !== null) {
            queryParameters = queryParameters.set('lte_collect_date', <any>lte_collect_date);
        }
        if (assigned !== undefined && assigned !== null) {
            queryParameters = queryParameters.set('assigned', <any>assigned);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (base_addr !== undefined && base_addr !== null) {
            queryParameters = queryParameters.set('base_addr', <any>base_addr);
        }
        if (logistics_div !== undefined && logistics_div !== null) {
            queryParameters = queryParameters.set('logistics_div', <any>logistics_div);
        }
        if (cellp_no !== undefined && cellp_no !== null) {
            queryParameters = queryParameters.set('cellp_no', <any>cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsLogisticsCollectTask>>(`${this.basePath}/logistics/givings/date`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 수배송 및 대량요청
     * 대외협력 &gt; 수배송 및 대량요청 메인화면 조회(요청등록일, 요청부서, 권역이름)
     * @param gte_date 
     * @param lte_date 
     * @param logistics_req_emp_dept_name 요청부서
     * @param logistics_collect_emp_dept_id 수배송 담당부서 아이디
     * @param event_flg 행사 포함 여부 Y,N
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logisticsNormalLargeGet(gte_date: string, lte_date: string, logistics_req_emp_dept_name?: string, logistics_collect_emp_dept_id?: string, event_flg?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsLogistics>>;
    public logisticsNormalLargeGet(gte_date: string, lte_date: string, logistics_req_emp_dept_name?: string, logistics_collect_emp_dept_id?: string, event_flg?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsLogistics>>>;
    public logisticsNormalLargeGet(gte_date: string, lte_date: string, logistics_req_emp_dept_name?: string, logistics_collect_emp_dept_id?: string, event_flg?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsLogistics>>>;
    public logisticsNormalLargeGet(gte_date: string, lte_date: string, logistics_req_emp_dept_name?: string, logistics_collect_emp_dept_id?: string, event_flg?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling logisticsNormalLargeGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling logisticsNormalLargeGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (logistics_req_emp_dept_name !== undefined && logistics_req_emp_dept_name !== null) {
            queryParameters = queryParameters.set('logistics_req_emp_dept_name', <any>logistics_req_emp_dept_name);
        }
        if (logistics_collect_emp_dept_id !== undefined && logistics_collect_emp_dept_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_emp_dept_id', <any>logistics_collect_emp_dept_id);
        }
        if (event_flg !== undefined && event_flg !== null) {
            queryParameters = queryParameters.set('event_flg', <any>event_flg);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsLogistics>>(`${this.basePath}/logistics/normal_large`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
