/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Account } from '../model/account';
import { ArrayOfUser } from '../model/arrayOfUser';
import { Empty } from '../model/empty';
import { Failure } from '../model/failure';
import { User } from '../model/user';
import { UserAttr } from '../model/userAttr';
import { UserMeta } from '../model/userMeta';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AccountService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 사용자 계정 삭제 (Admin)
     * 
     * @param id account_email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public accountIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public accountIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public accountIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling accountIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<Empty>(`${this.basePath}/account/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사용자 계정 해지 / 복구 (Batch)
     * 
     * @param id account_email
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountIdPut(id: string, user: UserAttr, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public accountIdPut(id: string, user: UserAttr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public accountIdPut(id: string, user: UserAttr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public accountIdPut(id: string, user: UserAttr, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling accountIdPut.');
        }
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling accountIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Empty>(`${this.basePath}/account/${encodeURIComponent(String(id))}`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사용자 계정 등록 (HRM)
     * 
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountPost(user: User, observe?: 'body', reportProgress?: boolean): Observable<Account>;
    public accountPost(user: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Account>>;
    public accountPost(user: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Account>>;
    public accountPost(user: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling accountPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Account>(`${this.basePath}/account`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * auth0 사용자 검색
     * 
     * @param q meta_key:meta_val, ex) userid:bstore
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public auth0Get(q: string, observe?: 'body', reportProgress?: boolean): Observable<ArrayOfUser>;
    public auth0Get(q: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArrayOfUser>>;
    public auth0Get(q: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArrayOfUser>>;
    public auth0Get(q: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (q === null || q === undefined) {
            throw new Error('Required parameter q was null or undefined when calling auth0Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<ArrayOfUser>(`${this.basePath}/auth0`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * auth0 사용자 삭제
     * 
     * @param id user_id issued by auth0
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public auth0IdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public auth0IdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public auth0IdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public auth0IdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling auth0IdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<Empty>(`${this.basePath}/auth0/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * auth0 사용자 상세정보 조회
     * 
     * @param id user_id issued by auth0
     * @param q option: um, am, fi, mr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public auth0IdGet(id: string, q: string, observe?: 'body', reportProgress?: boolean): Observable<UserMeta>;
    public auth0IdGet(id: string, q: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserMeta>>;
    public auth0IdGet(id: string, q: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserMeta>>;
    public auth0IdGet(id: string, q: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling auth0IdGet.');
        }
        if (q === null || q === undefined) {
            throw new Error('Required parameter q was null or undefined when calling auth0IdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<UserMeta>(`${this.basePath}/auth0/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * auth0 사용자 비활성화 / 활성화
     * 
     * @param id user_id issued by auth0
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public auth0IdPut(id: string, user: UserAttr, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public auth0IdPut(id: string, user: UserAttr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public auth0IdPut(id: string, user: UserAttr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public auth0IdPut(id: string, user: UserAttr, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling auth0IdPut.');
        }
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling auth0IdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Empty>(`${this.basePath}/auth0/${encodeURIComponent(String(id))}`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * auth0 사용자 등록
     * 
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public auth0Post(user: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public auth0Post(user: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public auth0Post(user: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public auth0Post(user: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling auth0Post.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/auth0`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gsuite 사용자 검색
     * 
     * @param q account_email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gsuiteGet(q: string, observe?: 'body', reportProgress?: boolean): Observable<ArrayOfUser>;
    public gsuiteGet(q: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArrayOfUser>>;
    public gsuiteGet(q: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArrayOfUser>>;
    public gsuiteGet(q: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (q === null || q === undefined) {
            throw new Error('Required parameter q was null or undefined when calling gsuiteGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<ArrayOfUser>(`${this.basePath}/gsuite`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gsuite 사용자 삭제
     * 
     * @param id account_email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gsuiteIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public gsuiteIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public gsuiteIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public gsuiteIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling gsuiteIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<Empty>(`${this.basePath}/gsuite/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gsuite 사용자 비활성화 / 활성화
     * 
     * @param id account_email
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gsuiteIdPut(id: string, user: UserAttr, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public gsuiteIdPut(id: string, user: UserAttr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public gsuiteIdPut(id: string, user: UserAttr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public gsuiteIdPut(id: string, user: UserAttr, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling gsuiteIdPut.');
        }
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling gsuiteIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Empty>(`${this.basePath}/gsuite/${encodeURIComponent(String(id))}`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gsuite 사용자 등록
     * 
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gsuitePost(user: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public gsuitePost(user: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public gsuitePost(user: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public gsuitePost(user: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling gsuitePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/gsuite`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
