/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsAlwaysSupplyShopData } from '../model/esAlwaysSupplyShopData';
import { EsCenterCirculationCourseData } from '../model/esCenterCirculationCourseData';
import { EsDownloadUrl } from '../model/esDownloadUrl';
import { EsEventShippingStatisticalData } from '../model/esEventShippingStatisticalData';
import { EsFailure } from '../model/esFailure';
import { EsGivingMoveData } from '../model/esGivingMoveData';
import { EsGreenbusinessStorage } from '../model/esGreenbusinessStorage';
import { EsGreenbusinessStorageInout } from '../model/esGreenbusinessStorageInout';
import { EsGreenbusinessStorageInoutOpen } from '../model/esGreenbusinessStorageInoutOpen';
import { EsGreenbusinessStorageInoutStatus } from '../model/esGreenbusinessStorageInoutStatus';
import { EsGreenbusinessStorageOut } from '../model/esGreenbusinessStorageOut';
import { EsGreenbusinessStoragePurchase } from '../model/esGreenbusinessStoragePurchase';
import { EsGreenbusinessStorageTaxDate } from '../model/esGreenbusinessStorageTaxDate';
import { EsInoutManagement } from '../model/esInoutManagement';
import { EsInoutManagementShop } from '../model/esInoutManagementShop';
import { EsInoutRealTime } from '../model/esInoutRealTime';
import { EsMonthStorage } from '../model/esMonthStorage';
import { EsProductionData } from '../model/esProductionData';
import { EsProductionDataSearchInfos } from '../model/esProductionDataSearchInfos';
import { EsProductionStatisticalData } from '../model/esProductionStatisticalData';
import { EsProductionWorkGroupData } from '../model/esProductionWorkGroupData';
import { EsReceivingConfirm } from '../model/esReceivingConfirm';
import { EsReceivingData } from '../model/esReceivingData';
import { EsSeq } from '../model/esSeq';
import { EsShareProductData } from '../model/esShareProductData';
import { EsShippingData } from '../model/esShippingData';
import { EsShippingStatisticalData } from '../model/esShippingStatisticalData';
import { EsShopGivingGoodStatus } from '../model/esShopGivingGoodStatus';
import { EsShopInout } from '../model/esShopInout';
import { EsShopInoutAggregate } from '../model/esShopInoutAggregate';
import { EsShopInoutConfirm } from '../model/esShopInoutConfirm';
import { EsShopInoutOpen } from '../model/esShopInoutOpen';
import { EsShopInoutStatus } from '../model/esShopInoutStatus';
import { EsShopOrder } from '../model/esShopOrder';
import { EsStorageData } from '../model/esStorageData';
import { EsStorageLocationData } from '../model/esStorageLocationData';
import { EsStorageNameData } from '../model/esStorageNameData';
import { EsStorageStatusData } from '../model/esStorageStatusData';
import { EsTargetYear } from '../model/esTargetYear';
import { EsTotalStatisticalData } from '../model/esTotalStatisticalData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsWarehouseService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 상품별 매장 현황 검색
     * 상품별 매장 현황 검색한다.
     * @param good_code 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGoodShopGoodStatusGet(good_code: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsInoutRealTime>>;
    public warehouseGoodShopGoodStatusGet(good_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsInoutRealTime>>>;
    public warehouseGoodShopGoodStatusGet(good_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsInoutRealTime>>>;
    public warehouseGoodShopGoodStatusGet(good_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseGoodShopGoodStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsInoutRealTime>>(`${this.basePath}/warehouse/good/shop/good_status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상품별 매장 현황 검색
     * 상품별 매장 현황 검색한다.
     * @param good_code 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGoodShopGoodStatusNewGet(good_code: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsInoutRealTime>>;
    public warehouseGoodShopGoodStatusNewGet(good_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsInoutRealTime>>>;
    public warehouseGoodShopGoodStatusNewGet(good_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsInoutRealTime>>>;
    public warehouseGoodShopGoodStatusNewGet(good_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseGoodShopGoodStatusNewGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsInoutRealTime>>(`${this.basePath}/warehouse/good/shop/good_status_new`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 실시간 창고별 상품 현황 검색
     * 실시간 창고별 상품 현황 검색한다.
     * @param management_id 매장아이디 또는 창고아이디
     * @param good_first_category_name 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenStorageRealTimeGoodStatusGet(management_id: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'body', reportProgress?: boolean): Observable<Array<EsInoutRealTime>>;
    public warehouseGreenStorageRealTimeGoodStatusGet(management_id: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsInoutRealTime>>>;
    public warehouseGreenStorageRealTimeGoodStatusGet(management_id: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsInoutRealTime>>>;
    public warehouseGreenStorageRealTimeGoodStatusGet(management_id: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseGreenStorageRealTimeGoodStatusGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseGreenStorageRealTimeGoodStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', <any>management_id);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsInoutRealTime>>(`${this.basePath}/warehouse/green_storage/real_time/good_status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린창고 기준 매장 반품 목록 검색
     * 그린창고 기준 매장 반품 목록을 검색한다.
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param shop_name 매장명
     * @param good_name 상품명
     * @param confirm_flg 승인여부
     * @param shop_id 매장아이디
     * @param good_first_category_name 상품구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenStorageShopReturnGet(gte_date: string, lte_date: string, shop_name?: string, good_name?: string, confirm_flg?: 'Y' | 'N', shop_id?: string, good_first_category_name?: '공익상품' | '메아리상품', observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInout>>;
    public warehouseGreenStorageShopReturnGet(gte_date: string, lte_date: string, shop_name?: string, good_name?: string, confirm_flg?: 'Y' | 'N', shop_id?: string, good_first_category_name?: '공익상품' | '메아리상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInout>>>;
    public warehouseGreenStorageShopReturnGet(gte_date: string, lte_date: string, shop_name?: string, good_name?: string, confirm_flg?: 'Y' | 'N', shop_id?: string, good_first_category_name?: '공익상품' | '메아리상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInout>>>;
    public warehouseGreenStorageShopReturnGet(gte_date: string, lte_date: string, shop_name?: string, good_name?: string, confirm_flg?: 'Y' | 'N', shop_id?: string, good_first_category_name?: '공익상품' | '메아리상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenStorageShopReturnGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenStorageShopReturnGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (confirm_flg !== undefined && confirm_flg !== null) {
            queryParameters = queryParameters.set('confirm_flg', <any>confirm_flg);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInout>>(`${this.basePath}/warehouse/green_storage/shop_return`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린물류창고 검색(창고명, 창고구분)
     * 그린물류창고를 조건에 의해 검색한다.
     * @param greenbusiness_storage_name 창고명
     * @param greenbusiness_storage_category 창고구분
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageGet(greenbusiness_storage_name?: string, greenbusiness_storage_category?: '창고' | '공장' | '외주공장', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStorage>>;
    public warehouseGreenbusinessStorageGet(greenbusiness_storage_name?: string, greenbusiness_storage_category?: '창고' | '공장' | '외주공장', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStorage>>>;
    public warehouseGreenbusinessStorageGet(greenbusiness_storage_name?: string, greenbusiness_storage_category?: '창고' | '공장' | '외주공장', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStorage>>>;
    public warehouseGreenbusinessStorageGet(greenbusiness_storage_name?: string, greenbusiness_storage_category?: '창고' | '공장' | '외주공장', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (greenbusiness_storage_name !== undefined && greenbusiness_storage_name !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_name', <any>greenbusiness_storage_name);
        }
        if (greenbusiness_storage_category !== undefined && greenbusiness_storage_category !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_category', <any>greenbusiness_storage_category);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStorage>>(`${this.basePath}/warehouse/greenbusiness_storage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린물류창고 수불 내역 검색(등록일, 창고아이디, 입출고구분, 물류구분, 상품명)
     * 그린물류창고 수불 내역을 조건에 의해 검색한다.
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param greenbusiness_storage_id 창고아이디
     * @param greenbusiness_storage_inout_flg 입출고
     * @param good_first_category_name 상품구분
     * @param greenbusiness_storage_inout_category 물류구분
     * @param good_name 상품명
     * @param greenbusiness_storage_inout_issue_type 입출고등록구분
     * @param greenbusiness_storage_inout_place_name 입출고 장소명
     * @param good_div_code 상품 카테고리
     * @param page_number 
     * @param greenbusiness_storage_inout_zero 제로수불 여부
     * @param inout_category 물류구분ENUM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageInoutGet(gte_date: string, lte_date: string, greenbusiness_storage_id?: string, greenbusiness_storage_inout_flg?: string, good_first_category_name?: string, greenbusiness_storage_inout_category?: string, good_name?: string, greenbusiness_storage_inout_issue_type?: 'SYSTEM' | 'DIRECT', greenbusiness_storage_inout_place_name?: string, good_div_code?: string, page_number?: string, greenbusiness_storage_inout_zero?: 'Y' | 'N', inout_category?: '생산자' | '납품처반품' | '매장반품' | '생산입고' | '창고순환' | '온라인직송' | 'B2B특판직송' | '대량직송' | '온라인직송반품' | 'B2B특판직송반품' | '대량직송반품' | '세트구성' | '세트해체' | '일반출고' | '자동출고' | '임의출고' | '온라인' | 'B2B특판' | '내부소비' | '생산자반품' | '원재료' | '증정용' | '폐기' | '매장직송' | '납품처직송' | '기타', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStorageInout>>;
    public warehouseGreenbusinessStorageInoutGet(gte_date: string, lte_date: string, greenbusiness_storage_id?: string, greenbusiness_storage_inout_flg?: string, good_first_category_name?: string, greenbusiness_storage_inout_category?: string, good_name?: string, greenbusiness_storage_inout_issue_type?: 'SYSTEM' | 'DIRECT', greenbusiness_storage_inout_place_name?: string, good_div_code?: string, page_number?: string, greenbusiness_storage_inout_zero?: 'Y' | 'N', inout_category?: '생산자' | '납품처반품' | '매장반품' | '생산입고' | '창고순환' | '온라인직송' | 'B2B특판직송' | '대량직송' | '온라인직송반품' | 'B2B특판직송반품' | '대량직송반품' | '세트구성' | '세트해체' | '일반출고' | '자동출고' | '임의출고' | '온라인' | 'B2B특판' | '내부소비' | '생산자반품' | '원재료' | '증정용' | '폐기' | '매장직송' | '납품처직송' | '기타', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStorageInout>>>;
    public warehouseGreenbusinessStorageInoutGet(gte_date: string, lte_date: string, greenbusiness_storage_id?: string, greenbusiness_storage_inout_flg?: string, good_first_category_name?: string, greenbusiness_storage_inout_category?: string, good_name?: string, greenbusiness_storage_inout_issue_type?: 'SYSTEM' | 'DIRECT', greenbusiness_storage_inout_place_name?: string, good_div_code?: string, page_number?: string, greenbusiness_storage_inout_zero?: 'Y' | 'N', inout_category?: '생산자' | '납품처반품' | '매장반품' | '생산입고' | '창고순환' | '온라인직송' | 'B2B특판직송' | '대량직송' | '온라인직송반품' | 'B2B특판직송반품' | '대량직송반품' | '세트구성' | '세트해체' | '일반출고' | '자동출고' | '임의출고' | '온라인' | 'B2B특판' | '내부소비' | '생산자반품' | '원재료' | '증정용' | '폐기' | '매장직송' | '납품처직송' | '기타', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStorageInout>>>;
    public warehouseGreenbusinessStorageInoutGet(gte_date: string, lte_date: string, greenbusiness_storage_id?: string, greenbusiness_storage_inout_flg?: string, good_first_category_name?: string, greenbusiness_storage_inout_category?: string, good_name?: string, greenbusiness_storage_inout_issue_type?: 'SYSTEM' | 'DIRECT', greenbusiness_storage_inout_place_name?: string, good_div_code?: string, page_number?: string, greenbusiness_storage_inout_zero?: 'Y' | 'N', inout_category?: '생산자' | '납품처반품' | '매장반품' | '생산입고' | '창고순환' | '온라인직송' | 'B2B특판직송' | '대량직송' | '온라인직송반품' | 'B2B특판직송반품' | '대량직송반품' | '세트구성' | '세트해체' | '일반출고' | '자동출고' | '임의출고' | '온라인' | 'B2B특판' | '내부소비' | '생산자반품' | '원재료' | '증정용' | '폐기' | '매장직송' | '납품처직송' | '기타', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenbusinessStorageInoutGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenbusinessStorageInoutGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', <any>greenbusiness_storage_id);
        }
        if (greenbusiness_storage_inout_flg !== undefined && greenbusiness_storage_inout_flg !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_flg', <any>greenbusiness_storage_inout_flg);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (greenbusiness_storage_inout_category !== undefined && greenbusiness_storage_inout_category !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_category', <any>greenbusiness_storage_inout_category);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (greenbusiness_storage_inout_issue_type !== undefined && greenbusiness_storage_inout_issue_type !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_issue_type', <any>greenbusiness_storage_inout_issue_type);
        }
        if (greenbusiness_storage_inout_place_name !== undefined && greenbusiness_storage_inout_place_name !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_place_name', <any>greenbusiness_storage_inout_place_name);
        }
        if (good_div_code !== undefined && good_div_code !== null) {
            queryParameters = queryParameters.set('good_div_code', <any>good_div_code);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }
        if (greenbusiness_storage_inout_zero !== undefined && greenbusiness_storage_inout_zero !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_zero', <any>greenbusiness_storage_inout_zero);
        }
        if (inout_category !== undefined && inout_category !== null) {
            queryParameters = queryParameters.set('inout_category', <any>inout_category);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStorageInout>>(`${this.basePath}/warehouse/greenbusiness_storage/inout`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 창고입출고 ID로 연결된 창고입출고 내역 검색
     * 창고입출고 ID로 연결된 창고입출고 내역 검색한다.
     * @param inout_id 창고입출고 ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageInoutInoutIdGet(inout_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStorageInout>>;
    public warehouseGreenbusinessStorageInoutInoutIdGet(inout_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStorageInout>>>;
    public warehouseGreenbusinessStorageInoutInoutIdGet(inout_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStorageInout>>>;
    public warehouseGreenbusinessStorageInoutInoutIdGet(inout_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inout_id === null || inout_id === undefined) {
            throw new Error('Required parameter inout_id was null or undefined when calling warehouseGreenbusinessStorageInoutInoutIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStorageInout>>(`${this.basePath}/warehouse/greenbusiness_storage_inout/${encodeURIComponent(String(inout_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 창고별 수불 수정 요청 목록
     * 창고별 수불 수정 요청 내역을 조건에 의해 검색한다.
     * @param greenbusiness_storage_id 창고아이디
     * @param inout_mng_yyyy_mm 관리 년월
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageInoutOpenGet(greenbusiness_storage_id?: string, inout_mng_yyyy_mm?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStorageInoutOpen>>;
    public warehouseGreenbusinessStorageInoutOpenGet(greenbusiness_storage_id?: string, inout_mng_yyyy_mm?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStorageInoutOpen>>>;
    public warehouseGreenbusinessStorageInoutOpenGet(greenbusiness_storage_id?: string, inout_mng_yyyy_mm?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStorageInoutOpen>>>;
    public warehouseGreenbusinessStorageInoutOpenGet(greenbusiness_storage_id?: string, inout_mng_yyyy_mm?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', <any>greenbusiness_storage_id);
        }
        if (inout_mng_yyyy_mm !== undefined && inout_mng_yyyy_mm !== null) {
            queryParameters = queryParameters.set('inout_mng_yyyy_mm', <any>inout_mng_yyyy_mm);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStorageInoutOpen>>(`${this.basePath}/warehouse/greenbusiness_storage_inout_open`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린물류창고 출고시 상품명 Like 검색
     * 상품 출고시 특정 창고에 현황을 상품명으로 LIKE 검색한다.
     * @param greenbusiness_storage_id 창고아이디
     * @param good_name 상품명
     * @param good_first_category_name 상품분류
     * @param good_code 상품코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageInoutStatusGet(greenbusiness_storage_id: string, good_name?: string, good_first_category_name?: '메아리상품' | '공익상품', good_code?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStorageInoutStatus>>;
    public warehouseGreenbusinessStorageInoutStatusGet(greenbusiness_storage_id: string, good_name?: string, good_first_category_name?: '메아리상품' | '공익상품', good_code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStorageInoutStatus>>>;
    public warehouseGreenbusinessStorageInoutStatusGet(greenbusiness_storage_id: string, good_name?: string, good_first_category_name?: '메아리상품' | '공익상품', good_code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStorageInoutStatus>>>;
    public warehouseGreenbusinessStorageInoutStatusGet(greenbusiness_storage_id: string, good_name?: string, good_first_category_name?: '메아리상품' | '공익상품', good_code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (greenbusiness_storage_id === null || greenbusiness_storage_id === undefined) {
            throw new Error('Required parameter greenbusiness_storage_id was null or undefined when calling warehouseGreenbusinessStorageInoutStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', <any>greenbusiness_storage_id);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStorageInoutStatus>>(`${this.basePath}/warehouse/greenbusiness_storage/inout/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린물류창고 출고시 특정 상품 현황 검색 결과
     * 그린물류창고 출고시 특정 상품 현황 검색 결과
     * @param greenbusiness_storage_id 창고아이디
     * @param good_code 상품코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageInventoryStatusGet(greenbusiness_storage_id: string, good_code: string, observe?: 'body', reportProgress?: boolean): Observable<EsGreenbusinessStorageInoutStatus>;
    public warehouseGreenbusinessStorageInventoryStatusGet(greenbusiness_storage_id: string, good_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsGreenbusinessStorageInoutStatus>>;
    public warehouseGreenbusinessStorageInventoryStatusGet(greenbusiness_storage_id: string, good_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsGreenbusinessStorageInoutStatus>>;
    public warehouseGreenbusinessStorageInventoryStatusGet(greenbusiness_storage_id: string, good_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (greenbusiness_storage_id === null || greenbusiness_storage_id === undefined) {
            throw new Error('Required parameter greenbusiness_storage_id was null or undefined when calling warehouseGreenbusinessStorageInventoryStatusGet.');
        }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseGreenbusinessStorageInventoryStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', <any>greenbusiness_storage_id);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsGreenbusinessStorageInoutStatus>(`${this.basePath}/warehouse/greenbusiness_storage/inventory/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 주문 ID로 그린물류창고 수불 내역 검색
     * 주문 ID로 그린물류창고 수불 내역 검색한다.
     * @param order_id 주문아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageOrderIdInoutGet(order_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStorageInout>>;
    public warehouseGreenbusinessStorageOrderIdInoutGet(order_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStorageInout>>>;
    public warehouseGreenbusinessStorageOrderIdInoutGet(order_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStorageInout>>>;
    public warehouseGreenbusinessStorageOrderIdInoutGet(order_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (order_id === null || order_id === undefined) {
            throw new Error('Required parameter order_id was null or undefined when calling warehouseGreenbusinessStorageOrderIdInoutGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStorageInout>>(`${this.basePath}/warehouse/greenbusiness_storage/${encodeURIComponent(String(order_id))}/inout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린물류창고 출고 내역 검색(출고일, 출고구분, 상품구분, 상품명, 발송처, 수신처)
     * 그린물류창고 출고 내역을 조건에 의해 검색한다.
     * @param gte_date 출고일 시작
     * @param lte_date 출고일 끝
     * @param greenbusiness_storage_out_type 출고구분
     * @param good_first_category_name 상품구분
     * @param good_name 상품명
     * @param greenbusiness_storage_inout_place_name 발송처 또는 수신처
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageOutGet(gte_date: string, lte_date: string, greenbusiness_storage_out_type?: '매장' | '매장택배' | '온라인' | 'B2B특판' | '임의' | '내부', good_first_category_name?: string, good_name?: string, greenbusiness_storage_inout_place_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStorageOut>>;
    public warehouseGreenbusinessStorageOutGet(gte_date: string, lte_date: string, greenbusiness_storage_out_type?: '매장' | '매장택배' | '온라인' | 'B2B특판' | '임의' | '내부', good_first_category_name?: string, good_name?: string, greenbusiness_storage_inout_place_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStorageOut>>>;
    public warehouseGreenbusinessStorageOutGet(gte_date: string, lte_date: string, greenbusiness_storage_out_type?: '매장' | '매장택배' | '온라인' | 'B2B특판' | '임의' | '내부', good_first_category_name?: string, good_name?: string, greenbusiness_storage_inout_place_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStorageOut>>>;
    public warehouseGreenbusinessStorageOutGet(gte_date: string, lte_date: string, greenbusiness_storage_out_type?: '매장' | '매장택배' | '온라인' | 'B2B특판' | '임의' | '내부', good_first_category_name?: string, good_name?: string, greenbusiness_storage_inout_place_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenbusinessStorageOutGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenbusinessStorageOutGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (greenbusiness_storage_out_type !== undefined && greenbusiness_storage_out_type !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_out_type', <any>greenbusiness_storage_out_type);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (greenbusiness_storage_inout_place_name !== undefined && greenbusiness_storage_inout_place_name !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_place_name', <any>greenbusiness_storage_inout_place_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStorageOut>>(`${this.basePath}/warehouse/greenbusiness_storage/out`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 그린물류창고 매입 내역 조회(등록일, 생산자명, 상품명)
     * 그린물류창고 매입 내역을 조건에 의해 검색한다.
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param good_name 상품명
     * @param maker_name 생산자명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStoragePurchaseGet(gte_date: string, lte_date: string, good_name?: string, maker_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStoragePurchase>>;
    public warehouseGreenbusinessStoragePurchaseGet(gte_date: string, lte_date: string, good_name?: string, maker_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStoragePurchase>>>;
    public warehouseGreenbusinessStoragePurchaseGet(gte_date: string, lte_date: string, good_name?: string, maker_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStoragePurchase>>>;
    public warehouseGreenbusinessStoragePurchaseGet(gte_date: string, lte_date: string, good_name?: string, maker_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenbusinessStoragePurchaseGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenbusinessStoragePurchaseGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', <any>maker_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStoragePurchase>>(`${this.basePath}/warehouse/greenbusiness_storage/purchase`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 세금계산서일자 관리를 위한 그린물류창고 입고 내역 검색(입고일, 세금계산서발행일자, 생산자, 창고, 상품명 ,발주번호  ,등록여부
     * 세금계산서일자 관리를 위한 그린물류창고 입고 내역을 조건에 의해 검색한다.
     * @param period_type 기간타입(입고일자_세금계산서일자)
     * @param gte_date 검색 시작일
     * @param lte_date 검색 종료일
     * @param maker_name 생산자명
     * @param greenbusiness_storage_id 창고아이디
     * @param good_name 상품명
     * @param logistics_order_id 발주번호
     * @param tax_date_flg 세금계산서일자 등록여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseGreenbusinessStorageTaxDateGet(period_type: '입고일자' | '세금계산서일자', gte_date: string, lte_date: string, maker_name?: string, greenbusiness_storage_id?: string, good_name?: string, logistics_order_id?: string, tax_date_flg?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGreenbusinessStorageTaxDate>>;
    public warehouseGreenbusinessStorageTaxDateGet(period_type: '입고일자' | '세금계산서일자', gte_date: string, lte_date: string, maker_name?: string, greenbusiness_storage_id?: string, good_name?: string, logistics_order_id?: string, tax_date_flg?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGreenbusinessStorageTaxDate>>>;
    public warehouseGreenbusinessStorageTaxDateGet(period_type: '입고일자' | '세금계산서일자', gte_date: string, lte_date: string, maker_name?: string, greenbusiness_storage_id?: string, good_name?: string, logistics_order_id?: string, tax_date_flg?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGreenbusinessStorageTaxDate>>>;
    public warehouseGreenbusinessStorageTaxDateGet(period_type: '입고일자' | '세금계산서일자', gte_date: string, lte_date: string, maker_name?: string, greenbusiness_storage_id?: string, good_name?: string, logistics_order_id?: string, tax_date_flg?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (period_type === null || period_type === undefined) {
            throw new Error('Required parameter period_type was null or undefined when calling warehouseGreenbusinessStorageTaxDateGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenbusinessStorageTaxDateGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenbusinessStorageTaxDateGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (period_type !== undefined && period_type !== null) {
            queryParameters = queryParameters.set('period_type', <any>period_type);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', <any>maker_name);
        }
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', <any>greenbusiness_storage_id);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (logistics_order_id !== undefined && logistics_order_id !== null) {
            queryParameters = queryParameters.set('logistics_order_id', <any>logistics_order_id);
        }
        if (tax_date_flg !== undefined && tax_date_flg !== null) {
            queryParameters = queryParameters.set('tax_date_flg', <any>tax_date_flg);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGreenbusinessStorageTaxDate>>(`${this.basePath}/warehouse/greenbusiness_storage/tax_date`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 월별 재고 관리 확정 여부 확인
     * 월별 재고 관리 확정 여부 확인한다.
     * @param management_id 매장아이디 또는 창고아이디
     * @param search_yyyy_mm 조회년월
     * @param good_first_category_name 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseInoutConfirmationCheckGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'body', reportProgress?: boolean): Observable<EsInoutManagement>;
    public warehouseInoutConfirmationCheckGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsInoutManagement>>;
    public warehouseInoutConfirmationCheckGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsInoutManagement>>;
    public warehouseInoutConfirmationCheckGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutConfirmationCheckGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutConfirmationCheckGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseInoutConfirmationCheckGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', <any>management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', <any>search_yyyy_mm);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsInoutManagement>(`${this.basePath}/warehouse/inout/confirmation_check`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 월별 재고 관리를 위한 현황 검색
     * 월별 재고 관리를 위한 현황을 검색한다.
     * @param management_id 매장아이디 또는 창고아이디
     * @param search_yyyy_mm 조회년월
     * @param good_first_category_name 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseInoutManagementGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'body', reportProgress?: boolean): Observable<EsInoutManagement>;
    public warehouseInoutManagementGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsInoutManagement>>;
    public warehouseInoutManagementGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsInoutManagement>>;
    public warehouseInoutManagementGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutManagementGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseInoutManagementGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', <any>management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', <any>search_yyyy_mm);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsInoutManagement>(`${this.basePath}/warehouse/inout/management`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 관리매장별 월별 확정 목록 검색
     * 관리매장별 월별 확정 목록 검색
     * @param management_id 매장아이디 또는 창고아이디
     * @param search_yyyy_mm 조회년월
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseInoutManagementMonthGet(management_id: string, search_yyyy_mm: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsInoutManagement>>;
    public warehouseInoutManagementMonthGet(management_id: string, search_yyyy_mm: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsInoutManagement>>>;
    public warehouseInoutManagementMonthGet(management_id: string, search_yyyy_mm: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsInoutManagement>>>;
    public warehouseInoutManagementMonthGet(management_id: string, search_yyyy_mm: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutManagementMonthGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', <any>management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', <any>search_yyyy_mm);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsInoutManagement>>(`${this.basePath}/warehouse/inout/management/month`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 재고 현황 검색
     * 매장별 재고 현황 검색한다.
     * @param search_yyyy_mm 조회년월
     * @param shop_name 매장명
     * @param good_first_category_name 상품분류
     * @param thismonth_mng_confirm_flg 재고조사구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseInoutManagementShopGet(search_yyyy_mm: string, shop_name?: string, good_first_category_name?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', thismonth_mng_confirm_flg?: '완료' | '미완료', observe?: 'body', reportProgress?: boolean): Observable<EsInoutManagementShop>;
    public warehouseInoutManagementShopGet(search_yyyy_mm: string, shop_name?: string, good_first_category_name?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', thismonth_mng_confirm_flg?: '완료' | '미완료', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsInoutManagementShop>>;
    public warehouseInoutManagementShopGet(search_yyyy_mm: string, shop_name?: string, good_first_category_name?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', thismonth_mng_confirm_flg?: '완료' | '미완료', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsInoutManagementShop>>;
    public warehouseInoutManagementShopGet(search_yyyy_mm: string, shop_name?: string, good_first_category_name?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', thismonth_mng_confirm_flg?: '완료' | '미완료', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementShopGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', <any>search_yyyy_mm);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (thismonth_mng_confirm_flg !== undefined && thismonth_mng_confirm_flg !== null) {
            queryParameters = queryParameters.set('thismonth_mng_confirm_flg', <any>thismonth_mng_confirm_flg);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsInoutManagementShop>(`${this.basePath}/warehouse/inout/management/shop`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 미등록 시 시스템에 의한 월별 재고 관리를 위한 현황 검색
     * 미등록 시 시스템에 의한 월별 재고 관리를 위한 현황을 검색한다.
     * @param management_id 매장아이디 또는 창고아이디
     * @param search_yyyy_mm 조회년월
     * @param good_first_category_name 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseInoutManagementSystemCoercionGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'body', reportProgress?: boolean): Observable<EsInoutManagement>;
    public warehouseInoutManagementSystemCoercionGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsInoutManagement>>;
    public warehouseInoutManagementSystemCoercionGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsInoutManagement>>;
    public warehouseInoutManagementSystemCoercionGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutManagementSystemCoercionGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementSystemCoercionGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseInoutManagementSystemCoercionGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', <any>management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', <any>search_yyyy_mm);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsInoutManagement>(`${this.basePath}/warehouse/inout/management/system/coercion`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 시스템에 의한 월별 재고 관리를 위한 현황 검색
     * 시스템에 의한 월별 재고 관리를 위한 현황을 검색한다.
     * @param management_id 매장아이디 또는 창고아이디
     * @param search_yyyy_mm 조회년월
     * @param good_first_category_name 상품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseInoutManagementSystemGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'body', reportProgress?: boolean): Observable<EsInoutManagement>;
    public warehouseInoutManagementSystemGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsInoutManagement>>;
    public warehouseInoutManagementSystemGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsInoutManagement>>;
    public warehouseInoutManagementSystemGet(management_id: string, search_yyyy_mm: string, good_first_category_name: '기증물품' | '별정상품' | '메아리상품' | '공익상품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutManagementSystemGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementSystemGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseInoutManagementSystemGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', <any>management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', <any>search_yyyy_mm);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsInoutManagement>(`${this.basePath}/warehouse/inout/management/system`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 상시공급매장 목록 조회
     * 되살림터별 상시공급매장 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceAlwaysSupplyShopGet(recycle_place_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsAlwaysSupplyShopData>>;
    public warehouseRecyclePlaceAlwaysSupplyShopGet(recycle_place_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsAlwaysSupplyShopData>>>;
    public warehouseRecyclePlaceAlwaysSupplyShopGet(recycle_place_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsAlwaysSupplyShopData>>>;
    public warehouseRecyclePlaceAlwaysSupplyShopGet(recycle_place_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceAlwaysSupplyShopGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsAlwaysSupplyShopData>>(`${this.basePath}/warehouse/recycle_place/always_supply_shop`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 센터순환코스 목록 조회
     * 되살림터별 센터순환코스 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceCenterCirculationCourseGet(recycle_place_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsCenterCirculationCourseData>>;
    public warehouseRecyclePlaceCenterCirculationCourseGet(recycle_place_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsCenterCirculationCourseData>>>;
    public warehouseRecyclePlaceCenterCirculationCourseGet(recycle_place_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsCenterCirculationCourseData>>>;
    public warehouseRecyclePlaceCenterCirculationCourseGet(recycle_place_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceCenterCirculationCourseGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsCenterCirculationCourseData>>(`${this.basePath}/warehouse/recycle_place/center_circulation_course`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 행사출고 통계표 조회
     * 되살림터별 행사출고 통계표 조회
     * @param start_month 조회시작월
     * @param end_month 조회종료월
     * @param dept_id 부서아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceEventShippingStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsEventShippingStatisticalData>>;
    public warehouseRecyclePlaceEventShippingStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEventShippingStatisticalData>>>;
    public warehouseRecyclePlaceEventShippingStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEventShippingStatisticalData>>>;
    public warehouseRecyclePlaceEventShippingStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (start_month === null || start_month === undefined) {
            throw new Error('Required parameter start_month was null or undefined when calling warehouseRecyclePlaceEventShippingStatisticalTableGet.');
        }
        if (end_month === null || end_month === undefined) {
            throw new Error('Required parameter end_month was null or undefined when calling warehouseRecyclePlaceEventShippingStatisticalTableGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start_month !== undefined && start_month !== null) {
            queryParameters = queryParameters.set('start_month', <any>start_month);
        }
        if (end_month !== undefined && end_month !== null) {
            queryParameters = queryParameters.set('end_month', <any>end_month);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEventShippingStatisticalData>>(`${this.basePath}/warehouse/recycle_place/event_shipping/statistical_table`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 기증물품 이동 목록 조회
     * 되살림터별 기증물품 이동 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param inout_div 입출고구분
     * @param gte_date 검색 시작일
     * @param lte_date 검색 종료일
     * @param search_recycle_place_id 검색 되살림터 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceGivingMoveGet(recycle_place_id: string, inout_div: '전체' | '입고' | '출고', gte_date: string, lte_date: string, search_recycle_place_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingMoveData>>;
    public warehouseRecyclePlaceGivingMoveGet(recycle_place_id: string, inout_div: '전체' | '입고' | '출고', gte_date: string, lte_date: string, search_recycle_place_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingMoveData>>>;
    public warehouseRecyclePlaceGivingMoveGet(recycle_place_id: string, inout_div: '전체' | '입고' | '출고', gte_date: string, lte_date: string, search_recycle_place_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingMoveData>>>;
    public warehouseRecyclePlaceGivingMoveGet(recycle_place_id: string, inout_div: '전체' | '입고' | '출고', gte_date: string, lte_date: string, search_recycle_place_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceGivingMoveGet.');
        }
        if (inout_div === null || inout_div === undefined) {
            throw new Error('Required parameter inout_div was null or undefined when calling warehouseRecyclePlaceGivingMoveGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceGivingMoveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceGivingMoveGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }
        if (inout_div !== undefined && inout_div !== null) {
            queryParameters = queryParameters.set('inout_div', <any>inout_div);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (search_recycle_place_id !== undefined && search_recycle_place_id !== null) {
            queryParameters = queryParameters.set('search_recycle_place_id', <any>search_recycle_place_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingMoveData>>(`${this.basePath}/warehouse/recycle_place/giving_move`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터 창고별 월보관 정보 검색
     * 되살림터 창고별 월보관 정보를 검색한다.
     * @param recycle_place_id 되살림터 ID
     * @param storage_id 창고 ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceMonthStorageGet(recycle_place_id: string, storage_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsMonthStorage>>;
    public warehouseRecyclePlaceMonthStorageGet(recycle_place_id: string, storage_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsMonthStorage>>>;
    public warehouseRecyclePlaceMonthStorageGet(recycle_place_id: string, storage_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsMonthStorage>>>;
    public warehouseRecyclePlaceMonthStorageGet(recycle_place_id: string, storage_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceMonthStorageGet.');
        }
        if (storage_id === null || storage_id === undefined) {
            throw new Error('Required parameter storage_id was null or undefined when calling warehouseRecyclePlaceMonthStorageGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }
        if (storage_id !== undefined && storage_id !== null) {
            queryParameters = queryParameters.set('storage_id', <any>storage_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsMonthStorage>>(`${this.basePath}/warehouse/recycle_place/month_storage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 생산 목록 검색 및 세부분류별 합계
     * 되살림터별 생산 목록 검색 및 세부분류별 합계.
     * @param gte_prod_date 생산일기준 쿼리
     * @param lte_prod_date 생산일기준 쿼리
     * @param recycle_place 되살림터 아이디
     * @param recycle_place_name 되살림터 이름
     * @param production_div 생산구분
     * @param event_id 이벤트 아이디
     * @param event_name 이벤트 이름
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceProductionGet(gte_prod_date?: string, lte_prod_date?: string, recycle_place?: string, recycle_place_name?: string, production_div?: string, event_id?: string, event_name?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<EsProductionDataSearchInfos>;
    public warehouseRecyclePlaceProductionGet(gte_prod_date?: string, lte_prod_date?: string, recycle_place?: string, recycle_place_name?: string, production_div?: string, event_id?: string, event_name?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsProductionDataSearchInfos>>;
    public warehouseRecyclePlaceProductionGet(gte_prod_date?: string, lte_prod_date?: string, recycle_place?: string, recycle_place_name?: string, production_div?: string, event_id?: string, event_name?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsProductionDataSearchInfos>>;
    public warehouseRecyclePlaceProductionGet(gte_prod_date?: string, lte_prod_date?: string, recycle_place?: string, recycle_place_name?: string, production_div?: string, event_id?: string, event_name?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_prod_date !== undefined && gte_prod_date !== null) {
            queryParameters = queryParameters.set('gte_prod_date', <any>gte_prod_date);
        }
        if (lte_prod_date !== undefined && lte_prod_date !== null) {
            queryParameters = queryParameters.set('lte_prod_date', <any>lte_prod_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (recycle_place_name !== undefined && recycle_place_name !== null) {
            queryParameters = queryParameters.set('recycle_place_name', <any>recycle_place_name);
        }
        if (production_div !== undefined && production_div !== null) {
            queryParameters = queryParameters.set('production_div', <any>production_div);
        }
        if (event_id !== undefined && event_id !== null) {
            queryParameters = queryParameters.set('event_id', <any>event_id);
        }
        if (event_name !== undefined && event_name !== null) {
            queryParameters = queryParameters.set('event_name', <any>event_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsProductionDataSearchInfos>(`${this.basePath}/warehouse/recycle_place/production`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 생산 기간별 검색
     * 되살림터별 생산 기간별 검색한다.
     * @param gte_date 생산일 시작
     * @param lte_date 생산일 끝
     * @param recycle_place 되살림터
     * @param give_good_cate 물품분류
     * @param give_good_detail_cate 물품상세분류
     * @param event_name 행사명
     * @param production_work_group_id 생산 작업 그룹 아이디
     * @param event_id 행사 ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceProductionRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, give_good_cate?: string, give_good_detail_cate?: string, event_name?: string, production_work_group_id?: string, event_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsProductionData>>;
    public warehouseRecyclePlaceProductionRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, give_good_cate?: string, give_good_detail_cate?: string, event_name?: string, production_work_group_id?: string, event_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsProductionData>>>;
    public warehouseRecyclePlaceProductionRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, give_good_cate?: string, give_good_detail_cate?: string, event_name?: string, production_work_group_id?: string, event_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsProductionData>>>;
    public warehouseRecyclePlaceProductionRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, give_good_cate?: string, give_good_detail_cate?: string, event_name?: string, production_work_group_id?: string, event_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceProductionRetrieveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceProductionRetrieveGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceProductionRetrieveGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (give_good_cate !== undefined && give_good_cate !== null) {
            queryParameters = queryParameters.set('give_good_cate', <any>give_good_cate);
        }
        if (give_good_detail_cate !== undefined && give_good_detail_cate !== null) {
            queryParameters = queryParameters.set('give_good_detail_cate', <any>give_good_detail_cate);
        }
        if (event_name !== undefined && event_name !== null) {
            queryParameters = queryParameters.set('event_name', <any>event_name);
        }
        if (production_work_group_id !== undefined && production_work_group_id !== null) {
            queryParameters = queryParameters.set('production_work_group_id', <any>production_work_group_id);
        }
        if (event_id !== undefined && event_id !== null) {
            queryParameters = queryParameters.set('event_id', <any>event_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsProductionData>>(`${this.basePath}/warehouse/recycle_place/production/retrieve`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 생산 통계표 엑셀출력
     * 되살림터별 생산 통계표 엑셀출력
     * @param start_month 조회시작월
     * @param end_month 조회종료월
     * @param dept_id 부서아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceProductionStatisticalTableExcelGet(start_month: string, end_month: string, dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public warehouseRecyclePlaceProductionStatisticalTableExcelGet(start_month: string, end_month: string, dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public warehouseRecyclePlaceProductionStatisticalTableExcelGet(start_month: string, end_month: string, dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public warehouseRecyclePlaceProductionStatisticalTableExcelGet(start_month: string, end_month: string, dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (start_month === null || start_month === undefined) {
            throw new Error('Required parameter start_month was null or undefined when calling warehouseRecyclePlaceProductionStatisticalTableExcelGet.');
        }
        if (end_month === null || end_month === undefined) {
            throw new Error('Required parameter end_month was null or undefined when calling warehouseRecyclePlaceProductionStatisticalTableExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start_month !== undefined && start_month !== null) {
            queryParameters = queryParameters.set('start_month', <any>start_month);
        }
        if (end_month !== undefined && end_month !== null) {
            queryParameters = queryParameters.set('end_month', <any>end_month);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/warehouse/recycle_place/production/statistical_table/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 생산 통계표 조회
     * 되살림터별 생산 통계표 조회
     * @param start_month 조회시작월
     * @param end_month 조회종료월
     * @param dept_id 부서아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceProductionStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsProductionStatisticalData>>;
    public warehouseRecyclePlaceProductionStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsProductionStatisticalData>>>;
    public warehouseRecyclePlaceProductionStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsProductionStatisticalData>>>;
    public warehouseRecyclePlaceProductionStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (start_month === null || start_month === undefined) {
            throw new Error('Required parameter start_month was null or undefined when calling warehouseRecyclePlaceProductionStatisticalTableGet.');
        }
        if (end_month === null || end_month === undefined) {
            throw new Error('Required parameter end_month was null or undefined when calling warehouseRecyclePlaceProductionStatisticalTableGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start_month !== undefined && start_month !== null) {
            queryParameters = queryParameters.set('start_month', <any>start_month);
        }
        if (end_month !== undefined && end_month !== null) {
            queryParameters = queryParameters.set('end_month', <any>end_month);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsProductionStatisticalData>>(`${this.basePath}/warehouse/recycle_place/production/statistical_table`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 생산 그룹 목록 조회
     * 되살림터별 생산 그룹 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param del_show 삭제 여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceProductionWorkGroupGet(recycle_place_id: string, del_show?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsProductionWorkGroupData>>;
    public warehouseRecyclePlaceProductionWorkGroupGet(recycle_place_id: string, del_show?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsProductionWorkGroupData>>>;
    public warehouseRecyclePlaceProductionWorkGroupGet(recycle_place_id: string, del_show?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsProductionWorkGroupData>>>;
    public warehouseRecyclePlaceProductionWorkGroupGet(recycle_place_id: string, del_show?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceProductionWorkGroupGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }
        if (del_show !== undefined && del_show !== null) {
            queryParameters = queryParameters.set('del_show', <any>del_show);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsProductionWorkGroupData>>(`${this.basePath}/warehouse/recycle_place/production_work_group`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 입고 목록 검색
     * 되살림터별 입고 목록 검색한다.
     * @param receiving_date 입고일
     * @param recycle_place 되살림터
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceReceivingGet(receiving_date: string, recycle_place: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsReceivingData>>;
    public warehouseRecyclePlaceReceivingGet(receiving_date: string, recycle_place: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsReceivingData>>>;
    public warehouseRecyclePlaceReceivingGet(receiving_date: string, recycle_place: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsReceivingData>>>;
    public warehouseRecyclePlaceReceivingGet(receiving_date: string, recycle_place: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (receiving_date === null || receiving_date === undefined) {
            throw new Error('Required parameter receiving_date was null or undefined when calling warehouseRecyclePlaceReceivingGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceReceivingGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (receiving_date !== undefined && receiving_date !== null) {
            queryParameters = queryParameters.set('receiving_date', <any>receiving_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsReceivingData>>(`${this.basePath}/warehouse/recycle_place/receiving`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 되살림터에서 입고되는 기증물품 목록 조회
     * 매장별 되살림터에서 입고되는 기증물품 목록 조회한다.
     * @param shop_id 매장아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceReceivingGivingGoodConfirmGet(shop_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsReceivingConfirm>>;
    public warehouseRecyclePlaceReceivingGivingGoodConfirmGet(shop_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsReceivingConfirm>>>;
    public warehouseRecyclePlaceReceivingGivingGoodConfirmGet(shop_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsReceivingConfirm>>>;
    public warehouseRecyclePlaceReceivingGivingGoodConfirmGet(shop_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseRecyclePlaceReceivingGivingGoodConfirmGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsReceivingConfirm>>(`${this.basePath}/warehouse/recycle_place_receiving/giving_good/confirm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 입고 기간별 검색
     * 되살림터별 입고 기간별 검색한다.
     * @param gte_date 입고일 시작
     * @param lte_date 입고일 끝
     * @param recycle_place 되살림터
     * @param shop_id 매장아이디
     * @param receiving_div 입고구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceReceivingRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, shop_id?: string, receiving_div?: '매장입고' | '직접입고' | '행사입고' | '대량입고' | '택배입고' | '수거입고' | '반품입고', observe?: 'body', reportProgress?: boolean): Observable<Array<EsReceivingData>>;
    public warehouseRecyclePlaceReceivingRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, shop_id?: string, receiving_div?: '매장입고' | '직접입고' | '행사입고' | '대량입고' | '택배입고' | '수거입고' | '반품입고', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsReceivingData>>>;
    public warehouseRecyclePlaceReceivingRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, shop_id?: string, receiving_div?: '매장입고' | '직접입고' | '행사입고' | '대량입고' | '택배입고' | '수거입고' | '반품입고', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsReceivingData>>>;
    public warehouseRecyclePlaceReceivingRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, shop_id?: string, receiving_div?: '매장입고' | '직접입고' | '행사입고' | '대량입고' | '택배입고' | '수거입고' | '반품입고', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceReceivingRetrieveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceReceivingRetrieveGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceReceivingRetrieveGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (receiving_div !== undefined && receiving_div !== null) {
            queryParameters = queryParameters.set('receiving_div', <any>receiving_div);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsReceivingData>>(`${this.basePath}/warehouse/recycle_place/receiving/retrieve`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 현물나눔 출고 목록 조회
     * 현물나눔 출고 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param gte_date 검색 시작일
     * @param lte_date 검색 종료일
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceShareProductGet(recycle_place_id: string, gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShareProductData>>;
    public warehouseRecyclePlaceShareProductGet(recycle_place_id: string, gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShareProductData>>>;
    public warehouseRecyclePlaceShareProductGet(recycle_place_id: string, gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShareProductData>>>;
    public warehouseRecyclePlaceShareProductGet(recycle_place_id: string, gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceShareProductGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceShareProductGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceShareProductGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShareProductData>>(`${this.basePath}/warehouse/recycle_place/share_product`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터 출고 정보 중 특정 현물나눔 출고 정보 조회
     * 되살림터 출고 정보 중 특정 현물나눔 출고 정보 조회
     * @param apply_id 나눔 신청서 아이디
     * @param idx_id 나눔 출고 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceShareProductInfoGet(apply_id: string, idx_id: number, observe?: 'body', reportProgress?: boolean): Observable<EsShippingData>;
    public warehouseRecyclePlaceShareProductInfoGet(apply_id: string, idx_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsShippingData>>;
    public warehouseRecyclePlaceShareProductInfoGet(apply_id: string, idx_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsShippingData>>;
    public warehouseRecyclePlaceShareProductInfoGet(apply_id: string, idx_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apply_id === null || apply_id === undefined) {
            throw new Error('Required parameter apply_id was null or undefined when calling warehouseRecyclePlaceShareProductInfoGet.');
        }
        if (idx_id === null || idx_id === undefined) {
            throw new Error('Required parameter idx_id was null or undefined when calling warehouseRecyclePlaceShareProductInfoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apply_id !== undefined && apply_id !== null) {
            queryParameters = queryParameters.set('apply_id', <any>apply_id);
        }
        if (idx_id !== undefined && idx_id !== null) {
            queryParameters = queryParameters.set('idx_id', <any>idx_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsShippingData>(`${this.basePath}/warehouse/recycle_place/share_product_info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 출고 목록 검색
     * 되살림터별 출고 목록 검색한다.
     * @param shipping_date 출고일
     * @param recycle_place 되살림터
     * @param no_tag 무택출고(Y)외부단체출고(E)일반출고(그외)
     * @param all 모든출고조회여부
     * @param course_id 센터순환코스 ID
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceShippingGet(shipping_date: string, recycle_place: string, no_tag?: string, all?: string, course_id?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShippingData>>;
    public warehouseRecyclePlaceShippingGet(shipping_date: string, recycle_place: string, no_tag?: string, all?: string, course_id?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShippingData>>>;
    public warehouseRecyclePlaceShippingGet(shipping_date: string, recycle_place: string, no_tag?: string, all?: string, course_id?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShippingData>>>;
    public warehouseRecyclePlaceShippingGet(shipping_date: string, recycle_place: string, no_tag?: string, all?: string, course_id?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shipping_date === null || shipping_date === undefined) {
            throw new Error('Required parameter shipping_date was null or undefined when calling warehouseRecyclePlaceShippingGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceShippingGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipping_date !== undefined && shipping_date !== null) {
            queryParameters = queryParameters.set('shipping_date', <any>shipping_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (no_tag !== undefined && no_tag !== null) {
            queryParameters = queryParameters.set('no_tag', <any>no_tag);
        }
        if (all !== undefined && all !== null) {
            queryParameters = queryParameters.set('all', <any>all);
        }
        if (course_id !== undefined && course_id !== null) {
            queryParameters = queryParameters.set('course_id', <any>course_id);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShippingData>>(`${this.basePath}/warehouse/recycle_place/shipping`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 출고 기간별 검색
     * 되살림터별 출고 기간별 검색한다.
     * @param gte_date 출고일 시작
     * @param lte_date 출고일 끝
     * @param recycle_place 되살림터
     * @param give_good_cate 물품분류
     * @param shipping_shop 출고매장
     * @param shipping_div 출고구분
     * @param give_good_detail_cate 물품상세분류
     * @param event_foundation_name 외부단체명
     * @param event_foundation_company_name 기증기업명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceShippingRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, give_good_cate?: string, shipping_shop?: string, shipping_div?: '일반출고' | '행사출고' | '무택출고' | '순환관리' | '현물나눔', give_good_detail_cate?: string, event_foundation_name?: string, event_foundation_company_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShippingData>>;
    public warehouseRecyclePlaceShippingRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, give_good_cate?: string, shipping_shop?: string, shipping_div?: '일반출고' | '행사출고' | '무택출고' | '순환관리' | '현물나눔', give_good_detail_cate?: string, event_foundation_name?: string, event_foundation_company_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShippingData>>>;
    public warehouseRecyclePlaceShippingRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, give_good_cate?: string, shipping_shop?: string, shipping_div?: '일반출고' | '행사출고' | '무택출고' | '순환관리' | '현물나눔', give_good_detail_cate?: string, event_foundation_name?: string, event_foundation_company_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShippingData>>>;
    public warehouseRecyclePlaceShippingRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, give_good_cate?: string, shipping_shop?: string, shipping_div?: '일반출고' | '행사출고' | '무택출고' | '순환관리' | '현물나눔', give_good_detail_cate?: string, event_foundation_name?: string, event_foundation_company_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceShippingRetrieveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceShippingRetrieveGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceShippingRetrieveGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (give_good_cate !== undefined && give_good_cate !== null) {
            queryParameters = queryParameters.set('give_good_cate', <any>give_good_cate);
        }
        if (shipping_shop !== undefined && shipping_shop !== null) {
            queryParameters = queryParameters.set('shipping_shop', <any>shipping_shop);
        }
        if (shipping_div !== undefined && shipping_div !== null) {
            queryParameters = queryParameters.set('shipping_div', <any>shipping_div);
        }
        if (give_good_detail_cate !== undefined && give_good_detail_cate !== null) {
            queryParameters = queryParameters.set('give_good_detail_cate', <any>give_good_detail_cate);
        }
        if (event_foundation_name !== undefined && event_foundation_name !== null) {
            queryParameters = queryParameters.set('event_foundation_name', <any>event_foundation_name);
        }
        if (event_foundation_company_name !== undefined && event_foundation_company_name !== null) {
            queryParameters = queryParameters.set('event_foundation_company_name', <any>event_foundation_company_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShippingData>>(`${this.basePath}/warehouse/recycle_place/shipping/retrieve`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 출고 통계표 조회
     * 되살림터별 출고 통계표 조회
     * @param start_month 조회시작월
     * @param end_month 조회종료월
     * @param dept_id 부서아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceShippingStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShippingStatisticalData>>;
    public warehouseRecyclePlaceShippingStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShippingStatisticalData>>>;
    public warehouseRecyclePlaceShippingStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShippingStatisticalData>>>;
    public warehouseRecyclePlaceShippingStatisticalTableGet(start_month: string, end_month: string, dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (start_month === null || start_month === undefined) {
            throw new Error('Required parameter start_month was null or undefined when calling warehouseRecyclePlaceShippingStatisticalTableGet.');
        }
        if (end_month === null || end_month === undefined) {
            throw new Error('Required parameter end_month was null or undefined when calling warehouseRecyclePlaceShippingStatisticalTableGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start_month !== undefined && start_month !== null) {
            queryParameters = queryParameters.set('start_month', <any>start_month);
        }
        if (end_month !== undefined && end_month !== null) {
            queryParameters = queryParameters.set('end_month', <any>end_month);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShippingStatisticalData>>(`${this.basePath}/warehouse/recycle_place/shipping/statistical_table`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 순환관리 목록 검색
     * 되살림터별 순환관리 목록 검색한다.
     * @param gte_date 매장출고일 시작
     * @param lte_date 매장출고일 끝
     * @param recycle_place_id 되살림터 아이디
     * @param shop_name 매장명
     * @param shop_id 매장아이디
     * @param confirm_flg 확인여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceShopCirculationGet(gte_date: string, lte_date: string, recycle_place_id?: string, shop_name?: string, shop_id?: string, confirm_flg?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInout>>;
    public warehouseRecyclePlaceShopCirculationGet(gte_date: string, lte_date: string, recycle_place_id?: string, shop_name?: string, shop_id?: string, confirm_flg?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInout>>>;
    public warehouseRecyclePlaceShopCirculationGet(gte_date: string, lte_date: string, recycle_place_id?: string, shop_name?: string, shop_id?: string, confirm_flg?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInout>>>;
    public warehouseRecyclePlaceShopCirculationGet(gte_date: string, lte_date: string, recycle_place_id?: string, shop_name?: string, shop_id?: string, confirm_flg?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceShopCirculationGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceShopCirculationGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (confirm_flg !== undefined && confirm_flg !== null) {
            queryParameters = queryParameters.set('confirm_flg', <any>confirm_flg);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInout>>(`${this.basePath}/warehouse/recycle_place/shop_circulation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 매장 반품 목록 검색
     * 되살림터별 매장 반품 목록 검색한다.
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param recycle_place_id 되살림터 아이디
     * @param give_good_cate 물품분류
     * @param shop_name 매장명
     * @param confirm_flg 확인여부
     * @param shop_id 매장아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceShopReturnGet(gte_date: string, lte_date: string, recycle_place_id?: string, give_good_cate?: '의류' | '잡화' | '도서음반' | '가전', shop_name?: string, confirm_flg?: 'Y' | 'N', shop_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInout>>;
    public warehouseRecyclePlaceShopReturnGet(gte_date: string, lte_date: string, recycle_place_id?: string, give_good_cate?: '의류' | '잡화' | '도서음반' | '가전', shop_name?: string, confirm_flg?: 'Y' | 'N', shop_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInout>>>;
    public warehouseRecyclePlaceShopReturnGet(gte_date: string, lte_date: string, recycle_place_id?: string, give_good_cate?: '의류' | '잡화' | '도서음반' | '가전', shop_name?: string, confirm_flg?: 'Y' | 'N', shop_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInout>>>;
    public warehouseRecyclePlaceShopReturnGet(gte_date: string, lte_date: string, recycle_place_id?: string, give_good_cate?: '의류' | '잡화' | '도서음반' | '가전', shop_name?: string, confirm_flg?: 'Y' | 'N', shop_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceShopReturnGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceShopReturnGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }
        if (give_good_cate !== undefined && give_good_cate !== null) {
            queryParameters = queryParameters.set('give_good_cate', <any>give_good_cate);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (confirm_flg !== undefined && confirm_flg !== null) {
            queryParameters = queryParameters.set('confirm_flg', <any>confirm_flg);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInout>>(`${this.basePath}/warehouse/recycle_place/shop_return`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 창고 보관,반출 목록 검색
     * 되살림터별 창고 보관,반출 목록 검색한다.
     * @param storage_date 등록일
     * @param recycle_place 되살림터 아이디
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceStorageGet(storage_date: string, recycle_place: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsStorageData>>;
    public warehouseRecyclePlaceStorageGet(storage_date: string, recycle_place: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsStorageData>>>;
    public warehouseRecyclePlaceStorageGet(storage_date: string, recycle_place: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsStorageData>>>;
    public warehouseRecyclePlaceStorageGet(storage_date: string, recycle_place: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (storage_date === null || storage_date === undefined) {
            throw new Error('Required parameter storage_date was null or undefined when calling warehouseRecyclePlaceStorageGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceStorageGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (storage_date !== undefined && storage_date !== null) {
            queryParameters = queryParameters.set('storage_date', <any>storage_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsStorageData>>(`${this.basePath}/warehouse/recycle_place/storage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 창고 목록 조회
     * 되살림터별 창고 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceStorageListGet(recycle_place_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsStorageData>>;
    public warehouseRecyclePlaceStorageListGet(recycle_place_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsStorageData>>>;
    public warehouseRecyclePlaceStorageListGet(recycle_place_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsStorageData>>>;
    public warehouseRecyclePlaceStorageListGet(recycle_place_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsStorageData>>(`${this.basePath}/warehouse/recycle_place/storage_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 창고위치 목록 조회
     * 되살림터별 창고위치 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param storage_id 창고 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceStorageLocationListGet(recycle_place_id: string, storage_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsStorageLocationData>>;
    public warehouseRecyclePlaceStorageLocationListGet(recycle_place_id: string, storage_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsStorageLocationData>>>;
    public warehouseRecyclePlaceStorageLocationListGet(recycle_place_id: string, storage_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsStorageLocationData>>>;
    public warehouseRecyclePlaceStorageLocationListGet(recycle_place_id: string, storage_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageLocationListGet.');
        }
        if (storage_id === null || storage_id === undefined) {
            throw new Error('Required parameter storage_id was null or undefined when calling warehouseRecyclePlaceStorageLocationListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }
        if (storage_id !== undefined && storage_id !== null) {
            queryParameters = queryParameters.set('storage_id', <any>storage_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsStorageLocationData>>(`${this.basePath}/warehouse/recycle_place/storage_location_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 창고명 목록 조회
     * 되살림터별 창고명 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceStorageNameListGet(recycle_place_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsStorageNameData>>;
    public warehouseRecyclePlaceStorageNameListGet(recycle_place_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsStorageNameData>>>;
    public warehouseRecyclePlaceStorageNameListGet(recycle_place_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsStorageNameData>>>;
    public warehouseRecyclePlaceStorageNameListGet(recycle_place_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageNameListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsStorageNameData>>(`${this.basePath}/warehouse/recycle_place/storage_name_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 창고 보관,반출 기간별 검색
     * 되살림터별 창고 보관,반출 기간별 검색한다.
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param recycle_place 되살림터
     * @param storage_id 창고아이디
     * @param storage_div 창고구분
     * @param storage_good_cate 물품분류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceStorageRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, storage_id?: string, storage_div?: '' | '보관' | '반출', storage_good_cate?: '' | '춘추의류' | '여름의류' | '여름잡화' | '여름신발' | '여름가전' | '겨울의류' | '겨울잡화' | '겨울신발' | '겨울가전' | '크리스마스' | '도서' | '비품', observe?: 'body', reportProgress?: boolean): Observable<Array<EsStorageData>>;
    public warehouseRecyclePlaceStorageRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, storage_id?: string, storage_div?: '' | '보관' | '반출', storage_good_cate?: '' | '춘추의류' | '여름의류' | '여름잡화' | '여름신발' | '여름가전' | '겨울의류' | '겨울잡화' | '겨울신발' | '겨울가전' | '크리스마스' | '도서' | '비품', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsStorageData>>>;
    public warehouseRecyclePlaceStorageRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, storage_id?: string, storage_div?: '' | '보관' | '반출', storage_good_cate?: '' | '춘추의류' | '여름의류' | '여름잡화' | '여름신발' | '여름가전' | '겨울의류' | '겨울잡화' | '겨울신발' | '겨울가전' | '크리스마스' | '도서' | '비품', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsStorageData>>>;
    public warehouseRecyclePlaceStorageRetrieveGet(gte_date: string, lte_date: string, recycle_place: string, storage_id?: string, storage_div?: '' | '보관' | '반출', storage_good_cate?: '' | '춘추의류' | '여름의류' | '여름잡화' | '여름신발' | '여름가전' | '겨울의류' | '겨울잡화' | '겨울신발' | '겨울가전' | '크리스마스' | '도서' | '비품', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceStorageRetrieveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceStorageRetrieveGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceStorageRetrieveGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', <any>recycle_place);
        }
        if (storage_id !== undefined && storage_id !== null) {
            queryParameters = queryParameters.set('storage_id', <any>storage_id);
        }
        if (storage_div !== undefined && storage_div !== null) {
            queryParameters = queryParameters.set('storage_div', <any>storage_div);
        }
        if (storage_good_cate !== undefined && storage_good_cate !== null) {
            queryParameters = queryParameters.set('storage_good_cate', <any>storage_good_cate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsStorageData>>(`${this.basePath}/warehouse/recycle_place/storage/retrieve`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 창고 물품 현황 조회
     * 되살림터별 창고 물품 현황 조회
     * @param recycle_place_id 되살림터 아이디
     * @param storage_location_id 창고 위치 아이디
     * @param storage_good_cate 보관 품목
     * @param storage_box_kind 박스 종류
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceStorageStatusGet(recycle_place_id: string, storage_location_id?: string, storage_good_cate?: string, storage_box_kind?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsStorageStatusData>>;
    public warehouseRecyclePlaceStorageStatusGet(recycle_place_id: string, storage_location_id?: string, storage_good_cate?: string, storage_box_kind?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsStorageStatusData>>>;
    public warehouseRecyclePlaceStorageStatusGet(recycle_place_id: string, storage_location_id?: string, storage_good_cate?: string, storage_box_kind?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsStorageStatusData>>>;
    public warehouseRecyclePlaceStorageStatusGet(recycle_place_id: string, storage_location_id?: string, storage_good_cate?: string, storage_box_kind?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }
        if (storage_location_id !== undefined && storage_location_id !== null) {
            queryParameters = queryParameters.set('storage_location_id', <any>storage_location_id);
        }
        if (storage_good_cate !== undefined && storage_good_cate !== null) {
            queryParameters = queryParameters.set('storage_good_cate', <any>storage_good_cate);
        }
        if (storage_box_kind !== undefined && storage_box_kind !== null) {
            queryParameters = queryParameters.set('storage_box_kind', <any>storage_box_kind);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsStorageStatusData>>(`${this.basePath}/warehouse/recycle_place/storage/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 창고 현황 목록 조회
     * 되살림터별 창고 현황 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceStorageStatusListGet(recycle_place_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsStorageStatusData>>;
    public warehouseRecyclePlaceStorageStatusListGet(recycle_place_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsStorageStatusData>>>;
    public warehouseRecyclePlaceStorageStatusListGet(recycle_place_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsStorageStatusData>>>;
    public warehouseRecyclePlaceStorageStatusListGet(recycle_place_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageStatusListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsStorageStatusData>>(`${this.basePath}/warehouse/recycle_place/storage_status_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 등록된 목표 년도 목록 조회
     * 되살림터별 등록된 목표 년도 목록 조회
     * @param recycle_place_id 되살림터 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceTargetYearListGet(recycle_place_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsTargetYear>>;
    public warehouseRecyclePlaceTargetYearListGet(recycle_place_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsTargetYear>>>;
    public warehouseRecyclePlaceTargetYearListGet(recycle_place_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsTargetYear>>>;
    public warehouseRecyclePlaceTargetYearListGet(recycle_place_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceTargetYearListGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', <any>recycle_place_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsTargetYear>>(`${this.basePath}/warehouse/recycle_place/target_year_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 되살림터별 종합 통계표 조회
     * 되살림터별 종합 통계표 조회
     * @param gte_date 조회월
     * @param lte_date 조회월
     * @param dept_id 부서아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseRecyclePlaceTotalStatisticalTableGet(gte_date: string, lte_date: string, dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsTotalStatisticalData>>;
    public warehouseRecyclePlaceTotalStatisticalTableGet(gte_date: string, lte_date: string, dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsTotalStatisticalData>>>;
    public warehouseRecyclePlaceTotalStatisticalTableGet(gte_date: string, lte_date: string, dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsTotalStatisticalData>>>;
    public warehouseRecyclePlaceTotalStatisticalTableGet(gte_date: string, lte_date: string, dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceTotalStatisticalTableGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceTotalStatisticalTableGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsTotalStatisticalData>>(`${this.basePath}/warehouse/recycle_place/total/statistical_table`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 각 메뉴별 날짜별 seq 의 최대 추출
     * 각 메뉴별 날짜별 seq 의 최대을 추출하여 신규 PK 생성에 활용
     * @param id 특정 메뉴의 DB Item 별 PK
     * @param type 어떤 메뉴인지 알게 해주는 데이터. ex) SHOPINOUT 등 (sk와 동일)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseSeqGet(id: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<EsSeq>;
    public warehouseSeqGet(id: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsSeq>>;
    public warehouseSeqGet(id: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsSeq>>;
    public warehouseSeqGet(id: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling warehouseSeqGet.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling warehouseSeqGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsSeq>(`${this.basePath}/warehouse/seq`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 매장순환을 통해 입고되는 기증물품 목록 조회
     * 매장별 매장순환을 통해 입고되는 기증물품 목록 조회한다.
     * @param shop_id 매장아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopCirculationGivingGoodConfirmGet(shop_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInoutConfirm>>;
    public warehouseShopCirculationGivingGoodConfirmGet(shop_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInoutConfirm>>>;
    public warehouseShopCirculationGivingGoodConfirmGet(shop_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInoutConfirm>>>;
    public warehouseShopCirculationGivingGoodConfirmGet(shop_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopCirculationGivingGoodConfirmGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInoutConfirm>>(`${this.basePath}/warehouse/shop_circulation/giving_good/confirm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 그린폐기 승인 목록 정보 검색
     * 매장별 그린폐기 승인 목록 정보를 검색한다.
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param shop_id 매장 ID
     * @param confirm_flg 승인여부
     * @param good_first_category_name 상품구분
     * @param shop_inout_remark 기록사항
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopDisposalConfirmGet(gte_date: string, lte_date: string, shop_id?: string, confirm_flg?: 'Y' | 'N', good_first_category_name?: '공익상품' | '메아리상품', shop_inout_remark?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInout>>;
    public warehouseShopDisposalConfirmGet(gte_date: string, lte_date: string, shop_id?: string, confirm_flg?: 'Y' | 'N', good_first_category_name?: '공익상품' | '메아리상품', shop_inout_remark?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInout>>>;
    public warehouseShopDisposalConfirmGet(gte_date: string, lte_date: string, shop_id?: string, confirm_flg?: 'Y' | 'N', good_first_category_name?: '공익상품' | '메아리상품', shop_inout_remark?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInout>>>;
    public warehouseShopDisposalConfirmGet(gte_date: string, lte_date: string, shop_id?: string, confirm_flg?: 'Y' | 'N', good_first_category_name?: '공익상품' | '메아리상품', shop_inout_remark?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseShopDisposalConfirmGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseShopDisposalConfirmGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (confirm_flg !== undefined && confirm_flg !== null) {
            queryParameters = queryParameters.set('confirm_flg', <any>confirm_flg);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', <any>good_first_category_name);
        }
        if (shop_inout_remark !== undefined && shop_inout_remark !== null) {
            queryParameters = queryParameters.set('shop_inout_remark', <any>shop_inout_remark);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInout>>(`${this.basePath}/warehouse/shop_disposal/confirm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 월별 집계표 엑셀출력
     * 매장별 월별 집계표 엑셀출력
     * @param summary_month 조회월
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutAggregateTableExcelGet(summary_month: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public warehouseShopInoutAggregateTableExcelGet(summary_month: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public warehouseShopInoutAggregateTableExcelGet(summary_month: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public warehouseShopInoutAggregateTableExcelGet(summary_month: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutAggregateTableExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', <any>summary_month);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/warehouse/shop_inout/aggregate_table/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 월별 집계표 조회
     * 매장별 월별 집계표 조회
     * @param summary_month 조회월
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutAggregateTableGet(summary_month: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInoutAggregate>>;
    public warehouseShopInoutAggregateTableGet(summary_month: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInoutAggregate>>>;
    public warehouseShopInoutAggregateTableGet(summary_month: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInoutAggregate>>>;
    public warehouseShopInoutAggregateTableGet(summary_month: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutAggregateTableGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', <any>summary_month);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInoutAggregate>>(`${this.basePath}/warehouse/shop_inout/aggregate_table`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자동 주문시 매장별 상품별 가용수량을 가져온다
     * 자동 주문시 매장별 상품별 가용수량을 검색한다.
     * @param shop_id 매장 ID
     * @param good_code 상품코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutAutoQtycheck(shop_id: string, good_code: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public warehouseShopInoutAutoQtycheck(shop_id: string, good_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public warehouseShopInoutAutoQtycheck(shop_id: string, good_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public warehouseShopInoutAutoQtycheck(shop_id: string, good_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutAutoQtycheck.');
        }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseShopInoutAutoQtycheck.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<number>(`${this.basePath}/warehouse/shop_inout/auto/qtycheck`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 수불 내역 엑셀 다운로드(등록일, 매장아이디, 상품분류, 입출고구분, 상품명 Like)
     * 매장 수불 내역을 조건에 의해 엑셀로 다운로드한다.
     * @param shop_id 매장아이디
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param shop_inout_good_category 상품분류
     * @param shop_inout_flg 입출고구분
     * @param good_name 상품명
     * @param shop_inout_issue_type 입출고등록구분
     * @param shop_inout_confirm_flg 수불 확정 여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutExcelGet(shop_id: string, gte_date: string, lte_date: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_inout_flg?: '입고' | '출고', good_name?: string, shop_inout_issue_type?: 'SYSTEM' | 'DIRECT', shop_inout_confirm_flg?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public warehouseShopInoutExcelGet(shop_id: string, gte_date: string, lte_date: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_inout_flg?: '입고' | '출고', good_name?: string, shop_inout_issue_type?: 'SYSTEM' | 'DIRECT', shop_inout_confirm_flg?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public warehouseShopInoutExcelGet(shop_id: string, gte_date: string, lte_date: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_inout_flg?: '입고' | '출고', good_name?: string, shop_inout_issue_type?: 'SYSTEM' | 'DIRECT', shop_inout_confirm_flg?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public warehouseShopInoutExcelGet(shop_id: string, gte_date: string, lte_date: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_inout_flg?: '입고' | '출고', good_name?: string, shop_inout_issue_type?: 'SYSTEM' | 'DIRECT', shop_inout_confirm_flg?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutExcelGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseShopInoutExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseShopInoutExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (shop_inout_good_category !== undefined && shop_inout_good_category !== null) {
            queryParameters = queryParameters.set('shop_inout_good_category', <any>shop_inout_good_category);
        }
        if (shop_inout_flg !== undefined && shop_inout_flg !== null) {
            queryParameters = queryParameters.set('shop_inout_flg', <any>shop_inout_flg);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (shop_inout_issue_type !== undefined && shop_inout_issue_type !== null) {
            queryParameters = queryParameters.set('shop_inout_issue_type', <any>shop_inout_issue_type);
        }
        if (shop_inout_confirm_flg !== undefined && shop_inout_confirm_flg !== null) {
            queryParameters = queryParameters.set('shop_inout_confirm_flg', <any>shop_inout_confirm_flg);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/warehouse/shop_inout/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 수불 내역 검색(등록일, 매장아이디, 상품분류, 입출고구분, 상품명 Like)
     * 매장 수불 내역을 조건에 의해 검색한다.
     * @param shop_id 매장아이디
     * @param gte_date 등록일 시작
     * @param lte_date 등록일 끝
     * @param shop_inout_good_category 상품분류
     * @param shop_inout_flg 입출고구분
     * @param good_name 상품명
     * @param shop_inout_issue_type 입출고등록구분
     * @param shop_inout_confirm_flg 수불 확정 여부
     * @param shop_inout_category 입출고구분 - 구분
     * @param shop_out_category 출고구분 - 구분
     * @param shop_inout_place_name 입출고처명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutGet(shop_id: string, gte_date: string, lte_date: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_inout_flg?: '입고' | '출고', good_name?: string, shop_inout_issue_type?: 'SYSTEM' | 'DIRECT', shop_inout_confirm_flg?: 'Y' | 'N', shop_inout_category?: '점생산' | '일반입고' | '행사입고' | '그린입고' | '그린직송입고' | '별정입고' | '그린반품' | '반품' | '별정반품' | '그린폐기' | '점폐기' | '현물나눔' | '프로모션' | '비품출고' | '센터경유순환' | '직접순환', shop_out_category?: '그린반품' | '반품' | '별정반품' | '그린폐기' | '점폐기' | '현물나눔' | '프로모션' | '비품출고' | '센터경유순환' | '직접순환', shop_inout_place_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInout>>;
    public warehouseShopInoutGet(shop_id: string, gte_date: string, lte_date: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_inout_flg?: '입고' | '출고', good_name?: string, shop_inout_issue_type?: 'SYSTEM' | 'DIRECT', shop_inout_confirm_flg?: 'Y' | 'N', shop_inout_category?: '점생산' | '일반입고' | '행사입고' | '그린입고' | '그린직송입고' | '별정입고' | '그린반품' | '반품' | '별정반품' | '그린폐기' | '점폐기' | '현물나눔' | '프로모션' | '비품출고' | '센터경유순환' | '직접순환', shop_out_category?: '그린반품' | '반품' | '별정반품' | '그린폐기' | '점폐기' | '현물나눔' | '프로모션' | '비품출고' | '센터경유순환' | '직접순환', shop_inout_place_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInout>>>;
    public warehouseShopInoutGet(shop_id: string, gte_date: string, lte_date: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_inout_flg?: '입고' | '출고', good_name?: string, shop_inout_issue_type?: 'SYSTEM' | 'DIRECT', shop_inout_confirm_flg?: 'Y' | 'N', shop_inout_category?: '점생산' | '일반입고' | '행사입고' | '그린입고' | '그린직송입고' | '별정입고' | '그린반품' | '반품' | '별정반품' | '그린폐기' | '점폐기' | '현물나눔' | '프로모션' | '비품출고' | '센터경유순환' | '직접순환', shop_out_category?: '그린반품' | '반품' | '별정반품' | '그린폐기' | '점폐기' | '현물나눔' | '프로모션' | '비품출고' | '센터경유순환' | '직접순환', shop_inout_place_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInout>>>;
    public warehouseShopInoutGet(shop_id: string, gte_date: string, lte_date: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', shop_inout_flg?: '입고' | '출고', good_name?: string, shop_inout_issue_type?: 'SYSTEM' | 'DIRECT', shop_inout_confirm_flg?: 'Y' | 'N', shop_inout_category?: '점생산' | '일반입고' | '행사입고' | '그린입고' | '그린직송입고' | '별정입고' | '그린반품' | '반품' | '별정반품' | '그린폐기' | '점폐기' | '현물나눔' | '프로모션' | '비품출고' | '센터경유순환' | '직접순환', shop_out_category?: '그린반품' | '반품' | '별정반품' | '그린폐기' | '점폐기' | '현물나눔' | '프로모션' | '비품출고' | '센터경유순환' | '직접순환', shop_inout_place_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseShopInoutGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseShopInoutGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (shop_inout_good_category !== undefined && shop_inout_good_category !== null) {
            queryParameters = queryParameters.set('shop_inout_good_category', <any>shop_inout_good_category);
        }
        if (shop_inout_flg !== undefined && shop_inout_flg !== null) {
            queryParameters = queryParameters.set('shop_inout_flg', <any>shop_inout_flg);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (shop_inout_issue_type !== undefined && shop_inout_issue_type !== null) {
            queryParameters = queryParameters.set('shop_inout_issue_type', <any>shop_inout_issue_type);
        }
        if (shop_inout_confirm_flg !== undefined && shop_inout_confirm_flg !== null) {
            queryParameters = queryParameters.set('shop_inout_confirm_flg', <any>shop_inout_confirm_flg);
        }
        if (shop_inout_category !== undefined && shop_inout_category !== null) {
            queryParameters = queryParameters.set('shop_inout_category', <any>shop_inout_category);
        }
        if (shop_out_category !== undefined && shop_out_category !== null) {
            queryParameters = queryParameters.set('shop_out_category', <any>shop_out_category);
        }
        if (shop_inout_place_name !== undefined && shop_inout_place_name !== null) {
            queryParameters = queryParameters.set('shop_inout_place_name', <any>shop_inout_place_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInout>>(`${this.basePath}/warehouse/shop_inout`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 기증물품 그외상품 재고현황 엑셀출력
     * 매장별 기증물품 그외상품 재고현황 엑셀출력
     * @param summary_month 조회월
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutGivingGoodExclusionInventoryStatusExcelGet(summary_month: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public warehouseShopInoutGivingGoodExclusionInventoryStatusExcelGet(summary_month: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public warehouseShopInoutGivingGoodExclusionInventoryStatusExcelGet(summary_month: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public warehouseShopInoutGivingGoodExclusionInventoryStatusExcelGet(summary_month: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutGivingGoodExclusionInventoryStatusExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', <any>summary_month);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/warehouse/shop_inout/giving_good_exclusion/inventory_status/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 기증물품 재고현황 엑셀출력
     * 매장별 기증물품 재고현황 엑셀출력
     * @param summary_month 조회월
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutGivingGoodInventoryStatusExcelGet(summary_month: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public warehouseShopInoutGivingGoodInventoryStatusExcelGet(summary_month: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public warehouseShopInoutGivingGoodInventoryStatusExcelGet(summary_month: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public warehouseShopInoutGivingGoodInventoryStatusExcelGet(summary_month: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutGivingGoodInventoryStatusExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', <any>summary_month);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/warehouse/shop_inout/giving_good/inventory_status/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 기증물품 재고현황 조회
     * 매장별 기증물품 재고현황 조회
     * @param summary_month 조회월
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutGivingGoodInventoryStatusGet(summary_month: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopGivingGoodStatus>>;
    public warehouseShopInoutGivingGoodInventoryStatusGet(summary_month: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopGivingGoodStatus>>>;
    public warehouseShopInoutGivingGoodInventoryStatusGet(summary_month: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopGivingGoodStatus>>>;
    public warehouseShopInoutGivingGoodInventoryStatusGet(summary_month: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutGivingGoodInventoryStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', <any>summary_month);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopGivingGoodStatus>>(`${this.basePath}/warehouse/shop_inout/giving_good/inventory_status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 수불 수정 요청 목록
     * 매장별 수불 수정 요청 내역을 조건에 의해 검색한다.
     * @param shop_name 매장명
     * @param inout_mng_yyyy_mm 관리 년월
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutOpenGet(shop_name?: string, inout_mng_yyyy_mm?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInoutOpen>>;
    public warehouseShopInoutOpenGet(shop_name?: string, inout_mng_yyyy_mm?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInoutOpen>>>;
    public warehouseShopInoutOpenGet(shop_name?: string, inout_mng_yyyy_mm?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInoutOpen>>>;
    public warehouseShopInoutOpenGet(shop_name?: string, inout_mng_yyyy_mm?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (inout_mng_yyyy_mm !== undefined && inout_mng_yyyy_mm !== null) {
            queryParameters = queryParameters.set('inout_mng_yyyy_mm', <any>inout_mng_yyyy_mm);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInoutOpen>>(`${this.basePath}/warehouse/shop_inout_open`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 상품별 가용수량을 가져온다
     * 매장별 상품별 가용수량을 검색한다.
     * @param shop_id 매장 ID
     * @param good_code 상품코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutQtycheck(shop_id: string, good_code: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public warehouseShopInoutQtycheck(shop_id: string, good_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public warehouseShopInoutQtycheck(shop_id: string, good_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public warehouseShopInoutQtycheck(shop_id: string, good_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutQtycheck.');
        }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseShopInoutQtycheck.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<number>(`${this.basePath}/warehouse/shop_inout/qtycheck`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 월별 정량지표 엑셀출력
     * 매장별 월별 정량지표 엑셀출력
     * @param summary_month 조회월
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutQuantitativeIndexExcelGet(summary_month: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public warehouseShopInoutQuantitativeIndexExcelGet(summary_month: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public warehouseShopInoutQuantitativeIndexExcelGet(summary_month: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public warehouseShopInoutQuantitativeIndexExcelGet(summary_month: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutQuantitativeIndexExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', <any>summary_month);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/warehouse/shop_inout/quantitative_index/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 출고시 상품명 Like 검색
     * 매장 상품 출고시 특정 창고에 현황을 상품명으로 LIKE 검색한다.
     * @param shop_id 매장아이디
     * @param good_name 상품명
     * @param shop_inout_good_category 상품분류
     * @param good_code 상품코드
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopInoutStatusGet(shop_id: string, good_name?: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', good_code?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInoutStatus>>;
    public warehouseShopInoutStatusGet(shop_id: string, good_name?: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', good_code?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInoutStatus>>>;
    public warehouseShopInoutStatusGet(shop_id: string, good_name?: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', good_code?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInoutStatus>>>;
    public warehouseShopInoutStatusGet(shop_id: string, good_name?: string, shop_inout_good_category?: '기증물품' | '별정상품' | '메아리상품' | '공익상품', good_code?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', <any>good_name);
        }
        if (shop_inout_good_category !== undefined && shop_inout_good_category !== null) {
            queryParameters = queryParameters.set('shop_inout_good_category', <any>shop_inout_good_category);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', <any>good_code);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInoutStatus>>(`${this.basePath}/warehouse/shop_inout/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 주문에 통해 입고되는 공익상품 목록 조회
     * 매장별 주문에 통해 입고되는 공익상품 목록 조회한다.
     * @param shop_id 매장아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopOrderGreenGoodConfirmGet(shop_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopOrder>>;
    public warehouseShopOrderGreenGoodConfirmGet(shop_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopOrder>>>;
    public warehouseShopOrderGreenGoodConfirmGet(shop_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopOrder>>>;
    public warehouseShopOrderGreenGoodConfirmGet(shop_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopOrderGreenGoodConfirmGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopOrder>>(`${this.basePath}/warehouse/shop_order/green_good/confirm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 주문 ID로 매장 수불 내역 검색
     * 주문 ID로 매장 수불 내역 검색한다.
     * @param order_id 주문아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopOrderIdInoutGet(order_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInout>>;
    public warehouseShopOrderIdInoutGet(order_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInout>>>;
    public warehouseShopOrderIdInoutGet(order_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInout>>>;
    public warehouseShopOrderIdInoutGet(order_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (order_id === null || order_id === undefined) {
            throw new Error('Required parameter order_id was null or undefined when calling warehouseShopOrderIdInoutGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInout>>(`${this.basePath}/warehouse/shop/${encodeURIComponent(String(order_id))}/inout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 수불 정보 중 특정 현물나눔 출고 정보 조회
     * 매장 수불 정보 중 특정 현물나눔 출고 정보 조회
     * @param apply_id 나눔 신청서 아이디
     * @param idx_id 나눔 출고 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseShopShareProductInfoGet(apply_id: string, idx_id: number, observe?: 'body', reportProgress?: boolean): Observable<EsShopInout>;
    public warehouseShopShareProductInfoGet(apply_id: string, idx_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsShopInout>>;
    public warehouseShopShareProductInfoGet(apply_id: string, idx_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsShopInout>>;
    public warehouseShopShareProductInfoGet(apply_id: string, idx_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apply_id === null || apply_id === undefined) {
            throw new Error('Required parameter apply_id was null or undefined when calling warehouseShopShareProductInfoGet.');
        }
        if (idx_id === null || idx_id === undefined) {
            throw new Error('Required parameter idx_id was null or undefined when calling warehouseShopShareProductInfoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apply_id !== undefined && apply_id !== null) {
            queryParameters = queryParameters.set('apply_id', <any>apply_id);
        }
        if (idx_id !== undefined && idx_id !== null) {
            queryParameters = queryParameters.set('idx_id', <any>idx_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsShopInout>(`${this.basePath}/warehouse/shop/share_product_info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
