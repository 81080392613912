import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from 'fecommon';
import { EsDeptService } from 'src/app/restapi/be-esquery';
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
var DeptAutoCompleteComponent = /** @class */ (function () {
    function DeptAutoCompleteComponent(authService, esDeptService) {
        this.authService = authService;
        this.esDeptService = esDeptService;
        this.selected = new EventEmitter();
        this.control = new FormControl();
    }
    DeptAutoCompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.label)
            this.label = '부서';
        if (this.initLoginDeptFlg)
            this.setLoginDeptInfo();
        if (!this.shopType)
            this.shopType = null;
        this.searchResults = this.control.valueChanges.pipe(debounceTime(500), tap(function (value) {
            if (!value) {
                _this.selected.emit(null);
            }
        }), filter(function (value) { return !!value && typeof value === 'string'; }), mergeMap(function (value) {
            return _this.esDeptService.deptInfoGet(value, _this.shopType);
        }), takeUntil(componentDestroyed(this)));
    };
    DeptAutoCompleteComponent.prototype.ngOnDestroy = function () { };
    DeptAutoCompleteComponent.prototype.setLoginDeptInfo = function () {
        var _this = this;
        this.authService.userSubject.subscribe(function (userProfile) {
            var deptInfo = {
                dept_id: userProfile.emp_dept_id,
                dept_name: userProfile.emp_dept_name
            };
            _this.control.setValue(deptInfo);
            _this.onDeptSelected(deptInfo);
        });
    };
    DeptAutoCompleteComponent.prototype.displayDeptName = function (dept) {
        return dept ? dept.dept_name : undefined;
    };
    DeptAutoCompleteComponent.prototype.onDeptSelected = function (value) {
        if (!value)
            return;
        this.selected.emit(value);
    };
    return DeptAutoCompleteComponent;
}());
export { DeptAutoCompleteComponent };
