import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS, MatDatepicker } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EsGivingsService, EsGivingCallStatistics } from 'src/app/restapi/be-esquery';
import { EsGivingStatisticsCategories } from 'src/app/restapi/be-esquery/model/esGivingStatisticsCategories';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat_Month, MONTH_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

@Component({
  selector: 'app-givings-call',
  templateUrl: './givings-call.component.html',
  styleUrls: ['./givings-call.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
  ]
})
export class GivingsCallComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  dataList: Array<EsGivingCallStatistics> = [];
  dataSource: MatTableDataSource<EsGivingCallStatistics> = new MatTableDataSource([]);
  displayedColumns = ['date', 'give_route', 'give_apply_route', 'state', 'give_count', '의류', '잡화', '도서음반', '가전'];

  constructor(
    private fb: FormBuilder,
    private esGivingsService: EsGivingsService
  ) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      date: moment().add(-1, 'month')
    });
  }

  get fc_date() { return this.form.get('date'); }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_date.setValue(normalizedMonth);
    this.search();

    datepicker.close();
  }

  convertCategories(category: string, categories: Array<EsGivingStatisticsCategories>) {
    if (categories.find(x => x.category == category)) {
      return categories.find(x => x.category == category).sum;
    }

    return "";
  }

  search() {
    this.givingsCallGet();
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      if (sort.active == '의류' || sort.active == '잡화' || sort.active == '도서음반' || sort.active == '가전') {
        sorted = this.dataList.sort((a: EsGivingCallStatistics, b: EsGivingCallStatistics) => {
          let result = 0;
          let a_cate_sum: number = Number(this.convertCategories(sort.active, a.categories));
          let b_cate_sum: number = Number(this.convertCategories(sort.active, b.categories));

          if (a_cate_sum < b_cate_sum) result = -1;
          else if (a_cate_sum > b_cate_sum) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      } else {
        sorted = this.dataList.sort((a: EsGivingCallStatistics, b: EsGivingCallStatistics) => {
          let result = 0;
          if (a[sort.active] == undefined) a[sort.active] = "";
          if (b[sort.active] == undefined) b[sort.active] = "";

          if (a[sort.active] < b[sort.active]) result = -1;
          else if (a[sort.active] > b[sort.active]) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsGivingCallStatistics>(this.dataList);
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '콜접수 구분별 통계');
    XLSX.writeFile(wb, '콜접수 구분별 통계.xlsx');
  }

  givingsCallGet() {
    this.dataList = [];

    this.esGivingsService.givingsCallGet(
      dateFormat_Month(this.fc_date.value),
      dateFormat_Month(this.fc_date.value)
    ).pipe(
      take(1)
    ).subscribe((res: Array<EsGivingCallStatistics>) => {
      this.dataList = res;
      this.initPage(this.sort);
    },
      (err) => {
        console.error(err);
        this.initPage(this.sort);
      }
    );
  }

}