/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsFailure } from '../model/esFailure';
import { EsGivingCallAllDurationStatistics } from '../model/esGivingCallAllDurationStatistics';
import { EsGivingCallDurationStatistics } from '../model/esGivingCallDurationStatistics';
import { EsGivingCallHistoryStatistics } from '../model/esGivingCallHistoryStatistics';
import { EsGivingCallStatistics } from '../model/esGivingCallStatistics';
import { EsGivingCallWatingStatistics } from '../model/esGivingCallWatingStatistics';
import { EsGivingPlaceStatistics } from '../model/esGivingPlaceStatistics';
import { EsGivingRegionStatistics } from '../model/esGivingRegionStatistics';
import { EsGivingsCase } from '../model/esGivingsCase';
import { EsGivingsReceptionDate } from '../model/esGivingsReceptionDate';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsGivingsService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 기증접수~영수증발급 월평균 소요시간
     * 
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsCallAllDurationGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingCallAllDurationStatistics>>;
    public givingsCallAllDurationGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingCallAllDurationStatistics>>>;
    public givingsCallAllDurationGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingCallAllDurationStatistics>>>;
    public givingsCallAllDurationGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallAllDurationGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallAllDurationGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingCallAllDurationStatistics>>(`${this.basePath}/givings/call/all_duration`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 콜접수 통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsCallAreaGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingCallStatistics>>;
    public givingsCallAreaGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingCallStatistics>>>;
    public givingsCallAreaGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingCallStatistics>>>;
    public givingsCallAreaGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallAreaGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallAreaGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingCallStatistics>>(`${this.basePath}/givings/call/area`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 접수대기일 통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsCallDurationGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingCallDurationStatistics>>;
    public givingsCallDurationGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingCallDurationStatistics>>>;
    public givingsCallDurationGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingCallDurationStatistics>>>;
    public givingsCallDurationGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallDurationGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallDurationGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingCallDurationStatistics>>(`${this.basePath}/givings/call/duration`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 콜접수 통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsCallGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingCallStatistics>>;
    public givingsCallGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingCallStatistics>>>;
    public givingsCallGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingCallStatistics>>>;
    public givingsCallGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingCallStatistics>>(`${this.basePath}/givings/call`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 상담문의 통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsCallHistoryGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingCallHistoryStatistics>>;
    public givingsCallHistoryGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingCallHistoryStatistics>>>;
    public givingsCallHistoryGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingCallHistoryStatistics>>>;
    public givingsCallHistoryGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallHistoryGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallHistoryGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingCallHistoryStatistics>>(`${this.basePath}/givings/call/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 방문소요일 통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsCallWatingGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingCallWatingStatistics>>;
    public givingsCallWatingGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingCallWatingStatistics>>>;
    public givingsCallWatingGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingCallWatingStatistics>>>;
    public givingsCallWatingGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsCallWatingGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsCallWatingGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingCallWatingStatistics>>(`${this.basePath}/givings/call/wating`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증건별 전체 엑셀 다운로드
     * 
     * @param gte_date 
     * @param lte_date 
     * @param dept_id 부서아이디
     * @param is_anonymous None 이면 전체 Y이면 포함,N제외
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsExcelGet(gte_date: string, lte_date: string, dept_id?: string, is_anonymous?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<any>;
    public givingsExcelGet(gte_date: string, lte_date: string, dept_id?: string, is_anonymous?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public givingsExcelGet(gte_date: string, lte_date: string, dept_id?: string, is_anonymous?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public givingsExcelGet(gte_date: string, lte_date: string, dept_id?: string, is_anonymous?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }
        if (is_anonymous !== undefined && is_anonymous !== null) {
            queryParameters = queryParameters.set('is_anonymous', <any>is_anonymous);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/givings/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증처별 통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param dept_id 부서아이디
     * @param give_route 수거경로 선택 -&gt; 수거,택배
     * @param is_anonymous None 이면 전체 Y이면 포함,N제외
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsPlaceGet(gte_date: string, lte_date: string, dept_id?: string, give_route?: '수거' | '택배' | '점기증', is_anonymous?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingPlaceStatistics>>;
    public givingsPlaceGet(gte_date: string, lte_date: string, dept_id?: string, give_route?: '수거' | '택배' | '점기증', is_anonymous?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingPlaceStatistics>>>;
    public givingsPlaceGet(gte_date: string, lte_date: string, dept_id?: string, give_route?: '수거' | '택배' | '점기증', is_anonymous?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingPlaceStatistics>>>;
    public givingsPlaceGet(gte_date: string, lte_date: string, dept_id?: string, give_route?: '수거' | '택배' | '점기증', is_anonymous?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsPlaceGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsPlaceGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }
        if (give_route !== undefined && give_route !== null) {
            queryParameters = queryParameters.set('give_route', <any>give_route);
        }
        if (is_anonymous !== undefined && is_anonymous !== null) {
            queryParameters = queryParameters.set('is_anonymous', <any>is_anonymous);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingPlaceStatistics>>(`${this.basePath}/givings/place`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증 접수건 날짜 기준 검색
     * 
     * @param gte_date 
     * @param lte_date 
     * @param date_div 
     * @param give_apply_route 
     * @param give_route 수거경로 선택 -&gt; 수거,택배
     * @param give_status 기증등록상태 -&gt; 접수,완료,취소
     * @param logistics_collect_confirm 수배송 확인
     * @param logistics_collect_owner_flg 담당자 지정여부
     * @param give_reception_hold_flg 기증접수 보류여부
     * @param give_reception_joiner_cellp_no 접수 전화번호
     * @param give_place_dept_id 기증처
     * @param give_reception_joiner_name 접수자 이름
     * @param logistics_collect_area_id 권역 아이디
     * @param give_addr 주소검색
     * @param give_tags 태그검색
     * @param except_reception 점기증 제외시 &#39;Y&#39;로 검색
     * @param give_reception_emp_name 접수등록 간사이름
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsReceptionDateGet(gte_date: string, lte_date: string, date_div: '접수일' | '방문예정일' | '기증일', give_apply_route?: 'PC웹' | '모바일웹' | '전화접수' | '인터넷접수', give_route?: '수거' | '택배', give_status?: '접수' | '완료' | '취소', logistics_collect_confirm?: 'Y' | 'N', logistics_collect_owner_flg?: 'Y' | 'N', give_reception_hold_flg?: 'Y' | 'N', give_reception_joiner_cellp_no?: string, give_place_dept_id?: string, give_reception_joiner_name?: string, logistics_collect_area_id?: string, give_addr?: string, give_tags?: string, except_reception?: string, give_reception_emp_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingsReceptionDate>>;
    public givingsReceptionDateGet(gte_date: string, lte_date: string, date_div: '접수일' | '방문예정일' | '기증일', give_apply_route?: 'PC웹' | '모바일웹' | '전화접수' | '인터넷접수', give_route?: '수거' | '택배', give_status?: '접수' | '완료' | '취소', logistics_collect_confirm?: 'Y' | 'N', logistics_collect_owner_flg?: 'Y' | 'N', give_reception_hold_flg?: 'Y' | 'N', give_reception_joiner_cellp_no?: string, give_place_dept_id?: string, give_reception_joiner_name?: string, logistics_collect_area_id?: string, give_addr?: string, give_tags?: string, except_reception?: string, give_reception_emp_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingsReceptionDate>>>;
    public givingsReceptionDateGet(gte_date: string, lte_date: string, date_div: '접수일' | '방문예정일' | '기증일', give_apply_route?: 'PC웹' | '모바일웹' | '전화접수' | '인터넷접수', give_route?: '수거' | '택배', give_status?: '접수' | '완료' | '취소', logistics_collect_confirm?: 'Y' | 'N', logistics_collect_owner_flg?: 'Y' | 'N', give_reception_hold_flg?: 'Y' | 'N', give_reception_joiner_cellp_no?: string, give_place_dept_id?: string, give_reception_joiner_name?: string, logistics_collect_area_id?: string, give_addr?: string, give_tags?: string, except_reception?: string, give_reception_emp_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingsReceptionDate>>>;
    public givingsReceptionDateGet(gte_date: string, lte_date: string, date_div: '접수일' | '방문예정일' | '기증일', give_apply_route?: 'PC웹' | '모바일웹' | '전화접수' | '인터넷접수', give_route?: '수거' | '택배', give_status?: '접수' | '완료' | '취소', logistics_collect_confirm?: 'Y' | 'N', logistics_collect_owner_flg?: 'Y' | 'N', give_reception_hold_flg?: 'Y' | 'N', give_reception_joiner_cellp_no?: string, give_place_dept_id?: string, give_reception_joiner_name?: string, logistics_collect_area_id?: string, give_addr?: string, give_tags?: string, except_reception?: string, give_reception_emp_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsReceptionDateGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsReceptionDateGet.');
        }
        if (date_div === null || date_div === undefined) {
            throw new Error('Required parameter date_div was null or undefined when calling givingsReceptionDateGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (date_div !== undefined && date_div !== null) {
            queryParameters = queryParameters.set('date_div', <any>date_div);
        }
        if (give_apply_route !== undefined && give_apply_route !== null) {
            queryParameters = queryParameters.set('give_apply_route', <any>give_apply_route);
        }
        if (give_route !== undefined && give_route !== null) {
            queryParameters = queryParameters.set('give_route', <any>give_route);
        }
        if (give_status !== undefined && give_status !== null) {
            queryParameters = queryParameters.set('give_status', <any>give_status);
        }
        if (logistics_collect_confirm !== undefined && logistics_collect_confirm !== null) {
            queryParameters = queryParameters.set('logistics_collect_confirm', <any>logistics_collect_confirm);
        }
        if (logistics_collect_owner_flg !== undefined && logistics_collect_owner_flg !== null) {
            queryParameters = queryParameters.set('logistics_collect_owner_flg', <any>logistics_collect_owner_flg);
        }
        if (give_reception_hold_flg !== undefined && give_reception_hold_flg !== null) {
            queryParameters = queryParameters.set('give_reception_hold_flg', <any>give_reception_hold_flg);
        }
        if (give_reception_joiner_cellp_no !== undefined && give_reception_joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_cellp_no', <any>give_reception_joiner_cellp_no);
        }
        if (give_place_dept_id !== undefined && give_place_dept_id !== null) {
            queryParameters = queryParameters.set('give_place_dept_id', <any>give_place_dept_id);
        }
        if (give_reception_joiner_name !== undefined && give_reception_joiner_name !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_name', <any>give_reception_joiner_name);
        }
        if (logistics_collect_area_id !== undefined && logistics_collect_area_id !== null) {
            queryParameters = queryParameters.set('logistics_collect_area_id', <any>logistics_collect_area_id);
        }
        if (give_addr !== undefined && give_addr !== null) {
            queryParameters = queryParameters.set('give_addr', <any>give_addr);
        }
        if (give_tags !== undefined && give_tags !== null) {
            queryParameters = queryParameters.set('give_tags', <any>give_tags);
        }
        if (except_reception !== undefined && except_reception !== null) {
            queryParameters = queryParameters.set('except_reception', <any>except_reception);
        }
        if (give_reception_emp_name !== undefined && give_reception_emp_name !== null) {
            queryParameters = queryParameters.set('give_reception_emp_name', <any>give_reception_emp_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingsReceptionDate>>(`${this.basePath}/givings/reception/date`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증 접수건 정보검색
     * 
     * @param give_reception_joiner_div 접수자 구분
     * @param give_reception_joiner_name 접수자 이름
     * @param give_reception_joiner_cellp_no 접수자 전화번호
     * @param give_reception_emp_name 접수등록 간사이름
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsReceptionGet(give_reception_joiner_div?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_emp_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingsReceptionDate>>;
    public givingsReceptionGet(give_reception_joiner_div?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_emp_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingsReceptionDate>>>;
    public givingsReceptionGet(give_reception_joiner_div?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_emp_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingsReceptionDate>>>;
    public givingsReceptionGet(give_reception_joiner_div?: string, give_reception_joiner_name?: string, give_reception_joiner_cellp_no?: string, give_reception_emp_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (give_reception_joiner_div !== undefined && give_reception_joiner_div !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_div', <any>give_reception_joiner_div);
        }
        if (give_reception_joiner_name !== undefined && give_reception_joiner_name !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_name', <any>give_reception_joiner_name);
        }
        if (give_reception_joiner_cellp_no !== undefined && give_reception_joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('give_reception_joiner_cellp_no', <any>give_reception_joiner_cellp_no);
        }
        if (give_reception_emp_name !== undefined && give_reception_emp_name !== null) {
            queryParameters = queryParameters.set('give_reception_emp_name', <any>give_reception_emp_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingsReceptionDate>>(`${this.basePath}/givings/reception`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 지역별 통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param region 지역이름
     * @param give_route 수거경로 선택 -&gt; 수거,택배
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsRegionGet(gte_date: string, lte_date: string, region?: string, give_route?: '수거' | '택배' | '점기증', observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingRegionStatistics>>;
    public givingsRegionGet(gte_date: string, lte_date: string, region?: string, give_route?: '수거' | '택배' | '점기증', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingRegionStatistics>>>;
    public givingsRegionGet(gte_date: string, lte_date: string, region?: string, give_route?: '수거' | '택배' | '점기증', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingRegionStatistics>>>;
    public givingsRegionGet(gte_date: string, lte_date: string, region?: string, give_route?: '수거' | '택배' | '점기증', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsRegionGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsRegionGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (region !== undefined && region !== null) {
            queryParameters = queryParameters.set('region', <any>region);
        }
        if (give_route !== undefined && give_route !== null) {
            queryParameters = queryParameters.set('give_route', <any>give_route);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingRegionStatistics>>(`${this.basePath}/givings/region`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기증건별조회
     * 
     * @param gte_date 검색시작일
     * @param lte_date 검색종료일
     * @param dept_name 기증처
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public givingsSearchBoardGet(gte_date: string, lte_date: string, dept_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingsCase>>;
    public givingsSearchBoardGet(gte_date: string, lte_date: string, dept_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingsCase>>>;
    public givingsSearchBoardGet(gte_date: string, lte_date: string, dept_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingsCase>>>;
    public givingsSearchBoardGet(gte_date: string, lte_date: string, dept_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling givingsSearchBoardGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling givingsSearchBoardGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_name !== undefined && dept_name !== null) {
            queryParameters = queryParameters.set('dept_name', <any>dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingsCase>>(`${this.basePath}/givings/search_board`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
