/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsPageGroupService = /** @class */ (function () {
    function EsPageGroupService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsPageGroupService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsPageGroupService.prototype.pageGroupGet = function (page_group_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (page_group_name !== undefined && page_group_name !== null) {
            queryParameters = queryParameters.set('page_group_name', page_group_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/page_group", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsPageGroupService;
}());
export { EsPageGroupService };
