/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Empty } from '../model/empty';
import { Failure } from '../model/failure';
import { MessageTemplate } from '../model/messageTemplate';
import { MessageTemplateResponse } from '../model/messageTemplateResponse';
import { SenderNumber } from '../model/senderNumber';
import { SentMessageResponse } from '../model/sentMessageResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MessageService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 문자발송내역삭제(아이디로 관리되는 구조가 아니여서tran_pr을 찾아서 삭제)
     * 
     * @param message_id 메시지 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public messagesMessageIdDelete(message_id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public messagesMessageIdDelete(message_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public messagesMessageIdDelete(message_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public messagesMessageIdDelete(message_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (message_id === null || message_id === undefined) {
            throw new Error('Required parameter message_id was null or undefined when calling messagesMessageIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/messages/${encodeURIComponent(String(message_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 메세지 발송내역 조회
     * 
     * @param month 조회할 월(format: yyyyMM)
     * @param name 조회할 수신자 이름
     * @param phone_number 조회할 수신자 전화번호
     * @param select_failures 발송 실패내역만 조회(y:선택/n:미선택(기본값))
     * @param count 한번에 조회할 개수
     * @param page 조회할 페이지 번호
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public messagesSentMonthGet(month: string, name?: string, phone_number?: string, select_failures?: string, count?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<SentMessageResponse>;
    public messagesSentMonthGet(month: string, name?: string, phone_number?: string, select_failures?: string, count?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SentMessageResponse>>;
    public messagesSentMonthGet(month: string, name?: string, phone_number?: string, select_failures?: string, count?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SentMessageResponse>>;
    public messagesSentMonthGet(month: string, name?: string, phone_number?: string, select_failures?: string, count?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (month === null || month === undefined) {
            throw new Error('Required parameter month was null or undefined when calling messagesSentMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (phone_number !== undefined && phone_number !== null) {
            queryParameters = queryParameters.set('phone_number', <any>phone_number);
        }
        if (select_failures !== undefined && select_failures !== null) {
            queryParameters = queryParameters.set('select_failures', <any>select_failures);
        }
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SentMessageResponse>(`${this.basePath}/messages/sent/${encodeURIComponent(String(month))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 문자 template 조회
     * 
     * @param emp_id 사용자 아이디
     * @param count 한번에 조회할 개수 (default:10)
     * @param page 조회할 페이지 (default:1)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public messagesTemplatesEmpIdGet(emp_id: string, count?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MessageTemplateResponse>>;
    public messagesTemplatesEmpIdGet(emp_id: string, count?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MessageTemplateResponse>>>;
    public messagesTemplatesEmpIdGet(emp_id: string, count?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MessageTemplateResponse>>>;
    public messagesTemplatesEmpIdGet(emp_id: string, count?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling messagesTemplatesEmpIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MessageTemplateResponse>>(`${this.basePath}/messages/templates/${encodeURIComponent(String(emp_id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 문자 template 추가
     * 
     * @param emp_id 사용자 아이디
     * @param template 추가할 template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public messagesTemplatesEmpIdPost(emp_id: string, template: MessageTemplate, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public messagesTemplatesEmpIdPost(emp_id: string, template: MessageTemplate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public messagesTemplatesEmpIdPost(emp_id: string, template: MessageTemplate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public messagesTemplatesEmpIdPost(emp_id: string, template: MessageTemplate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling messagesTemplatesEmpIdPost.');
        }
        if (template === null || template === undefined) {
            throw new Error('Required parameter template was null or undefined when calling messagesTemplatesEmpIdPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/messages/templates/${encodeURIComponent(String(emp_id))}`,
            template,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 문자 template 삭제
     * 
     * @param emp_id 사용자 아이디
     * @param template_id 삭제할 문자 template의 sk
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public messagesTemplatesEmpIdTemplateIdDelete(emp_id: string, template_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Empty>>;
    public messagesTemplatesEmpIdTemplateIdDelete(emp_id: string, template_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Empty>>>;
    public messagesTemplatesEmpIdTemplateIdDelete(emp_id: string, template_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Empty>>>;
    public messagesTemplatesEmpIdTemplateIdDelete(emp_id: string, template_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling messagesTemplatesEmpIdTemplateIdDelete.');
        }
        if (template_id === null || template_id === undefined) {
            throw new Error('Required parameter template_id was null or undefined when calling messagesTemplatesEmpIdTemplateIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Array<Empty>>(`${this.basePath}/messages/templates/${encodeURIComponent(String(emp_id))}/${encodeURIComponent(String(template_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 발신자 번호 삭제
     * 
     * @param emp_id 요청자 아이디
     * @param number 삭제할 발신자 번호
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendernumbersEmpIdDelete(emp_id: string, number: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Empty>>;
    public sendernumbersEmpIdDelete(emp_id: string, number: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Empty>>>;
    public sendernumbersEmpIdDelete(emp_id: string, number: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Empty>>>;
    public sendernumbersEmpIdDelete(emp_id: string, number: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling sendernumbersEmpIdDelete.');
        }
        if (number === null || number === undefined) {
            throw new Error('Required parameter number was null or undefined when calling sendernumbersEmpIdDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (number !== undefined && number !== null) {
            queryParameters = queryParameters.set('number', <any>number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Array<Empty>>(`${this.basePath}/sendernumbers/${encodeURIComponent(String(emp_id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 요청자의 발신자 번호 조회
     * 
     * @param emp_id 요청자 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendernumbersEmpIdGet(emp_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SenderNumber>>;
    public sendernumbersEmpIdGet(emp_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SenderNumber>>>;
    public sendernumbersEmpIdGet(emp_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SenderNumber>>>;
    public sendernumbersEmpIdGet(emp_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling sendernumbersEmpIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SenderNumber>>(`${this.basePath}/sendernumbers/${encodeURIComponent(String(emp_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 전체 발신자 번호 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendernumbersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SenderNumber>>;
    public sendernumbersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SenderNumber>>>;
    public sendernumbersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SenderNumber>>>;
    public sendernumbersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SenderNumber>>(`${this.basePath}/sendernumbers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 발신자 번호 추가
     * 
     * @param number 추가할 발신자 번호
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendernumbersPost(number: string, observe?: 'body', reportProgress?: boolean): Observable<SenderNumber>;
    public sendernumbersPost(number: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SenderNumber>>;
    public sendernumbersPost(number: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SenderNumber>>;
    public sendernumbersPost(number: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (number === null || number === undefined) {
            throw new Error('Required parameter number was null or undefined when calling sendernumbersPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (number !== undefined && number !== null) {
            queryParameters = queryParameters.set('number', <any>number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<SenderNumber>(`${this.basePath}/sendernumbers`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 발신자 번호 승인/미승인
     * 
     * @param number 승인/미승인할 발신자 번호
     * @param approve 승인 여부(y:승인/n:미승인)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendernumbersPut(number: string, approve: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Empty>>;
    public sendernumbersPut(number: string, approve: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Empty>>>;
    public sendernumbersPut(number: string, approve: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Empty>>>;
    public sendernumbersPut(number: string, approve: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (number === null || number === undefined) {
            throw new Error('Required parameter number was null or undefined when calling sendernumbersPut.');
        }
        if (approve === null || approve === undefined) {
            throw new Error('Required parameter approve was null or undefined when calling sendernumbersPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (number !== undefined && number !== null) {
            queryParameters = queryParameters.set('number', <any>number);
        }
        if (approve !== undefined && approve !== null) {
            queryParameters = queryParameters.set('approve', <any>approve);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<Array<Empty>>(`${this.basePath}/sendernumbers`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
