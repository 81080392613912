import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EsSalesService } from 'src/app/restapi/be-esquery';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
var ɵ0 = DEFAULT_FORMATS;
var SalesRateBySupplyComponent = /** @class */ (function () {
    function SalesRateBySupplyComponent(fb, esSalesService) {
        this.fb = fb;
        this.esSalesService = esSalesService;
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['shop_name', 'good_second_category_name', 'good_third_category_name', 'tot_sale_amt', 'no_tax_sale_amt',
            'tot_sale_qty', 'avg_price', 'rate', 'recycle_place_name', 'normal_cnt', 'event_cnt', 'none_cnt', 'tot_cnt'];
        this.paginationCollection = new Array();
        this.page = 1;
        this.pageSize = 10;
    }
    SalesRateBySupplyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    SalesRateBySupplyComponent.prototype.ngOnDestroy = function () { };
    SalesRateBySupplyComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            gte_date: [new Date()],
            lte_date: [new Date()],
            dept_name: ['']
        });
    };
    Object.defineProperty(SalesRateBySupplyComponent.prototype, "fc_gteDate", {
        get: function () { return this.form.get('gte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalesRateBySupplyComponent.prototype, "fc_lteDate", {
        get: function () { return this.form.get('lte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalesRateBySupplyComponent.prototype, "fc_deptName", {
        get: function () { return this.form.get('dept_name'); },
        enumerable: true,
        configurable: true
    });
    SalesRateBySupplyComponent.prototype.initPage = function (sort) {
        if (!this.paginationCollection)
            this.paginationCollection = [];
        var sorted = this.paginationCollection;
        if (sort) {
            sorted = this.paginationCollection.sort(function (a, b) {
                var result = 0;
                if (a[sort.active] == undefined)
                    a[sort.active] = "";
                if (b[sort.active] == undefined)
                    b[sort.active] = "";
                if (a[sort.active] < b[sort.active]) {
                    result = -1;
                }
                else if (a[sort.active] > b[sort.active]) {
                    result = 1;
                }
                return result * (sort.direction === 'asc' ? 1 : -1);
            });
        }
        this.paginationCollection = sorted;
        this.setPage(this.page);
    };
    SalesRateBySupplyComponent.prototype.setPage = function (currentPage) {
        this.page = currentPage;
        var dataSource = this.paginationCollection.slice((currentPage - 1) * this.pageSize, currentPage * this.pageSize);
        this.dataSource.data = dataSource;
        this.dataSource.sort = this.sort;
    };
    SalesRateBySupplyComponent.prototype.rangeComplete = function (event) {
        this.form.patchValue({
            gte_date: event.startDate,
            lte_date: event.endDate
        });
    };
    SalesRateBySupplyComponent.prototype.search = function () {
        var _this = this;
        this.esSalesService.salesRateBySupplyGet(dateFormat(this.fc_gteDate.value), dateFormat(this.fc_lteDate.value), this.fc_deptName.value && this.fc_deptName.value.trim() != '' ?
            this.fc_deptName.value : null).subscribe({
            next: function (res) {
                _this.paginationCollection = res;
            },
            complete: function () {
                _this.setPage(_this.page);
                _this.sort.sortChange.emit({ active: _this.sort.active, direction: _this.sort.direction });
            }
        });
    };
    SalesRateBySupplyComponent.prototype.onClickExcelExport = function () {
        var _this = this;
        this.esSalesService.salesRateBySupplyExcelGet(dateFormat(this.fc_gteDate.value), dateFormat(this.fc_lteDate.value), this.fc_deptName.value && this.fc_deptName.value.trim() != '' ?
            this.fc_deptName.value : null).subscribe(function (res) {
            _this.createDownloadExcel(res.url);
        });
    };
    SalesRateBySupplyComponent.prototype.createDownloadExcel = function (downloadUrl) {
        var link = document.createElement('a');
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return SalesRateBySupplyComponent;
}());
export { SalesRateBySupplyComponent };
export { ɵ0 };
