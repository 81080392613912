import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { EsSalesService } from 'src/app/restapi/be-esquery';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
var ɵ0 = DEFAULT_FORMATS;
var SupplyRateBySalesComponent = /** @class */ (function () {
    function SupplyRateBySalesComponent(fb, esSalesService, datePipe) {
        this.fb = fb;
        this.esSalesService = esSalesService;
        this.datePipe = datePipe;
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['recycle_place_name', 'shop_name', 'good_second_category_name', 'good_third_category_name', 'normal_cnt', 'event_cnt', 'none_cnt', 'tot_cnt',
            'rate', 'tot_sale_amt', 'no_tax_sale_amt', 'tot_sale_qty', 'avg_price'];
        this.paginationCollection = new Array();
        this.page = 1;
        this.pageSize = 10;
    }
    SupplyRateBySalesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    SupplyRateBySalesComponent.prototype.ngOnDestroy = function () { };
    SupplyRateBySalesComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            gte_date: [new Date()],
            lte_date: [new Date()],
            dept_name: ['']
        });
    };
    Object.defineProperty(SupplyRateBySalesComponent.prototype, "fc_gteDate", {
        get: function () { return this.form.get('gte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SupplyRateBySalesComponent.prototype, "fc_lteDate", {
        get: function () { return this.form.get('lte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SupplyRateBySalesComponent.prototype, "fc_deptName", {
        get: function () { return this.form.get('dept_name'); },
        enumerable: true,
        configurable: true
    });
    SupplyRateBySalesComponent.prototype.initPage = function (sort) {
        if (!this.paginationCollection)
            this.paginationCollection = [];
        var sorted = this.paginationCollection;
        if (sort) {
            sorted = this.paginationCollection.sort(function (a, b) {
                var result = 0;
                if (a[sort.active] == undefined)
                    a[sort.active] = "";
                if (b[sort.active] == undefined)
                    b[sort.active] = "";
                if (a[sort.active] < b[sort.active]) {
                    result = -1;
                }
                else if (a[sort.active] > b[sort.active]) {
                    result = 1;
                }
                return result * (sort.direction === 'asc' ? 1 : -1);
            });
        }
        this.paginationCollection = sorted;
        this.setPage(this.page);
    };
    SupplyRateBySalesComponent.prototype.setPage = function (currentPage) {
        this.page = currentPage;
        var dataSource = this.paginationCollection.slice((currentPage - 1) * this.pageSize, currentPage * this.pageSize);
        this.dataSource.data = dataSource;
        this.dataSource.sort = this.sort;
    };
    SupplyRateBySalesComponent.prototype.rangeComplete = function (event) {
        this.form.patchValue({
            gte_date: event.startDate,
            lte_date: event.endDate
        });
    };
    SupplyRateBySalesComponent.prototype.search = function () {
        var _this = this;
        this.esSalesService.supplyRateBySalesGet(dateFormat(this.fc_gteDate.value), dateFormat(this.fc_lteDate.value), this.fc_deptName.value && this.fc_deptName.value.trim() != '' ?
            this.fc_deptName.value : null).subscribe({
            next: function (res) {
                _this.paginationCollection = res;
            },
            complete: function () {
                _this.setPage(_this.page);
                _this.sort.sortChange.emit({ active: _this.sort.active, direction: _this.sort.direction });
            }
        });
    };
    SupplyRateBySalesComponent.prototype.onClickExcelExport = function () {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(this.convertDatasource());
        var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 },
            { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, '판매대비공급통계');
        XLSX.writeFile(wb, "\uD310\uB9E4\uB300\uBE44\uACF5\uAE09\uD1B5\uACC4_" + this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + ".xlsx");
    };
    SupplyRateBySalesComponent.prototype.convertDatasource = function () {
        var xlsxDatasource = [];
        this.paginationCollection.forEach(function (item) {
            xlsxDatasource.push({
                '되살림터': item.recycle_place_name,
                '매장': item.shop_name,
                '물품구분': item.good_second_category_name,
                '물품상세': item.good_third_category_name,
                '일반출고량': item.normal_cnt,
                '행사출고량': item.event_cnt,
                '무택출고량': item.none_cnt,
                '총출고량': item.tot_cnt,
                '판매대비공급율': item.rate,
                '매출': item.tot_sale_amt,
                'VAT제외매출': item.no_tax_sale_amt,
                '판매수량': item.tot_sale_qty,
                '평균단가': item.avg_price
            });
        });
        return xlsxDatasource;
    };
    return SupplyRateBySalesComponent;
}());
export { SupplyRateBySalesComponent };
export { ɵ0 };
