<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style">
      <label>기간</label>
      <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()"
        (dateChange)="chosenYearHandler($event.target.value, end)" formControlName="year" readonly>
      <mat-datepicker #end startView="multi-year" (yearSelected)="chosenYearHandler($event, end)"></mat-datepicker>
    </div>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div class="statistics-mat-table" #TABLE>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="history_category">
          <mat-header-cell *matHeaderCellDef mat-sort-header>문의구분</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.history_category}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="01">
          <mat-header-cell *matHeaderCellDef mat-sort-header>1월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('01', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="02">
          <mat-header-cell *matHeaderCellDef mat-sort-header>2월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('02', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="03">
          <mat-header-cell *matHeaderCellDef mat-sort-header>3월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('03', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="04">
          <mat-header-cell *matHeaderCellDef mat-sort-header>4월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('04', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="05">
          <mat-header-cell *matHeaderCellDef mat-sort-header>5월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('05', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="06">
          <mat-header-cell *matHeaderCellDef mat-sort-header>6월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('06', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="07">
          <mat-header-cell *matHeaderCellDef mat-sort-header>7월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('07', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="08">
          <mat-header-cell *matHeaderCellDef mat-sort-header>8월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('08', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="09">
          <mat-header-cell *matHeaderCellDef mat-sort-header>9월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('09', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="10">
          <mat-header-cell *matHeaderCellDef mat-sort-header>10월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('10', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="11">
          <mat-header-cell *matHeaderCellDef mat-sort-header>11월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('11', element) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="12">
          <mat-header-cell *matHeaderCellDef mat-sort-header>12월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertMonthInfo('12', element) | currencyMask}}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>