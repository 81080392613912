import { OnInit, ElementRef, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
var LogisticsDeptsDetailComponent = /** @class */ (function () {
    function LogisticsDeptsDetailComponent() {
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['date', 'dept_name', 'logistics_collect_area_name', 'logistics_div', 'count', 'give_unit_count', 'give_box_count'];
    }
    LogisticsDeptsDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    LogisticsDeptsDetailComponent.prototype.ngOnDestroy = function () { };
    LogisticsDeptsDetailComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.dataList && changes.dataList.currentValue != null) {
            setTimeout(function () {
                _this.initPage(_this.sort);
            });
        }
    };
    LogisticsDeptsDetailComponent.prototype.initPage = function (sort) {
        if (!this.dataList)
            this.dataList = [];
        var sorted = this.dataList;
        if (sort) {
            sorted = this.dataList.sort(function (a, b) {
                var result = 0;
                if (a[sort.active] == undefined)
                    a[sort.active] = "";
                if (b[sort.active] == undefined)
                    b[sort.active] = "";
                if (a[sort.active] < b[sort.active])
                    result = -1;
                else if (a[sort.active] > b[sort.active])
                    result = 1;
                return result * (sort.direction === 'asc' ? 1 : -1);
            });
        }
        this.dataList = sorted;
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    return LogisticsDeptsDetailComponent;
}());
export { LogisticsDeptsDetailComponent };
