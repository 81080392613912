import { Directive, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDatepicker } from '@angular/material';
import { DatePipe } from '@angular/common';

export interface DateRangePicker {
    startDate: string;
    endDate: string;
}

@Directive({
    selector: '[dateRangePicker]',
    exportAs: 'dateRangePicker',
    providers: [DatePipe]
})
export class DateRangePickerDirective implements OnInit {
    private _INPUT_DATE_FORMAT: string = 'yyyy-MM-dd';
    startDate: MatDatepicker<any>;
    endDate: MatDatepicker<any>;

    @Input('dateRangePicker') rangeDatePicker: any;
    @Output() rangeComplete: EventEmitter<DateRangePicker> = new EventEmitter<DateRangePicker>();

    constructor(private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.startDate = this.rangeDatePicker.startDate;
        this.endDate = this.rangeDatePicker.endDate;
    }

    @HostListener('closed', ['$event'])
    onClosed() {
        if (null != this.startDate._selected && null != this.endDate._selected) {
            let selectedStartDate: any = this.startDate._selected;
            let selectedEndDate: any = this.endDate._selected;

            if (selectedEndDate._d.getTime() < selectedStartDate._d.getTime()) {
                this.endDate.select(selectedStartDate);
                this.startDate.select(selectedEndDate);
            }

            let output: DateRangePicker = {
                startDate: this.datePipe.transform(this.startDate._selected._d, this._INPUT_DATE_FORMAT),
                endDate: this.datePipe.transform(this.endDate._selected._d, this._INPUT_DATE_FORMAT)
            };
            this.rangeComplete.emit(output);
        }
    }
}
