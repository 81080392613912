/**
 * angel
 *  ## 자원활동관련 데이타를 관리한다.
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var StatisticsService = /** @class */ (function () {
    function StatisticsService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9905/angel/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    StatisticsService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    StatisticsService.prototype.statisticsActiveStateGet = function (act_full_dept_name, angel_type, angel_term, angel_job, angel_gender, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        if (angel_job !== undefined && angel_job !== null) {
            queryParameters = queryParameters.set('angel_job', angel_job);
        }
        if (angel_gender !== undefined && angel_gender !== null) {
            queryParameters = queryParameters.set('angel_gender', angel_gender);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/statistics/active_state", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    StatisticsService.prototype.statisticsActiveStateYearGet = function (year, act_full_dept_name, angel_type, angel_term, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsActiveStateYearGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/statistics/active_state_year", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    StatisticsService.prototype.statisticsAgeYearGet = function (year, act_full_dept_name, angel_type, angel_term, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsAgeYearGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/statistics/age_year", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    StatisticsService.prototype.statisticsAreaYearGet = function (year, act_full_dept_name, angel_type, angel_term, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsAreaYearGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/statistics/area_year", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    StatisticsService.prototype.statisticsByTimetableGet = function (act_full_dept_name, angel_type, angel_term, angel_job, angel_gender, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        if (angel_job !== undefined && angel_job !== null) {
            queryParameters = queryParameters.set('angel_job', angel_job);
        }
        if (angel_gender !== undefined && angel_gender !== null) {
            queryParameters = queryParameters.set('angel_gender', angel_gender);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/statistics/by_timetable", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    StatisticsService.prototype.statisticsDifferenceYearGet = function (year, act_full_dept_name, angel_type, angel_term, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsDifferenceYearGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/statistics/difference_year", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    StatisticsService.prototype.statisticsGenderYearGet = function (year, act_full_dept_name, angel_type, angel_term, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsGenderYearGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/statistics/gender_year", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    StatisticsService.prototype.statisticsTimeYearGet = function (year, act_full_dept_name, angel_type, angel_term, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsTimeYearGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [];
        return this.httpClient.get(this.basePath + "/statistics/time_year", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return StatisticsService;
}());
export { StatisticsService };
