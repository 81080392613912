import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS, MatDatepicker } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { take, tap, takeUntil } from 'rxjs/operators';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { EsDreceiptService } from 'src/app/restapi/be-esquery';
import { EsDreceiptIssue } from 'src/app/restapi/be-esquery/model/esDreceiptIssue';
import { EsDreceiptTotal } from 'src/app/restapi/be-esquery/model/esDreceiptTotal';
import { EsDreceiptMonthly } from 'src/app/restapi/be-esquery/model/esDreceiptMonthly';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

@Component({
  selector: 'app-dreceipt-issue',
  templateUrl: './dreceipt-issue.component.html',
  styleUrls: ['./dreceipt-issue.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ]
})
export class DreceiptIssueComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  dataList: Array<EsDreceiptTotal> = [];
  dataSource: MatTableDataSource<EsDreceiptTotal> = new MatTableDataSource([]);
  displayedColumns = ['joiner_div', 'name', 'total', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  constructor(
    private fb: FormBuilder,
    private esDreceiptService: EsDreceiptService
  ) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      year: moment(),
      is_auto: '',
      is_type: '발행일'
    });
  }

  get fc_year() { return this.form.get('year'); }
  get fc_isAuto() { return this.form.get('is_auto'); }
  get fc_isType() { return this.form.get('is_type'); }

  chosenYearHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_year.setValue(normalizedMonth);
    this.search();
    
    datepicker.close();
  }

  convertName(data: EsDreceiptTotal) {
    if (data.total_price) return '금액';
    if (data.total_count) return '건수';
    return '';
  }

  convertTotal(data: EsDreceiptTotal) {
    if (data.total_price) return data.total_price;
    if (data.total_count) return data.total_count;
    return '';
  }

  convertMonthInfo(month: string, dreceiptIssue: EsDreceiptTotal) {
    if (dreceiptIssue.total_price) {
      const dreceiptMonthly: EsDreceiptMonthly = dreceiptIssue.monthly_info.find(x => x.date.split("-")[1] == month);
      if (dreceiptMonthly) return dreceiptMonthly.price;
    }

    if (dreceiptIssue.total_count) {
      const dreceiptMonthly: EsDreceiptMonthly = dreceiptIssue.monthly_info.find(x => x.date.split("-")[1] == month);
      if (dreceiptMonthly) return dreceiptMonthly.count;
    }

    return '';
  }

  search() {
    this.dreceiptIssueGet();
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      if (sort.active == '01' || sort.active == '02' || sort.active == '03' || sort.active == '04' || sort.active == '05' || sort.active == '06' ||
        sort.active == '07' || sort.active == '08' || sort.active == '09' || sort.active == '10' || sort.active == '11' || sort.active == '12') {
        sorted = this.dataList.sort((a: EsDreceiptTotal, b: EsDreceiptTotal) => {
          let result = 0;
          let a_data: number = Number(this.convertMonthInfo(sort.active, a));
          let b_data: number = Number(this.convertMonthInfo(sort.active, b));

          if (a_data < b_data) result = -1;
          else if (a_data > b_data) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
      else if (sort.active == 'name') {
        sorted = this.dataList.sort((a: EsDreceiptTotal, b: EsDreceiptTotal) => {
          let result = 0;
          let a_data: string = this.convertName(a);
          let b_data: string = this.convertName(b);

          if (a_data < b_data) result = -1;
          else if (a_data > b_data) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
      else if (sort.active == 'total') {
        sorted = this.dataList.sort((a: EsDreceiptTotal, b: EsDreceiptTotal) => {
          let result = 0;
          let a_data: number = Number(this.convertTotal(a));
          let b_data: number = Number(this.convertTotal(b));

          if (a_data < b_data) result = -1;
          else if (a_data > b_data) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
      else {
        sorted = this.dataList.sort((a: EsDreceiptTotal, b: EsDreceiptTotal) => {
          let result = 0;
          if (a[sort.active] == undefined) a[sort.active] = "";
          if (b[sort.active] == undefined) b[sort.active] = "";

          if (a[sort.active] < b[sort.active]) result = -1;
          else if (a[sort.active] > b[sort.active]) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsDreceiptTotal>(this.dataList);
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '기부영수증 통계');
    XLSX.writeFile(wb, '기부영수증 통계.xlsx');
  }

  dreceiptIssueGet() {
    this.dataList = [];

    this.esDreceiptService.dreceiptIssueGet(
      dateFormat_Year(this.fc_year.value),
      this.fc_isAuto.value == '' ? null : this.fc_isAuto.value,
      this.fc_isType.value
    ).pipe(
      take(1)
    ).subscribe((res: EsDreceiptIssue) => {
      this.dataList = res.div;
      this.initPage(this.sort);
    },
      (err) => {
        console.error(err);
        this.initPage(this.sort);
      }
    );
  }

}