import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS } from '@angular/material';
import { DateRangePicker } from 'src/app/share/components/directives/date-range-picker.directive';
import { DatePipe } from '@angular/common';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { EsGivingsCase } from 'src/app/restapi/be-esquery/model/esGivingsCase';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-givings-by-donation',
  templateUrl: './givings-by-donation.component.html',
  styleUrls: ['./givings-by-donation.component.scss'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS },
  ]
})
export class GivingsByDonationComponent implements OnInit {
  form: FormGroup;
  dataSource: MatTableDataSource<EsGivingsCase> = new MatTableDataSource([]);
  displayedColumns = ['give_confirm_date', 'give_reception_joiner_name', 'give_confirm_emp_dept_name', 'give_route', 'good_cl', 'good_li', 'good_br', 'good_el',
    'tot_amt'];
  paginationCollection: Array<any> = new Array<any>();
  page: number = 1;
  pageSize: number = 10;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder, private esGivingsService: EsGivingsService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy(): void { }

  initForm() {
    this.form = this.fb.group({
      gte_date: [new Date()],
      lte_date: [new Date()],
      dept_name: ['']
    });
  }

  get fc_gteDate() { return this.form.get('gte_date'); }
  get fc_lteDate() { return this.form.get('lte_date'); }
  get fc_deptName() { return this.form.get('dept_name'); }

  initPage(sort?: Sort) {
    if (!this.paginationCollection) this.paginationCollection = [];

    let sorted = this.paginationCollection;
    if (sort) {
      sorted = this.paginationCollection.sort((a: any, b: any) => {
        let result = 0;

        if (a[sort.active] == undefined) a[sort.active] = "";
        if (b[sort.active] == undefined) b[sort.active] = "";

        if (a[sort.active] < b[sort.active]) {
          result = -1;
        } else if (a[sort.active] > b[sort.active]) {
          result = 1;
        }
        return result * (sort.direction === 'asc' ? 1 : -1);
      });
    }
    this.paginationCollection = sorted;
    this.setPage(this.page);
  }

  setPage(currentPage: number) {
    this.page = currentPage;
    const dataSource = this.paginationCollection.slice(
      (currentPage - 1) * this.pageSize,
      currentPage * this.pageSize
    );
    this.dataSource.data = dataSource;
    this.dataSource.sort = this.sort;
  }

  rangeComplete(event: DateRangePicker) {
    this.form.patchValue({
      gte_date: event.startDate,
      lte_date: event.endDate
    });
  }

  search() {
    this.esGivingsService.givingsSearchBoardGet(dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value), this.fc_deptName.value && this.fc_deptName.value.trim() != '' ?
      this.fc_deptName.value : null).subscribe({
        next: res => {
          this.paginationCollection = res;
        },
        complete: () => {
          this.setPage(this.page);
          this.sort.sortChange.emit({ active: this.sort.active, direction: this.sort.direction });
        }
      });
  }

  onClickExcelExport() {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertDatasource());
    const wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 },
    { wch: 15 }];
    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, '기증건별조회');
    XLSX.writeFile(wb, `기증건별조회_${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}.xlsx`);
  }

  convertDatasource() {
    const xlsxDatasource: Array<any> = [];

    this.paginationCollection.forEach((item: any) => {
      xlsxDatasource.push({
        '기증일자': this.datePipe.transform(item.give_confirm_date, 'yyyy-MM-dd'),
        '기증자명': item.give_reception_joiner_name,
        '기증처': item.give_confirm_emp_dept_name,
        '기증경로': item.give_route,
        '의류': item.good_cl,
        '잡화': item.good_li,
        '도서음반': item.good_br,
        '가전': item.good_el,
        '기증총점수': item.tot_amt
      });
    });
    return xlsxDatasource;
  }

}
