/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsCommuteInfo } from '../model/esCommuteInfo';
import { EsCommuteInfoMonthly } from '../model/esCommuteInfoMonthly';
import { EsDownloadUrl } from '../model/esDownloadUrl';
import { EsEmpIdWithAuth0Id } from '../model/esEmpIdWithAuth0Id';
import { EsEmpMeta } from '../model/esEmpMeta';
import { EsFailure } from '../model/esFailure';
import { EsHRBasic } from '../model/esHRBasic';
import { EsStatement } from '../model/esStatement';
import { EsVacationInfo } from '../model/esVacationInfo';
import { EsVacationInfoMonthly } from '../model/esVacationInfoMonthly';
import { EsVehicleInfo } from '../model/esVehicleInfo';
import { EsWork } from '../model/esWork';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsEmpService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * emp_account_id 로 중복검색
     * emp_account_id 로 중복검색
     * @param emp_account_id emp_account_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empAccountIdGet(emp_account_id: string, observe?: 'body', reportProgress?: boolean): Observable<EsEmpIdWithAuth0Id>;
    public empAccountIdGet(emp_account_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsEmpIdWithAuth0Id>>;
    public empAccountIdGet(emp_account_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsEmpIdWithAuth0Id>>;
    public empAccountIdGet(emp_account_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_account_id === null || emp_account_id === undefined) {
            throw new Error('Required parameter emp_account_id was null or undefined when calling empAccountIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emp_account_id !== undefined && emp_account_id !== null) {
            queryParameters = queryParameters.set('emp_account_id', <any>emp_account_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsEmpIdWithAuth0Id>(`${this.basePath}/emp/account_id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기초정보 기준검색 엑셀다운로드
     * 기초정보 기준검색 엑셀다운로드
     * @param contract_date 계약기간 (greater than equals) 형식 2018-09-10
     * @param gte_contract 계약기간 (greater than equals) 형식 2018-09-10
     * @param lte_contract 계약기간 (less than equals) 형식 2018-09-10
     * @param emp_id 직원아이디
     * @param emp_name 이름
     * @param full_dept_id 부서아이디
     * @param full_dept_name 부서이름
     * @param emp_job_status 코드이 들어간다.e.g)job_sta_0
     * @param conf_emp_private_no 주민번호검색
     * @param permanent_emp 정규직만 검색할때 &#39;Y&#39;
     * @param emp_job_rank 코드이 들어간다.e.g)job_ran_0
     * @param emp_job_title 코드이 들어간다.e.g)job_tit_0
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empBasicExcelGet(contract_date?: 'start' | 'end', gte_contract?: string, lte_contract?: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, emp_job_status?: string, conf_emp_private_no?: string, permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public empBasicExcelGet(contract_date?: 'start' | 'end', gte_contract?: string, lte_contract?: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, emp_job_status?: string, conf_emp_private_no?: string, permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public empBasicExcelGet(contract_date?: 'start' | 'end', gte_contract?: string, lte_contract?: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, emp_job_status?: string, conf_emp_private_no?: string, permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public empBasicExcelGet(contract_date?: 'start' | 'end', gte_contract?: string, lte_contract?: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, emp_job_status?: string, conf_emp_private_no?: string, permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contract_date !== undefined && contract_date !== null) {
            queryParameters = queryParameters.set('contract_date', <any>contract_date);
        }
        if (gte_contract !== undefined && gte_contract !== null) {
            queryParameters = queryParameters.set('gte_contract', <any>gte_contract);
        }
        if (lte_contract !== undefined && lte_contract !== null) {
            queryParameters = queryParameters.set('lte_contract', <any>lte_contract);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', <any>emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (full_dept_name !== undefined && full_dept_name !== null) {
            queryParameters = queryParameters.set('full_dept_name', <any>full_dept_name);
        }
        if (emp_job_status !== undefined && emp_job_status !== null) {
            queryParameters = queryParameters.set('emp_job_status', <any>emp_job_status);
        }
        if (conf_emp_private_no !== undefined && conf_emp_private_no !== null) {
            queryParameters = queryParameters.set('conf_emp_private_no', <any>conf_emp_private_no);
        }
        if (permanent_emp !== undefined && permanent_emp !== null) {
            queryParameters = queryParameters.set('permanent_emp', <any>permanent_emp);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }
        if (emp_job_title !== undefined && emp_job_title !== null) {
            queryParameters = queryParameters.set('emp_job_title', <any>emp_job_title);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/emp/basic/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 기초정보 기준검색
     * 기초정보로 검색된 사용자를 가지고 온다.
     * @param contract_date 계약기간 (greater than equals) 형식 2018-09-10
     * @param gte_contract 계약기간 (greater than equals) 형식 2018-09-10
     * @param lte_contract 계약기간 (less than equals) 형식 2018-09-10
     * @param emp_id 직원아이디
     * @param emp_name 이름
     * @param full_dept_id 부서아이디
     * @param full_dept_name 부서이름
     * @param emp_job_status 코드이 들어간다.e.g)job_sta_0
     * @param conf_emp_private_no 주민번호검색
     * @param permanent_emp 정규직만 검색할때 &#39;Y&#39;
     * @param emp_job_rank 코드이 들어간다.e.g)job_ran_0
     * @param emp_job_title 코드이 들어간다.e.g)job_tit_0
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empBasicGet(contract_date?: 'start' | 'end', gte_contract?: string, lte_contract?: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, emp_job_status?: string, conf_emp_private_no?: string, permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsHRBasic>>;
    public empBasicGet(contract_date?: 'start' | 'end', gte_contract?: string, lte_contract?: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, emp_job_status?: string, conf_emp_private_no?: string, permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsHRBasic>>>;
    public empBasicGet(contract_date?: 'start' | 'end', gte_contract?: string, lte_contract?: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, emp_job_status?: string, conf_emp_private_no?: string, permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsHRBasic>>>;
    public empBasicGet(contract_date?: 'start' | 'end', gte_contract?: string, lte_contract?: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, emp_job_status?: string, conf_emp_private_no?: string, permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contract_date !== undefined && contract_date !== null) {
            queryParameters = queryParameters.set('contract_date', <any>contract_date);
        }
        if (gte_contract !== undefined && gte_contract !== null) {
            queryParameters = queryParameters.set('gte_contract', <any>gte_contract);
        }
        if (lte_contract !== undefined && lte_contract !== null) {
            queryParameters = queryParameters.set('lte_contract', <any>lte_contract);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', <any>emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (full_dept_name !== undefined && full_dept_name !== null) {
            queryParameters = queryParameters.set('full_dept_name', <any>full_dept_name);
        }
        if (emp_job_status !== undefined && emp_job_status !== null) {
            queryParameters = queryParameters.set('emp_job_status', <any>emp_job_status);
        }
        if (conf_emp_private_no !== undefined && conf_emp_private_no !== null) {
            queryParameters = queryParameters.set('conf_emp_private_no', <any>conf_emp_private_no);
        }
        if (permanent_emp !== undefined && permanent_emp !== null) {
            queryParameters = queryParameters.set('permanent_emp', <any>permanent_emp);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }
        if (emp_job_title !== undefined && emp_job_title !== null) {
            queryParameters = queryParameters.set('emp_job_title', <any>emp_job_title);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsHRBasic>>(`${this.basePath}/emp/basic`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 일자별 출퇴근정보 정산 엑셀다운로드
     * 일자별 출퇴근정보 정산 엑셀다운로드
     * @param year_month 검색 연월(2019-04)
     * @param emp_id 출퇴근 정보조회 대상 아이디
     * @param emp_name 출퇴근 정보조회 대상 이름
     * @param full_dept_id 출퇴근 정보조회 대상 부서아이디
     * @param emp_job_rank 직급코드
     * @param conf_emp_salary_type 급여형태코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empCommuteCalculationExcelGet(year_month: string, emp_id?: string, emp_name?: string, full_dept_id?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public empCommuteCalculationExcelGet(year_month: string, emp_id?: string, emp_name?: string, full_dept_id?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public empCommuteCalculationExcelGet(year_month: string, emp_id?: string, emp_name?: string, full_dept_id?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public empCommuteCalculationExcelGet(year_month: string, emp_id?: string, emp_name?: string, full_dept_id?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year_month === null || year_month === undefined) {
            throw new Error('Required parameter year_month was null or undefined when calling empCommuteCalculationExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year_month !== undefined && year_month !== null) {
            queryParameters = queryParameters.set('year_month', <any>year_month);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', <any>emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }
        if (conf_emp_salary_type !== undefined && conf_emp_salary_type !== null) {
            queryParameters = queryParameters.set('conf_emp_salary_type', <any>conf_emp_salary_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/emp/commute/calculation/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 일자별 출퇴근정보 엑셀다운로드
     * 일자별 출퇴근 정보 엑셀다운로드
     * @param emp_id 출퇴근 정보조회 대상 아이디
     * @param full_dept_id 출퇴근 정보조회 대상 부서아이디
     * @param name 부서/사람대상으로 검색
     * @param start_date 검색 시작일
     * @param end_date 검색 종료일
     * @param emp_job_rank 직급코드
     * @param conf_emp_salary_type 급여형태코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empCommuteExcelGet(emp_id?: string, full_dept_id?: string, name?: string, start_date?: string, end_date?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public empCommuteExcelGet(emp_id?: string, full_dept_id?: string, name?: string, start_date?: string, end_date?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public empCommuteExcelGet(emp_id?: string, full_dept_id?: string, name?: string, start_date?: string, end_date?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public empCommuteExcelGet(emp_id?: string, full_dept_id?: string, name?: string, start_date?: string, end_date?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (start_date !== undefined && start_date !== null) {
            queryParameters = queryParameters.set('start_date', <any>start_date);
        }
        if (end_date !== undefined && end_date !== null) {
            queryParameters = queryParameters.set('end_date', <any>end_date);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }
        if (conf_emp_salary_type !== undefined && conf_emp_salary_type !== null) {
            queryParameters = queryParameters.set('conf_emp_salary_type', <any>conf_emp_salary_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/emp/commute/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 일자별 출퇴근정보
     * 일자별 출퇴근정보
     * @param emp_id 출퇴근 정보조회 대상 아이디
     * @param full_dept_id 출퇴근 정보조회 대상 부서아이디
     * @param name 부서/사람대상으로 검색
     * @param start_date 검색 시작일
     * @param end_date 검색 종료일
     * @param emp_job_rank 직급코드
     * @param conf_emp_salary_type 급여형태코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empCommuteGet(emp_id?: string, full_dept_id?: string, name?: string, start_date?: string, end_date?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsCommuteInfo>>;
    public empCommuteGet(emp_id?: string, full_dept_id?: string, name?: string, start_date?: string, end_date?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsCommuteInfo>>>;
    public empCommuteGet(emp_id?: string, full_dept_id?: string, name?: string, start_date?: string, end_date?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsCommuteInfo>>>;
    public empCommuteGet(emp_id?: string, full_dept_id?: string, name?: string, start_date?: string, end_date?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (start_date !== undefined && start_date !== null) {
            queryParameters = queryParameters.set('start_date', <any>start_date);
        }
        if (end_date !== undefined && end_date !== null) {
            queryParameters = queryParameters.set('end_date', <any>end_date);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }
        if (conf_emp_salary_type !== undefined && conf_emp_salary_type !== null) {
            queryParameters = queryParameters.set('conf_emp_salary_type', <any>conf_emp_salary_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsCommuteInfo>>(`${this.basePath}/emp/commute`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 월별 출퇴근 계산시간
     * 월별 출퇴근 계산시간
     * @param year_month 검색 연월(2019-04)
     * @param emp_id 출퇴근 정보조회 대상 아이디
     * @param emp_name 출퇴근 정보조회 대상 이름
     * @param full_dept_id 출퇴근 정보조회 대상 부서아이디
     * @param emp_job_rank 직급코드
     * @param conf_emp_salary_type 급여형태코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empCommuteMonthlyGet(year_month: string, emp_id?: string, emp_name?: string, full_dept_id?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsCommuteInfoMonthly>>;
    public empCommuteMonthlyGet(year_month: string, emp_id?: string, emp_name?: string, full_dept_id?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsCommuteInfoMonthly>>>;
    public empCommuteMonthlyGet(year_month: string, emp_id?: string, emp_name?: string, full_dept_id?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsCommuteInfoMonthly>>>;
    public empCommuteMonthlyGet(year_month: string, emp_id?: string, emp_name?: string, full_dept_id?: string, emp_job_rank?: string, conf_emp_salary_type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year_month === null || year_month === undefined) {
            throw new Error('Required parameter year_month was null or undefined when calling empCommuteMonthlyGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year_month !== undefined && year_month !== null) {
            queryParameters = queryParameters.set('year_month', <any>year_month);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', <any>emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }
        if (conf_emp_salary_type !== undefined && conf_emp_salary_type !== null) {
            queryParameters = queryParameters.set('conf_emp_salary_type', <any>conf_emp_salary_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsCommuteInfoMonthly>>(`${this.basePath}/emp/commute/monthly`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Driver 기준 차량검색
     * emp_id driver 기준으로 차량을 검색한다.
     * @param emp_id emp_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empEmpIdVehiclesGet(emp_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsVehicleInfo>>;
    public empEmpIdVehiclesGet(emp_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsVehicleInfo>>>;
    public empEmpIdVehiclesGet(emp_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsVehicleInfo>>>;
    public empEmpIdVehiclesGet(emp_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling empEmpIdVehiclesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsVehicleInfo>>(`${this.basePath}/emp/${encodeURIComponent(String(emp_id))}/vehicles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사용자 검색조건에따라 메타정보를 가지고온다
     * 사용자 검색조건에 따라 메타정보를 가지고 온다.
     * @param emp_name 직원이름
     * @param meta_info 이름,조직명,핸드폰,근무처전화번호 필드대상으로 검색한다.
     * @param role role 대상으로 검색한다.
     * @param page_group page_group 대상으로 검색한다.
     * @param retired_emp 퇴직자 포함일 경우 &#39;Y&#39; 
     * @param permanent_emp 정규직만 검색할때 &#39;Y&#39;
     * @param emp_job_rank 코드이 들어간다.e.g)job_ran_0
     * @param emp_job_title 코드이 들어간다.e.g)job_tit_0
     * @param emp_auth0_id auth0 아이디
     * @param emp_id 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empMetaGet(emp_name?: string, meta_info?: string, role?: string, page_group?: string, retired_emp?: 'Y' | 'N', permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, emp_auth0_id?: string, emp_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsEmpMeta>>;
    public empMetaGet(emp_name?: string, meta_info?: string, role?: string, page_group?: string, retired_emp?: 'Y' | 'N', permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, emp_auth0_id?: string, emp_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEmpMeta>>>;
    public empMetaGet(emp_name?: string, meta_info?: string, role?: string, page_group?: string, retired_emp?: 'Y' | 'N', permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, emp_auth0_id?: string, emp_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEmpMeta>>>;
    public empMetaGet(emp_name?: string, meta_info?: string, role?: string, page_group?: string, retired_emp?: 'Y' | 'N', permanent_emp?: 'Y' | 'N', emp_job_rank?: string, emp_job_title?: string, emp_auth0_id?: string, emp_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', <any>emp_name);
        }
        if (meta_info !== undefined && meta_info !== null) {
            queryParameters = queryParameters.set('meta_info', <any>meta_info);
        }
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }
        if (page_group !== undefined && page_group !== null) {
            queryParameters = queryParameters.set('page_group', <any>page_group);
        }
        if (retired_emp !== undefined && retired_emp !== null) {
            queryParameters = queryParameters.set('retired_emp', <any>retired_emp);
        }
        if (permanent_emp !== undefined && permanent_emp !== null) {
            queryParameters = queryParameters.set('permanent_emp', <any>permanent_emp);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }
        if (emp_job_title !== undefined && emp_job_title !== null) {
            queryParameters = queryParameters.set('emp_job_title', <any>emp_job_title);
        }
        if (emp_auth0_id !== undefined && emp_auth0_id !== null) {
            queryParameters = queryParameters.set('emp_auth0_id', <any>emp_auth0_id);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEmpMeta>>(`${this.basePath}/emp/meta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사용자가 가지고있는 모든 statments를 가지고 온다
     * 사용자가 가지고있는 모든 statments를 가지고 온다
     * @param emp_id 직원아이디
     * @param emp_account_id emp_account_id
     * @param emp_auth0_id auth0아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empPoliciesStatmentsGet(emp_id?: string, emp_account_id?: string, emp_auth0_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsStatement>>;
    public empPoliciesStatmentsGet(emp_id?: string, emp_account_id?: string, emp_auth0_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsStatement>>>;
    public empPoliciesStatmentsGet(emp_id?: string, emp_account_id?: string, emp_auth0_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsStatement>>>;
    public empPoliciesStatmentsGet(emp_id?: string, emp_account_id?: string, emp_auth0_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_account_id !== undefined && emp_account_id !== null) {
            queryParameters = queryParameters.set('emp_account_id', <any>emp_account_id);
        }
        if (emp_auth0_id !== undefined && emp_auth0_id !== null) {
            queryParameters = queryParameters.set('emp_auth0_id', <any>emp_auth0_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsStatement>>(`${this.basePath}/emp/policies/statments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 휴가정보조회(휴가 구분이 여러개일경우 &#39;|&#39; 수직바로 구분해서 전달필요함)
     * 휴가정보를 구분,기간,이름으로 검색(여러개일경우 &#39;|&#39; 수직바로 구분해서 전달필요함)
     * @param emp_id 휴가정보 조회대상 아이디
     * @param full_dept_id 휴가정보 조회대상 부서아이디
     * @param vac_div_codes 휴가 구분(여러개일경우 &#39;|&#39; 수직바로 구분해서 전달필요함)
     * @param name 부서/사람대상으로 검색
     * @param search_date 휴가검색 기준일
     * @param gte Greater-than or equal to
     * @param lte Less-than or equal to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empVacationExcelGet(emp_id?: string, full_dept_id?: string, vac_div_codes?: string, name?: string, search_date?: '전체' | '시작일' | '종료일', gte?: string, lte?: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public empVacationExcelGet(emp_id?: string, full_dept_id?: string, vac_div_codes?: string, name?: string, search_date?: '전체' | '시작일' | '종료일', gte?: string, lte?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public empVacationExcelGet(emp_id?: string, full_dept_id?: string, vac_div_codes?: string, name?: string, search_date?: '전체' | '시작일' | '종료일', gte?: string, lte?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public empVacationExcelGet(emp_id?: string, full_dept_id?: string, vac_div_codes?: string, name?: string, search_date?: '전체' | '시작일' | '종료일', gte?: string, lte?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (vac_div_codes !== undefined && vac_div_codes !== null) {
            queryParameters = queryParameters.set('vac_div_codes', <any>vac_div_codes);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (search_date !== undefined && search_date !== null) {
            queryParameters = queryParameters.set('search_date', <any>search_date);
        }
        if (gte !== undefined && gte !== null) {
            queryParameters = queryParameters.set('gte', <any>gte);
        }
        if (lte !== undefined && lte !== null) {
            queryParameters = queryParameters.set('lte', <any>lte);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/emp/vacation/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 휴가정보조회(휴가 구분이 여러개일경우 &#39;|&#39; 수직바로 구분해서 전달필요함)
     * 휴가정보를 구분,기간,이름으로 검색(여러개일경우 &#39;|&#39; 수직바로 구분해서 전달필요함)
     * @param emp_id 휴가정보 조회대상 아이디
     * @param full_dept_id 휴가정보 조회대상 부서아이디
     * @param vac_div_codes 휴가 구분(여러개일경우 &#39;|&#39; 수직바로 구분해서 전달필요함)
     * @param name 부서/사람대상으로 검색
     * @param search_date 휴가검색 기준일
     * @param gte Greater-than or equal to
     * @param lte Less-than or equal to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empVacationGet(emp_id?: string, full_dept_id?: string, vac_div_codes?: string, name?: string, search_date?: '전체' | '시작일' | '종료일', gte?: string, lte?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsVacationInfo>>;
    public empVacationGet(emp_id?: string, full_dept_id?: string, vac_div_codes?: string, name?: string, search_date?: '전체' | '시작일' | '종료일', gte?: string, lte?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsVacationInfo>>>;
    public empVacationGet(emp_id?: string, full_dept_id?: string, vac_div_codes?: string, name?: string, search_date?: '전체' | '시작일' | '종료일', gte?: string, lte?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsVacationInfo>>>;
    public empVacationGet(emp_id?: string, full_dept_id?: string, vac_div_codes?: string, name?: string, search_date?: '전체' | '시작일' | '종료일', gte?: string, lte?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (vac_div_codes !== undefined && vac_div_codes !== null) {
            queryParameters = queryParameters.set('vac_div_codes', <any>vac_div_codes);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (search_date !== undefined && search_date !== null) {
            queryParameters = queryParameters.set('search_date', <any>search_date);
        }
        if (gte !== undefined && gte !== null) {
            queryParameters = queryParameters.set('gte', <any>gte);
        }
        if (lte !== undefined && lte !== null) {
            queryParameters = queryParameters.set('lte', <any>lte);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsVacationInfo>>(`${this.basePath}/emp/vacation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 월별 연차 사용조회 엑셀
     * 월별 연차사용 조회 엑셀
     * @param year 휴가검색 기준년도
     * @param emp_id 휴가정보 조회대상 아이디
     * @param emp_name 휴가정보 조회대상 이름
     * @param full_dept_id 휴가정보 조회대상 부서아이디
     * @param full_dept_name 휴가정보 조회대상 부서이름
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empVacationMonthlyExcelGet(year: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public empVacationMonthlyExcelGet(year: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public empVacationMonthlyExcelGet(year: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public empVacationMonthlyExcelGet(year: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling empVacationMonthlyExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', <any>emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (full_dept_name !== undefined && full_dept_name !== null) {
            queryParameters = queryParameters.set('full_dept_name', <any>full_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/emp/vacation/monthly/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 월별 연차 사용조회
     * 월별 연차사용 조회
     * @param year 휴가검색 기준년도
     * @param emp_id 휴가정보 조회대상 아이디
     * @param emp_name 휴가정보 조회대상 이름
     * @param full_dept_id 휴가정보 조회대상 부서아이디
     * @param full_dept_name 휴가정보 조회대상 부서이름
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empVacationMonthlyGet(year: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsVacationInfoMonthly>>;
    public empVacationMonthlyGet(year: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsVacationInfoMonthly>>>;
    public empVacationMonthlyGet(year: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsVacationInfoMonthly>>>;
    public empVacationMonthlyGet(year: string, emp_id?: string, emp_name?: string, full_dept_id?: string, full_dept_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling empVacationMonthlyGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', <any>emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }
        if (full_dept_name !== undefined && full_dept_name !== null) {
            queryParameters = queryParameters.set('full_dept_name', <any>full_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsVacationInfoMonthly>>(`${this.basePath}/emp/vacation/monthly`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 근무정보 기준 엑셀다운로드
     * 근무정보 기준 엑셀다운로드
     * @param issue_start_date_gte 발령난날 형식 2018-09-10
     * @param issue_start_date_lte 발령난날 형식 2018-09-10
     * @param emp_id 직원아이디
     * @param emp_dept_id 부서아이디
     * @param name 부서이름 또는 직원이름
     * @param work_div_code 근무구분코드
     * @param code 하위 코드
     * @param proc_status 발령처리상태
     * @param emp_job_rank 직급코드입력 e.g.)job_ran_0
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empWorksExcelGet(issue_start_date_gte: string, issue_start_date_lte: string, emp_id?: string, emp_dept_id?: string, name?: string, work_div_code?: string, code?: string, proc_status?: string, emp_job_rank?: string, observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public empWorksExcelGet(issue_start_date_gte: string, issue_start_date_lte: string, emp_id?: string, emp_dept_id?: string, name?: string, work_div_code?: string, code?: string, proc_status?: string, emp_job_rank?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public empWorksExcelGet(issue_start_date_gte: string, issue_start_date_lte: string, emp_id?: string, emp_dept_id?: string, name?: string, work_div_code?: string, code?: string, proc_status?: string, emp_job_rank?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public empWorksExcelGet(issue_start_date_gte: string, issue_start_date_lte: string, emp_id?: string, emp_dept_id?: string, name?: string, work_div_code?: string, code?: string, proc_status?: string, emp_job_rank?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (issue_start_date_gte === null || issue_start_date_gte === undefined) {
            throw new Error('Required parameter issue_start_date_gte was null or undefined when calling empWorksExcelGet.');
        }
        if (issue_start_date_lte === null || issue_start_date_lte === undefined) {
            throw new Error('Required parameter issue_start_date_lte was null or undefined when calling empWorksExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issue_start_date_gte !== undefined && issue_start_date_gte !== null) {
            queryParameters = queryParameters.set('issue_start_date_gte', <any>issue_start_date_gte);
        }
        if (issue_start_date_lte !== undefined && issue_start_date_lte !== null) {
            queryParameters = queryParameters.set('issue_start_date_lte', <any>issue_start_date_lte);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_dept_id !== undefined && emp_dept_id !== null) {
            queryParameters = queryParameters.set('emp_dept_id', <any>emp_dept_id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (work_div_code !== undefined && work_div_code !== null) {
            queryParameters = queryParameters.set('work_div_code', <any>work_div_code);
        }
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (proc_status !== undefined && proc_status !== null) {
            queryParameters = queryParameters.set('proc_status', <any>proc_status);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/emp/works/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 근무정보 기준검색
     * 모든 근무정보를 검색조건에 따라 가지고 온다.
     * @param issue_start_date_gte 발령난날 형식 2018-09-10
     * @param issue_start_date_lte 발령난날 형식 2018-09-10
     * @param emp_id 직원아이디
     * @param emp_dept_id 부서아이디
     * @param name 부서이름 또는 직원이름
     * @param work_div_code 근무구분코드
     * @param code 하위 코드
     * @param proc_status 발령처리상태
     * @param emp_job_rank 직급코드입력 e.g.)job_ran_0
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empWorksGet(issue_start_date_gte: string, issue_start_date_lte: string, emp_id?: string, emp_dept_id?: string, name?: string, work_div_code?: string, code?: string, proc_status?: string, emp_job_rank?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsWork>>;
    public empWorksGet(issue_start_date_gte: string, issue_start_date_lte: string, emp_id?: string, emp_dept_id?: string, name?: string, work_div_code?: string, code?: string, proc_status?: string, emp_job_rank?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsWork>>>;
    public empWorksGet(issue_start_date_gte: string, issue_start_date_lte: string, emp_id?: string, emp_dept_id?: string, name?: string, work_div_code?: string, code?: string, proc_status?: string, emp_job_rank?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsWork>>>;
    public empWorksGet(issue_start_date_gte: string, issue_start_date_lte: string, emp_id?: string, emp_dept_id?: string, name?: string, work_div_code?: string, code?: string, proc_status?: string, emp_job_rank?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (issue_start_date_gte === null || issue_start_date_gte === undefined) {
            throw new Error('Required parameter issue_start_date_gte was null or undefined when calling empWorksGet.');
        }
        if (issue_start_date_lte === null || issue_start_date_lte === undefined) {
            throw new Error('Required parameter issue_start_date_lte was null or undefined when calling empWorksGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issue_start_date_gte !== undefined && issue_start_date_gte !== null) {
            queryParameters = queryParameters.set('issue_start_date_gte', <any>issue_start_date_gte);
        }
        if (issue_start_date_lte !== undefined && issue_start_date_lte !== null) {
            queryParameters = queryParameters.set('issue_start_date_lte', <any>issue_start_date_lte);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', <any>emp_id);
        }
        if (emp_dept_id !== undefined && emp_dept_id !== null) {
            queryParameters = queryParameters.set('emp_dept_id', <any>emp_dept_id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (work_div_code !== undefined && work_div_code !== null) {
            queryParameters = queryParameters.set('work_div_code', <any>work_div_code);
        }
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (proc_status !== undefined && proc_status !== null) {
            queryParameters = queryParameters.set('proc_status', <any>proc_status);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', <any>emp_job_rank);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsWork>>(`${this.basePath}/emp/works`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
