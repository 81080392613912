import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { EsLogisticsEmpDetailStatistics } from 'src/app/restapi/be-esquery/model/esLogisticsEmpDetailStatistics';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-logistics-emps-detail',
  templateUrl: './logistics-emps-detail.component.html',
  styleUrls: ['./logistics-emps-detail.component.scss']
})
export class LogisticsEmpsDetailComponent implements OnInit, OnDestroy, OnChanges {

  @Input() dataList: Array<EsLogisticsEmpDetailStatistics>;
  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<EsLogisticsEmpDetailStatistics> = new MatTableDataSource([]);
  displayedColumns = ['date', 'emp_name', 'logistics_div', 'give_unit_count', 'count'];

  constructor() { }

  ngOnInit() {
    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataList && changes.dataList.currentValue != null) {
      setTimeout(() => {
        this.initPage(this.sort);
      });
    }
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      sorted = this.dataList.sort((a: EsLogisticsEmpDetailStatistics, b: EsLogisticsEmpDetailStatistics) => {
        let result = 0;
        if (a[sort.active] == undefined) a[sort.active] = "";
        if (b[sort.active] == undefined) b[sort.active] = "";

        if (a[sort.active] < b[sort.active]) result = -1;
        else if (a[sort.active] > b[sort.active]) result = 1;
        return result * (sort.direction === 'asc' ? 1 : -1);
      });
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsLogisticsEmpDetailStatistics>(this.dataList);
  }

}