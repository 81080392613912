import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS, MatDatepicker } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EsGivingsService, EsGivingRegionStatistics, EsGivingCount } from 'src/app/restapi/be-esquery';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

@Component({
  selector: 'app-givings-region',
  templateUrl: './givings-region.component.html',
  styleUrls: ['./givings-region.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ]
})
export class GivingsRegionComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  dataList: Array<EsGivingRegionStatistics> = [];
  dataSource: MatTableDataSource<EsGivingRegionStatistics> = new MatTableDataSource([]);
  displayedColumns = ['state', 'city', 'give_route', 'give_count', 'give_unit_count',
    '01c', '01uc', '02c', '02uc', '03c', '03uc', '04c', '04uc', '05c', '05uc', '06c', '06uc',
    '07c', '07uc', '08c', '08uc', '09c', '09uc', '10c', '10uc', '11c', '11uc', '12c', '12uc'];

  constructor(
    private fb: FormBuilder,
    private esGivingsService: EsGivingsService
  ) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      year: moment(),
      areas: ''
    });
  }

  get fc_year() { return this.form.get('year'); }
  get fc_areas() { return this.form.get('areas'); }

  chosenYearHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_year.setValue(normalizedMonth);
    this.search();
    
    datepicker.close();
  }

  convertMonthInfo(month: string, type: string, data: EsGivingRegionStatistics) {
    if (data.monthly_info) {
      const givingCount: EsGivingCount = data.monthly_info.find(x => x.date.split("-")[1] == month);
      if (givingCount) {
        if (type == 'give_count') return givingCount.give_count;
        else if (type == 'give_unit_count') return givingCount.give_unit_count;
      }
    }

    return '';
  }

  search() {
    this.givingsRegionGet();
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      if (sort.active == '01c' || sort.active == '02c' || sort.active == '03c' || sort.active == '04c' || sort.active == '05c' || sort.active == '06c' ||
        sort.active == '07c' || sort.active == '08c' || sort.active == '09c' || sort.active == '10c' || sort.active == '11c' || sort.active == '12c' ||
        sort.active == '01uc' || sort.active == '02uc' || sort.active == '03uc' || sort.active == '04uc' || sort.active == '05uc' || sort.active == '06uc' ||
        sort.active == '07uc' || sort.active == '08uc' || sort.active == '09uc' || sort.active == '10uc' || sort.active == '11uc' || sort.active == '12uc') {
        sorted = this.dataList.sort((a: EsGivingRegionStatistics, b: EsGivingRegionStatistics) => {
          let result = 0;
          let a_data: number = 0;
          let b_data: number = 0;

          if (sort.active.includes('uc')) {
            a_data = Number(this.convertMonthInfo(sort.active.substr(0, 2), 'give_unit_count', a));
            b_data = Number(this.convertMonthInfo(sort.active.substr(0, 2), 'give_unit_count', b));
          } else {
            a_data = Number(this.convertMonthInfo(sort.active.substr(0, 2), 'give_count', a));
            b_data = Number(this.convertMonthInfo(sort.active.substr(0, 2), 'give_count', b));
          }

          if (a_data < b_data) result = -1;
          else if (a_data > b_data) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
      else {
        sorted = this.dataList.sort((a: EsGivingRegionStatistics, b: EsGivingRegionStatistics) => {
          let result = 0;
          if (a[sort.active] == undefined) a[sort.active] = "";
          if (b[sort.active] == undefined) b[sort.active] = "";

          if (a[sort.active] < b[sort.active]) result = -1;
          else if (a[sort.active] > b[sort.active]) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsGivingRegionStatistics>(this.dataList);
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '지역별 통계');
    XLSX.writeFile(wb, '지역별 통계.xlsx');
  }

  givingsRegionGet() {
    this.dataList = [];

    this.esGivingsService.givingsRegionGet(
      dateFormat_Year(this.fc_year.value),
      dateFormat_Year(this.fc_year.value),
      this.fc_areas.value
    ).pipe(
      take(1)
    ).subscribe((res: Array<EsGivingRegionStatistics>) => {
      this.dataList = res;
      this.initPage(this.sort);
    },
      (err) => {
        console.error(err);
        this.initPage(this.sort);
      }
    );
  }

}