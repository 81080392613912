import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-angel',
  templateUrl: './angel.component.html',
  styleUrls: ['./angel.component.scss']
})
export class AngelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
