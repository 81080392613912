/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { DecryptedPrivateNo } from '../model/decryptedPrivateNo';
import { Failure } from '../model/failure';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PrivateNumberService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 복호화된 주민번호 조회
     * 
     * @param resource_type 리소스타입
     * @param resource_id 리소스 아이디 emp_id or joiner_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public privateNumberGet(resource_type: 'hrm' | 'joiner', resource_id: string, observe?: 'body', reportProgress?: boolean): Observable<DecryptedPrivateNo>;
    public privateNumberGet(resource_type: 'hrm' | 'joiner', resource_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DecryptedPrivateNo>>;
    public privateNumberGet(resource_type: 'hrm' | 'joiner', resource_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DecryptedPrivateNo>>;
    public privateNumberGet(resource_type: 'hrm' | 'joiner', resource_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resource_type === null || resource_type === undefined) {
            throw new Error('Required parameter resource_type was null or undefined when calling privateNumberGet.');
        }
        if (resource_id === null || resource_id === undefined) {
            throw new Error('Required parameter resource_id was null or undefined when calling privateNumberGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (resource_type !== undefined && resource_type !== null) {
            queryParameters = queryParameters.set('resource_type', <any>resource_type);
        }
        if (resource_id !== undefined && resource_id !== null) {
            queryParameters = queryParameters.set('resource_id', <any>resource_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<DecryptedPrivateNo>(`${this.basePath}/private/number`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 주민번호 변경
     * 
     * @param resource_type 리소스타입
     * @param resource_id 리소스 아이디 emp_id or joiner_id
     * @param private_no 업데이트 대상 식별번호
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public privateNumberPut(resource_type: 'hrm' | 'joiner', resource_id: string, private_no: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public privateNumberPut(resource_type: 'hrm' | 'joiner', resource_id: string, private_no: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public privateNumberPut(resource_type: 'hrm' | 'joiner', resource_id: string, private_no: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public privateNumberPut(resource_type: 'hrm' | 'joiner', resource_id: string, private_no: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resource_type === null || resource_type === undefined) {
            throw new Error('Required parameter resource_type was null or undefined when calling privateNumberPut.');
        }
        if (resource_id === null || resource_id === undefined) {
            throw new Error('Required parameter resource_id was null or undefined when calling privateNumberPut.');
        }
        if (private_no === null || private_no === undefined) {
            throw new Error('Required parameter private_no was null or undefined when calling privateNumberPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (resource_type !== undefined && resource_type !== null) {
            queryParameters = queryParameters.set('resource_type', <any>resource_type);
        }
        if (resource_id !== undefined && resource_id !== null) {
            queryParameters = queryParameters.set('resource_id', <any>resource_id);
        }
        if (private_no !== undefined && private_no !== null) {
            queryParameters = queryParameters.set('private_no', <any>private_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.put<any>(`${this.basePath}/private/number`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
