<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style">
      <label>기간</label>
      <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()"
        (dateChange)="chosenMonthHandler($event.target.value, end)" formControlName="date" readonly>
      <mat-datepicker #end startView="year" (monthSelected)="chosenMonthHandler($event, end)"></mat-datepicker>
    </div>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div class="statistics-mat-table" #TABLE>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>년월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.date}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_route">
          <mat-header-cell *matHeaderCellDef mat-sort-header>접수구분</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_route}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_apply_route">
          <mat-header-cell *matHeaderCellDef mat-sort-header>접수경로</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_apply_route}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef mat-sort-header>지역</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.state}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_count">
          <mat-header-cell *matHeaderCellDef mat-sort-header>기부건수</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_count | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="의류">
          <mat-header-cell *matHeaderCellDef mat-sort-header>의류(점)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertCategories('의류',  element?.categories) | currencyMask}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="잡화">
          <mat-header-cell *matHeaderCellDef mat-sort-header>잡화(점)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertCategories('잡화', element?.categories) | currencyMask}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="도서음반">
          <mat-header-cell *matHeaderCellDef mat-sort-header>도서음반(점)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertCategories('도서음반', element?.categories) | currencyMask}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="가전">
          <mat-header-cell *matHeaderCellDef mat-sort-header>가전(점)</mat-header-cell>
          <mat-cell *matCellDef="let element">{{convertCategories('가전', element?.categories) | currencyMask}}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>