/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다. 
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Areas } from '../model/areas';
import { CallLimit } from '../model/callLimit';
import { CallReceptionArea } from '../model/callReceptionArea';
import { CallReceptionCallLimit } from '../model/callReceptionCallLimit';
import { CallReceptionMappingAddr } from '../model/callReceptionMappingAddr';
import { EncodedFile } from '../model/encodedFile';
import { Failure } from '../model/failure';
import { LimitInit } from '../model/limitInit';
import { MappingAddr } from '../model/mappingAddr';
import { ReceptionCallLimit } from '../model/receptionCallLimit';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AreaService {

    protected basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 특정 권역 정보 삭제
     * 
     * @param area_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaAreaIdDelete(area_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public areaAreaIdDelete(area_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public areaAreaIdDelete(area_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public areaAreaIdDelete(area_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling areaAreaIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/area/${encodeURIComponent(String(area_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 특정 권역 조회
     * 
     * @param area_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaAreaIdGet(area_id: string, observe?: 'body', reportProgress?: boolean): Observable<Areas>;
    public areaAreaIdGet(area_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Areas>>;
    public areaAreaIdGet(area_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Areas>>;
    public areaAreaIdGet(area_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling areaAreaIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Areas>(`${this.basePath}/area/${encodeURIComponent(String(area_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 특정 권역 정보 수정
     * 
     * @param area_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaAreaIdPut(area_id: string, body: Areas, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public areaAreaIdPut(area_id: string, body: Areas, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public areaAreaIdPut(area_id: string, body: Areas, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public areaAreaIdPut(area_id: string, body: Areas, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling areaAreaIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling areaAreaIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/area/${encodeURIComponent(String(area_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 특정 권역 조회
     * 
     * @param area_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaAreaNameGet(area_name: string, observe?: 'body', reportProgress?: boolean): Observable<Areas>;
    public areaAreaNameGet(area_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Areas>>;
    public areaAreaNameGet(area_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Areas>>;
    public areaAreaNameGet(area_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_name === null || area_name === undefined) {
            throw new Error('Required parameter area_name was null or undefined when calling areaAreaNameGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Areas>(`${this.basePath}/area/area_name/${encodeURIComponent(String(area_name))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 권역 전체 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Areas>>;
    public areaGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Areas>>>;
    public areaGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Areas>>>;
    public areaGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Areas>>(`${this.basePath}/area`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 권역 등록
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaPost(body: Areas, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public areaPost(body: Areas, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public areaPost(body: Areas, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public areaPost(body: Areas, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling areaPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/area`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 특정 권역 콜 제한 조회
     * 
     * @param area_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callLimitAreaIdGet(area_id: string, observe?: 'body', reportProgress?: boolean): Observable<CallLimit>;
    public callLimitAreaIdGet(area_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallLimit>>;
    public callLimitAreaIdGet(area_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallLimit>>;
    public callLimitAreaIdGet(area_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling callLimitAreaIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CallLimit>(`${this.basePath}/call_limit/${encodeURIComponent(String(area_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 특정 권역 콜 제한 수정
     * 
     * @param area_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callLimitAreaIdPut(area_id: string, body: CallLimit, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public callLimitAreaIdPut(area_id: string, body: CallLimit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public callLimitAreaIdPut(area_id: string, body: CallLimit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public callLimitAreaIdPut(area_id: string, body: CallLimit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling callLimitAreaIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling callLimitAreaIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/call_limit/${encodeURIComponent(String(area_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 권역 정보 및 콜 접수 일정 조회
     * 
     * @param area_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callReceptionAreaAreaIdGet(area_id: string, observe?: 'body', reportProgress?: boolean): Observable<CallReceptionArea>;
    public callReceptionAreaAreaIdGet(area_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallReceptionArea>>;
    public callReceptionAreaAreaIdGet(area_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallReceptionArea>>;
    public callReceptionAreaAreaIdGet(area_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling callReceptionAreaAreaIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CallReceptionArea>(`${this.basePath}/call_reception/area/${encodeURIComponent(String(area_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 콜 제한에 상관없이 콜 접수
     * 
     * @param area_id 권역 SK
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callReceptionCallLimitAreaIdCoercionPut(area_id: string, body: ReceptionCallLimit, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public callReceptionCallLimitAreaIdCoercionPut(area_id: string, body: ReceptionCallLimit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public callReceptionCallLimitAreaIdCoercionPut(area_id: string, body: ReceptionCallLimit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public callReceptionCallLimitAreaIdCoercionPut(area_id: string, body: ReceptionCallLimit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling callReceptionCallLimitAreaIdCoercionPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling callReceptionCallLimitAreaIdCoercionPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/call_reception/call_limit/${encodeURIComponent(String(area_id))}/coercion`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 수거 접수 또는 취소 시 수거 건수 설정
     * 
     * @param area_id 권역 SK
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callReceptionCallLimitAreaIdPut(area_id: string, body: ReceptionCallLimit, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public callReceptionCallLimitAreaIdPut(area_id: string, body: ReceptionCallLimit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public callReceptionCallLimitAreaIdPut(area_id: string, body: ReceptionCallLimit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public callReceptionCallLimitAreaIdPut(area_id: string, body: ReceptionCallLimit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (area_id === null || area_id === undefined) {
            throw new Error('Required parameter area_id was null or undefined when calling callReceptionCallLimitAreaIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling callReceptionCallLimitAreaIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/call_reception/call_limit/${encodeURIComponent(String(area_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 콜 접수시 특정 날짜 콜 제한 정보 조회
     * 
     * @param collect_type 수거 타입
     * @param collect_date 수거 날짜
     * @param collect_target_id 권역 아이디 또는 되살림터 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callReceptionCallLimitStatusGet(collect_type: '수거' | '택배', collect_date: string, collect_target_id: string, observe?: 'body', reportProgress?: boolean): Observable<CallReceptionCallLimit>;
    public callReceptionCallLimitStatusGet(collect_type: '수거' | '택배', collect_date: string, collect_target_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallReceptionCallLimit>>;
    public callReceptionCallLimitStatusGet(collect_type: '수거' | '택배', collect_date: string, collect_target_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallReceptionCallLimit>>;
    public callReceptionCallLimitStatusGet(collect_type: '수거' | '택배', collect_date: string, collect_target_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (collect_type === null || collect_type === undefined) {
            throw new Error('Required parameter collect_type was null or undefined when calling callReceptionCallLimitStatusGet.');
        }
        if (collect_date === null || collect_date === undefined) {
            throw new Error('Required parameter collect_date was null or undefined when calling callReceptionCallLimitStatusGet.');
        }
        if (collect_target_id === null || collect_target_id === undefined) {
            throw new Error('Required parameter collect_target_id was null or undefined when calling callReceptionCallLimitStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collect_type !== undefined && collect_type !== null) {
            queryParameters = queryParameters.set('collect_type', <any>collect_type);
        }
        if (collect_date !== undefined && collect_date !== null) {
            queryParameters = queryParameters.set('collect_date', <any>collect_date);
        }
        if (collect_target_id !== undefined && collect_target_id !== null) {
            queryParameters = queryParameters.set('collect_target_id', <any>collect_target_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CallReceptionCallLimit>(`${this.basePath}/call_reception/call_limit/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 법정동 정보 및 콜 접수 일정 조회
     * 
     * @param bcode 
     * @param ch 조회 채널
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callReceptionMappingAddrBcodeGet(bcode: string, ch?: 'W', observe?: 'body', reportProgress?: boolean): Observable<CallReceptionMappingAddr>;
    public callReceptionMappingAddrBcodeGet(bcode: string, ch?: 'W', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallReceptionMappingAddr>>;
    public callReceptionMappingAddrBcodeGet(bcode: string, ch?: 'W', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallReceptionMappingAddr>>;
    public callReceptionMappingAddrBcodeGet(bcode: string, ch?: 'W', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (bcode === null || bcode === undefined) {
            throw new Error('Required parameter bcode was null or undefined when calling callReceptionMappingAddrBcodeGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ch !== undefined && ch !== null) {
            queryParameters = queryParameters.set('ch', <any>ch);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CallReceptionMappingAddr>(`${this.basePath}/call_reception/mapping_addr/${encodeURIComponent(String(bcode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 콜 접수 초기값 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public limitInitGet(observe?: 'body', reportProgress?: boolean): Observable<LimitInit>;
    public limitInitGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LimitInit>>;
    public limitInitGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LimitInit>>;
    public limitInitGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LimitInit>(`${this.basePath}/limit_init`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 콜 접수 초기값 설정
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public limitInitPut(body: LimitInit, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public limitInitPut(body: LimitInit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public limitInitPut(body: LimitInit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public limitInitPut(body: LimitInit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling limitInitPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/limit_init`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 법정동 매핑 정보 수정
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mappingAddrAreaPut(body: MappingAddr, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mappingAddrAreaPut(body: MappingAddr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mappingAddrAreaPut(body: MappingAddr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mappingAddrAreaPut(body: MappingAddr, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling mappingAddrAreaPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/mapping_addr/area`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 특정 법정동 정보 조회
     * 
     * @param bcode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mappingAddrBcodeGet(bcode: string, observe?: 'body', reportProgress?: boolean): Observable<MappingAddr>;
    public mappingAddrBcodeGet(bcode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MappingAddr>>;
    public mappingAddrBcodeGet(bcode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MappingAddr>>;
    public mappingAddrBcodeGet(bcode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (bcode === null || bcode === undefined) {
            throw new Error('Required parameter bcode was null or undefined when calling mappingAddrBcodeGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MappingAddr>(`${this.basePath}/mapping_addr/${encodeURIComponent(String(bcode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 법정동 정보 전체 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mappingAddrGet(observe?: 'body', reportProgress?: boolean): Observable<Array<MappingAddr>>;
    public mappingAddrGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MappingAddr>>>;
    public mappingAddrGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MappingAddr>>>;
    public mappingAddrGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MappingAddr>>(`${this.basePath}/mapping_addr`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 법정동 정보 일괄 등록 작업(기초작업)
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mappingAddrInitDataPost(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mappingAddrInitDataPost(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mappingAddrInitDataPost(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mappingAddrInitDataPost(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling mappingAddrInitDataPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/mapping_addr/init_data`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 법정동 정보 일괄 등록 작업
     * 
     * @param up_file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mappingAddrPost(up_file: EncodedFile, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mappingAddrPost(up_file: EncodedFile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mappingAddrPost(up_file: EncodedFile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mappingAddrPost(up_file: EncodedFile, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (up_file === null || up_file === undefined) {
            throw new Error('Required parameter up_file was null or undefined when calling mappingAddrPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/mapping_addr`,
            up_file,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매핑되지 않은 법정동 전체 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public noMappingAddrGet(observe?: 'body', reportProgress?: boolean): Observable<Array<MappingAddr>>;
    public noMappingAddrGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MappingAddr>>>;
    public noMappingAddrGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MappingAddr>>>;
    public noMappingAddrGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MappingAddr>>(`${this.basePath}/no_mapping_addr`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
