import { Component, OnInit } from '@angular/core';
import { MAT_DATE_FORMATS, MatDatepicker, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EsGivingsService, EsDeptInfo } from 'src/app/restapi/be-esquery';
import { take } from 'rxjs/operators';
import { dateFormat_Month, MONTH_FORMATS } from 'src/app/share/utils/common-utils';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { AlertDialogComponent, ConfirmDialogComponent } from 'fecommon';

const moment = _moment;

@Component({
  selector: 'app-raw-data-joiner-givings',
  templateUrl: './raw-data-joiner-givings.component.html',
  styleUrls: ['./raw-data-joiner-givings.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
  ]
})
export class RawDataJoinerGivingsComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private esGivingsService: EsGivingsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      date: moment().add(-1, 'month'),
      dept_info: null
    });
  }

  get fc_date() { return this.form.get('date'); }
  get fc_deptInfo() { return this.form.get('dept_info'); }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_date.setValue(normalizedMonth);
    datepicker.close();
  }

  selectDept(value: EsDeptInfo) {
    if (!value || value == null) {
      this.fc_deptInfo.setValue(null);
    } else {
      this.fc_deptInfo.setValue(value);
    }
  }

  download() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '기부자 기부건별 데이터',
        description: '다운로드 하시겠습니까?',
        okText: '확인',
        cancelText: '취소'
      }
    }).afterClosed().pipe(take(1)).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.givingsExcelGet();
      }
    });
  }

  givingsExcelGet() {
    this.esGivingsService.givingsExcelGet(
      dateFormat_Month(this.fc_date.value),
      dateFormat_Month(this.fc_date.value),
      this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null
    ).pipe(
      take(1)
    ).subscribe((res: any) => {
      this.dialog.open(AlertDialogComponent, {
        data: {
          message: '이메일로 전송될 예정입니다.'
        }
      });
    },
      (err) => { console.error(err); }
    );
  }

}