import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DatePipe } from '@angular/common';
import { DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { CodeService, StatisticsService } from 'src/app/restapi/be-angel';
var ɵ0 = DEFAULT_FORMATS;
var AngelActivityStatusComponent = /** @class */ (function () {
    function AngelActivityStatusComponent(fb, statisticsService, datePipe, codeService) {
        this.fb = fb;
        this.statisticsService = statisticsService;
        this.datePipe = datePipe;
        this.codeService = codeService;
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['department_name', 'team_name', 'location_name', 'active', 'stop', 'end'];
        this.page = 1;
        this.pageSize = 10;
    }
    AngelActivityStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.codeService.angelCodeGet("자원활동", "활동구분").subscribe(function (res) {
            _this.act_div_list = res;
            console.info(_this.act_div_list);
        });
        this.codeService.angelCodeGet("자원활동", "활동주기").subscribe(function (res) {
            _this.act_period_list = res;
            console.info(_this.act_period_list);
        });
        this.codeService.angelCodeGet("자원활동", "직업").subscribe(function (res) {
            _this.job_list = res;
            console.info(_this.job_list);
        });
    };
    AngelActivityStatusComponent.prototype.ngOnDestroy = function () { };
    AngelActivityStatusComponent.prototype.a = function (a, b) {
        console.info(a);
        console.info(b);
    };
    AngelActivityStatusComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            act_div: [''],
            act_period: [''],
            job: [''],
            gender: [''],
            search_act_dept_name: ['']
        });
    };
    Object.defineProperty(AngelActivityStatusComponent.prototype, "fc_actDiv", {
        get: function () { return this.form.get('act_div'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelActivityStatusComponent.prototype, "fc_actPeriod", {
        get: function () { return this.form.get('act_period'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelActivityStatusComponent.prototype, "fc_job", {
        get: function () { return this.form.get('job'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelActivityStatusComponent.prototype, "fc_gender", {
        get: function () { return this.form.get('gender'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelActivityStatusComponent.prototype, "fc_deptName", {
        get: function () { return this.form.get('search_act_dept_name'); },
        enumerable: true,
        configurable: true
    });
    AngelActivityStatusComponent.prototype.search = function () {
        var _this = this;
        this.statisticsService.statisticsActiveStateGet(this.fc_deptName.value, this.fc_actDiv.value, this.fc_actPeriod.value, this.fc_job.value, this.fc_gender.value).subscribe({
            next: function (res) {
                _this.dataSource.data = res;
            },
            complete: function () {
                _this.sort.sortChange.emit({ active: _this.sort.active, direction: _this.sort.direction });
            }
        });
    };
    AngelActivityStatusComponent.prototype.onClickExcelExport = function () {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(this.convertDatasource());
        var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, '활동상태현황');
        XLSX.writeFile(wb, "\uD65C\uB3D9\uC0C1\uD0DC\uD604\uD669_" + this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + ".xlsx");
    };
    AngelActivityStatusComponent.prototype.convertDatasource = function () {
        var xlsxDatasource = [];
        this.dataSource.data.forEach(function (item) {
            xlsxDatasource.push({
                '국': item.department_name,
                '팀': item.team_name,
                '매장': item.location_name,
                '활동중': item.active,
                // '활동대기': item.wait,
                '일시중단': item.stop,
                '활동종료': item.end
            });
        });
        return xlsxDatasource;
    };
    return AngelActivityStatusComponent;
}());
export { AngelActivityStatusComponent };
export { ɵ0 };
