/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsAnonymousDonorService = /** @class */ (function () {
    function EsAnonymousDonorService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsAnonymousDonorService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsAnonymousDonorService.prototype.anonymousDonorGet = function (gte_give_confirm_date, lte_give_confirm_date, give_confirm_emp_dept_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_give_confirm_date === null || gte_give_confirm_date === undefined) {
            throw new Error('Required parameter gte_give_confirm_date was null or undefined when calling anonymousDonorGet.');
        }
        if (lte_give_confirm_date === null || lte_give_confirm_date === undefined) {
            throw new Error('Required parameter lte_give_confirm_date was null or undefined when calling anonymousDonorGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_give_confirm_date !== undefined && gte_give_confirm_date !== null) {
            queryParameters = queryParameters.set('gte_give_confirm_date', gte_give_confirm_date);
        }
        if (lte_give_confirm_date !== undefined && lte_give_confirm_date !== null) {
            queryParameters = queryParameters.set('lte_give_confirm_date', lte_give_confirm_date);
        }
        if (give_confirm_emp_dept_name !== undefined && give_confirm_emp_dept_name !== null) {
            queryParameters = queryParameters.set('give_confirm_emp_dept_name', give_confirm_emp_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/anonymous_donor", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsAnonymousDonorService;
}());
export { EsAnonymousDonorService };
