import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS } from '@angular/material';
import { DEFAULT_FORMATS, dateFormat } from 'src/app/share/utils/common-utils';
import { EsShopsService, EsShopGivingProductionStatistics, EsShop } from 'src/app/restapi/be-esquery';
import { EsGivingStatisticsCategories } from 'src/app/restapi/be-esquery/model/esGivingStatisticsCategories';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-shop-givings-production',
  templateUrl: './shop-givings-production.component.html',
  styleUrls: ['./shop-givings-production.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS }
  ]
})
export class ShopGivingsProductionComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  dataList: Array<EsShopGivingProductionStatistics> = [];
  dataSource: MatTableDataSource<EsShopGivingProductionStatistics> = new MatTableDataSource([]);
  displayedColumns = ['date', 'department_name', 'team_name', 'location_name', 'production_count', '의류', '잡화', '도서음반', '가전'];
  minDate = moment().add(-3, 'month');
  maxDate = moment();

  constructor(
    private fb: FormBuilder,
    private esShopsService: EsShopsService
  ) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      gte_date: this.minDate,
      lte_date: this.maxDate,
      shop: null
    });
  }

  get fc_gteDate() { return this.form.get('gte_date'); }
  get fc_lteDate() { return this.form.get('lte_date'); }
  get fc_shop() { return this.form.get('shop'); }

  onChangeStartDate() {
    const limitDate = moment(this.fc_gteDate.value).add(3, 'month');
    this.minDate = this.fc_gteDate.value;

    if (this.fc_gteDate.value > this.fc_lteDate.value) {
      this.fc_lteDate.setValue(this.fc_gteDate.value);
    }

    if (limitDate < this.fc_lteDate.value) {
      this.fc_lteDate.setValue(limitDate);
    }
  }

  onChangeEndDate() {
    const limitDate = moment(this.fc_lteDate.value).add(-3, 'month');

    if (limitDate > this.fc_gteDate.value) {
      this.fc_gteDate.setValue(limitDate);
      this.minDate = limitDate;
    }
  }

  selectShop(value: EsShop) {
    if (!value || value == null) {
      this.fc_shop.setValue(null);
      return;
    }

    this.fc_shop.setValue(value);
    this.search();
  }

  convertCategories(category: string, categories: Array<EsGivingStatisticsCategories>) {
    if (categories.find(x => x.category == category)) {
      return categories.find(x => x.category == category).sum;
    }

    return "";
  }

  search() {
    this.shopGivings();
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      if (sort.active == '의류' || sort.active == '잡화' || sort.active == '도서음반' || sort.active == '가전') {
        sorted = this.dataList.sort((a: EsShopGivingProductionStatistics, b: EsShopGivingProductionStatistics) => {
          let result = 0;
          let a_cate_sum: number = Number(this.convertCategories(sort.active, a.categories));
          let b_cate_sum: number = Number(this.convertCategories(sort.active, b.categories));

          if (a_cate_sum < b_cate_sum) result = -1;
          else if (a_cate_sum > b_cate_sum) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      } else {
        sorted = this.dataList.sort((a: EsShopGivingProductionStatistics, b: EsShopGivingProductionStatistics) => {
          let result = 0;
          if (a[sort.active] == undefined) a[sort.active] = "";
          if (b[sort.active] == undefined) b[sort.active] = "";

          if (a[sort.active] < b[sort.active]) result = -1;
          else if (a[sort.active] > b[sort.active]) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsShopGivingProductionStatistics>(this.dataList);
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '매장별 점생산 통계');
    XLSX.writeFile(wb, '매장별 점생산 통계.xlsx');
  }

  shopGivings() {
    this.dataList = [];

    this.esShopsService.shopsGivingsProductionGet(
      dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value),
      this.fc_shop.value != null ? this.fc_shop.value.shop_id : null
    ).pipe(
      take(1)
    ).subscribe((res: Array<EsShopGivingProductionStatistics>) => {
      this.dataList = res;
      this.initPage(this.sort);
    },
      (err) => {
        console.error(err);
        this.initPage(this.sort);
      }
    );
  }

}