import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material';
import { CurrencyMaskPipe } from './pipes/currency-mask.pipe';
import { DeptAutoCompleteComponent } from './components/dept-auto-complete/dept-auto-complete.component';
import { ShopAutoCompleteComponent } from './components/shop-auto-complete/shop-auto-complete.component';
import { AreasAutoCompleteComponent } from './components/areas-auto-complete/areas-auto-complete.component';
import { DateRangePickerDirective } from './components/directives/date-range-picker.directive';
import { AngelDeptAutoCompleteComponent } from './components/angel-dept-auto-complete/angel-dept-auto-complete.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule
    ],
    declarations: [
        DeptAutoCompleteComponent,
        ShopAutoCompleteComponent,
        AreasAutoCompleteComponent,
        CurrencyMaskPipe,
        DateRangePickerDirective,
        AngelDeptAutoCompleteComponent
    ],
    exports: [
        DeptAutoCompleteComponent,
        AngelDeptAutoCompleteComponent,
        ShopAutoCompleteComponent,
        AreasAutoCompleteComponent,
        CurrencyMaskPipe,
        DateRangePickerDirective
    ]
})
export class ShareModule {
}