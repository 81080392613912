import { OnInit, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
var LogisticsDeptsDivComponent = /** @class */ (function () {
    function LogisticsDeptsDivComponent() {
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['logistics_div', 'count', 'give_unit_count'];
    }
    LogisticsDeptsDivComponent.prototype.ngOnInit = function () {
    };
    LogisticsDeptsDivComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.dataList && changes.dataList.currentValue != null) {
            setTimeout(function () {
                _this.convertData();
            });
        }
    };
    LogisticsDeptsDivComponent.prototype.convertData = function () {
        var totalCount = this.dataList.map(function (x) { return x.count; }).filter(function (x) { return x; }).reduce(function (pValue, cValue) { return pValue + cValue; }, 0);
        var totalGiveUnitCount = this.dataList.map(function (x) { return x.give_unit_count; }).filter(function (x) { return x; }).reduce(function (pValue, cValue) { return pValue + cValue; }, 0);
        this.dataList.push({
            logistics_div: '합계',
            count: totalCount,
            give_unit_count: totalGiveUnitCount
        });
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    return LogisticsDeptsDivComponent;
}());
export { LogisticsDeptsDivComponent };
