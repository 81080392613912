/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsAvgSales } from '../model/esAvgSales';
import { EsDownloadUrl } from '../model/esDownloadUrl';
import { EsEventSales } from '../model/esEventSales';
import { EsFailure } from '../model/esFailure';
import { EsRateSales } from '../model/esRateSales';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsSalesService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 기증물품 평균판매단가를 조회한다(현시점1년간)
     * 기증물품판매단가
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dgPriceAvgGet(observe?: 'body', reportProgress?: boolean): Observable<Array<EsAvgSales>>;
    public dgPriceAvgGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsAvgSales>>>;
    public dgPriceAvgGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsAvgSales>>>;
    public dgPriceAvgGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsAvgSales>>(`${this.basePath}/sales/avg_price`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부서장 이메일주소 검색
     * 부서장 이메일주소 검색
     * @param dept_id 부서코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailAddrGet(dept_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailAddrGet(dept_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailAddrGet(dept_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailAddrGet(dept_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling emailAddrGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/sales/get_email_addr`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 행사로 판매된 매출정보를 조회한다
     * 행사매출정보검색
     * @param dept_id 부서아이디(,분리기호)
     * @param gte_date 시작일자(YYYY-MM-DD)
     * @param lte_date 종료일자(YYYY-MM-DD)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salesEventGet(dept_id: string, gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsEventSales>>;
    public salesEventGet(dept_id: string, gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEventSales>>>;
    public salesEventGet(dept_id: string, gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEventSales>>>;
    public salesEventGet(dept_id: string, gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling salesEventGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling salesEventGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling salesEventGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEventSales>>(`${this.basePath}/sales/event`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 공급대비 판매율(매장용)
     * 공급대비 판매율(매장용)
     * @param gte_date 검색시작일
     * @param lte_date 검색종료일
     * @param dept_name 부서명(Like)
     * @param good_category 물품구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salesRateBySupplyExcelGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'body', reportProgress?: boolean): Observable<EsDownloadUrl>;
    public salesRateBySupplyExcelGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsDownloadUrl>>;
    public salesRateBySupplyExcelGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsDownloadUrl>>;
    public salesRateBySupplyExcelGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling salesRateBySupplyExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling salesRateBySupplyExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_name !== undefined && dept_name !== null) {
            queryParameters = queryParameters.set('dept_name', <any>dept_name);
        }
        if (good_category !== undefined && good_category !== null) {
            queryParameters = queryParameters.set('good_category', <any>good_category);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsDownloadUrl>(`${this.basePath}/sales/sales_rate_by_supply_excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 공급대비 판매율(매장용)
     * 공급대비 판매율(매장용)
     * @param gte_date 검색시작일
     * @param lte_date 검색종료일
     * @param dept_name 부서명(Like)
     * @param good_category 물품구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salesRateBySupplyGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'body', reportProgress?: boolean): Observable<Array<EsRateSales>>;
    public salesRateBySupplyGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsRateSales>>>;
    public salesRateBySupplyGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsRateSales>>>;
    public salesRateBySupplyGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling salesRateBySupplyGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling salesRateBySupplyGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_name !== undefined && dept_name !== null) {
            queryParameters = queryParameters.set('dept_name', <any>dept_name);
        }
        if (good_category !== undefined && good_category !== null) {
            queryParameters = queryParameters.set('good_category', <any>good_category);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsRateSales>>(`${this.basePath}/sales/sales_rate_by_supply`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 판매대비 공급율(되살림터용)
     * 판매대비 공급율(되살림터용)
     * @param gte_date 검색시작일
     * @param lte_date 검색종료일
     * @param dept_name 부서명(Like)
     * @param good_category 물품구분
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supplyRateBySalesGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'body', reportProgress?: boolean): Observable<Array<EsRateSales>>;
    public supplyRateBySalesGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsRateSales>>>;
    public supplyRateBySalesGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsRateSales>>>;
    public supplyRateBySalesGet(gte_date: string, lte_date: string, dept_name?: string, good_category?: '의류' | '잡화' | '도서음반' | '가전', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling supplyRateBySalesGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling supplyRateBySalesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (dept_name !== undefined && dept_name !== null) {
            queryParameters = queryParameters.set('dept_name', <any>dept_name);
        }
        if (good_category !== undefined && good_category !== null) {
            queryParameters = queryParameters.set('good_category', <any>good_category);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsRateSales>>(`${this.basePath}/sales/supply_rate_by_sales`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
