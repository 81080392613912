/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsWarehouseService = /** @class */ (function () {
    function EsWarehouseService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsWarehouseService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsWarehouseService.prototype.warehouseGoodShopGoodStatusGet = function (good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseGoodShopGoodStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/good/shop/good_status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGoodShopGoodStatusNewGet = function (good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseGoodShopGoodStatusNewGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/good/shop/good_status_new", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenStorageRealTimeGoodStatusGet = function (management_id, good_first_category_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseGreenStorageRealTimeGoodStatusGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseGreenStorageRealTimeGoodStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', management_id);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/green_storage/real_time/good_status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenStorageShopReturnGet = function (gte_date, lte_date, shop_name, good_name, confirm_flg, shop_id, good_first_category_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenStorageShopReturnGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenStorageShopReturnGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (confirm_flg !== undefined && confirm_flg !== null) {
            queryParameters = queryParameters.set('confirm_flg', confirm_flg);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/green_storage/shop_return", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageGet = function (greenbusiness_storage_name, greenbusiness_storage_category, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (greenbusiness_storage_name !== undefined && greenbusiness_storage_name !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_name', greenbusiness_storage_name);
        }
        if (greenbusiness_storage_category !== undefined && greenbusiness_storage_category !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_category', greenbusiness_storage_category);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageInoutGet = function (gte_date, lte_date, greenbusiness_storage_id, greenbusiness_storage_inout_flg, good_first_category_name, greenbusiness_storage_inout_category, good_name, greenbusiness_storage_inout_issue_type, greenbusiness_storage_inout_place_name, good_div_code, page_number, greenbusiness_storage_inout_zero, inout_category, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenbusinessStorageInoutGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenbusinessStorageInoutGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', greenbusiness_storage_id);
        }
        if (greenbusiness_storage_inout_flg !== undefined && greenbusiness_storage_inout_flg !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_flg', greenbusiness_storage_inout_flg);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (greenbusiness_storage_inout_category !== undefined && greenbusiness_storage_inout_category !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_category', greenbusiness_storage_inout_category);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (greenbusiness_storage_inout_issue_type !== undefined && greenbusiness_storage_inout_issue_type !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_issue_type', greenbusiness_storage_inout_issue_type);
        }
        if (greenbusiness_storage_inout_place_name !== undefined && greenbusiness_storage_inout_place_name !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_place_name', greenbusiness_storage_inout_place_name);
        }
        if (good_div_code !== undefined && good_div_code !== null) {
            queryParameters = queryParameters.set('good_div_code', good_div_code);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        if (greenbusiness_storage_inout_zero !== undefined && greenbusiness_storage_inout_zero !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_zero', greenbusiness_storage_inout_zero);
        }
        if (inout_category !== undefined && inout_category !== null) {
            queryParameters = queryParameters.set('inout_category', inout_category);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage/inout", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageInoutInoutIdGet = function (inout_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (inout_id === null || inout_id === undefined) {
            throw new Error('Required parameter inout_id was null or undefined when calling warehouseGreenbusinessStorageInoutInoutIdGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage_inout/" + encodeURIComponent(String(inout_id)), {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageInoutOpenGet = function (greenbusiness_storage_id, inout_mng_yyyy_mm, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', greenbusiness_storage_id);
        }
        if (inout_mng_yyyy_mm !== undefined && inout_mng_yyyy_mm !== null) {
            queryParameters = queryParameters.set('inout_mng_yyyy_mm', inout_mng_yyyy_mm);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage_inout_open", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageInoutStatusGet = function (greenbusiness_storage_id, good_name, good_first_category_name, good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (greenbusiness_storage_id === null || greenbusiness_storage_id === undefined) {
            throw new Error('Required parameter greenbusiness_storage_id was null or undefined when calling warehouseGreenbusinessStorageInoutStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', greenbusiness_storage_id);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage/inout/status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageInventoryStatusGet = function (greenbusiness_storage_id, good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (greenbusiness_storage_id === null || greenbusiness_storage_id === undefined) {
            throw new Error('Required parameter greenbusiness_storage_id was null or undefined when calling warehouseGreenbusinessStorageInventoryStatusGet.');
        }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseGreenbusinessStorageInventoryStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', greenbusiness_storage_id);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage/inventory/status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageOrderIdInoutGet = function (order_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (order_id === null || order_id === undefined) {
            throw new Error('Required parameter order_id was null or undefined when calling warehouseGreenbusinessStorageOrderIdInoutGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage/" + encodeURIComponent(String(order_id)) + "/inout", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageOutGet = function (gte_date, lte_date, greenbusiness_storage_out_type, good_first_category_name, good_name, greenbusiness_storage_inout_place_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenbusinessStorageOutGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenbusinessStorageOutGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (greenbusiness_storage_out_type !== undefined && greenbusiness_storage_out_type !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_out_type', greenbusiness_storage_out_type);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (greenbusiness_storage_inout_place_name !== undefined && greenbusiness_storage_inout_place_name !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_inout_place_name', greenbusiness_storage_inout_place_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage/out", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStoragePurchaseGet = function (gte_date, lte_date, good_name, maker_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenbusinessStoragePurchaseGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenbusinessStoragePurchaseGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', maker_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage/purchase", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseGreenbusinessStorageTaxDateGet = function (period_type, gte_date, lte_date, maker_name, greenbusiness_storage_id, good_name, logistics_order_id, tax_date_flg, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (period_type === null || period_type === undefined) {
            throw new Error('Required parameter period_type was null or undefined when calling warehouseGreenbusinessStorageTaxDateGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseGreenbusinessStorageTaxDateGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseGreenbusinessStorageTaxDateGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (period_type !== undefined && period_type !== null) {
            queryParameters = queryParameters.set('period_type', period_type);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (maker_name !== undefined && maker_name !== null) {
            queryParameters = queryParameters.set('maker_name', maker_name);
        }
        if (greenbusiness_storage_id !== undefined && greenbusiness_storage_id !== null) {
            queryParameters = queryParameters.set('greenbusiness_storage_id', greenbusiness_storage_id);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (logistics_order_id !== undefined && logistics_order_id !== null) {
            queryParameters = queryParameters.set('logistics_order_id', logistics_order_id);
        }
        if (tax_date_flg !== undefined && tax_date_flg !== null) {
            queryParameters = queryParameters.set('tax_date_flg', tax_date_flg);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/greenbusiness_storage/tax_date", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseInoutConfirmationCheckGet = function (management_id, search_yyyy_mm, good_first_category_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutConfirmationCheckGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutConfirmationCheckGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseInoutConfirmationCheckGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', search_yyyy_mm);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/inout/confirmation_check", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseInoutManagementGet = function (management_id, search_yyyy_mm, good_first_category_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutManagementGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseInoutManagementGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', search_yyyy_mm);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/inout/management", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseInoutManagementMonthGet = function (management_id, search_yyyy_mm, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutManagementMonthGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementMonthGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', search_yyyy_mm);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/inout/management/month", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseInoutManagementShopGet = function (search_yyyy_mm, shop_name, good_first_category_name, thismonth_mng_confirm_flg, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementShopGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', search_yyyy_mm);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (thismonth_mng_confirm_flg !== undefined && thismonth_mng_confirm_flg !== null) {
            queryParameters = queryParameters.set('thismonth_mng_confirm_flg', thismonth_mng_confirm_flg);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/inout/management/shop", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseInoutManagementSystemCoercionGet = function (management_id, search_yyyy_mm, good_first_category_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutManagementSystemCoercionGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementSystemCoercionGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseInoutManagementSystemCoercionGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', search_yyyy_mm);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/inout/management/system/coercion", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseInoutManagementSystemGet = function (management_id, search_yyyy_mm, good_first_category_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling warehouseInoutManagementSystemGet.');
        }
        if (search_yyyy_mm === null || search_yyyy_mm === undefined) {
            throw new Error('Required parameter search_yyyy_mm was null or undefined when calling warehouseInoutManagementSystemGet.');
        }
        if (good_first_category_name === null || good_first_category_name === undefined) {
            throw new Error('Required parameter good_first_category_name was null or undefined when calling warehouseInoutManagementSystemGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', management_id);
        }
        if (search_yyyy_mm !== undefined && search_yyyy_mm !== null) {
            queryParameters = queryParameters.set('search_yyyy_mm', search_yyyy_mm);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/inout/management/system", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceAlwaysSupplyShopGet = function (recycle_place_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceAlwaysSupplyShopGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/always_supply_shop", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceCenterCirculationCourseGet = function (recycle_place_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceCenterCirculationCourseGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/center_circulation_course", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceEventShippingStatisticalTableGet = function (start_month, end_month, dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (start_month === null || start_month === undefined) {
            throw new Error('Required parameter start_month was null or undefined when calling warehouseRecyclePlaceEventShippingStatisticalTableGet.');
        }
        if (end_month === null || end_month === undefined) {
            throw new Error('Required parameter end_month was null or undefined when calling warehouseRecyclePlaceEventShippingStatisticalTableGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (start_month !== undefined && start_month !== null) {
            queryParameters = queryParameters.set('start_month', start_month);
        }
        if (end_month !== undefined && end_month !== null) {
            queryParameters = queryParameters.set('end_month', end_month);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/event_shipping/statistical_table", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceGivingMoveGet = function (recycle_place_id, inout_div, gte_date, lte_date, search_recycle_place_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceGivingMoveGet.');
        }
        if (inout_div === null || inout_div === undefined) {
            throw new Error('Required parameter inout_div was null or undefined when calling warehouseRecyclePlaceGivingMoveGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceGivingMoveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceGivingMoveGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        if (inout_div !== undefined && inout_div !== null) {
            queryParameters = queryParameters.set('inout_div', inout_div);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (search_recycle_place_id !== undefined && search_recycle_place_id !== null) {
            queryParameters = queryParameters.set('search_recycle_place_id', search_recycle_place_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/giving_move", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceMonthStorageGet = function (recycle_place_id, storage_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceMonthStorageGet.');
        }
        if (storage_id === null || storage_id === undefined) {
            throw new Error('Required parameter storage_id was null or undefined when calling warehouseRecyclePlaceMonthStorageGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        if (storage_id !== undefined && storage_id !== null) {
            queryParameters = queryParameters.set('storage_id', storage_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/month_storage", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceProductionGet = function (gte_prod_date, lte_prod_date, recycle_place, recycle_place_name, production_div, event_id, event_name, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_prod_date !== undefined && gte_prod_date !== null) {
            queryParameters = queryParameters.set('gte_prod_date', gte_prod_date);
        }
        if (lte_prod_date !== undefined && lte_prod_date !== null) {
            queryParameters = queryParameters.set('lte_prod_date', lte_prod_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (recycle_place_name !== undefined && recycle_place_name !== null) {
            queryParameters = queryParameters.set('recycle_place_name', recycle_place_name);
        }
        if (production_div !== undefined && production_div !== null) {
            queryParameters = queryParameters.set('production_div', production_div);
        }
        if (event_id !== undefined && event_id !== null) {
            queryParameters = queryParameters.set('event_id', event_id);
        }
        if (event_name !== undefined && event_name !== null) {
            queryParameters = queryParameters.set('event_name', event_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/production", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceProductionRetrieveGet = function (gte_date, lte_date, recycle_place, give_good_cate, give_good_detail_cate, event_name, production_work_group_id, event_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceProductionRetrieveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceProductionRetrieveGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceProductionRetrieveGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (give_good_cate !== undefined && give_good_cate !== null) {
            queryParameters = queryParameters.set('give_good_cate', give_good_cate);
        }
        if (give_good_detail_cate !== undefined && give_good_detail_cate !== null) {
            queryParameters = queryParameters.set('give_good_detail_cate', give_good_detail_cate);
        }
        if (event_name !== undefined && event_name !== null) {
            queryParameters = queryParameters.set('event_name', event_name);
        }
        if (production_work_group_id !== undefined && production_work_group_id !== null) {
            queryParameters = queryParameters.set('production_work_group_id', production_work_group_id);
        }
        if (event_id !== undefined && event_id !== null) {
            queryParameters = queryParameters.set('event_id', event_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/production/retrieve", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceProductionStatisticalTableExcelGet = function (start_month, end_month, dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (start_month === null || start_month === undefined) {
            throw new Error('Required parameter start_month was null or undefined when calling warehouseRecyclePlaceProductionStatisticalTableExcelGet.');
        }
        if (end_month === null || end_month === undefined) {
            throw new Error('Required parameter end_month was null or undefined when calling warehouseRecyclePlaceProductionStatisticalTableExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (start_month !== undefined && start_month !== null) {
            queryParameters = queryParameters.set('start_month', start_month);
        }
        if (end_month !== undefined && end_month !== null) {
            queryParameters = queryParameters.set('end_month', end_month);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/production/statistical_table/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceProductionStatisticalTableGet = function (start_month, end_month, dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (start_month === null || start_month === undefined) {
            throw new Error('Required parameter start_month was null or undefined when calling warehouseRecyclePlaceProductionStatisticalTableGet.');
        }
        if (end_month === null || end_month === undefined) {
            throw new Error('Required parameter end_month was null or undefined when calling warehouseRecyclePlaceProductionStatisticalTableGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (start_month !== undefined && start_month !== null) {
            queryParameters = queryParameters.set('start_month', start_month);
        }
        if (end_month !== undefined && end_month !== null) {
            queryParameters = queryParameters.set('end_month', end_month);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/production/statistical_table", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceProductionWorkGroupGet = function (recycle_place_id, del_show, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceProductionWorkGroupGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        if (del_show !== undefined && del_show !== null) {
            queryParameters = queryParameters.set('del_show', del_show);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/production_work_group", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceReceivingGet = function (receiving_date, recycle_place, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (receiving_date === null || receiving_date === undefined) {
            throw new Error('Required parameter receiving_date was null or undefined when calling warehouseRecyclePlaceReceivingGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceReceivingGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (receiving_date !== undefined && receiving_date !== null) {
            queryParameters = queryParameters.set('receiving_date', receiving_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/receiving", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceReceivingGivingGoodConfirmGet = function (shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseRecyclePlaceReceivingGivingGoodConfirmGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place_receiving/giving_good/confirm", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceReceivingRetrieveGet = function (gte_date, lte_date, recycle_place, shop_id, receiving_div, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceReceivingRetrieveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceReceivingRetrieveGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceReceivingRetrieveGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (receiving_div !== undefined && receiving_div !== null) {
            queryParameters = queryParameters.set('receiving_div', receiving_div);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/receiving/retrieve", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceShareProductGet = function (recycle_place_id, gte_date, lte_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceShareProductGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceShareProductGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceShareProductGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/share_product", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceShareProductInfoGet = function (apply_id, idx_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (apply_id === null || apply_id === undefined) {
            throw new Error('Required parameter apply_id was null or undefined when calling warehouseRecyclePlaceShareProductInfoGet.');
        }
        if (idx_id === null || idx_id === undefined) {
            throw new Error('Required parameter idx_id was null or undefined when calling warehouseRecyclePlaceShareProductInfoGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apply_id !== undefined && apply_id !== null) {
            queryParameters = queryParameters.set('apply_id', apply_id);
        }
        if (idx_id !== undefined && idx_id !== null) {
            queryParameters = queryParameters.set('idx_id', idx_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/share_product_info", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceShippingGet = function (shipping_date, recycle_place, no_tag, all, course_id, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shipping_date === null || shipping_date === undefined) {
            throw new Error('Required parameter shipping_date was null or undefined when calling warehouseRecyclePlaceShippingGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceShippingGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shipping_date !== undefined && shipping_date !== null) {
            queryParameters = queryParameters.set('shipping_date', shipping_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (no_tag !== undefined && no_tag !== null) {
            queryParameters = queryParameters.set('no_tag', no_tag);
        }
        if (all !== undefined && all !== null) {
            queryParameters = queryParameters.set('all', all);
        }
        if (course_id !== undefined && course_id !== null) {
            queryParameters = queryParameters.set('course_id', course_id);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/shipping", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceShippingRetrieveGet = function (gte_date, lte_date, recycle_place, give_good_cate, shipping_shop, shipping_div, give_good_detail_cate, event_foundation_name, event_foundation_company_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceShippingRetrieveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceShippingRetrieveGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceShippingRetrieveGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (give_good_cate !== undefined && give_good_cate !== null) {
            queryParameters = queryParameters.set('give_good_cate', give_good_cate);
        }
        if (shipping_shop !== undefined && shipping_shop !== null) {
            queryParameters = queryParameters.set('shipping_shop', shipping_shop);
        }
        if (shipping_div !== undefined && shipping_div !== null) {
            queryParameters = queryParameters.set('shipping_div', shipping_div);
        }
        if (give_good_detail_cate !== undefined && give_good_detail_cate !== null) {
            queryParameters = queryParameters.set('give_good_detail_cate', give_good_detail_cate);
        }
        if (event_foundation_name !== undefined && event_foundation_name !== null) {
            queryParameters = queryParameters.set('event_foundation_name', event_foundation_name);
        }
        if (event_foundation_company_name !== undefined && event_foundation_company_name !== null) {
            queryParameters = queryParameters.set('event_foundation_company_name', event_foundation_company_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/shipping/retrieve", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceShippingStatisticalTableGet = function (start_month, end_month, dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (start_month === null || start_month === undefined) {
            throw new Error('Required parameter start_month was null or undefined when calling warehouseRecyclePlaceShippingStatisticalTableGet.');
        }
        if (end_month === null || end_month === undefined) {
            throw new Error('Required parameter end_month was null or undefined when calling warehouseRecyclePlaceShippingStatisticalTableGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (start_month !== undefined && start_month !== null) {
            queryParameters = queryParameters.set('start_month', start_month);
        }
        if (end_month !== undefined && end_month !== null) {
            queryParameters = queryParameters.set('end_month', end_month);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/shipping/statistical_table", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceShopCirculationGet = function (gte_date, lte_date, recycle_place_id, shop_name, shop_id, confirm_flg, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceShopCirculationGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceShopCirculationGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (confirm_flg !== undefined && confirm_flg !== null) {
            queryParameters = queryParameters.set('confirm_flg', confirm_flg);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/shop_circulation", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceShopReturnGet = function (gte_date, lte_date, recycle_place_id, give_good_cate, shop_name, confirm_flg, shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceShopReturnGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceShopReturnGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        if (give_good_cate !== undefined && give_good_cate !== null) {
            queryParameters = queryParameters.set('give_good_cate', give_good_cate);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (confirm_flg !== undefined && confirm_flg !== null) {
            queryParameters = queryParameters.set('confirm_flg', confirm_flg);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/shop_return", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceStorageGet = function (storage_date, recycle_place, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (storage_date === null || storage_date === undefined) {
            throw new Error('Required parameter storage_date was null or undefined when calling warehouseRecyclePlaceStorageGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceStorageGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (storage_date !== undefined && storage_date !== null) {
            queryParameters = queryParameters.set('storage_date', storage_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/storage", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceStorageListGet = function (recycle_place_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageListGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/storage_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceStorageLocationListGet = function (recycle_place_id, storage_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageLocationListGet.');
        }
        if (storage_id === null || storage_id === undefined) {
            throw new Error('Required parameter storage_id was null or undefined when calling warehouseRecyclePlaceStorageLocationListGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        if (storage_id !== undefined && storage_id !== null) {
            queryParameters = queryParameters.set('storage_id', storage_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/storage_location_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceStorageNameListGet = function (recycle_place_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageNameListGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/storage_name_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceStorageRetrieveGet = function (gte_date, lte_date, recycle_place, storage_id, storage_div, storage_good_cate, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceStorageRetrieveGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceStorageRetrieveGet.');
        }
        if (recycle_place === null || recycle_place === undefined) {
            throw new Error('Required parameter recycle_place was null or undefined when calling warehouseRecyclePlaceStorageRetrieveGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (recycle_place !== undefined && recycle_place !== null) {
            queryParameters = queryParameters.set('recycle_place', recycle_place);
        }
        if (storage_id !== undefined && storage_id !== null) {
            queryParameters = queryParameters.set('storage_id', storage_id);
        }
        if (storage_div !== undefined && storage_div !== null) {
            queryParameters = queryParameters.set('storage_div', storage_div);
        }
        if (storage_good_cate !== undefined && storage_good_cate !== null) {
            queryParameters = queryParameters.set('storage_good_cate', storage_good_cate);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/storage/retrieve", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceStorageStatusGet = function (recycle_place_id, storage_location_id, storage_good_cate, storage_box_kind, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        if (storage_location_id !== undefined && storage_location_id !== null) {
            queryParameters = queryParameters.set('storage_location_id', storage_location_id);
        }
        if (storage_good_cate !== undefined && storage_good_cate !== null) {
            queryParameters = queryParameters.set('storage_good_cate', storage_good_cate);
        }
        if (storage_box_kind !== undefined && storage_box_kind !== null) {
            queryParameters = queryParameters.set('storage_box_kind', storage_box_kind);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/storage/status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceStorageStatusListGet = function (recycle_place_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceStorageStatusListGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/storage_status_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceTargetYearListGet = function (recycle_place_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (recycle_place_id === null || recycle_place_id === undefined) {
            throw new Error('Required parameter recycle_place_id was null or undefined when calling warehouseRecyclePlaceTargetYearListGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (recycle_place_id !== undefined && recycle_place_id !== null) {
            queryParameters = queryParameters.set('recycle_place_id', recycle_place_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/target_year_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseRecyclePlaceTotalStatisticalTableGet = function (gte_date, lte_date, dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseRecyclePlaceTotalStatisticalTableGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseRecyclePlaceTotalStatisticalTableGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', dept_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/recycle_place/total/statistical_table", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseSeqGet = function (id, type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling warehouseSeqGet.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling warehouseSeqGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', id);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/seq", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopCirculationGivingGoodConfirmGet = function (shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopCirculationGivingGoodConfirmGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_circulation/giving_good/confirm", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopDisposalConfirmGet = function (gte_date, lte_date, shop_id, confirm_flg, good_first_category_name, shop_inout_remark, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseShopDisposalConfirmGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseShopDisposalConfirmGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (confirm_flg !== undefined && confirm_flg !== null) {
            queryParameters = queryParameters.set('confirm_flg', confirm_flg);
        }
        if (good_first_category_name !== undefined && good_first_category_name !== null) {
            queryParameters = queryParameters.set('good_first_category_name', good_first_category_name);
        }
        if (shop_inout_remark !== undefined && shop_inout_remark !== null) {
            queryParameters = queryParameters.set('shop_inout_remark', shop_inout_remark);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_disposal/confirm", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutAggregateTableExcelGet = function (summary_month, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutAggregateTableExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', summary_month);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/aggregate_table/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutAggregateTableGet = function (summary_month, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutAggregateTableGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', summary_month);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/aggregate_table", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutAutoQtycheck = function (shop_id, good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutAutoQtycheck.');
        }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseShopInoutAutoQtycheck.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/auto/qtycheck", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutExcelGet = function (shop_id, gte_date, lte_date, shop_inout_good_category, shop_inout_flg, good_name, shop_inout_issue_type, shop_inout_confirm_flg, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutExcelGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseShopInoutExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseShopInoutExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (shop_inout_good_category !== undefined && shop_inout_good_category !== null) {
            queryParameters = queryParameters.set('shop_inout_good_category', shop_inout_good_category);
        }
        if (shop_inout_flg !== undefined && shop_inout_flg !== null) {
            queryParameters = queryParameters.set('shop_inout_flg', shop_inout_flg);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (shop_inout_issue_type !== undefined && shop_inout_issue_type !== null) {
            queryParameters = queryParameters.set('shop_inout_issue_type', shop_inout_issue_type);
        }
        if (shop_inout_confirm_flg !== undefined && shop_inout_confirm_flg !== null) {
            queryParameters = queryParameters.set('shop_inout_confirm_flg', shop_inout_confirm_flg);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutGet = function (shop_id, gte_date, lte_date, shop_inout_good_category, shop_inout_flg, good_name, shop_inout_issue_type, shop_inout_confirm_flg, shop_inout_category, shop_out_category, shop_inout_place_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutGet.');
        }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling warehouseShopInoutGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling warehouseShopInoutGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (shop_inout_good_category !== undefined && shop_inout_good_category !== null) {
            queryParameters = queryParameters.set('shop_inout_good_category', shop_inout_good_category);
        }
        if (shop_inout_flg !== undefined && shop_inout_flg !== null) {
            queryParameters = queryParameters.set('shop_inout_flg', shop_inout_flg);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (shop_inout_issue_type !== undefined && shop_inout_issue_type !== null) {
            queryParameters = queryParameters.set('shop_inout_issue_type', shop_inout_issue_type);
        }
        if (shop_inout_confirm_flg !== undefined && shop_inout_confirm_flg !== null) {
            queryParameters = queryParameters.set('shop_inout_confirm_flg', shop_inout_confirm_flg);
        }
        if (shop_inout_category !== undefined && shop_inout_category !== null) {
            queryParameters = queryParameters.set('shop_inout_category', shop_inout_category);
        }
        if (shop_out_category !== undefined && shop_out_category !== null) {
            queryParameters = queryParameters.set('shop_out_category', shop_out_category);
        }
        if (shop_inout_place_name !== undefined && shop_inout_place_name !== null) {
            queryParameters = queryParameters.set('shop_inout_place_name', shop_inout_place_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutGivingGoodExclusionInventoryStatusExcelGet = function (summary_month, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutGivingGoodExclusionInventoryStatusExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', summary_month);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/giving_good_exclusion/inventory_status/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutGivingGoodInventoryStatusExcelGet = function (summary_month, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutGivingGoodInventoryStatusExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', summary_month);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/giving_good/inventory_status/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutGivingGoodInventoryStatusGet = function (summary_month, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutGivingGoodInventoryStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', summary_month);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/giving_good/inventory_status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutOpenGet = function (shop_name, inout_mng_yyyy_mm, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', shop_name);
        }
        if (inout_mng_yyyy_mm !== undefined && inout_mng_yyyy_mm !== null) {
            queryParameters = queryParameters.set('inout_mng_yyyy_mm', inout_mng_yyyy_mm);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout_open", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutQtycheck = function (shop_id, good_code, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutQtycheck.');
        }
        if (good_code === null || good_code === undefined) {
            throw new Error('Required parameter good_code was null or undefined when calling warehouseShopInoutQtycheck.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/qtycheck", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutQuantitativeIndexExcelGet = function (summary_month, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (summary_month === null || summary_month === undefined) {
            throw new Error('Required parameter summary_month was null or undefined when calling warehouseShopInoutQuantitativeIndexExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (summary_month !== undefined && summary_month !== null) {
            queryParameters = queryParameters.set('summary_month', summary_month);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/quantitative_index/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopInoutStatusGet = function (shop_id, good_name, shop_inout_good_category, good_code, page_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopInoutStatusGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        if (good_name !== undefined && good_name !== null) {
            queryParameters = queryParameters.set('good_name', good_name);
        }
        if (shop_inout_good_category !== undefined && shop_inout_good_category !== null) {
            queryParameters = queryParameters.set('shop_inout_good_category', shop_inout_good_category);
        }
        if (good_code !== undefined && good_code !== null) {
            queryParameters = queryParameters.set('good_code', good_code);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', page_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_inout/status", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopOrderGreenGoodConfirmGet = function (shop_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling warehouseShopOrderGreenGoodConfirmGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', shop_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop_order/green_good/confirm", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopOrderIdInoutGet = function (order_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (order_id === null || order_id === undefined) {
            throw new Error('Required parameter order_id was null or undefined when calling warehouseShopOrderIdInoutGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop/" + encodeURIComponent(String(order_id)) + "/inout", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsWarehouseService.prototype.warehouseShopShareProductInfoGet = function (apply_id, idx_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (apply_id === null || apply_id === undefined) {
            throw new Error('Required parameter apply_id was null or undefined when calling warehouseShopShareProductInfoGet.');
        }
        if (idx_id === null || idx_id === undefined) {
            throw new Error('Required parameter idx_id was null or undefined when calling warehouseShopShareProductInfoGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apply_id !== undefined && apply_id !== null) {
            queryParameters = queryParameters.set('apply_id', apply_id);
        }
        if (idx_id !== undefined && idx_id !== null) {
            queryParameters = queryParameters.set('idx_id', idx_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/warehouse/shop/share_product_info", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsWarehouseService;
}());
export { EsWarehouseService };
