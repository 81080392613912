import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EsShopsService, EsShop } from 'src/app/restapi/be-esquery';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-shop-auto-complete',
  templateUrl: './shop-auto-complete.component.html',
  styleUrls: ['./shop-auto-complete.component.scss']
})
export class ShopAutoCompleteComponent implements OnInit, OnDestroy {

  @Input() label: string;
  @Input() shopType: string;
  @Output() selected = new EventEmitter<EsShop>();

  control = new FormControl();
  searchResults: Observable<Array<EsShop>>;

  constructor(
    private esShopsService: EsShopsService
  ) { }

  ngOnInit() {
    if (!this.label) this.label = '매장';

    if (!this.shopType) this.shopType = null;

    this.searchResults = this.control.valueChanges.pipe(
      debounceTime(500),
      tap((value: string) => {
        if (!value) {
          this.selected.emit(null);
        }
      }),
      filter((value: any) => !!value && typeof value === 'string'),
      mergeMap((value: string) => {
        return this.esShopsService.shopsMetaGet((<any>this.shopType), value);
      }),
      takeUntil(componentDestroyed(this)),
    );
  }

  ngOnDestroy() { }

  displayShopName(shop: EsShop): string | undefined {
    return shop ? shop.shop_name : undefined;
  }

  onShopSelected(value: EsShop) {
    if (!value) return;

    this.selected.emit(value);
  }
}
