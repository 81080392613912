<div class="row">
    <div class="col-lg-6">
        <div class="section-basic">
            <app-raw-data-joiner-givings></app-raw-data-joiner-givings>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="section-basic">
            <app-raw-data-anonymous-givings></app-raw-data-anonymous-givings>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <div class="section-basic">
            <app-raw-data-logistics></app-raw-data-logistics>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="section-basic">
            <app-raw-data-logistics-order></app-raw-data-logistics-order>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <div class="section-basic">
            <app-raw-data-shop-givings></app-raw-data-shop-givings>
        </div>
    </div>
</div>