import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EsMasterService } from 'src/app/restapi/be-esquery';
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
var AreasAutoCompleteComponent = /** @class */ (function () {
    function AreasAutoCompleteComponent(esMasterService) {
        this.esMasterService = esMasterService;
        this.selected = new EventEmitter();
        this.control = new FormControl();
    }
    AreasAutoCompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.label)
            this.label = '권역';
        this.searchResults = this.control.valueChanges.pipe(debounceTime(500), tap(function (value) {
            if (!value) {
                _this.selected.emit(null);
            }
        }), filter(function (value) { return !!value && typeof value === 'string'; }), mergeMap(function (value) {
            return _this.esMasterService.masterAreaInfoGet(value);
        }), takeUntil(componentDestroyed(this)));
    };
    AreasAutoCompleteComponent.prototype.ngOnDestroy = function () { };
    AreasAutoCompleteComponent.prototype.displayAreasName = function (areas) {
        return areas ? areas.area_name : undefined;
    };
    AreasAutoCompleteComponent.prototype.onAreasSelected = function (value) {
        if (!value)
            return;
        this.selectedAreas = value;
        this.selected.emit(this.selectedAreas);
    };
    return AreasAutoCompleteComponent;
}());
export { AreasAutoCompleteComponent };
