import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS } from '@angular/material';
import { EsDeptInfo, EsGivingsService, EsGivingPlaceStatistics } from 'src/app/restapi/be-esquery';
import { EsGivingStatisticsCategories } from 'src/app/restapi/be-esquery/model/esGivingStatisticsCategories';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
import { DateRangePicker } from 'src/app/share/components/directives/date-range-picker.directive';

const moment = _moment;

@Component({
  selector: 'app-givings-place',
  templateUrl: './givings-place.component.html',
  styleUrls: ['./givings-place.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS },
  ]
})
export class GivingsPlaceComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  isAnonymous: boolean;
  form: FormGroup;
  dataList: Array<EsGivingPlaceStatistics> = [];
  dataSource: MatTableDataSource<EsGivingPlaceStatistics> = new MatTableDataSource([]);
  displayedColumns = [];
  defaultDisplayedColumns = ['date', 'department_name', 'team_name', 'location_name', 'give_route', 'give_count', 'give_unit_count', '의류', '잡화', '도서음반', '가전'];
  anonymousDisplayedColumns = ['date', 'department_name', 'team_name', 'location_name', 'give_count', 'give_unit_count', '의류', '잡화', '도서음반', '가전'];
  minDate = moment().add(-3, 'month');
  maxDate = moment();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private esGivingsService: EsGivingsService
  ) { }

  ngOnInit() {
    this.checkPage();
    this.initForm();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  // 기증처별통계, 기증처별 익명기증통계 구분
  // 화면이 동일함.
  checkPage() {
    if (this.router.url.includes('anonymous')) {
      this.isAnonymous = true;
      this.displayedColumns = this.anonymousDisplayedColumns;
    } else {
      this.isAnonymous = false;
      this.displayedColumns = this.defaultDisplayedColumns;
    }
  }

  initForm() {
    this.form = this.fb.group({
      gte_date: this.minDate,
      lte_date: this.maxDate,
      dept_info: null,
      give_route: ''  // 기증처별통계에서만 사용
    });
  }

  get fc_gteDate() { return this.form.get('gte_date'); }
  get fc_lteDate() { return this.form.get('lte_date'); }
  get fc_deptInfo() { return this.form.get('dept_info'); }
  get fc_giveRoute() { return this.form.get('give_route'); }

  rangeComplete(event: DateRangePicker) {
    this.form.patchValue({
      gte_date: event.startDate,
      lte_date: event.endDate
    });
    //this.onClickSearch();
  }

  /*   onChangeStartDate() {
      const limitDate = moment(this.fc_gteDate.value).add(3, 'month');
      this.minDate = this.fc_gteDate.value;
  
      if (this.fc_gteDate.value > this.fc_lteDate.value) {
        this.fc_lteDate.setValue(this.fc_gteDate.value);
      }
  
      if (limitDate < this.fc_lteDate.value) {
        this.fc_lteDate.setValue(limitDate);
      }
    }
  
    onChangeEndDate() {
      const limitDate = moment(this.fc_lteDate.value).add(-3, 'month');
  
      if (limitDate > this.fc_gteDate.value) {
        this.fc_gteDate.setValue(limitDate);
        this.minDate = limitDate;
      }
    } */

  selectDept(value: EsDeptInfo) {
    if (!value || value == null) {
      this.fc_deptInfo.setValue(null);
      return;
    }

    this.fc_deptInfo.setValue(value);
    this.search();
  }

  convertCategories(category: string, categories: Array<EsGivingStatisticsCategories>) {
    if (categories.find(x => x.category == category)) {
      return categories.find(x => x.category == category).sum;
    }

    return "";
  }

  search() {
    this.givingsPlaceGet();
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      if (sort.active == '의류' || sort.active == '잡화' || sort.active == '도서음반' || sort.active == '가전') {
        sorted = this.dataList.sort((a: EsGivingPlaceStatistics, b: EsGivingPlaceStatistics) => {
          let result = 0;
          let a_cate_sum: number = Number(this.convertCategories(sort.active, a.categories));
          let b_cate_sum: number = Number(this.convertCategories(sort.active, b.categories));

          if (a_cate_sum < b_cate_sum) result = -1;
          else if (a_cate_sum > b_cate_sum) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      } else {
        sorted = this.dataList.sort((a: EsGivingPlaceStatistics, b: EsGivingPlaceStatistics) => {
          let result = 0;
          if (a[sort.active] == undefined) a[sort.active] = "";
          if (b[sort.active] == undefined) b[sort.active] = "";

          if (a[sort.active] < b[sort.active]) result = -1;
          else if (a[sort.active] > b[sort.active]) result = 1;
          return result * (sort.direction === 'asc' ? 1 : -1);
        });
      }
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsGivingPlaceStatistics>(this.dataList);
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    if (this.isAnonymous) {
      XLSX.utils.book_append_sheet(wb, ws, '기증처별 익명기증 통계');
      XLSX.writeFile(wb, '기증처별 익명기증 통계.xlsx');
    } else {
      XLSX.utils.book_append_sheet(wb, ws, '기증처별 통계');
      XLSX.writeFile(wb, '기증처별 통계.xlsx');
    }
  }

  givingsPlaceGet() {
    this.dataList = [];

    this.esGivingsService.givingsPlaceGet(
      dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value),
      this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null,
      this.isAnonymous ? null : this.fc_giveRoute.value,
      this.isAnonymous ? 'Y' : null
    ).pipe(
      take(1)
    ).subscribe((res: Array<EsGivingPlaceStatistics>) => {
      this.dataList = res;
      this.initPage(this.sort);
    },
      (err) => {
        console.error(err);
        this.initPage(this.sort);
      }
    );
  }

}