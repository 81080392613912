import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { EsLogisticsDivStatistics } from 'src/app/restapi/be-esquery';

@Component({
  selector: 'app-logistics-depts-div',
  templateUrl: './logistics-depts-div.component.html',
  styleUrls: ['./logistics-depts-div.component.scss']
})
export class LogisticsDeptsDivComponent implements OnInit, OnChanges {

  @Input() dataList: Array<EsLogisticsDivStatistics>;
  @ViewChild('TABLE') table: ElementRef;
  dataSource: MatTableDataSource<EsLogisticsDivStatistics> = new MatTableDataSource([]);
  displayedColumns = ['logistics_div', 'count', 'give_unit_count'];

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataList && changes.dataList.currentValue != null) {
      setTimeout(() => {
        this.convertData();
      });
    }
  }

  convertData() {
    const totalCount: number = this.dataList.map(x => x.count).filter(x => x).reduce((pValue, cValue) => { return pValue + cValue; }, 0);
    const totalGiveUnitCount: number = this.dataList.map(x => x.give_unit_count).filter(x => x).reduce((pValue, cValue) => { return pValue + cValue; }, 0);

    this.dataList.push({
      logistics_div: '합계',
      count: totalCount,
      give_unit_count: totalGiveUnitCount
    });

    this.dataSource = new MatTableDataSource<EsLogisticsDivStatistics>(this.dataList);
  }

}