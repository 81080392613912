/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Failure } from '../model/failure';
import { SearchUpdate } from '../model/searchUpdate';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InternalManagementService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * ES 조회후 Dydb에 업데이트
     * 
     * @param elastic_search_index user_id issued by auth0
     * @param dyanmodb_target_table user_id issued by auth0
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalManagementDynamodbPatch(elastic_search_index: string, dyanmodb_target_table: string, body: SearchUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public internalManagementDynamodbPatch(elastic_search_index: string, dyanmodb_target_table: string, body: SearchUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public internalManagementDynamodbPatch(elastic_search_index: string, dyanmodb_target_table: string, body: SearchUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public internalManagementDynamodbPatch(elastic_search_index: string, dyanmodb_target_table: string, body: SearchUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (elastic_search_index === null || elastic_search_index === undefined) {
            throw new Error('Required parameter elastic_search_index was null or undefined when calling internalManagementDynamodbPatch.');
        }
        if (dyanmodb_target_table === null || dyanmodb_target_table === undefined) {
            throw new Error('Required parameter dyanmodb_target_table was null or undefined when calling internalManagementDynamodbPatch.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling internalManagementDynamodbPatch.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (elastic_search_index !== undefined && elastic_search_index !== null) {
            queryParameters = queryParameters.set('elastic_search_index', <any>elastic_search_index);
        }
        if (dyanmodb_target_table !== undefined && dyanmodb_target_table !== null) {
            queryParameters = queryParameters.set('dyanmodb_target_table', <any>dyanmodb_target_table);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/internal_management_dynamodb`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
