/**
 * angel
 *  ## 자원활동관련 데이타를 관리한다. 
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Failure } from '../model/failure';
import { Group } from '../model/group';
import { GroupId } from '../model/groupId';
import { Join } from '../model/join';
import { Reserved } from '../model/reserved';
import { ReservedArrayId } from '../model/reservedArrayId';
import { ReservedList } from '../model/reservedList';
import { Schedule } from '../model/schedule';
import { ScheduleList } from '../model/scheduleList';
import { Social } from '../model/social';
import { Target } from '../model/target';
import { TargetArrayId } from '../model/targetArrayId';
import { TargetList } from '../model/targetList';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GroupService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9905/angel/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 사회봉사관리의 대학교 정보를 삭제.
     * 사회봉사관리의 대학교 정보를 삭제한다.
     * @param group_id 사회봉사 대학그룹 아이디
     * @param college_id 사회봉사그룹 대학 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupCollegeCollegeIdDelete(group_id: string, college_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupCollegeCollegeIdDelete(group_id: string, college_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupCollegeCollegeIdDelete(group_id: string, college_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupCollegeCollegeIdDelete(group_id: string, college_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupCollegeCollegeIdDelete.');
        }
        if (college_id === null || college_id === undefined) {
            throw new Error('Required parameter college_id was null or undefined when calling groupCollegeCollegeIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/group/college/${encodeURIComponent(String(group_id))}/${encodeURIComponent(String(college_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사회봉사관리의 대학교그룹 정보를 삭제.
     * 사회봉사관리의 대학교그룹 정보를 삭제한다.
     * @param group_id 사회봉사대학그룹 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupCollegeDelete(group_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupCollegeDelete(group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupCollegeDelete(group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupCollegeDelete(group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupCollegeDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (group_id !== undefined && group_id !== null) {
            queryParameters = queryParameters.set('group_id', <any>group_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/group/college`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사회봉사 대학관리정보를 조회
     * 사회봉사 대학관리 정보를 조회한다
     * @param group_name 그룹명
     * @param college_name 대학명
     * @param group_status 상태
     * @param etc_add 기타추가여부코드사용시Y
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupCollegeGet(group_name?: string, college_name?: string, group_status?: '진행중' | '종료', etc_add?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<Group>>;
    public groupCollegeGet(group_name?: string, college_name?: string, group_status?: '진행중' | '종료', etc_add?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Group>>>;
    public groupCollegeGet(group_name?: string, college_name?: string, group_status?: '진행중' | '종료', etc_add?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Group>>>;
    public groupCollegeGet(group_name?: string, college_name?: string, group_status?: '진행중' | '종료', etc_add?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (group_name !== undefined && group_name !== null) {
            queryParameters = queryParameters.set('group_name', <any>group_name);
        }
        if (college_name !== undefined && college_name !== null) {
            queryParameters = queryParameters.set('college_name', <any>college_name);
        }
        if (group_status !== undefined && group_status !== null) {
            queryParameters = queryParameters.set('group_status', <any>group_status);
        }
        if (etc_add !== undefined && etc_add !== null) {
            queryParameters = queryParameters.set('etc_add', <any>etc_add);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Group>>(`${this.basePath}/group/college`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사회봉사관리의 대학교 정보를 등록.
     * 사회봉사관리의 대학교 정보를 등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupCollegePost(body: Group, observe?: 'body', reportProgress?: boolean): Observable<GroupId>;
    public groupCollegePost(body: Group, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GroupId>>;
    public groupCollegePost(body: Group, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GroupId>>;
    public groupCollegePost(body: Group, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupCollegePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GroupId>(`${this.basePath}/group/college`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사회봉사관리의 대학교 정보를 변경.
     * 사회봉사관리의 대학교 정보를 변경한다
     * @param group_id 사회봉사대학그룹 아이디
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupCollegePut(group_id: string, body: Group, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupCollegePut(group_id: string, body: Group, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupCollegePut(group_id: string, body: Group, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupCollegePut(group_id: string, body: Group, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupCollegePut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupCollegePut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (group_id !== undefined && group_id !== null) {
            queryParameters = queryParameters.set('group_id', <any>group_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/college`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여관리 참가 활동천사 삭제.
     * 참여관리 참가 활동천사 삭제한다.
     * @param join_id 참여관리 아이디
     * @param angel_id 활동천사 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupJoinAngelDelete(join_id: string, angel_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupJoinAngelDelete(join_id: string, angel_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupJoinAngelDelete(join_id: string, angel_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupJoinAngelDelete(join_id: string, angel_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (join_id === null || join_id === undefined) {
            throw new Error('Required parameter join_id was null or undefined when calling groupJoinAngelDelete.');
        }
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling groupJoinAngelDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/group/join/${encodeURIComponent(String(join_id))}/${encodeURIComponent(String(angel_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여관리의 정보를 삭제.
     * 참여관리의 정보를 삭제한다.
     * @param join_id 참여관리 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupJoinDelete(join_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupJoinDelete(join_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupJoinDelete(join_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupJoinDelete(join_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (join_id === null || join_id === undefined) {
            throw new Error('Required parameter join_id was null or undefined when calling groupJoinDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (join_id !== undefined && join_id !== null) {
            queryParameters = queryParameters.set('join_id', <any>join_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/group/join`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여관리의 활동천사 정보를 조회한다
     * 참여관리의 내용 및 활동천사 정보를 조회한다
     * @param gte_date 참여시작일자
     * @param lte_date 참여종료일자
     * @param join_name 참여명
     * @param act_dept_name 활동지명
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰
     * @param join_type 참여구분
     * @param join_category 참여항목
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupJoinGet(gte_date: string, lte_date: string, join_name?: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, join_type?: string, join_category?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Join>>;
    public groupJoinGet(gte_date: string, lte_date: string, join_name?: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, join_type?: string, join_category?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Join>>>;
    public groupJoinGet(gte_date: string, lte_date: string, join_name?: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, join_type?: string, join_category?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Join>>>;
    public groupJoinGet(gte_date: string, lte_date: string, join_name?: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, join_type?: string, join_category?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling groupJoinGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling groupJoinGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (join_name !== undefined && join_name !== null) {
            queryParameters = queryParameters.set('join_name', <any>join_name);
        }
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', <any>act_dept_name);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }
        if (join_type !== undefined && join_type !== null) {
            queryParameters = queryParameters.set('join_type', <any>join_type);
        }
        if (join_category !== undefined && join_category !== null) {
            queryParameters = queryParameters.set('join_category', <any>join_category);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Join>>(`${this.basePath}/group/join`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여관리의 활동천사 정보를 등록.
     * 참여관리의 활동천사 정보를 등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupJoinPost(body: Join, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupJoinPost(body: Join, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupJoinPost(body: Join, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupJoinPost(body: Join, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupJoinPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/group/join`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여관리의 활동천사 정보를 변경.
     * 참여관리의 활동천사 정보를 변경한다
     * @param join_id 참여관리 아이디
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupJoinPut(join_id: string, body: Join, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupJoinPut(join_id: string, body: Join, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupJoinPut(join_id: string, body: Join, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupJoinPut(join_id: string, body: Join, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (join_id === null || join_id === undefined) {
            throw new Error('Required parameter join_id was null or undefined when calling groupJoinPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupJoinPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (join_id !== undefined && join_id !== null) {
            queryParameters = queryParameters.set('join_id', <any>join_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/join`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육접수내역 정보를 삭제.
     * 입문교육접수내역 정보를 삭제한다.
     * @param reserved_id 입문교육접수내역 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupReservedDelete(reserved_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupReservedDelete(reserved_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupReservedDelete(reserved_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupReservedDelete(reserved_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reserved_id === null || reserved_id === undefined) {
            throw new Error('Required parameter reserved_id was null or undefined when calling groupReservedDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reserved_id !== undefined && reserved_id !== null) {
            queryParameters = queryParameters.set('reserved_id', <any>reserved_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/group/reserved`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육의 접수내역을 조회
     * 입문교육의 접수내역을 조회한다
     * @param gte_date 입문교육일시작일자
     * @param lte_date 입문교육일종료일자
     * @param reserved_region 활동지역
     * @param angel_type 활동구분
     * @param schedule_id 입문교육아이디
     * @param schedule_date_id 접수교육일아이디
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰번호
     * @param schedule_dept_name 교육부서
     * @param angel_edu_yn 교육상태
     * @param reserved_place_status 배치상태
     * @param angel_edu_path 교육경로
     * @param college_name 대학교명
     * @param act_dept_name 희망활동지명
     * @param angel_1365_join 1365가입여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupReservedGet(gte_date?: string, lte_date?: string, reserved_region?: string, angel_type?: string, schedule_id?: string, schedule_date_id?: string, angel_name?: string, angel_cellp_no?: string, schedule_dept_name?: string, angel_edu_yn?: '수료' | '미수료', reserved_place_status?: '배치' | '미배치', angel_edu_path?: string, college_name?: string, act_dept_name?: string, angel_1365_join?: '가입' | '미가입', observe?: 'body', reportProgress?: boolean): Observable<Array<Reserved>>;
    public groupReservedGet(gte_date?: string, lte_date?: string, reserved_region?: string, angel_type?: string, schedule_id?: string, schedule_date_id?: string, angel_name?: string, angel_cellp_no?: string, schedule_dept_name?: string, angel_edu_yn?: '수료' | '미수료', reserved_place_status?: '배치' | '미배치', angel_edu_path?: string, college_name?: string, act_dept_name?: string, angel_1365_join?: '가입' | '미가입', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reserved>>>;
    public groupReservedGet(gte_date?: string, lte_date?: string, reserved_region?: string, angel_type?: string, schedule_id?: string, schedule_date_id?: string, angel_name?: string, angel_cellp_no?: string, schedule_dept_name?: string, angel_edu_yn?: '수료' | '미수료', reserved_place_status?: '배치' | '미배치', angel_edu_path?: string, college_name?: string, act_dept_name?: string, angel_1365_join?: '가입' | '미가입', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reserved>>>;
    public groupReservedGet(gte_date?: string, lte_date?: string, reserved_region?: string, angel_type?: string, schedule_id?: string, schedule_date_id?: string, angel_name?: string, angel_cellp_no?: string, schedule_dept_name?: string, angel_edu_yn?: '수료' | '미수료', reserved_place_status?: '배치' | '미배치', angel_edu_path?: string, college_name?: string, act_dept_name?: string, angel_1365_join?: '가입' | '미가입', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (reserved_region !== undefined && reserved_region !== null) {
            queryParameters = queryParameters.set('reserved_region', <any>reserved_region);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (schedule_id !== undefined && schedule_id !== null) {
            queryParameters = queryParameters.set('schedule_id', <any>schedule_id);
        }
        if (schedule_date_id !== undefined && schedule_date_id !== null) {
            queryParameters = queryParameters.set('schedule_date_id', <any>schedule_date_id);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }
        if (schedule_dept_name !== undefined && schedule_dept_name !== null) {
            queryParameters = queryParameters.set('schedule_dept_name', <any>schedule_dept_name);
        }
        if (angel_edu_yn !== undefined && angel_edu_yn !== null) {
            queryParameters = queryParameters.set('angel_edu_yn', <any>angel_edu_yn);
        }
        if (reserved_place_status !== undefined && reserved_place_status !== null) {
            queryParameters = queryParameters.set('reserved_place_status', <any>reserved_place_status);
        }
        if (angel_edu_path !== undefined && angel_edu_path !== null) {
            queryParameters = queryParameters.set('angel_edu_path', <any>angel_edu_path);
        }
        if (college_name !== undefined && college_name !== null) {
            queryParameters = queryParameters.set('college_name', <any>college_name);
        }
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', <any>act_dept_name);
        }
        if (angel_1365_join !== undefined && angel_1365_join !== null) {
            queryParameters = queryParameters.set('angel_1365_join', <any>angel_1365_join);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Reserved>>(`${this.basePath}/group/reserved`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 교육의 접수내역을 조회
     * 교육의 접수내역을 조회한다
     * @param schedule_date_id 교육일아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupReservedListGet(schedule_date_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ReservedList>>;
    public groupReservedListGet(schedule_date_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReservedList>>>;
    public groupReservedListGet(schedule_date_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReservedList>>>;
    public groupReservedListGet(schedule_date_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schedule_date_id !== undefined && schedule_date_id !== null) {
            queryParameters = queryParameters.set('schedule_date_id', <any>schedule_date_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<ReservedList>>(`${this.basePath}/group/reserved_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육접수내역 정보를 멀티 수료처리한다.
     * 입문교육접수내역 정보를 멀티 수료처리한다.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupReservedMultiComplete(body: ReservedArrayId, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupReservedMultiComplete(body: ReservedArrayId, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupReservedMultiComplete(body: ReservedArrayId, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupReservedMultiComplete(body: ReservedArrayId, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedMultiComplete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/reserved_multi_complete`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육접수내역 정보를 멀티 삭제.
     * 입문교육접수내역 정보를 멀티 삭제한다.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupReservedMultiDelete(body: ReservedArrayId, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupReservedMultiDelete(body: ReservedArrayId, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupReservedMultiDelete(body: ReservedArrayId, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupReservedMultiDelete(body: ReservedArrayId, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedMultiDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/reserved_multi_delete`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육접수내역 정보를 멀티 미수료처리한다.
     * 입문교육접수내역 정보를 멀티 미수료처리한다.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupReservedMultiNotComplete(body: ReservedArrayId, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupReservedMultiNotComplete(body: ReservedArrayId, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupReservedMultiNotComplete(body: ReservedArrayId, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupReservedMultiNotComplete(body: ReservedArrayId, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedMultiNotComplete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/reserved_multi_not_complete`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육 접수정보를 등록.
     * 입문교육 접수정보를 등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupReservedPost(body: Reserved, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupReservedPost(body: Reserved, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupReservedPost(body: Reserved, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupReservedPost(body: Reserved, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/group/reserved`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육 접수내역 정보를 변경.
     * 입문교육 접수내역 정보를 변경한다
     * @param reserved_id 입문교육접수내역아이디
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupReservedPut(reserved_id: string, body: Reserved, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupReservedPut(reserved_id: string, body: Reserved, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupReservedPut(reserved_id: string, body: Reserved, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupReservedPut(reserved_id: string, body: Reserved, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reserved_id === null || reserved_id === undefined) {
            throw new Error('Required parameter reserved_id was null or undefined when calling groupReservedPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reserved_id !== undefined && reserved_id !== null) {
            queryParameters = queryParameters.set('reserved_id', <any>reserved_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/reserved`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육일정을 코드로 사용 조회한다
     * 입문교육일정을 코드로 사용 조회한다
     * @param schedule_title 교육명
     * @param schedule_state 교육상태
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupScheduleCodeGet(schedule_title?: string, schedule_state?: '진행중' | '종료', observe?: 'body', reportProgress?: boolean): Observable<Array<Schedule>>;
    public groupScheduleCodeGet(schedule_title?: string, schedule_state?: '진행중' | '종료', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Schedule>>>;
    public groupScheduleCodeGet(schedule_title?: string, schedule_state?: '진행중' | '종료', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Schedule>>>;
    public groupScheduleCodeGet(schedule_title?: string, schedule_state?: '진행중' | '종료', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schedule_title !== undefined && schedule_title !== null) {
            queryParameters = queryParameters.set('schedule_title', <any>schedule_title);
        }
        if (schedule_state !== undefined && schedule_state !== null) {
            queryParameters = queryParameters.set('schedule_state', <any>schedule_state);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Schedule>>(`${this.basePath}/group/schedule_code`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육일정 교육일 정보를 삭제.
     * 입문교육일정 교육일 정보를 삭제한다.
     * @param schedule_id 입문교육일정 아이디
     * @param schedule_date_id 입문교육일 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupScheduleDateDelete(schedule_id: string, schedule_date_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupScheduleDateDelete(schedule_id: string, schedule_date_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupScheduleDateDelete(schedule_id: string, schedule_date_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupScheduleDateDelete(schedule_id: string, schedule_date_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (schedule_id === null || schedule_id === undefined) {
            throw new Error('Required parameter schedule_id was null or undefined when calling groupScheduleDateDelete.');
        }
        if (schedule_date_id === null || schedule_date_id === undefined) {
            throw new Error('Required parameter schedule_date_id was null or undefined when calling groupScheduleDateDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schedule_id !== undefined && schedule_id !== null) {
            queryParameters = queryParameters.set('schedule_id', <any>schedule_id);
        }
        if (schedule_date_id !== undefined && schedule_date_id !== null) {
            queryParameters = queryParameters.set('schedule_date_id', <any>schedule_date_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/group/schedule_date`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육일정 정보를 삭제.
     * 입문교육일정 정보를 삭제한다.
     * @param schedule_id 입문교육일정 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupScheduleDelete(schedule_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupScheduleDelete(schedule_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupScheduleDelete(schedule_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupScheduleDelete(schedule_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (schedule_id === null || schedule_id === undefined) {
            throw new Error('Required parameter schedule_id was null or undefined when calling groupScheduleDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schedule_id !== undefined && schedule_id !== null) {
            queryParameters = queryParameters.set('schedule_id', <any>schedule_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/group/schedule`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육일정을 조회
     * 입문교육일정을 조회한다
     * @param schedule_year 교육연도
     * @param schedule_title 교육명
     * @param schedule_dept_name 교육부서
     * @param schedule_type 활동구분
     * @param schedule_region 활동지역
     * @param schedule_state 교육상태
     * @param schedule_homepage_view_yn 홈페이지노출여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupScheduleGet(schedule_year?: string, schedule_title?: string, schedule_dept_name?: string, schedule_type?: string, schedule_region?: string, schedule_state?: '진행중' | '종료', schedule_homepage_view_yn?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<Schedule>>;
    public groupScheduleGet(schedule_year?: string, schedule_title?: string, schedule_dept_name?: string, schedule_type?: string, schedule_region?: string, schedule_state?: '진행중' | '종료', schedule_homepage_view_yn?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Schedule>>>;
    public groupScheduleGet(schedule_year?: string, schedule_title?: string, schedule_dept_name?: string, schedule_type?: string, schedule_region?: string, schedule_state?: '진행중' | '종료', schedule_homepage_view_yn?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Schedule>>>;
    public groupScheduleGet(schedule_year?: string, schedule_title?: string, schedule_dept_name?: string, schedule_type?: string, schedule_region?: string, schedule_state?: '진행중' | '종료', schedule_homepage_view_yn?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schedule_year !== undefined && schedule_year !== null) {
            queryParameters = queryParameters.set('schedule_year', <any>schedule_year);
        }
        if (schedule_title !== undefined && schedule_title !== null) {
            queryParameters = queryParameters.set('schedule_title', <any>schedule_title);
        }
        if (schedule_dept_name !== undefined && schedule_dept_name !== null) {
            queryParameters = queryParameters.set('schedule_dept_name', <any>schedule_dept_name);
        }
        if (schedule_type !== undefined && schedule_type !== null) {
            queryParameters = queryParameters.set('schedule_type', <any>schedule_type);
        }
        if (schedule_region !== undefined && schedule_region !== null) {
            queryParameters = queryParameters.set('schedule_region', <any>schedule_region);
        }
        if (schedule_state !== undefined && schedule_state !== null) {
            queryParameters = queryParameters.set('schedule_state', <any>schedule_state);
        }
        if (schedule_homepage_view_yn !== undefined && schedule_homepage_view_yn !== null) {
            queryParameters = queryParameters.set('schedule_homepage_view_yn', <any>schedule_homepage_view_yn);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Schedule>>(`${this.basePath}/group/schedule`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육 일정현황을 조회
     * 입문교육 일정현황을 조회한다
     * @param gte_date 입문교육일시작일자
     * @param lte_date 입문교육일종료일자
     * @param schedule_dept_name 교육부서
     * @param schedule_region 활동지역
     * @param schedule_type 활동구분
     * @param schedule_state 교육상태
     * @param schedule_date_state 교육일상태
     * @param schedule_homepage_view_yn 홈페이지노출
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupScheduleListGet(gte_date?: string, lte_date?: string, schedule_dept_name?: string, schedule_region?: string, schedule_type?: string, schedule_state?: '진행중' | '종료', schedule_date_state?: '진행중' | '종료', schedule_homepage_view_yn?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<ScheduleList>>;
    public groupScheduleListGet(gte_date?: string, lte_date?: string, schedule_dept_name?: string, schedule_region?: string, schedule_type?: string, schedule_state?: '진행중' | '종료', schedule_date_state?: '진행중' | '종료', schedule_homepage_view_yn?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScheduleList>>>;
    public groupScheduleListGet(gte_date?: string, lte_date?: string, schedule_dept_name?: string, schedule_region?: string, schedule_type?: string, schedule_state?: '진행중' | '종료', schedule_date_state?: '진행중' | '종료', schedule_homepage_view_yn?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScheduleList>>>;
    public groupScheduleListGet(gte_date?: string, lte_date?: string, schedule_dept_name?: string, schedule_region?: string, schedule_type?: string, schedule_state?: '진행중' | '종료', schedule_date_state?: '진행중' | '종료', schedule_homepage_view_yn?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (schedule_dept_name !== undefined && schedule_dept_name !== null) {
            queryParameters = queryParameters.set('schedule_dept_name', <any>schedule_dept_name);
        }
        if (schedule_region !== undefined && schedule_region !== null) {
            queryParameters = queryParameters.set('schedule_region', <any>schedule_region);
        }
        if (schedule_type !== undefined && schedule_type !== null) {
            queryParameters = queryParameters.set('schedule_type', <any>schedule_type);
        }
        if (schedule_state !== undefined && schedule_state !== null) {
            queryParameters = queryParameters.set('schedule_state', <any>schedule_state);
        }
        if (schedule_date_state !== undefined && schedule_date_state !== null) {
            queryParameters = queryParameters.set('schedule_date_state', <any>schedule_date_state);
        }
        if (schedule_homepage_view_yn !== undefined && schedule_homepage_view_yn !== null) {
            queryParameters = queryParameters.set('schedule_homepage_view_yn', <any>schedule_homepage_view_yn);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<ScheduleList>>(`${this.basePath}/group/schedule_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육일정 정보를 등록.
     * 입문교육일정 정보를 등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupSchedulePost(body: Schedule, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupSchedulePost(body: Schedule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupSchedulePost(body: Schedule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupSchedulePost(body: Schedule, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupSchedulePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/group/schedule`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 입문교육일정 정보를 변경.
     * 입문교육일정 정보를 변경한다
     * @param schedule_id 입문교육일정 아이디
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupSchedulePut(schedule_id: string, body: Schedule, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupSchedulePut(schedule_id: string, body: Schedule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupSchedulePut(schedule_id: string, body: Schedule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupSchedulePut(schedule_id: string, body: Schedule, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (schedule_id === null || schedule_id === undefined) {
            throw new Error('Required parameter schedule_id was null or undefined when calling groupSchedulePut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupSchedulePut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schedule_id !== undefined && schedule_id !== null) {
            queryParameters = queryParameters.set('schedule_id', <any>schedule_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/schedule`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사회봉사이수현황을 조회
     * 사회봉사이수현황을 조회한다
     * @param group_id 사회봉사대학그룹 아이디
     * @param college_id 대학교아이디
     * @param act_dept_name 배치처
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰번호
     * @param completion_yn 이수여부
     * @param reserved_evaluation_submit_yn 평가서제출여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupSocialCompletionGet(group_id: string, college_id?: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, completion_yn?: '이수' | '미이수', reserved_evaluation_submit_yn?: '제출' | '미제출', observe?: 'body', reportProgress?: boolean): Observable<Array<Social>>;
    public groupSocialCompletionGet(group_id: string, college_id?: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, completion_yn?: '이수' | '미이수', reserved_evaluation_submit_yn?: '제출' | '미제출', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Social>>>;
    public groupSocialCompletionGet(group_id: string, college_id?: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, completion_yn?: '이수' | '미이수', reserved_evaluation_submit_yn?: '제출' | '미제출', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Social>>>;
    public groupSocialCompletionGet(group_id: string, college_id?: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, completion_yn?: '이수' | '미이수', reserved_evaluation_submit_yn?: '제출' | '미제출', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupSocialCompletionGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (group_id !== undefined && group_id !== null) {
            queryParameters = queryParameters.set('group_id', <any>group_id);
        }
        if (college_id !== undefined && college_id !== null) {
            queryParameters = queryParameters.set('college_id', <any>college_id);
        }
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', <any>act_dept_name);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }
        if (completion_yn !== undefined && completion_yn !== null) {
            queryParameters = queryParameters.set('completion_yn', <any>completion_yn);
        }
        if (reserved_evaluation_submit_yn !== undefined && reserved_evaluation_submit_yn !== null) {
            queryParameters = queryParameters.set('reserved_evaluation_submit_yn', <any>reserved_evaluation_submit_yn);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Social>>(`${this.basePath}/group/social_completion`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 사회봉사서류제출미제출여부변경.
     * 사회봉사서류제출미제출여부변경.
     * @param reserved_evaluation_submit_yn 평가서제출여부
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupSocialCompletionPut(reserved_evaluation_submit_yn: '제출' | '미제출', body: ReservedArrayId, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupSocialCompletionPut(reserved_evaluation_submit_yn: '제출' | '미제출', body: ReservedArrayId, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupSocialCompletionPut(reserved_evaluation_submit_yn: '제출' | '미제출', body: ReservedArrayId, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupSocialCompletionPut(reserved_evaluation_submit_yn: '제출' | '미제출', body: ReservedArrayId, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reserved_evaluation_submit_yn === null || reserved_evaluation_submit_yn === undefined) {
            throw new Error('Required parameter reserved_evaluation_submit_yn was null or undefined when calling groupSocialCompletionPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupSocialCompletionPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reserved_evaluation_submit_yn !== undefined && reserved_evaluation_submit_yn !== null) {
            queryParameters = queryParameters.set('reserved_evaluation_submit_yn', <any>reserved_evaluation_submit_yn);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/social_completion`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대상그룹관리 정보를 삭제.
     * 대상그룹관리 정보를 삭제한다.
     * @param target_id 대상그룹관리 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupTargetDelete(target_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupTargetDelete(target_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupTargetDelete(target_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupTargetDelete(target_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (target_id === null || target_id === undefined) {
            throw new Error('Required parameter target_id was null or undefined when calling groupTargetDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (target_id !== undefined && target_id !== null) {
            queryParameters = queryParameters.set('target_id', <any>target_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/group/target`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대상그룹 정보를 조회한다
     * 대상그룹 정보를 조회한다
     * @param gte_date 대상관리시작일자
     * @param lte_date 대상관리종료일자
     * @param target_name 대상그룹명
     * @param target_remark 대상그룹설명
     * @param label_name 검색라벨명
     * @param item_issue_emp_name 등록자
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupTargetGet(gte_date: string, lte_date: string, target_name?: string, target_remark?: string, label_name?: string, item_issue_emp_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Target>>;
    public groupTargetGet(gte_date: string, lte_date: string, target_name?: string, target_remark?: string, label_name?: string, item_issue_emp_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Target>>>;
    public groupTargetGet(gte_date: string, lte_date: string, target_name?: string, target_remark?: string, label_name?: string, item_issue_emp_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Target>>>;
    public groupTargetGet(gte_date: string, lte_date: string, target_name?: string, target_remark?: string, label_name?: string, item_issue_emp_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling groupTargetGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling groupTargetGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (target_name !== undefined && target_name !== null) {
            queryParameters = queryParameters.set('target_name', <any>target_name);
        }
        if (target_remark !== undefined && target_remark !== null) {
            queryParameters = queryParameters.set('target_remark', <any>target_remark);
        }
        if (label_name !== undefined && label_name !== null) {
            queryParameters = queryParameters.set('label_name', <any>label_name);
        }
        if (item_issue_emp_name !== undefined && item_issue_emp_name !== null) {
            queryParameters = queryParameters.set('item_issue_emp_name', <any>item_issue_emp_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Target>>(`${this.basePath}/group/target`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대상그룹 정보를 아이디로 조회한다
     * 대상그룹 정보를 아이디로 조회한다
     * @param target_id 대상그룹아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupTargetIdGet(target_id: string, observe?: 'body', reportProgress?: boolean): Observable<Target>;
    public groupTargetIdGet(target_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Target>>;
    public groupTargetIdGet(target_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Target>>;
    public groupTargetIdGet(target_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (target_id === null || target_id === undefined) {
            throw new Error('Required parameter target_id was null or undefined when calling groupTargetIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (target_id !== undefined && target_id !== null) {
            queryParameters = queryParameters.set('target_id', <any>target_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Target>(`${this.basePath}/group/target_id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대상자추가_대상자 정보를 검색한다
     * 대상자 정보를 검색
     * @param act_dept_name 활동지명
     * @param first_gte_date 최초활동일시작일자
     * @param first_lte_date 최초활동일종료일자
     * @param final_gte_date 최종활동일시작일자
     * @param final_lte_date 최종활동일종료일자
     * @param gte_time 누적총시간시작시간
     * @param lte_time 누적총시간종료시간
     * @param gte_count 누적총횟수시작
     * @param lte_count 누적총횟수종료
     * @param birth_gte_date 생일시작일자
     * @param birth_lte_date 생일종료일자
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰
     * @param angel_now_status 현재활동상태
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param angel_gender 성별
     * @param angel_position 소속단체
     * @param have_tag_name 포함태그
     * @param except_tag_name 제외태그
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupTargetListGet(act_dept_name?: string, first_gte_date?: string, first_lte_date?: string, final_gte_date?: string, final_lte_date?: string, gte_time?: string, lte_time?: string, gte_count?: string, lte_count?: string, birth_gte_date?: string, birth_lte_date?: string, angel_name?: string, angel_cellp_no?: string, angel_now_status?: '활동중' | '활동대기' | '일시중단' | '활동종료', angel_type?: string, angel_term?: string, angel_gender?: '남' | '여', angel_position?: string, have_tag_name?: string, except_tag_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TargetList>>;
    public groupTargetListGet(act_dept_name?: string, first_gte_date?: string, first_lte_date?: string, final_gte_date?: string, final_lte_date?: string, gte_time?: string, lte_time?: string, gte_count?: string, lte_count?: string, birth_gte_date?: string, birth_lte_date?: string, angel_name?: string, angel_cellp_no?: string, angel_now_status?: '활동중' | '활동대기' | '일시중단' | '활동종료', angel_type?: string, angel_term?: string, angel_gender?: '남' | '여', angel_position?: string, have_tag_name?: string, except_tag_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetList>>>;
    public groupTargetListGet(act_dept_name?: string, first_gte_date?: string, first_lte_date?: string, final_gte_date?: string, final_lte_date?: string, gte_time?: string, lte_time?: string, gte_count?: string, lte_count?: string, birth_gte_date?: string, birth_lte_date?: string, angel_name?: string, angel_cellp_no?: string, angel_now_status?: '활동중' | '활동대기' | '일시중단' | '활동종료', angel_type?: string, angel_term?: string, angel_gender?: '남' | '여', angel_position?: string, have_tag_name?: string, except_tag_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetList>>>;
    public groupTargetListGet(act_dept_name?: string, first_gte_date?: string, first_lte_date?: string, final_gte_date?: string, final_lte_date?: string, gte_time?: string, lte_time?: string, gte_count?: string, lte_count?: string, birth_gte_date?: string, birth_lte_date?: string, angel_name?: string, angel_cellp_no?: string, angel_now_status?: '활동중' | '활동대기' | '일시중단' | '활동종료', angel_type?: string, angel_term?: string, angel_gender?: '남' | '여', angel_position?: string, have_tag_name?: string, except_tag_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', <any>act_dept_name);
        }
        if (first_gte_date !== undefined && first_gte_date !== null) {
            queryParameters = queryParameters.set('first_gte_date', <any>first_gte_date);
        }
        if (first_lte_date !== undefined && first_lte_date !== null) {
            queryParameters = queryParameters.set('first_lte_date', <any>first_lte_date);
        }
        if (final_gte_date !== undefined && final_gte_date !== null) {
            queryParameters = queryParameters.set('final_gte_date', <any>final_gte_date);
        }
        if (final_lte_date !== undefined && final_lte_date !== null) {
            queryParameters = queryParameters.set('final_lte_date', <any>final_lte_date);
        }
        if (gte_time !== undefined && gte_time !== null) {
            queryParameters = queryParameters.set('gte_time', <any>gte_time);
        }
        if (lte_time !== undefined && lte_time !== null) {
            queryParameters = queryParameters.set('lte_time', <any>lte_time);
        }
        if (gte_count !== undefined && gte_count !== null) {
            queryParameters = queryParameters.set('gte_count', <any>gte_count);
        }
        if (lte_count !== undefined && lte_count !== null) {
            queryParameters = queryParameters.set('lte_count', <any>lte_count);
        }
        if (birth_gte_date !== undefined && birth_gte_date !== null) {
            queryParameters = queryParameters.set('birth_gte_date', <any>birth_gte_date);
        }
        if (birth_lte_date !== undefined && birth_lte_date !== null) {
            queryParameters = queryParameters.set('birth_lte_date', <any>birth_lte_date);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }
        if (angel_now_status !== undefined && angel_now_status !== null) {
            queryParameters = queryParameters.set('angel_now_status', <any>angel_now_status);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }
        if (angel_gender !== undefined && angel_gender !== null) {
            queryParameters = queryParameters.set('angel_gender', <any>angel_gender);
        }
        if (angel_position !== undefined && angel_position !== null) {
            queryParameters = queryParameters.set('angel_position', <any>angel_position);
        }
        if (have_tag_name !== undefined && have_tag_name !== null) {
            queryParameters = queryParameters.set('have_tag_name', <any>have_tag_name);
        }
        if (except_tag_name !== undefined && except_tag_name !== null) {
            queryParameters = queryParameters.set('except_tag_name', <any>except_tag_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<TargetList>>(`${this.basePath}/group/target_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대상그룹관리 대상자 정보를 멀티 삭제.
     * 대상그룹관리 대상자 정보를 멀티 삭제한다.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupTargetMultiDelete(body: TargetArrayId, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupTargetMultiDelete(body: TargetArrayId, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupTargetMultiDelete(body: TargetArrayId, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupTargetMultiDelete(body: TargetArrayId, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupTargetMultiDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/target_multi_delete`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대상그룹 정보를 등록.
     * 대상그룹 정보를 등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupTargetPut(body: Target, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupTargetPut(body: Target, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupTargetPut(body: Target, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupTargetPut(body: Target, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupTargetPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/group/target`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
