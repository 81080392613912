import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MatDatepicker } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { take, tap, takeUntil } from 'rxjs/operators';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { EsDreceiptService } from 'src/app/restapi/be-esquery';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = YEAR_FORMATS;
var DreceiptIssueComponent = /** @class */ (function () {
    function DreceiptIssueComponent(fb, esDreceiptService) {
        this.fb = fb;
        this.esDreceiptService = esDreceiptService;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['joiner_div', 'name', 'total', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    }
    DreceiptIssueComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    DreceiptIssueComponent.prototype.ngOnDestroy = function () { };
    DreceiptIssueComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            year: moment(),
            is_auto: '',
            is_type: '발행일'
        });
    };
    Object.defineProperty(DreceiptIssueComponent.prototype, "fc_year", {
        get: function () { return this.form.get('year'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DreceiptIssueComponent.prototype, "fc_isAuto", {
        get: function () { return this.form.get('is_auto'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DreceiptIssueComponent.prototype, "fc_isType", {
        get: function () { return this.form.get('is_type'); },
        enumerable: true,
        configurable: true
    });
    DreceiptIssueComponent.prototype.chosenYearHandler = function (normalizedMonth, datepicker) {
        this.fc_year.setValue(normalizedMonth);
        this.search();
        datepicker.close();
    };
    DreceiptIssueComponent.prototype.convertName = function (data) {
        if (data.total_price)
            return '금액';
        if (data.total_count)
            return '건수';
        return '';
    };
    DreceiptIssueComponent.prototype.convertTotal = function (data) {
        if (data.total_price)
            return data.total_price;
        if (data.total_count)
            return data.total_count;
        return '';
    };
    DreceiptIssueComponent.prototype.convertMonthInfo = function (month, dreceiptIssue) {
        if (dreceiptIssue.total_price) {
            var dreceiptMonthly = dreceiptIssue.monthly_info.find(function (x) { return x.date.split("-")[1] == month; });
            if (dreceiptMonthly)
                return dreceiptMonthly.price;
        }
        if (dreceiptIssue.total_count) {
            var dreceiptMonthly = dreceiptIssue.monthly_info.find(function (x) { return x.date.split("-")[1] == month; });
            if (dreceiptMonthly)
                return dreceiptMonthly.count;
        }
        return '';
    };
    DreceiptIssueComponent.prototype.search = function () {
        this.dreceiptIssueGet();
    };
    DreceiptIssueComponent.prototype.initPage = function (sort) {
        var _this = this;
        if (!this.dataList)
            this.dataList = [];
        var sorted = this.dataList;
        if (sort) {
            if (sort.active == '01' || sort.active == '02' || sort.active == '03' || sort.active == '04' || sort.active == '05' || sort.active == '06' ||
                sort.active == '07' || sort.active == '08' || sort.active == '09' || sort.active == '10' || sort.active == '11' || sort.active == '12') {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    var a_data = Number(_this.convertMonthInfo(sort.active, a));
                    var b_data = Number(_this.convertMonthInfo(sort.active, b));
                    if (a_data < b_data)
                        result = -1;
                    else if (a_data > b_data)
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
            else if (sort.active == 'name') {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    var a_data = _this.convertName(a);
                    var b_data = _this.convertName(b);
                    if (a_data < b_data)
                        result = -1;
                    else if (a_data > b_data)
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
            else if (sort.active == 'total') {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    var a_data = Number(_this.convertTotal(a));
                    var b_data = Number(_this.convertTotal(b));
                    if (a_data < b_data)
                        result = -1;
                    else if (a_data > b_data)
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
            else {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    if (a[sort.active] == undefined)
                        a[sort.active] = "";
                    if (b[sort.active] == undefined)
                        b[sort.active] = "";
                    if (a[sort.active] < b[sort.active])
                        result = -1;
                    else if (a[sort.active] > b[sort.active])
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
        }
        this.dataList = sorted;
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    DreceiptIssueComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '기부영수증 통계');
        XLSX.writeFile(wb, '기부영수증 통계.xlsx');
    };
    DreceiptIssueComponent.prototype.dreceiptIssueGet = function () {
        var _this = this;
        this.dataList = [];
        this.esDreceiptService.dreceiptIssueGet(dateFormat_Year(this.fc_year.value), this.fc_isAuto.value == '' ? null : this.fc_isAuto.value, this.fc_isType.value).pipe(take(1)).subscribe(function (res) {
            _this.dataList = res.div;
            _this.initPage(_this.sort);
        }, function (err) {
            console.error(err);
            _this.initPage(_this.sort);
        });
    };
    return DreceiptIssueComponent;
}());
export { DreceiptIssueComponent };
export { ɵ0 };
