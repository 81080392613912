/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsFailure } from '../model/esFailure';
import { EsPosPostPut } from '../model/esPosPostPut';
import { EsShop } from '../model/esShop';
import { EsShopContractInfo } from '../model/esShopContractInfo';
import { EsShopGivingProductionStatistics } from '../model/esShopGivingProductionStatistics';
import { EsShopInfo } from '../model/esShopInfo';
import { EsShopList } from '../model/esShopList';
import { EsShopMaintenanceInfo } from '../model/esShopMaintenanceInfo';
import { EsShopRealSaleInfo } from '../model/esShopRealSaleInfo';
import { EsShopTargetSaleInfo } from '../model/esShopTargetSaleInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsShopsService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 매장폐점 체크(내부체크)
     * 매장폐점 체크 유무 파악
     * @param shop_id 매장코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsCheckShopCloseGet(shop_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public shopsCheckShopCloseGet(shop_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public shopsCheckShopCloseGet(shop_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public shopsCheckShopCloseGet(shop_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling shopsCheckShopCloseGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/shops/check_shop_close`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 동일매장명 체크
     * 매장명 존재 유무 파악
     * @param shop_name 매장명
     * @param shop_type 매장 또는 되살림터 중 택1
     * @param shop_id 매장코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsCheckShopNameGet(shop_name: string, shop_type: '매장' | '되살림터', shop_id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public shopsCheckShopNameGet(shop_name: string, shop_type: '매장' | '되살림터', shop_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public shopsCheckShopNameGet(shop_name: string, shop_type: '매장' | '되살림터', shop_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public shopsCheckShopNameGet(shop_name: string, shop_type: '매장' | '되살림터', shop_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_name === null || shop_name === undefined) {
            throw new Error('Required parameter shop_name was null or undefined when calling shopsCheckShopNameGet.');
        }
        if (shop_type === null || shop_type === undefined) {
            throw new Error('Required parameter shop_type was null or undefined when calling shopsCheckShopNameGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', <any>shop_type);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/shops/check_shop_name`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 계약정보 검색(매장아이디)
     * 매장계약정보 전체 리스트 조회
     * @param shop_id 매장아이디
     * @param shop_number 차수정보
     * @param page_number 리스트 페이지 번호
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsContractGet(shop_id: string, shop_number: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopContractInfo>>;
    public shopsContractGet(shop_id: string, shop_number: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopContractInfo>>>;
    public shopsContractGet(shop_id: string, shop_number: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopContractInfo>>>;
    public shopsContractGet(shop_id: string, shop_number: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling shopsContractGet.');
        }
        if (shop_number === null || shop_number === undefined) {
            throw new Error('Required parameter shop_number was null or undefined when calling shopsContractGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (shop_number !== undefined && shop_number !== null) {
            queryParameters = queryParameters.set('shop_number', <any>shop_number);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopContractInfo>>(`${this.basePath}/shops/contract`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 엑셀출력검색
     * 삭제및 폐지제외한 매장 기본정보 검색
     * @param shop_type 매장 또는 되살림터 중 택1
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsExcelGet(shop_type?: '매장' | '되살림터', observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopList>>;
    public shopsExcelGet(shop_type?: '매장' | '되살림터', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopList>>>;
    public shopsExcelGet(shop_type?: '매장' | '되살림터', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopList>>>;
    public shopsExcelGet(shop_type?: '매장' | '되살림터', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', <any>shop_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopList>>(`${this.basePath}/shops/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장정보 검색(매장_부서, 담당자, 지역, 중지매장포함여부)
     * 행사세부정보, 참여협력처, 참여매장, 히스토리 등
     * @param shop_type 매장 또는 되살림터 중 택1
     * @param shop_name_or_dept_name 매장_부서
     * @param manager_emp_name 담당자
     * @param shop_local_name 지역
     * @param shop_close_flg 중지매장포함여부(Y/N)
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsGet(shop_type: '매장' | '되살림터', shop_name_or_dept_name?: string, manager_emp_name?: string, shop_local_name?: string, shop_close_flg?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShop>>;
    public shopsGet(shop_type: '매장' | '되살림터', shop_name_or_dept_name?: string, manager_emp_name?: string, shop_local_name?: string, shop_close_flg?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShop>>>;
    public shopsGet(shop_type: '매장' | '되살림터', shop_name_or_dept_name?: string, manager_emp_name?: string, shop_local_name?: string, shop_close_flg?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShop>>>;
    public shopsGet(shop_type: '매장' | '되살림터', shop_name_or_dept_name?: string, manager_emp_name?: string, shop_local_name?: string, shop_close_flg?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_type === null || shop_type === undefined) {
            throw new Error('Required parameter shop_type was null or undefined when calling shopsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_name_or_dept_name !== undefined && shop_name_or_dept_name !== null) {
            queryParameters = queryParameters.set('shop_name_or_dept_name', <any>shop_name_or_dept_name);
        }
        if (manager_emp_name !== undefined && manager_emp_name !== null) {
            queryParameters = queryParameters.set('manager_emp_name', <any>manager_emp_name);
        }
        if (shop_local_name !== undefined && shop_local_name !== null) {
            queryParameters = queryParameters.set('shop_local_name', <any>shop_local_name);
        }
        if (shop_close_flg !== undefined && shop_close_flg !== null) {
            queryParameters = queryParameters.set('shop_close_flg', <any>shop_close_flg);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', <any>shop_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShop>>(`${this.basePath}/shops`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장점기증 전체 엑셀 다운로드
     * 
     * @param gte_date 
     * @param lte_date 
     * @param shop_id 매장아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsGivingsProductionExcelGet(gte_date: string, lte_date: string, shop_id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public shopsGivingsProductionExcelGet(gte_date: string, lte_date: string, shop_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public shopsGivingsProductionExcelGet(gte_date: string, lte_date: string, shop_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public shopsGivingsProductionExcelGet(gte_date: string, lte_date: string, shop_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling shopsGivingsProductionExcelGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling shopsGivingsProductionExcelGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/shops/givings/production/excel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장별 점기증 통계
     * 
     * @param gte_date 
     * @param lte_date 
     * @param shop_id 부서아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsGivingsProductionGet(gte_date: string, lte_date: string, shop_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopGivingProductionStatistics>>;
    public shopsGivingsProductionGet(gte_date: string, lte_date: string, shop_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopGivingProductionStatistics>>>;
    public shopsGivingsProductionGet(gte_date: string, lte_date: string, shop_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopGivingProductionStatistics>>>;
    public shopsGivingsProductionGet(gte_date: string, lte_date: string, shop_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling shopsGivingsProductionGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling shopsGivingsProductionGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopGivingProductionStatistics>>(`${this.basePath}/shops/givings/production`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부서코드로 소속 매장검색
     * 부서코드로 하위 매장코드와 명을 검색한다
     * @param dept_id 부서코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsInfoGet(dept_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopInfo>>;
    public shopsInfoGet(dept_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopInfo>>>;
    public shopsInfoGet(dept_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopInfo>>>;
    public shopsInfoGet(dept_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling shopsInfoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopInfo>>(`${this.basePath}/shops/shopinfo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 관리정보 검색(매장아이디)
     * 매장계약정보 전체 리스트 조회
     * @param shop_id 매장아이디
     * @param shop_number 차수정보
     * @param shop_maintenance_type 관리구분 (되살림터의 경우 shop_maintenance_type 임)
     * @param page_number 리스트 페이지 번호
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsMaintenanceGet(shop_id: string, shop_number: string, shop_maintenance_type?: string, page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopMaintenanceInfo>>;
    public shopsMaintenanceGet(shop_id: string, shop_number: string, shop_maintenance_type?: string, page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopMaintenanceInfo>>>;
    public shopsMaintenanceGet(shop_id: string, shop_number: string, shop_maintenance_type?: string, page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopMaintenanceInfo>>>;
    public shopsMaintenanceGet(shop_id: string, shop_number: string, shop_maintenance_type?: string, page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling shopsMaintenanceGet.');
        }
        if (shop_number === null || shop_number === undefined) {
            throw new Error('Required parameter shop_number was null or undefined when calling shopsMaintenanceGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (shop_number !== undefined && shop_number !== null) {
            queryParameters = queryParameters.set('shop_number', <any>shop_number);
        }
        if (shop_maintenance_type !== undefined && shop_maintenance_type !== null) {
            queryParameters = queryParameters.set('shop_maintenance_type', <any>shop_maintenance_type);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopMaintenanceInfo>>(`${this.basePath}/shops/maintenance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 Like검색 완성
     * 행사 정보 등록 시 참여 매장명에서 text 입력 시 Like검색 기능 제공
     * @param shop_type 매장 또는 되살림터 중 택1
     * @param shop_name 매장명
     * @param dept_id 부서아이디
     * @param shop_virtual 가상매장여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsMetaGet(shop_type: '매장' | '되살림터', shop_name?: string, dept_id?: string, shop_virtual?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<EsShop>>;
    public shopsMetaGet(shop_type: '매장' | '되살림터', shop_name?: string, dept_id?: string, shop_virtual?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShop>>>;
    public shopsMetaGet(shop_type: '매장' | '되살림터', shop_name?: string, dept_id?: string, shop_virtual?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShop>>>;
    public shopsMetaGet(shop_type: '매장' | '되살림터', shop_name?: string, dept_id?: string, shop_virtual?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_type === null || shop_type === undefined) {
            throw new Error('Required parameter shop_type was null or undefined when calling shopsMetaGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', <any>shop_type);
        }
        if (shop_name !== undefined && shop_name !== null) {
            queryParameters = queryParameters.set('shop_name', <any>shop_name);
        }
        if (dept_id !== undefined && dept_id !== null) {
            queryParameters = queryParameters.set('dept_id', <any>dept_id);
        }
        if (shop_virtual !== undefined && shop_virtual !== null) {
            queryParameters = queryParameters.set('shop_virtual', <any>shop_virtual);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShop>>(`${this.basePath}/shops/meta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 권한관계없이 모든 매장정보 검색(매장_부서, 담당자, 지역, 중지매장포함여부)
     * 행사세부정보, 참여협력처, 참여매장, 히스토리 등
     * @param shop_type 매장 또는 되살림터 중 택1
     * @param page_number 
     * @param shop_name_or_dept_name 매장_부서명명
     * @param manager_emp_name 담당자
     * @param shop_local_name 지역
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsNoAuthGet(shop_type: '매장' | '되살림터', page_number?: string, shop_name_or_dept_name?: string, manager_emp_name?: string, shop_local_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShop>>;
    public shopsNoAuthGet(shop_type: '매장' | '되살림터', page_number?: string, shop_name_or_dept_name?: string, manager_emp_name?: string, shop_local_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShop>>>;
    public shopsNoAuthGet(shop_type: '매장' | '되살림터', page_number?: string, shop_name_or_dept_name?: string, manager_emp_name?: string, shop_local_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShop>>>;
    public shopsNoAuthGet(shop_type: '매장' | '되살림터', page_number?: string, shop_name_or_dept_name?: string, manager_emp_name?: string, shop_local_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_type === null || shop_type === undefined) {
            throw new Error('Required parameter shop_type was null or undefined when calling shopsNoAuthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', <any>shop_type);
        }
        if (shop_name_or_dept_name !== undefined && shop_name_or_dept_name !== null) {
            queryParameters = queryParameters.set('shop_name_or_dept_name', <any>shop_name_or_dept_name);
        }
        if (manager_emp_name !== undefined && manager_emp_name !== null) {
            queryParameters = queryParameters.set('manager_emp_name', <any>manager_emp_name);
        }
        if (shop_local_name !== undefined && shop_local_name !== null) {
            queryParameters = queryParameters.set('shop_local_name', <any>shop_local_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShop>>(`${this.basePath}/shops/no_auth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 4자리 Pos 코드 생성 및 조회
     * 매장별 Pos 코드 고유 생성 및 조회로 매장생성 시 4자리 Pos 코드를 입력받게 함(수정불가) 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsPoscodeGenerateGet(observe?: 'body', reportProgress?: boolean): Observable<EsPosPostPut>;
    public shopsPoscodeGenerateGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsPosPostPut>>;
    public shopsPoscodeGenerateGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsPosPostPut>>;
    public shopsPoscodeGenerateGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsPosPostPut>(`${this.basePath}/shops/poscode_generate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 연도별 매장 실매출액 검색
     * 연도별 매장 실매출액 검색한다
     * @param shop_id 매장아이디
     * @param target_year 목표매출연도
     * @param target_percent 가중치(1~999%)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsRealsalesGet(shop_id: string, target_year: number, target_percent: number, observe?: 'body', reportProgress?: boolean): Observable<EsShopRealSaleInfo>;
    public shopsRealsalesGet(shop_id: string, target_year: number, target_percent: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsShopRealSaleInfo>>;
    public shopsRealsalesGet(shop_id: string, target_year: number, target_percent: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsShopRealSaleInfo>>;
    public shopsRealsalesGet(shop_id: string, target_year: number, target_percent: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (shop_id === null || shop_id === undefined) {
            throw new Error('Required parameter shop_id was null or undefined when calling shopsRealsalesGet.');
        }
        if (target_year === null || target_year === undefined) {
            throw new Error('Required parameter target_year was null or undefined when calling shopsRealsalesGet.');
        }
        if (target_percent === null || target_percent === undefined) {
            throw new Error('Required parameter target_percent was null or undefined when calling shopsRealsalesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop_id !== undefined && shop_id !== null) {
            queryParameters = queryParameters.set('shop_id', <any>shop_id);
        }
        if (target_year !== undefined && target_year !== null) {
            queryParameters = queryParameters.set('target_year', <any>target_year);
        }
        if (target_percent !== undefined && target_percent !== null) {
            queryParameters = queryParameters.set('target_percent', <any>target_percent);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsShopRealSaleInfo>(`${this.basePath}/shops/realsales`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 매장 순번검색
     * 매장번호 순번을 검색하고 증가하여 매장을 생성
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsSeqGet(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public shopsSeqGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public shopsSeqGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public shopsSeqGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<number>(`${this.basePath}/shops/seq`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 연도별 매장 목표매출액 검색
     * 연도별 매장 목표매출액 검색한다
     * @param target_year 목표매출연도
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopsTargetsalesGet(target_year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<EsShopTargetSaleInfo>>;
    public shopsTargetsalesGet(target_year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsShopTargetSaleInfo>>>;
    public shopsTargetsalesGet(target_year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsShopTargetSaleInfo>>>;
    public shopsTargetsalesGet(target_year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (target_year === null || target_year === undefined) {
            throw new Error('Required parameter target_year was null or undefined when calling shopsTargetsalesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (target_year !== undefined && target_year !== null) {
            queryParameters = queryParameters.set('target_year', <any>target_year);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsShopTargetSaleInfo>>(`${this.basePath}/shops/targetsales`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
