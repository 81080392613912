/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다. 
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Code } from '../model/code';
import { Failure } from '../model/failure';
import { HRCodeId } from '../model/hRCodeId';
import { HrCode } from '../model/hrCode';
import { HrCodeDisplayName } from '../model/hrCodeDisplayName';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HrCodesService {

    protected basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 하위코드값 사용플래그 N 으로 업데이트(categories_code코드사용바람)
     * 
     * @param codes_id 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrCodesCodesIdCodesCodeDelete(codes_id: string, code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public hrCodesCodesIdCodesCodeDelete(codes_id: string, code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public hrCodesCodesIdCodesCodeDelete(codes_id: string, code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public hrCodesCodesIdCodesCodeDelete(codes_id: string, code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (codes_id === null || codes_id === undefined) {
            throw new Error('Required parameter codes_id was null or undefined when calling hrCodesCodesIdCodesCodeDelete.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling hrCodesCodesIdCodesCodeDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/x-www-form-urlencoded'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/hr_codes/${encodeURIComponent(String(codes_id))}/codes/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 하위코드값 조회(categories_code코드사용바람)
     * 
     * @param codes_id 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrCodesCodesIdCodesCodeGet(codes_id: string, code: string, observe?: 'body', reportProgress?: boolean): Observable<Code>;
    public hrCodesCodesIdCodesCodeGet(codes_id: string, code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Code>>;
    public hrCodesCodesIdCodesCodeGet(codes_id: string, code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Code>>;
    public hrCodesCodesIdCodesCodeGet(codes_id: string, code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (codes_id === null || codes_id === undefined) {
            throw new Error('Required parameter codes_id was null or undefined when calling hrCodesCodesIdCodesCodeGet.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling hrCodesCodesIdCodesCodeGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Code>(`${this.basePath}/hr_codes/${encodeURIComponent(String(codes_id))}/codes/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 하위코드값(디스플레이이름) 수정(categories_code코드사용바람)
     * 
     * @param codes_id 
     * @param code 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrCodesCodesIdCodesCodePut(codes_id: string, code: string, body: HrCodeDisplayName, observe?: 'body', reportProgress?: boolean): Observable<Code>;
    public hrCodesCodesIdCodesCodePut(codes_id: string, code: string, body: HrCodeDisplayName, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Code>>;
    public hrCodesCodesIdCodesCodePut(codes_id: string, code: string, body: HrCodeDisplayName, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Code>>;
    public hrCodesCodesIdCodesCodePut(codes_id: string, code: string, body: HrCodeDisplayName, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (codes_id === null || codes_id === undefined) {
            throw new Error('Required parameter codes_id was null or undefined when calling hrCodesCodesIdCodesCodePut.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling hrCodesCodesIdCodesCodePut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hrCodesCodesIdCodesCodePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Code>(`${this.basePath}/hr_codes/${encodeURIComponent(String(codes_id))}/codes/${encodeURIComponent(String(code))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 하위코드값 등록(categories_code코드사용바람)
     * 
     * @param codes_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrCodesCodesIdCodesPost(codes_id: string, body: HrCodeDisplayName, observe?: 'body', reportProgress?: boolean): Observable<HrCode>;
    public hrCodesCodesIdCodesPost(codes_id: string, body: HrCodeDisplayName, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HrCode>>;
    public hrCodesCodesIdCodesPost(codes_id: string, body: HrCodeDisplayName, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HrCode>>;
    public hrCodesCodesIdCodesPost(codes_id: string, body: HrCodeDisplayName, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (codes_id === null || codes_id === undefined) {
            throw new Error('Required parameter codes_id was null or undefined when calling hrCodesCodesIdCodesPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hrCodesCodesIdCodesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HrCode>(`${this.basePath}/hr_codes/${encodeURIComponent(String(codes_id))}/codes`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 인사코드 아이디로 하위 코드조회(categories_code코드사용바람)
     * 
     * @param codes_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrCodesCodesIdGet(codes_id: string, observe?: 'body', reportProgress?: boolean): Observable<HrCode>;
    public hrCodesCodesIdGet(codes_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HrCode>>;
    public hrCodesCodesIdGet(codes_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HrCode>>;
    public hrCodesCodesIdGet(codes_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (codes_id === null || codes_id === undefined) {
            throw new Error('Required parameter codes_id was null or undefined when calling hrCodesCodesIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<HrCode>(`${this.basePath}/hr_codes/${encodeURIComponent(String(codes_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 인사코드 아이디로 디스플레이 이름수정(categories_code코드사용바람)
     * 
     * @param codes_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrCodesCodesIdPut(codes_id: string, body: HrCodeDisplayName, observe?: 'body', reportProgress?: boolean): Observable<HrCode>;
    public hrCodesCodesIdPut(codes_id: string, body: HrCodeDisplayName, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HrCode>>;
    public hrCodesCodesIdPut(codes_id: string, body: HrCodeDisplayName, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HrCode>>;
    public hrCodesCodesIdPut(codes_id: string, body: HrCodeDisplayName, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (codes_id === null || codes_id === undefined) {
            throw new Error('Required parameter codes_id was null or undefined when calling hrCodesCodesIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hrCodesCodesIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<HrCode>(`${this.basePath}/hr_codes/${encodeURIComponent(String(codes_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 모든인사코드조회(categories_code코드사용바람)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrCodesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<HrCode>>;
    public hrCodesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HrCode>>>;
    public hrCodesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HrCode>>>;
    public hrCodesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<HrCode>>(`${this.basePath}/hr_codes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 인사코드 아이디 등록(categories_code코드사용바람)
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hrCodesPost(body: HRCodeId, observe?: 'body', reportProgress?: boolean): Observable<HRCodeId>;
    public hrCodesPost(body: HRCodeId, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HRCodeId>>;
    public hrCodesPost(body: HRCodeId, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HRCodeId>>;
    public hrCodesPost(body: HRCodeId, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hrCodesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HRCodeId>(`${this.basePath}/hr_codes`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
