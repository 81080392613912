import { OnInit, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { CodeService, StatisticsService } from 'src/app/restapi/be-angel';
var ɵ0 = DEFAULT_FORMATS;
var AngelDayTimeComponent = /** @class */ (function () {
    function AngelDayTimeComponent(fb, statisticsService, datePipe, codeService) {
        this.fb = fb;
        this.statisticsService = statisticsService;
        this.datePipe = datePipe;
        this.codeService = codeService;
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['department_name', 'team_name', 'location_name', 'mon_mor', 'mon_aft', 'mon_din', 'tue_mor', 'tue_aft', 'tue_din',
            'wed_mor', 'wed_aft', 'wed_din', 'thu_mor', 'thu_aft', 'thu_din', 'fri_mor', 'fri_aft', 'fri_din', 'sat_mor',
            'sat_aft', 'sat_din', 'sun_mor', 'sun_aft', 'sun_din'];
    }
    AngelDayTimeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.codeService.angelCodeGet("자원활동", "활동구분").subscribe(function (res) {
            _this.act_div_list = res;
            console.info(_this.act_div_list);
        });
        this.codeService.angelCodeGet("자원활동", "활동주기").subscribe(function (res) {
            _this.act_period_list = res;
            console.info(_this.act_period_list);
        });
        this.codeService.angelCodeGet("자원활동", "직업").subscribe(function (res) {
            _this.job_list = res;
            console.info(_this.job_list);
        });
    };
    AngelDayTimeComponent.prototype.ngOnDestroy = function () { };
    AngelDayTimeComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            act_div: [''],
            act_period: [''],
            job: [''],
            gender: [''],
            gte_date: [''],
            lte_date: [''],
            dept_name: [''],
            search_act_dept_name: ['']
        });
    };
    Object.defineProperty(AngelDayTimeComponent.prototype, "fc_actDiv", {
        get: function () { return this.form.get('act_div'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelDayTimeComponent.prototype, "fc_actPeriod", {
        get: function () { return this.form.get('act_period'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelDayTimeComponent.prototype, "fc_job", {
        get: function () { return this.form.get('job'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelDayTimeComponent.prototype, "fc_gender", {
        get: function () { return this.form.get('gender'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelDayTimeComponent.prototype, "fc_gteDate", {
        get: function () { return this.form.get('gte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelDayTimeComponent.prototype, "fc_lteDate", {
        get: function () { return this.form.get('lte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelDayTimeComponent.prototype, "fc_deptName", {
        get: function () { return this.form.get('search_act_dept_name'); },
        enumerable: true,
        configurable: true
    });
    AngelDayTimeComponent.prototype.rangeComplete = function (event) {
        this.form.patchValue({
            gte_date: event.startDate,
            lte_date: event.endDate
        });
    };
    AngelDayTimeComponent.prototype.search = function () {
        var _this = this;
        this.statisticsService.statisticsByTimetableGet(this.fc_deptName.value, this.fc_actDiv.value, this.fc_actPeriod.value, this.fc_job.value, this.fc_gender.value).subscribe({
            next: function (res) {
                console.info(res);
                _this.dataSource.data = res;
            },
            complete: function () {
                _this.sort.sortChange.emit({ active: _this.sort.active, direction: _this.sort.direction });
            }
        });
    };
    AngelDayTimeComponent.prototype.onClickExcelExport = function () {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(this.convertDatasource());
        var wscols = [{ wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 },
            { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 }, { wch: 7 },
            { wch: 7 }, { wch: 7 },];
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, '요일시간대별현황_');
        XLSX.writeFile(wb, "\uC694\uC77C\uC2DC\uAC04\uB300\uBCC4\uD604\uD669_" + this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + ".xlsx");
    };
    AngelDayTimeComponent.prototype.convertDatasource = function () {
        var xlsxDatasource = [];
        this.dataSource.data.forEach(function (item) {
            xlsxDatasource.push({
                '국': item.department_name, '팀': item.team_name, '매장': item.location_name,
                '월오전': item.mon_mor, '월오후': item.mon_aft, '월저녁': item.mon_din,
                '화오전': item.tue_mor, '화오후': item.tue_aft, '화저녁': item.tue_din,
                '수오전': item.wed_mor, '수오후': item.wed_aft, '수저녁': item.wed_din,
                '목오전': item.thu_mor, '목오후': item.thu_aft, '목저녁': item.thu_din,
                '금오전': item.fri_mor, '금오후': item.fri_aft, '금저녁': item.fri_din,
                '토오전': item.sat_mor, '토오후': item.sat_aft, '토저녁': item.sat_din,
                '일오전': item.sun_mor, '일오후': item.sun_aft, '일저녁': item.sun_din,
            });
        });
        return xlsxDatasource;
    };
    return AngelDayTimeComponent;
}());
export { AngelDayTimeComponent };
export { ɵ0 };
