import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { DatePipe } from '@angular/common';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
var ɵ0 = DEFAULT_FORMATS;
var GivingsByDonationComponent = /** @class */ (function () {
    function GivingsByDonationComponent(fb, esGivingsService, datePipe) {
        this.fb = fb;
        this.esGivingsService = esGivingsService;
        this.datePipe = datePipe;
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['give_confirm_date', 'give_reception_joiner_name', 'give_confirm_emp_dept_name', 'give_route', 'good_cl', 'good_li', 'good_br', 'good_el',
            'tot_amt'];
        this.paginationCollection = new Array();
        this.page = 1;
        this.pageSize = 10;
    }
    GivingsByDonationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    GivingsByDonationComponent.prototype.ngOnDestroy = function () { };
    GivingsByDonationComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            gte_date: [new Date()],
            lte_date: [new Date()],
            dept_name: ['']
        });
    };
    Object.defineProperty(GivingsByDonationComponent.prototype, "fc_gteDate", {
        get: function () { return this.form.get('gte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GivingsByDonationComponent.prototype, "fc_lteDate", {
        get: function () { return this.form.get('lte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GivingsByDonationComponent.prototype, "fc_deptName", {
        get: function () { return this.form.get('dept_name'); },
        enumerable: true,
        configurable: true
    });
    GivingsByDonationComponent.prototype.initPage = function (sort) {
        if (!this.paginationCollection)
            this.paginationCollection = [];
        var sorted = this.paginationCollection;
        if (sort) {
            sorted = this.paginationCollection.sort(function (a, b) {
                var result = 0;
                if (a[sort.active] == undefined)
                    a[sort.active] = "";
                if (b[sort.active] == undefined)
                    b[sort.active] = "";
                if (a[sort.active] < b[sort.active]) {
                    result = -1;
                }
                else if (a[sort.active] > b[sort.active]) {
                    result = 1;
                }
                return result * (sort.direction === 'asc' ? 1 : -1);
            });
        }
        this.paginationCollection = sorted;
        this.setPage(this.page);
    };
    GivingsByDonationComponent.prototype.setPage = function (currentPage) {
        this.page = currentPage;
        var dataSource = this.paginationCollection.slice((currentPage - 1) * this.pageSize, currentPage * this.pageSize);
        this.dataSource.data = dataSource;
        this.dataSource.sort = this.sort;
    };
    GivingsByDonationComponent.prototype.rangeComplete = function (event) {
        this.form.patchValue({
            gte_date: event.startDate,
            lte_date: event.endDate
        });
    };
    GivingsByDonationComponent.prototype.search = function () {
        var _this = this;
        this.esGivingsService.givingsSearchBoardGet(dateFormat(this.fc_gteDate.value), dateFormat(this.fc_lteDate.value), this.fc_deptName.value && this.fc_deptName.value.trim() != '' ?
            this.fc_deptName.value : null).subscribe({
            next: function (res) {
                _this.paginationCollection = res;
            },
            complete: function () {
                _this.setPage(_this.page);
                _this.sort.sortChange.emit({ active: _this.sort.active, direction: _this.sort.direction });
            }
        });
    };
    GivingsByDonationComponent.prototype.onClickExcelExport = function () {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(this.convertDatasource());
        var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 },
            { wch: 15 }];
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, '기증건별조회');
        XLSX.writeFile(wb, "\uAE30\uC99D\uAC74\uBCC4\uC870\uD68C_" + this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + ".xlsx");
    };
    GivingsByDonationComponent.prototype.convertDatasource = function () {
        var _this = this;
        var xlsxDatasource = [];
        this.paginationCollection.forEach(function (item) {
            xlsxDatasource.push({
                '기증일자': _this.datePipe.transform(item.give_confirm_date, 'yyyy-MM-dd'),
                '기증자명': item.give_reception_joiner_name,
                '기증처': item.give_confirm_emp_dept_name,
                '기증경로': item.give_route,
                '의류': item.good_cl,
                '잡화': item.good_li,
                '도서음반': item.good_br,
                '가전': item.good_el,
                '기증총점수': item.tot_amt
            });
        });
        return xlsxDatasource;
    };
    return GivingsByDonationComponent;
}());
export { GivingsByDonationComponent };
export { ɵ0 };
