import { Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './components/home/home.component';
var ɵ0 = {
    title: '베이스캠프',
    homeLink: '/',
    firstLevel: '홈'
}, ɵ1 = {
    title: '대시보드',
    homeLink: '/',
    firstLevel: '대시보드'
};
var routes = [
    {
        path: '', component: HomeComponent,
        data: ɵ0
    },
    {
        path: 'dashboard', component: DashboardComponent,
        data: ɵ1
    },
    { path: 'logout', component: LogoutComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
