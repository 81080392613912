import * as _moment from 'moment';

const moment = _moment;

export const DEFAULT_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'YYYY-MM-DD',
        monthYearA11yLabel: 'MM YYYY'
    }
};

export const YEAR_FORMATS = {
    parse: {
        dateInput: 'YYYY',
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY MMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY MMMM',
    }
};

export const MONTH_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM',
    },
    display: {
        dateInput: 'YYYY-MM',
        monthYearLabel: 'YYYY MMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY MMMM',
    }
};

export const dateFormat = (date: any) => {
    return moment(date).format("YYYY-MM-DD");
}

export const dateFormat_Month = (date: any) => {
    return moment(date).format("YYYY-MM");
}

export const dateFormat_Month2 = (date: any) => {
    return moment(date).format("YYYY MM");
}

export const dateFormat_Year = (date: any) => {
    return moment(date).format("YYYY");
}