import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EsShopsService } from 'src/app/restapi/be-esquery';
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
var ShopAutoCompleteComponent = /** @class */ (function () {
    function ShopAutoCompleteComponent(esShopsService) {
        this.esShopsService = esShopsService;
        this.selected = new EventEmitter();
        this.control = new FormControl();
    }
    ShopAutoCompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.label)
            this.label = '매장';
        if (!this.shopType)
            this.shopType = null;
        this.searchResults = this.control.valueChanges.pipe(debounceTime(500), tap(function (value) {
            if (!value) {
                _this.selected.emit(null);
            }
        }), filter(function (value) { return !!value && typeof value === 'string'; }), mergeMap(function (value) {
            return _this.esShopsService.shopsMetaGet(_this.shopType, value);
        }), takeUntil(componentDestroyed(this)));
    };
    ShopAutoCompleteComponent.prototype.ngOnDestroy = function () { };
    ShopAutoCompleteComponent.prototype.displayShopName = function (shop) {
        return shop ? shop.shop_name : undefined;
    };
    ShopAutoCompleteComponent.prototype.onShopSelected = function (value) {
        if (!value)
            return;
        this.selected.emit(value);
    };
    return ShopAutoCompleteComponent;
}());
export { ShopAutoCompleteComponent };
