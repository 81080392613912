<div class="section-basic">
    <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style">
      <label class="required">연도</label>
      <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()"
        (dateChange)="chosenYearHandler($event.target.value, end)" formControlName="year" readonly>
      <mat-datepicker #end startView="multi-year" (yearSelected)="chosenYearHandler($event, end)"></mat-datepicker>
    </div>

    <div class="form-group m-form__group default-style">
      <label>활동지</label>
      <app-angel-dept-auto-complete [placeholder]="'활동지'" formControlName="search_act_dept_name">
      </app-angel-dept-auto-complete>
    </div>

     <div class="form-group m-form__group default-style form_select">
      <label>활동구분</label>
      <select class="form-control m-input" formControlName="act_div" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_div_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

    <div class="form-group m-form__group default-style form_select">
      <label>활동주기</label>
      <select class="form-control m-input" formControlName="act_period" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_period_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

        <div class="form-group m-form__group default-style">
            <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
        </div>

    </form>
</div>

<!-- 데이터 테이블 -->
<div class="result">
    <div class="section-basic mb-0 pb-2">
        <div class="btns_right_align">
            <button class="btn btn-sm btn-outline-primary" (click)="onClickExcelExport()"
                [disabled]="dataSource.data.length == 0">엑셀
                다운로드</button>
        </div>

        <div class="m--space-10"></div>

        <mat-table class="unset-cursor-pointer" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="department_name" sticky>
                <mat-header-cell *matHeaderCellDef class="sticky-unset-border sticky"> 국
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="sticky-unset-border sticky"> {{element.department_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="team_name" sticky>
                <mat-header-cell *matHeaderCellDef class="sticky-unset-border team sticky"> 팀
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="sticky-unset-border team sticky">
                    {{element.team_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="location_name" sticky>
                <mat-header-cell *matHeaderCellDef class="store sticky"> 매장
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="store sticky"> {{element.location_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon01_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon01_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon01_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon01_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon02_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon02_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon02_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon02_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon03_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon03_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon03_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon03_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon04_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon04_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon04_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon04_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon05_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon05_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon05_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon05_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon06_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon06_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon06_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon06_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon07_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon07_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon07_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon07_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon08_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon08_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon08_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon08_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon09_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon09_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon09_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon09_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon10_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon10_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon10_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon10_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon11_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon11_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon11_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon11_time | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon12_cnt">
                <mat-header-cell *matHeaderCellDef> 횟수 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon12_cnt | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon12_time">
                <mat-header-cell *matHeaderCellDef> 시간 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon12_time | currencyMask }}
                </mat-cell>
            </ng-container>
            
            
            


            <ng-container matColumnDef="group1" sticky>
                <mat-header-cell *matHeaderCellDef class="header-group1"></mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group2">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 1월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group3">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 2월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group4">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 3월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group5">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 4월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group6">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 5월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group7">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 6월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group8">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 7월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group9">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 8월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group10">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 9월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group11">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 10월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group12">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 11월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group13">
                <mat-header-cell *matHeaderCellDef class="header-group3"> 12월 </mat-header-cell>
            </ng-container>

            <ng-container matColumnDef="noData">
                <mat-footer-cell *matFooterCellDef>
                    <div class="no-records">데이터가 존재하지 않습니다.</div>
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['group1', 'group2', 'group3', 'group4', 'group5', 'group6', 'group7', 'group8', 'group9', 'group10', 'group11', 'group12', 'group13']">
            </mat-header-row>
            <mat-header-row *matHeaderRowDef="displayedColumns">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'row-default':row,'row-team':row.location_name == '팀소계','row-department':row.team_name == '국소계'}">
            </mat-row>

            <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource.data.length == 0)}">
            </mat-footer-row>
        </mat-table>

        <div class="m-separator m-separator--dashed"></div>
         </div>
</div>
