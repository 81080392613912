/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsEmpService = /** @class */ (function () {
    function EsEmpService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsEmpService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsEmpService.prototype.empAccountIdGet = function (emp_account_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (emp_account_id === null || emp_account_id === undefined) {
            throw new Error('Required parameter emp_account_id was null or undefined when calling empAccountIdGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (emp_account_id !== undefined && emp_account_id !== null) {
            queryParameters = queryParameters.set('emp_account_id', emp_account_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/account_id", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empBasicExcelGet = function (contract_date, gte_contract, lte_contract, emp_id, emp_name, full_dept_id, full_dept_name, emp_job_status, conf_emp_private_no, permanent_emp, emp_job_rank, emp_job_title, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (contract_date !== undefined && contract_date !== null) {
            queryParameters = queryParameters.set('contract_date', contract_date);
        }
        if (gte_contract !== undefined && gte_contract !== null) {
            queryParameters = queryParameters.set('gte_contract', gte_contract);
        }
        if (lte_contract !== undefined && lte_contract !== null) {
            queryParameters = queryParameters.set('lte_contract', lte_contract);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (full_dept_name !== undefined && full_dept_name !== null) {
            queryParameters = queryParameters.set('full_dept_name', full_dept_name);
        }
        if (emp_job_status !== undefined && emp_job_status !== null) {
            queryParameters = queryParameters.set('emp_job_status', emp_job_status);
        }
        if (conf_emp_private_no !== undefined && conf_emp_private_no !== null) {
            queryParameters = queryParameters.set('conf_emp_private_no', conf_emp_private_no);
        }
        if (permanent_emp !== undefined && permanent_emp !== null) {
            queryParameters = queryParameters.set('permanent_emp', permanent_emp);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        if (emp_job_title !== undefined && emp_job_title !== null) {
            queryParameters = queryParameters.set('emp_job_title', emp_job_title);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/basic/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empBasicGet = function (contract_date, gte_contract, lte_contract, emp_id, emp_name, full_dept_id, full_dept_name, emp_job_status, conf_emp_private_no, permanent_emp, emp_job_rank, emp_job_title, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (contract_date !== undefined && contract_date !== null) {
            queryParameters = queryParameters.set('contract_date', contract_date);
        }
        if (gte_contract !== undefined && gte_contract !== null) {
            queryParameters = queryParameters.set('gte_contract', gte_contract);
        }
        if (lte_contract !== undefined && lte_contract !== null) {
            queryParameters = queryParameters.set('lte_contract', lte_contract);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (full_dept_name !== undefined && full_dept_name !== null) {
            queryParameters = queryParameters.set('full_dept_name', full_dept_name);
        }
        if (emp_job_status !== undefined && emp_job_status !== null) {
            queryParameters = queryParameters.set('emp_job_status', emp_job_status);
        }
        if (conf_emp_private_no !== undefined && conf_emp_private_no !== null) {
            queryParameters = queryParameters.set('conf_emp_private_no', conf_emp_private_no);
        }
        if (permanent_emp !== undefined && permanent_emp !== null) {
            queryParameters = queryParameters.set('permanent_emp', permanent_emp);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        if (emp_job_title !== undefined && emp_job_title !== null) {
            queryParameters = queryParameters.set('emp_job_title', emp_job_title);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/basic", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empCommuteCalculationExcelGet = function (year_month, emp_id, emp_name, full_dept_id, emp_job_rank, conf_emp_salary_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year_month === null || year_month === undefined) {
            throw new Error('Required parameter year_month was null or undefined when calling empCommuteCalculationExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year_month !== undefined && year_month !== null) {
            queryParameters = queryParameters.set('year_month', year_month);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        if (conf_emp_salary_type !== undefined && conf_emp_salary_type !== null) {
            queryParameters = queryParameters.set('conf_emp_salary_type', conf_emp_salary_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/commute/calculation/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empCommuteExcelGet = function (emp_id, full_dept_id, name, start_date, end_date, emp_job_rank, conf_emp_salary_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', name);
        }
        if (start_date !== undefined && start_date !== null) {
            queryParameters = queryParameters.set('start_date', start_date);
        }
        if (end_date !== undefined && end_date !== null) {
            queryParameters = queryParameters.set('end_date', end_date);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        if (conf_emp_salary_type !== undefined && conf_emp_salary_type !== null) {
            queryParameters = queryParameters.set('conf_emp_salary_type', conf_emp_salary_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/commute/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empCommuteGet = function (emp_id, full_dept_id, name, start_date, end_date, emp_job_rank, conf_emp_salary_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', name);
        }
        if (start_date !== undefined && start_date !== null) {
            queryParameters = queryParameters.set('start_date', start_date);
        }
        if (end_date !== undefined && end_date !== null) {
            queryParameters = queryParameters.set('end_date', end_date);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        if (conf_emp_salary_type !== undefined && conf_emp_salary_type !== null) {
            queryParameters = queryParameters.set('conf_emp_salary_type', conf_emp_salary_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/commute", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empCommuteMonthlyGet = function (year_month, emp_id, emp_name, full_dept_id, emp_job_rank, conf_emp_salary_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year_month === null || year_month === undefined) {
            throw new Error('Required parameter year_month was null or undefined when calling empCommuteMonthlyGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year_month !== undefined && year_month !== null) {
            queryParameters = queryParameters.set('year_month', year_month);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        if (conf_emp_salary_type !== undefined && conf_emp_salary_type !== null) {
            queryParameters = queryParameters.set('conf_emp_salary_type', conf_emp_salary_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/commute/monthly", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empEmpIdVehiclesGet = function (emp_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling empEmpIdVehiclesGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/" + encodeURIComponent(String(emp_id)) + "/vehicles", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empMetaGet = function (emp_name, meta_info, role, page_group, retired_emp, permanent_emp, emp_job_rank, emp_job_title, emp_auth0_id, emp_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', emp_name);
        }
        if (meta_info !== undefined && meta_info !== null) {
            queryParameters = queryParameters.set('meta_info', meta_info);
        }
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', role);
        }
        if (page_group !== undefined && page_group !== null) {
            queryParameters = queryParameters.set('page_group', page_group);
        }
        if (retired_emp !== undefined && retired_emp !== null) {
            queryParameters = queryParameters.set('retired_emp', retired_emp);
        }
        if (permanent_emp !== undefined && permanent_emp !== null) {
            queryParameters = queryParameters.set('permanent_emp', permanent_emp);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        if (emp_job_title !== undefined && emp_job_title !== null) {
            queryParameters = queryParameters.set('emp_job_title', emp_job_title);
        }
        if (emp_auth0_id !== undefined && emp_auth0_id !== null) {
            queryParameters = queryParameters.set('emp_auth0_id', emp_auth0_id);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/meta", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empPoliciesStatmentsGet = function (emp_id, emp_account_id, emp_auth0_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_account_id !== undefined && emp_account_id !== null) {
            queryParameters = queryParameters.set('emp_account_id', emp_account_id);
        }
        if (emp_auth0_id !== undefined && emp_auth0_id !== null) {
            queryParameters = queryParameters.set('emp_auth0_id', emp_auth0_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/policies/statments", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empVacationExcelGet = function (emp_id, full_dept_id, vac_div_codes, name, search_date, gte, lte, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (vac_div_codes !== undefined && vac_div_codes !== null) {
            queryParameters = queryParameters.set('vac_div_codes', vac_div_codes);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', name);
        }
        if (search_date !== undefined && search_date !== null) {
            queryParameters = queryParameters.set('search_date', search_date);
        }
        if (gte !== undefined && gte !== null) {
            queryParameters = queryParameters.set('gte', gte);
        }
        if (lte !== undefined && lte !== null) {
            queryParameters = queryParameters.set('lte', lte);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/vacation/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empVacationGet = function (emp_id, full_dept_id, vac_div_codes, name, search_date, gte, lte, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (vac_div_codes !== undefined && vac_div_codes !== null) {
            queryParameters = queryParameters.set('vac_div_codes', vac_div_codes);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', name);
        }
        if (search_date !== undefined && search_date !== null) {
            queryParameters = queryParameters.set('search_date', search_date);
        }
        if (gte !== undefined && gte !== null) {
            queryParameters = queryParameters.set('gte', gte);
        }
        if (lte !== undefined && lte !== null) {
            queryParameters = queryParameters.set('lte', lte);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/vacation", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empVacationMonthlyExcelGet = function (year, emp_id, emp_name, full_dept_id, full_dept_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling empVacationMonthlyExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (full_dept_name !== undefined && full_dept_name !== null) {
            queryParameters = queryParameters.set('full_dept_name', full_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/vacation/monthly/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empVacationMonthlyGet = function (year, emp_id, emp_name, full_dept_id, full_dept_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling empVacationMonthlyGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', year);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_name !== undefined && emp_name !== null) {
            queryParameters = queryParameters.set('emp_name', emp_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', full_dept_id);
        }
        if (full_dept_name !== undefined && full_dept_name !== null) {
            queryParameters = queryParameters.set('full_dept_name', full_dept_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/vacation/monthly", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empWorksExcelGet = function (issue_start_date_gte, issue_start_date_lte, emp_id, emp_dept_id, name, work_div_code, code, proc_status, emp_job_rank, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (issue_start_date_gte === null || issue_start_date_gte === undefined) {
            throw new Error('Required parameter issue_start_date_gte was null or undefined when calling empWorksExcelGet.');
        }
        if (issue_start_date_lte === null || issue_start_date_lte === undefined) {
            throw new Error('Required parameter issue_start_date_lte was null or undefined when calling empWorksExcelGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (issue_start_date_gte !== undefined && issue_start_date_gte !== null) {
            queryParameters = queryParameters.set('issue_start_date_gte', issue_start_date_gte);
        }
        if (issue_start_date_lte !== undefined && issue_start_date_lte !== null) {
            queryParameters = queryParameters.set('issue_start_date_lte', issue_start_date_lte);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_dept_id !== undefined && emp_dept_id !== null) {
            queryParameters = queryParameters.set('emp_dept_id', emp_dept_id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', name);
        }
        if (work_div_code !== undefined && work_div_code !== null) {
            queryParameters = queryParameters.set('work_div_code', work_div_code);
        }
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', code);
        }
        if (proc_status !== undefined && proc_status !== null) {
            queryParameters = queryParameters.set('proc_status', proc_status);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/works/excel", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsEmpService.prototype.empWorksGet = function (issue_start_date_gte, issue_start_date_lte, emp_id, emp_dept_id, name, work_div_code, code, proc_status, emp_job_rank, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (issue_start_date_gte === null || issue_start_date_gte === undefined) {
            throw new Error('Required parameter issue_start_date_gte was null or undefined when calling empWorksGet.');
        }
        if (issue_start_date_lte === null || issue_start_date_lte === undefined) {
            throw new Error('Required parameter issue_start_date_lte was null or undefined when calling empWorksGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (issue_start_date_gte !== undefined && issue_start_date_gte !== null) {
            queryParameters = queryParameters.set('issue_start_date_gte', issue_start_date_gte);
        }
        if (issue_start_date_lte !== undefined && issue_start_date_lte !== null) {
            queryParameters = queryParameters.set('issue_start_date_lte', issue_start_date_lte);
        }
        if (emp_id !== undefined && emp_id !== null) {
            queryParameters = queryParameters.set('emp_id', emp_id);
        }
        if (emp_dept_id !== undefined && emp_dept_id !== null) {
            queryParameters = queryParameters.set('emp_dept_id', emp_dept_id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', name);
        }
        if (work_div_code !== undefined && work_div_code !== null) {
            queryParameters = queryParameters.set('work_div_code', work_div_code);
        }
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', code);
        }
        if (proc_status !== undefined && proc_status !== null) {
            queryParameters = queryParameters.set('proc_status', proc_status);
        }
        if (emp_job_rank !== undefined && emp_job_rank !== null) {
            queryParameters = queryParameters.set('emp_job_rank', emp_job_rank);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/emp/works", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsEmpService;
}());
export { EsEmpService };
