import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { dateFormat, DEFAULT_FORMATS } from 'src/app/share/utils/common-utils';
import { EsWarehouseService } from 'src/app/restapi/be-esquery';
import { take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = DEFAULT_FORMATS;
var WarehouseProductionComponent = /** @class */ (function () {
    function WarehouseProductionComponent(fb, esWarehouseService) {
        this.fb = fb;
        this.esWarehouseService = esWarehouseService;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['search_yyyy_mm', 'recycle_place_name', 'production_work_group_name', 'give_good_cate', 'give_good_detail_cate', 'general_production',
            'event_production', 'event_disposal', 'tot_working_people_num', 'tot_working_time', 'production_per_working_people', 'production_per_working_time'];
        this.minDate = moment().add(-3, 'month');
        this.maxDate = moment();
        this.spans = [];
    }
    WarehouseProductionComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    WarehouseProductionComponent.prototype.ngOnDestroy = function () { };
    WarehouseProductionComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            gte_date: this.minDate,
            lte_date: this.maxDate,
            dept_info: null
        });
    };
    Object.defineProperty(WarehouseProductionComponent.prototype, "fc_gteDate", {
        get: function () { return this.form.get('gte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WarehouseProductionComponent.prototype, "fc_lteDate", {
        get: function () { return this.form.get('lte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WarehouseProductionComponent.prototype, "fc_deptInfo", {
        get: function () { return this.form.get('dept_info'); },
        enumerable: true,
        configurable: true
    });
    WarehouseProductionComponent.prototype.onChangeStartDate = function () {
        var limitDate = moment(this.fc_gteDate.value).add(3, 'month');
        this.minDate = this.fc_gteDate.value;
        if (this.fc_gteDate.value > this.fc_lteDate.value) {
            this.fc_lteDate.setValue(this.fc_gteDate.value);
        }
        if (limitDate < this.fc_lteDate.value) {
            this.fc_lteDate.setValue(limitDate);
        }
    };
    WarehouseProductionComponent.prototype.onChangeEndDate = function () {
        var limitDate = moment(this.fc_lteDate.value).add(-3, 'month');
        if (limitDate > this.fc_gteDate.value) {
            this.fc_gteDate.setValue(limitDate);
            this.minDate = limitDate;
        }
    };
    WarehouseProductionComponent.prototype.selectDept = function (value) {
        if (!value || value == null) {
            this.fc_deptInfo.setValue(null);
            return;
        }
        this.fc_deptInfo.setValue(value);
        this.search();
    };
    WarehouseProductionComponent.prototype.search = function () {
        this.productionGet();
    };
    WarehouseProductionComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '생산통계');
        XLSX.writeFile(wb, '생산통계.xlsx');
    };
    WarehouseProductionComponent.prototype.productionGet = function () {
        var _this = this;
        this.dataList = [];
        this.esWarehouseService.warehouseRecyclePlaceProductionStatisticalTableGet(dateFormat(this.fc_gteDate.value), dateFormat(this.fc_lteDate.value), this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null).pipe(take(1)).subscribe(function (res) {
            _this.dataList = res;
            _this.dataSource = new MatTableDataSource(_this.dataList);
            _this.cacheSpan('production_work_group_name', function (data) { return data.production_work_group_name; });
        }, function (err) {
            console.error(err);
            _this.dataSource = new MatTableDataSource(_this.dataList);
        });
    };
    WarehouseProductionComponent.prototype.getRowSpan = function (col, index) {
        return this.spans[index] && this.spans[index][col];
    };
    WarehouseProductionComponent.prototype.cacheSpan = function (key, accessor) {
        this.spans = [];
        for (var i = 0; i < this.dataList.length;) {
            var currentValue = accessor(this.dataList[i]);
            var count = 1;
            for (var j = i + 1; j < this.dataList.length; j++) {
                if (currentValue != accessor(this.dataList[j])) {
                    break;
                }
                count++;
            }
            if (!this.spans[i]) {
                this.spans[i] = {};
            }
            this.spans[i][key] = count;
            i += count;
        }
    };
    return WarehouseProductionComponent;
}());
export { WarehouseProductionComponent };
export { ɵ0 };
