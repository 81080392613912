/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsFailure } from '../model/esFailure';
import { EsGivingLogistics } from '../model/esGivingLogistics';
import { EsJoiner } from '../model/esJoiner';
import { EsWorklog } from '../model/esWorklog';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsJoinersService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 참여자를 phone(휴대,일반,인입)번호 대상이 아닌 휴대번호(cellp_no)로만 검색한다
     * 
     * @param joiner_cellp_no 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersCellpNoGet(joiner_cellp_no: string, observe?: 'body', reportProgress?: boolean): Observable<EsJoiner>;
    public joinersCellpNoGet(joiner_cellp_no: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsJoiner>>;
    public joinersCellpNoGet(joiner_cellp_no: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsJoiner>>;
    public joinersCellpNoGet(joiner_cellp_no: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (joiner_cellp_no === null || joiner_cellp_no === undefined) {
            throw new Error('Required parameter joiner_cellp_no was null or undefined when calling joinersCellpNoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_cellp_no !== undefined && joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('joiner_cellp_no', <any>joiner_cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsJoiner>(`${this.basePath}/joiners/cellp_no`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여자 검색(이름,주소,전화번호(핸드폰,유선,인입등))
     * 
     * @param joiner_name 
     * @param joiner_addr 
     * @param joiner_phone 
     * @param joiner_private_no 
     * @param joiner_div 
     * @param is_giving_angel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersGet(joiner_name?: string, joiner_addr?: string, joiner_phone?: string, joiner_private_no?: string, joiner_div?: '개인' | '사업자', is_giving_angel?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<EsJoiner>>;
    public joinersGet(joiner_name?: string, joiner_addr?: string, joiner_phone?: string, joiner_private_no?: string, joiner_div?: '개인' | '사업자', is_giving_angel?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsJoiner>>>;
    public joinersGet(joiner_name?: string, joiner_addr?: string, joiner_phone?: string, joiner_private_no?: string, joiner_div?: '개인' | '사업자', is_giving_angel?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsJoiner>>>;
    public joinersGet(joiner_name?: string, joiner_addr?: string, joiner_phone?: string, joiner_private_no?: string, joiner_div?: '개인' | '사업자', is_giving_angel?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', <any>joiner_name);
        }
        if (joiner_addr !== undefined && joiner_addr !== null) {
            queryParameters = queryParameters.set('joiner_addr', <any>joiner_addr);
        }
        if (joiner_phone !== undefined && joiner_phone !== null) {
            queryParameters = queryParameters.set('joiner_phone', <any>joiner_phone);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', <any>joiner_private_no);
        }
        if (joiner_div !== undefined && joiner_div !== null) {
            queryParameters = queryParameters.set('joiner_div', <any>joiner_div);
        }
        if (is_giving_angel !== undefined && is_giving_angel !== null) {
            queryParameters = queryParameters.set('is_giving_angel', <any>is_giving_angel);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsJoiner>>(`${this.basePath}/joiners`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 개발 통계용
     * 
     * @param gte_date 
     * @param lte_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersGivingsMarketingGet(gte_date: string, lte_date: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public joinersGivingsMarketingGet(gte_date: string, lte_date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public joinersGivingsMarketingGet(gte_date: string, lte_date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public joinersGivingsMarketingGet(gte_date: string, lte_date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling joinersGivingsMarketingGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling joinersGivingsMarketingGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/joiners/givings/marketing`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여자의 최근 기증건 정보
     * 
     * @param joiner_name 
     * @param joiner_cellp_no 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersGivingsRecentGet(joiner_name: string, joiner_cellp_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsJoiner>>;
    public joinersGivingsRecentGet(joiner_name: string, joiner_cellp_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsJoiner>>>;
    public joinersGivingsRecentGet(joiner_name: string, joiner_cellp_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsJoiner>>>;
    public joinersGivingsRecentGet(joiner_name: string, joiner_cellp_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (joiner_name === null || joiner_name === undefined) {
            throw new Error('Required parameter joiner_name was null or undefined when calling joinersGivingsRecentGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', <any>joiner_name);
        }
        if (joiner_cellp_no !== undefined && joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('joiner_cellp_no', <any>joiner_cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsJoiner>>(`${this.basePath}/joiners/givings/recent`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여자의 식별자를 and 조건으로 검색
     * 
     * @param joiner_name 
     * @param joiner_private_no 
     * @param joiner_cellp_no 
     * @param joiner_phone_no 
     * @param joiner_type 
     * @param joiner_div 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersIdentificationAndNumberGet(joiner_name?: string, joiner_private_no?: string, joiner_cellp_no?: string, joiner_phone_no?: string, joiner_type?: '기증자' | '기부자' | '활동천사' | '대외협력', joiner_div?: '개인' | '사업자', observe?: 'body', reportProgress?: boolean): Observable<Array<EsJoiner>>;
    public joinersIdentificationAndNumberGet(joiner_name?: string, joiner_private_no?: string, joiner_cellp_no?: string, joiner_phone_no?: string, joiner_type?: '기증자' | '기부자' | '활동천사' | '대외협력', joiner_div?: '개인' | '사업자', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsJoiner>>>;
    public joinersIdentificationAndNumberGet(joiner_name?: string, joiner_private_no?: string, joiner_cellp_no?: string, joiner_phone_no?: string, joiner_type?: '기증자' | '기부자' | '활동천사' | '대외협력', joiner_div?: '개인' | '사업자', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsJoiner>>>;
    public joinersIdentificationAndNumberGet(joiner_name?: string, joiner_private_no?: string, joiner_cellp_no?: string, joiner_phone_no?: string, joiner_type?: '기증자' | '기부자' | '활동천사' | '대외협력', joiner_div?: '개인' | '사업자', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', <any>joiner_name);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', <any>joiner_private_no);
        }
        if (joiner_cellp_no !== undefined && joiner_cellp_no !== null) {
            queryParameters = queryParameters.set('joiner_cellp_no', <any>joiner_cellp_no);
        }
        if (joiner_phone_no !== undefined && joiner_phone_no !== null) {
            queryParameters = queryParameters.set('joiner_phone_no', <any>joiner_phone_no);
        }
        if (joiner_type !== undefined && joiner_type !== null) {
            queryParameters = queryParameters.set('joiner_type', <any>joiner_type);
        }
        if (joiner_div !== undefined && joiner_div !== null) {
            queryParameters = queryParameters.set('joiner_div', <any>joiner_div);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsJoiner>>(`${this.basePath}/joiners/identification_and_number`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여자의 모든기증건 검색+수거정보
     * 
     * @param joiner_id 
     * @param give_status 
     * @param include_del_flg 
     * @param give_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersJoinerIdGivingsGet(joiner_id: string, give_status?: string, include_del_flg?: 'Y' | 'N', give_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsGivingLogistics>>;
    public joinersJoinerIdGivingsGet(joiner_id: string, give_status?: string, include_del_flg?: 'Y' | 'N', give_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsGivingLogistics>>>;
    public joinersJoinerIdGivingsGet(joiner_id: string, give_status?: string, include_del_flg?: 'Y' | 'N', give_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsGivingLogistics>>>;
    public joinersJoinerIdGivingsGet(joiner_id: string, give_status?: string, include_del_flg?: 'Y' | 'N', give_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (joiner_id === null || joiner_id === undefined) {
            throw new Error('Required parameter joiner_id was null or undefined when calling joinersJoinerIdGivingsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (give_status !== undefined && give_status !== null) {
            queryParameters = queryParameters.set('give_status', <any>give_status);
        }
        if (include_del_flg !== undefined && include_del_flg !== null) {
            queryParameters = queryParameters.set('include_del_flg', <any>include_del_flg);
        }
        if (give_id !== undefined && give_id !== null) {
            queryParameters = queryParameters.set('give_id', <any>give_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsGivingLogistics>>(`${this.basePath}/joiners/${encodeURIComponent(String(joiner_id))}/givings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대외협력처 협력일지 검색
     * 상단에서 선택된 특정 대외협력처에 등록된 협력일지 리스트
     * @param gte_date 
     * @param lte_date 
     * @param all_content 
     * @param joiner_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersJoinerIdPartnersWorklogGet(gte_date?: string, lte_date?: string, all_content?: string, joiner_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsWorklog>>;
    public joinersJoinerIdPartnersWorklogGet(gte_date?: string, lte_date?: string, all_content?: string, joiner_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsWorklog>>>;
    public joinersJoinerIdPartnersWorklogGet(gte_date?: string, lte_date?: string, all_content?: string, joiner_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsWorklog>>>;
    public joinersJoinerIdPartnersWorklogGet(gte_date?: string, lte_date?: string, all_content?: string, joiner_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (all_content !== undefined && all_content !== null) {
            queryParameters = queryParameters.set('all_content', <any>all_content);
        }
        if (joiner_id !== undefined && joiner_id !== null) {
            queryParameters = queryParameters.set('joiner_id', <any>joiner_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsWorklog>>(`${this.basePath}/joiners/partners/worklog`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여자를 전화번호(휴대,유선,담당자,인입번호등)로만 검색한다(우선 순위적용됨 -&gt; 휴대,유선,담당자,인입) 
     * 
     * @param joiner_phone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersJoinerPhonePhoneGet(joiner_phone: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsJoiner>>;
    public joinersJoinerPhonePhoneGet(joiner_phone: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsJoiner>>>;
    public joinersJoinerPhonePhoneGet(joiner_phone: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsJoiner>>>;
    public joinersJoinerPhonePhoneGet(joiner_phone: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (joiner_phone === null || joiner_phone === undefined) {
            throw new Error('Required parameter joiner_phone was null or undefined when calling joinersJoinerPhonePhoneGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_phone !== undefined && joiner_phone !== null) {
            queryParameters = queryParameters.set('joiner_phone', <any>joiner_phone);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsJoiner>>(`${this.basePath}/joiners/phone`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 참여자를 식별번호로만 검색한다
     * 
     * @param joiner_private_no 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersJoinerPrivateNoPrivateGet(joiner_private_no: string, observe?: 'body', reportProgress?: boolean): Observable<EsJoiner>;
    public joinersJoinerPrivateNoPrivateGet(joiner_private_no: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EsJoiner>>;
    public joinersJoinerPrivateNoPrivateGet(joiner_private_no: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EsJoiner>>;
    public joinersJoinerPrivateNoPrivateGet(joiner_private_no: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (joiner_private_no === null || joiner_private_no === undefined) {
            throw new Error('Required parameter joiner_private_no was null or undefined when calling joinersJoinerPrivateNoPrivateGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', <any>joiner_private_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<EsJoiner>(`${this.basePath}/joiners/private`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 개발 통계용 수신동의 참여자
     * 
     * @param lte_recent_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersMarketingGet(lte_recent_date?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public joinersMarketingGet(lte_recent_date?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public joinersMarketingGet(lte_recent_date?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public joinersMarketingGet(lte_recent_date?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (lte_recent_date !== undefined && lte_recent_date !== null) {
            queryParameters = queryParameters.set('lte_recent_date', <any>lte_recent_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<any>(`${this.basePath}/joiners/marketing`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대외협력처 등록 시 중복체크
     * 대외협력처 등록 시 중복체크
     * @param joiner_name 협력처명
     * @param joiner_private_no 사업자번호
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersPartnersDuplicateGet(joiner_name?: string, joiner_private_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsJoiner>>;
    public joinersPartnersDuplicateGet(joiner_name?: string, joiner_private_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsJoiner>>>;
    public joinersPartnersDuplicateGet(joiner_name?: string, joiner_private_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsJoiner>>>;
    public joinersPartnersDuplicateGet(joiner_name?: string, joiner_private_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', <any>joiner_name);
        }
        if (joiner_private_no !== undefined && joiner_private_no !== null) {
            queryParameters = queryParameters.set('joiner_private_no', <any>joiner_private_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsJoiner>>(`${this.basePath}/joiners/partners/duplicate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 대외협력처 검색
     * 대외협력처 정보기준 검색
     * @param joiner_name 협력처명
     * @param joiner_issue_emp_dept_name 등록부서명
     * @param search_type search_type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersPartnersGet(joiner_name?: string, joiner_issue_emp_dept_name?: string, search_type?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsJoiner>>;
    public joinersPartnersGet(joiner_name?: string, joiner_issue_emp_dept_name?: string, search_type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsJoiner>>>;
    public joinersPartnersGet(joiner_name?: string, joiner_issue_emp_dept_name?: string, search_type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsJoiner>>>;
    public joinersPartnersGet(joiner_name?: string, joiner_issue_emp_dept_name?: string, search_type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', <any>joiner_name);
        }
        if (joiner_issue_emp_dept_name !== undefined && joiner_issue_emp_dept_name !== null) {
            queryParameters = queryParameters.set('joiner_issue_emp_dept_name', <any>joiner_issue_emp_dept_name);
        }
        if (search_type !== undefined && search_type !== null) {
            queryParameters = queryParameters.set('search_type', <any>search_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsJoiner>>(`${this.basePath}/joiners/partners`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 협력처 이름으로 like 검색
     * 협력처 이름으로 like 검색
     * @param joiner_name 협력처명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersPartnersMetaGet(joiner_name: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsJoiner>>;
    public joinersPartnersMetaGet(joiner_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsJoiner>>>;
    public joinersPartnersMetaGet(joiner_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsJoiner>>>;
    public joinersPartnersMetaGet(joiner_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (joiner_name === null || joiner_name === undefined) {
            throw new Error('Required parameter joiner_name was null or undefined when calling joinersPartnersMetaGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (joiner_name !== undefined && joiner_name !== null) {
            queryParameters = queryParameters.set('joiner_name', <any>joiner_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsJoiner>>(`${this.basePath}/joiners/partners/meta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 개발 통계용
     * 
     * @param gte_date 
     * @param lte_date 
     * @param state 
     * @param city 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinersRegionMarketingGet(gte_date: string, lte_date: string, state?: string, city?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsJoiner>>;
    public joinersRegionMarketingGet(gte_date: string, lte_date: string, state?: string, city?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsJoiner>>>;
    public joinersRegionMarketingGet(gte_date: string, lte_date: string, state?: string, city?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsJoiner>>>;
    public joinersRegionMarketingGet(gte_date: string, lte_date: string, state?: string, city?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling joinersRegionMarketingGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling joinersRegionMarketingGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (city !== undefined && city !== null) {
            queryParameters = queryParameters.set('city', <any>city);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsJoiner>>(`${this.basePath}/joiners/region/marketing`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
