import { Component, OnInit } from '@angular/core';
import { MAT_DATE_FORMATS, MatDatepicker, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { dateFormat_Month, MONTH_FORMATS } from 'src/app/share/utils/common-utils';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { AlertDialogComponent, ConfirmDialogComponent } from 'fecommon';
import { EsGreenbusinessService } from 'src/app/restapi/be-esquery';

const moment = _moment;

@Component({
  selector: 'app-raw-data-logistics-order',
  templateUrl: './raw-data-logistics-order.component.html',
  styleUrls: ['./raw-data-logistics-order.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
  ]
})
export class RawDataLogisticsOrderComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private esGreenbusinessService: EsGreenbusinessService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      date: moment().add(-1, 'month')
    });
  }

  get fc_date() { return this.form.get('date'); }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_date.setValue(normalizedMonth);
    datepicker.close();
  }

  download() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '그린 입고 데이터',
        description: '다운로드 하시겠습니까?',
        okText: '확인',
        cancelText: '취소'
      }
    }).afterClosed().pipe(take(1)).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.logisticsOrderExcelGet();
      }
    });
  }

  logisticsOrderExcelGet() {
    this.esGreenbusinessService.logisticsOrderExcelGet(
      dateFormat_Month(this.fc_date.value),
      dateFormat_Month(this.fc_date.value)
    ).pipe(
      take(1)
    ).subscribe((res: any) => {
      this.dialog.open(AlertDialogComponent, {
        data: {
          message: '이메일로 전송될 예정입니다.'
        }
      });
    },
      (err) => { console.error(err); }
    );
  }

}