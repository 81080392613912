import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS, MatDatepicker } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { EsGivingCallHistoryStatistics } from 'src/app/restapi/be-esquery/model/esGivingCallHistoryStatistics';
import { EsGivingHistory } from 'src/app/restapi/be-esquery/model/esGivingHistory';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { CodeService, Statistics, StatisticsService } from 'src/app/restapi/be-angel';
import { DatePipe } from '@angular/common';
import { Code } from 'src/app/restapi/be-angel';

const moment = _moment;

@Component({
  selector: 'app-angel-workyear-annual',
  templateUrl: './angel-workyear-annual.component.html',
  styleUrls: ['./angel-workyear-annual.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },DatePipe
  ]
})
export class AngelWorkyearAnnualComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  dataList: Array<Statistics> = [];
  dataSource: MatTableDataSource<Statistics> = new MatTableDataSource([]);
  displayedColumns = ['division', 'mon01', 'mon02', 'mon03', 'mon04', 'mon05', 'mon06', 'mon07', 'mon08', 'mon09', 'mon10', 'mon11', 'mon12'];
  act_div_list: Array<Code>;
  act_period_list: Array<Code>;
  
  constructor(
    private fb: FormBuilder,
    private statisticsService: StatisticsService,
    private codeService: CodeService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.initForm();
    this.search();

    this.codeService.angelCodeGet("자원활동","활동구분").subscribe( res => {
      this.act_div_list = res;
      console.info(this.act_div_list);
    })
    this.codeService.angelCodeGet("자원활동","활동주기").subscribe( res => {
      this.act_period_list = res;
      console.info(this.act_period_list);
    })
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      act_div: [''],
      act_period: [''],
      year: moment(),
      search_act_dept_name: ['']
    });
  }

  get fc_countDiv() { return this.form.get('act_div'); }
  get fc_countPeriod() { return this.form.get('act_period'); }
  get fc_year() { return this.form.get('year'); }
  get fc_deptName() { return this.form.get('search_act_dept_name'); }

  chosenYearHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_year.setValue(normalizedMonth);
    this.search();
    
    datepicker.close();
  }

  convertMonthInfo(month: string, data: EsGivingCallHistoryStatistics) {
    if (data.monthly_info) {
      const givingHistory: EsGivingHistory = data.monthly_info.find(x => x.date.split("-")[1] == month);
      if (givingHistory) return givingHistory.count;
    }

    return '';
  }

  search() {
    this.givingsCallHistoryGet();
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '연차별연보통계');
    XLSX.writeFile(wb, `연차별연보통계_${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}.xlsx`);
  }

  givingsCallHistoryGet() {
    this.dataList = [];

    this.statisticsService.statisticsDifferenceYearGet(
      dateFormat_Year(this.fc_year.value), this.fc_deptName.value,
      this.fc_countDiv.value, this.fc_countPeriod.value
    ).pipe(
      take(1)
    ).subscribe((res: Array<Statistics>) => {
      this.dataList = res;
      this.dataSource = new MatTableDataSource<Statistics>(this.dataList);
    },
      (err) => {
        console.error(err);
      }
    );
  }

}