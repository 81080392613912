import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, MatDatepicker } from '@angular/material';
import { YEAR_FORMATS, dateFormat_Year } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { CodeService, StatisticsService } from 'src/app/restapi/be-angel';
var moment = _moment;
var ɵ0 = YEAR_FORMATS;
var AngelTimeAnnualComponent = /** @class */ (function () {
    function AngelTimeAnnualComponent(fb, statisticsService, datePipe, codeService) {
        this.fb = fb;
        this.statisticsService = statisticsService;
        this.datePipe = datePipe;
        this.codeService = codeService;
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['department_name', 'team_name', 'location_name', 'mon01_cnt', 'mon01_time', 'mon02_cnt', 'mon02_time', 'mon03_cnt', 'mon03_time', 'mon04_cnt', 'mon04_time',
            'mon05_cnt', 'mon05_time', 'mon06_cnt', 'mon06_time', 'mon07_cnt', 'mon07_time', 'mon08_cnt', 'mon08_time', 'mon09_cnt', 'mon09_time', 'mon10_cnt', 'mon10_time',
            'mon11_cnt', 'mon11_time', 'mon12_cnt', 'mon12_time'];
        this.paginationCollection = new Array();
        this.page = 1;
        this.pageSize = 10;
    }
    AngelTimeAnnualComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.codeService.angelCodeGet("자원활동", "활동구분").subscribe(function (res) {
            _this.act_div_list = res;
            console.info(_this.act_div_list);
        });
        this.codeService.angelCodeGet("자원활동", "활동주기").subscribe(function (res) {
            _this.act_period_list = res;
            console.info(_this.act_period_list);
        });
    };
    AngelTimeAnnualComponent.prototype.ngOnDestroy = function () { };
    AngelTimeAnnualComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            act_div: [''],
            act_period: [''],
            year: moment(),
            search_act_dept_name: ['']
        });
    };
    Object.defineProperty(AngelTimeAnnualComponent.prototype, "fc_actDiv", {
        get: function () { return this.form.get('act_div'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelTimeAnnualComponent.prototype, "fc_actPeriod", {
        get: function () { return this.form.get('act_period'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelTimeAnnualComponent.prototype, "fc_year", {
        get: function () { return this.form.get('year'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngelTimeAnnualComponent.prototype, "fc_deptName", {
        get: function () { return this.form.get('search_act_dept_name'); },
        enumerable: true,
        configurable: true
    });
    AngelTimeAnnualComponent.prototype.chosenYearHandler = function (normalizedMonth, datepicker) {
        this.fc_year.setValue(normalizedMonth);
        this.search();
        datepicker.close();
    };
    AngelTimeAnnualComponent.prototype.rangeComplete = function (event) {
        this.form.patchValue({
            gte_date: event.startDate,
            lte_date: event.endDate
        });
    };
    AngelTimeAnnualComponent.prototype.search = function () {
        var _this = this;
        this.statisticsService.statisticsTimeYearGet(dateFormat_Year(this.fc_year.value), this.fc_deptName.value, this.fc_actDiv.value, this.fc_actPeriod.value).subscribe({
            next: function (res) {
                console.info(res);
                _this.dataSource.data = res;
            },
            complete: function () {
                _this.sort.sortChange.emit({ active: _this.sort.active, direction: _this.sort.direction });
            }
        });
    };
    AngelTimeAnnualComponent.prototype.onClickExcelExport = function () {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(this.convertDatasource());
        var wscols = [{ wch: 20 }, { wch: 15 }, { wch: 20 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 },
            { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 },
            { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }];
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, '시간건수연보');
        XLSX.writeFile(wb, "\uC2DC\uAC04\uAC74\uC218\uC5F0\uBCF4_" + this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + ".xlsx");
    };
    AngelTimeAnnualComponent.prototype.convertDatasource = function () {
        var xlsxDatasource = [];
        this.dataSource.data.forEach(function (item) {
            xlsxDatasource.push({
                '국': item.department_name, '팀': item.team_name, '매장': item.location_name,
                '1월횟수': item.mon01_cnt, '1월시간': item.mon01_time, '2월횟수': item.mon02_cnt, '2월시간': item.mon02_time,
                '3월횟수': item.mon03_cnt, '3월시간': item.mon03_time, '4월횟수': item.mon04_cnt, '4월시간': item.mon04_time,
                '5월횟수': item.mon05_cnt, '5월시간': item.mon05_time, '6월횟수': item.mon06_cnt, '6월시간': item.mon06_time,
                '7월횟수': item.mon07_cnt, '7월시간': item.mon07_time, '8월횟수': item.mon08_cnt, '8월시간': item.mon08_time,
                '9월횟수': item.mon09_cnt, '9월시간': item.mon09_time, '10월횟수': item.mon10_cnt, '10월시간': item.mon10_time,
                '11월횟수': item.mon11_cnt, '11월시간': item.mon11_time, '12월횟수': item.mon12_cnt, '12월시간': item.mon12_time,
            });
        });
        return xlsxDatasource;
    };
    return AngelTimeAnnualComponent;
}());
export { AngelTimeAnnualComponent };
export { ɵ0 };
