/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BulkMessageBody } from '../model/bulkMessageBody';
import { BulkMessageFormatBody } from '../model/bulkMessageFormatBody';
import { Cache } from '../model/cache';
import { Empty } from '../model/empty';
import { Failure } from '../model/failure';
import { Fax } from '../model/fax';
import { History } from '../model/history';
import { Kakao } from '../model/kakao';
import { KakaoMessageBody } from '../model/kakaoMessageBody';
import { Mail } from '../model/mail';
import { Phone } from '../model/phone';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FeedbackService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 캐싱된 메타 정보 조회
     * 
     * @param mk meta_key
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cacheMkGet(mk: string, observe?: 'body', reportProgress?: boolean): Observable<Cache>;
    public cacheMkGet(mk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cache>>;
    public cacheMkGet(mk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cache>>;
    public cacheMkGet(mk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (mk === null || mk === undefined) {
            throw new Error('Required parameter mk was null or undefined when calling cacheMkGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Cache>(`${this.basePath}/cache/${encodeURIComponent(String(mk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 메타 정보 캐싱 (elastiCache-Redis)
     * 
     * @param cache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cachePost(cache: Cache, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public cachePost(cache: Cache, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public cachePost(cache: Cache, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public cachePost(cache: Cache, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (cache === null || cache === undefined) {
            throw new Error('Required parameter cache was null or undefined when calling cachePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/cache`,
            cache,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 공통 이력 저장 (dynamo-Common)
     * 
     * @param history 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dbitemPost(history: History, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public dbitemPost(history: History, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public dbitemPost(history: History, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public dbitemPost(history: History, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (history === null || history === undefined) {
            throw new Error('Required parameter history was null or undefined when calling dbitemPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/dbitem`,
            history,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 단순 메일 발송 (파일 첨부 No)
     * 
     * @param mail 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailPost(mail: Mail, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public emailPost(mail: Mail, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public emailPost(mail: Mail, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public emailPost(mail: Mail, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (mail === null || mail === undefined) {
            throw new Error('Required parameter mail was null or undefined when calling emailPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/email`,
            mail,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 카카오 알림톡 발송(대량)
     * 
     * @param kakao_message_body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kakaoBulkPost(kakao_message_body: KakaoMessageBody, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public kakaoBulkPost(kakao_message_body: KakaoMessageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public kakaoBulkPost(kakao_message_body: KakaoMessageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public kakaoBulkPost(kakao_message_body: KakaoMessageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (kakao_message_body === null || kakao_message_body === undefined) {
            throw new Error('Required parameter kakao_message_body was null or undefined when calling kakaoBulkPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/kko/kakao/bulk`,
            kakao_message_body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 카카오 알림톡 발송
     * 
     * @param kakao 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kakaoPost(kakao: Kakao, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public kakaoPost(kakao: Kakao, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public kakaoPost(kakao: Kakao, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public kakaoPost(kakao: Kakao, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (kakao === null || kakao === undefined) {
            throw new Error('Required parameter kakao was null or undefined when calling kakaoPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/kko/kakao`,
            kakao,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 문자 메시지 발송(대량, 포맷이 있는)
     * 
     * @param formatted_message_body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public smsBulkFormatPost(formatted_message_body: BulkMessageFormatBody, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public smsBulkFormatPost(formatted_message_body: BulkMessageFormatBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public smsBulkFormatPost(formatted_message_body: BulkMessageFormatBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public smsBulkFormatPost(formatted_message_body: BulkMessageFormatBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (formatted_message_body === null || formatted_message_body === undefined) {
            throw new Error('Required parameter formatted_message_body was null or undefined when calling smsBulkFormatPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/sms/bulk/format`,
            formatted_message_body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 문자 메시지 발송(대량)
     * 
     * @param message_body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public smsBulkPost(message_body: BulkMessageBody, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public smsBulkPost(message_body: BulkMessageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public smsBulkPost(message_body: BulkMessageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public smsBulkPost(message_body: BulkMessageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (message_body === null || message_body === undefined) {
            throw new Error('Required parameter message_body was null or undefined when calling smsBulkPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/sms/bulk`,
            message_body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 문자 메시지 발송
     * 
     * @param phone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public smsPost(phone: Phone, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public smsPost(phone: Phone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public smsPost(phone: Phone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public smsPost(phone: Phone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (phone === null || phone === undefined) {
            throw new Error('Required parameter phone was null or undefined when calling smsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/sms`,
            phone,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 웹팩스 메시지 발송 [TBD]
     * 
     * @param fax 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webfaxPost(fax: Fax, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public webfaxPost(fax: Fax, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public webfaxPost(fax: Fax, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public webfaxPost(fax: Fax, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fax === null || fax === undefined) {
            throw new Error('Required parameter fax was null or undefined when calling webfaxPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/webfax`,
            fax,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
