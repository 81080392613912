/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다.
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var VehicleService = /** @class */ (function () {
    function VehicleService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    VehicleService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    VehicleService.prototype.vehiclesInfoGet = function (owner_dept_id, driver_emp_id, vehicle_number, vehicle_full_number, vehicle_status, vehicle_id, del_flg, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (owner_dept_id !== undefined && owner_dept_id !== null) {
            queryParameters = queryParameters.set('owner_dept_id', owner_dept_id);
        }
        if (driver_emp_id !== undefined && driver_emp_id !== null) {
            queryParameters = queryParameters.set('driver_emp_id', driver_emp_id);
        }
        if (vehicle_number !== undefined && vehicle_number !== null) {
            queryParameters = queryParameters.set('vehicle_number', vehicle_number);
        }
        if (vehicle_full_number !== undefined && vehicle_full_number !== null) {
            queryParameters = queryParameters.set('vehicle_full_number', vehicle_full_number);
        }
        if (vehicle_status !== undefined && vehicle_status !== null) {
            queryParameters = queryParameters.set('vehicle_status', vehicle_status);
        }
        if (vehicle_id !== undefined && vehicle_id !== null) {
            queryParameters = queryParameters.set('vehicle_id', vehicle_id);
        }
        if (del_flg !== undefined && del_flg !== null) {
            queryParameters = queryParameters.set('del_flg', del_flg);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/vehicles/info", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    VehicleService.prototype.vehiclesManagementGet = function (gte_date, lte_date, owner_dept_id, vehicle_number, vehicle_full_number, management_div, vehicle_id, management_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (owner_dept_id !== undefined && owner_dept_id !== null) {
            queryParameters = queryParameters.set('owner_dept_id', owner_dept_id);
        }
        if (vehicle_number !== undefined && vehicle_number !== null) {
            queryParameters = queryParameters.set('vehicle_number', vehicle_number);
        }
        if (vehicle_full_number !== undefined && vehicle_full_number !== null) {
            queryParameters = queryParameters.set('vehicle_full_number', vehicle_full_number);
        }
        if (management_div !== undefined && management_div !== null) {
            queryParameters = queryParameters.set('management_div', management_div);
        }
        if (vehicle_id !== undefined && vehicle_id !== null) {
            queryParameters = queryParameters.set('vehicle_id', vehicle_id);
        }
        if (management_id !== undefined && management_id !== null) {
            queryParameters = queryParameters.set('management_id', management_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/vehicles/management", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    VehicleService.prototype.vehiclesMonthlyGet = function (gte_date, lte_date, owner_dept_id, vehicle_full_number, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling vehiclesMonthlyGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling vehiclesMonthlyGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (owner_dept_id !== undefined && owner_dept_id !== null) {
            queryParameters = queryParameters.set('owner_dept_id', owner_dept_id);
        }
        if (vehicle_full_number !== undefined && vehicle_full_number !== null) {
            queryParameters = queryParameters.set('vehicle_full_number', vehicle_full_number);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/vehicles/monthly", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    VehicleService.prototype.vehiclesPost = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vehiclesPost.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/vehicles", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    VehicleService.prototype.vehiclesVehicleIdDelete = function (vehicle_id, stop_date, vehicle_status, del_flg, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (vehicle_id === null || vehicle_id === undefined) {
            throw new Error('Required parameter vehicle_id was null or undefined when calling vehiclesVehicleIdDelete.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (stop_date !== undefined && stop_date !== null) {
            queryParameters = queryParameters.set('stop_date', stop_date);
        }
        if (vehicle_status !== undefined && vehicle_status !== null) {
            queryParameters = queryParameters.set('vehicle_status', vehicle_status);
        }
        if (del_flg !== undefined && del_flg !== null) {
            queryParameters = queryParameters.set('del_flg', del_flg);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/vehicles/" + encodeURIComponent(String(vehicle_id)), {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    VehicleService.prototype.vehiclesVehicleIdInfoPut = function (vehicle_id, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (vehicle_id === null || vehicle_id === undefined) {
            throw new Error('Required parameter vehicle_id was null or undefined when calling vehiclesVehicleIdInfoPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vehiclesVehicleIdInfoPut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/vehicles/" + encodeURIComponent(String(vehicle_id)) + "/info", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    VehicleService.prototype.vehiclesVehicleIdManagementManagementIdPut = function (vehicle_id, management_id, body, issue_date, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (vehicle_id === null || vehicle_id === undefined) {
            throw new Error('Required parameter vehicle_id was null or undefined when calling vehiclesVehicleIdManagementManagementIdPut.');
        }
        if (management_id === null || management_id === undefined) {
            throw new Error('Required parameter management_id was null or undefined when calling vehiclesVehicleIdManagementManagementIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vehiclesVehicleIdManagementManagementIdPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (issue_date !== undefined && issue_date !== null) {
            queryParameters = queryParameters.set('issue_date', issue_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/vehicles/" + encodeURIComponent(String(vehicle_id)) + "/management/" + encodeURIComponent(String(management_id)), body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    VehicleService.prototype.vehiclesVehicleIdManagementPost = function (vehicle_id, issue_date, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (vehicle_id === null || vehicle_id === undefined) {
            throw new Error('Required parameter vehicle_id was null or undefined when calling vehiclesVehicleIdManagementPost.');
        }
        if (issue_date === null || issue_date === undefined) {
            throw new Error('Required parameter issue_date was null or undefined when calling vehiclesVehicleIdManagementPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vehiclesVehicleIdManagementPost.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (issue_date !== undefined && issue_date !== null) {
            queryParameters = queryParameters.set('issue_date', issue_date);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/vehicles/" + encodeURIComponent(String(vehicle_id)) + "/management", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return VehicleService;
}());
export { VehicleService };
