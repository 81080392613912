import { OnInit } from '@angular/core';
import { AuthService } from 'fecommon';
var LogoutComponent = /** @class */ (function () {
    function LogoutComponent(authService) {
        this.authService = authService;
    }
    LogoutComponent.prototype.ngOnInit = function () {
        this.authService.signOut();
    };
    return LogoutComponent;
}());
export { LogoutComponent };
