/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EsDeptInfo } from '../model/esDeptInfo';
import { EsDeptToCur } from '../model/esDeptToCur';
import { EsEmpMeta } from '../model/esEmpMeta';
import { EsFailure } from '../model/esFailure';
import { EsVehicleInfo } from '../model/esVehicleInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsDeptService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 부서의 하위부서 아이디 와 이름 조회
     * 부서의 바로 아래 단계의 하위부서 아이디와 이름을 list로 전달
     * @param dept_id 부서의 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deptDeptIdChildrenGet(dept_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsDeptInfo>>;
    public deptDeptIdChildrenGet(dept_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDeptInfo>>>;
    public deptDeptIdChildrenGet(dept_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDeptInfo>>>;
    public deptDeptIdChildrenGet(dept_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptDeptIdChildrenGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDeptInfo>>(`${this.basePath}/dept/${encodeURIComponent(String(dept_id))}/children`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부서아이디로 emp_id 및 emp_name 조회
     * 부서아이디로 아이디와 이름을 가지고온다
     * @param dept_id 부서의 아이디
     * @param sub 하위부서의 직원까지 검색
     * @param permanent_emp 정규직만 검색할때 &#39;Y&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deptDeptIdEmpsGet(dept_id: string, sub?: 'Y' | 'N', permanent_emp?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<EsEmpMeta>>;
    public deptDeptIdEmpsGet(dept_id: string, sub?: 'Y' | 'N', permanent_emp?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsEmpMeta>>>;
    public deptDeptIdEmpsGet(dept_id: string, sub?: 'Y' | 'N', permanent_emp?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsEmpMeta>>>;
    public deptDeptIdEmpsGet(dept_id: string, sub?: 'Y' | 'N', permanent_emp?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptDeptIdEmpsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sub !== undefined && sub !== null) {
            queryParameters = queryParameters.set('sub', <any>sub);
        }
        if (permanent_emp !== undefined && permanent_emp !== null) {
            queryParameters = queryParameters.set('permanent_emp', <any>permanent_emp);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsEmpMeta>>(`${this.basePath}/dept/${encodeURIComponent(String(dept_id))}/emps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부서별 현재인원 정보를 가지고온다
     * 부서아이디로 현재인원 정보를 가지고온다
     * @param dept_id 부서의 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deptDeptIdToCurMemberGet(dept_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsDeptToCur>>;
    public deptDeptIdToCurMemberGet(dept_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDeptToCur>>>;
    public deptDeptIdToCurMemberGet(dept_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDeptToCur>>>;
    public deptDeptIdToCurMemberGet(dept_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptDeptIdToCurMemberGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDeptToCur>>(`${this.basePath}/dept/${encodeURIComponent(String(dept_id))}/to/cur_member`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부서아이디로 차량정보 조회
     * 부서아이디로 차량정보 조회
     * @param dept_id 부서의 아이디
     * @param sub 하위부서의 차량까지 검색
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deptDeptIdVehiclesGet(dept_id: string, sub?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<Array<EsVehicleInfo>>;
    public deptDeptIdVehiclesGet(dept_id: string, sub?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsVehicleInfo>>>;
    public deptDeptIdVehiclesGet(dept_id: string, sub?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsVehicleInfo>>>;
    public deptDeptIdVehiclesGet(dept_id: string, sub?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptDeptIdVehiclesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sub !== undefined && sub !== null) {
            queryParameters = queryParameters.set('sub', <any>sub);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsVehicleInfo>>(`${this.basePath}/dept/${encodeURIComponent(String(dept_id))}/vehicles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부서정보기준 검색
     * 부서정보를 검색한다.
     * @param dept_name 부서이름
     * @param shop_type 매장되살림터 구분에 따라 쿼리
     * @param exclude_dept_unit_id 검색에서 제외할 부서단위아이디코드 e.g.)dep_uni_5(매장/되살림터)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deptInfoGet(dept_name?: string, shop_type?: '매장' | '되살림터', exclude_dept_unit_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EsDeptInfo>>;
    public deptInfoGet(dept_name?: string, shop_type?: '매장' | '되살림터', exclude_dept_unit_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDeptInfo>>>;
    public deptInfoGet(dept_name?: string, shop_type?: '매장' | '되살림터', exclude_dept_unit_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDeptInfo>>>;
    public deptInfoGet(dept_name?: string, shop_type?: '매장' | '되살림터', exclude_dept_unit_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dept_name !== undefined && dept_name !== null) {
            queryParameters = queryParameters.set('dept_name', <any>dept_name);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', <any>shop_type);
        }
        if (exclude_dept_unit_id !== undefined && exclude_dept_unit_id !== null) {
            queryParameters = queryParameters.set('exclude_dept_unit_id', <any>exclude_dept_unit_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDeptInfo>>(`${this.basePath}/dept/info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부서정보를 dep_uni 단위로 검색(dep_uni_1~dep_uni_5)
     * 부서정보를 dep_uni 단위로 검색한다.
     * @param dep_uni dep_uni code
     * @param shop_type 매장되살림터 구분에 따라 쿼리
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deptUnitIdGet(dep_uni: string, shop_type?: '매장' | '되살림터', observe?: 'body', reportProgress?: boolean): Observable<Array<EsDeptInfo>>;
    public deptUnitIdGet(dep_uni: string, shop_type?: '매장' | '되살림터', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EsDeptInfo>>>;
    public deptUnitIdGet(dep_uni: string, shop_type?: '매장' | '되살림터', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EsDeptInfo>>>;
    public deptUnitIdGet(dep_uni: string, shop_type?: '매장' | '되살림터', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dep_uni === null || dep_uni === undefined) {
            throw new Error('Required parameter dep_uni was null or undefined when calling deptUnitIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dep_uni !== undefined && dep_uni !== null) {
            queryParameters = queryParameters.set('dep_uni', <any>dep_uni);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', <any>shop_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<EsDeptInfo>>(`${this.basePath}/dept/unit_id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
