<div class="section-basic">
    <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

        <div class="form-group m-form__group default-style datepicker-group">
            <label>조회기간</label>
            <div class="input-group">
                <input [matDatepicker]="start" (click)="start.open()" class="form-control m-input cursorpointer"
                    formControlName="gte_date" readonly>
                <mat-datepicker #start [dateRangePicker]="{startDate: start, endDate: end}"
                    (rangeComplete)="rangeComplete($event)"></mat-datepicker>
                <div class="input-group-append">
                    <span class="input-group-text">~</span>
                </div>
                <input [matDatepicker]="end" (click)="end.open()" class="form-control m-input cursorpointer"
                    formControlName="lte_date" readonly>
                <mat-datepicker #end [dateRangePicker]="{startDate: start, endDate: end}"
                    (rangeComplete)="rangeComplete($event)"></mat-datepicker>
            </div>
        </div>

        <div class="form-group m-form__group default-style">
            <label>조직</label>
            <input class="form-control m-input" formControlName="dept_name" (keydown.enter)="search()">
        </div>

        <div class="form-group m-form__group default-style">
            <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
        </div>

    </form>
</div>

<!-- 데이터 테이블 -->
<div class="result">
    <div class="section-basic mb-0 pb-2">
        <div class="btns_right_align">
            <button class="btn btn-sm btn-outline-primary" (click)="onClickExcelExport()"
                [disabled]="dataSource.data.length == 0">엑셀
                다운로드</button>
        </div>

        <div class="m--space-10"></div>

        <mat-table class="unset-cursor-pointer" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="recycle_place_name" sticky>
                <mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-unset-border sticky"> 되살림터
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="sticky-unset-border sticky"> {{element.recycle_place_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="shop_name" sticky>
                <mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-unset-border sticky"> 매장
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="sticky-unset-border sticky"> {{element.shop_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="good_second_category_name" sticky>
                <mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-unset-border sticky"> 구분
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="sticky-unset-border sticky">
                    {{element.good_second_category_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="good_third_category_name" sticky>
                <mat-header-cell *matHeaderCellDef mat-sort-header class="sticky"> 상세
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.good_third_category_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="normal_cnt">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 일반출고량 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.normal_cnt | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="event_cnt">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 행사출고량 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.event_cnt | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="none_cnt">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 무택출고량 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.none_cnt | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tot_cnt">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 합계 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tot_cnt | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="rate">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 판매대비<br />공급율 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.rate}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tot_sale_amt">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 매출 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tot_sale_amt | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="no_tax_sale_amt">
                <mat-header-cell *matHeaderCellDef mat-sort-header> VAT제외매출 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.no_tax_sale_amt | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tot_sale_qty">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 판매수량 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tot_sale_qty | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="avg_price">
                <mat-header-cell *matHeaderCellDef mat-sort-header> 평균단가 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.avg_price | currencyMask}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="group1" sticky>
                <mat-header-cell *matHeaderCellDef class="header-group1"></mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group2" sticky>
                <mat-header-cell *matHeaderCellDef class="header-group2"> 물품구분 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group3">
                <mat-header-cell *matHeaderCellDef class="header-group3"> 되살림터정보 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group4">
                <mat-header-cell *matHeaderCellDef class="header-group4"> 매장정보(판매수량) </mat-header-cell>
            </ng-container>

            <ng-container matColumnDef="noData">
                <mat-footer-cell *matFooterCellDef>
                    <div class="no-records">데이터가 존재하지 않습니다.</div>
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['group1', 'group2', 'group3', 'group4']">
            </mat-header-row>
            <mat-header-row *matHeaderRowDef="displayedColumns">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>

            <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource.data.length == 0)}">
            </mat-footer-row>
        </mat-table>

        <div class="m-separator m-separator--dashed"></div>
        <ngb-pagination [pageSize]="pageSize" [collectionSize]="paginationCollection? paginationCollection.length : 0"
            [(page)]="page" (pageChange)="setPage($event)" [boundaryLinks]="true" [maxSize]="5"></ngb-pagination>
    </div>
</div>
