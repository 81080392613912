import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LogisticsDeptsDivComponent } from '../logistics-depts-div/logistics-depts-div.component';
import { LogisticsDeptsDetailComponent } from '../logistics-depts-detail/logistics-depts-detail.component';
import { EsLogisticsService } from 'src/app/restapi/be-esquery';
import { DEFAULT_FORMATS, dateFormat } from 'src/app/share/utils/common-utils';
import { take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = DEFAULT_FORMATS;
var LogisticsDeptsComponent = /** @class */ (function () {
    function LogisticsDeptsComponent(fb, esLogisticsService) {
        this.fb = fb;
        this.esLogisticsService = esLogisticsService;
        this.minDate = moment().add(-3, 'month');
        this.maxDate = moment();
    }
    LogisticsDeptsComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    LogisticsDeptsComponent.prototype.ngOnDestroy = function () { };
    LogisticsDeptsComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            gte_date: this.minDate,
            lte_date: this.maxDate,
            dept_info: null,
            areas: null
        });
    };
    Object.defineProperty(LogisticsDeptsComponent.prototype, "fc_gteDate", {
        get: function () { return this.form.get('gte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LogisticsDeptsComponent.prototype, "fc_lteDate", {
        get: function () { return this.form.get('lte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LogisticsDeptsComponent.prototype, "fc_deptInfo", {
        get: function () { return this.form.get('dept_info'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LogisticsDeptsComponent.prototype, "fc_areas", {
        get: function () { return this.form.get('areas'); },
        enumerable: true,
        configurable: true
    });
    LogisticsDeptsComponent.prototype.onChangeStartDate = function () {
        var limitDate = moment(this.fc_gteDate.value).add(3, 'month');
        this.minDate = this.fc_gteDate.value;
        if (this.fc_gteDate.value > this.fc_lteDate.value) {
            this.fc_lteDate.setValue(this.fc_gteDate.value);
        }
        if (limitDate < this.fc_lteDate.value) {
            this.fc_lteDate.setValue(limitDate);
        }
    };
    LogisticsDeptsComponent.prototype.onChangeEndDate = function () {
        var limitDate = moment(this.fc_lteDate.value).add(-3, 'month');
        if (limitDate > this.fc_gteDate.value) {
            this.fc_gteDate.setValue(limitDate);
            this.minDate = limitDate;
        }
    };
    LogisticsDeptsComponent.prototype.selectDept = function (value) {
        if (!value || value == null) {
            this.fc_deptInfo.setValue(null);
            return;
        }
        this.fc_deptInfo.setValue(value);
        this.search();
    };
    LogisticsDeptsComponent.prototype.selectAreas = function (value) {
        if (!value || value == null) {
            this.fc_areas.setValue(null);
            return;
        }
        this.fc_areas.setValue(value);
        this.search();
    };
    LogisticsDeptsComponent.prototype.search = function () {
        this.logisticsDeptsGet();
    };
    LogisticsDeptsComponent.prototype.exportTOExcel = function () {
        var ws_div = XLSX.utils.table_to_sheet(this.divComp.table.nativeElement);
        var ws_detail = XLSX.utils.table_to_sheet(this.detailComp.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws_div, '수송구분별 순환지원 통계1');
        XLSX.utils.book_append_sheet(wb, ws_detail, '수송구분별 순환지원 통계2');
        XLSX.writeFile(wb, '수송구분별 순환지원 통계.xlsx');
    };
    LogisticsDeptsComponent.prototype.logisticsDeptsGet = function () {
        var _this = this;
        this.esLogisticsService.logisticsDeptsGet(dateFormat(this.fc_gteDate.value), dateFormat(this.fc_lteDate.value), this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null, this.fc_areas.value != null ? this.fc_areas.value.sk : null).pipe(take(1)).subscribe(function (res) {
            _this.logisticsDeptsStatistics = res;
        }, function (err) { console.error(err); });
    };
    return LogisticsDeptsComponent;
}());
export { LogisticsDeptsComponent };
export { ɵ0 };
