import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS } from '@angular/material';
import { DEFAULT_FORMATS, dateFormat } from 'src/app/share/utils/common-utils';
import { EsDeptInfo, EsWarehouseService } from 'src/app/restapi/be-esquery';
import { EsEventShippingStatisticalData } from 'src/app/restapi/be-esquery/model/esEventShippingStatisticalData';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-warehouse-event-shipping',
  templateUrl: './warehouse-event-shipping.component.html',
  styleUrls: ['./warehouse-event-shipping.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS }
  ]
})
export class WarehouseEventShippingComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  dataList: Array<EsEventShippingStatisticalData> = [];
  dataSource: MatTableDataSource<EsEventShippingStatisticalData> = new MatTableDataSource([]);
  displayedColumns = ['search_yyyy_mm', 'recycle_place_name', 'event_name', 'give_good_detail_cate_1', 'give_good_detail_cate_2', 'give_good_detail_cate_3', 'give_good_detail_cate_4', 'give_good_detail_cate_5', 'give_good_detail_cate_6',
    'give_good_detail_cate_7', 'give_good_detail_cate_8', 'give_good_detail_cate_9', 'give_good_detail_cate_10', 'give_good_detail_cate_11', 'give_good_detail_cate_12'];
  minDate = moment().add(-3, 'month');
  maxDate = moment();

  constructor(
    private fb: FormBuilder,
    private esWarehouseService: EsWarehouseService
  ) { }

  ngOnInit() {
    this.initForm();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      gte_date: this.minDate,
      lte_date: this.maxDate,
      dept_info: null
    });
  }

  get fc_gteDate() { return this.form.get('gte_date'); }
  get fc_lteDate() { return this.form.get('lte_date'); }
  get fc_deptInfo() { return this.form.get('dept_info'); }

  onChangeStartDate() {
    const limitDate = moment(this.fc_gteDate.value).add(3, 'month');
    this.minDate = this.fc_gteDate.value;

    if (this.fc_gteDate.value > this.fc_lteDate.value) {
      this.fc_lteDate.setValue(this.fc_gteDate.value);
    }

    if (limitDate < this.fc_lteDate.value) {
      this.fc_lteDate.setValue(limitDate);
    }
  }

  onChangeEndDate() {
    const limitDate = moment(this.fc_lteDate.value).add(-3, 'month');

    if (limitDate > this.fc_gteDate.value) {
      this.fc_gteDate.setValue(limitDate);
      this.minDate = limitDate;
    }
  }

  selectDept(value: EsDeptInfo) {
    if (!value || value == null) {
      this.fc_deptInfo.setValue(null);
      return;
    }

    this.fc_deptInfo.setValue(value);
    this.search();
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      sorted = this.dataList.sort((a: EsEventShippingStatisticalData, b: EsEventShippingStatisticalData) => {
        let result = 0;
        if (a[sort.active] == undefined) a[sort.active] = "";
        if (b[sort.active] == undefined) b[sort.active] = "";

        if (a[sort.active] < b[sort.active]) result = -1;
        else if (a[sort.active] > b[sort.active]) result = 1;
        return result * (sort.direction === 'asc' ? 1 : -1);
      });
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsEventShippingStatisticalData>(this.dataList);
  }

  search() {
    this.eventShippingGet();
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '행사출고통계');
    XLSX.writeFile(wb, '행사출고통계.xlsx');
  }

  eventShippingGet() {
    this.dataList = [];

    this.esWarehouseService.warehouseRecyclePlaceEventShippingStatisticalTableGet(
      dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value),
      this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null
    ).pipe(
      take(1)
    ).subscribe((res: Array<EsEventShippingStatisticalData>) => {
      this.dataList = res;
      this.initPage(this.sort);
    },
      (err) => {
        console.error(err);
        this.initPage(this.sort);
      }
    );
  }

}