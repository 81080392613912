import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { DEFAULT_FORMATS, dateFormat } from 'src/app/share/utils/common-utils';
import { EsWarehouseService } from 'src/app/restapi/be-esquery';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = DEFAULT_FORMATS;
var WarehouseShippingComponent = /** @class */ (function () {
    function WarehouseShippingComponent(fb, esWarehouseService) {
        this.fb = fb;
        this.esWarehouseService = esWarehouseService;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['search_yyyy_mm', 'recycle_place_name', 'give_good_cate', 'give_good_detail_cate', 'general_shipping',
            'event_shipping', 'sharing_shipping', 'no_tag_shipping_general', 'no_tag_shipping_material', 'no_tag_shipping_fixtures'];
        this.minDate = moment().add(-3, 'month');
        this.maxDate = moment();
    }
    WarehouseShippingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    WarehouseShippingComponent.prototype.ngOnDestroy = function () { };
    WarehouseShippingComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            gte_date: this.minDate,
            lte_date: this.maxDate,
            dept_info: null
        });
    };
    Object.defineProperty(WarehouseShippingComponent.prototype, "fc_gteDate", {
        get: function () { return this.form.get('gte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WarehouseShippingComponent.prototype, "fc_lteDate", {
        get: function () { return this.form.get('lte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WarehouseShippingComponent.prototype, "fc_deptInfo", {
        get: function () { return this.form.get('dept_info'); },
        enumerable: true,
        configurable: true
    });
    WarehouseShippingComponent.prototype.onChangeStartDate = function () {
        var limitDate = moment(this.fc_gteDate.value).add(3, 'month');
        this.minDate = this.fc_gteDate.value;
        if (this.fc_gteDate.value > this.fc_lteDate.value) {
            this.fc_lteDate.setValue(this.fc_gteDate.value);
        }
        if (limitDate < this.fc_lteDate.value) {
            this.fc_lteDate.setValue(limitDate);
        }
    };
    WarehouseShippingComponent.prototype.onChangeEndDate = function () {
        var limitDate = moment(this.fc_lteDate.value).add(-3, 'month');
        if (limitDate > this.fc_gteDate.value) {
            this.fc_gteDate.setValue(limitDate);
            this.minDate = limitDate;
        }
    };
    WarehouseShippingComponent.prototype.selectDept = function (value) {
        if (!value || value == null) {
            this.fc_deptInfo.setValue(null);
            return;
        }
        this.fc_deptInfo.setValue(value);
        this.search();
    };
    WarehouseShippingComponent.prototype.initPage = function (sort) {
        if (!this.dataList)
            this.dataList = [];
        var sorted = this.dataList;
        if (sort) {
            sorted = this.dataList.sort(function (a, b) {
                var result = 0;
                if (a[sort.active] == undefined)
                    a[sort.active] = "";
                if (b[sort.active] == undefined)
                    b[sort.active] = "";
                if (a[sort.active] < b[sort.active])
                    result = -1;
                else if (a[sort.active] > b[sort.active])
                    result = 1;
                return result * (sort.direction === 'asc' ? 1 : -1);
            });
        }
        this.dataList = sorted;
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    WarehouseShippingComponent.prototype.search = function () {
        this.shippingGet();
    };
    WarehouseShippingComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '전체출고통계');
        XLSX.writeFile(wb, '전체출고통계.xlsx');
    };
    WarehouseShippingComponent.prototype.shippingGet = function () {
        var _this = this;
        this.dataList = [];
        this.esWarehouseService.warehouseRecyclePlaceShippingStatisticalTableGet(dateFormat(this.fc_gteDate.value), dateFormat(this.fc_lteDate.value), this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null).pipe(take(1)).subscribe(function (res) {
            _this.dataList = res;
            _this.initPage(_this.sort);
        }, function (err) {
            console.error(err);
            _this.initPage(_this.sort);
        });
    };
    return WarehouseShippingComponent;
}());
export { WarehouseShippingComponent };
export { ɵ0 };
