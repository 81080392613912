import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MatDatepicker } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = YEAR_FORMATS;
var GivingsCallWatingComponent = /** @class */ (function () {
    function GivingsCallWatingComponent(fb, esGivingsService) {
        this.fb = fb;
        this.esGivingsService = esGivingsService;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['logistics_collect_area_name', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    }
    GivingsCallWatingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    GivingsCallWatingComponent.prototype.ngOnDestroy = function () { };
    GivingsCallWatingComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            year: moment()
        });
    };
    Object.defineProperty(GivingsCallWatingComponent.prototype, "fc_year", {
        get: function () { return this.form.get('year'); },
        enumerable: true,
        configurable: true
    });
    GivingsCallWatingComponent.prototype.chosenYearHandler = function (normalizedMonth, datepicker) {
        this.fc_year.setValue(normalizedMonth);
        this.search();
        datepicker.close();
    };
    GivingsCallWatingComponent.prototype.convertMonthInfo = function (month, data) {
        if (data.monthly_info) {
            var givingWatingday = data.monthly_info.find(function (x) { return x.date.split("-")[1] == month; });
            if (givingWatingday)
                return givingWatingday.wating_days;
        }
        return '';
    };
    GivingsCallWatingComponent.prototype.search = function () {
        this.givingsCallWatingGet();
    };
    GivingsCallWatingComponent.prototype.initPage = function (sort) {
        var _this = this;
        if (!this.dataList)
            this.dataList = [];
        var sorted = this.dataList;
        if (sort) {
            if (sort.active == '01' || sort.active == '02' || sort.active == '03' || sort.active == '04' || sort.active == '05' ||
                sort.active == '06' || sort.active == '07' || sort.active == '08' || sort.active == '09' || sort.active == '10' ||
                sort.active == '11' || sort.active == '12') {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    var a_data = Number(_this.convertMonthInfo(sort.active, a));
                    var b_data = Number(_this.convertMonthInfo(sort.active, b));
                    if (a_data < b_data)
                        result = -1;
                    else if (a_data > b_data)
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
            else {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    if (a[sort.active] == undefined)
                        a[sort.active] = "";
                    if (b[sort.active] == undefined)
                        b[sort.active] = "";
                    if (a[sort.active] < b[sort.active])
                        result = -1;
                    else if (a[sort.active] > b[sort.active])
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
        }
        this.dataList = sorted;
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    GivingsCallWatingComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '콜수거 방문소요일 통계');
        XLSX.writeFile(wb, '콜수거 방문소요일 통계.xlsx');
    };
    GivingsCallWatingComponent.prototype.givingsCallWatingGet = function () {
        var _this = this;
        this.dataList = [];
        this.esGivingsService.givingsCallWatingGet(dateFormat_Year(this.fc_year.value), dateFormat_Year(this.fc_year.value)).pipe(take(1)).subscribe(function (res) {
            _this.dataList = res;
            _this.initPage(_this.sort);
        }, function (err) {
            console.error(err);
            _this.initPage(_this.sort);
        });
    };
    return GivingsCallWatingComponent;
}());
export { GivingsCallWatingComponent };
export { ɵ0 };
