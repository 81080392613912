import { OnInit, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
var LogisticsEmpsDivComponent = /** @class */ (function () {
    function LogisticsEmpsDivComponent() {
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['emp_name', 'count', 'give_unit_count'];
    }
    LogisticsEmpsDivComponent.prototype.ngOnInit = function () {
    };
    LogisticsEmpsDivComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.dataList && changes.dataList.currentValue != null) {
            setTimeout(function () {
                _this.convertData();
            });
        }
    };
    LogisticsEmpsDivComponent.prototype.convertData = function () {
        var totalCount = this.dataList.map(function (x) { return x.count; }).filter(function (x) { return x; }).reduce(function (pValue, cValue) { return pValue + cValue; }, 0);
        var totalGiveUnitCount = this.dataList.map(function (x) { return x.give_unit_count; }).filter(function (x) { return x; }).reduce(function (pValue, cValue) { return pValue + cValue; }, 0);
        this.dataList.push({
            emp_name: '합계',
            count: totalCount,
            give_unit_count: totalGiveUnitCount
        });
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    return LogisticsEmpsDivComponent;
}());
export { LogisticsEmpsDivComponent };
