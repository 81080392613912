<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style datepicker-group">
      <label>기간</label>
      <div class="input-group">
        <input [matDatepicker]="start" [max]="maxDate" (click)="start.open()" class="form-control m-input"
          formControlName="gte_date" (dateChange)="onChangeStartDate()">
        <mat-datepicker #start></mat-datepicker>

        <button class="btn btn-secondary" type="button"></button>

        <mat-datepicker #end></mat-datepicker>
        <input [matDatepicker]="end" [min]="minDate" [max]="maxDate" (click)="end.open()" class="form-control m-input"
          formControlName="lte_date" (dateChange)="onChangeEndDate()">
      </div>
    </div>

    <app-dept-auto-complete [initLoginDeptFlg]="true" (selected)="selectDept($event)"></app-dept-auto-complete>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div class="statistics-mat-table" #TABLE>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="search_yyyy_mm">
          <mat-header-cell *matHeaderCellDef>년월</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.search_yyyy_mm}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="recycle_place_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>되살림터</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.recycle_place_name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="event_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>행사명</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.event_name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_1">
          <mat-header-cell *matHeaderCellDef mat-sort-header>의류상</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_1}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_2">
          <mat-header-cell *matHeaderCellDef mat-sort-header>의류하</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_2}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_3">
          <mat-header-cell *matHeaderCellDef mat-sort-header>의류아동</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_3}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_4">
          <mat-header-cell *matHeaderCellDef mat-sort-header>신발</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_4}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_5">
          <mat-header-cell *matHeaderCellDef mat-sort-header>주방</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_5}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_6">
          <mat-header-cell *matHeaderCellDef mat-sort-header>가방</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_6}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_7">
          <mat-header-cell *matHeaderCellDef mat-sort-header>잡화</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_7}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_8">
          <mat-header-cell *matHeaderCellDef mat-sort-header>예술품</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_8}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_9">
          <mat-header-cell *matHeaderCellDef mat-sort-header>일반도서</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_9}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_10">
          <mat-header-cell *matHeaderCellDef mat-sort-header>아동도서</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_10}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_11">
          <mat-header-cell *matHeaderCellDef mat-sort-header>음반DVD</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_11}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate_12">
          <mat-header-cell *matHeaderCellDef mat-sort-header>가전</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.give_good_detail_cate_12}}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>