/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Announce } from '../model/announce';
import { Empty } from '../model/empty';
import { Failure } from '../model/failure';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AnnounceService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 공지사항삭제
     * 
     * @param ann_code 공지사항코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announceDelete(ann_code: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public announceDelete(ann_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public announceDelete(ann_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public announceDelete(ann_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (ann_code === null || ann_code === undefined) {
            throw new Error('Required parameter ann_code was null or undefined when calling announceDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Empty>(`${this.basePath}/announce/${encodeURIComponent(String(ann_code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 공지사항 조회
     * 
     * @param gte_date 등록검색시작일
     * @param lte_date 등록검색종료일
     * @param title 공지제목
     * @param is_post 게시여부
     * @param ann_name 공지구분명
     * @param page_number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announceGet(gte_date?: string, lte_date?: string, title?: string, is_post?: 'Y' | 'N', ann_name?: '시스템' | '콜센터' | '공익상품' | '메아리상품', page_number?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Announce>>;
    public announceGet(gte_date?: string, lte_date?: string, title?: string, is_post?: 'Y' | 'N', ann_name?: '시스템' | '콜센터' | '공익상품' | '메아리상품', page_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Announce>>>;
    public announceGet(gte_date?: string, lte_date?: string, title?: string, is_post?: 'Y' | 'N', ann_name?: '시스템' | '콜센터' | '공익상품' | '메아리상품', page_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Announce>>>;
    public announceGet(gte_date?: string, lte_date?: string, title?: string, is_post?: 'Y' | 'N', ann_name?: '시스템' | '콜센터' | '공익상품' | '메아리상품', page_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (is_post !== undefined && is_post !== null) {
            queryParameters = queryParameters.set('is_post', <any>is_post);
        }
        if (ann_name !== undefined && ann_name !== null) {
            queryParameters = queryParameters.set('ann_name', <any>ann_name);
        }
        if (page_number !== undefined && page_number !== null) {
            queryParameters = queryParameters.set('page_number', <any>page_number);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Announce>>(`${this.basePath}/announce`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 공지사항등록.
     * 
     * @param announce 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announcePost(announce: Announce, observe?: 'body', reportProgress?: boolean): Observable<Announce>;
    public announcePost(announce: Announce, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Announce>>;
    public announcePost(announce: Announce, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Announce>>;
    public announcePost(announce: Announce, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (announce === null || announce === undefined) {
            throw new Error('Required parameter announce was null or undefined when calling announcePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Announce>(`${this.basePath}/announce`,
            announce,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 공지사항수정
     * 
     * @param ann_code 공지사항코드
     * @param announce 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announcePut(ann_code: string, announce: Announce, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public announcePut(ann_code: string, announce: Announce, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public announcePut(ann_code: string, announce: Announce, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public announcePut(ann_code: string, announce: Announce, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (ann_code === null || ann_code === undefined) {
            throw new Error('Required parameter ann_code was null or undefined when calling announcePut.');
        }
        if (announce === null || announce === undefined) {
            throw new Error('Required parameter announce was null or undefined when calling announcePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Empty>(`${this.basePath}/announce/${encodeURIComponent(String(ann_code))}`,
            announce,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
