import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { DEFAULT_FORMATS, dateFormat } from 'src/app/share/utils/common-utils';
import { EsShopsService } from 'src/app/restapi/be-esquery';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = DEFAULT_FORMATS;
var ShopGivingsProductionComponent = /** @class */ (function () {
    function ShopGivingsProductionComponent(fb, esShopsService) {
        this.fb = fb;
        this.esShopsService = esShopsService;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['date', 'department_name', 'team_name', 'location_name', 'production_count', '의류', '잡화', '도서음반', '가전'];
        this.minDate = moment().add(-3, 'month');
        this.maxDate = moment();
    }
    ShopGivingsProductionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    ShopGivingsProductionComponent.prototype.ngOnDestroy = function () { };
    ShopGivingsProductionComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            gte_date: this.minDate,
            lte_date: this.maxDate,
            shop: null
        });
    };
    Object.defineProperty(ShopGivingsProductionComponent.prototype, "fc_gteDate", {
        get: function () { return this.form.get('gte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShopGivingsProductionComponent.prototype, "fc_lteDate", {
        get: function () { return this.form.get('lte_date'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShopGivingsProductionComponent.prototype, "fc_shop", {
        get: function () { return this.form.get('shop'); },
        enumerable: true,
        configurable: true
    });
    ShopGivingsProductionComponent.prototype.onChangeStartDate = function () {
        var limitDate = moment(this.fc_gteDate.value).add(3, 'month');
        this.minDate = this.fc_gteDate.value;
        if (this.fc_gteDate.value > this.fc_lteDate.value) {
            this.fc_lteDate.setValue(this.fc_gteDate.value);
        }
        if (limitDate < this.fc_lteDate.value) {
            this.fc_lteDate.setValue(limitDate);
        }
    };
    ShopGivingsProductionComponent.prototype.onChangeEndDate = function () {
        var limitDate = moment(this.fc_lteDate.value).add(-3, 'month');
        if (limitDate > this.fc_gteDate.value) {
            this.fc_gteDate.setValue(limitDate);
            this.minDate = limitDate;
        }
    };
    ShopGivingsProductionComponent.prototype.selectShop = function (value) {
        if (!value || value == null) {
            this.fc_shop.setValue(null);
            return;
        }
        this.fc_shop.setValue(value);
        this.search();
    };
    ShopGivingsProductionComponent.prototype.convertCategories = function (category, categories) {
        if (categories.find(function (x) { return x.category == category; })) {
            return categories.find(function (x) { return x.category == category; }).sum;
        }
        return "";
    };
    ShopGivingsProductionComponent.prototype.search = function () {
        this.shopGivings();
    };
    ShopGivingsProductionComponent.prototype.initPage = function (sort) {
        var _this = this;
        if (!this.dataList)
            this.dataList = [];
        var sorted = this.dataList;
        if (sort) {
            if (sort.active == '의류' || sort.active == '잡화' || sort.active == '도서음반' || sort.active == '가전') {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    var a_cate_sum = Number(_this.convertCategories(sort.active, a.categories));
                    var b_cate_sum = Number(_this.convertCategories(sort.active, b.categories));
                    if (a_cate_sum < b_cate_sum)
                        result = -1;
                    else if (a_cate_sum > b_cate_sum)
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
            else {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    if (a[sort.active] == undefined)
                        a[sort.active] = "";
                    if (b[sort.active] == undefined)
                        b[sort.active] = "";
                    if (a[sort.active] < b[sort.active])
                        result = -1;
                    else if (a[sort.active] > b[sort.active])
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
        }
        this.dataList = sorted;
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    ShopGivingsProductionComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '매장별 점생산 통계');
        XLSX.writeFile(wb, '매장별 점생산 통계.xlsx');
    };
    ShopGivingsProductionComponent.prototype.shopGivings = function () {
        var _this = this;
        this.dataList = [];
        this.esShopsService.shopsGivingsProductionGet(dateFormat(this.fc_gteDate.value), dateFormat(this.fc_lteDate.value), this.fc_shop.value != null ? this.fc_shop.value.shop_id : null).pipe(take(1)).subscribe(function (res) {
            _this.dataList = res;
            _this.initPage(_this.sort);
        }, function (err) {
            console.error(err);
            _this.initPage(_this.sort);
        });
    };
    return ShopGivingsProductionComponent;
}());
export { ShopGivingsProductionComponent };
export { ɵ0 };
