import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS, MatDatepicker, MatDialog } from '@angular/material';
import { MONTH_FORMATS, dateFormat_Month, dateFormat_Month2 } from 'src/app/share/utils/common-utils';
import { EsDeptInfo, EsWarehouseService, EsTotalStatisticalData } from 'src/app/restapi/be-esquery';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { take, tap, takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

@Component({
  selector: 'app-warehouse-total',
  templateUrl: './warehouse-total.component.html',
  styleUrls: ['./warehouse-total.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS }
  ]
})
export class WarehouseTotalComponent implements OnInit, OnDestroy {

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  dataList: Array<EsTotalStatisticalData> = [];
  dataSource: MatTableDataSource<EsTotalStatisticalData> = new MatTableDataSource([]);
  displayedColumns = ['recycle_place_name', 'give_good_cate', 'retrieve_sum', 'production_sum', 'event_production', 'storage_capacity',
    'center_cycle_product_before','center_cycle_product_after', 'shop_production', 'event_disposal', 'calculation_disposal', 'disposal_rate', 'shipping_sum'];
  minDate = moment().add(-1, 'year');
  maxDate = moment();

  constructor(
    private fb: FormBuilder,
    private esWarehouseService: EsWarehouseService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initForm();

    this.sort.sortChange.pipe(
      tap((sort: Sort) => {
        this.initPage(sort);
      }),
      takeUntil(componentDestroyed(this)),
    ).subscribe();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      date: moment().add(-1, 'month'),
      dept_info: null
    });
  }

  get fc_date() { return this.form.get('date'); }
  get fc_deptInfo() { return this.form.get('dept_info'); }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fc_date.setValue(normalizedMonth);
    this.search();

    datepicker.close();
  }

  selectDept(value: EsDeptInfo) {
    if (!value || value == null) {
      this.fc_deptInfo.setValue(null);
      return;
    }
    
    this.fc_deptInfo.setValue(value);
    this.search();
  }

  get date() {
    return dateFormat_Month2(this.fc_date.value);
  }

  initPage(sort?: Sort) {
    if (!this.dataList) this.dataList = [];

    let sorted = this.dataList;
    if (sort) {
      sorted = this.dataList.sort((a: EsTotalStatisticalData, b: EsTotalStatisticalData) => {
        let result = 0;
        if (a[sort.active] == undefined) a[sort.active] = "";
        if (b[sort.active] == undefined) b[sort.active] = "";

        if (a[sort.active] < b[sort.active]) result = -1;
        else if (a[sort.active] > b[sort.active]) result = 1;
        return result * (sort.direction === 'asc' ? 1 : -1);
      });
    }

    this.dataList = sorted;
    this.dataSource = new MatTableDataSource<EsTotalStatisticalData>(this.dataList);
  }

  search() {
    this.totalGet();
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, '되살림터 종합 통계');
    XLSX.writeFile(wb, '되살림터 종합 통계.xlsx');
  }

  totalGet() {
    this.dataList = [];

    this.esWarehouseService.warehouseRecyclePlaceTotalStatisticalTableGet(
      dateFormat_Month(this.fc_date.value),
      dateFormat_Month(this.fc_date.value),
      this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null
    ).pipe(
      take(1)
    ).subscribe((res: Array<EsTotalStatisticalData>) => {
      this.dataList = res;
      this.initPage(this.sort);
    },
      (err) => {
        console.error(err);
        this.initPage(this.sort);
      }
    );
  }

}