import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material';
import { LogisticsEmpsDivComponent } from '../logistics-emps-div/logistics-emps-div.component';
import { LogisticsEmpsDetailComponent } from '../logistics-emps-detail/logistics-emps-detail.component';
import { EsLogisticsService, EsDeptInfo } from 'src/app/restapi/be-esquery';
import { EsLogisticsEmpsStatistics } from 'src/app/restapi/be-esquery/model/esLogisticsEmpsStatistics';
import { EsAreas } from 'src/app/restapi/be-esquery/model/esAreas';
import { DEFAULT_FORMATS, dateFormat } from 'src/app/share/utils/common-utils';
import { take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-logistics-emps',
  templateUrl: './logistics-emps.component.html',
  styleUrls: ['./logistics-emps.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMATS }
  ]
})
export class LogisticsEmpsComponent implements OnInit, OnDestroy {

  @ViewChild(LogisticsEmpsDivComponent) divComp: LogisticsEmpsDivComponent;
  @ViewChild(LogisticsEmpsDetailComponent) detailComp: LogisticsEmpsDetailComponent;
  form: FormGroup;
  logisticsEmpsStatistics: EsLogisticsEmpsStatistics;
  minDate = moment().add(-3, 'month');
  maxDate = moment();

  constructor(
    private fb: FormBuilder,
    private esLogisticsService: EsLogisticsService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() { }

  initForm() {
    this.form = this.fb.group({
      gte_date: this.minDate,
      lte_date: this.maxDate,
      dept_info: null,
      areas: null
    });
  }

  get fc_gteDate() { return this.form.get('gte_date'); }
  get fc_lteDate() { return this.form.get('lte_date'); }
  get fc_deptInfo() { return this.form.get('dept_info'); }
  get fc_areas() { return this.form.get('areas'); }

  onChangeStartDate() {
    const limitDate = moment(this.fc_gteDate.value).add(3, 'month');
    this.minDate = this.fc_gteDate.value;

    if (this.fc_gteDate.value > this.fc_lteDate.value) {
      this.fc_lteDate.setValue(this.fc_gteDate.value);
    }

    if (limitDate < this.fc_lteDate.value) {
      this.fc_lteDate.setValue(limitDate);
    }
  }

  onChangeEndDate() {
    const limitDate = moment(this.fc_lteDate.value).add(-3, 'month');

    if (limitDate > this.fc_gteDate.value) {
      this.fc_gteDate.setValue(limitDate);
      this.minDate = limitDate;
    }
  }

  selectDept(value: EsDeptInfo) {
    if (!value || value == null) {
      this.fc_deptInfo.setValue(null);
      return;
    }

    this.fc_deptInfo.setValue(value);
    this.search();
  }

  selectAreas(value: EsAreas) {
    if (!value || value == null) {
      this.fc_areas.setValue(null);
      return;
    }

    this.fc_areas.setValue(value);
    this.search();
  }

  search() {
    this.logisticsEmpsGet();
  }

  exportTOExcel() {
    const ws_div: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.divComp.table.nativeElement);
    const ws_detail: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.detailComp.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws_div, '운전자별 순환지원 통계1');
    XLSX.utils.book_append_sheet(wb, ws_detail, '운전자별 순환지원 통계2');
    XLSX.writeFile(wb, '운전자별 순환지원 통계.xlsx');
  }

  logisticsEmpsGet() {
    this.esLogisticsService.logisticsEmpsGet(
      dateFormat(this.fc_gteDate.value),
      dateFormat(this.fc_lteDate.value),
      this.fc_deptInfo.value != null ? this.fc_deptInfo.value.dept_id : null,
      this.fc_areas.value != null ? this.fc_areas.value.sk : null
    ).pipe(
      take(1)
    ).subscribe((res: EsLogisticsEmpsStatistics) => {
      this.logisticsEmpsStatistics = res;
    },
      (err) => { console.error(err); }
    );
  }

}