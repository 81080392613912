<div class="statistics-mat-table" #TABLE>

  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="logistics_div">
      <mat-header-cell *matHeaderCellDef>구분</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.logistics_div}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef>건수</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.count | currencyMask}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="give_unit_count">
      <mat-header-cell *matHeaderCellDef>물량(점)</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.give_unit_count | currencyMask}}</mat-cell>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>