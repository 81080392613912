import { EventEmitter, OnInit, SimpleChanges, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { AuthService } from 'fecommon';
import { CodeService } from 'src/app/restapi/be-angel';
var AngelDeptAutoCompleteComponent = /** @class */ (function () {
    function AngelDeptAutoCompleteComponent(authService, codeService) {
        var _this = this;
        this.authService = authService;
        this.codeService = codeService;
        this.selected = new EventEmitter();
        this.control = new FormControl('');
        this.displayName = (function (item) {
            if (_this.initValue && _this.initValue === _this.control.value) {
                return _this.initValue.dept_name;
            }
            return item ? item.dept_name : undefined;
        });
        this.propagateChange = function (_) { };
    }
    AngelDeptAutoCompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.placeholder = this.placeholder ? this.placeholder + ' 자동완성' : '';
        if (this.readonly)
            this.control.disable();
        this.searchResults = this.control.valueChanges.pipe(debounceTime(250), tap(function (value) {
            if (!value && _this.selectedItem) {
                _this.selectedItem = null;
                _this.selected.emit(null);
                _this.propagateChange('');
            }
        }), distinctUntilChanged(), switchMap(function (inputText) {
            return (typeof inputText != 'string' || inputText == null || inputText.trim() === '') ? of([]) :
                _this.codeService.angelDeptGet('SEARCHTYPE', inputText).pipe(catchError(function () { return of([]); }));
        }));
    };
    AngelDeptAutoCompleteComponent.prototype.ngOnDestroy = function () { };
    AngelDeptAutoCompleteComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.initValue) {
            this.initValue = changes.initValue.currentValue;
            console.log('this.initValue:', this.initValue);
            if (Object.entries(this.initValue).length > 0) {
                setTimeout(function () {
                    _this.control.setValue(_this.initValue);
                    _this.selectedItem = _this.initValue;
                });
            }
            else {
                this.control.setValue(null);
                this.selectedItem = null;
                this.propagateChange('');
            }
        }
        if (changes.readonly) {
            this.readonly = changes.readonly.currentValue;
            if (this.readonly) {
                this.control.setValue('');
                this.control.disable();
            }
            else {
                this.control.enable();
            }
        }
        if (changes.initLoginDeptFlg)
            this.setLoginDeptInfo();
    };
    AngelDeptAutoCompleteComponent.prototype.onAutoCompletionClosed = function () {
        if (!this.selectedItem) {
            this.control.setValue(null);
        }
        else {
            this.control.setValue(this.selectedItem);
            this.propagateChange(this.selectedItem.dept_name);
        }
    };
    AngelDeptAutoCompleteComponent.prototype.setLoginDeptInfo = function () {
        var _this = this;
        this.authService.userSubject.subscribe(function (userProfile) {
            console.log('userProfile:', userProfile);
            var deptInfo = {
                dept_id: userProfile.emp_dept_id,
                dept_name: userProfile.emp_dept_name,
                full_dept_id: userProfile.full_dept_id,
                full_dept_name: userProfile.full_dept_name
            };
            _this.control.setValue(deptInfo);
            _this.onDeptSelected(deptInfo);
        });
    };
    AngelDeptAutoCompleteComponent.prototype.onDeptSelected = function (value) {
        if (!value)
            return;
        this.selectedItem = value;
        this.selected.emit(value);
    };
    AngelDeptAutoCompleteComponent.prototype.writeValue = function (value) {
        this.propagateChange(value);
    };
    AngelDeptAutoCompleteComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    AngelDeptAutoCompleteComponent.prototype.registerOnTouched = function (fn) {
    };
    return AngelDeptAutoCompleteComponent;
}());
export { AngelDeptAutoCompleteComponent };
