<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style datepicker-group">
      <label>기간</label>
      <div class="input-group">
        <input [matDatepicker]="start" [max]="maxDate" (click)="start.open()" class="form-control m-input"
          formControlName="gte_date" (dateChange)="onChangeStartDate()">
        <mat-datepicker #start></mat-datepicker>

        <button class="btn btn-secondary" type="button"></button>

        <mat-datepicker #end></mat-datepicker>
        <input [matDatepicker]="end" [min]="minDate" [max]="maxDate" (click)="end.open()" class="form-control m-input"
          formControlName="lte_date" (dateChange)="onChangeEndDate()">
      </div>
    </div>

    <app-dept-auto-complete [initLoginDeptFlg]="true" (selected)="selectDept($event)"></app-dept-auto-complete>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div #TABLE>

      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="search_yyyy_mm">
          <th mat-header-cell *matHeaderCellDef>년월</th>
          <td mat-cell *matCellDef="let element">{{element.search_yyyy_mm}}</td>
        </ng-container>

        <ng-container matColumnDef="recycle_place_name">
          <th mat-header-cell *matHeaderCellDef>되살림터</th>
          <td mat-cell *matCellDef="let element">{{element.recycle_place_name}}</td>
        </ng-container>

        <ng-container matColumnDef="production_work_group_name">
          <th mat-header-cell *matHeaderCellDef>그룹</th>
          <td mat-cell *matCellDef="let element">{{element.production_work_group_name}}</td>
        </ng-container>

        <ng-container matColumnDef="give_good_cate">
          <th mat-header-cell *matHeaderCellDef>물품구분</th>
          <td mat-cell *matCellDef="let element">{{element.give_good_cate}}</td>
        </ng-container>

        <ng-container matColumnDef="give_good_detail_cate">
          <th mat-header-cell *matHeaderCellDef>물품상세</th>
          <td mat-cell *matCellDef="let element">{{element.give_good_detail_cate}}</td>
        </ng-container>

        <ng-container matColumnDef="general_production">
          <th mat-header-cell *matHeaderCellDef>일반생산</th>
          <td mat-cell *matCellDef="let element">{{element.general_production | currencyMask}}</td>
        </ng-container>

        <ng-container matColumnDef="event_production">
          <th mat-header-cell *matHeaderCellDef>행사생산</th>
          <td mat-cell *matCellDef="let element">{{element.event_production | currencyMask}}</td>
        </ng-container>

        <ng-container matColumnDef="event_disposal">
          <th mat-header-cell *matHeaderCellDef>행사폐기</th>
          <td mat-cell *matCellDef="let element">{{element.event_disposal | currencyMask}}</td>
        </ng-container>

        <ng-container matColumnDef="tot_working_people_num">
          <th mat-header-cell *matHeaderCellDef>총 생산인원</th>
          <td mat-cell *matCellDef="let element; let i = index"
            [attr.rowspan]="getRowSpan('production_work_group_name', i)"
            [style.display]="getRowSpan('production_work_group_name', i) ? '' : 'none'">
            {{element.tot_working_people_num | currencyMask}}</td>
        </ng-container>

        <ng-container matColumnDef="tot_working_time">
          <th mat-header-cell *matHeaderCellDef>총 생산소요시간</th>
          <td mat-cell *matCellDef="let element; let i = index"
            [attr.rowspan]="getRowSpan('production_work_group_name', i)"
            [style.display]="getRowSpan('production_work_group_name', i) ? '' : 'none'">{{element.tot_working_time}}
          </td>
        </ng-container>

        <ng-container matColumnDef="production_per_working_people">
          <th mat-header-cell *matHeaderCellDef>인당 생산량</th>
          <td mat-cell *matCellDef="let element; let i = index"
            [attr.rowspan]="getRowSpan('production_work_group_name', i)"
            [style.display]="getRowSpan('production_work_group_name', i) ? '' : 'none'">
            {{element.production_per_working_people}}</td>
        </ng-container>

        <ng-container matColumnDef="production_per_working_time">
          <th mat-header-cell *matHeaderCellDef>시간당 생산량</th>
          <td mat-cell *matCellDef="let element; let i = index"
            [attr.rowspan]="getRowSpan('production_work_group_name', i)"
            [style.display]="getRowSpan('production_work_group_name', i) ? '' : 'none'">
            {{element.production_per_working_time}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>