<div class="section-basic">
    <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" mon11alidate>
    
    <div class="form-group m-form__group default-style">
      <label class="required">연도</label>
      <input class="form-control m-input" [matDatepicker]="end" (click)="end.open()"
        (dateChange)="chosenYearHandler($event.target.value, end)" formControlName="year" readonly>
      <mat-datepicker #end startView="multi-year" (yearSelected)="chosenYearHandler($event, end)"></mat-datepicker>
    </div>

    <div class="form-group m-form__group default-style">
      <label>활동지</label>
      <app-angel-dept-auto-complete [placeholder]="'활동지'" formControlName="search_act_dept_name">
      </app-angel-dept-auto-complete>
    </div>
    
    <div class="form-group m-form__group default-style form_select">
      <label>활동구분</label>
      <select class="form-control m-input" formControlName="act_div" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_div_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

    <div class="form-group m-form__group default-style form_select">
      <label>활동주기</label>
      <select class="form-control m-input" formControlName="act_period" (change)="search()">
        <option value="">전체</option>
        <option *ngFor="let item of act_period_list" value="{{item.display_name}}">{{item.display_name}}
      </select>
    </div>

        <div class="form-group m-form__group default-style">
            <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
        </div>

    </form>
</div>

<!-- 데이터 테이블 -->
<div class="result">
    <div class="section-basic mb-0 pb-2">
        <div class="btns_right_align">
            <button class="btn btn-sm btn-outline-primary" (click)="onClickExcelExport()"
                [disabled]="dataSource.data.length == 0">엑셀
                다운로드</button>
        </div>

        <div class="m--space-10"></div>

        <mat-table class="unset-cursor-pointer" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="department_name" sticky>
                <mat-header-cell *matHeaderCellDef class="sticky-unset-border sticky"> 국
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="sticky-unset-border sticky"> {{element.department_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="team_name" sticky>
                <mat-header-cell *matHeaderCellDef class="sticky-unset-border team sticky"> 팀
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="sticky-unset-border team sticky">
                    {{element.team_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="location_name" sticky>
                <mat-header-cell *matHeaderCellDef class="store sticky"> 매장
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="store sticky"> {{element.location_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon01_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon01_active | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon01_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon01_wait  | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon01_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon01_stop | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon01_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon01_end | currencyMask}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon02_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon02_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon02_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon02_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon02_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon02_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon02_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon02_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon03_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon03_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon03_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon03_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon03_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon03_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon03_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon03_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon04_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon04_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon04_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon04_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon04_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon04_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon04_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon04_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon05_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon05_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon05_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon05_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon05_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon05_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon05_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon05_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon06_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon06_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon06_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon06_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon06_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon06_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon06_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon06_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon07_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon07_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon07_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon07_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon07_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon07_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon07_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon07_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon08_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon08_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon08_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon08_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon08_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon08_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon08_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon08_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon09_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon09_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon09_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon09_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon09_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon09_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon09_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon09_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon10_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon10_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon10_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon10_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon10_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon10_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon10_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon10_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon11_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon11_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon11_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon11_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon11_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon11_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon11_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon11_end | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon12_active">
                <mat-header-cell *matHeaderCellDef> 활동 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon12_active | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon12_wait">
                <mat-header-cell *matHeaderCellDef> 신규 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon12_wait | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon12_stop">
                <mat-header-cell *matHeaderCellDef> 중단 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon12_stop | currencyMask }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mon12_end">
                <mat-header-cell *matHeaderCellDef> 종료 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mon12_end | currencyMask }}
                </mat-cell>
            </ng-container>
            


            <ng-container matColumnDef="group1" sticky>
                <mat-header-cell *matHeaderCellDef class="header-group1"></mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group2">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 1월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group3">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 2월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group4">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 3월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group5">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 4월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group6">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 5월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group7">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 6월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group8">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 7월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group9">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 8월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group10">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 9월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group11">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 10월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group12">
                <mat-header-cell *matHeaderCellDef class="header-group2"> 11월 </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="group13">
                <mat-header-cell *matHeaderCellDef class="header-group3"> 12월 </mat-header-cell>
            </ng-container>

            <ng-container matColumnDef="noData">
                <mat-footer-cell *matFooterCellDef>
                    <div class="no-records">데이터가 존재하지 않습니다.</div>
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['group1', 'group2', 'group3', 'group4', 'group5', 'group6', 'group7', 'group8', 'group9', 'group10', 'group11', 'group12', 'group13']">
            </mat-header-row>
            <mat-header-row *matHeaderRowDef="displayedColumns">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'row-default':row,'row-team':row.location_name == '팀소계','row-department':row.team_name == '국소계'}">
            </mat-row>

            <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource.data.length == 0)}">
            </mat-footer-row>
        </mat-table>

        <div class="m-mon09arator m-mon09arator--dashed"></div>
        </div>
</div>
