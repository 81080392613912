import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort, MAT_DATE_FORMATS, MatDatepicker } from '@angular/material';
import { EsRateSales, EsSalesService } from 'src/app/restapi/be-esquery';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DateRangePicker } from 'src/app/share/components/directives/date-range-picker.directive';
import { dateFormat, DEFAULT_FORMATS, YEAR_FORMATS, dateFormat_Year } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { CodeService, StatisticsService, TimeYear } from 'src/app/restapi/be-angel';
import { Code } from 'src/app/restapi/be-angel';

const moment = _moment;

@Component({
  selector: 'app-angel-time-annual',
  templateUrl: './angel-time-annual.component.html',
  styleUrls: ['./angel-time-annual.component.scss'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ]
})
export class AngelTimeAnnualComponent implements OnInit {
    form: FormGroup;
    dataSource: MatTableDataSource<TimeYear> = new MatTableDataSource([]);
    displayedColumns = ['department_name', 'team_name', 'location_name', 'mon01_cnt', 'mon01_time','mon02_cnt', 'mon02_time', 'mon03_cnt', 'mon03_time', 'mon04_cnt', 'mon04_time',
      'mon05_cnt', 'mon05_time', 'mon06_cnt', 'mon06_time',  'mon07_cnt', 'mon07_time', 'mon08_cnt', 'mon08_time', 'mon09_cnt', 'mon09_time', 'mon10_cnt', 'mon10_time',
      'mon11_cnt', 'mon11_time', 'mon12_cnt', 'mon12_time'];
    paginationCollection: Array<any> = new Array<any>();
    page: number = 1;
    pageSize: number = 10;
    act_div_list: Array<Code>;
    act_period_list: Array<Code>;

    @ViewChild(MatSort) sort: MatSort;

    constructor(private fb: FormBuilder, private statisticsService: StatisticsService, private datePipe: DatePipe, private codeService: CodeService) { }

    ngOnInit() {
      this.initForm();
      this.search();

      this.codeService.angelCodeGet("자원활동","활동구분").subscribe( res => {
        this.act_div_list = res;
        console.info(this.act_div_list);
      })
      this.codeService.angelCodeGet("자원활동","활동주기").subscribe( res => {
        this.act_period_list = res;
        console.info(this.act_period_list);
      })

    }

    ngOnDestroy(): void { }

    initForm() {
      this.form = this.fb.group({
        act_div: [''],
        act_period: [''],
        year: moment(),
        search_act_dept_name: ['']
      });
    }

    get fc_actDiv() { return this.form.get('act_div'); }
    get fc_actPeriod() { return this.form.get('act_period'); }
    get fc_year() { return this.form.get('year'); }
    get fc_deptName() { return this.form.get('search_act_dept_name'); }


    chosenYearHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
      this.fc_year.setValue(normalizedMonth);
      this.search();
      
      datepicker.close();
    }

    rangeComplete(event: DateRangePicker) {
      this.form.patchValue({
        gte_date: event.startDate,
        lte_date: event.endDate
      });
    }

    search() {
      this.statisticsService.statisticsTimeYearGet(
        dateFormat_Year(this.fc_year.value), this.fc_deptName.value,
        this.fc_actDiv.value, this.fc_actPeriod.value).subscribe({
          next: res => {
            console.info(res);
            this.dataSource.data = res;
          },
          complete: () => {
            this.sort.sortChange.emit({ active: this.sort.active, direction: this.sort.direction });
          }
        });
    }

    onClickExcelExport() {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertDatasource());
      const wscols = [{ wch: 20 }, { wch: 15 }, { wch: 20 }, { wch: 8 }, { wch: 8 }, { wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },
      { wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },
      { wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 },{ wch: 8 }];
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, '시간건수연보');
      XLSX.writeFile(wb, `시간건수연보_${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}.xlsx`);
    }

    convertDatasource() {
      const xlsxDatasource: Array<any> = [];

      this.dataSource.data.forEach((item: any) => {
        xlsxDatasource.push({
          '국': item.department_name, '팀': item.team_name, '매장': item.location_name,
          '1월횟수': item.mon01_cnt , '1월시간':  item.mon01_time, '2월횟수': item.mon02_cnt , '2월시간':  item.mon02_time,
          '3월횟수': item.mon03_cnt , '3월시간':  item.mon03_time, '4월횟수': item.mon04_cnt , '4월시간':  item.mon04_time, 
          '5월횟수': item.mon05_cnt , '5월시간':  item.mon05_time, '6월횟수': item.mon06_cnt , '6월시간':  item.mon06_time, 
          '7월횟수': item.mon07_cnt , '7월시간':  item.mon07_time, '8월횟수': item.mon08_cnt , '8월시간':  item.mon08_time, 
          '9월횟수': item.mon09_cnt , '9월시간':  item.mon09_time, '10월횟수': item.mon10_cnt , '10월시간':  item.mon10_time, 
          '11월횟수': item.mon11_cnt , '11월시간':  item.mon11_time, '12월횟수': item.mon12_cnt , '12월시간':  item.mon12_time, 
      });
      });
      return xlsxDatasource;
    }

}
