/**
 * common
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Empty } from '../model/empty';
import { Failure } from '../model/failure';
import { Tags } from '../model/tags';
import { TagsInfo } from '../model/tagsInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TagsService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9990/common/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 태그 삭제
     * 
     * @param tag_code 태그 코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tagsDelete(tag_code: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public tagsDelete(tag_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public tagsDelete(tag_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public tagsDelete(tag_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tag_code === null || tag_code === undefined) {
            throw new Error('Required parameter tag_code was null or undefined when calling tagsDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Empty>(`${this.basePath}/tags/${encodeURIComponent(String(tag_code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 태그 조회
     * 
     * @param tag_name 태그 명칭
     * @param tag_ref_name 적용범위
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tagsGet(tag_name?: string, tag_ref_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TagsInfo>>;
    public tagsGet(tag_name?: string, tag_ref_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TagsInfo>>>;
    public tagsGet(tag_name?: string, tag_ref_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TagsInfo>>>;
    public tagsGet(tag_name?: string, tag_ref_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tag_name !== undefined && tag_name !== null) {
            queryParameters = queryParameters.set('tag_name', <any>tag_name);
        }
        if (tag_ref_name !== undefined && tag_ref_name !== null) {
            queryParameters = queryParameters.set('tag_ref_name', <any>tag_ref_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TagsInfo>>(`${this.basePath}/tags`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 태그 정보 등록.
     * 
     * @param tags 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tagsPost(tags: Tags, observe?: 'body', reportProgress?: boolean): Observable<Tags>;
    public tagsPost(tags: Tags, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tags>>;
    public tagsPost(tags: Tags, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tags>>;
    public tagsPost(tags: Tags, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tags === null || tags === undefined) {
            throw new Error('Required parameter tags was null or undefined when calling tagsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Tags>(`${this.basePath}/tags`,
            tags,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 태그 수정
     * 
     * @param tag_code 태그 코드
     * @param tags 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tagsPut(tag_code: string, tags: Tags, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public tagsPut(tag_code: string, tags: Tags, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public tagsPut(tag_code: string, tags: Tags, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public tagsPut(tag_code: string, tags: Tags, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tag_code === null || tag_code === undefined) {
            throw new Error('Required parameter tag_code was null or undefined when calling tagsPut.');
        }
        if (tags === null || tags === undefined) {
            throw new Error('Required parameter tags was null or undefined when calling tagsPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Empty>(`${this.basePath}/tags/${encodeURIComponent(String(tag_code))}`,
            tags,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 태그명 조회
     * 
     * @param tag_ref_name 적용범위
     * @param full_dept_id 적용범위
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tagsTagsNameRefNameGet(tag_ref_name: string, full_dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TagsInfo>>;
    public tagsTagsNameRefNameGet(tag_ref_name: string, full_dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TagsInfo>>>;
    public tagsTagsNameRefNameGet(tag_ref_name: string, full_dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TagsInfo>>>;
    public tagsTagsNameRefNameGet(tag_ref_name: string, full_dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tag_ref_name === null || tag_ref_name === undefined) {
            throw new Error('Required parameter tag_ref_name was null or undefined when calling tagsTagsNameRefNameGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tag_ref_name !== undefined && tag_ref_name !== null) {
            queryParameters = queryParameters.set('tag_ref_name', <any>tag_ref_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TagsInfo>>(`${this.basePath}/tags/tags_name`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
