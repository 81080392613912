import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort, Sort, MatDatepicker } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { EsGivingsService } from 'src/app/restapi/be-esquery';
import { take, tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dateFormat_Year, YEAR_FORMATS } from 'src/app/share/utils/common-utils';
import * as XLSX from 'xlsx';
import * as _moment from 'moment';
var moment = _moment;
var ɵ0 = YEAR_FORMATS;
var GivingsRegionComponent = /** @class */ (function () {
    function GivingsRegionComponent(fb, esGivingsService) {
        this.fb = fb;
        this.esGivingsService = esGivingsService;
        this.dataList = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['state', 'city', 'give_route', 'give_count', 'give_unit_count',
            '01c', '01uc', '02c', '02uc', '03c', '03uc', '04c', '04uc', '05c', '05uc', '06c', '06uc',
            '07c', '07uc', '08c', '08uc', '09c', '09uc', '10c', '10uc', '11c', '11uc', '12c', '12uc'];
    }
    GivingsRegionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initForm();
        this.search();
        this.sort.sortChange.pipe(tap(function (sort) {
            _this.initPage(sort);
        }), takeUntil(componentDestroyed(this))).subscribe();
    };
    GivingsRegionComponent.prototype.ngOnDestroy = function () { };
    GivingsRegionComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            year: moment(),
            areas: ''
        });
    };
    Object.defineProperty(GivingsRegionComponent.prototype, "fc_year", {
        get: function () { return this.form.get('year'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GivingsRegionComponent.prototype, "fc_areas", {
        get: function () { return this.form.get('areas'); },
        enumerable: true,
        configurable: true
    });
    GivingsRegionComponent.prototype.chosenYearHandler = function (normalizedMonth, datepicker) {
        this.fc_year.setValue(normalizedMonth);
        this.search();
        datepicker.close();
    };
    GivingsRegionComponent.prototype.convertMonthInfo = function (month, type, data) {
        if (data.monthly_info) {
            var givingCount = data.monthly_info.find(function (x) { return x.date.split("-")[1] == month; });
            if (givingCount) {
                if (type == 'give_count')
                    return givingCount.give_count;
                else if (type == 'give_unit_count')
                    return givingCount.give_unit_count;
            }
        }
        return '';
    };
    GivingsRegionComponent.prototype.search = function () {
        this.givingsRegionGet();
    };
    GivingsRegionComponent.prototype.initPage = function (sort) {
        var _this = this;
        if (!this.dataList)
            this.dataList = [];
        var sorted = this.dataList;
        if (sort) {
            if (sort.active == '01c' || sort.active == '02c' || sort.active == '03c' || sort.active == '04c' || sort.active == '05c' || sort.active == '06c' ||
                sort.active == '07c' || sort.active == '08c' || sort.active == '09c' || sort.active == '10c' || sort.active == '11c' || sort.active == '12c' ||
                sort.active == '01uc' || sort.active == '02uc' || sort.active == '03uc' || sort.active == '04uc' || sort.active == '05uc' || sort.active == '06uc' ||
                sort.active == '07uc' || sort.active == '08uc' || sort.active == '09uc' || sort.active == '10uc' || sort.active == '11uc' || sort.active == '12uc') {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    var a_data = 0;
                    var b_data = 0;
                    if (sort.active.includes('uc')) {
                        a_data = Number(_this.convertMonthInfo(sort.active.substr(0, 2), 'give_unit_count', a));
                        b_data = Number(_this.convertMonthInfo(sort.active.substr(0, 2), 'give_unit_count', b));
                    }
                    else {
                        a_data = Number(_this.convertMonthInfo(sort.active.substr(0, 2), 'give_count', a));
                        b_data = Number(_this.convertMonthInfo(sort.active.substr(0, 2), 'give_count', b));
                    }
                    if (a_data < b_data)
                        result = -1;
                    else if (a_data > b_data)
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
            else {
                sorted = this.dataList.sort(function (a, b) {
                    var result = 0;
                    if (a[sort.active] == undefined)
                        a[sort.active] = "";
                    if (b[sort.active] == undefined)
                        b[sort.active] = "";
                    if (a[sort.active] < b[sort.active])
                        result = -1;
                    else if (a[sort.active] > b[sort.active])
                        result = 1;
                    return result * (sort.direction === 'asc' ? 1 : -1);
                });
            }
        }
        this.dataList = sorted;
        this.dataSource = new MatTableDataSource(this.dataList);
    };
    GivingsRegionComponent.prototype.exportTOExcel = function () {
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '지역별 통계');
        XLSX.writeFile(wb, '지역별 통계.xlsx');
    };
    GivingsRegionComponent.prototype.givingsRegionGet = function () {
        var _this = this;
        this.dataList = [];
        this.esGivingsService.givingsRegionGet(dateFormat_Year(this.fc_year.value), dateFormat_Year(this.fc_year.value), this.fc_areas.value).pipe(take(1)).subscribe(function (res) {
            _this.dataList = res;
            _this.initPage(_this.sort);
        }, function (err) {
            console.error(err);
            _this.initPage(_this.sort);
        });
    };
    return GivingsRegionComponent;
}());
export { GivingsRegionComponent };
export { ɵ0 };
