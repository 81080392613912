/**
 * angel
 *  ## 자원활동관련 데이타를 관리한다. 
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AngelAct } from '../model/angelAct';
import { AngelGet } from '../model/angelGet';
import { AngelPost } from '../model/angelPost';
import { Failure } from '../model/failure';
import { GroupAngel } from '../model/groupAngel';
import { IssueHistory } from '../model/issueHistory';
import { Note } from '../model/note';
import { NotePost } from '../model/notePost';
import { Place } from '../model/place';
import { PlaceChange } from '../model/placeChange';
import { Report } from '../model/report';
import { ReportAct } from '../model/reportAct';
import { ReportDept } from '../model/reportDept';
import { ReportPost } from '../model/reportPost';
import { ReportPut } from '../model/reportPut';
import { Request } from '../model/request';
import { RequestGet } from '../model/requestGet';
import { RequestHistory } from '../model/requestHistory';
import { Reserved } from '../model/reserved';
import { Tag } from '../model/tag';
import { TimeTable } from '../model/timeTable';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AngelService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9905/angel/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 활동조회 활동기간 전체를 조회한다.
     * 스케줄러로 자동 계산된 활동기간을 조회한다.
     * @param act_dept_id 활동지아이디
     * @param angel_now_status 현재활동상태
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allActivityLookupGet(act_dept_id?: string, angel_now_status?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AngelAct>>;
    public allActivityLookupGet(act_dept_id?: string, angel_now_status?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AngelAct>>>;
    public allActivityLookupGet(act_dept_id?: string, angel_now_status?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AngelAct>>>;
    public allActivityLookupGet(act_dept_id?: string, angel_now_status?: string, angel_name?: string, angel_cellp_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (act_dept_id !== undefined && act_dept_id !== null) {
            queryParameters = queryParameters.set('act_dept_id', <any>act_dept_id);
        }
        if (angel_now_status !== undefined && angel_now_status !== null) {
            queryParameters = queryParameters.set('angel_now_status', <any>angel_now_status);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<AngelAct>>(`${this.basePath}/all_activity_lookup`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 접수자 활동천사로 배치대기를 조회한다.
     * 접수자 활동천사로 배치대기를 조회한다.
     * @param act_dept_name 활동지명
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelBatchWaitGet(act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reserved>>;
    public angelBatchWaitGet(act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reserved>>>;
    public angelBatchWaitGet(act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reserved>>>;
    public angelBatchWaitGet(act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', <any>act_dept_name);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Reserved>>(`${this.basePath}/angel_batch_wait`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 접수자를 활동지의 활동천사로 배치한다.
     * 접수자를 활동지의 활동천사로 배치한다
     * @param reserved_id 사용자 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelBatchWaitPut(reserved_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelBatchWaitPut(reserved_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelBatchWaitPut(reserved_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelBatchWaitPut(reserved_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reserved_id === null || reserved_id === undefined) {
            throw new Error('Required parameter reserved_id was null or undefined when calling angelBatchWaitPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/angel_batch_wait/${encodeURIComponent(String(reserved_id))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 정보를 삭제한다
     * angel_id의 info정보의 활동천사(자원활동)내역을 삭제한다
     * @param angel_id 사용자 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoDelete(angel_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelInfoDelete(angel_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelInfoDelete(angel_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelInfoDelete(angel_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelInfoDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/angel_info/${encodeURIComponent(String(angel_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 정보를 조회한다
     * 로그인정보를 이용 활동천사 정보를 조회한다
     * @param act_dept_name 활동지명
     * @param act_status 활동지상태
     * @param act_week_name 요일
     * @param angel_name 활동천사명
     * @param angel_cellp_no 활동천사핸드폰
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param angel_edu_yn 교육수료여부
     * @param angel_job 직업
     * @param angel_position 소속단체
     * @param angel_1365_join 1365가입여부
     * @param angel_tag 자원활동태그(|구분)
     * @param angel_remark 비고사항
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoGet(act_dept_name?: string, act_status?: '활동중' | '활동종료' | '활동대기' | '일시중단', act_week_name?: '월요일' | '화요일' | '수요일' | '목요일' | '금요일' | '토요일' | '일요일', angel_name?: string, angel_cellp_no?: string, angel_type?: string, angel_term?: string, angel_edu_yn?: '수료' | '미수료', angel_job?: string, angel_position?: string, angel_1365_join?: '가입' | '미가입', angel_tag?: string, angel_remark?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GroupAngel>>;
    public angelInfoGet(act_dept_name?: string, act_status?: '활동중' | '활동종료' | '활동대기' | '일시중단', act_week_name?: '월요일' | '화요일' | '수요일' | '목요일' | '금요일' | '토요일' | '일요일', angel_name?: string, angel_cellp_no?: string, angel_type?: string, angel_term?: string, angel_edu_yn?: '수료' | '미수료', angel_job?: string, angel_position?: string, angel_1365_join?: '가입' | '미가입', angel_tag?: string, angel_remark?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GroupAngel>>>;
    public angelInfoGet(act_dept_name?: string, act_status?: '활동중' | '활동종료' | '활동대기' | '일시중단', act_week_name?: '월요일' | '화요일' | '수요일' | '목요일' | '금요일' | '토요일' | '일요일', angel_name?: string, angel_cellp_no?: string, angel_type?: string, angel_term?: string, angel_edu_yn?: '수료' | '미수료', angel_job?: string, angel_position?: string, angel_1365_join?: '가입' | '미가입', angel_tag?: string, angel_remark?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GroupAngel>>>;
    public angelInfoGet(act_dept_name?: string, act_status?: '활동중' | '활동종료' | '활동대기' | '일시중단', act_week_name?: '월요일' | '화요일' | '수요일' | '목요일' | '금요일' | '토요일' | '일요일', angel_name?: string, angel_cellp_no?: string, angel_type?: string, angel_term?: string, angel_edu_yn?: '수료' | '미수료', angel_job?: string, angel_position?: string, angel_1365_join?: '가입' | '미가입', angel_tag?: string, angel_remark?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', <any>act_dept_name);
        }
        if (act_status !== undefined && act_status !== null) {
            queryParameters = queryParameters.set('act_status', <any>act_status);
        }
        if (act_week_name !== undefined && act_week_name !== null) {
            queryParameters = queryParameters.set('act_week_name', <any>act_week_name);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }
        if (angel_edu_yn !== undefined && angel_edu_yn !== null) {
            queryParameters = queryParameters.set('angel_edu_yn', <any>angel_edu_yn);
        }
        if (angel_job !== undefined && angel_job !== null) {
            queryParameters = queryParameters.set('angel_job', <any>angel_job);
        }
        if (angel_position !== undefined && angel_position !== null) {
            queryParameters = queryParameters.set('angel_position', <any>angel_position);
        }
        if (angel_1365_join !== undefined && angel_1365_join !== null) {
            queryParameters = queryParameters.set('angel_1365_join', <any>angel_1365_join);
        }
        if (angel_tag !== undefined && angel_tag !== null) {
            queryParameters = queryParameters.set('angel_tag', <any>angel_tag);
        }
        if (angel_remark !== undefined && angel_remark !== null) {
            queryParameters = queryParameters.set('angel_remark', <any>angel_remark);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<GroupAngel>>(`${this.basePath}/angel_info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 정보를 활동천사 아이디로 조회한다
     * 활동천사 정보를 활동천사 아이디로 조회
     * @param angel_id 활동천사아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoIdGet(angel_id: string, observe?: 'body', reportProgress?: boolean): Observable<AngelGet>;
    public angelInfoIdGet(angel_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AngelGet>>;
    public angelInfoIdGet(angel_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AngelGet>>;
    public angelInfoIdGet(angel_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelInfoIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<AngelGet>(`${this.basePath}/angel_info/${encodeURIComponent(String(angel_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 중복확인
     * 기존 활동천사 정보에 활동천사의 존재를 조회한다
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰번호
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoOverlapGet(angel_name?: string, angel_cellp_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GroupAngel>>;
    public angelInfoOverlapGet(angel_name?: string, angel_cellp_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GroupAngel>>>;
    public angelInfoOverlapGet(angel_name?: string, angel_cellp_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GroupAngel>>>;
    public angelInfoOverlapGet(angel_name?: string, angel_cellp_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<GroupAngel>>(`${this.basePath}/angel_info_overlap`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동지 기본정보 추가
     * 로그인정보를 이용 활동천사의 활동지 기본정보를 추가
     * @param angel_id 활동천사ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoPlaceAddPut(angel_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelInfoPlaceAddPut(angel_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelInfoPlaceAddPut(angel_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelInfoPlaceAddPut(angel_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelInfoPlaceAddPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_id !== undefined && angel_id !== null) {
            queryParameters = queryParameters.set('angel_id', <any>angel_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/angel_info_place_add`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 정보를 등록한다
     * 활동천사에 존재하지 않는 활동천사를 신규등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoPost(body: AngelPost, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelInfoPost(body: AngelPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelInfoPost(body: AngelPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelInfoPost(body: AngelPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelInfoPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/angel_info`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 정보를 수정한다
     * angel_id의 info정보의 활동천사(자원활동)내역을 변경한다
     * @param angel_id 사용자 아이디
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoPut(angel_id: string, body: AngelPost, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelInfoPut(angel_id: string, body: AngelPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelInfoPut(angel_id: string, body: AngelPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelInfoPut(angel_id: string, body: AngelPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelInfoPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelInfoPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/angel_info/${encodeURIComponent(String(angel_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동일지 정보를 조회한다(현시점)
     * 활동천사 활동일지 정보를 조회한다(현시점)
     * @param angel_name 활동천사명
     * @param angel_cellp_no 활동천사핸드폰
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoReportGet(angel_name?: string, angel_cellp_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GroupAngel>>;
    public angelInfoReportGet(angel_name?: string, angel_cellp_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GroupAngel>>>;
    public angelInfoReportGet(angel_name?: string, angel_cellp_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GroupAngel>>>;
    public angelInfoReportGet(angel_name?: string, angel_cellp_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<GroupAngel>>(`${this.basePath}/angel_info_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사정보에 태그리스트를 붙인다
     * 활동천사정보에 태그리스트를 붙인다 
     * @param angel_id 사용자 아이디
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelInfoTagsListPut(angel_id: string, body: Array<Tag>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelInfoTagsListPut(angel_id: string, body: Array<Tag>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelInfoTagsListPut(angel_id: string, body: Array<Tag>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelInfoTagsListPut(angel_id: string, body: Array<Tag>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelInfoTagsListPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelInfoTagsListPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/angel_info/tags/${encodeURIComponent(String(angel_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동수첩을 삭제한다
     * angel_id의 info정보의 활동수첩 정보를 삭제한다
     * @param angel_id 사용자 아이디
     * @param note_id 사용자 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelNoteDelete(angel_id: string, note_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelNoteDelete(angel_id: string, note_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelNoteDelete(angel_id: string, note_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelNoteDelete(angel_id: string, note_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelNoteDelete.');
        }
        if (note_id === null || note_id === undefined) {
            throw new Error('Required parameter note_id was null or undefined when calling angelNoteDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/angel_note/${encodeURIComponent(String(angel_id))}/${encodeURIComponent(String(note_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동수첩을 조회한다
     * 로그인정보를 이용 활동천사의 활동수첩을 조회한다
     * @param angel_id angel_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelNoteGet(angel_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Note>>;
    public angelNoteGet(angel_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Note>>>;
    public angelNoteGet(angel_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Note>>>;
    public angelNoteGet(angel_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelNoteGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_id !== undefined && angel_id !== null) {
            queryParameters = queryParameters.set('angel_id', <any>angel_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Note>>(`${this.basePath}/angel_note`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 정보를 등록한다
     * 활동천사에 존재하지 않는 활동천사를 신규등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelNotePost(body: NotePost, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelNotePost(body: NotePost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelNotePost(body: NotePost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelNotePost(body: NotePost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelNotePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/angel_note`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동지 상태변경 이력을 조회한다
     * 로그인정보를 이용 활동천사의 활동지 상태변경 이력을 조회한다
     * @param angel_id angel_id
     * @param management_dept_name 활동지명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelPlaceChangeGet(angel_id: string, management_dept_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PlaceChange>>;
    public angelPlaceChangeGet(angel_id: string, management_dept_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PlaceChange>>>;
    public angelPlaceChangeGet(angel_id: string, management_dept_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PlaceChange>>>;
    public angelPlaceChangeGet(angel_id: string, management_dept_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelPlaceChangeGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_id !== undefined && angel_id !== null) {
            queryParameters = queryParameters.set('angel_id', <any>angel_id);
        }
        if (management_dept_name !== undefined && management_dept_name !== null) {
            queryParameters = queryParameters.set('management_dept_name', <any>management_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<PlaceChange>>(`${this.basePath}/angel_place_change`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동지 정보를 삭제한다
     * angel_id의 info정보의 활동지 정보를 삭제한다
     * @param angel_id 사용자 아이디
     * @param act_id 사용자 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelPlaceDelete(angel_id: string, act_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelPlaceDelete(angel_id: string, act_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelPlaceDelete(angel_id: string, act_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelPlaceDelete(angel_id: string, act_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelPlaceDelete.');
        }
        if (act_id === null || act_id === undefined) {
            throw new Error('Required parameter act_id was null or undefined when calling angelPlaceDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/angel_place/${encodeURIComponent(String(angel_id))}/${encodeURIComponent(String(act_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동지정보를 조회한다
     * 로그인정보를 이용 활동천사의 활동지정보를 조회한다
     * @param angel_id angel_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelPlaceGet(angel_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Place>>;
    public angelPlaceGet(angel_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Place>>>;
    public angelPlaceGet(angel_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Place>>>;
    public angelPlaceGet(angel_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelPlaceGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_id !== undefined && angel_id !== null) {
            queryParameters = queryParameters.set('angel_id', <any>angel_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Place>>(`${this.basePath}/angel_place`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동지 정보를 수정한다
     * angel_id의 info정보의 활동지 정보를 변경한다
     * @param angel_id 사용자 아이디
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelPlacePut(angel_id: string, body: Array<Place>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelPlacePut(angel_id: string, body: Array<Place>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelPlacePut(angel_id: string, body: Array<Place>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelPlacePut(angel_id: string, body: Array<Place>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelPlacePut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelPlacePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/angel_place/${encodeURIComponent(String(angel_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동내역 확인서 출력정보를 저장한다
     * 활동천사 활동내역 확인서 출력정보를 저장한다
     * @param angel_id 활동천사아이디
     * @param angel_name 신청자명
     * @param angel_cellp_no 신청자전화번호
     * @param print_no 발급번호
     * @param select_item 상세내역(2013-07-07 ~ 2016-05-21|전체부서|33회|265시간|확인서_상세내역포함)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportConfirmPrintPut(angel_id: string, angel_name: string, angel_cellp_no: string, print_no: string, select_item: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelReportConfirmPrintPut(angel_id: string, angel_name: string, angel_cellp_no: string, print_no: string, select_item: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelReportConfirmPrintPut(angel_id: string, angel_name: string, angel_cellp_no: string, print_no: string, select_item: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelReportConfirmPrintPut(angel_id: string, angel_name: string, angel_cellp_no: string, print_no: string, select_item: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelReportConfirmPrintPut.');
        }
        if (angel_name === null || angel_name === undefined) {
            throw new Error('Required parameter angel_name was null or undefined when calling angelReportConfirmPrintPut.');
        }
        if (angel_cellp_no === null || angel_cellp_no === undefined) {
            throw new Error('Required parameter angel_cellp_no was null or undefined when calling angelReportConfirmPrintPut.');
        }
        if (print_no === null || print_no === undefined) {
            throw new Error('Required parameter print_no was null or undefined when calling angelReportConfirmPrintPut.');
        }
        if (select_item === null || select_item === undefined) {
            throw new Error('Required parameter select_item was null or undefined when calling angelReportConfirmPrintPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_id !== undefined && angel_id !== null) {
            queryParameters = queryParameters.set('angel_id', <any>angel_id);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }
        if (print_no !== undefined && print_no !== null) {
            queryParameters = queryParameters.set('print_no', <any>print_no);
        }
        if (select_item !== undefined && select_item !== null) {
            queryParameters = queryParameters.set('select_item', <any>select_item);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.put<any>(`${this.basePath}/angel_report/confirm_print`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동내역 확인서 신청한다
     * 활동천사의 활동내역 확인서를 신청한다
     * @param angel_id 활동천사아이디
     * @param angel_email 신청이메일주소
     * @param gte_date 활동시작일자
     * @param lte_date 활동종료일자
     * @param report_dept_name 활동지명
     * @param act_type 내역서포함여부
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportConfirmPut(angel_id: string, angel_email: string, gte_date?: string, lte_date?: string, report_dept_name?: string, act_type?: 'Y' | 'N', observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelReportConfirmPut(angel_id: string, angel_email: string, gte_date?: string, lte_date?: string, report_dept_name?: string, act_type?: 'Y' | 'N', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelReportConfirmPut(angel_id: string, angel_email: string, gte_date?: string, lte_date?: string, report_dept_name?: string, act_type?: 'Y' | 'N', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelReportConfirmPut(angel_id: string, angel_email: string, gte_date?: string, lte_date?: string, report_dept_name?: string, act_type?: 'Y' | 'N', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelReportConfirmPut.');
        }
        if (angel_email === null || angel_email === undefined) {
            throw new Error('Required parameter angel_email was null or undefined when calling angelReportConfirmPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_id !== undefined && angel_id !== null) {
            queryParameters = queryParameters.set('angel_id', <any>angel_id);
        }
        if (angel_email !== undefined && angel_email !== null) {
            queryParameters = queryParameters.set('angel_email', <any>angel_email);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (report_dept_name !== undefined && report_dept_name !== null) {
            queryParameters = queryParameters.set('report_dept_name', <any>report_dept_name);
        }
        if (act_type !== undefined && act_type !== null) {
            queryParameters = queryParameters.set('act_type', <any>act_type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.put<any>(`${this.basePath}/angel_report/confirm`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동일지 대상자를 조회한다
     * 지정일자 활동일지 대상자를 조회한다
     * @param report_date 활동일자
     * @param report_dept_id 활동지아이디
     * @param act_status 활동지상태
     * @param angel_name 활동천사명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportDateDeptGet(report_date: string, report_dept_id: string, act_status?: string, angel_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ReportAct>>;
    public angelReportDateDeptGet(report_date: string, report_dept_id: string, act_status?: string, angel_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReportAct>>>;
    public angelReportDateDeptGet(report_date: string, report_dept_id: string, act_status?: string, angel_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReportAct>>>;
    public angelReportDateDeptGet(report_date: string, report_dept_id: string, act_status?: string, angel_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (report_date === null || report_date === undefined) {
            throw new Error('Required parameter report_date was null or undefined when calling angelReportDateDeptGet.');
        }
        if (report_dept_id === null || report_dept_id === undefined) {
            throw new Error('Required parameter report_dept_id was null or undefined when calling angelReportDateDeptGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (report_date !== undefined && report_date !== null) {
            queryParameters = queryParameters.set('report_date', <any>report_date);
        }
        if (report_dept_id !== undefined && report_dept_id !== null) {
            queryParameters = queryParameters.set('report_dept_id', <any>report_dept_id);
        }
        if (act_status !== undefined && act_status !== null) {
            queryParameters = queryParameters.set('act_status', <any>act_status);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<ReportAct>>(`${this.basePath}/angel_report_date_dept`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동일지를 선택하여 등록.
     * 활동천사 활동일지를 선택하여 등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportDateDeptPost(body: ReportDept, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelReportDateDeptPost(body: ReportDept, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelReportDateDeptPost(body: ReportDept, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelReportDateDeptPost(body: ReportDept, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelReportDateDeptPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/angel_report_date_dept`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동일지를 삭제한다
     * 활동천사 활동일지 정보를 삭제한다
     * @param report_id 활동일지 아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportDelete(report_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelReportDelete(report_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelReportDelete(report_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelReportDelete(report_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (report_id === null || report_id === undefined) {
            throw new Error('Required parameter report_id was null or undefined when calling angelReportDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/angel_report/${encodeURIComponent(String(report_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동일지 이력을 조회한다
     * 로그인정보를 이용 활동천사의 활동일지 이력을 조회한다
     * @param gte_date 활동시작일자
     * @param lte_date 활동종료일자
     * @param report_dept_name 활동지명
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportGet(gte_date: string, lte_date: string, report_dept_name?: string, angel_name?: string, angel_cellp_no?: string, angel_type?: string, angel_term?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Report>>;
    public angelReportGet(gte_date: string, lte_date: string, report_dept_name?: string, angel_name?: string, angel_cellp_no?: string, angel_type?: string, angel_term?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Report>>>;
    public angelReportGet(gte_date: string, lte_date: string, report_dept_name?: string, angel_name?: string, angel_cellp_no?: string, angel_type?: string, angel_term?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Report>>>;
    public angelReportGet(gte_date: string, lte_date: string, report_dept_name?: string, angel_name?: string, angel_cellp_no?: string, angel_type?: string, angel_term?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling angelReportGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling angelReportGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (report_dept_name !== undefined && report_dept_name !== null) {
            queryParameters = queryParameters.set('report_dept_name', <any>report_dept_name);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Report>>(`${this.basePath}/angel_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동내역을 조회한다
     * 활동천사의 활동내역을 조회한다
     * @param angel_id 활동천사아이디
     * @param gte_date 활동시작일자
     * @param lte_date 활동종료일자
     * @param report_dept_name 활동지명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportIdGet(angel_id: string, gte_date?: string, lte_date?: string, report_dept_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Report>>;
    public angelReportIdGet(angel_id: string, gte_date?: string, lte_date?: string, report_dept_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Report>>>;
    public angelReportIdGet(angel_id: string, gte_date?: string, lte_date?: string, report_dept_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Report>>>;
    public angelReportIdGet(angel_id: string, gte_date?: string, lte_date?: string, report_dept_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling angelReportIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (angel_id !== undefined && angel_id !== null) {
            queryParameters = queryParameters.set('angel_id', <any>angel_id);
        }
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (report_dept_name !== undefined && report_dept_name !== null) {
            queryParameters = queryParameters.set('report_dept_name', <any>report_dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Report>>(`${this.basePath}/angel_report_id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동내역 확인서 발급내역을 조회한다
     * 활동천사의 활동내역 확인서 발급내역을 조회한다
     * @param gte_date 발급시작일자
     * @param lte_date 발급종료일자
     * @param angel_name 활동천사명
     * @param angel_cellp_no 활동천사전화
     * @param select_item 발급내역
     * @param item_issue_emp_name 발급자
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportIssueHistoryGet(gte_date?: string, lte_date?: string, angel_name?: string, angel_cellp_no?: string, select_item?: string, item_issue_emp_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<IssueHistory>>;
    public angelReportIssueHistoryGet(gte_date?: string, lte_date?: string, angel_name?: string, angel_cellp_no?: string, select_item?: string, item_issue_emp_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IssueHistory>>>;
    public angelReportIssueHistoryGet(gte_date?: string, lte_date?: string, angel_name?: string, angel_cellp_no?: string, select_item?: string, item_issue_emp_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IssueHistory>>>;
    public angelReportIssueHistoryGet(gte_date?: string, lte_date?: string, angel_name?: string, angel_cellp_no?: string, select_item?: string, item_issue_emp_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }
        if (select_item !== undefined && select_item !== null) {
            queryParameters = queryParameters.set('select_item', <any>select_item);
        }
        if (item_issue_emp_name !== undefined && item_issue_emp_name !== null) {
            queryParameters = queryParameters.set('item_issue_emp_name', <any>item_issue_emp_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<IssueHistory>>(`${this.basePath}/angel_report_issue_history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동일지 정보를 등록한다
     * 활동천사 활동일지를 신규등록한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportPost(body: ReportPost, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelReportPost(body: ReportPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelReportPost(body: ReportPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelReportPost(body: ReportPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelReportPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/angel_report`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 활동일지 정보를 수정한다
     * 활동천사 활동일지를 변경한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelReportPut(body: ReportPut, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelReportPut(body: ReportPut, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelReportPut(body: ReportPut, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelReportPut(body: ReportPut, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelReportPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/angel_report`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 요청사항을 조회한다
     * 각부서의 활동천사 요청사항을 조회한다
     * @param request_act_dept_id 활동부서아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelRequestGet(request_act_dept_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Request>>;
    public angelRequestGet(request_act_dept_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Request>>>;
    public angelRequestGet(request_act_dept_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Request>>>;
    public angelRequestGet(request_act_dept_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request_act_dept_id === null || request_act_dept_id === undefined) {
            throw new Error('Required parameter request_act_dept_id was null or undefined when calling angelRequestGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request_act_dept_id !== undefined && request_act_dept_id !== null) {
            queryParameters = queryParameters.set('request_act_dept_id', <any>request_act_dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Request>>(`${this.basePath}/angel_request`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 요청사항의 히스토리를 조회한다
     * 각부서의 활동천사 요청사항의 히스토리를 조회한다
     * @param request_act_id 활동천사요청아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelRequestHistoryGet(request_act_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RequestHistory>>;
    public angelRequestHistoryGet(request_act_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RequestHistory>>>;
    public angelRequestHistoryGet(request_act_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RequestHistory>>>;
    public angelRequestHistoryGet(request_act_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request_act_id === null || request_act_id === undefined) {
            throw new Error('Required parameter request_act_id was null or undefined when calling angelRequestHistoryGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request_act_id !== undefined && request_act_id !== null) {
            queryParameters = queryParameters.set('request_act_id', <any>request_act_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<RequestHistory>>(`${this.basePath}/angel_request/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사요청 부서정보를 조회한다
     * 활동천사요청 부서정보를 조회한다
     * @param request_dept_id 활동부서코드
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelRequestManagementGet(request_dept_id: string, observe?: 'body', reportProgress?: boolean): Observable<RequestGet>;
    public angelRequestManagementGet(request_dept_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RequestGet>>;
    public angelRequestManagementGet(request_dept_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RequestGet>>;
    public angelRequestManagementGet(request_dept_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request_dept_id === null || request_dept_id === undefined) {
            throw new Error('Required parameter request_dept_id was null or undefined when calling angelRequestManagementGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request_dept_id !== undefined && request_dept_id !== null) {
            queryParameters = queryParameters.set('request_dept_id', <any>request_dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<RequestGet>(`${this.basePath}/angel_request_management`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사요청 부서정보를 수정한다
     * 활동천사요청 부서정보를 변경한다
     * @param request_dept_id 활동부서코드
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelRequestManagementPut(request_dept_id: string, body: RequestGet, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelRequestManagementPut(request_dept_id: string, body: RequestGet, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelRequestManagementPut(request_dept_id: string, body: RequestGet, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelRequestManagementPut(request_dept_id: string, body: RequestGet, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request_dept_id === null || request_dept_id === undefined) {
            throw new Error('Required parameter request_dept_id was null or undefined when calling angelRequestManagementPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelRequestManagementPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request_dept_id !== undefined && request_dept_id !== null) {
            queryParameters = queryParameters.set('request_dept_id', <any>request_dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/angel_request_management`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사 요청사항 정보를 수정한다
     * 활동천사 요청사항을 변경한다
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelRequestPut(body: Request, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public angelRequestPut(body: Request, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public angelRequestPut(body: Request, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public angelRequestPut(body: Request, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling angelRequestPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/angel_request`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동조회 시간표를 조회한다.
     * 매장별 주별 활동시간표를 조회한다.
     * @param act_dept_id 활동지아이디
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelTimetableGet(act_dept_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TimeTable>>;
    public angelTimetableGet(act_dept_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TimeTable>>>;
    public angelTimetableGet(act_dept_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TimeTable>>>;
    public angelTimetableGet(act_dept_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (act_dept_id === null || act_dept_id === undefined) {
            throw new Error('Required parameter act_dept_id was null or undefined when calling angelTimetableGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (act_dept_id !== undefined && act_dept_id !== null) {
            queryParameters = queryParameters.set('act_dept_id', <any>act_dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<TimeTable>>(`${this.basePath}/angel_timetable`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동조회 활동기간 기간를 조회한다.
     * 스케줄러로 자동 계산된 활동기간을 조회한다.
     * @param gte_date 참여시작일자
     * @param lte_date 참여종료일자
     * @param act_dept_name 활동지명
     * @param angel_name 활동천사명
     * @param angel_cellp_no 핸드폰
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public periodActivityLookupGet(gte_date: string, lte_date: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AngelAct>>;
    public periodActivityLookupGet(gte_date: string, lte_date: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AngelAct>>>;
    public periodActivityLookupGet(gte_date: string, lte_date: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AngelAct>>>;
    public periodActivityLookupGet(gte_date: string, lte_date: string, act_dept_name?: string, angel_name?: string, angel_cellp_no?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling periodActivityLookupGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling periodActivityLookupGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', <any>gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', <any>lte_date);
        }
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', <any>act_dept_name);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', <any>angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', <any>angel_cellp_no);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<AngelAct>>(`${this.basePath}/period_activity_lookup`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
