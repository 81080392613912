/**
 * angel
 *  ## 자원활동관련 데이타를 관리한다.
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var GroupService = /** @class */ (function () {
    function GroupService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9905/angel/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    GroupService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    GroupService.prototype.groupCollegeCollegeIdDelete = function (group_id, college_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupCollegeCollegeIdDelete.');
        }
        if (college_id === null || college_id === undefined) {
            throw new Error('Required parameter college_id was null or undefined when calling groupCollegeCollegeIdDelete.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/group/college/" + encodeURIComponent(String(group_id)) + "/" + encodeURIComponent(String(college_id)), {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupCollegeDelete = function (group_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupCollegeDelete.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (group_id !== undefined && group_id !== null) {
            queryParameters = queryParameters.set('group_id', group_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/group/college", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupCollegeGet = function (group_name, college_name, group_status, etc_add, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (group_name !== undefined && group_name !== null) {
            queryParameters = queryParameters.set('group_name', group_name);
        }
        if (college_name !== undefined && college_name !== null) {
            queryParameters = queryParameters.set('college_name', college_name);
        }
        if (group_status !== undefined && group_status !== null) {
            queryParameters = queryParameters.set('group_status', group_status);
        }
        if (etc_add !== undefined && etc_add !== null) {
            queryParameters = queryParameters.set('etc_add', etc_add);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/college", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupCollegePost = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupCollegePost.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/group/college", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupCollegePut = function (group_id, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupCollegePut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupCollegePut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (group_id !== undefined && group_id !== null) {
            queryParameters = queryParameters.set('group_id', group_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/college", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupJoinAngelDelete = function (join_id, angel_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (join_id === null || join_id === undefined) {
            throw new Error('Required parameter join_id was null or undefined when calling groupJoinAngelDelete.');
        }
        if (angel_id === null || angel_id === undefined) {
            throw new Error('Required parameter angel_id was null or undefined when calling groupJoinAngelDelete.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/group/join/" + encodeURIComponent(String(join_id)) + "/" + encodeURIComponent(String(angel_id)), {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupJoinDelete = function (join_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (join_id === null || join_id === undefined) {
            throw new Error('Required parameter join_id was null or undefined when calling groupJoinDelete.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (join_id !== undefined && join_id !== null) {
            queryParameters = queryParameters.set('join_id', join_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/group/join", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupJoinGet = function (gte_date, lte_date, join_name, act_dept_name, angel_name, angel_cellp_no, join_type, join_category, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling groupJoinGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling groupJoinGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (join_name !== undefined && join_name !== null) {
            queryParameters = queryParameters.set('join_name', join_name);
        }
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', act_dept_name);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', angel_cellp_no);
        }
        if (join_type !== undefined && join_type !== null) {
            queryParameters = queryParameters.set('join_type', join_type);
        }
        if (join_category !== undefined && join_category !== null) {
            queryParameters = queryParameters.set('join_category', join_category);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/join", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupJoinPost = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupJoinPost.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/group/join", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupJoinPut = function (join_id, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (join_id === null || join_id === undefined) {
            throw new Error('Required parameter join_id was null or undefined when calling groupJoinPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupJoinPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (join_id !== undefined && join_id !== null) {
            queryParameters = queryParameters.set('join_id', join_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/join", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupReservedDelete = function (reserved_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (reserved_id === null || reserved_id === undefined) {
            throw new Error('Required parameter reserved_id was null or undefined when calling groupReservedDelete.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (reserved_id !== undefined && reserved_id !== null) {
            queryParameters = queryParameters.set('reserved_id', reserved_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/group/reserved", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupReservedGet = function (gte_date, lte_date, reserved_region, angel_type, schedule_id, schedule_date_id, angel_name, angel_cellp_no, schedule_dept_name, angel_edu_yn, reserved_place_status, angel_edu_path, college_name, act_dept_name, angel_1365_join, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (reserved_region !== undefined && reserved_region !== null) {
            queryParameters = queryParameters.set('reserved_region', reserved_region);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (schedule_id !== undefined && schedule_id !== null) {
            queryParameters = queryParameters.set('schedule_id', schedule_id);
        }
        if (schedule_date_id !== undefined && schedule_date_id !== null) {
            queryParameters = queryParameters.set('schedule_date_id', schedule_date_id);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', angel_cellp_no);
        }
        if (schedule_dept_name !== undefined && schedule_dept_name !== null) {
            queryParameters = queryParameters.set('schedule_dept_name', schedule_dept_name);
        }
        if (angel_edu_yn !== undefined && angel_edu_yn !== null) {
            queryParameters = queryParameters.set('angel_edu_yn', angel_edu_yn);
        }
        if (reserved_place_status !== undefined && reserved_place_status !== null) {
            queryParameters = queryParameters.set('reserved_place_status', reserved_place_status);
        }
        if (angel_edu_path !== undefined && angel_edu_path !== null) {
            queryParameters = queryParameters.set('angel_edu_path', angel_edu_path);
        }
        if (college_name !== undefined && college_name !== null) {
            queryParameters = queryParameters.set('college_name', college_name);
        }
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', act_dept_name);
        }
        if (angel_1365_join !== undefined && angel_1365_join !== null) {
            queryParameters = queryParameters.set('angel_1365_join', angel_1365_join);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/reserved", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupReservedListGet = function (schedule_date_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (schedule_date_id !== undefined && schedule_date_id !== null) {
            queryParameters = queryParameters.set('schedule_date_id', schedule_date_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/reserved_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupReservedMultiComplete = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedMultiComplete.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/reserved_multi_complete", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupReservedMultiDelete = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedMultiDelete.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/reserved_multi_delete", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupReservedMultiNotComplete = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedMultiNotComplete.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/reserved_multi_not_complete", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupReservedPost = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedPost.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/group/reserved", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupReservedPut = function (reserved_id, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (reserved_id === null || reserved_id === undefined) {
            throw new Error('Required parameter reserved_id was null or undefined when calling groupReservedPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupReservedPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (reserved_id !== undefined && reserved_id !== null) {
            queryParameters = queryParameters.set('reserved_id', reserved_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/reserved", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupScheduleCodeGet = function (schedule_title, schedule_state, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (schedule_title !== undefined && schedule_title !== null) {
            queryParameters = queryParameters.set('schedule_title', schedule_title);
        }
        if (schedule_state !== undefined && schedule_state !== null) {
            queryParameters = queryParameters.set('schedule_state', schedule_state);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/schedule_code", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupScheduleDateDelete = function (schedule_id, schedule_date_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (schedule_id === null || schedule_id === undefined) {
            throw new Error('Required parameter schedule_id was null or undefined when calling groupScheduleDateDelete.');
        }
        if (schedule_date_id === null || schedule_date_id === undefined) {
            throw new Error('Required parameter schedule_date_id was null or undefined when calling groupScheduleDateDelete.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (schedule_id !== undefined && schedule_id !== null) {
            queryParameters = queryParameters.set('schedule_id', schedule_id);
        }
        if (schedule_date_id !== undefined && schedule_date_id !== null) {
            queryParameters = queryParameters.set('schedule_date_id', schedule_date_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/group/schedule_date", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupScheduleDelete = function (schedule_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (schedule_id === null || schedule_id === undefined) {
            throw new Error('Required parameter schedule_id was null or undefined when calling groupScheduleDelete.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (schedule_id !== undefined && schedule_id !== null) {
            queryParameters = queryParameters.set('schedule_id', schedule_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/group/schedule", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupScheduleGet = function (schedule_year, schedule_title, schedule_dept_name, schedule_type, schedule_region, schedule_state, schedule_homepage_view_yn, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (schedule_year !== undefined && schedule_year !== null) {
            queryParameters = queryParameters.set('schedule_year', schedule_year);
        }
        if (schedule_title !== undefined && schedule_title !== null) {
            queryParameters = queryParameters.set('schedule_title', schedule_title);
        }
        if (schedule_dept_name !== undefined && schedule_dept_name !== null) {
            queryParameters = queryParameters.set('schedule_dept_name', schedule_dept_name);
        }
        if (schedule_type !== undefined && schedule_type !== null) {
            queryParameters = queryParameters.set('schedule_type', schedule_type);
        }
        if (schedule_region !== undefined && schedule_region !== null) {
            queryParameters = queryParameters.set('schedule_region', schedule_region);
        }
        if (schedule_state !== undefined && schedule_state !== null) {
            queryParameters = queryParameters.set('schedule_state', schedule_state);
        }
        if (schedule_homepage_view_yn !== undefined && schedule_homepage_view_yn !== null) {
            queryParameters = queryParameters.set('schedule_homepage_view_yn', schedule_homepage_view_yn);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/schedule", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupScheduleListGet = function (gte_date, lte_date, schedule_dept_name, schedule_region, schedule_type, schedule_state, schedule_date_state, schedule_homepage_view_yn, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (schedule_dept_name !== undefined && schedule_dept_name !== null) {
            queryParameters = queryParameters.set('schedule_dept_name', schedule_dept_name);
        }
        if (schedule_region !== undefined && schedule_region !== null) {
            queryParameters = queryParameters.set('schedule_region', schedule_region);
        }
        if (schedule_type !== undefined && schedule_type !== null) {
            queryParameters = queryParameters.set('schedule_type', schedule_type);
        }
        if (schedule_state !== undefined && schedule_state !== null) {
            queryParameters = queryParameters.set('schedule_state', schedule_state);
        }
        if (schedule_date_state !== undefined && schedule_date_state !== null) {
            queryParameters = queryParameters.set('schedule_date_state', schedule_date_state);
        }
        if (schedule_homepage_view_yn !== undefined && schedule_homepage_view_yn !== null) {
            queryParameters = queryParameters.set('schedule_homepage_view_yn', schedule_homepage_view_yn);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/schedule_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupSchedulePost = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupSchedulePost.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(this.basePath + "/group/schedule", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupSchedulePut = function (schedule_id, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (schedule_id === null || schedule_id === undefined) {
            throw new Error('Required parameter schedule_id was null or undefined when calling groupSchedulePut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupSchedulePut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (schedule_id !== undefined && schedule_id !== null) {
            queryParameters = queryParameters.set('schedule_id', schedule_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/schedule", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupSocialCompletionGet = function (group_id, college_id, act_dept_name, angel_name, angel_cellp_no, completion_yn, reserved_evaluation_submit_yn, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupSocialCompletionGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (group_id !== undefined && group_id !== null) {
            queryParameters = queryParameters.set('group_id', group_id);
        }
        if (college_id !== undefined && college_id !== null) {
            queryParameters = queryParameters.set('college_id', college_id);
        }
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', act_dept_name);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', angel_cellp_no);
        }
        if (completion_yn !== undefined && completion_yn !== null) {
            queryParameters = queryParameters.set('completion_yn', completion_yn);
        }
        if (reserved_evaluation_submit_yn !== undefined && reserved_evaluation_submit_yn !== null) {
            queryParameters = queryParameters.set('reserved_evaluation_submit_yn', reserved_evaluation_submit_yn);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/social_completion", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupSocialCompletionPut = function (reserved_evaluation_submit_yn, body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (reserved_evaluation_submit_yn === null || reserved_evaluation_submit_yn === undefined) {
            throw new Error('Required parameter reserved_evaluation_submit_yn was null or undefined when calling groupSocialCompletionPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupSocialCompletionPut.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (reserved_evaluation_submit_yn !== undefined && reserved_evaluation_submit_yn !== null) {
            queryParameters = queryParameters.set('reserved_evaluation_submit_yn', reserved_evaluation_submit_yn);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/social_completion", body, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupTargetDelete = function (target_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (target_id === null || target_id === undefined) {
            throw new Error('Required parameter target_id was null or undefined when calling groupTargetDelete.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (target_id !== undefined && target_id !== null) {
            queryParameters = queryParameters.set('target_id', target_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.delete(this.basePath + "/group/target", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupTargetGet = function (gte_date, lte_date, target_name, target_remark, label_name, item_issue_emp_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (gte_date === null || gte_date === undefined) {
            throw new Error('Required parameter gte_date was null or undefined when calling groupTargetGet.');
        }
        if (lte_date === null || lte_date === undefined) {
            throw new Error('Required parameter lte_date was null or undefined when calling groupTargetGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (gte_date !== undefined && gte_date !== null) {
            queryParameters = queryParameters.set('gte_date', gte_date);
        }
        if (lte_date !== undefined && lte_date !== null) {
            queryParameters = queryParameters.set('lte_date', lte_date);
        }
        if (target_name !== undefined && target_name !== null) {
            queryParameters = queryParameters.set('target_name', target_name);
        }
        if (target_remark !== undefined && target_remark !== null) {
            queryParameters = queryParameters.set('target_remark', target_remark);
        }
        if (label_name !== undefined && label_name !== null) {
            queryParameters = queryParameters.set('label_name', label_name);
        }
        if (item_issue_emp_name !== undefined && item_issue_emp_name !== null) {
            queryParameters = queryParameters.set('item_issue_emp_name', item_issue_emp_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/target", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupTargetIdGet = function (target_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (target_id === null || target_id === undefined) {
            throw new Error('Required parameter target_id was null or undefined when calling groupTargetIdGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (target_id !== undefined && target_id !== null) {
            queryParameters = queryParameters.set('target_id', target_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/target_id", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupTargetListGet = function (act_dept_name, first_gte_date, first_lte_date, final_gte_date, final_lte_date, gte_time, lte_time, gte_count, lte_count, birth_gte_date, birth_lte_date, angel_name, angel_cellp_no, angel_now_status, angel_type, angel_term, angel_gender, angel_position, have_tag_name, except_tag_name, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (act_dept_name !== undefined && act_dept_name !== null) {
            queryParameters = queryParameters.set('act_dept_name', act_dept_name);
        }
        if (first_gte_date !== undefined && first_gte_date !== null) {
            queryParameters = queryParameters.set('first_gte_date', first_gte_date);
        }
        if (first_lte_date !== undefined && first_lte_date !== null) {
            queryParameters = queryParameters.set('first_lte_date', first_lte_date);
        }
        if (final_gte_date !== undefined && final_gte_date !== null) {
            queryParameters = queryParameters.set('final_gte_date', final_gte_date);
        }
        if (final_lte_date !== undefined && final_lte_date !== null) {
            queryParameters = queryParameters.set('final_lte_date', final_lte_date);
        }
        if (gte_time !== undefined && gte_time !== null) {
            queryParameters = queryParameters.set('gte_time', gte_time);
        }
        if (lte_time !== undefined && lte_time !== null) {
            queryParameters = queryParameters.set('lte_time', lte_time);
        }
        if (gte_count !== undefined && gte_count !== null) {
            queryParameters = queryParameters.set('gte_count', gte_count);
        }
        if (lte_count !== undefined && lte_count !== null) {
            queryParameters = queryParameters.set('lte_count', lte_count);
        }
        if (birth_gte_date !== undefined && birth_gte_date !== null) {
            queryParameters = queryParameters.set('birth_gte_date', birth_gte_date);
        }
        if (birth_lte_date !== undefined && birth_lte_date !== null) {
            queryParameters = queryParameters.set('birth_lte_date', birth_lte_date);
        }
        if (angel_name !== undefined && angel_name !== null) {
            queryParameters = queryParameters.set('angel_name', angel_name);
        }
        if (angel_cellp_no !== undefined && angel_cellp_no !== null) {
            queryParameters = queryParameters.set('angel_cellp_no', angel_cellp_no);
        }
        if (angel_now_status !== undefined && angel_now_status !== null) {
            queryParameters = queryParameters.set('angel_now_status', angel_now_status);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', angel_term);
        }
        if (angel_gender !== undefined && angel_gender !== null) {
            queryParameters = queryParameters.set('angel_gender', angel_gender);
        }
        if (angel_position !== undefined && angel_position !== null) {
            queryParameters = queryParameters.set('angel_position', angel_position);
        }
        if (have_tag_name !== undefined && have_tag_name !== null) {
            queryParameters = queryParameters.set('have_tag_name', have_tag_name);
        }
        if (except_tag_name !== undefined && except_tag_name !== null) {
            queryParameters = queryParameters.set('except_tag_name', except_tag_name);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/group/target_list", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupTargetMultiDelete = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupTargetMultiDelete.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/target_multi_delete", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    GroupService.prototype.groupTargetPut = function (body, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupTargetPut.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(this.basePath + "/group/target", body, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return GroupService;
}());
export { GroupService };
