import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BootstrapComponent, FECommonModule } from 'fecommon';
import { environment } from '../environments/environment';
import { BASE_PATH as ES_BASE_PATH, APIS as BeEsApis } from './restapi/be-esquery';
import { BASE_PATH as MASTER_BASE_PATH, APIS as BeMasterApis } from './restapi/be-master';
import { BASE_PATH as ANGEL_BASE_PATH, APIS as BeAngelApis } from './restapi/be-angel';
import { BASE_PATH as COMMON_BASE_PATH, APIS as BeCommonApis } from './restapi/be-common';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './components/home/home.component';
import { StatisticsModule } from './components/statistics/statistics.module';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

@NgModule({
    imports: [
        BrowserModule,
        FECommonModule.forRoot(environment.oAuthConfig),
        StatisticsModule,
        AppRoutingModule
    ],
    declarations: [
        HomeComponent,
        DashboardComponent,
        LogoutComponent
    ],
    providers: [
        { provide: ES_BASE_PATH, useValue: environment.ES_REST_API },
        { provide: MASTER_BASE_PATH, useValue: environment.MASTER_REST_API },
        { provide: ANGEL_BASE_PATH, useValue: environment.ANGEL_REST_API },
        { provide: COMMON_BASE_PATH, useValue: environment.COMMON_REST_API },
        BeEsApis,
        BeMasterApis,
        BeAngelApis,
        BeCommonApis,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
    ],
    bootstrap: [BootstrapComponent]
})
export class AppModule { }
