/**
 * esquery
 *  ## 1.ES를 이용해서 쿼리 API를 정의한다 .
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
var EsDeptService = /** @class */ (function () {
    function EsDeptService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9980/esquery/v1';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    EsDeptService.prototype.canConsumeForm = function (consumes) {
        var form = 'multipart/form-data';
        for (var _i = 0, consumes_1 = consumes; _i < consumes_1.length; _i++) {
            var consume = consumes_1[_i];
            if (form === consume) {
                return true;
            }
        }
        return false;
    };
    EsDeptService.prototype.deptDeptIdChildrenGet = function (dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptDeptIdChildrenGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dept/" + encodeURIComponent(String(dept_id)) + "/children", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsDeptService.prototype.deptDeptIdEmpsGet = function (dept_id, sub, permanent_emp, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptDeptIdEmpsGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (sub !== undefined && sub !== null) {
            queryParameters = queryParameters.set('sub', sub);
        }
        if (permanent_emp !== undefined && permanent_emp !== null) {
            queryParameters = queryParameters.set('permanent_emp', permanent_emp);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dept/" + encodeURIComponent(String(dept_id)) + "/emps", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsDeptService.prototype.deptDeptIdToCurMemberGet = function (dept_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptDeptIdToCurMemberGet.');
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dept/" + encodeURIComponent(String(dept_id)) + "/to/cur_member", {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsDeptService.prototype.deptDeptIdVehiclesGet = function (dept_id, sub, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptDeptIdVehiclesGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (sub !== undefined && sub !== null) {
            queryParameters = queryParameters.set('sub', sub);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dept/" + encodeURIComponent(String(dept_id)) + "/vehicles", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsDeptService.prototype.deptInfoGet = function (dept_name, shop_type, exclude_dept_unit_id, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (dept_name !== undefined && dept_name !== null) {
            queryParameters = queryParameters.set('dept_name', dept_name);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', shop_type);
        }
        if (exclude_dept_unit_id !== undefined && exclude_dept_unit_id !== null) {
            queryParameters = queryParameters.set('exclude_dept_unit_id', exclude_dept_unit_id);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dept/info", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    EsDeptService.prototype.deptUnitIdGet = function (dep_uni, shop_type, observe, reportProgress) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (dep_uni === null || dep_uni === undefined) {
            throw new Error('Required parameter dep_uni was null or undefined when calling deptUnitIdGet.');
        }
        var queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (dep_uni !== undefined && dep_uni !== null) {
            queryParameters = queryParameters.set('dep_uni', dep_uni);
        }
        if (shop_type !== undefined && shop_type !== null) {
            queryParameters = queryParameters.set('shop_type', shop_type);
        }
        var headers = this.defaultHeaders;
        // to determine the Accept header
        var httpHeaderAccepts = [
            'application/json'
        ];
        var httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/x-www-form-urlencoded'
        ];
        return this.httpClient.get(this.basePath + "/dept/unit_id", {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    return EsDeptService;
}());
export { EsDeptService };
