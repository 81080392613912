/**
 * angel
 *  ## 자원활동관련 데이타를 관리한다. 
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ActiveState } from '../model/activeState';
import { ActiveStateYear } from '../model/activeStateYear';
import { ByTimetable } from '../model/byTimetable';
import { Failure } from '../model/failure';
import { Statistics } from '../model/statistics';
import { TimeYear } from '../model/timeYear';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StatisticsService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9905/angel/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 활동천사정보 활동상태현황 통계
     * 
     * @param act_full_dept_name 활동지명
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param angel_job 직업
     * @param angel_gender 성별
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsActiveStateGet(act_full_dept_name?: string, angel_type?: string, angel_term?: string, angel_job?: string, angel_gender?: '남' | '여', observe?: 'body', reportProgress?: boolean): Observable<Array<ActiveState>>;
    public statisticsActiveStateGet(act_full_dept_name?: string, angel_type?: string, angel_term?: string, angel_job?: string, angel_gender?: '남' | '여', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActiveState>>>;
    public statisticsActiveStateGet(act_full_dept_name?: string, angel_type?: string, angel_term?: string, angel_job?: string, angel_gender?: '남' | '여', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActiveState>>>;
    public statisticsActiveStateGet(act_full_dept_name?: string, angel_type?: string, angel_term?: string, angel_job?: string, angel_gender?: '남' | '여', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', <any>act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }
        if (angel_job !== undefined && angel_job !== null) {
            queryParameters = queryParameters.set('angel_job', <any>angel_job);
        }
        if (angel_gender !== undefined && angel_gender !== null) {
            queryParameters = queryParameters.set('angel_gender', <any>angel_gender);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ActiveState>>(`${this.basePath}/statistics/active_state`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사정보 활동상태연보 통계
     * 
     * @param year 연도(YYYY)
     * @param act_full_dept_name 활동지명
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsActiveStateYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ActiveStateYear>>;
    public statisticsActiveStateYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActiveStateYear>>>;
    public statisticsActiveStateYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActiveStateYear>>>;
    public statisticsActiveStateYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsActiveStateYearGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', <any>act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ActiveStateYear>>(`${this.basePath}/statistics/active_state_year`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사정보 연령대별 연보 통계
     * 
     * @param year 연도(YYYY)
     * @param act_full_dept_name 활동지명
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsAgeYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Statistics>>;
    public statisticsAgeYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Statistics>>>;
    public statisticsAgeYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Statistics>>>;
    public statisticsAgeYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsAgeYearGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', <any>act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Statistics>>(`${this.basePath}/statistics/age_year`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사정보 지역별연보 통계
     * 
     * @param year 연도(YYYY)
     * @param act_full_dept_name 활동지명
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsAreaYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Statistics>>;
    public statisticsAreaYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Statistics>>>;
    public statisticsAreaYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Statistics>>>;
    public statisticsAreaYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsAreaYearGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', <any>act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Statistics>>(`${this.basePath}/statistics/area_year`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사정보 요일시간대별 통계
     * 
     * @param act_full_dept_name 활동지명
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param angel_job 직업
     * @param angel_gender 성별
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsByTimetableGet(act_full_dept_name?: string, angel_type?: string, angel_term?: string, angel_job?: string, angel_gender?: '남' | '여', observe?: 'body', reportProgress?: boolean): Observable<Array<ByTimetable>>;
    public statisticsByTimetableGet(act_full_dept_name?: string, angel_type?: string, angel_term?: string, angel_job?: string, angel_gender?: '남' | '여', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ByTimetable>>>;
    public statisticsByTimetableGet(act_full_dept_name?: string, angel_type?: string, angel_term?: string, angel_job?: string, angel_gender?: '남' | '여', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ByTimetable>>>;
    public statisticsByTimetableGet(act_full_dept_name?: string, angel_type?: string, angel_term?: string, angel_job?: string, angel_gender?: '남' | '여', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', <any>act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }
        if (angel_job !== undefined && angel_job !== null) {
            queryParameters = queryParameters.set('angel_job', <any>angel_job);
        }
        if (angel_gender !== undefined && angel_gender !== null) {
            queryParameters = queryParameters.set('angel_gender', <any>angel_gender);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ByTimetable>>(`${this.basePath}/statistics/by_timetable`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사정보 연차별 연보 통계
     * 
     * @param year 연도(YYYY)
     * @param act_full_dept_name 활동지명
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsDifferenceYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Statistics>>;
    public statisticsDifferenceYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Statistics>>>;
    public statisticsDifferenceYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Statistics>>>;
    public statisticsDifferenceYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsDifferenceYearGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', <any>act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Statistics>>(`${this.basePath}/statistics/difference_year`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사정보 성별연보 통계
     * 
     * @param year 연도(YYYY)
     * @param act_full_dept_name 활동지명
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsGenderYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Statistics>>;
    public statisticsGenderYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Statistics>>>;
    public statisticsGenderYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Statistics>>>;
    public statisticsGenderYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsGenderYearGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', <any>act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Statistics>>(`${this.basePath}/statistics/gender_year`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 활동천사정보 시간건수 연보 통계
     * 
     * @param year 연도(YYYY)
     * @param act_full_dept_name 활동지명
     * @param angel_type 활동구분
     * @param angel_term 활동주기
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsTimeYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TimeYear>>;
    public statisticsTimeYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TimeYear>>>;
    public statisticsTimeYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TimeYear>>>;
    public statisticsTimeYearGet(year: string, act_full_dept_name?: string, angel_type?: string, angel_term?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling statisticsTimeYearGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (act_full_dept_name !== undefined && act_full_dept_name !== null) {
            queryParameters = queryParameters.set('act_full_dept_name', <any>act_full_dept_name);
        }
        if (angel_type !== undefined && angel_type !== null) {
            queryParameters = queryParameters.set('angel_type', <any>angel_type);
        }
        if (angel_term !== undefined && angel_term !== null) {
            queryParameters = queryParameters.set('angel_term', <any>angel_term);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TimeYear>>(`${this.basePath}/statistics/time_year`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
