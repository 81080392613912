/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다. 
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { EmpPageGroup } from '../model/empPageGroup';
import { Failure } from '../model/failure';
import { NonPropertiesStringList } from '../model/nonPropertiesStringList';
import { PageGroup } from '../model/pageGroup';
import { PageGroupPost } from '../model/pageGroupPost';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PageGroupService {

    protected basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 해당 부서전체 인원에 page_group 추가 및 변경
     * 
     * @param dept_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deptsDeptIdPageGroupPut(dept_id: string, body: NonPropertiesStringList, observe?: 'body', reportProgress?: boolean): Observable<Array<EmpPageGroup>>;
    public deptsDeptIdPageGroupPut(dept_id: string, body: NonPropertiesStringList, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EmpPageGroup>>>;
    public deptsDeptIdPageGroupPut(dept_id: string, body: NonPropertiesStringList, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EmpPageGroup>>>;
    public deptsDeptIdPageGroupPut(dept_id: string, body: NonPropertiesStringList, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_id === null || dept_id === undefined) {
            throw new Error('Required parameter dept_id was null or undefined when calling deptsDeptIdPageGroupPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deptsDeptIdPageGroupPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<EmpPageGroup>>(`${this.basePath}/depts/${encodeURIComponent(String(dept_id))}/page_group`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * emp에게 page_group 추가 및 변경
     * 
     * @param emp_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public empsEmpIdPageGroupPut(emp_id: string, body: NonPropertiesStringList, observe?: 'body', reportProgress?: boolean): Observable<EmpPageGroup>;
    public empsEmpIdPageGroupPut(emp_id: string, body: NonPropertiesStringList, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmpPageGroup>>;
    public empsEmpIdPageGroupPut(emp_id: string, body: NonPropertiesStringList, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmpPageGroup>>;
    public empsEmpIdPageGroupPut(emp_id: string, body: NonPropertiesStringList, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (emp_id === null || emp_id === undefined) {
            throw new Error('Required parameter emp_id was null or undefined when calling empsEmpIdPageGroupPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling empsEmpIdPageGroupPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<EmpPageGroup>(`${this.basePath}/emps/${encodeURIComponent(String(emp_id))}/page_group`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 전체 page_group 조회(삭제 페이지그룹 포함)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pageGroupGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PageGroup>>;
    public pageGroupGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PageGroup>>>;
    public pageGroupGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PageGroup>>>;
    public pageGroupGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<PageGroup>>(`${this.basePath}/page_group`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * page_group 삭제
     * 등록된 page_group을 삭제한다.
     * @param page_group_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pageGroupPageGroupIdDelete(page_group_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pageGroupPageGroupIdDelete(page_group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pageGroupPageGroupIdDelete(page_group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pageGroupPageGroupIdDelete(page_group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (page_group_id === null || page_group_id === undefined) {
            throw new Error('Required parameter page_group_id was null or undefined when calling pageGroupPageGroupIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/page_group/${encodeURIComponent(String(page_group_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * page_group 그룹 조회
     * 등록된 page_group을 조회
     * @param page_group_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pageGroupPageGroupIdGet(page_group_id: string, observe?: 'body', reportProgress?: boolean): Observable<PageGroup>;
    public pageGroupPageGroupIdGet(page_group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageGroup>>;
    public pageGroupPageGroupIdGet(page_group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageGroup>>;
    public pageGroupPageGroupIdGet(page_group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (page_group_id === null || page_group_id === undefined) {
            throw new Error('Required parameter page_group_id was null or undefined when calling pageGroupPageGroupIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PageGroup>(`${this.basePath}/page_group/${encodeURIComponent(String(page_group_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * page_group의 page_list 추가 수정
     * 등록된 page_group의 page_list를 추가하거나 수정한다.
     * @param page_group_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pageGroupPageGroupIdPageListPut(page_group_id: string, body: NonPropertiesStringList, observe?: 'body', reportProgress?: boolean): Observable<PageGroup>;
    public pageGroupPageGroupIdPageListPut(page_group_id: string, body: NonPropertiesStringList, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageGroup>>;
    public pageGroupPageGroupIdPageListPut(page_group_id: string, body: NonPropertiesStringList, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageGroup>>;
    public pageGroupPageGroupIdPageListPut(page_group_id: string, body: NonPropertiesStringList, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (page_group_id === null || page_group_id === undefined) {
            throw new Error('Required parameter page_group_id was null or undefined when calling pageGroupPageGroupIdPageListPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling pageGroupPageGroupIdPageListPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PageGroup>(`${this.basePath}/page_group/${encodeURIComponent(String(page_group_id))}/page_list`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * page_group 그룹명 설명수정
     * 등록된 page_group의 기본정보 그룹명 설명등을 수정한다.
     * @param page_group_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pageGroupPageGroupIdPut(page_group_id: string, body: PageGroupPost, observe?: 'body', reportProgress?: boolean): Observable<PageGroup>;
    public pageGroupPageGroupIdPut(page_group_id: string, body: PageGroupPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageGroup>>;
    public pageGroupPageGroupIdPut(page_group_id: string, body: PageGroupPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageGroup>>;
    public pageGroupPageGroupIdPut(page_group_id: string, body: PageGroupPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (page_group_id === null || page_group_id === undefined) {
            throw new Error('Required parameter page_group_id was null or undefined when calling pageGroupPageGroupIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling pageGroupPageGroupIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PageGroup>(`${this.basePath}/page_group/${encodeURIComponent(String(page_group_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * page_group 등록
     * page_group를 등록한다.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pageGroupPost(body: PageGroupPost, observe?: 'body', reportProgress?: boolean): Observable<PageGroup>;
    public pageGroupPost(body: PageGroupPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageGroup>>;
    public pageGroupPost(body: PageGroupPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageGroup>>;
    public pageGroupPost(body: PageGroupPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling pageGroupPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PageGroup>(`${this.basePath}/page_group`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
