/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다. 
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ChCode } from '../model/chCode';
import { ChCodePost } from '../model/chCodePost';
import { CodeDisplayName } from '../model/codeDisplayName';
import { Failure } from '../model/failure';
import { MasterCode } from '../model/masterCode';
import { PaCode } from '../model/paCode';
import { PaCodePost } from '../model/paCodePost';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CategoriesCodesService {

    protected basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 카테고리 아이디로 전체 코드조회
     * 
     * @param category_code_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesCategoryCodeIdGet(category_code_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PaCode>>;
    public categoriesCodesCategoryCodeIdGet(category_code_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaCode>>>;
    public categoriesCodesCategoryCodeIdGet(category_code_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaCode>>>;
    public categoriesCodesCategoryCodeIdGet(category_code_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (category_code_id === null || category_code_id === undefined) {
            throw new Error('Required parameter category_code_id was null or undefined when calling categoriesCodesCategoryCodeIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<PaCode>>(`${this.basePath}/categories_codes/${encodeURIComponent(String(category_code_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자식코드값 사용 플래그 업데이트
     * 
     * @param category_code_id 
     * @param pacode_id 
     * @param chcode_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdDelete(category_code_id: string, pacode_id: string, chcode_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdDelete(category_code_id: string, pacode_id: string, chcode_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdDelete(category_code_id: string, pacode_id: string, chcode_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdDelete(category_code_id: string, pacode_id: string, chcode_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (category_code_id === null || category_code_id === undefined) {
            throw new Error('Required parameter category_code_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdDelete.');
        }
        if (pacode_id === null || pacode_id === undefined) {
            throw new Error('Required parameter pacode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdDelete.');
        }
        if (chcode_id === null || chcode_id === undefined) {
            throw new Error('Required parameter chcode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/categories_codes/${encodeURIComponent(String(category_code_id))}/pacode/${encodeURIComponent(String(pacode_id))}/chcode/${encodeURIComponent(String(chcode_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자식코드값 조회
     * 
     * @param category_code_id 
     * @param pacode_id 
     * @param chcode_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdGet(category_code_id: string, pacode_id: string, chcode_id: string, observe?: 'body', reportProgress?: boolean): Observable<ChCode>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdGet(category_code_id: string, pacode_id: string, chcode_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChCode>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdGet(category_code_id: string, pacode_id: string, chcode_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChCode>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdGet(category_code_id: string, pacode_id: string, chcode_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (category_code_id === null || category_code_id === undefined) {
            throw new Error('Required parameter category_code_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdGet.');
        }
        if (pacode_id === null || pacode_id === undefined) {
            throw new Error('Required parameter pacode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdGet.');
        }
        if (chcode_id === null || chcode_id === undefined) {
            throw new Error('Required parameter chcode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<ChCode>(`${this.basePath}/categories_codes/${encodeURIComponent(String(category_code_id))}/pacode/${encodeURIComponent(String(pacode_id))}/chcode/${encodeURIComponent(String(chcode_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자식코드 이름 및 content url 수정
     * 
     * @param category_code_id 
     * @param pacode_id 
     * @param chcode_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdPut(category_code_id: string, pacode_id: string, chcode_id: string, body: ChCodePost, observe?: 'body', reportProgress?: boolean): Observable<ChCode>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdPut(category_code_id: string, pacode_id: string, chcode_id: string, body: ChCodePost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChCode>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdPut(category_code_id: string, pacode_id: string, chcode_id: string, body: ChCodePost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChCode>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdPut(category_code_id: string, pacode_id: string, chcode_id: string, body: ChCodePost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (category_code_id === null || category_code_id === undefined) {
            throw new Error('Required parameter category_code_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdPut.');
        }
        if (pacode_id === null || pacode_id === undefined) {
            throw new Error('Required parameter pacode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdPut.');
        }
        if (chcode_id === null || chcode_id === undefined) {
            throw new Error('Required parameter chcode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodeChcodeIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ChCode>(`${this.basePath}/categories_codes/${encodeURIComponent(String(category_code_id))}/pacode/${encodeURIComponent(String(pacode_id))}/chcode/${encodeURIComponent(String(chcode_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 자식코드값 등록
     * 
     * @param category_code_id 
     * @param pacode_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodePost(category_code_id: string, pacode_id: string, body: ChCodePost, observe?: 'body', reportProgress?: boolean): Observable<PaCode>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodePost(category_code_id: string, pacode_id: string, body: ChCodePost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaCode>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodePost(category_code_id: string, pacode_id: string, body: ChCodePost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaCode>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdChcodePost(category_code_id: string, pacode_id: string, body: ChCodePost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (category_code_id === null || category_code_id === undefined) {
            throw new Error('Required parameter category_code_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodePost.');
        }
        if (pacode_id === null || pacode_id === undefined) {
            throw new Error('Required parameter pacode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodePost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdChcodePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaCode>(`${this.basePath}/categories_codes/${encodeURIComponent(String(category_code_id))}/pacode/${encodeURIComponent(String(pacode_id))}/chcode`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부모코드로 전체자식코드 조회
     * 
     * @param category_code_id 
     * @param pacode_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesCategoryCodeIdPacodePacodeIdGet(category_code_id: string, pacode_id: string, observe?: 'body', reportProgress?: boolean): Observable<PaCode>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdGet(category_code_id: string, pacode_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaCode>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdGet(category_code_id: string, pacode_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaCode>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdGet(category_code_id: string, pacode_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (category_code_id === null || category_code_id === undefined) {
            throw new Error('Required parameter category_code_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdGet.');
        }
        if (pacode_id === null || pacode_id === undefined) {
            throw new Error('Required parameter pacode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<PaCode>(`${this.basePath}/categories_codes/${encodeURIComponent(String(category_code_id))}/pacode/${encodeURIComponent(String(pacode_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 부모코드 디스플레이 이름수정
     * 
     * @param category_code_id 
     * @param pacode_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesCategoryCodeIdPacodePacodeIdPut(category_code_id: string, pacode_id: string, body: CodeDisplayName, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdPut(category_code_id: string, pacode_id: string, body: CodeDisplayName, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdPut(category_code_id: string, pacode_id: string, body: CodeDisplayName, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public categoriesCodesCategoryCodeIdPacodePacodeIdPut(category_code_id: string, pacode_id: string, body: CodeDisplayName, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (category_code_id === null || category_code_id === undefined) {
            throw new Error('Required parameter category_code_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdPut.');
        }
        if (pacode_id === null || pacode_id === undefined) {
            throw new Error('Required parameter pacode_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling categoriesCodesCategoryCodeIdPacodePacodeIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/categories_codes/${encodeURIComponent(String(category_code_id))}/pacode/${encodeURIComponent(String(pacode_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 카테고리 안에 부모가 되는 코드 등록
     * 
     * @param category_code_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesCategoryCodeIdPacodePost(category_code_id: string, body: PaCodePost, observe?: 'body', reportProgress?: boolean): Observable<PaCodePost>;
    public categoriesCodesCategoryCodeIdPacodePost(category_code_id: string, body: PaCodePost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaCodePost>>;
    public categoriesCodesCategoryCodeIdPacodePost(category_code_id: string, body: PaCodePost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaCodePost>>;
    public categoriesCodesCategoryCodeIdPacodePost(category_code_id: string, body: PaCodePost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (category_code_id === null || category_code_id === undefined) {
            throw new Error('Required parameter category_code_id was null or undefined when calling categoriesCodesCategoryCodeIdPacodePost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling categoriesCodesCategoryCodeIdPacodePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaCodePost>(`${this.basePath}/categories_codes/${encodeURIComponent(String(category_code_id))}/pacode`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 전체 카테고리 코드 pk값조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<MasterCode>>;
    public categoriesCodesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MasterCode>>>;
    public categoriesCodesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MasterCode>>>;
    public categoriesCodesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<MasterCode>>(`${this.basePath}/categories_codes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 카테고리 코드 등록
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoriesCodesPost(body: MasterCode, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public categoriesCodesPost(body: MasterCode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public categoriesCodesPost(body: MasterCode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public categoriesCodesPost(body: MasterCode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling categoriesCodesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/categories_codes`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
