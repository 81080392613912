import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareModule } from 'src/app/share/share.module';

import { MatTabsModule, MatTableModule, MatSortModule, MatDialogModule, MatDatepickerModule } from '@angular/material';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { StatisticsRoutingModule } from './statistics-routing.module';
import { GivingsPlaceComponent } from './donation/givings-place/givings-place.component';
import { DreceiptIssueComponent } from './donation/dreceipt-issue/dreceipt-issue.component';
import { GivingsCallWatingComponent } from './donation/givings-call-wating/givings-call-wating.component';
import { GivingsCallHistoryComponent } from './donation/givings-call-history/givings-call-history.component';
import { GivingsRegionComponent } from './donation/givings-region/givings-region.component';
import { LogisticsDeptsComponent } from './donation/logistics-depts/logistics-depts.component';
import { LogisticsDeptsDivComponent } from './donation/logistics-depts-div/logistics-depts-div.component';
import { LogisticsDeptsDetailComponent } from './donation/logistics-depts-detail/logistics-depts-detail.component';
import { LogisticsEmpsComponent } from './donation/logistics-emps/logistics-emps.component';
import { LogisticsEmpsDivComponent } from './donation/logistics-emps-div/logistics-emps-div.component';
import { LogisticsEmpsDetailComponent } from './donation/logistics-emps-detail/logistics-emps-detail.component';
import { GivingsCallComponent } from './donation/givings-call/givings-call.component';
import { RawDataComponent } from './donation/raw-data/raw-data.component';
import { RawDataJoinerGivingsComponent } from './donation/raw-data-joiner-givings/raw-data-joiner-givings.component';
import { RawDataAnonymousGivingsComponent } from './donation/raw-data-anonymous-givings/raw-data-anonymous-givings.component';
import { RawDataLogisticsComponent } from './donation/raw-data-logistics/raw-data-logistics.component';
import { WarehouseProductionComponent } from './donation/warehouse-production/warehouse-production.component';
import { WarehouseShippingComponent } from './donation/warehouse-shipping/warehouse-shipping.component';
import { WarehouseEventShippingComponent } from './donation/warehouse-event-shipping/warehouse-event-shipping.component';
import { WarehouseTotalComponent } from './donation/warehouse-total/warehouse-total.component';
import { RawDataLogisticsOrderComponent } from './donation/raw-data-logistics-order/raw-data-logistics-order.component';
import { ShopGivingsProductionComponent } from './donation/shop-givings-production/shop-givings-production.component';
import { RawDataShopGivingsComponent } from './donation/raw-data-shop-givings/raw-data-shop-givings.component';
import { GivingsCallDurationComponent } from './donation/givings-call-duration/givings-call-duration.component';
import { GivingsCallAllDurationComponent } from './donation/givings-call-all-duration/givings-call-all-duration.component';
import { GivingsCallAreaComponent } from './donation/givings-call-area/givings-call-area.component';
import { SupplyRateBySalesComponent } from './donation/supply-rate-by-sales/supply-rate-by-sales.component';
import { SalesRateBySupplyComponent } from './donation/sales-rate-by-supply/sales-rate-by-supply.component';
import { GivingsByDonationComponent } from './donation/givings-by-donation/givings-by-donation.component';
import { AngelModule } from './angel/angel.module';
import { FECommonModule } from 'fecommon';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ShareModule,
        FECommonModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        MatDialogModule,
        MatDatepickerModule,
        NgbPaginationModule,
        StatisticsRoutingModule,
        AngelModule
    ],
    declarations: [
        GivingsPlaceComponent,
        DreceiptIssueComponent,
        GivingsCallWatingComponent,
        GivingsCallHistoryComponent,
        GivingsRegionComponent,
        LogisticsDeptsComponent,
        LogisticsDeptsDivComponent,
        LogisticsDeptsDetailComponent,
        LogisticsEmpsComponent,
        LogisticsEmpsDivComponent,
        LogisticsEmpsDetailComponent,
        GivingsCallComponent,
        RawDataComponent,
        RawDataJoinerGivingsComponent,
        RawDataAnonymousGivingsComponent,
        RawDataLogisticsComponent,
        WarehouseProductionComponent,
        WarehouseShippingComponent,
        WarehouseEventShippingComponent,
        WarehouseTotalComponent,
        RawDataLogisticsOrderComponent,
        ShopGivingsProductionComponent,
        RawDataShopGivingsComponent,
        GivingsCallDurationComponent,
        GivingsCallAllDurationComponent,
        GivingsCallAreaComponent,
        SupplyRateBySalesComponent,
        SalesRateBySupplyComponent,
        GivingsByDonationComponent
    ]
})
export class StatisticsModule { }
