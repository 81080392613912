<div class="section-basic">

  <form class="m-form" [formGroup]="form" (keydown.enter)="$event.preventDefault();" autocomplete="off" novalidate>

    <div class="form-group m-form__group default-style datepicker-group">
      <label>기간</label>
      <div class="input-group">
        <input [matDatepicker]="start" [max]="maxDate" (click)="start.open()" class="form-control m-input"
          formControlName="gte_date" (dateChange)="onChangeStartDate()">
        <mat-datepicker #start></mat-datepicker>

        <button class="btn btn-secondary" type="button"></button>

        <mat-datepicker #end></mat-datepicker>
        <input [matDatepicker]="end" [min]="minDate" [max]="maxDate" (click)="end.open()" class="form-control m-input"
          formControlName="lte_date" (dateChange)="onChangeEndDate()">
      </div>
    </div>

    <app-dept-auto-complete [initLoginDeptFlg]="true" (selected)="selectDept($event)"></app-dept-auto-complete>

    <app-areas-auto-complete (selected)="selectAreas($event)"></app-areas-auto-complete>

    <div class="form-group m-form__group default-style">
      <button class="btn btn-sm btn-primary btn_search" (click)="search()">검색</button>
    </div>

  </form>

</div>

<div class="result">
  <div class="section-basic">

    <div class="btns_right_align">
      <button class="btn btn-sm btn-outline-primary" (click)="exportTOExcel()">엑셀 다운로드</button>
    </div>

    <div class="m--space-20"></div>

    <div class="row">
      <div class="col-lg-3">
        <app-logistics-emps-div [dataList]="logisticsEmpsStatistics?.emps_aggs"></app-logistics-emps-div>
      </div>

      <div class="col-lg-9">
        <app-logistics-emps-detail [dataList]="logisticsEmpsStatistics?.detail_aggs"></app-logistics-emps-detail>
      </div>
    </div>

  </div>

</div>

<fc-detail-fabs [listDisabled]="true"></fc-detail-fabs>