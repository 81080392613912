/**
 * angel
 *  ## 자원활동관련 데이타를 관리한다. 
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Code } from '../model/code';
import { DeptInfo } from '../model/deptInfo';
import { Failure } from '../model/failure';
import { Tag } from '../model/tag';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CodeService {

    protected basePath = 'https://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9905/angel/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 자원활동관리의 코드를 조회.
     * 자원활동관리의 코드를 조회한다.
     * @param code_group_name 코드구분명
     * @param code_category_name 코드카테고리명
     * @param code_name 코드명및카테고리명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelCodeGet(code_group_name: string, code_category_name?: string, code_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Code>>;
    public angelCodeGet(code_group_name: string, code_category_name?: string, code_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Code>>>;
    public angelCodeGet(code_group_name: string, code_category_name?: string, code_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Code>>>;
    public angelCodeGet(code_group_name: string, code_category_name?: string, code_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code_group_name === null || code_group_name === undefined) {
            throw new Error('Required parameter code_group_name was null or undefined when calling angelCodeGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code_group_name !== undefined && code_group_name !== null) {
            queryParameters = queryParameters.set('code_group_name', <any>code_group_name);
        }
        if (code_category_name !== undefined && code_category_name !== null) {
            queryParameters = queryParameters.set('code_category_name', <any>code_category_name);
        }
        if (code_name !== undefined && code_name !== null) {
            queryParameters = queryParameters.set('code_name', <any>code_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Code>>(`${this.basePath}/code/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 권한에따른 활동지를 조회.
     * search_type에 따른 활동지 검색.
     * @param dept_type 부서구분
     * @param dept_name 코드카테고리명
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelDeptGet(dept_type: 'ALL' | 'SEARCHTYPE', dept_name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DeptInfo>>;
    public angelDeptGet(dept_type: 'ALL' | 'SEARCHTYPE', dept_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DeptInfo>>>;
    public angelDeptGet(dept_type: 'ALL' | 'SEARCHTYPE', dept_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DeptInfo>>>;
    public angelDeptGet(dept_type: 'ALL' | 'SEARCHTYPE', dept_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dept_type === null || dept_type === undefined) {
            throw new Error('Required parameter dept_type was null or undefined when calling angelDeptGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dept_type !== undefined && dept_type !== null) {
            queryParameters = queryParameters.set('dept_type', <any>dept_type);
        }
        if (dept_name !== undefined && dept_name !== null) {
            queryParameters = queryParameters.set('dept_name', <any>dept_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<DeptInfo>>(`${this.basePath}/dept/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 태그명 조회
     * 
     * @param tag_ref_name 적용범위
     * @param full_dept_id 적용범위
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public angelTagsGet(tag_ref_name: string, full_dept_id?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Tag>>;
    public angelTagsGet(tag_ref_name: string, full_dept_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Tag>>>;
    public angelTagsGet(tag_ref_name: string, full_dept_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Tag>>>;
    public angelTagsGet(tag_ref_name: string, full_dept_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tag_ref_name === null || tag_ref_name === undefined) {
            throw new Error('Required parameter tag_ref_name was null or undefined when calling angelTagsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tag_ref_name !== undefined && tag_ref_name !== null) {
            queryParameters = queryParameters.set('tag_ref_name', <any>tag_ref_name);
        }
        if (full_dept_id !== undefined && full_dept_id !== null) {
            queryParameters = queryParameters.set('full_dept_id', <any>full_dept_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Tag>>(`${this.basePath}/tags/tags_name`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
