export const environment = {
    production: false,
    ES_REST_API: 'https://api-dev.beautifulstore.org/esquery/v1',
    DONATION_REST_API: 'https://api-dev.beautifulstore.org/donation/v1',
    MASTER_REST_API: 'https://api-dev.beautifulstore.org/master/v1',
    SHOP_REST_API: 'https://api-dev.beautifulstore.org/shop/v1',
    WAREHOUSE_REST_API: 'https://api-dev.beautifulstore.org/warehouse/v1',
    COMMON_REST_API: 'https://api-dev.beautifulstore.org/common/v1',
    ANGEL_REST_API: 'https://api-dev.beautifulstore.org/angel/v1',
    oAuthConfig: {
        issuer: 'https://bs-test.auth0.com/',
        clientId: 'kJbKunh5hdzdISMIi3t9Mc8Kd80BYu1N',
        scope: 'openid profile',
        options: {
            appname: 'master'
        }
    }
};
