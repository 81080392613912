/**
 * master
 *  ## 공통으로 사용되는 데이터를 등록,조회,수정,삭제한다. 
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Failure } from '../model/failure';
import { Policy } from '../model/policy';
import { PolicyDescription } from '../model/policyDescription';
import { PolicyPost } from '../model/policyPost';
import { Statement } from '../model/statement';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PolicyService {

    protected basePath = 'http://bstore-dev-nlb-to-backends-03c7de26a0c53e22.elb.ap-northeast-2.amazonaws.com:9900/master/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * policy 전체 조회
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policiesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Policy>>;
    public policiesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Policy>>>;
    public policiesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Policy>>>;
    public policiesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Array<Policy>>(`${this.basePath}/policies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * policy 삭제
     * 
     * @param policy_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policiesPolicyIdDelete(policy_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public policiesPolicyIdDelete(policy_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public policiesPolicyIdDelete(policy_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public policiesPolicyIdDelete(policy_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (policy_id === null || policy_id === undefined) {
            throw new Error('Required parameter policy_id was null or undefined when calling policiesPolicyIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/policies/${encodeURIComponent(String(policy_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * policy 조회
     * 
     * @param policy_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policiesPolicyIdGet(policy_id: string, observe?: 'body', reportProgress?: boolean): Observable<Policy>;
    public policiesPolicyIdGet(policy_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Policy>>;
    public policiesPolicyIdGet(policy_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Policy>>;
    public policiesPolicyIdGet(policy_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (policy_id === null || policy_id === undefined) {
            throw new Error('Required parameter policy_id was null or undefined when calling policiesPolicyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.get<Policy>(`${this.basePath}/policies/${encodeURIComponent(String(policy_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * policy description 수정
     * 
     * @param policy_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policiesPolicyIdPut(policy_id: string, body: PolicyDescription, observe?: 'body', reportProgress?: boolean): Observable<Policy>;
    public policiesPolicyIdPut(policy_id: string, body: PolicyDescription, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Policy>>;
    public policiesPolicyIdPut(policy_id: string, body: PolicyDescription, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Policy>>;
    public policiesPolicyIdPut(policy_id: string, body: PolicyDescription, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (policy_id === null || policy_id === undefined) {
            throw new Error('Required parameter policy_id was null or undefined when calling policiesPolicyIdPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling policiesPolicyIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Policy>(`${this.basePath}/policies/${encodeURIComponent(String(policy_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * statement 삭제
     * 
     * @param effect allow,deny
     * @param policy_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policiesPolicyIdStatementDelete(effect: 'allow' | 'deny', policy_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public policiesPolicyIdStatementDelete(effect: 'allow' | 'deny', policy_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public policiesPolicyIdStatementDelete(effect: 'allow' | 'deny', policy_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public policiesPolicyIdStatementDelete(effect: 'allow' | 'deny', policy_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (effect === null || effect === undefined) {
            throw new Error('Required parameter effect was null or undefined when calling policiesPolicyIdStatementDelete.');
        }
        if (policy_id === null || policy_id === undefined) {
            throw new Error('Required parameter policy_id was null or undefined when calling policiesPolicyIdStatementDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (effect !== undefined && effect !== null) {
            queryParameters = queryParameters.set('effect', <any>effect);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/policies/${encodeURIComponent(String(policy_id))}/statement`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * statement 추가및 수정
     * 
     * @param policy_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policiesPolicyIdStatementPut(policy_id: string, body: Statement, observe?: 'body', reportProgress?: boolean): Observable<Policy>;
    public policiesPolicyIdStatementPut(policy_id: string, body: Statement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Policy>>;
    public policiesPolicyIdStatementPut(policy_id: string, body: Statement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Policy>>;
    public policiesPolicyIdStatementPut(policy_id: string, body: Statement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (policy_id === null || policy_id === undefined) {
            throw new Error('Required parameter policy_id was null or undefined when calling policiesPolicyIdStatementPut.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling policiesPolicyIdStatementPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Policy>(`${this.basePath}/policies/${encodeURIComponent(String(policy_id))}/statement`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * policy 등록
     * 서비스이름 + 리소스 키워드 + read or write 형태로 등록한다.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policiesPost(body: PolicyPost, observe?: 'body', reportProgress?: boolean): Observable<Policy>;
    public policiesPost(body: PolicyPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Policy>>;
    public policiesPost(body: PolicyPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Policy>>;
    public policiesPost(body: PolicyPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling policiesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Policy>(`${this.basePath}/policies`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
