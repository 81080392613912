import { PipeTransform } from '@angular/core';
var CurrencyMaskPipe = /** @class */ (function () {
    function CurrencyMaskPipe() {
    }
    CurrencyMaskPipe.prototype.transform = function (value) {
        if (value == null) {
            return '';
        }
        var formatNum = this.format_number(value.toString());
        var result = formatNum != null && formatNum != '' ? formatNum : '';
        return result;
    };
    CurrencyMaskPipe.prototype.format_number = function (value) {
        var valueStr = value.toString();
        if (value.includes('-')) {
            valueStr = value.replace(/-/gi, '').toString();
        }
        var decimalIndex = valueStr.lastIndexOf('.');
        var removeIndex = decimalIndex > -1 ? valueStr.length - decimalIndex : 0;
        var thousand_separator = ',';
        var rest = (valueStr.length - removeIndex) % 3;
        var result = valueStr.substr(0, rest);
        var thousands = valueStr.substr(rest).match(/\d{3}/g);
        if (thousands) {
            var separator = rest ? thousand_separator : '';
            result += separator + thousands.join(thousand_separator);
            if (decimalIndex > -1) {
                result = result.concat(valueStr.substring(decimalIndex, valueStr.length));
            }
        }
        if (value.includes('-')) {
            return "-" + result;
        }
        else {
            return result;
        }
    };
    return CurrencyMaskPipe;
}());
export { CurrencyMaskPipe };
