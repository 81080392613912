import { OnInit, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
var DateRangePickerDirective = /** @class */ (function () {
    function DateRangePickerDirective(datePipe) {
        this.datePipe = datePipe;
        this._INPUT_DATE_FORMAT = 'yyyy-MM-dd';
        this.rangeComplete = new EventEmitter();
    }
    DateRangePickerDirective.prototype.ngOnInit = function () {
        this.startDate = this.rangeDatePicker.startDate;
        this.endDate = this.rangeDatePicker.endDate;
    };
    DateRangePickerDirective.prototype.onClosed = function () {
        if (null != this.startDate._selected && null != this.endDate._selected) {
            var selectedStartDate = this.startDate._selected;
            var selectedEndDate = this.endDate._selected;
            if (selectedEndDate._d.getTime() < selectedStartDate._d.getTime()) {
                this.endDate.select(selectedStartDate);
                this.startDate.select(selectedEndDate);
            }
            var output = {
                startDate: this.datePipe.transform(this.startDate._selected._d, this._INPUT_DATE_FORMAT),
                endDate: this.datePipe.transform(this.endDate._selected._d, this._INPUT_DATE_FORMAT)
            };
            this.rangeComplete.emit(output);
        }
    };
    return DateRangePickerDirective;
}());
export { DateRangePickerDirective };
